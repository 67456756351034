import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../../data/enums/SuccessMessage';
import { SUB_D } from '../../../../data/enums/Route';
import { FORM_CONFIG } from '../../../../data/enums/config';
import { refGenerator } from '../../../../utils';
import { fileParser } from '../../../../utils/parser';
import { UPLOADABLES } from '../../../../data/enums/GraphQL';
import { breadCrumbConfig } from '../config';

const breadCrumb = [
  ...breadCrumbConfig,
  {
    id: 1,
    title: 'Sub D',
    path: `/${SUB_D}`,
    active: true,
  },
];

const title = 'Sub D';

const formConfig = {
  [FORM_CONFIG.MAPPER]: element => ({
    file: element.file || {},
  }),
  [FORM_CONFIG.TITLE]: `${EVENT_OPERATION.UPLOAD} ${title}`,
  [FORM_CONFIG.REFS_OBJ]: refGenerator([
    'title',
    'town',
  ]),
  [FORM_CONFIG.VALIDATION_REQUIRED]: true,
};

const crudSuccess = {
  [EVENT_OPERATION.CREATE]: {
    objectMapper: data => data,
    responseName: 'createSubD',
    message: `${title} ${MESSAGE_EVENT_OPERATION.CREATE}`,
  },
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: data => ({
      ...data,
      appPermissions: data.appPermissions ? data.appPermissions : {
        sales: false,
        retailer: false,
        supervisor: false,
        merchandiser: false,
      },
      secondaryContact: data.secondaryContact ? data.secondaryContact : {
        name: '',
        mobileNumber: '',
        email: '',
      },
      servicesUsed: data.servicesUsed ? data.servicesUsed : {
        billing: {
          status: false,
          url: '',
        },
        delivery: {
          status: false,
          url: '',
        },
        logistic: {
          status: false,
          url: '',
        },
      },
      townIds: data.Towns ? data.Towns.map(town => town.id) : [],
    }),
    responseName: 'updateSubD',
    message: `${title} ${MESSAGE_EVENT_OPERATION.UPDATE}`,
  },
  [EVENT_OPERATION.DELETE]: {
    objectMapper: data => ({
      ids: [data.id],
      type: 'Distributor',
      active: false,
    }),
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: data => ({
      ids: [data.id],
      type: 'Distributor',
      active: false,
    }),
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
  [EVENT_OPERATION.UPLOAD]: {
    objectMapper: async (data) => {
      const fileData = await fileParser(data.file)
        .then(file => ({
          input: {
            file,
            type: UPLOADABLES.SUBD,
          },
        }));

      return fileData;
    },
    responseName: 'importCSV',
    message: `${title} ${MESSAGE_EVENT_OPERATION.UPLOAD}`,
  },
};

// eslint-disable-next-line no-unused-vars
const apiTransformer = () => {};

export {
  crudSuccess, breadCrumb as breadCrumbConfig, formConfig,
};
