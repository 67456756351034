import React from 'react';
import {
  Link, Route, Switch, Redirect,
} from 'react-router-dom';

import Navigation from '../navigation';
import BaseWrapper from './BaseStyled';
import { ErrorPage } from '../../components/index';
import {
  DemoAlert,
  DemoBadge,
  DemoButton,
  DemoCheckbox,
  DemoColorPalette,
  DemoIcon,
  errorPage as Error,
} from '../../styleguide';
import NavigationStyled from '../navigation/NavigationStyled';

import * as RoutePage from '..';

import * as RouteLink from '../../data/enums/Route';

import Main from '../configuration/arc/Main';
import TTMain from '../configuration/targetTools/Main';
import { BaseContext } from '../../utils/context';

const Base = ({ match: { url }, history }) => (
  <BaseWrapper className="main-wrapper">
    <NavigationStyled className="side-nav">
      <div className="sidebar-navigation">
        <div className="sidebar-inner">
          <div className="logo-wrap" onClick={() => history.push(`/${RouteLink.DASHBOARD}`)}>
            <a title="Rosia V3">
              <img src="/image/evolve-logo.svg" alt="Rosia V3" />
            </a>
          </div>
          <Navigation />
          <div className="hidden styleguide-menu">
            <Link to={`${url}color-palette`}>Color Palette</Link>
            <Link to={`${url}icons`}>Icons</Link>
            <Link to={`${url}alerts`}>Alerts</Link>
            <Link to={`${url}button`}>Button</Link>
            <Link to={`${url}badge`}>Badge</Link>
            <Link to={`${url}checkbox`}>Checkbox</Link>
            <Link to={`${url}form`}>Form</Link>
            <Link to={`${url}${RouteLink.SKU}`}>SKU</Link>
            <Link to={`${url}${RouteLink.TERRITORY}`}>Territory</Link>
            <Link to={`${url}pagination`}>Pagination</Link>
            <Link to={`${url}dummy`}>Dummy</Link>
            <Link to={`${url}${RouteLink.TOWN}`}>Town</Link>
          </div>
        </div>
      </div>
    </NavigationStyled>
    <div className="section-panel">
      <BaseContext.Provider value={history.location}>
        <Switch>
          <Route exact path={`${url}form`} component={RoutePage.Form} />
          <Route exact path={`${url}dummy`} component={RoutePage.Dummy} />
          <Route exact path={`${url}icons`} component={DemoIcon} />
          <Route exact path={`${url}badge`} component={DemoBadge} />
          <Route exact path={`${url}new/:page`} component={Error} />
          <Route exact path={`${url}alerts`} component={DemoAlert} />
          <Route exact path={`${url}button`} component={DemoButton} />
          <Route exact path={`${url}checkbox`} component={DemoCheckbox} />
          <Route exact path={`${url}pagination`} component={RoutePage.SamplePagination} />
          <Route exact path={`${url}color-palette`} component={DemoColorPalette} />
          <Route exact path={`${url}${RouteLink.DASHBOARD}`} component={RoutePage.Dashboard} />
          <Route exact path={`${url}${RouteLink.DSE_DETAIL}/:id`} component={RoutePage.DSEDetail} />
          <Route exact path={`${url}${RouteLink.BU}`} component={RoutePage.BusinessUnit} />
          <Route exact path={`${url}${RouteLink.DSR}`} component={RoutePage.Town} />
          <Route exact path={`${url}${RouteLink.TOWN}`} component={RoutePage.Town} />
          <Route exact path={`${url}${RouteLink.CHANNEL}`} component={RoutePage.Channel} />
          <Route exact path={`${url}${RouteLink.CHANNEL_DETAILS}/:id`} component={RoutePage.ChannelDetails} />
          <Route exact path={`${url}${RouteLink.TERRITORY}`} component={RoutePage.Territory} />
          <Route exact path={`${url}${RouteLink.USER_ROLE}`} component={RoutePage.UserRole} />
          <Route exact path={`${url}${RouteLink.USER_ACCOUNT}`} component={RoutePage.UserAccount} />
          <Route exact path={`${url}${RouteLink.USER_CREATE}`} component={RoutePage.UserAccountDetail} />
          <Route exact path={`${url}${RouteLink.USER_ACCOUNT}/:id`} component={RoutePage.UserAccountDetail} />
          <Route exact path={`${url}${RouteLink.SKU}`} component={RoutePage.Sku} />
          <Route exact path={`${url}${RouteLink.SKU_CREATE}`} component={RoutePage.CreateSku} />
          <Route exact path={`${url}${RouteLink.SKU_DETAILS}/:id`} component={RoutePage.CreateSku} />
          <Route exact path={`${url}${RouteLink.GRN}`} component={RoutePage.Grn} />
          <Route exact path={`${url}${RouteLink.GRN_CREATE}`} component={RoutePage.CreateGrn} />
          <Route exact path={`${url}${RouteLink.GRN_DETAILS}/:id`} component={RoutePage.CreateGrn} />
          <Route exact path={`${url}${RouteLink.ORDER_FULFILMENT}`} component={RoutePage.OrderDeliveryProcessing} />
          <Route exact path={`${url}${RouteLink.PROMOTION}`} component={RoutePage.Promotion} />
          <Route exact path={`${url}${RouteLink.PROMOTION_DETAILS}/:id`} component={RoutePage.PromotionDetails} />
          <Route exact path={`${url}${RouteLink.PROMOTION}/create`} component={RoutePage.PromotionDetails} />
          <Route exact path={`${url}${RouteLink.ORDER_INVOICE}`} component={RoutePage.SalesInvoice} />
          <Route exact path={`${url}${RouteLink.TARGET_ACHIEVEMENT}`} component={RoutePage.TargetAchievement} />
          <Route
            exact
            path={`${url}${RouteLink.TARGET_ACHIEVEMENT_DETAILS}/:id`}
            component={RoutePage.TargetAchievementDetails}
          />
          <Route exact path={`${url}${RouteLink.OUTLET}`} component={RoutePage.Outlet} />
          <Route exact path={`${url}${RouteLink.OUTLET_CREATE}`} component={RoutePage.OutletDetail} />
          <Route exact path={`${url}${RouteLink.OUTLET_DETAILS}/:id`} component={RoutePage.OutletDetail} />
          <Route exact path={`${url}${RouteLink.ROUTE}`} component={RoutePage.Route} />
          <Route exact path={`${url}${RouteLink.ROUTE_CREATE}`} component={RoutePage.RouteDetail} />
          <Route exact path={`${url}${RouteLink.ROUTE_DETAILS}/:id`} component={RoutePage.RouteDetail} />
          <Route exact path={`${url}${RouteLink.SUB_D}`} component={RoutePage.SubD} />
          <Route exact path={`${url}${RouteLink.SUB_D_CREATE}`} component={RoutePage.CreateSubD} />
          <Route exact path={`${url}${RouteLink.SUB_D_DETAILS}/:id`} component={RoutePage.SubDDetail} />
          <Route exact path={`${url}${RouteLink.STOCK_LEDGER}`} component={RoutePage.StockLedger} />
          <Route exact path={`${url}${RouteLink.MERCHANDISE}`} component={RoutePage.MerchandiseDevice} />
          <Route exact path={`${url}${RouteLink.CATALOG}`} component={RoutePage.Catalog} />
          <Route exact path={`${url}${RouteLink.CATALOG}/:id`} component={RoutePage.CatalogDetailList} />
          <Route exact path={`${url}${RouteLink.CATALOG}/:catalogId/create`} component={RoutePage.CatalogListDetails} />
          <Route exact path={`${url}${RouteLink.CATALOG}/:catalogId/details/:id`} component={RoutePage.CatalogListDetails} />
          <Route exact path={`${url}${RouteLink.SRN}`} component={RoutePage.Srn} />
          <Route exact path={`${url}${RouteLink.PRN}`} component={RoutePage.Prn} />
          <Route exact path={`${url}${RouteLink.PSR}`} component={RoutePage.Psr} />
          <Route exact path={`${url}${RouteLink.ATTENDANCE_REPORT}`} component={RoutePage.AttendanceReport} />
          <Route exact path={`${url}${RouteLink.PRODUCTIVITY}`} component={RoutePage.Productivity} />
          <Route exact path={`${url}${RouteLink.DISTRIBUTION_COVERAGE}`} component={RoutePage.distributionCoverage} />
          <Route exact path={`${url}${RouteLink.PRN_CREATE}`} component={RoutePage.CreatePrn} />
          <Route exact path={`${url}${RouteLink.PRN_DETAILS}/:id`} component={RoutePage.PrnDetails} />
          <Route exact path={`${url}${RouteLink.SRN_CREATE}`} component={RoutePage.CreateSrn} />
          <Route exact path={`${url}${RouteLink.SRN_DETAILS}/:id`} component={RoutePage.SrnDetails} />
          <Route exact path={`${url}${RouteLink.LEAVE_MANAGEMENT}`} component={RoutePage.LeaveManagement} />
          <Route exact path={`${url}${RouteLink.CALL_ROSTER}`} component={RoutePage.CallRoster} />
          <Route exact path={`${url}${RouteLink.SBD}`} component={RoutePage.Sbd} />
          <Route exact path={`${url}${RouteLink.SBD_DETAILS}/:id`} component={RoutePage.SbdDetails} />
          <Route exact path={`${url}${RouteLink.FOCUSED_SKU}`} component={RoutePage.FocusedSku} />
          <Route exact path={`${url}${RouteLink.FOCUSED_SKU_DETAILS}/:id`} component={RoutePage.FocusedSkuDetails} />
          <Route exact path={`${url}${RouteLink.OPENING_BALANCE}`} component={RoutePage.OpeningBalance} />
          <Route exact path={`${url}${RouteLink.STOCK_ADJUSTMENT}`} component={RoutePage.StockAdjustment} />
          <Route exact path={`${url}${RouteLink.SUB_D_ASSORTMENT}`} component={RoutePage.SubDAssortment} />
          <Route exact path={`${url}${RouteLink.SUB_D_ASSORTMENT_DETAILS}/:id`} component={RoutePage.SubDAssortmentDetails} />
          <Route exact path={`${url}${RouteLink.CHANNEL_ASSORTMENT}`} component={RoutePage.ChannelAssortment} />
          <Route exact path={`${url}${RouteLink.CHANNEL_ASSORTMENT_DETAILS}/:id`} component={RoutePage.ChannelAssortmentDetails} />
          <Route exact path={`${url}${RouteLink.REPLENISHMENT}`} component={RoutePage.Replenishment} />
          <Route exact path={`${url}${RouteLink.REPLENISHMENT_DETAILS}/:id`} component={RoutePage.ReplenishmentDetails} />
          <Route exact path={`${url}${RouteLink.SUBD_STOCK_AVAILABILITY}`} component={RoutePage.SubDStockAvailability} />
          <Route exact path={`${url}${RouteLink.SUBD_REPLENISHMENT_REPORT}`} component={RoutePage.SubDReplenishmentReport} />
          <Route exact path={`${url}${RouteLink.STOCK_REPLACEMENT_REPORT}`} component={RoutePage.StockReplacementReport} />
          <Route exact path={`${url}${RouteLink.ARC}`} component={Main} />
          <Route exact path={`${url}${RouteLink.TARGET_TOOLS}`} component={TTMain} />
          <Route exact path={`${url}${RouteLink.ASM_REPORT}`} component={RoutePage.ASMReport} />
          <Route exact path={`${url}${RouteLink.STL_REPORT}`} component={RoutePage.STLReport} />
          <Route exact path={`${url}${RouteLink.DSE_REPORT}`} component={RoutePage.DSEReport} />
          <Route exact path={`${url}${RouteLink.USER_CLUSTER}`} component={RoutePage.UserCluster} />
          <Route exact path={`${url}image-upload`} component={RoutePage.ImageUploadTestPage} />
          <Route exact path={`${url}${RouteLink.PRODUCTIVE_COVERAGE}`} component={RoutePage.ProductiveCoverage} />
          <Route exact path={`${url}${RouteLink.PRODUCTIVE_COVERAGE_DETAIL}/:id`} component={RoutePage.ProductiveCoverageDetails} />
          <Route exact path={`${url}${RouteLink.SKU_COVERAGE}`} component={RoutePage.SKUCoverage} />
          <Route exact path={`${url}${RouteLink.SKU_DISTRIBUTION}/:id`} component={RoutePage.SKUDistribution} />
          <Route exact path={`${url}${RouteLink.SKU_DISTRIBUTION_DETAILS}/:id`} component={RoutePage.SKUDistributionDetails} />
          <Route exact path={`${url}${RouteLink.BRAND_COVERAGE}`} component={RoutePage.BrandCoverage} />
          <Route exact path={`${url}${RouteLink.CALLAGE}`} component={RoutePage.Callage} />
          <Route exact path={`${url}${RouteLink.TARGET_ACHIEVEMENT_REPORT}`} component={RoutePage.TargetAchievementReport} />
          <Route exact path={`${url}testSelect`} component={RoutePage.testSelect} />
          <Route exact path={`${url}${RouteLink.RECEIPTS}`} component={RoutePage.Receipt} />
          <Route exact path={`${url}${RouteLink.RECEIPTS}/create`} component={RoutePage.ReceiptDetails} />
          <Route exact path={`${url}${RouteLink.CHEQUES}`} component={RoutePage.Cheques} />
          <Route exact path={`${url}${RouteLink.CASH}`} component={RoutePage.Cash} />
          <Route exact path={`${url}${RouteLink.POST_DATED_CHEQUES}`} component={RoutePage.PostDatedCheques} />
          <Route exact path={`${url}${RouteLink.PFD_SALES_TARGET}`} component={RoutePage.PFDSalesTarget} />
          <Route exact path={`${url}${RouteLink.CLASSIFICATION}`} component={RoutePage.Classification} />
          <Route exact path={`${url}${RouteLink.OUTSTANDINGS}`} component={RoutePage.Outstandings} />
          <Route exact path={`${url}${RouteLink.ORDER_SUMMARY_REPORT}`} component={RoutePage.OrderSummaryReport} />
          <Route exact path={`${url}${RouteLink.BRAND_COVERAGE}/:id`} component={RoutePage.BrandDistribution} />
          <Route exact path={`${url}${RouteLink.BRAND_COVERAGE}/:id/details/:id`} component={RoutePage.BrandDistributionDetails} />
          <Route exact path={`${url}${RouteLink.PROFILE}`} component={RoutePage.UserProfile} />
          <Route exact path={`${url}${RouteLink.ATTENDANCE_HISTORY_MAP}/:id`} component={RoutePage.AttendanceLocationMap} />
          <Route exact path={`${url}${RouteLink.ATTENDANCE_REPORT_MAP}/:id`} component={RoutePage.AttendanceReportMap} />
          <Route
            exact
            path={`${url}${RouteLink.ERR_BASE}/:id`}
            component={ErrorPage}
          />
          <Route render={() => <Redirect to={`${url}${RouteLink.ERR_BASE}/404`} />} />
        </Switch>
      </BaseContext.Provider>
    </div>
  </BaseWrapper>
);

export default Base;
