import React, { Fragment } from 'react';
import {
  Tab, TabList, TabPanel, Tabs,
} from 'react-tabs';
import { PanelStyled } from '../../../common/configuration';
import PageHeader from '../../../base/PageHeader';
import { title, breadCrumbConfig, filterConfig } from './config';
import { Badge } from '../../../../components';
import Pending from './pending';
import Received from './received';
import { DOWNLOAD_DOMAIN } from '../../../../data/enums/GraphQL';
import withErrorAndLoadingScreen from '../../../../utils/composition/withLoading';


const View = ({ ...props }) => {
  const {
    activeTab,
    onTabChange,
    tableConfig,
    columnSelectChange,
    pending,
    receive,
    queryParameters,
    pagination, handleFilterChange,
    basePaginationService,
    controlDisplay,
    onButtonClick,
    handleDownloadClick,
    permission,
  } = props;
  return (
    <Fragment>
      <div className="section-header">
        <PanelStyled>
          <PageHeader
            breadCrumb={breadCrumbConfig}
            config={{
              title,
              create: false,
              download: true,
              filter: true,
              upload: false,
              date: true,
              search: true,
              columSelect: true,
            }}
            downloadConfig={{
              domain: DOWNLOAD_DOMAIN.RECEIPT,
            }}
            filter={{
              date: queryParameters.date,
              menuList: filterConfig.menu,
              onFilterChange: handleFilterChange,
            }}
            labelConfig={tableConfig}
            queryParameters={queryParameters}
            columnSelectChange={columnSelectChange}
            controlDisplay={controlDisplay}
            resetFilter={basePaginationService.resetFilter}
            clearSearchText={basePaginationService.clearSearchText}
            handleSearchChange={basePaginationService.handleSearchInputChange}
            handleDateRangeChange={basePaginationService.handleDateRangeChange}
            handleDownloadClick={handleDownloadClick}
          />
        </PanelStyled>
      </div>
      <div className="section-content section-tab cash-tab">
        <Tabs selectedIndex={activeTab} onSelect={tabIndex => onTabChange(tabIndex)}>
          <TabList>
            <Tab>
              <span className="tab-label">Pending</span>
              <Badge light label={pending.total} />
            </Tab>
            <Tab>
              <span className="tab-label">Received</span>
              <Badge light label={receive.total} />
            </Tab>
          </TabList>
          <div className="tab-container">
            <TabPanel>
              <section id="pending-tab">
                <div id="detail-tab">
                  <div className="create-ui-inner tab-less-height" style={{ padding: '0' }}>
                    <Pending
                      tableConfig={tableConfig}
                      pendings={pending}
                      pagination={pagination}
                      onPageChange={basePaginationService.onPageChange}
                      fetchAllData={onButtonClick}
                      permission={permission}
                    />
                  </div>
                </div>
              </section>
            </TabPanel>
            <TabPanel>
              <section id="received-tab">
                <div id="detail-tab">
                  <div className="create-ui-inner tab-less-height" style={{ padding: '0' }}>
                    <Received
                      tableConfig={tableConfig}
                      receiveds={receive}
                      pagination={pagination}
                      onPageChange={basePaginationService.onPageChange}
                    />
                  </div>
                </div>

              </section>
            </TabPanel>
          </div>
        </Tabs>
      </div>
    </Fragment>
  );
};

export default withErrorAndLoadingScreen(View);
