import { EVENT_OPERATION } from '../../data/enums/EventOperation';
import { JWT } from '../../environment';
import { voucherEncoder } from '../../utils/transformation';
import { getUserBillingCredentials } from '../../data/services/user';
import { newWindow } from '../../utils/newWindow';
import { isObjectEmpty } from '../../utils/objectPrototypes';
import { VIEW_LIST } from '../reports/productive-coverage/config';
import { COVERAGE_TYPE } from '../../data/enums/GraphQL';
import { DOMAIN } from '../../data/enums/config';
import { SORTING } from '../../data/enums';

const isConfirmationType = type => type === EVENT_OPERATION.DELETE
  || type === EVENT_OPERATION.BULK_DELETE
  || type === EVENT_OPERATION.UPDATE_STATUS || type === EVENT_OPERATION.SAVE;

const getCheckboxStatus = (selectedList = [], id = '') => (selectedList.includes(id));

const getFlattenedOrders = orders => ((orders.map(order => order.Lines.map(item => item)) || []).flat() || []);

const isError = (response = {}) => (response.errors && response.errors.length > 0);

const getAllIds = list => (list.map(item => item.id));

const errorMessageParser = (err = {}) => {
  // can be array
  // "graphQLErrors", "networkError", "message", "extraInfo"

  if (err.graphQLErrors && err.graphQLErrors.length > 0) {
    return err.graphQLErrors[0].message;
  }

  if (err.networkError) {
    return err.networkError.message;
  }

  if (err.message) {
    return err.message;
  }

  return 'Error in operation. Please try again later';
};

const imageSliderListFormatter = (list, urlPrefix = '', style = { w: 500, h: 500 }) => list.map(imageObj => ({
  thumbnail: imageObj.thumbnail ? `${urlPrefix}${imageObj.thumbnail}` : '',
  w: imageObj.w ? imageObj.w : style.w,
  h: imageObj.h ? imageObj.h : style.h,
  title: imageObj.title || '',
  src: imageObj.src ? `${urlPrefix}${imageObj.src}` : '',
}));

const imageSliderListFormatterWithSrc = (list, urlPrefix = '', style = { w: 500, h: 500 }) => list.map((imgSrc, index) => ({
  thumbnail: `${urlPrefix}${imgSrc}`,
  w: style.w,
  h: style.h,
  title: `${index}`,
  src: `${urlPrefix}${imgSrc}`,
}));

const updateSkuUsageDates = (state, stateUpdater, values) => {
  state.manufactureDate = values.manufacture;
  state.expiryDate = values.expiry;
  stateUpdater(state);
};

const handleSkuBatchSelect = (value, skuBatchList, state, stateUpdater,
  rateUpdater = () => null) => {
  const selectedBatch = skuBatchList.filter(({ id }) => id === value)[0];
  const usageDate = {
    manufacture: selectedBatch.batchDetails.usageDate
      ? selectedBatch.batchDetails.usageDate.manufacture : new Date().toISOString()
        .slice(0, 10),
    expiry: selectedBatch.batchDetails.usageDate
      ? selectedBatch.batchDetails.usageDate.expiry : new Date().toISOString()
        .slice(0, 10),
  };
  updateSkuUsageDates(state, stateUpdater, usageDate);
  rateUpdater(state, stateUpdater, selectedBatch);
};

const storeInLocalStorage = (key, data, domain) => {
  localStorage.setItem(`${domain}-${key}`, JSON.stringify(data));
};

const fetchFromLocalStorage = (key, domain) => JSON.parse(localStorage.getItem(`${domain}-${key}`));

const getPrintUrl = ({
  module, schema, invoiceNumber, firstCopy, userId,
}) => {
  if (module === DOMAIN.SALES) return (`${schema}/sales-invoice-print-details/${invoiceNumber}/${userId}/${firstCopy}`);

  return (`${schema}/sales-return-print-details/${invoiceNumber}/${userId}`);
};

const handlePrint = (data = { distributorServices: {} }, callBack = () => null) => {
  const invoiceNumber = voucherEncoder(data.invoiceNumber);
  const schema = data.distributorServices.billing.url || 'https://qa.billing.rosia.one';
  const userId = getUserBillingCredentials().userId || 1;
  const url = getPrintUrl({
    ...data, schema, invoiceNumber, userId,
  });
  newWindow(url);
  callBack();
};

const getDistributorServiceStatus = (distributor, service) => (distributor.servicesUsed
  && distributor.servicesUsed[service].status)
  || false;

const getDistributorFilteredViewList = (userInfo, viewList = VIEW_LIST) => {
  if (userInfo.Distributor && userInfo.Distributor.length > 0) {
    return viewList.filter(item => item.label !== COVERAGE_TYPE.SUB_D);
  }

  return viewList;
};

const controlFilterDisplay = (filterConfig) => {
  if (!isObjectEmpty(filterConfig.menu)) {
    return true;
  }
  return false;
};

const headerLabelConfig = JSON.parse(localStorage.getItem(JWT.LOCAL_STORAGE.LABEL.HEADER)) || {};

const getSortValueAfterValueCheck = sort => (sort.order && sort.order !== SORTING.NO_SORT ? [{ sortBy: sort.label, order: sort.order }] : {});

const getFromLocalStorage = (key) => {
  if (localStorage.getItem(key)) {
    return JSON.parse(localStorage.getItem(key));
  }
};

export {
  isConfirmationType, getCheckboxStatus, getFlattenedOrders, isError, errorMessageParser,
  imageSliderListFormatter, updateSkuUsageDates, handleSkuBatchSelect, storeInLocalStorage,
  fetchFromLocalStorage, getAllIds, imageSliderListFormatterWithSrc, handlePrint,
  getDistributorServiceStatus, controlFilterDisplay, headerLabelConfig, getDistributorFilteredViewList, getSortValueAfterValueCheck, getFromLocalStorage,
};
