import { ApolloLink } from 'apollo-link';
import { withClientState } from 'apollo-link-state';
import * as config from '../config';
import httpWsLink from './http-ws.link';
import removeTypeNameLink from './typeName.link';
import { errorLink, afterwareLink } from './error.link';
import authMiddleWareLink from './auth.middleware.link';


const stateLink = withClientState({
  cache: config.cache,
  // defaults,
  // resolvers,
});

const links = config.useLocalStorage
  ? [errorLink, stateLink, afterwareLink, authMiddleWareLink, removeTypeNameLink, httpWsLink]
  : [errorLink, stateLink, httpWsLink];

const link = ApolloLink.from(links);

export default link;

/** export {
  stateLink,
  errorLink,
  afterwareLink,
  removeTypeNameLink,
  authMiddleWareLink,
}; */
