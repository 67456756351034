import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LabelStyled, MessageStyled } from '../Input/TextStyled';
import { Icon, Input, Button } from '../index';
import Menu from '../Menu';
import { EMPTY as EMPTY_ERROR } from '../../data/enums/ErrorMessage';
import CustomSelectBtnStyle from './CustomSelectBtnStyle';

const propTypes = {
  placeholder: PropTypes.string,
  searchText: PropTypes.string,
  labelContent: PropTypes.string,
  errorMessage: PropTypes.string,
  enableValidation: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.instanceOf(Object),
  enableErrorDisplay: PropTypes.bool,
  dropDownValueKey: PropTypes.string,
  onChange: PropTypes.func,
  dropDownDisplayKey: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  data: PropTypes.instanceOf(Object),
};

const defaultProps = {
  searchText: '',
  placeholder: '',
  options: [],
  labelContent: '',
  value: '',
  dropDownValueKey: 'name',
  dropDownDisplayKey: 'name',
  enableValidation: false,
  enableErrorDisplay: false,
  errorMessage: EMPTY_ERROR,
  onChange: () => null,
  data: '',
};

class CustomSelectWithBtn extends Component {
 getValidState = () => {
   const { value } = this.props;

   return value.length > 0;
 };

 render() {
   const {
     labelContent, options, placeholder, name, enableValidation,
     enableErrorDisplay, iconName, errorMessage, disabled, handleIconClick, dropDownDisplayKey, data, onChange,
   } = this.props;
   const valid = this.getValidState();
   const displayError = enableValidation && enableErrorDisplay && !valid;
   const menuHeader = (
     <CustomSelectBtnStyle>
       {
       labelContent !== undefined ? <LabelStyled>{labelContent}</LabelStyled> : ''
        }
       <Input
         name={name}
         type="text"
         value={data.customer.name}
         placeholder={placeholder}
         disabled={disabled}
         enableValidation={enableValidation}
         enableErrorDisplay={enableErrorDisplay}
         errorMessage={errorMessage}
       />
       {
          options.length > 0 && (
            <span className="dropdown">
              <Icon iconName="caret-bottom" />
            </span>
          )
        }
     </CustomSelectBtnStyle>
   );

   return (
     <CustomSelectBtnStyle>
       <div className="customselect-pop-up">
         <Menu
           block
           header={menuHeader}
           hideOnClick
           disabled={disabled}
           disableBody={disabled}
         >
           { options.length > 0 && (
             <>
               <div className="autocomplete ">
                 {
                options
                  .map(dropDown => (
                    <div
                      onClick={() => onChange(dropDown)}
                    >
                      <span>
                        {
                        dropDown[dropDownDisplayKey]
                      }
                      </span>
                    </div>

                  ))
                  }
               </div>
             </>
           )}
           <div className="create-btn">
             <Button small primary onClick={() => handleIconClick()}>Create</Button>
           </div>
         </Menu>
         {
          displayError && (
            <MessageStyled>
              {
                iconName !== undefined ? <Icon iconName={iconName} /> : ''
              }
              <span className="error-message">
                <Icon iconName="exclamation-full" />
                {errorMessage}
              </span>
            </MessageStyled>
          )
        }
       </div>
     </CustomSelectBtnStyle>
   );
 }
}

CustomSelectWithBtn.propTypes = propTypes;

CustomSelectWithBtn.defaultProps = defaultProps;

export default CustomSelectWithBtn;
