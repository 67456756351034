import React, { Component } from 'react';
import MultiDropDownStyled from './MultiDropDownStyled';
import { Icon } from '../../../../../components';
import downloadRoutes from '../../services/downloadRoutes'

class MultiDropDown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
    };

    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  showMenu(event) {
    event.preventDefault();

    this.setState({ showMenu: true }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  }

  closeMenu() {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  }

  render() {
    return (
      <MultiDropDownStyled>
        <span className="dropdown">
          <button type="button" className="borderless ellipse" onClick={this.showMenu}><Icon className="simple-popup-actions" iconName="ellipsis-v" /></button>
          {
            this.state.showMenu
              ? (
                <ul className="menu">
                  <li onClick={() => this.props.saveRoutes()}>
                    <img src=" /image/icons/save.svg" alt="save-file" />
                    Save
                              </li>
                  <li onClick={() => downloadRoutes(this.props.routesList)}>
                    <Icon className="download" iconName="download" />
                    <span>Export .csv</span>
                  </li>
                </ul>
              )
              : (null)
          }
        </span>
      </MultiDropDownStyled>
    );
  }
}
export default MultiDropDown;
