import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CheckBox } from '../../../components';
import { tableHeaderWithCheckBox } from '../../../utils/paginatedListUtils';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';

const labelMappings = {
  checkbox: 'checkbox',
  id: 'id',
  title: 'title',
  outlet_count: 'outlet_count',
  visit_category: 'visit_category',
  visit_frequency: 'visit_frequency',
  visit_type: 'visit_type',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.checkbox,
    title: '',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.id,
    title: 'ID',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.title,
    title: 'Title',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.outlet_count,
    title: 'Outlets',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.visit_category,
    title: 'Visit Category',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.visit_frequency,
    title: 'Visit Frequency',
    show: true,
  },
  {
    id: 7,
    label: labelMappings.visit_type,
    title: 'Visit Type',
    show: true,
  },
];

const TableHeader = ({
  dataListLength, checkedListLength, primaryCheckboxHandler, permission,
}) => tableHeaderWithCheckBox(
  dataListLength,
  checkedListLength,
  primaryCheckboxHandler,
  permission,
  labelConfig,
  labelMappings,
);

const returnTableBody = (
  data,
  checkedList,
  onSecondaryCheckBoxClick,
  permission,
) => {
  const table = {};
  table[labelMappings.checkbox] = permission.delete && (
    <td onClick={e => e.stopPropagation()}>
      <CheckBox
        checked={checkedList.includes(data.id)}
        onCheckBoxClick={() => onSecondaryCheckBoxClick(data.id)}
      />
    </td>
  );
  table[labelMappings.id] = (<td key={`${data.id}-id`}>{data.id}</td>
  );
  table[labelMappings.title] = (
    <td key={`${data.id}-title`}>{data.title}</td>
  );
  table[labelMappings.outlet_count] = (
    <td key={`${data.id}-outlet_count`}>
      {data.retailOutletCount}
    </td>);
  table[labelMappings.visit_category] = (
    <td key={`cash-${data.id}-visit_category`}>
      {data.VisitCategory.title}
    </td>
  );
  table[labelMappings.visit_frequency] = (
    <td key={`cash-${data.id}-visit_frequency`}>
      {data.VisitFrequency.title}
    </td>
  );
  table[labelMappings.visit_type] = (
    <td key={`cash-${data.id}-visit_type`}>
      {data.VisitType.title.replace(/_/g, ' ')}
    </td>
  );

  return table;
};

const TableBody = ({
  data,
  checkedList,
  secondaryCheckboxHandler,
  permission,
  onTableBodyClick,
}) => {
  // Expanding/Dragging and collapsing table body.
  // color based upon table body
  // tooltip if it is sorted.
  // active className

  const tableBody = returnTableBody(
    data,
    checkedList,
    secondaryCheckboxHandler,
    permission,
    onTableBodyClick,
  );

  return (
    <tr
      key={data.id}
      onClick={() => onTableBodyClick(EVENT_OPERATION.DETAILS, data.id, data.title)
        }
      style={{ cursor: 'pointer' }}
    >
      <Link to={`route/details/${data.id}`}>
        {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
      </Link>
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  checkedList: PropTypes.instanceOf(Array),
};

TableBody.defaultProps = {
  checkedList: [],
};
export {
  TableHeader,
  TableBody,
};
