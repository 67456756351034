import gql from 'graphql-tag';

const GET_ATTENDANCE_HISTORY = gql`
    query listAttendanceHistory($offset: Int, $limit: Int, $filter: FilterInput){
        listAttendanceHistory(offset: $offset, limit: $limit, filter: $filter){
            rows{
              id
              user_id
              login_time
              location{
                latitude
                longitude
              }
              images{
                image
                thumbnail
                created_at
              }
              logout_time
              User{
                fullName
              }
            }
              count
          }
    }
`;

const query = {
  getAttendanceHistory: GET_ATTENDANCE_HISTORY,
};

export { query };
