import PropTypes from 'prop-types';
import React, { Component } from 'react';
import PageHeader from '../../base/PageHeader';
import { PanelStyled } from '../../common/configuration';
import View from './View';
import {
  breadCrumbConfig,
} from '../config';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { NamedDateFilter } from '../../../components';
import { getCurrentDay } from '../../../utils/date';
import CallageWrapper from './CallageWrapper';

const propTypes = {
  getCallage: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  serverResponseWaiting: false,
};
class Callage extends Component {
  constructor(props) {
    super(props);
    const dateRange = {
      start: getCurrentDay(),
      end: getCurrentDay(),
    };
    this.state = {
      data: [],
      dateRange,
      labelName: 'Today',
    };

    this.getData = () => {
      const { data } = this.state;
      return data;
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: false };
  }

  componentDidMount() {
    this.getList();
  }

  getList = () => {
    const { getCallage, displayAlert } = this.props;
    const { dateRange } = this.state;
    getCallage({
      filter: {
        dateRange,
      },
    }, {
      handleSuccess: (response) => {
        this.setState({
          data: response.data.getUserWiseCallage || [],
        });
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  }

  onDropDownChange = (event) => {
    const { label, value } = event;
    this.setState({
      labelName: label,
      dateRange: value,
    }, () => {
      this.getList();
    });
  }

  render() {
    const {
      data, dateRange, labelName,
    } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <CallageWrapper>
        <div className="section-header">
          <PanelStyled>
            <PageHeader
              breadCrumb={breadCrumbConfig}
              config={{
                title: 'Callage',
                create: false,
                download: false,
                filter: false,
                search: false,
                date: false,
              }}
            >
              <NamedDateFilter
                onDropDownChange={event => this.onDropDownChange(event)}
                labelName={labelName}
              />
            </PageHeader>
          </PanelStyled>
        </div>
        <div className="section-content">
          <View
            data={data}
            loading={serverResponseWaiting}
          />
        </div>
      </CallageWrapper>
    );
  }
}

Callage.propTypes = propTypes;
Callage.defaultProps = defaultProps;

export default withAlert()(Callage);
