import React, { Fragment } from 'react';
import LoadingStyled from './LoadingStyled';

const Loading = ({
  children, classNames, title, ...otherProps
}) => (
  <Fragment>
    <LoadingStyled title={title} {...otherProps} className="loading-main">
      <div className="loading-wrap">
        <div className="spinner-wrap">
          <div className="spinner" />
        </div>
        <p>{title}</p>
      </div>
    </LoadingStyled>
  </Fragment>
);

export default Loading;
