import styled from 'styled-components';

const FormContentStyled = styled.div`
margin-bottom:32px;
&:last-child{
  margin-bottom:0;
}
&>h3{
  margin:0;
  font-size:14px;
  line-height:21px;
  font-weight:400;
  color:#6B6C7E;
  padding:4px;
  border-bottom:1px solid #CDCED9;
  /* width:524px; */
  width:48%;
  &.w-100{
    width:100%;
    padding-top:8px;
  }
    &.w-60{
    width:740px;
    width:67%;
  }
}
.form-input-wrap{
  width:48%;
  padding:24px 0;
  &.padding-top-zero{
    padding-top:0;
  }
   &.padding-bottom-zero{
    padding-bottom:0;
  }
  .margin-bottom-zero{
    margin-bottom:0 !important;
  }
  /* holiday-status-checkbox start */
    &.w-60{
      width:740px;
      width:67%;
      .checkbox-list{
        .checkbox{
           margin-right:12px;
          label{
            margin:0;
            display:flex;
            flex-wrap:wrap;
           &>span{
             padding-left:0;
             &>span{
               margin-left:7px;
             }
           }
          }
          &:last-child{
            margin-right:0;
          }
        }
      }
    }

  /* w-100 is 100% styling  */
    &.w-100{
      width:100%;
      padding-bottom:0;
      .form-select-input,
      .form-input{
            label{
              width:calc(166px - 10px);
              width:calc(31% - 10px);
              margin-right:10px;
            }
            .select-css,
              input{
              width:calc(100% - 166px);
              width:calc(100% - 31%);
              flex:1;
            }
        }

    /* channel categories list only styling */
    .category-wrap{
      display:flex;
      flex-wrap:wrap;
      .form-input{
          width:48.57%;
          flex:unset;
          margin-bottom:4px;
          &:last-child{
            margin-bottom:0;
          }
      }
    }
    /* channel categories list only styling */
    }
  /* for swtich only styling */
  .form-select-input,
  .form-input{
    display:flex;
    align-items:center;
    justify-content:space-between;
    flex-wrap:wrap;
    width:100%;
    margin-bottom:24px;
    &.switch{
      justify-content:unset;
      & + span{
        font-size: 10px;
        color: #6B6C7E;
        line-height: 10px;
        margin-top: -20px;
        display: block;
        width:32%;
      }
    }
    &:last-child{
      margin-bottom:0;
    }
      .form-error{
        width:100%;
        text-align:right;
      }
    label{
      font-size:14px;
      line-height:21px;
      color:#272833;
      font-weight:500;
      width:262px;
      width:calc(48.57% - 10px );
      margin:0;
      margin-right:10px;
    }

    /* checkbox only  start */
    .checkbox{
      width:calc(100% - 48.57%);
     flex:1;
     display:flex;
     label{
       min-width:unset;
       width:unset;
       margin-right:24px;
       input{
         width:unset;
         margin:8px;
       }
       &:last-child{
         margin-right:0;
       }
     }
    }
     /* checkbox only  end*/
    .select-css,
    input{
     width:calc(100% - 48.57%);
     flex:1;
    align-self:end;
      border:1px solid #E7E7ED;
      background-color:#F1F2F5;
      border-radius:4px;
      .zindex-2__control{
        border:0;
        background-color:#F1F2F5;
      }
    }
 
/* for upload section only  */
    .upload-input{
      border-radius:4px;
      width:51%;
      display:flex;
      align-items:center;
      font-size:16px;
      line-height:24px;
      border-radius:4px;
      background-color:#F1F2F5;
      button{
        color:#6B6C7E;
        display:flex;
        align-items:center;
        font-weight:500;
        background-color:#FFFFFF;
        border:1px solid #CDCED9;
        border-right:0;
        border-radius:4px 0  0 4px;;
        span{
          margin-right:14px;
        }
      }
      .no-file{
      padding-left:16px;
      height:40px;
      line-height:40px;
      flex:1;
      color:#272833;
      border:1px solid #E7E7ED;
      border-radius: 0 4px 4px 0;
      display:flex;
      align-items:center;
      span{
        margin:8px 16px  8px 0;
        padding:4px 8px;
        background-color:#ffffff;
        border:1px solid #CDCED9;
        font-size:12px;
        line-height:15px;
        color:#6B6C7E; 
        flex:1;
        text-transform:uppercase;
        font-weight:500;
        border-radius:2px ;
      }
      }
    }
    
  }
}

`;
export default FormContentStyled;
