import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../../../../components';

const propTypes = {
  routeList: PropTypes.instanceOf(Array),
  user: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
  }),
  removeRosterLine: PropTypes.func,
  onEditIconClick: PropTypes.func,
  name: PropTypes.string.required,
};

const defaultProps = {
  routeList: [],
  user: {
    id: null,
    title: '',
  },
  removeRosterLine: () => null,
  onEditIconClick: () => null,
};

const getRouteViewClassName = (route) => {
  return route.creationIdentifier ? `roster-route-view status_${route.visitFrequencyId}`: 'roster-route-view status_once';
};

const RoutesView = (
  {
    name,
    date,
    routeList = [],
    user,
    removeRosterLine,
    onEditIconClick,
  },
) => {
  return (
    <Fragment>
      {
        routeList.map(route => (
          <div className={getRouteViewClassName(route)}>
            <div>
              <div>
                <span className="name">{route.routeTitle}</span>
                {routeList.length > 1 ? null : (<span>{route.outletCount} Outlets </span>) }
              </div>
              <div className="action-btn">
                <div>
                  <button className="dot-wrap">
                    <span className="dot"></span>
                    <span className="dot"></span>
                    <span className="dot"></span>
                  </button>
                  <div className="actions">
                    <div onClick={() => onEditIconClick('edit', {
                      ...route,
                      selectedRouteList: routeList,
                      date,
                      user,
                    })}>
                      <Icon iconName="pencil" />
                      <span className="text">Edit</span>
                    </div>
                    <div onClick={() => onEditIconClick('delete', {
                      ...route,
                      selectedRouteList: routeList,
                      date,
                      user,
                    })}>
                      <Icon iconName="trash" />
                      <span className="text">Delete</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      }
    </Fragment>
  );
};


RoutesView.propTypes = propTypes;

RoutesView.defaultProps = defaultProps;

export default RoutesView;
