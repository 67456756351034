import React from 'react';
import PropTypes from 'prop-types';
import { tableData } from '../../../utils/paginatedListUtils';
import { fixedFloatAndCommas } from '../../../utils/conversion';

const labelMappings = {
  distributor: 'distributor',
  brand: 'brand',
  skuFamily: 'skuFamily',
  upc: 'upc',
  perDaysSales: 'perDaysSales',
  currentStock: 'currentStock',
  doh: 'doh',
  availability: 'availability',
  sufficiency: 'sufficiency',
  overstock: 'overstock',
  availabilityScore: 'availabilityScore',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.distributor,
    title: 'Distributor',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.brand,
    title: 'Brand',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.skuFamily,
    title: 'SKU Family',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.upc,
    title: 'UPC (Units Per Sales)',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.perDaysSales,
    title: 'Per Days Sales',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.currentStock,
    title: 'Current Stock',
    show: true,
  }, {
    id: 7,
    label: labelMappings.doh,
    title: 'DOH (Days of Stock Holding)',
    show: true,
  },
  {
    id: 8,
    label: labelMappings.availability,
    title: 'Availability',
    show: true,
  },
  {
    id: 9,
    label: labelMappings.sufficiency,
    title: 'Sufficiency',
    show: true,
  },
  {
    id: 10,
    label: labelMappings.overstock,
    title: 'Overstock',
    show: true,
  },
  {
    id: 11,
    label: labelMappings.availabilityScore,
    title: 'Availability Score',
    show: true,
  },
];

const TableHeader = () => (
  <thead className="">
    <tr>
      {labelConfig.map(label => (label.show
        ? tableData(label.title, label.align, label.classNames) : null))}
    </tr>
  </thead>
);

const returnTableBody = (data) => {
  const table = {};

  table[labelMappings.distributor] = (
    <td key={`${data.id}-distributor`}>{data.distributor}</td>
  );
  table[labelMappings.brand] = (
    <td key={`${data.id}-brand`}>
      { data.brand }
    </td>
  );
  table[labelMappings.skuFamily] = (
    <td key={`${data.id}-skuFamily`}>
      {data.skuFamily}
    </td>
  );
  table[labelMappings.upc] = (
    <td key={`${data.id}-upc`} className="text-right">
      {fixedFloatAndCommas(data.upc)}
    </td>
  );
  table[labelMappings.perDaysSales] = (
    <td key={`${data.id}-perDaysSales`} className="text-right">
      {fixedFloatAndCommas(data.averageSales)}
    </td>
  );
  table[labelMappings.currentStock] = (
    <td key={`${data.id}-currentStock`} className="text-right">
      {fixedFloatAndCommas(data.currentStock)}
    </td>
  );
  table[labelMappings.doh] = (
    <td key={`${data.id}-doh`} className="text-right">
      {data.doh}
    </td>
  );
  table[labelMappings.availability] = (
    <td key={`${data.id}-availability`} className="text-right">
      {data.availability}
    </td>
  );
  table[labelMappings.sufficiency] = (
    <td key={`${data.id}-sufficiency`} className="text-right">
      {data.sufficiency}
    </td>
  );
  table[labelMappings.overstock] = (
    <td key={`${data.id}-overstock`} className="text-right">
      {data.overStock}
    </td>
  );
  table[labelMappings.availabilityScore] = (
    <td key={`${data.id}-availabilityScore`} className="text-right">
      {fixedFloatAndCommas(data.availabilityScore)}
    </td>
  );

  return table;
};

const TableBody = ({
  data,
}) => {
  // Expanding/Dragging and collapsing table body.
  // color based upon table body
  // tooltip if it is sorted.
  // active className
  const tableBody = returnTableBody(data);

  return (

    <tr key={`stockAvailability-${data.id}`}>
      {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
};

TableBody.defaultProps = {
  data: {},
};

export {
  TableHeader,
  TableBody,
};
