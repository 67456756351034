import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  breadCrumbConfig, filterConfig, title,
} from './config';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../../utils/api';
import { imageSliderListFormatter, isError } from '../../../common/HelperFunctions';
import { PanelStyled } from '../../../common/configuration';
import PageHeader from '../../../base/PageHeader';
import { getPermissionForPayment } from '../../../base/permission';
import * as queryService from '../../../base/query.service';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import View from './View';
import withAlert from '../../../../utils/composition/withAlert';
import { RECEIPTS } from '../../../../data/enums/Route';
import {
  labelConfig,
} from './tableConfig';
import { DOWNLOAD_DOMAIN } from '../../../../data/enums/GraphQL';

const propTypes = {};

const defaultProps = {};

class Receipts extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      data: {
        list: [],
        total: 1,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
      },
      tableInfo: labelConfig,
    };
    this.permission = getPermissionForPayment();
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      props.downloadReport,
      props.displayAlert,
    );
    this.getData = () => {
      const { data } = this.state;
      return data;
    };
  }

  componentDidMount() {
    // this.fetchOutlets();
    this.loadTableData();
  }

  setQueryParameters = (queryParams, callBack = () => null) => this.setState(
    { queryParameters: queryParams },
    callBack,
  );

  getQueryParameters = () => {
    const { queryParameters } = this.state;
    return queryParameters;
  };

  loadTableData = () => {
    const { data, queryParameters } = this.state;
    const { payments } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);

    payments({
      limit: queryParameters.pagination.limit,
      offset,
      filter: {
        filters: apiFilterProcessor(queryParameters.filter),
        dateRange: queryParameters.date,
        queryString: queryParameters.search,
      },
    },
    {
      handleSuccess: (response) => {
        const paymentsList = (response.data.payments && response.data.payments.rows) || [];
        data.total = (response.data.payments && response.data.payments.count) || 0;
        data.list = paymentsList.map((payment) => {
          if (payment.details.image) {
            payment.details.image = [...(imageSliderListFormatter(payment.details.image, '', { w: 2400, h: 1100 }) || [])];
          }
          return payment;
        });
        if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
        this.setState(data);
      },
      handleError: error => this.onAPIRequestFailure(error),
    });
  };


  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
    console.log(error);
  };


    onReceiptsClick = (type) => {
      const { history } = this.props;
      if (type === EVENT_OPERATION.CREATE) {
        history.push(`/${RECEIPTS}/create`);
      }
    };

    controlDisplay = (label, value) => {
      const { display } = this.state;
      display[label] = value;
      this.setState(display);
    };

  columnSelectChange = (data) => {
    this.setState({
      tableInfo: data,
    });
  }

  render() {
    const {
      data,
      queryParameters,
      tableInfo,
    } = this.state;
    const {
      serverResponseWaiting,
    } = this.props;
    return (
      <Fragment>
        <div className="return-create">
          <div className="section-header">
            <PanelStyled>
              <PageHeader
                breadCrumb={breadCrumbConfig}
                config={{
                  title,
                  create: this.permission.create,
                  download: true,
                  filter: true,
                  upload: false,
                  date: true,
                  search: true,
                  columSelect: true,
                }}
                downloadConfig={{
                  domain: DOWNLOAD_DOMAIN.RECEIPT,
                }}
                filter={{
                  date: queryParameters.date,
                  menuList: filterConfig.menu,
                  onFilterChange: this.basePaginationService.handleFilterChange,
                }}
                labelConfig={tableInfo}
                queryParameters={queryParameters}
                columnSelectChange={this.columnSelectChange}
                controlDisplay={this.controlDisplay}
                handleCreateClick={this.onReceiptsClick}
                resetFilter={this.basePaginationService.resetFilter}
                clearSearchText={this.basePaginationService.clearSearchText}
                handleSearchChange={this.basePaginationService.handleSearchInputChange}
                handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
                handleDownloadClick={this.basePaginationService.handleDownloadClick}
              />
            </PanelStyled>
          </div>
          <div className="section-content">
            <View
              data={data}
              pagination={queryParameters.pagination}
              loading={serverResponseWaiting}
              onPageChange={this.basePaginationService.onPageSelect}
              tableConfig={tableInfo}
            />
          </div>
        </div>
      </Fragment>);
  }
}

Receipts.propTypes = propTypes;

Receipts.defaultProps = defaultProps;

export default withAlert()(Receipts);
