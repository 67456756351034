import styled from 'styled-components';

const ImgUploadStyled = styled.div`
  .upload-img-add{
    display:flex;
    .big{
      background-color:#F1F2F5;
      border:1px solid #E7E7ED;
    }
    .attachment-item{
      margin:0;
      border:1px solid #E7E7ED;
      height:64px;
      width:64px;
      margin-left:16px;
      img{
        width:100%;
        height:100%;
        object-fit:cover;
        margin:0;
      }
    }
  }
`;
export default ImgUploadStyled;
