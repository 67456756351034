import React from 'react';
import PropTypes from 'prop-types';
import { Input, Form } from '../../../components';
import withLoading from '../../../utils/composition/withLoading';
import EVENT_OPERATION from '../../../data/enums/EventOperation';

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  }),
  handleInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  refsObj: PropTypes.instanceOf(Object),
  title: PropTypes.string,
  type: PropTypes.string,
};

const defaultProps = {
  data: {
    title: '',
  },
  refsObj: {
    title: '',
  },
  enableErrorDisplay: true,
  handleInputChange: () => null,
  title: '',
  type: '',
};

const TradeForm = ({ ...props }) => {
  const {
    data,
    refsObj,
    handleInputChange,
    enableErrorDisplay,
    title,
    type,
  } = props;

  return (
    <Form>
      <Input
        ref={ref => refsObj.title = ref}
        value={data.title}
        name="title"
        type="text"
        placeholder="Trade"
        enableErrorDisplay={enableErrorDisplay}
        enableValidation
        onChange={event => handleInputChange(event)}
        labelContent="Trade"
        disabled={type === EVENT_OPERATION.READ || title !== 'Trade'}
      />
      {(title === 'Channel' || title === 'Category') && (
        <Input
          ref={ref => refsObj.title = ref}
          value={data.Channels ? data.Channels.title : ''}
          name="title"
          type="text"
          placeholder="Channel"
          enableErrorDisplay={enableErrorDisplay}
          enableValidation
          onChange={event => handleInputChange(event, 'Channels')}
          labelContent="Channel"
          disabled={type === EVENT_OPERATION.READ || title === 'Category'}
        />
      )}
      { title === 'Category' && (
        <Input
          ref={ref => refsObj.title = ref}
          value={data.Categories.title}
          name="title"
          type="text"
          placeholder="Category"
          enableErrorDisplay={enableErrorDisplay}
          enableValidation
          onChange={event => handleInputChange(event, 'Categories')}
          labelContent="Category"
          disabled={type === EVENT_OPERATION.READ}
        />
      )}
    </Form>
  );
};

TradeForm.propTypes = propTypes;

TradeForm.defaultProps = defaultProps;

const ComposedTradeForm = withLoading(TradeForm);

export default ComposedTradeForm;
