import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import {
  Input, Form, Button,
} from '../../../../../../components';
import withLoading from '../../../../../../utils/composition/withLoading';
import { EMPTY } from '../../../../../../data/enums/ErrorMessage';
import { fixedFloatAndCommas } from '../../../../../../utils/conversion';
import Icon from '../../../../../../components/Icon';

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  handleInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  totalAllottedQuantity: PropTypes.number,
  refsObj: PropTypes.instanceOf(Object),
  message: PropTypes.instanceOf(Object),
  handleAddButtonClick: PropTypes.func,
  onCrossIconClick: PropTypes.func,
  enableAddOption: PropTypes.bool,

};

const defaultProps = {
  data: {
    name: '',
    description: '',
  },
  refsObj: {
    title: '',
  },
  enableErrorDisplay: true,
  handleInputChange: () => null,
  totalAllottedQuantity: 0,
  onCrossIconClick: () => null,
  enableAddOption: true,
  handleAddButtonClick: () => null,
  message: {},
};

const splitLinePriceFormatter = (sku, lineSummary) => {
  const netAmount = sku.quantity / lineSummary.quantity * lineSummary.amountDetails.netAmount;
  const promotionAmount = lineSummary.promotionPerUnitSKU * sku.quantity;

  return {
    promotionAmount: Number.isNaN(promotionAmount) ? 0 : promotionAmount,
    netAmount: Number.isNaN(netAmount) ? 0 : netAmount,
  };
};

const getErrorMsg = (data, sku, message) => {
  if (sku.quantity) {
    if (sku.quantity > data.quantity) return message.INVALID;
    if (sku.quantity < data.minimumSplitQuantity) return message.MINIMUM_SPLIT_QTY;
    return null;
  }
  return EMPTY;
};

const SplitForm = ({ ...props }) => {
  const {
    data,
    message,
    enableAddOption,
    enableErrorDisplay,
    handleInputChange,
    handleAddButtonClick,
    onCrossIconClick,
    totalAllottedQuantity,
  } = props;
  // minimum quantity is obtained from configuration.
  // maximum quantity is obtained from configuration.
  // also maximum quantity should not exceed the data.quantity
  return (

    <div className="split-form-container">
      <div className="split-header">
        <div className="stats">
          <Row>
            <Col md={2}>
              <div>
                <label>Total order</label>
                <span>{fixedFloatAndCommas(data.amountDetails.netAmount)}</span>
              </div>
            </Col>
            <Col md={8}>
              <div>
                <label>SKU</label>
                <span>{data.SKU.title}</span>
              </div>
            </Col>
            <Col md={2}>
              <div>
                <label>Quantity</label>
                <span>{data.quantity}</span>
              </div>
            </Col>
          </Row>

        </div>
        <div>
          <label>Promotion</label>
          <p>{data.Promotion.title}</p>
        </div>
      </div>
      <div className="split-body">
        <div className="table-wrap">
          <table>
            <thead>
              <tr>
                <th>SKU</th>
                <th>{`Quantity (${data.quantity - totalAllottedQuantity} left)`}</th>
                <th>Promotion</th>
                <th>Value</th>
                <th />
              </tr>
            </thead>

            <tbody>
              {
              data.splitList.map((sku, index) => {
                const price = splitLinePriceFormatter(sku, data);

                return (
                  <tr key={index}>
                    <td>{sku.title}</td>
                    <td>
                      <Form>
                        <Input
                          name="quantity"
                          type="number"
                          rule="isInt"
                          enableValidation
                          value={sku.quantity}
                          enableErrorDisplay={enableErrorDisplay || sku.displayError}
                          argument={{
                            min: data.minimumSplitQuantity || 0,
                            allow_leading_zeroes: false,
                          }}
                          ref={ref => sku.refsObj.quantity = ref}
                          onChange={event => handleInputChange(event, index)}
                          externalValidation={!sku.displayError}
                          errorMessage={(enableErrorDisplay || sku.displayError)
                            ? getErrorMsg(data, sku, message) : EMPTY}
                        />
                      </Form>
                    </td>
                    <td>
                      {
                        fixedFloatAndCommas(price.promotionAmount)
                      }
                    </td>
                    <td>
                      {
                        fixedFloatAndCommas(price.netAmount)
                      }
                    </td>
                    <td>
                      <span className="cursor-pointer" onClick={() => onCrossIconClick(index)}>
                        <Icon iconName="times" />
                      </span>
                    </td>
                  </tr>
                );
              })
            }
            </tbody>


          </table>
        </div>
        {/* <div> */}
        {/* Minimum split quantity: */}
        {/* {data.minimumSplitQuantity} */}
        {/* </div> */}
        <div className="add-btn">
          {
            enableAddOption
            && (
              <Button
                secondary
                iconName="plus"
                small
                onClick={() => handleAddButtonClick()
                }
                disabled={!enableAddOption}
              >
                <span>Add</span>
              </Button>
            )
          }
        </div>
      </div>
    </div>

  );
};

SplitForm.propTypes = propTypes;

SplitForm.defaultProps = defaultProps;

const SplitFormWithErrorAndLoading = withLoading(SplitForm);

export default SplitFormWithErrorAndLoading;
