import { EVENT_OPERATION } from '../../../data/enums/EventOperation';

const formConfig = {
  [EVENT_OPERATION.CREATE]: {
    title: 'Add Alias ',
  },
  [EVENT_OPERATION.READ]: {
    title: 'Alias ',
  },
};

export { formConfig };
