import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../components/Button';
import {
  DialogBody, DialogFooter, DialogHeader,
} from '../../../components/Dialog';
import Input from '../../../components/Input/Text';
import CustomSelect from '../../../components/CustomSelect';
import { refValidator } from '../../../utils/refGenerator';
import {
  formConfig,
  openingBalanceTypeData,
} from './config';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { EMPTY, INVALID } from '../../../data/enums/ErrorMessage';
import CustomAutoComplete from '../../../components/CustomAutoComplete';

class LedgerAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: {
        title: '',
        typeId: 1,
        panNo: null,
        address: '',
        openingBalance: '',
        openingBalanceType: '',
        creditDay: 0,
        creditLimit: 0,
        billingLedgerId: '',
      },
      enableErrorDisplay: false,
      formReference: formConfig.refsObj,
      billingCustomerList: [],
    };
  }

  onInputChange = (e, type) => {
    const { info } = this.state;
    this.setState({
      info: {
        ...info,
        [e.target.name]: type === 'number' ? parseInt(e.target.value, 10) : e.target.value,
      },
    });
  };

  checkBillingLedgerId = (id, object) => {
    if (id) {
      return object;
    }
    delete object.billingLedgerId;
    return object;
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { info } = this.state;
    const {
      onSubmit, createLedger, displayAlert, id, billingUser,
    } = this.props;
    const {
      formReference,
    } = this.state;

    const { panNo, ...formReferenceWithOutPAN } = formReference;

    const formValidation = info.panNo
      ? refValidator(formReference) : refValidator(formReferenceWithOutPAN);
    if (!formValidation) {
      this.setState({ enableErrorDisplay: true });
    } else {
      const { panNo, ...infoWithoutPanNo } = info;

      const formattedData = {
        outletId: id,
        ...infoWithoutPanNo,
      };

      const {
        outletId, title, address, billingLedgerId,
      } = formattedData;

      const aliasOutletData = {
        outletId,
        title,
        address,
        billingLedgerId,
      };

      if (panNo) {
        aliasOutletData.panNo = panNo;
        formattedData.panNo = panNo;
      }


      const inputData = billingUser ? this.checkBillingLedgerId(billingLedgerId, formattedData) : this.checkBillingLedgerId(billingLedgerId, aliasOutletData);
      createLedger({ input: inputData }, {
        handleSuccess: (response) => {
          const { createLedger } = response.data;
          onSubmit(createLedger);
          displayAlert(ALERT_TYPE.SUCCESS, 'Ledger successfully created');
        },
        handleError: (err) => {
          this.onAPIRequestFailure(err);
        },
      });
    }
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  onSelectChange = (event, name, type) => {
    const { info } = this.state;
    this.setState({
      info: {
        ...info,
        [name]: type === 'number' ? parseInt(event.id, 10) : event.id,
      },
    });
  };

  customInputChange = (event) => {
    const { info } = this.state;
    const { billingCustomers, distributorId } = this.props;
    this.setState({
      info: {
        ...info,
        title: event.formattedValue,
      },
    }, () => {
      billingCustomers({ distributorId, searchText: event.formattedValue }, {
        handleSuccess: (response) => {
          this.setState({
            billingCustomerList: (response.data.billingCustomers && response.data.billingCustomers.rows) || [],
          });
        },
        handleError: (err) => {
          this.onAPIRequestFailure(err);
        },
      });
    });
  };

  customInputSelect = (selected) => {
    const { customerId, id, ...items } = selected;
    const { info: { typeId } } = this.state;
    this.setState({
      info: {
        ...items,
        billingLedgerId: customerId,
        typeId,
      },
    });
  };

  render() {
    const { onCancel, billingUser, search } = this.props;
    const {
      info, enableErrorDisplay, formReference: refsObj, billingCustomerList,
    } = this.state;
    const {
      title: ledgerTitle,
      typeId,
      panNo,
      address,
      openingBalance,
      openingBalanceType,
      creditDay,
      creditLimit,
    } = info;

    return (
      <div className="modal-wrapper">
        <div className="modal-inner">
          <DialogHeader><h2>Create Ledger</h2></DialogHeader>
          <form onSubmit={this.onSubmit}>
            <DialogBody className="ledger-modal-body">
              {!search && (
                <Input
                  type="text"
                  placeholder="Ledger Name"
                  labelContent="Ledger Name"
                  name="title"
                  onChange={e => this.onInputChange(e, 'string')}
                  value={ledgerTitle}
                  enableValidation
                  enableErrorDisplay={enableErrorDisplay}
                  ref={ref => refsObj.title = ref}
                />
              )}
              {search && (
                <CustomAutoComplete
                  name="title"
                  onChange={this.customInputChange}
                  dropDownList={billingCustomerList}
                  labelContent="LedgerName"
                  placeholder="Search or Type name"
                  dropDownValueKey={null}
                  dropDownDisplayKey="title"
                  enableValidation
                  ref={ref => refsObj.title = ref}
                  enableErrorDisplay={enableErrorDisplay}
                  onDropDownSelection={this.customInputSelect}
                />
              )}
              <Input
                type="text"
                placeholder="Address"
                labelContent="Address"
                name="address"
                onChange={e => this.onInputChange(e, 'string')}
                value={address}
                enableValidation
                enableErrorDisplay={enableErrorDisplay}
                ref={ref => refsObj.address = ref}
              />
              <Input
                type="number"
                placeholder="Pan No."
                labelContent="Pan No."
                name="panNo"
                rule="matches"
                argument="^((?!(0))[0-9]{9})$" /* matches digit of length 9 and doesn't start with 0 */
                errorMessage={panNo ? INVALID : EMPTY}
                onChange={e => this.onInputChange(e, 'number')}
                value={panNo}
                enableValidation
                enableErrorDisplay={panNo ? enableErrorDisplay : false}
                ref={ref => refsObj.panNo = ref}
              />
              {billingUser && (
                <>
                  {/* <CustomSelect
                    labelContent="Ledger Type"
                    placeholder="Select Ledger Type"
                    getOptionLabel={({ title }) => title}
                    getOptionValue={({ id }) => id}
                    name="typeId"
                    options={ledgerType}
                    onChange={e => this.onSelectChange(e, 'typeId', 'number')}
                    value={ledgerType.filter(d => d.id === typeId)}
                    enableValidation
                    enableErrorDisplay={enableErrorDisplay}
                    ref={ref => refsObj.typeId = ref}
                  /> */}
                  <CustomSelect
                    labelContent="Opening Balance Type"
                    placeholder="Select Opening Balance Type"
                    name="openingBalanceType"
                    getOptionLabel={({ title }) => title}
                    getOptionValue={({ id }) => id}
                    options={openingBalanceTypeData}
                    onChange={e => this.onSelectChange(e, 'openingBalanceType', 'string')}
                    value={openingBalanceTypeData.filter(d => d.id === openingBalanceType)}
                    enableValidation
                    enableErrorDisplay={enableErrorDisplay}
                    ref={ref => refsObj.openingBalanceType = ref}
                  />
                  <Input
                    name="openingBalance"
                    type="number"
                    placeholder="0"
                    labelContent="Opening Balance"
                    onChange={e => this.onInputChange(e, 'number')}
                    value={openingBalance}
                    enableValidation
                    enableErrorDisplay={enableErrorDisplay}
                    ref={ref => refsObj.openingBalance = ref}
                  />
                  <Input
                    name="creditLimit"
                    type="number"
                    placeholder="0"
                    labelContent="Credit Limit"
                    onChange={e => this.onInputChange(e, 'number')}
                    value={creditLimit}
                    enableValidation
                    enableErrorDisplay={enableErrorDisplay}
                    ref={ref => refsObj.creditLimit = ref}
                  />
                  <Input
                    name="creditDay"
                    type="number"
                    placeholder="0"
                    labelContent="Credit Day"
                    onChange={e => this.onInputChange(e, 'number')}
                    value={creditDay}
                    enableValidation
                    enableErrorDisplay={enableErrorDisplay}
                    ref={ref => refsObj.creditDay = ref}
                  />
                </>
              )}
            </DialogBody>
            <DialogFooter>
              <Button secondary onClick={() => onCancel()}>Cancel</Button>
              <Button primary onClick={() => {}}>ok</Button>
            </DialogFooter>
          </form>
        </div>
        <div className="overlay" />
      </div>
    );
  }
}

LedgerAdd.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  createLedger: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default withAlert()(LedgerAdd);
