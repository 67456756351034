
import AttendanceReport from './AttendanceReport';
import { compose } from 'recompose';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import { query, mutation, DOWNLOAD_REPORT} from './API';
import AttendanceReportMap from './AttendanceReportMap';

const ComposedAttendanceReport = compose(
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  }),
)(AttendanceReport);

const ComposedReportWithAttendanceReportMap= compose(
  withRequestTracker({
    query,
  }),
)(AttendanceReportMap);
export { ComposedAttendanceReport, ComposedReportWithAttendanceReportMap as AttendanceReportMap };

export default ComposedAttendanceReport;
