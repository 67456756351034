import styled from 'styled-components';

const OutletDetailWrap = styled.div`
  position: relative;
.button-wrap,
.route-btn-wrap{
  position:absolute;
  top:-48px;
  right:48px;
}
  .mr-16{
    margin-right: 16px;
  }

  .create-ui {
   .text-right-flex {
      display: flex;
      justify-content: flex-end;
      position: absolute;
      top: -50px;
      right: 48px;
    }
  }
  
  .subtitle {
    position: absolute;
    top: -49px;
    left: 24px;
    font-size: 18px;
    font-weight: normal;
    color: #6b6c7e;
  }
  
  ul.list li {
    padding-right: 24px;
  }
 
  section {
    margin-bottom: 40px;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
  
  .container-fluid {
    padding-left: 24px;
    padding-right: 24px;
  }
  
  .tab-section {
    table {
      th {
        width: 11%;
        
        &:first-child {
          width: 7%;
        }
        
        &:nth-child(2) {
          width: 8%;
        }
        
        &:last-child {
          width: 5%;
        }
      }
      
      tr {
        &:hover {
          background: #f0f5ff;
        }
        
        &.active {
        background: #f0f5ff;
        
        td {
            border-bottom: 1px solid #0c5fff;
          }
        }
      }
    }
    
    .split-form-wrap {
      table th {
        &:first-child {
          width: 15%;
        }
        &:last-child {
          width: 1%;
        }
      }
    }
    
    .collapsible-body-tr {
      &:hover, &:focus {
        background: transparent;
      }
      
      table {
        box-shadow: 0 4px 8px 0 rgba(39, 40, 51, 0.12);
        border: none;
        
        tbody {
          tr:last-child {
            border-top: 1px solid #e7e7ed;
          }
        }
        
        tr {
          th {
            background: transparent;
            width: auto;
          }
        
          th, td {
            border-bottom: none;
          }
        }
      }
    }
  }
  
  .stock-label {
    border: 1px solid transparent;
    display: inline-block;
    font-size: 10px;
    border-radius: 2px;
    padding: 3px;
    
    &.IN {
      border-color: #47c464;
      color: #287d3c;
    }
    
    &.OUT {
      border-color: #ff8f39;
      color: #b95000;
    }
  }
  
  .split-form-container {
  
    .table-wrap {
      max-height: 250px;
      overflow-y: scroll;
      margin-bottom: 24px;
    }
  
    .stats {
      margin: 16px 0 24px;
    }
    
    .split-header {
      label {
        font-size: 12px;
        font-weight: 600;
        display: block;
        color: #6b6c7e;
      }
      
      span {
        display: inline-block;
        font-size: 18px;
        font-weight: 600;
        margin-top: 5px;
      }
    }
    
    .split-body {
      .table-paginate {
        text-align: right;
        padding: 10px 0;
      }
    }
    
  }
  /* .search-input {
    position: absolute;
    top: 0px;
    right: 13px;
} */
  
`;

export default OutletDetailWrap;
