import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { GET_ROLE_LIST } from '../userRole/API';
import { CHECK_IF_ALREADY_EXIST } from '../../../common/GqlAPI';

const USER_FRAGMENT = gql`
    fragment UserFragment on User {
        id
        email
        roleId
        role: Role {
            name
        }
        lastName
        firstName
        title: fullName
        fullName
        phoneNumber
        parentUserId
        billingStatus
        active
        townIds
    }
`;

const GET_USER = gql`
    query getUser($filter: FilterInput ) {
        users(filter: $filter) {
            rows {
                ...UserFragment
                Distributor {
                    id
                    title
                }
                Town {
                    id
                    title
                }
                billingStatus
                billingCredentials {
                    user_id
                    username
                    password
                }
                townIds
                distributorIds
            }
        }
    }
    ${USER_FRAGMENT}
`;

const GET_USER_LIST = gql`
    query getUser ($limit: Int, $offset: Int, $filter: FilterInput) {
        users (limit: $limit, offset: $offset, filter: $filter) {
            rows {
                ...UserFragment
            }
            count
        }
    }
    ${USER_FRAGMENT}
`;

const GET_USER_WITH_TOWN_LIST = gql`
    query getUser ($limit: Int, $offset: Int, $filter: FilterInput) {
        users (limit: $limit, offset: $offset, filter: $filter) {
            rows {
                ...UserFragment
                Town {
                    id
                } 
            }
            count
        }
    }
    ${USER_FRAGMENT}
`;

const CREATE_USER = graphql(gql`
    mutation (
        $firstName: String!,
        $lastName: String!,
        $email: String,
        $phoneNumber: String!,
        $password: String!,
        $roleId: Int!,
        $parentUserId: Int,
        $billingStatus: Boolean,
        $billingCredentials: BillingCredentialsInput,
        $townIds: [Int],
        $distributorIds: [Int],
    ) {
        createUser(
            input: {
                firstName: $firstName,
                lastName: $lastName,
                email: $email,
                phoneNumber: $phoneNumber,
                password: $password,
                roleId: $roleId,
                parentUserId: $parentUserId
                billingStatus: $billingStatus
                billingCredentials: $billingCredentials,
                townIds:$townIds,
                distributorIds:$distributorIds
            }
        )
        {
            ...UserFragment
            billingCredentials {
                user_id
                username
            }
            townIds
            distributorIds
        }
    }
    ${USER_FRAGMENT}
`, {
  props: ({ mutate }) => ({
    createUser: variables => mutate({
      variables,
    }),
  }),
});

const UPDATE_USER = graphql(
  gql`
      mutation(
          $id: Int!,
          $firstName: String!,
          $lastName: String!,
          $email: String,
          $phoneNumber: String!,
          $roleId: Int!,
          $parentUserId: Int,
          $billingStatus: Boolean,
          $billingCredentials: BillingCredentialsInput,
          $townIds: [Int],
          $distributorIds: [Int],
      ) {
          updateUser(id: $id, input: {
              firstName: $firstName,
              lastName: $lastName,
              email: $email,
              phoneNumber: $phoneNumber,
              roleId: $roleId,
              parentUserId: $parentUserId,
              billingStatus: $billingStatus,
              billingCredentials: $billingCredentials,
              townIds:$townIds,
              distributorIds:$distributorIds
          }) {
              ...UserFragment
              billingCredentials {
                  user_id,
                  username
              }
              Distributor{
                  id
                  title
              }
              Town {
                  id
                  title
              }
              townIds
              distributorIds
          }
      }
      ${USER_FRAGMENT}
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      updateUser: variables => mutate({
        variables,
      }),
    }),
  },
);

const GET_BILLING_USER_LIST = gql`
    query getBillingUsers($distributorId: Int!) {
        billingUsers(distributorId: $distributorId) {
            billingUsers {
                idUsers
                name
                email
            }
        }
    }
`;

const TOGGLE_STATE = graphql(gql`
    mutation ($ids: [Int]!, $type: statefulEntities $active: Boolean!) {
        toggleState(ids: $ids, type:$type, active: $active)
    }`, {
  props: ({ mutate }) => ({
    toggleState: variables => mutate({
      variables,
    }),
  }),
});

const TOGGLE_USER_STATUS = graphql(gql`
    mutation ($id: Int!, $active: Boolean!) {
        toggleUser(id: $id, active: $active) {
            firstName
            lastName
        }
    }`, {
  props: ({ mutate }) => ({
    toggleUser: variables => mutate({
      variables,
    }),
  }),
});

const RESET_PASSWORD = graphql(gql`
    mutation ($userId: Int!, $password: String!) {
        resetPasswordByAdmin(userId: $userId, password: $password)
    }`, {
  props: ({ mutate }) => ({
    resetPassword: variables => mutate({
      variables,
    }),
  }),
});

const query = {
  getUserList: GET_USER_LIST,
  getUserWithTownList: GET_USER_WITH_TOWN_LIST,
  getUserRoleList: GET_ROLE_LIST,
  getUser: GET_USER,
  getBillingUsers: GET_BILLING_USER_LIST,
  checkIfAlreadyExist: CHECK_IF_ALREADY_EXIST,
};

const mutation = {
  createUser: CREATE_USER,
  updateUser: UPDATE_USER,
  toggleState: TOGGLE_STATE,
  toggleUser: TOGGLE_USER_STATUS,
  resetPassword: RESET_PASSWORD,
};

export {
  CREATE_USER,
  UPDATE_USER,
  TOGGLE_STATE,
  GET_USER_LIST,
  GET_BILLING_USER_LIST,
  TOGGLE_USER_STATUS,
  RESET_PASSWORD,
};

export { query, mutation };
