import APP from '../settings/app';

const {
  NAMESPACE = 'rosia',
  CONSTANTS: {
    HTTP_ONLY = 'HTTP_ONLY',
    LOCAL_STORAGE = 'LOCAL_STORAGE',
  } = {},
  PREFIX = '-v3',
  TOKEN_SUFFIX = 'authorization',
  REFRESH_TOKEN_SUFFIX = '-refresh-token',
  AUTH_HEADER_SUFFIX = '-auth-request-type',
  APP_STORAGE = '-detail',
  APP_PERMISSION = '-permission',
  PAGE_PERMISSION = '-page-info',
  HEADER_LABEL = '-header-label',
  BU_SETTINGS = '-bu-settings',
} = APP;

export const AUTH = {
  STRATEGIES: {
    CLIENT: {
      AUTH_HEADER: `${PREFIX}${NAMESPACE}${AUTH_HEADER_SUFFIX}`,
      HTTP_ONLY,
      LOCAL_STORAGE,
    },
  },
};

// You may change this one only to match your needs. This is how what authentication strategy
// you want your client uses
export const CLIENT_AUTH_REQUEST_TYPE = AUTH.STRATEGIES.CLIENT.LOCAL_STORAGE;

const TOKEN_NAME = TOKEN_SUFFIX;
const REFRESH_TOKEN_NAME = `${PREFIX}${NAMESPACE}${REFRESH_TOKEN_SUFFIX}`;

export const JWT = {
  HEADER: {
    TOKEN: {
      NAME: TOKEN_NAME,
    },
    REFRESH_TOKEN: {
      NAME: REFRESH_TOKEN_NAME,
    },
  },
  LOCAL_STORAGE: {
    TOKEN: {
      NAME: `${NAMESPACE}Token`,
    },
    REFRESH_TOKEN: {
      NAME: `${NAMESPACE}RefreshToken`,
    },
    APP_STORAGE: {
      NAME: `${NAMESPACE}${APP_STORAGE}`,
    },
    PERMISSION: {
      NAME: `-${NAMESPACE}${APP_PERMISSION}`,
    },
    LABEL: {
      HEADER: `-${NAMESPACE}${HEADER_LABEL}`,
    },
    BU_SETTINGS: `${NAMESPACE}${BU_SETTINGS}`,
  },
  SESSION_STORAGE: {
    APP_STORAGE: {
      NAME: `${NAMESPACE}${APP_STORAGE}`,
      PERMISSION: `${NAMESPACE}${APP_PERMISSION}`,
      PAGE: `${NAMESPACE}${PAGE_PERMISSION}`,
    },
  },
};

export const CLIENT_AUTHENTICATION_METHOD = { // Either both FALSE or one TRUE at any given time
  HTTP_ONLY: APP.STRATEGIES.HTTP_ONLY
    && AUTH.STRATEGIES.CLIENT.HTTP_ONLY === CLIENT_AUTH_REQUEST_TYPE,
  LOCAL_STORAGE: APP.STRATEGIES.LOCAL_STORAGE
    && AUTH.STRATEGIES.CLIENT.LOCAL_STORAGE === CLIENT_AUTH_REQUEST_TYPE,
};
