import React from 'react';
import PropTypes from 'prop-types';
import { CheckBox } from '../../../components';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import {
  menuAction,
  tableHeaderWithCheckBox,
} from '../../../utils/paginatedListUtils';
import { PERMISSION_OBJ } from '../../../data/enums/Permission';

const labelMappings = {
  checkbox: 'checkbox',
  id: 'id',
  title: 'title',
  categories: 'categories',
  actionHolders: 'actionHolders',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.checkbox,
    title: '',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.id,
    title: 'ID',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.title,
    title: 'Title',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.categories,
    title: 'Categories',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.actionHolders,
    title: '',
    show: true,
  },
];


const menuConfigList = [
  {
    title: 'Edit',
    icon: 'pencil',
    permissionDerivedBy: 'update',
    type: EVENT_OPERATION.UPDATE,
  },
  {
    title: 'Delete',
    icon: 'trash',
    permissionDerivedBy: 'delete',
    type: EVENT_OPERATION.DELETE,
  },
];

const TableHeader = ({
  dataListLength,
  checkedListLength,
  primaryCheckboxHandler,
  permission,
}) => tableHeaderWithCheckBox(
  dataListLength,
  checkedListLength,
  primaryCheckboxHandler,
  permission,
  labelConfig,
  labelMappings,
);

TableHeader.propTypes = {
  checkedListLength: PropTypes.number,
  dataListLength: PropTypes.number,
  permission: PropTypes.instanceOf(Object),
  primaryCheckboxHandler: PropTypes.func.isRequired,
};

TableHeader.defaultProps = {
  checkedListLength: 0,
  dataListLength: 0,
  permission: PERMISSION_OBJ,
};

const returnTableBody = (
  data,
  checkedList,
  onIconClick,
  onSecondaryCheckBoxClick,
  permission,
  getCategoryTitle,
) => {
  const table = {};
  table[labelMappings.checkbox] = permission.delete && (
    <td onClick={e => e.stopPropagation()}>
      <CheckBox
        checked={checkedList.includes(data.id)}
        onCheckBoxClick={() => onSecondaryCheckBoxClick(data.id)}
      />
    </td>
  );
  table[labelMappings.id] = <td>{data.id}</td>;
  table[labelMappings.title] = <td>{data.title}</td>;
  table[labelMappings.categories] = (
    <td>
      {
                            data.Categories.map(category => (
                              <div
                                className="category-list"
                                key={`cat-id-${category.id}`}
                              >
                                {
                                  getCategoryTitle(category.id)
                                }
                              </div>
                            ))
                          }
    </td>
  );
  table[labelMappings.actionHolders] = (
    <td
      className="text-right simple-popup-actions"
      onClick={e => e.stopPropagation()}
    >
      {menuAction(
        menuConfigList,
        { menuIcon: 'ellipsis-v' },
        onIconClick,
        data,
        permission,
      )}
    </td>
  );

  return table;
};

const TableBody = ({
  data,
  checkedList,
  onIconClick,
  secondaryCheckboxHandler,
  permission, getCategoryTitle,
}) => {
  // Expanding/Dragging and collapsing table body.
  // color based upon table body
  // tooltip if it is sorted.
  // active className
  const tableBody = returnTableBody(
    data,
    checkedList,
    onIconClick,
    secondaryCheckboxHandler,
    permission,
    getCategoryTitle,
  );

  return (
    <tr
      key={data.id}
    >
      {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  checkedList: PropTypes.instanceOf(Array),
  data: PropTypes.instanceOf(Object),
  onIconClick: PropTypes.func.isRequired,
  getCategoryTitle: PropTypes.func.isRequired,
  permission: PropTypes.instanceOf(Object),
  secondaryCheckboxHandler: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
  checkedList: [],
  permission: PERMISSION_OBJ,
};

export {
  TableHeader,
  TableBody,
};
