import styled from 'styled-components';

const ARCStyled = styled.div`
  .section-header {
    .arc-title-btn {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      button {
        height: 32px;
        width: 64px;
        font-size: 14px;
      }
    }
  }
  .section-content {
    border-top: 1px solid #e7e7ed;
    overflow: hidden !important;
  }
  .download-ico,
  .merge-ico,
  .extra-ico,
  .absolute-eyes,
  .gmnoprint > div > div[role='button'] {
    width: 32px;
    height: 32px;
    vertical-align: middle;
    line-height: 28px;
    text-align: center;
    margin-right: 8px;
    border: 1px solid #cdced9;
    border-radius: 4px !important;
    background-color: #cdced9;
  }
  /* .gmnoprint > div > div[role=button] > span > div > img {
  display: none !important;
} */
  .gmnoprint > div {
    & > div[title='Stop drawing'] {
      top: 16px !important;
      & > span > div {
        width: 18px;
        height: 18px;
        position: relative;
        right: -3px;
        top: 1px;
        img {
          left: 1px;
          top: -63px;
        }
      }
    }
    & > div[title='Draw a shape'] {
      top: 16px !important;
      & > span > div {
        width: 18px;
        height: 18px;
        position: relative;
        right: -3px;
        top: 1px;
        img {
          left: 1px;
          top: -63px;
        }
      }
    }
  }
  .download-ico,
  .merge-ico,
  .extra-ico,
  .absolute-eyes {
    position: absolute;
    top: 37px !important;
    left: 50% !important;
    transform: translate(40px, -50%);
    z-index: 1;
    border: 0;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    svg {
      height: 16px !important;
      width: 16px !important;
      fill: #6b6c7e !important;
    }
  }
  .merge-ico {
    transform: translate(-80px, -50%);
  }
  .download-ico {
    transform: translate(-120px, -50%);
  }

  .extra-ico {
    transform: translate(80px, -50%);
    .icon {
      transform: rotate(-90deg);
    }
  }
  .flex-container {
    display: flex;
    flex-wrap: wrap;
    .mapParam {
      flex-basis: 738px;
      flex-basis: 68%;
      width: 68%;
      padding: 32px 48px;
      .parameters {
        display: flex;
        justify-content: space-between;
      }
      .get-outlet-btn {
        display: flex;
        align-items: center;
        & > span {
          margin-left: 16px;
          color: #272833;
          font-size: 14px;
          font-weight: 500;
        }
      }
      .map-wrapper-inner {
        #map {
          height: 65vh;
        }
        .filter-dropdown-card {
          left: 0;
          min-width: 550px;
        }
        .absolute-edit {
          width: 83px;
          height: 16px;
          border: 1px solid #47c464;
          margin: auto;
          position: absolute;
          z-index: 1;
          margin-left: auto;
          margin-right: auto;
          left: -7px;
          right: 0;
          top: 58px;
          background-color: #fff;
          font-size: 10px;
          color: #287d3c;
          font-weight: 500;
          text-align: center;
          line-height: 14px;
          cursor: pointer;
        }
        .btns-wrapper {
          position: absolute;
          bottom: 0;
          left: 20px;
          z-index: 1;
          width: calc(100% - 2 * 20px);
          padding: 12px 16px;
          background-color: #ffffff;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .right-btns,
          .left-btns {
            display: flex;
          }
          .left-btns {
            button {
              display: flex;
              align-items: center;
              img {
                width: 10px;
                height: 15px;
                margin-right: 10px;
              }
            }
          }
          & > span {
            font-size: 10px;
            line-height: 10px;
            color: #272833;
          }
        }
        /* custom css edit of map */
        .gmnoprint {
          top: 45px;
        }
        .gm-style-cc {
          opacity: 0;
        }
      }
    }
    .routesList {
      flex: 1;
    }
    .map-wrapper-inner {
      position: relative;
    }
    .absolute {
      position: absolute;
      top: 0;
    }
    /* for filterbtn  */
    .filter-btn {
      display: flex;
      justify-content: space-between;
    }
    .filter-dropdown-card {
      input {
        background: unset;
        border: unset;
        padding: unset;
        font-size: 11px;
        font-weight: unset;
        color: unset;
        width: 100%;
      }
    }
    /* for filterbtn  */
  }
  /* for search button only  */
  #demo-2 input {
    outline: none;
    background-color: #fff;
  }
  #demo-2 input[type='search'] {
    background: url('https://img.icons8.com/ios-glyphs/20/000000/search.png')
      no-repeat 6px center;
    padding: 4px 7px 4px 32px;
    border-radius: 4px;
    transition: all 0.5s;
    box-sizing: content-box;
  }
  #demo-2 input[type='search'] {
    width: 0;
    cursor: pointer;
    padding-left: 23px;
    background-color: #fff;
  }
  #demo-2 input[type='search']:focus {
    width: 150px;
    padding-left: 32px;
    font-size: 1rem;
    color: #000;
    font-weight: 400;
    background-color: #fff;
    cursor: auto;
  }
  #demo-2 input::placeholder {
    font-size: 14px;
    color: #a7a9bc;
    font-weight: 400;
  }
  .gm-style-cc {
    display: none !important;
  }
  .placeholder {
    position: relative;
    margin-bottom: 16px;
    width: calc(100% - 24px);
    margin-right: 24px;
    .form-input {
      width: 100%;
    }
    &::after {
      position: absolute;
      right: 10px;
      top: 33px;
      content: attr(data-placeholder);
      opacity: 0.4;
    }
  }
  .form-input {
    margin-bottom: 16px;
    width: 225px;
    width: calc(100% - 24px);
    margin-right: 24px;
    &:last-child {
      margin-right: 0;
    }
  }
`;
export default ARCStyled;
