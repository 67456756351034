import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import { Form, Input } from '../../../../../components/index';
import withLoading from '../../../../../utils/composition/withLoading';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import CustomSelect from '../../../../../components/CustomSelect/CustomSelect';
import SingleDatePicker from '../../../../../components/DatePicker/SingleDatePicker';

const propTypes = {
  handleInputChange: PropTypes.func,
  data: PropTypes.instanceOf(Object),
  enableErrorDisplay: PropTypes.bool,
  update: PropTypes.instanceOf(Object),
  handleDropDownChange: PropTypes.func,
  refsObj: PropTypes.instanceOf(Object),
  vendorList: PropTypes.instanceOf(Array),
  distributorList: PropTypes.instanceOf(Array),
};

const defaultProps = {
  data: {},
  refsObj: {
    title: '',
  },
  enableErrorDisplay: true,
  update: {
    type: EVENT_OPERATION.CREATE,
    status: false,
  },
  vendorList: [],
  distributorList: [],
  handleDropDownChange: () => null,
  /**
   * agruments:
   * first param: input event (required),
   * second param: data's first depth targeted key,
   * third param:
   *  only if object depth greater than one,
   *  should contain list of object keys in accordance of depth upto end i.e target key
   */
  handleInputChange: () => null,

};

const DetailForm = ({ ...props }) => {
  const {
    data,
    update,
    refsObj,
    subDUser,
    vendorList,
    distributorList,
    handleInputChange,
    enableErrorDisplay,
    handleDropDownChange,
  } = props;

  return (
    <Form className="grn-form">
      <Row>
        <Col md={6}>
          <Row>
            <Col className="mb-48" md={6}>
              <Input
                disabled
                name="id"
                type="number"
                value={data.id}
                labelContent="GRN ID"
                placeholder="GRN ID"
              />
            </Col>
            <Col className="mb-24" md={6}>
              <Input
                disabled
                name="date"
                type="date"
                value={data.createdAt}
                labelContent="GRN Date"
                placeholder="GRN Date"
                ref={ref => refsObj.date = ref}
              />
            </Col>
            <Col className="mb-24" md={12}>
              <CustomSelect
                enableValidation
                options={vendorList}
                labelContent="Vendors"
                className="custom-select"
                placeholder="Select Vendors"
                getOptionValue={({ id }) => id}
                getOptionLabel={({ name }) => name}
                ref={ref => refsObj.vendorId = ref}
                enableErrorDisplay={enableErrorDisplay}
                disabled={update.type === EVENT_OPERATION.APPROVE}
                onChange={event => handleDropDownChange(event.id, ['Vendor', 'id'])}
                value={vendorList.filter(({ id }) => id === data.Vendor.id)}
              />
            </Col>
            {
              !subDUser && (
                <Col md={12}>
                  <CustomSelect
                    enableValidation
                    labelContent="Distributor"
                    options={distributorList}
                    className="custom-select"
                    placeholder="Select Distributors"
                    getOptionValue={({ id }) => id}
                    getOptionLabel={({ title }) => title}
                    enableErrorDisplay={enableErrorDisplay}
                    ref={ref => refsObj.distributorId = ref}
                    disabled={update.type === EVENT_OPERATION.APPROVE}
                    onChange={event => handleDropDownChange(event.id, ['Distributor', 'id'])}
                    value={distributorList.filter(({ id }) => id === data.Distributor.id)}
                  />
                </Col>
              )
            }
            <Col md={6} className="mt-24">
              <Input
                type="text"
                enableValidation
                name="invoiceNumber"
                value={data.invoiceNumber}
                placeholder="Invoice Number"
                labelContent="Invoice Number"
                ref={ref => refsObj.invoiceNumber = ref}
                enableErrorDisplay={enableErrorDisplay}
                onChange={event => handleInputChange(event)}
                disabled={update.type === EVENT_OPERATION.APPROVE}
              />
            </Col>
            <Col md={6} className="mb-24">
              <div className="single-date-picker">
                <label>Invoice Date</label>
                <SingleDatePicker
                  name="invoiceDate"
                  date={data.invoiceDate}
                  onChange={(name, date) => (
                    handleInputChange({
                      target: {
                        name: 'invoiceDate',
                      },
                      formattedValue: date,
                    })
                  )}
                />
              </div>
            </Col>
            <Col className="mb-24" md={12}>
              <Input
                type="text"
                name="remarks"
                enableValidation
                value={data.remarks}
                placeholder="Add Remarks"
                labelContent="Remarks"
                enableErrorDisplay={enableErrorDisplay}
                onChange={event => handleInputChange(event)}
                disabled={update.type === EVENT_OPERATION.APPROVE}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

DetailForm.propTypes = propTypes;

DetailForm.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withLoading(DetailForm);

export default LoginViewWithErrorAndLoading;
