import styled from 'styled-components';

const ReceivedStyled = styled.div`
/* stock replacement btn on Received accordion styling start -by Sandesh*/
tr.stock-replace-tr{
  background-color:#ffffff !important;
  transition:unset;
    &:hover{
      background-color:#ffffff !important;
    }
    td{
      border:0;
      button{
        display:flex;
        align-items:center;
          .count{
            height:16px;
            width:16px;
            line-height:15px;
            text-align:center;
            border-radius:50%;
            background-color:#2E5AAC;
            margin:0;
            margin-left:10px;
            color:#ffffff;
            font-size:10px;
          }
      }

      &:first-child{
        span{
          padding:0;
          position:unset;
        }
      }
      }
    }
    /* stock replacement btn on Received accordion styling end*/

.received{
  .modal-inner.bulk_delete{
    max-width:443px;
  }
}
.table-wrap{
 table{
   background-color:#ffffff;
  tbody, thead{
  td,th{
    width:20%;
    &:nth-last-child(3),
      &:last-child,
      &:nth-child(2),
    &:first-child{
      width:auto;
      white-space:nowrap;
    }
  
  }
  }
  .collapsible-body-tr{
    tbody tr th:first-child{
      width:unset;
      min-width:90px;
    }
  }
  }
}
  `;

export default ReceivedStyled;
