import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import withLoading from '../../../../../../utils/composition/withLoading';
import {
  dataProps,
  paginationProps,
} from '../../../../../../data/enums/Component';

const propTypes = {
  data: dataProps.value,
  onPageChange: PropTypes.func,
  pagination: paginationProps.value,
  breadCrumb: PropTypes.instanceOf(Array),
  onIconClick: PropTypes.func.isRequired,
  onTableRowClick: PropTypes.func.isRequired,
  enableOutletCreation: PropTypes.bool,
};

const defaultProps = {
  data: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
  onPageChange: () => null,
  breadCrumb: [],
  enableOutletCreation: false,
};

const View = ({ ...props }) => {
  const { data, billingUser } = props;

  return (
    <Fragment>
      <div className="table-wrap">
        <table>
          <thead>
            <tr>
              <th>Ledger</th>
              <th>Address</th>
              <th>Pan No.</th>
              {billingUser && <th>Ledger Type</th>}
              {billingUser && <th>Opening Balance Type</th>}
              {billingUser && <th>Opening Balance</th>}
            </tr>
          </thead>
          <tbody>
            {data.map(ledger => (
              <tr key={ledger.id} style={{ cursor: 'pointer' }}>
                <td>{ledger.title}</td>
                <td>{ledger.address}</td>
                <td>{ledger.panNumber}</td>
                {billingUser && <td>{ledger.type}</td>}
                {billingUser && <td>{ledger.openingBalanceType}</td>}
                {billingUser && <td>{ledger.openingBalance ? ledger.openingBalance.toLocaleString('en') : ''}</td>}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      /> */}
    </Fragment>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const ViewWithLoading = withLoading(View);

export default ViewWithLoading;
