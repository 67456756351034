import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import { GRN_FRAGMENT } from '../../grn/API';
import { GRN_LINE_FRAGMENT } from '../../grn/createGrn/API';
import {
  GET_DISTRIBUTORS, GET_SKU_WITH_BATCH, GET_VENDORS } from '../../../common/GqlAPI';

const GET_UPDATED_GRN_DETAIL = gql`
  query getGrnDetail ($invoiceNumber: String!) {
    findGRNInvoice(invoiceNumber: $invoiceNumber) {
      ...GrnFragment
      lines {
        ...GrnLine
      }
    }
  }
  ${GRN_FRAGMENT}
  ${GRN_LINE_FRAGMENT}
`;

const CREATE_PRN = graphql(gql`
  mutation($input: PRNInput!) {
    createPurchaseReturn(input: $input)
    {
     id
     prnInvoiceNumber
    }
  }
  `, {
  props: ({ mutate }) => ({
    createPrn: variables => mutate({
      variables,
    }),
  }),
});

const GET_GRN_INVOICE_NUMBER_LIST = gql`
  query getInvoiceNumberList( $invoiceString: String!, $distributorId: Int!) {
    searchGRNInvoiceNumber(invoiceString: $invoiceString, distributorId: $distributorId) {
      invoiceNumbers
      matched
    }
  }
`;

const CHECK_SKU_STOCK = gql`
    query checkSkuStock( $skuId: Int!, $distributorId: Int!, $quantity: Int! ) {
        checkSkuStock(skuId: $skuId, distributorId: $distributorId, quantity: $quantity)   
    }
`;


const query = {
  getVendors: GET_VENDORS,
  getSkus: GET_SKU_WITH_BATCH,
  getDistributors: GET_DISTRIBUTORS,
  getUpdatedGrnDetail: GET_UPDATED_GRN_DETAIL,
};

const mutation = {
  createPrn: CREATE_PRN,
};

export {
  CREATE_PRN,
  CHECK_SKU_STOCK,
  GET_GRN_INVOICE_NUMBER_LIST,
};

export { query, mutation };
