import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import {
  ERROR,
  formConfig,
  SRN_TYPE,
  STOCK_TYPE_LIST,
  totalViewRefs,
  updateSkuLine,
  prepareSkuLinesForValidation, updateRate,
} from './config';
import Form from './Form';
import { TableView, TotalView } from './View';
import { DialogFormWrapper } from '../../../../common';
import withAlert from '../../../../../utils/composition/withAlert';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import {
  compareList,
  extractSingleItemFromList,
  filterItems,
  findAndReplaceItem,
} from '../../../../../utils/arrayProcessor';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import { totalPriceMapper } from '../config';
import { refValidator } from '../../../../../utils/refGenerator';
import { RETURN_TYPE } from '../../../../common/DomainConfig';
import { VAT_AMOUNT } from '../../../../../data/enums/GeneralConstants';
import {
  getFlattenedOrders,
  handleSkuBatchSelect, isConfirmationType,
  isError,
} from '../../../../common/HelperFunctions';
import Confirmation from '../../../../common/DialogConfirmation';
import PromotionForm from '../../../orderProcessing/received/detail/promotion/PromotionForm';

const propTypes = {
  data: PropTypes.object,
  displayAlert: PropTypes.func.isRequired,
  enableErrorDisplay: PropTypes.bool,
  getDetails: PropTypes.func.isRequired,
  getStatus: PropTypes.func.isRequired,
  imageUploader: PropTypes.any,
  serverResponseWaiting: PropTypes.any,
  skuList: PropTypes.array,
  update: PropTypes.any,
};

const defaultProps = {
  data: {
    orders: [],
    amount: {},
  },
  dialog: {
    type: '',
    element: '',
  },
  selectedOrder: [],
  skuBatchList: [],
  skuList: [],
  totalAmount: totalPriceMapper({}),
};

class Table extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    return { data: nextProps.data };
  }


  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      data: {
        orders: [],
        amount: {},
      },
      dialog: {
        type: '',
        element: '',
      },
      selectedOrders: [],
      skuBatchList: [],
      validatedOrders: [],
      srnType: SRN_TYPE.PARTIAL,
      totalAmount: totalPriceMapper({}),
    };
  }

  componentDidMount() {
    const { getStatus, getDetails } = this.props;
    getStatus(this.getValidationStatus);
    getDetails(this.exportData);
  }

  componentDidUpdate(prevProps, prevState) {
    const { data } = this.state;
    if (!compareList(data.orders, prevState.data.orders)) {
      this.updateState({
        totalAmount: totalPriceMapper({}),
        selectedOrders: [],
      });
    }
  }

  onDropDownChange = (item, value, state, stateUpdater) => {
    const { skuList } = this.props;
    const { skuBatchList } = this.state;

    if (item === 'skuId') {
      const selectedSku = filterItems(skuList, value)[0] || {};
      const { Rates = [] } = selectedSku;
      this.setState({ skuBatchList: Rates }, () => {
        if (Rates.length > 0) {
          state.skuBatchId = Rates[0].id;
          stateUpdater(state);
          handleSkuBatchSelect(Rates[0].id, Rates, state, stateUpdater, updateRate);
        }
      });
    }

    if (item === 'skuBatchId') {
      handleSkuBatchSelect(value, skuBatchList, state, stateUpdater, updateRate);
    }
  };

  handleIconClick = (type, element = {}) => {
    let rates = [];
    if (element.skuId) {
      const { skuList } = this.props;
      const selectedSku = filterItems(skuList, element.skuId)[0] || {};
      rates = selectedSku.Rates || [];
    }

    this.setState({
      dialog: {
        type,
        element,
      },
      skuBatchList: rates,
    });
  };

  handleQtyChange = async (param1, returnqty, currentOrder, stateSetter) => {
    const { data } = this.state;
    if ((currentOrder.promotionId || data.billDiscountDetails.length > 0) && currentOrder.returnQuantity) {
      await this.getDiscountValidation(currentOrder).then(order => (stateSetter(order)));
    } else return stateSetter(currentOrder);
  };

  getDiscountValidation = async currentOrder => await this.validatePromotion(currentOrder).then(order => order);

  handlePrimaryCheckBoxClick = (event) => {
    const { data } = this.state;
    let selectedOrdersList = [];
    let flattenedOrderList = [];
    let validatedOrders = [];
    if (event.currentTarget.checked) {
      selectedOrdersList = (data.orders.map(order => extractSingleItemFromList(order.Lines)) || []).flat();
      flattenedOrderList = getFlattenedOrders(data.orders);
    } else {
      selectedOrdersList = [];
    }
    if (flattenedOrderList.length > 0) {
      validatedOrders = flattenedOrderList.map(order => prepareSkuLinesForValidation(order) || {});
    }
    this.setState({ selectedOrders: selectedOrdersList, validatedOrders },
      () => {
        this.calculateLineTotal();
      });
  };

  handleSecondaryCheckboxClick = async (order, checked = false) => {
    const { selectedOrders, data, validatedOrders } = this.state;
    const { invoiceNumberStatus } = this.props;
    const index = selectedOrders.indexOf(order.id);
    let clickSuccessful = false;
    let validatedOrder = order;
    if (index > -1) {
      const promotionOrder = data.billDiscountDetails.length > 0 || order.promotionId;
      if (promotionOrder || validatedOrders.length > 0) {
        if ((order.updatedQuantity === order.returnQuantity) || !promotionOrder) {
          await this.getDiscountValidation(order).then((modifiedOrder) => {
            validatedOrder = { ...(modifiedOrder || {}) };
            if (modifiedOrder.valid) {
              !checked && selectedOrders.splice(index, 1);
              clickSuccessful = true;
            }
          });
        } else {
          !checked && selectedOrders.splice(index, 1);
          clickSuccessful = true;
        }
      } else {
        !checked && selectedOrders.splice(index, 1);
        clickSuccessful = true;
      }
    } else if (data.billDiscountDetails.length > 0 || order.promotionId) {
      if (order.updatedQuantity === order.returnQuantity) {
        await this.getDiscountValidation(order).then((modifiedOrder) => {
          validatedOrder = { ...(modifiedOrder || {}) };
          if (modifiedOrder.valid) {
            // this.updateValidatedOrder(modifiedOrder);
            selectedOrders.push(order.id);
            clickSuccessful = true;
          }
        });
      } else {
        selectedOrders.push(order.id);
        clickSuccessful = true;
      }
    } else {
      clickSuccessful = true;
      selectedOrders.push(order.id);
    }
    this.setState({ selectedOrders }, () => {
      this.calculateLineTotal();
      if (invoiceNumberStatus && clickSuccessful) this.updateValidatedOrder(validatedOrder);
    });
  };

  handleDiscountChange = (e) => {
    const { totalAmount } = this.state;
    totalAmount[e.target.name] = e.formattedValue;
    this.calculateTotal([], totalAmount);
  };

  handleFormSubmit = (type, dialogData) => {
    if (type === EVENT_OPERATION.DELETE) {
      this.handleRowDelete(dialogData);
    } else {
      this.handleDataUpdate(type, dialogData);
    }
  };

  handleRowDelete = (dialogData) => {
    const { data } = this.state;
    const orderIndex = data.orders.findIndex(item => item.promotionId === dialogData.promotionId);
    const index = data.orders[orderIndex].Lines.findIndex(item => item.id === dialogData.id);
    data.orders[orderIndex].Lines.splice(index, 1);
    this.setState({ data }, () => this.calculateLineTotal());
  };

  handleDataUpdate = (type, dialogData) => {
    const { data } = this.state;
    const { invoiceNumberStatus } = this.props;
    const modifiedDialogData = this.modifyData(dialogData);

    if (modifiedDialogData.id) {
      /** need to work on this * */
      const index = data.orders.findIndex(order => order.promotionId === dialogData.promotionId);
      data.orders[index].Lines = findAndReplaceItem(data.orders[index].Lines, modifiedDialogData);
    } else {
      modifiedDialogData.id = 1000 + (data.orders.length > 0
        ? data.orders[0].Lines
          ? data.orders[0].Lines.length + 1 : 1 : 1);
      data.orders = this.encapsulateSkuLineToPromotionOrder([...data.orders], modifiedDialogData);
    }
    this.setState({ data }, () => this.handleSecondaryCheckboxClick(modifiedDialogData, true));
  };

  updateValidatedOrder = (currentOrder) => {
    const { validatedOrders } = this.state;
    const newValidatedOrder = [...(validatedOrders || [])];
    // if (currentOrder.valid) {
    const index = newValidatedOrder.findIndex(item => item.orderId === currentOrder.id);
    const formattedOrder = prepareSkuLinesForValidation(currentOrder) || {};
    if (index > -1) newValidatedOrder.splice(index, 1, formattedOrder);
    else newValidatedOrder.push(formattedOrder);
    //  }

    this.setState({ validatedOrders: newValidatedOrder });
  };

  encapsulateSkuLineToPromotionOrder = (orders, dialogData) => {
    if (orders.length === 0) {
      return [{
        id: null,
        promotionId: null,
        Lines: [{ ...dialogData, promotionId: null }],
      }];
    }
    orders[0].Lines.push({ ...dialogData, promotionId: null });

    return orders;
  };

  validatePromotion = async (currentOrder) => {
    const { validateSrnData, displayAlert } = this.props;
    const validationObj = this.prepareDataForValidation(currentOrder) || {};

    return new Promise((resolve, reject) => {
      validateSrnData({
        input: { ...validationObj },
      }, {
        handleSuccess: (response) => {
          const responseData = response.data.validateSrnData;
          if (responseData) {
            if (responseData.status) {
              resolve({
                ...currentOrder,
                updatedAmountDetails: responseData.Data.amountDetails || {},
                valid: true,
              });
            } else {
              displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Violates Discount Criteria');
              resolve({
                ...currentOrder,
                returnQuantity: currentOrder.updatedQuantity,
                valid: false,
              });
            }
          } else {
            if (isError(response)) displayAlert(ALERT_TYPE.CUSTOM_DANGER, response.errors[0].message);
            resolve({
              ...currentOrder,
              returnQuantity: currentOrder.updatedQuantity,
              valid: false,
            });
          }
        },
        handleError: (error) => {
          displayAlert(ALERT_TYPE.DANGER, error);
          resolve({
            ...currentOrder,
            returnQuantity: currentOrder.updatedQuantity,
            valid: false,
          });
        },
      });
    });
  };

  prepareDataForValidation = (currentOrder) => {
    const { data, validatedOrders } = this.state;
    const preparedData = {
      triggeredObj: prepareSkuLinesForValidation(currentOrder),
      invoiceNumber: data.salesInvoiceNumber,
      editedObj: validatedOrders.filter(order => order.orderId !== currentOrder.id) || [],
    };
    if (currentOrder.promotionId) {
      const promotionOrderObj = data.orders.filter(order => order.promotionId === currentOrder.promotionId)[0] || {};
      const formattedLines = promotionOrderObj.Lines ? promotionOrderObj.Lines.map(order => prepareSkuLinesForValidation(order)) : [];
      preparedData.promotionData = {
        promotionId: promotionOrderObj.promotionId,
        Lines: formattedLines,
      };
    }
    if (data.billDiscountDetails.length > 0) {
      const flattenedOrder = getFlattenedOrders(data.orders);
      preparedData.billDiscountData = {
        promotionId: data.billDiscountDetails.map(billDiscount => billDiscount.id) || [],
        Lines: flattenedOrder.map(order => prepareSkuLinesForValidation(order) || {}),
      };
    }

    return preparedData;
  }

  modifyData = (dialogData) => {
    const { skuList, invoiceNumberStatus } = this.props;
    const { skuBatchList, data, totalAmount } = this.state;
    const selectedSku = filterItems(skuList, dialogData.skuId)[0] || {};
    const selectedBatch = filterItems(skuBatchList, dialogData.skuBatchId)[0] || {};

    return updateSkuLine(dialogData, selectedSku, selectedBatch,
      invoiceNumberStatus ? data.amount : totalAmount);
  };

  calculateTotal = (filteredList, updatedTotalObj) => {
    const { data } = this.state;
    const { invoiceNumberStatus } = this.props;
    const totalAmount = { ...updatedTotalObj };
    const discounts = {
      billDiscount: !invoiceNumberStatus ? totalAmount.billDiscount : 0,
      tradeDiscount: !invoiceNumberStatus ? totalAmount.tradeDiscount : 0,
    };
    if (invoiceNumberStatus) {
      filteredList.forEach((item) => {
        discounts.billDiscount += (item.updatedAmountDetails.billDiscount || 0);
        discounts.tradeDiscount += (item.updatedAmountDetails.tradeDiscount || 0);
      });
    }
    const discountAmount = updatedTotalObj.promotionDiscount
      + updatedTotalObj.topUpDiscount + discounts.billDiscount + discounts.tradeDiscount;
    const taxableAmount = (totalAmount.subTotal
      - discounts.billDiscount - discounts.tradeDiscount) || 0;
    const vat = VAT_AMOUNT * taxableAmount;

    totalAmount.billDiscount = discounts.billDiscount || 0;
    totalAmount.tradeDiscount = discounts.tradeDiscount || 0;
    totalAmount.discountAmount = discountAmount || 0;
    totalAmount.taxAmount = vat;
    totalAmount.taxableAmount = taxableAmount;
    totalAmount.netAmount = taxableAmount + vat;
    this.setState({ totalAmount });
  };

  calculateLineTotal = () => {
    const calculatedAmounts = {
      subTotal: 0,
      promotionDiscount: 0,
      topUpDiscount: 0,
      grossAmount: 0,
    };
    const { data, selectedOrders, totalAmount } = this.state;
    const flattenedOrders = getFlattenedOrders(data.orders);
    const selectedOrderDetails = this.getSelectedOrders(flattenedOrders);

    selectedOrderDetails.forEach((sku) => {
      calculatedAmounts.subTotal += sku.updatedAmountDetails.subTotal;
      calculatedAmounts.promotionDiscount += sku.updatedAmountDetails.promotionDiscount;
      calculatedAmounts.topUpDiscount += sku.updatedAmountDetails.topUpDiscount;
      calculatedAmounts.grossAmount += sku.updatedAmountDetails.grossAmount;
    });

    totalAmount.subTotal = calculatedAmounts.subTotal;
    totalAmount.promotionDiscount = calculatedAmounts.promotionDiscount;
    totalAmount.topUpDiscount = calculatedAmounts.topUpDiscount;
    totalAmount.grossAmount = calculatedAmounts.grossAmount;
    this.calculateTotal(selectedOrderDetails, totalAmount);
  };

  getCheckboxStatus = (orderId) => {
    const { selectedOrders } = this.state;
    return (selectedOrders.indexOf(orderId) > -1);
  };

  getValidationStatus = () => {
    const lineValidation = this.linesValidationStatus();

    return (lineValidation && refValidator(totalViewRefs));
  };

  linesValidationStatus = () => {
    const { invoiceNumberStatus, displayAlert } = this.props;
    const { selectedOrders, data } = this.state;
    const status = invoiceNumberStatus ? selectedOrders.length > 0 : data.orders.length > 0;
    if (!status) {
      const message = invoiceNumberStatus ? ERROR.NO_ORDER_SELECTED : ERROR.NO_ORDER_AVAILABLE;
      displayAlert(ALERT_TYPE.CUSTOM_DANGER, message);
    }

    return status;
  };

  determineReturnType = (entireOrders) => {
    const { selectedOrders } = this.state;
    const { invoiceNumberStatus } = this.props;
    if (invoiceNumberStatus) {
      if (selectedOrders.length === entireOrders.length) {
        const srnType = entireOrders.some(item => (item.updatedQuantity !== item.returnQuantity))
          ? RETURN_TYPE.PARTIAL : RETURN_TYPE.COMPLETE;

        return srnType;
      }

      return RETURN_TYPE.PARTIAL;
    }

    return RETURN_TYPE.COMPLETE;
  };

  getSelectedOrders = (flattenedOrders) => {
    const { selectedOrders } = this.state;

    return (flattenedOrders.filter(order => selectedOrders.indexOf(order.id) > -1) || []);
  };

  exportData = () => {
    const { data, totalAmount } = this.state;
    const flattenedOrders = getFlattenedOrders(data.orders);
    const returnOrders = this.getSelectedOrders(flattenedOrders);
    const srnType = this.determineReturnType(flattenedOrders);

    return {
      srnType,
      returnOrders,
      totalAmount,
      orders: data.orders,
    };
  };

  updateState = (states = {}) => {
    this.setState({ ...states });
  };


  resetDialog = () => {
    this.setState({
      dialog: {
        type: '',
        element: '',
      },
    });
  };


  render() {
    const {
      data,
      dialog,
      skuBatchList,
      totalAmount,
      selectedOrders,
    } = this.state;
    const { type } = dialog;
    const {
      update,
      skuList,
      enableErrorDisplay,
      serverResponseWaiting,
      invoiceNumberStatus,
    } = this.props;

    const skuLines = data.orders || [];
    const priceDetails = totalAmount || {};

    return (
      <Fragment>
        <div className="return-create">
          {type && (
            <DialogFormWrapper
              formConfig={formConfig[type]}
              dialogElement={dialog.element}
              onDialogSubmit={this.handleFormSubmit}
              onDialogCancel={this.resetDialog}
              type={type}
              renderDialog={
                ({
                  refsObj,
                  dialogData,
                  handleInputChange,
                  enableErrorDisplay,
                  handleMultipleUpload,
                  handleFormSubmit,
                  handleFileUpload,
                  handleDropDownChange,
                  getFormValidationStatus,
                  updateState,
                  getState,
                }) => (
                  <Fragment>
                    {
                      (type === EVENT_OPERATION.UPDATE || type === EVENT_OPERATION.CREATE) && (
                        <Form
                          show
                          type={type}
                          update={update}
                          refsObj={refsObj}
                          skuList={skuList}
                          data={dialogData}
                          skuBatchList={skuBatchList}
                          stockTypeList={STOCK_TYPE_LIST}
                          loading={serverResponseWaiting}
                          getState={getState}
                          updateState={updateState}
                          handleInputChange={handleInputChange}
                          enableErrorDisplay={enableErrorDisplay}
                          dropDownCallBack={this.onDropDownChange}
                          inputCallBack={this.handleQtyChange}
                          handleDropDownChange={handleDropDownChange}
                          handleMultipleUpload={handleMultipleUpload}
                          invoiceNumberStatus={invoiceNumberStatus}
                        />
                      )
                    }
                    {
                      type === EVENT_OPERATION.READ && (
                        <PromotionForm
                          data={dialogData}
                          loading={serverResponseWaiting}
                        />
                      )
                    }
                    {
                      isConfirmationType(type)
                      && Confirmation(type)
                    }
                  </Fragment>
                )}
            />
          )}
          <TableView
            update={update}
            skuLines={skuLines}
            checkedList={selectedOrders}
            totalOrders={data.totalValidOrders}
            onIconClick={this.handleIconClick}
            checkBoxStatus={this.getCheckboxStatus}
            invoiceNumberStatus={invoiceNumberStatus}
            onPrimaryCheckBoxClick={this.handlePrimaryCheckBoxClick}
            onSecondaryCheckBoxClick={this.handleSecondaryCheckboxClick}
          />
          <TotalView
            update={update}
            refsObj={totalViewRefs}
            priceDetails={priceDetails}
            enableErrorDisplay={enableErrorDisplay}
            onInputChange={this.handleDiscountChange}
            invoiceNumberStatus={invoiceNumberStatus}

          />
        </div>
      </Fragment>

    );
  }
}

Table.propTypes = propTypes;

Table.defaultProps = defaultProps;

export default withAlert()(Table);
