import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../components/Icon';
import { Search } from '../../../components';
import { menuActionWithTitle } from '../../../utils/paginatedListUtils';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import CardStyled from './CardStyled';

const propTypes = {
  onItemClick: PropTypes.func.isRequired,
  handleSearchInput: PropTypes.func.isRequired,
  title: PropTypes.string,
  selectedId: PropTypes.string,
  handleIconClick: PropTypes.func.isRequired,
};

const defaultProps = {
  title: '',
  selectedId: '',
};

const menuConfigList = [
  {
    title: 'View',
    icon: '',
    permissionDerivedBy: 'update',
    type: EVENT_OPERATION.READ,
  },
  {
    title: 'Edit',
    icon: '',
    permissionDerivedBy: 'update',
    type: EVENT_OPERATION.UPDATE,
  },
  {
    title: 'Delete',
    icon: '',
    permissionDerivedBy: 'delete',
    type: EVENT_OPERATION.DELETE,
  },
];

const Card = ({ ...props }) => {
  const {
    data, onItemClick, handleSearchInput, title, selectedId, handleIconClick,
  } = props;

  return (
    <CardStyled>
      <div className="card-pannel">
        <div className="title-section">
          <label>{title}</label>
          <Icon iconName="plus" style={{ cursor: 'pointer' }} onClick={() => handleIconClick(EVENT_OPERATION.CREATE, {}, title)} />
        </div>
        <div className="body-section">
          <Search
            hideInput={false}
            handleSearchInput={text => handleSearchInput(text, title)}
          />
          <div className="list-section">
            <ul>
              {
                data.map(d => (
                  <li className={`${d.id === selectedId ? 'active' : 'inActive'}`}>
                    {menuActionWithTitle(menuConfigList, { menuIcon: 'ellipsis-v' }, handleIconClick, d, title)}
                    <span
                      key={d.id}
                      onClick={() => (onItemClick ? onItemClick(d.id) : '')}
                    >
                      {d.title}
                    </span>
                  </li>
                ))
              }
            </ul>
          </div>
        </div>
      </div>
    </CardStyled>
  );
};

Card.propTypes = propTypes;

Card.defaultProps = defaultProps;

export default Card;
