import React, { Fragment } from 'react';
import { numberPresentor } from '../../../utils/conversion';
import TargetDetailWrapper
  from '../../salesForce/target-Achievement/details/TargetDetailWrapper';
import AccordionTable from '../../../components/CollapsibleTable/AccordionTable';
import withErrorAndLoadingScreen from '../../../utils/composition/withLoading';

const propTypes = {};
const defaultProps = {
};

const View = ({ ...props }) => {
  const {
    data,
    onIconClick,
    settings,
    pagination,
    handleBrandDialog,
  } = props;

  const tableData = (childUser, collapsible = false) => (
    <>
      <td>{collapsible ? <span>{childUser.name}</span> : childUser.name}</td>
      <td>{childUser.role}</td>
      <td>{numberPresentor(childUser.target)}</td>
      <td>{numberPresentor(childUser.achievement)}</td>
      <td className="text-right">
        {childUser.target === 0 ? 0 : numberPresentor(childUser.achievement * 100 / (childUser.target))}
%
      </td>
      {/* {settings.brandWise && (
        <td
          onClick={e => e.stopPropagation()}
        >
          <span
            onClick={() => onIconClick('', {}, handleBrandDialog(childUser.id))}
          >
            <Icon iconName="view" />
            {' '}
          Brand Target
          </span>
        </td>
      )} */}
    </>
  );

  const getCollapsibleComponent = (dataList, settings = {}) => (
    <AccordionTable>
      { dataList.map(user => (
        <tr
          key={user.id}
          trigger={
          <>
            <td><span>{user.name}</span></td>
            <td>{user.role}</td>
            <td>{numberPresentor(user.target)}</td>
            <td>{numberPresentor(user.achievement)}</td>
            <td className="text-right">
              {user.target === 0 ? 0 : numberPresentor(user.achievement * 100 / (user.target))}
              %
            </td>
            {/* {settings.brandWise && (
              <td
                onClick={e => e.stopPropagation()}
              >
                <span onClick={() => onIconClick('', {}, handleBrandDialog(user.id))}>
                  <Icon iconName="view" />
                  {' '}
                      Brand Target
                </span>
              </td>
            )} */}
          </>
          }
          isChildPresent={!user.isChildPresent}
          simpleChild={(
            <tr className="active" key={user.id}>
              {tableData(user)}
            </tr>)}
        >
          <AccordionTable>
            {
              user.childUsers.map(childUser1 => (
                <tr
                  key={childUser1.id}
                  trigger={
                  tableData(childUser1, childUser1.isChildPresent)
                }
                  isChildPresent={!childUser1.isChildPresent}
                  simpleChild={(
                    <tr className="active" key={childUser1.id}>
                      {tableData(childUser1)}
                    </tr>)}
                >
                  <AccordionTable>
                    {
                      childUser1.childUsers.map(childUser2 => (
                        <tr
                          key={childUser2.id}
                          trigger={
                            tableData(childUser2, childUser2.isChildPresent)
                          }
                          isChildPresent={!childUser2.isChildPresent}
                          simpleChild={(
                            <tr className="active" key={childUser2.id}>
                              {tableData(childUser2)}
                            </tr>)}
                        >
                          <AccordionTable>
                            {
                                childUser2.childUsers.map(childUser3 => (
                                  <tr
                                    key={childUser3.id}
                                    trigger={
                                      tableData(childUser3, childUser3.isChildPresent)
                                    }
                                    simpleChild={(
                                      <tr className="active" key={childUser3.id}>
                                        { tableData(childUser3)}
                                      </tr>
                                    )}
                                  >

                                    <AccordionTable>
                                      {
                                        childUser3.childUsers.map(childUser4 => (
                                          <tr
                                            key={childUser4.id}
                                            trigger={
                                              tableData(childUser4, childUser4.isChildPresent)
                                            }
                                            isChildPresent={!childUser4.isChildPresent}
                                            simpleChild={(
                                              <tr className="active" key={childUser4.id}>
                                                { tableData(childUser4)}
                                              </tr>
                                            )}
                                          >
                                            <AccordionTable>
                                              {
                                                childUser4.childUsers.map(childUser5 => (
                                                  <tr
                                                    key={childUser5.id}
                                                    trigger={
                                                      tableData(childUser5, childUser5.isChildPresent)
                                                    }
                                                    isChildPresent={!childUser5.isChildPresent}
                                                    simpleChild={(
                                                      <tr className="active" key={childUser5.id}>
                                                        { tableData(childUser5)}
                                                      </tr>
                                                    )}
                                                  >
                                                    {childUser5.isChildPresent
                                                    && childUser5.childUsers.length !== 0
                                                    && childUser5.childUsers.map(childUser5 => (
                                                      <tr className="active" key={childUser5.id}>
                                                        {tableData(childUser5)}
                                                      </tr>
                                                    ))}
                                                  </tr>
                                                ))
                                              }
                                            </AccordionTable>
                                          </tr>
                                        ))
                                      }
                                    </AccordionTable>
                                  </tr>
                                ))
                              }
                          </AccordionTable>
                        </tr>
                      ))
                    }
                  </AccordionTable>
                </tr>
              ))
            }
          </AccordionTable>
        </tr>
      ))}
    </AccordionTable>
  );
  return (
    <Fragment>
      <div className="section-content">
        <TargetDetailWrapper>
          <div className="table-wrap">
            <table>
              <thead>
                <tr>
                  <th>User</th>
                  <th>Role</th>
                  <th>Target</th>
                  <th>Achievement</th>
                  <th className="text-right">Achievement %</th>
                  {/*  {settings.brandWise && <th />} */}
                </tr>
              </thead>
              <tbody>
                {getCollapsibleComponent(data, settings)}
              </tbody>
            </table>
          </div>
        </TargetDetailWrapper>
      </div>
    </Fragment>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withErrorAndLoadingScreen(View);

export default LoginViewWithErrorAndLoading;
