import React from 'react';
import withLoading from '../../../../../../utils/composition/withLoading';
import { fixedFloatAndCommas } from '../../../../../../utils/conversion';

const propTypes = {};
const defaultProps = {
  data: {
    list: [],
    total: 0,
  },
};
const BillDiscountView = ({ data }) => (
  <div className="promotion-popup">
    <div className="table">
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Bill Discount Title</th>
            <th>Discount %</th>
            <th>Discount</th>
          </tr>
        </thead>
        <tbody>
          { data.list.map(item => (
            <tr>
              <td>{item.id}</td>
              <td>{item.title}</td>
              <td>{`${item.discountPercentage}${item.discountPercentage ? '%' : ''}`}</td>
              <td>{fixedFloatAndCommas(item.amount)}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={3}>Total Discount</td>
            <td>{fixedFloatAndCommas(data.total)}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
);

BillDiscountView.propTypes = propTypes;

BillDiscountView.defaultProps = defaultProps;

const BillDiscountViewErrorAndLoading = withLoading(BillDiscountView);

export default BillDiscountViewErrorAndLoading;
