import React, { Fragment } from 'react';
import { numberPresentor } from '../../../utils/conversion';
import TargetDetailWrapper
  from '../../salesForce/target-Achievement/details/TargetDetailWrapper';
import AccordionTable from '../../../components/CollapsibleTable/AccordionTable';
import withErrorAndLoadingScreen from '../../../utils/composition/withLoading';

const propTypes = {};
const defaultProps = {
};

const SubDTargetAchievementView = ({ ...props }) => {
  const {
    data,
    onIconClick,
    settings,
    pagination,
    handleBrandDialog,
  } = props;

  const tableData = (childUser, collapsible = false) => (
    <>
      <td>{collapsible ? <span>{childUser.name}</span> : childUser.name}</td>
      <td>{numberPresentor(childUser.target)}</td>
      <td>{numberPresentor(childUser.achievement)}</td>
      <td>
        {childUser.target === 0 ? 0 : numberPresentor(childUser.achievement * 100 / (childUser.target))}
        %
      </td>
    </>
  );

  const getOutletView = dse => (
    <Fragment>
      <tr className="tr-head">
        <th>Outlet</th>
        <th>Town</th>
        <th>Channel</th>
        <th>Category</th>
        <th className="text-right">Amount</th>
      </tr>
      {

          dse.map(data => (
            <tr>
              <td>{data.name}</td>
              <td>{data.town}</td>
              <td>{data.channel}</td>
              <td>{data.category}</td>
              <td>{data.achievement}</td>
            </tr>
          ))
        }
    </Fragment>
  );

  const getCollapsibleComponent = (dataList = [], settings = {}) => (
    <AccordionTable>
      { dataList.map(user => (
        <tr
          key={user.id}
          trigger={
            <>
              <td><span>{user.name}</span></td>
              <td>{numberPresentor(user.target)}</td>
              <td>{numberPresentor(user.achievement)}</td>
              <td>
                {user.target === 0 ? 0 : numberPresentor(user.achievement * 100 / (user.target))}
                %
              </td>
            </>
          }
          isChildPresent={!(user.childUsers.length > 0)}
          simpleChild={(
            <tr className="active" key={user.id}>
              {tableData(user)}
            </tr>)}
        >
          <AccordionTable>
            {
              user.childUsers.map((childUser1) => {
                const childUserExist = childUser1.outlets.length > 0;
                return (
                  <tr
                    key={childUser1.id}
                    trigger={
                      tableData(childUser1, childUserExist)
                    }
                    isChildPresent={!childUserExist}
                    simpleChild={(
                      <tr className="active" key={childUser1.id}>
                        {tableData(childUser1)}
                      </tr>)}
                  >

                    {
                      getOutletView(childUser1.outlets || [])
                    }
                  </tr>
                );
              })
            }
          </AccordionTable>
        </tr>
      ))}
    </AccordionTable>
  );
  return (
    <Fragment>
      <div className="section-panel">
        <div className="section-content">
          <TargetDetailWrapper>
            <div className="table-wrap">
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Target</th>
                    <th>Achievement</th>
                    <th>Achievement %</th>
                    {/*  {settings.brandWise && <th />} */}
                  </tr>
                </thead>
                <tbody>
                  {getCollapsibleComponent(data, settings)}
                </tbody>
              </table>
            </div>
          </TargetDetailWrapper>
        </div>
      </div>
    </Fragment>
  );
};

SubDTargetAchievementView.propTypes = propTypes;

SubDTargetAchievementView.defaultProps = defaultProps;

const LoginViewWithErrorAndLoadingSubdView = withErrorAndLoadingScreen(SubDTargetAchievementView);

export default LoginViewWithErrorAndLoadingSubdView;
