import styled from 'styled-components';

const DseListViewStyled = styled.div`
  .dse-li-wrap {
   .tr-card{
     &.highlight{
      box-shadow:0 10px 24px 0 rgba(29,42,68,0.12);
     }
     &.blur{
       opacity:0.6;
       box-shadow:0 10px 24px 0 rgba(29,42,68,0.12);
     }
   }
    .dse-li-view-title {
      font-size: 28px;
      line-height: 21px;
      color: #2e384d;
      text-transform: uppercase;
      margin:0;
      margin-bottom: 8px;
    }
    .tr-card {
      background-color: #ffffff;
      border-top: 1px solid rgba(46, 91, 255, 0.08);
      border-bottom: 1px solid rgba(46, 91, 255, 0.08);
      border-right: 1px solid rgba(46, 91, 255, 0.08);
      border-radius: 4px;
      padding: 8px 20px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 4px;
      height:48px;
      &:last-child {
        margin-bottom: 0;
      }
      p {
        margin: 0;
        margin-right: 16px;
        height: 32px;
        width: 32px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba(46, 91, 255, 0.08);
        img{
          height:32px;
          width:32px;
          border-radius:50%;
          object-fit:cover;
        }
      }
      span {
        font-size: 15px;
        line-height: 16px;
        color: #2e384d;
        text-transform: capitalize;
        font-weight: 500;
      }
    }
  }
  .dseRow {
    border-top-left-radius: '4px';
    border-bottom-left-radius: '4px';
  }

  .info-li {
    cursor: pointer;
  }
`;

export default DseListViewStyled;
