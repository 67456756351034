import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import BaseCatalogSelect from './BaseCatalogSelect';
import { Form } from '../../../../../components/index';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import { clone, compareList } from '../../../../../utils/arrayProcessor';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import withAlert from '../../../../../utils/composition/withAlert';
import { catalogSelectFormConfig as form, REQUIRED_CATALOG_ID, SKU_CATALOG_DETAILS_LENGTH } from './config';

const propTypes = {
  crudMode: PropTypes.string,
  skuId: PropTypes.number,
  enableErrorDisplay: PropTypes.bool,
  skuCatalogDetails: PropTypes.array,
  getSkuCatalogDetails: PropTypes.func,
  getStatus: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  getCatalogList: PropTypes.func.isRequired,
  getCatalogDetail: PropTypes.func.isRequired,
};

const defaultProps = {
  skuId: 0,
  skuCatalogDetails: [],
  enableErrorDisplay: false,
  crudMode: EVENT_OPERATION.READ,
  getSkuCatalogDetails: () => null,
};

class CatalogSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      catalogs: [],
      skuCatalogDetails: [...props.skuCatalogDetails],
    };
    this.modifiedSkuCatalogDetails = [];
  }

  componentDidMount() {
    const { getStatus, getSkuCatalogDetails } = this.props;
    getStatus(this.getHierarchyValidationStatus);
    getSkuCatalogDetails(this.exportData);
    this.generateCatalogBar();
    this.getCatalogs();
  }

  shouldComponentUpdate(nextProps, prevState) {
    const { skuCatalogDetails } = this.props;
    if (!compareList(nextProps.skuCatalogDetails, skuCatalogDetails)) {
      if (this.state.skuCatalogDetails.length < SKU_CATALOG_DETAILS_LENGTH) {
        this.setCatalogAndCatalogDetailList(nextProps.skuCatalogDetails);
      }
    }

    return true;
  }

  getCatalogs = () => {
    const {
      getCatalogList, displayAlert,
    } = this.props;
    getCatalogList(null, {
      handleSuccess: (response) => {
        const { rows = [] } = response.data.catalogs;
        if (rows.length > 0) {
          this.setState({ catalogs: rows }, () => {
            this.setDefaultCatalog(REQUIRED_CATALOG_ID);
          });
        }
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  };

  getCatalogDetailList(catalogDetailItem, index, catalogId, parentCatalogDetailId, callBackFxn = () => null) {
    const { getCatalogDetail, displayAlert } = this.props;
    getCatalogDetail({ catalogId, parentCatalogDetailId }, {
      handleSuccess: (response) => {
        const { rows = [] } = response.data.catalogDetails;
        const newCatalogDetailItem = { ...catalogDetailItem };
        newCatalogDetailItem.catalogDetailList = rows;
        callBackFxn(newCatalogDetailItem, index);
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
        callBackFxn(catalogDetailItem, index);
      },
    });
  }

  generateCatalogBar = () => {
    const { skuCatalogDetails } = this.state;
    const catalogDetailItem = form.mapper({});
    catalogDetailItem.catalogList = this.getAppropriateCatalogs(1, skuCatalogDetails);
    skuCatalogDetails.push(catalogDetailItem);
    this.setState({ skuCatalogDetails });
  };

  setCatalogAndCatalogDetailList = (list) => {
    const reverselist = clone(list).reverse();
    list.map((item, index, skuCatalogDetails) => {
      const parentCatalogDetailId = index > 0
        ? skuCatalogDetails[index - 1].CatalogDetail.id : null;
      item.catalogList = this.getAppropriateCatalogs(index, reverselist, item.Catalog.id);
      item.catalogDetailList = this.getCatalogDetailList(item, index, item.Catalog.id, parentCatalogDetailId, this.mapModifiedSkuCatalogsData);

      return item;
    });
    this.setState({ skuCatalogDetails: list });
  };

  mapModifiedSkuCatalogsData = (obj = {}, index = 0) => {
    this.modifiedSkuCatalogDetails[index] = obj;
    if (this.modifiedSkuCatalogDetails.length === SKU_CATALOG_DETAILS_LENGTH) {
      this.setState({ skuCatalogDetails: this.modifiedSkuCatalogDetails });
    }
  };

  getAppropriateCatalogs = (currentIndex, list = [], currentCatalogId = '') => {
    const { catalogs } = this.state;
    if (currentIndex !== 0) {
      const len = list.length;
      const i = currentIndex ? len - (currentIndex || 0) : len - 1;
      const catalog = catalogs.filter((item) => {
        if ((item.id === list[i].Catalog.id)) {
          return false;
        }
        return (item.parentCatalogId ? (item.parentCatalogId > (list[i].Catalog.parentCatalogId || 0)) : false);
      });

      return catalog;
    }

    return catalogs;
  };

  setDefaultCatalog = (catalogId, index = 0) => {
    const { skuCatalogDetails } = this.state;
    skuCatalogDetails[index].Catalog = this.getDefaultCatalog(catalogId);
    skuCatalogDetails[index].catalogList = this.getAppropriateCatalogs(index, skuCatalogDetails, catalogId);
    this.getCatalogDetailList(skuCatalogDetails[index], index, catalogId, null, this.setData);
  };

  getDefaultCatalog = (catalogId) => {
    const { catalogs } = this.state;
    const catalog = this.findCatalog(catalogs, catalogId);

    return catalog;
  };

  findCatalog = (list, id = '') => {
    const requiredItem = list.find(item => item.id === id);
    if (requiredItem) {
      return requiredItem;
    }

    return {};
  };

  setData = (obj, index) => {
    const { skuCatalogDetails } = this.state;
    skuCatalogDetails[index] = obj;
    this.setState({ skuCatalogDetails });
  };

  handleButtonClick = (type) => {
    switch (type) {
      case EVENT_OPERATION.CREATE:
        this.generateCatalogBar();
        break;
      case EVENT_OPERATION.DELETE:
        break;
      default:
        break;
    }
  };

  handleCatalogChange = (event, arrayIndex) => {
    const { skuCatalogDetails } = this.state;
    const targetedObj = skuCatalogDetails[arrayIndex];
    const parentCatalogDetailId = arrayIndex > 0 ? skuCatalogDetails[arrayIndex - 1].CatalogDetail.id : null;
    targetedObj.Catalog = event;
    targetedObj.CatalogDetail = this.resetCatalogDetail();
    this.getCatalogDetailList(targetedObj, arrayIndex, targetedObj.Catalog.id, parentCatalogDetailId, this.setData);
    if (arrayIndex < skuCatalogDetails.length - 1) this.updateSkuCatalogDetails(arrayIndex);
  };

  handleCatalogDetailChange = (event, arrayIndex) => {
    const { skuCatalogDetails } = this.state;
    let newSkuCatalogDetails = [...skuCatalogDetails];
    const targetedObj = newSkuCatalogDetails[arrayIndex];
    targetedObj.CatalogDetail = event;
    if (skuCatalogDetails.length - 1 > arrayIndex) {
      newSkuCatalogDetails = this.updateCatalogDetail(newSkuCatalogDetails, arrayIndex);
    }
    this.setState({ skuCatalogDetails: newSkuCatalogDetails });
  };

  updateSkuCatalogDetails = (index) => {
    const { skuCatalogDetails } = this.state;
    const updatedList = skuCatalogDetails.slice(0, index);
    this.setState({ skuCatalogDetails: updatedList });
  };

  updateCatalogDetail = (skuCatalogDetails, currentIndex) => {
    const list = [...skuCatalogDetails];
    for (let item = currentIndex + 1; item < list.length; item++) {
      const catalogObj = {
        Catalog: list[item].Catalog,
      };
      if (item === currentIndex + 1) {
        list[item].CatalogDetail = this.resetCatalogDetail();
        this.getCatalogDetailList(list[item], item, list[item].Catalog.id, list[currentIndex].CatalogDetail.id, this.setData);
      } else {
        list[item].CatalogDetail = this.resetCatalogDetail();
        list[item].catalogDetailList = [];
      }
    }

    return list;
  };

  getHierarchyValidationStatus = () => {
    const { skuCatalogDetails, catalogs } = this.state;
    const { displayAlert } = this.props;
    const catalogsLength = skuCatalogDetails.length;
    if (skuCatalogDetails[catalogsLength - 1].Catalog.id === REQUIRED_CATALOG_ID) {
      return (this.getChildStatus);
    }

    displayAlert(ALERT_TYPE.CUSTOM_DANGER, `${catalogs[catalogs.length - 1].title} is not selected!`);
    return false;
  };

  exportData = () => {
    const { skuCatalogDetails } = this.state;
    return skuCatalogDetails;
  };

  getChildStatus = () => this.getValidationStatus();

  resetCatalogDetail = () => ({ id: '', title: '' });

  render() {
    const { skuCatalogDetails } = this.state;
    const { enableErrorDisplay } = this.props;
    return (
      <Fragment>
        <Form>
          <div className="config-panel-header">
            <label>SKU Catalog</label>
          </div>
          <div className="config-panel-content sku-list">
            {
              skuCatalogDetails.length > 0 ? skuCatalogDetails.map((catalogItem, key, list) => (
                <BaseCatalogSelect
                  index={key}
                  key={key}
                  arrayLength={list.length}
                  catalog={catalogItem.Catalog}
                  requiredCatalogId={REQUIRED_CATALOG_ID}
                  catalogList={catalogItem.catalogList}
                  onButtonClick={this.handleButtonClick}
                  validationField={form.validationField}
                  enableErrorDisplay={enableErrorDisplay}
                  catalogDetail={catalogItem.CatalogDetail}
                  onCatalogChange={this.handleCatalogChange}
                  catalogDetailList={catalogItem.catalogDetailList}
                  onCatalogDetailChange={this.handleCatalogDetailChange}
                  getStatus={childMethod => this.getValidationStatus = childMethod}
                />
              )) : (<div />)
            }
          </div>
        </Form>

      </Fragment>);
  }
}

CatalogSelect.propTypes = propTypes;

CatalogSelect.defaultProps = defaultProps;

export default withAlert()(CatalogSelect);
