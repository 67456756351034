import React, { Component } from 'react';
import View from './View';
import {
  labelConfig,
} from './tableConfig';
import withAlert from '../../../../utils/composition/withAlert';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import * as queryService from '../../../base/query.service';
import {
  CHEQUES_TABS, tabMapper,
} from './config';
import {
  imageSliderListFormatter, isError,
} from '../../../common/HelperFunctions';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../../utils/api';
import ChequeWrapper from './ChequeWrapper';
import { getPermissionForPayment } from '../../../base/permission';


class Cheques extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pending: {
        list: [],
        total: 0,
      },
      cheque_received: {
        list: [],
        total: 0,
      },
      reconciled: {
        list: [],
        total: 0,
      },
      dishonored: {
        list: [],
        total: 0,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
      },
      activeTab: 0,
      tableInfo: labelConfig,

    };
    const { downloadReport, displayAlert } = this.props;
    this.permission = getPermissionForPayment();
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.getPaymentsList,
      downloadReport,
      displayAlert,
    );
    this.getData = () => {
      const { pending } = this.state;
      return pending;
    };

    this.tabListGetterMapper = {
      [CHEQUES_TABS.PENDING]: this.getPaymentsList,
      [CHEQUES_TABS.RECEIVED]: this.getReceivedList,
      [CHEQUES_TABS.RECONCILED]: this.getReconciledList,
      [CHEQUES_TABS.DISHONORED]: this.getDishonoredList,
    };
  }

  componentDidMount() {
    this.getInitialData();
  }

  getInitialData = () => {
    this.getReceivedList();
    this.getReconciledList();
    this.getDishonoredList();
    const { activeTab } = this.state;
    this.fetchActiveTabPaginatedList(activeTab);
  }

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
    console.log(error);
  };

  setQueryParameters = (queryParams, callBack = () => null) => this.setState(
    { queryParameters: queryParams },
    callBack,
  );

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

   handleTabChange = (tab) => {
     this.setState({
       activeTab: tab,
       tableInfo: labelConfig,
     },
     () => this.fetchActiveTabPaginatedList(tab));
   };

   fetchActiveTabPaginatedList = (tab) => {
     const type = tabMapper[tab];
     this.tabListGetterMapper[type]();
   };

 controlDisplay = (label, value) => {
   const { display } = this.state;
   display[label] = value;
   this.setState(display);
 };

  columnSelectChange = (data) => {
    this.setState({
      tableInfo: data,
    });
  }

  onButtonClick = () => {
    this.getInitialData();
  }

    getPaymentsList = (tabStatus) => {
      const {
        pending, queryParameters, activeTab,
      } = this.state;
      const { payments } = this.props;
      const offset = getOffsetFromPagination(queryParameters.pagination);
      const status = tabStatus || tabMapper[activeTab];

      payments(
        {
          limit: queryParameters.pagination.limit,
          offset,
          filter: {
            filters: [
              ...apiFilterProcessor(queryParameters.filter),
              ...[{
                column: 'collection_type',
                value: ['CHEQUE'],

              }, {
                column: 'status',
                value: [status.toUpperCase()],

              }]],
            dateRange: queryParameters.date,
            queryString: queryParameters.search,
          },
        },
        {
          handleSuccess: (response) => {
            const payment = (response.data.payments && response.data.payments.rows) || [];
            this.state[status] = {
              total: (response.data.payments && response.data.payments.count) || 0,
              list: payment.map((payment) => {
                if (payment.details.image) {
                  payment.details.image = [...(imageSliderListFormatter(payment.details.image, '', { w: 2400, h: 1100 }) || [])];
                }
                return payment;
              }),
            };
            if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
            this.setState(pending);
          },
          handleError: error => this.onAPIRequestFailure(error),
        },
      );
    };

   getReceivedList = () => {
     this.getPaymentsList(CHEQUES_TABS.RECEIVED);
   };

  getReconciledList = () => {
    this.getPaymentsList(CHEQUES_TABS.RECONCILED);
  };

  getDishonoredList = () => {
    this.getPaymentsList(CHEQUES_TABS.DISHONORED);
  };

 handleDownloadClick = (reportType, domain) => {
   const { queryParameters } = this.state;
   const filter = {
     filters: [
       ...apiFilterProcessor(queryParameters.filter),
       {
         column: 'collection_type',
         value: ['CHEQUE'],
       },
     ],
     queryString: queryParameters.search,
     dateRange: queryParameters.date,
   };

   this.basePaginationService.handleDownloadClickWithCustomFilter('csv', domain, filter);
 };

 render() {
   const {
     activeTab,
     tableInfo,
     pending,
     cheque_received,
     reconciled,
     dishonored,
     queryParameters,
   } = this.state;
   const {
     serverResponseWaiting,
   } = this.props;
   return (
     <ChequeWrapper>
       <View
         pending={pending}
         received={cheque_received}
         reconcile={reconciled}
         dishonore={dishonored}
         activeTab={activeTab}
         onTabChange={this.handleTabChange}
         tableConfig={tableInfo}
         columnSelectChange={this.columnSelectChange}
         queryParameters={queryParameters}
         basePaginationService={this.basePaginationService}
         handleFilterChange={this.basePaginationService.handleFilterChange}
         onButtonClick={this.onButtonClick}
         loading={serverResponseWaiting}
         handleDownloadClick={this.handleDownloadClick}
         permission={this.permission}
       />
     </ChequeWrapper>
   );
 }
}

export default withAlert()(Cheques);
