/* global google */
import React, { Component } from 'react';
import Modal from '../../components/Modal/Modal'
import Select from 'react-select';
import { Icon, Button, CheckBox } from '../../../../../components';
import SingleRouteStyled from './SingleRouteStyled'
import backButton from "../../assets/back-button.svg"
import withAlert from "../../../../../utils/composition/withAlert"
import { ALERT_TYPE } from "../../../../../data/enums/AlertType"
import DropDown from '../../components/DropDown/DropDown'
import _ from 'lodash';
import BulkTransfer from './components/BulkTransfer';
import setMarkerImage from '../../services/setMarkerImage';

class SingleRoute extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enableEdit: false,
            newOrderList: props.newOrderList,
            routeLines: props.routeLines,
            routesList: props.routesList,
            show: false,
            transferToRoute: '',
            transferOutletId: '',
            originalOutletOrder: [],
            editSuccess: false,
            routeDeleteSuccess: false,
            currentOutlets: [],
            currentOpenTransfer: {},
            checkedList: []
        }
    }

    componentDidMount() {
        this.state.newOrderList.forEach(marker => {
            marker.oldLabel = (marker.distance === "First" || marker.distance === "Last") ? marker.label.text : Number(marker.label);
        })
    }

    polylineFromMarker = () => {
        // draws polyline according to the markers' order
        this.state.routeLines[this.props.clickedRouteId].setMap(null);

        // gets current polygon path
        const path = this.state.routeLines[this.props.clickedRouteId].getPath();

        // sets the path(of routeLines) array to empty for new ordered marker path add
        const tempRouteLines = this.state.routeLines.slice();
        tempRouteLines[this.props.clickedRouteId].latLngs.i[0].i = [];
        this.setState({ routeLines: tempRouteLines });
        this.state.newOrderList.forEach((marker) => {
            path.push(
                new google.maps.LatLng(marker.position.lat(), marker.position.lng()),
            );

        });
        this.state.routeLines[this.props.clickedRouteId].setMap(window.globalMap);
    }

    sortRoutesList = (array, order, key) => {
        array.sort(function (a, b) {
            var A = a[key], B = b[key];
            if (order.indexOf(A) > order.indexOf(B)) {
                return 1;
            } else {
                return -1;
            }
        });
        return array;
    }

    onDragStart = (e, index) => {
        this.draggedItem = this.state.newOrderList[index];
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('text/html', e.target.parentNode);
        e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
    };

    onDragOver = (index) => {
        const draggedOverItem = this.state.newOrderList[index];
        // if the item is dragged over itself, ignore
        if (this.draggedItem === draggedOverItem) {
            return;
        }

        // filter out the currently dragged item
        const items = this.state.newOrderList.filter(
            item => item !== this.draggedItem,
        );

        // add the dragged item after the dragged over item
        items.splice(index, 0, this.draggedItem);
        this.setState({ newOrderList: items }, () => {
            // set appropriate 'sequence' labels for each markers
            this.state.newOrderList.forEach((marker, i) => {
                if ((!(marker.distance === 'First')) && (!(marker.distance === 'Last'))) {
                    marker.set('label', i + 1);
                    marker.setMap(window.globalMap)
                }
            });
            this.polylineFromMarker()
        });
    };

    onDragEnd = (e) => {
        e.preventDefault()
        e.dataTransfer.dropEffect = "none"
        this.draggedIdx = null;

    };

    transferToRoute = (e) => {
        this.setState({ transferToRoute: e.dse })
    }

    showModal = (outletId) => {
        this.setState({ show: true, transferOutletId: outletId });
    }

    hideModal = () => {
        this.setState({ show: false, currentOpenTransfer: {} });
    }

    handleRouteTransfer = () => {
        if (this.state.transferToRoute === this.props.clickedRouteId) {
            this.props.displayAlert(ALERT_TYPE.INFO, 'This is the same route!');
        } else {
            this.setState({ show: false })
            //remove outlet with transferOutletId from current "state.newOrderList"
            let currentRoute = this.state.newOrderList;
            let outletToRemoveInst = currentRoute.find(outlet => outlet.id === this.state.transferOutletId)
            let outletToRemoveIndex = currentRoute.indexOf(outletToRemoveInst)
            currentRoute.splice(outletToRemoveIndex, 1);

            //remove the outlet from state.routesList[this.props.clickedRouteId].travel
            let removedOutletToTransfer = this.state.routesList[this.props.clickedRouteId].travel.filter(
                outlet => outlet.id === this.state.transferOutletId
            )
            this.state.routesList[this.props.clickedRouteId].travel.forEach((outlet, i) => {
                if (outlet.id === removedOutletToTransfer[0].id) {
                    this.state.routesList[this.props.clickedRouteId].travel.splice(i, 1)
                }
            })

            outletToRemoveInst.setVisible(false)
            this.setState({ newOrderList: currentRoute }, () => {

                // set appropriate 'sequence' labels for each markers
                this.state.newOrderList.forEach((marker, i) => {
                    if (!(marker.distance === 'First') && !(marker.distance === 'Last')) {
                        marker.set('label', null);
                        marker.set('label', i + 1);
                        marker.setMap(window.globalMap)
                    }
                });

                // draws polyline according to the markers' order
                this.state.routeLines[this.props.clickedRouteId].setMap(null);

                // gets current polygon path
                const path = this.state.routeLines[this.props.clickedRouteId].getPath();

                const tempRouteLines = this.state.routeLines.slice();
                const tempRoutesList = this.state.routesList.slice();
                let removedOutletToTransferInst = tempRouteLines[this.props.clickedRouteId].latLngs.i[0].i.splice(outletToRemoveIndex, 1);
                tempRouteLines[this.props.clickedRouteId].latLngs.i[0].i = [];

                this.state.newOrderList.forEach((marker) => {
                    path.push(
                        new google.maps.LatLng(marker.position.lat(), marker.position.lng()),
                    );
                });
                this.state.routeLines[this.props.clickedRouteId].setMap(window.globalMap);


                //transfer the outlet to state.routesList[this.state.transferToRoute]'s at 2nd last index
                //and transfer to polyline instance as well
                let outletIndexToRemove = _.findIndex(tempRoutesList, { dse: this.state.transferToRoute });
                tempRoutesList[outletIndexToRemove].travel.splice(tempRoutesList[outletIndexToRemove].travel.length - 1, 0, removedOutletToTransfer[0]);
                tempRouteLines[outletIndexToRemove].latLngs.i[0].i
                    .splice([tempRouteLines[outletIndexToRemove].latLngs.i[0].i.length - 1], 0, removedOutletToTransferInst[0]);

                //change old properties- icon color, label and "order" according to new route:
                tempRoutesList.forEach(route => {
                    route.travel.forEach((marker, i) => {
                        marker.order = i + 1;
                    })
                })
                outletToRemoveInst.label = tempRoutesList[outletIndexToRemove].travel.length - 1
                //marker from the same index as polyline index
                outletToRemoveInst.icon = this.state.routesList[outletIndexToRemove].travel[1].iconUrl
                this.setState({ routeLines: tempRouteLines, routesList: tempRoutesList });
            })
        }
    }

    handleMultipleOutletTransfer = () => {
        const { transferToRoute, routesList, routeLines, newOrderList, transferOutletId, } = this.state;
        const { displayAlert, clickedRouteId, markerColor } = this.props;
        if (transferToRoute === clickedRouteId) {
            displayAlert(ALERT_TYPE.INFO, 'This is the same route!');
        } else {
            this.setState({ show: false, checkedList: [] });
            //remove the outlets from current route and update the route
            let removedMarkers = _.remove(newOrderList, outlet => transferOutletId.includes(outlet.id));
            removedMarkers.forEach(outlet => outlet.setVisible(false))

            let outletsToRemoveIndices = [];
            routesList[clickedRouteId].travel.forEach((outlet, i) => {
                if (transferOutletId.includes(outlet.id)) {
                    outletsToRemoveIndices.push(i)
                }
            })

            //remove the outlets from routesList
            let removedOutlets = _.remove(routesList[clickedRouteId].travel, outlet => transferOutletId.includes(outlet.id))

            //update marker sequence of current route
            for (let i = 1; i < newOrderList.length - 1; i++) {
                let marker = newOrderList[i];
                marker.set('label', null);
                marker.set('label', i + 1);
                marker.setMap(window.globalMap)
            }

            //update polyline of currentroute
            routeLines[clickedRouteId].setMap(null);
            const path = routeLines[clickedRouteId].getPath();

            let removedOutletToTransferInst = [];
            outletsToRemoveIndices.forEach(index => {
                removedOutletToTransferInst.push(
                    ...routeLines[clickedRouteId].latLngs.i[0].i.splice(index, 1)
                )
            })
            routeLines[clickedRouteId].latLngs.i[0].i = [];

            newOrderList.forEach((marker) => {
                path.push(
                    new google.maps.LatLng(marker.position.lat(), marker.position.lng()),
                );
            });
            routeLines[clickedRouteId].setMap(window.globalMap);

            //transfer the outlets to target routesList at 2nd last index
            let transferRouteIndex = _.findIndex(routesList, { dse: transferToRoute });
            routesList[transferRouteIndex].travel.splice(routesList[transferRouteIndex].travel.length - 1, 0, ...removedOutlets);

            //and transfer to polyline instance as well
            const transferToPath = routeLines[transferRouteIndex].getPath();
            removedOutlets.forEach(outlet => {
                transferToPath.insertAt(transferToPath.length - 1, new google.maps.LatLng(outlet.lat, outlet.lng))
            })

            //change transfered markers' properties according to new route:
            for (let i = 1; i < routesList[transferRouteIndex].travel.length - 1; i++) {
                routesList[transferRouteIndex].travel[i].order = i + 1;
            }

            const transferLabel = (routesList[transferRouteIndex].travel.length) - (removedMarkers.length) //for 1st marker among transferred markers
            removedMarkers.forEach((marker, i) => {
                marker.set('label', transferLabel + i)
                marker.set('icon', setMarkerImage(markerColor[transferToRoute], null, null, 42, 42))
            })
            this.setState({ routeLines, routesList });
        }
    }

    editOutlets() {
        this.setState({ enableEdit: !this.state.enableEdit, checkedList: [] })
    }

    cancelEdit = () => {
        this.setState({ enableEdit: false, checkedList: [], transferOutletId: '' })

        this.state.newOrderList.forEach(marker => {
            marker.setMap(null)
        })

        const toSort = this.state.newOrderList;
        const sorted = _.sortBy(toSort, marker => marker.oldLabel)
        this.setState({ newOrderList: sorted }, () => {
            for (let i = 1; i < this.state.newOrderList.length - 1; i++) {
                this.state.newOrderList[i].set('label', this.state.newOrderList[i].oldLabel)
                this.state.newOrderList[i].setMap(window.globalMap)
            }
            this.state.newOrderList[0].setMap(window.globalMap)
            this.state.newOrderList[this.state.newOrderList.length - 1].setMap(window.globalMap)
            this.polylineFromMarker()
        })
    }

    saveOrderEdit = () => {
        this.props.displayAlert(ALERT_TYPE.SUCCESS, `Route ${this.props.clickedRouteId + 1} edited successfully.`);
        this.setState({ editSuccess: true, enableEdit: false, checkedList: [], transferOutletId: '' })

        //find the index order of newOrderList and according to that
        //reorder routesList[this.props.clickedRouteId]
        let idsOrder = []
        this.state.newOrderList.forEach(outlet => {
            idsOrder.push(outlet.id)
        })
        let sortedOutletsList = this.sortRoutesList(this.state.routesList[this.props.clickedRouteId].travel, idsOrder, 'id')
        if (sortedOutletsList[0].id === sortedOutletsList[1].id) {
            sortedOutletsList.push(sortedOutletsList[0])
            sortedOutletsList.splice(1, 1)
        }
        let orderedRoutesList = this.state.routesList.slice()
        orderedRoutesList[this.props.clickedRouteId].travel = sortedOutletsList
        this.setState({ routesList: orderedRoutesList })

    }

    showDropDown = (i) => {
        if (Object.keys(this.state.currentOpenTransfer).length > 0) {
            this.state.currentOpenTransfer.classList.toggle("show")
            this.setState({ currentOpenTransfer: {} })
        }
        document.getElementById(`myDropdown${i}`).classList.toggle("show");
        this.setState({ currentOpenTransfer: document.getElementById(`myDropdown${i}`) })
    }

    render() {
        const { enableEdit, newOrderList, show, transferOutletId, routesList, checkedList } = this.state;
        const { clickedRouteId, showDeleteFromInside, goBack } = this.props;
        return (
            <SingleRouteStyled>
                <div className="routesDisplay">
                    <div className="header-route">
                        <div className="left-sec">
                            {!enableEdit ? <> <button className="back borderless" onClick={() => goBack()} ><img src={backButton} alt="back-btn" /></button>
                                <span className="title"> {this.state.routesList[clickedRouteId].name}</span></>
                                : <span className="title">Edit {this.state.routesList[clickedRouteId].name}</span>
                            }
                        </div>
                        {!enableEdit ?
                            <div className="right-sec">
                                <button className="trash-btn borderless" onClick={() => showDeleteFromInside(clickedRouteId)}><Icon iconName="trash " /></button>
                                <button className="edit-btn borderless" onClick={() => this.editOutlets()}><Icon iconName="pencil" /></button>
                            </div>
                            :
                            <div className="right-sec">
                                <Button
                                    secondary
                                    iconBtnSmall
                                    className="times-btn bordered"
                                    iconName="times"
                                    onClick={() => this.cancelEdit()}
                                />
                                <Button
                                    primary
                                    iconBtnSmall
                                    iconName="check"
                                    className="check-btn bordered"
                                    onClick={() => this.saveOrderEdit()}
                                />
                            </div>
                        }

                    </div>
                    <>
                        <BulkTransfer
                            checkedList={checkedList}
                            onTransferClick={this.showModal}
                            render={(state, primaryCheckboxHandler, secondaryCheckboxHandler) => (<div className="outlets-aside" style={{ alignItems: 'flex-end' }}>
                                <ul className="draggableUl" >
                                    <li key="firstOutlet">
                                        <div className="left">
                                            <div id={1} key={newOrderList[0].id} />
                                            <span className="content">
                                                <div className="wrap-lft">
                                                    <Icon disabled className="dragIcon" iconName="drag" />
                                                    {enableEdit && <CheckBox disabled />}
                                                    <span className="drag-number">1</span>
                                                    <span className="outlet-name" title={newOrderList[0].name}>{newOrderList[0].name}</span>
                                                </div>
                                            </span>
                                        </div>
                                    </li>
                                    {newOrderList
                                        .slice(1, newOrderList.length - 1)
                                        .map((outlet, i) => (
                                            <li
                                                key={i + 1}
                                                onDragOver={() => this.onDragOver(i + 1)}
                                                className="draggableLi"
                                            >
                                                <div className="left">
                                                    <div
                                                        className="drag"
                                                        draggable={enableEdit === true ? true : false}
                                                        id={i + 1}
                                                        key={outlet.id}
                                                        onDragStart={e => this.onDragStart(e, i + 1)}
                                                        onDragEnd={(e) => this.onDragEnd(e)}
                                                    >
                                                        <Icon className="dragIcon" iconName="drag" />
                                                    </div>
                                                    <div className="content">
                                                        <div className="wrap-lft">
                                                            {enableEdit && <CheckBox
                                                                checked={state.checkedList.includes(outlet.id)}
                                                                onCheckBoxClick={() => secondaryCheckboxHandler(outlet.id)}
                                                            />}
                                                            <span className="drag-number">{i + 2}</span>
                                                            <span className="outlet-name">{outlet.name}</span>
                                                        </div>
                                                        <DropDown showModal={this.showModal} outletName={outlet.name} outletId={outlet.id} />
                                                    </div>
                                                </div>
                                            </li>
                                        ))}

                                    <li key="lastOutlet">
                                        <div className="left">
                                            <div
                                                id={newOrderList.length - 1}
                                                key={
                                                    newOrderList[newOrderList.length - 1]
                                                        .id
                                                }
                                            />
                                            <span className="content">
                                                <div className="wrap-lft">
                                                    <Icon disabled className="dragIcon" iconName="drag" />
                                                    {enableEdit && <CheckBox disabled />}
                                                    <span className="drag-number">{newOrderList.length}</span>
                                                    <span className="outlet-name" title={newOrderList[newOrderList.length - 1].name}>{newOrderList[newOrderList.length - 1].name}</span>
                                                </div>
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </div >

                            )}
                        >
                        </BulkTransfer>
                    </>
                </div >

                <Modal show={show} handleClose={this.hideModal} handler={typeof (transferOutletId) === "object" ? this.handleMultipleOutletTransfer : this.handleRouteTransfer}>
                    <div className="m-header"><h2>Transfer Outlet</h2></div>
                    <div className="m-body">
                        <p>Please select route to transfer selected outlet(s).</p>
                        <div className="form-input">
                            <label>Route</label>
                            <Select
                                name="routes"
                                options={routesList}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(e) => this.transferToRoute(e)} />
                        </div>
                    </div>
                </Modal>

            </SingleRouteStyled>
        )
    }
}

export default withAlert()(SingleRoute);