import {
  compose,
} from 'recompose';
import SummaryDetails from './SummaryDetails';
import { GET_CUSTOMERS } from '../../../../common/GqlAPI';
import withRequestTracker from '../../../../../utils/composition/withRequestTracker';
import { GET_INVOICE_NUMBER_LIST } from '../API';

const query = {
  getCustomers: GET_CUSTOMERS,
  getInvoiceNumberList: GET_INVOICE_NUMBER_LIST,
};

const ComposedCreateSrnSummaryDetails = compose(
  withRequestTracker({
    query,
    mutation: {},
  }),
)(SummaryDetails);

export default ComposedCreateSrnSummaryDetails;
