import gql from 'graphql-tag';


const GET_REPORT_LIST = gql`
    query getReport($roleId: Int!,$filter:FilterInput) {
        getUserWithStateList(roleId: $roleId, filter:$filter) {
            rows {
                id
                name
                status
                loginTime:login_time
            }
            count
        }
    }
`;

const query = {
  getReport: GET_REPORT_LIST,
};

export { query };
