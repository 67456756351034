import styled from 'styled-components';

const ForgotStyled = styled.div`

.top-section{
  background-color: #073681;
  height: 64px;
display: flex;
 flex-direction: column;
    justify-content: center;
}
p{
  position: absolute;
  bottom:0;
  right: 45%;
  height: 15px;
  color: #272833;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;

}
.password{
 position: relative;
}
  .eye{
    position: absolute;
    top: 34px;
    bottom: 0;
    right: 16px;
    cursor: pointer;
    color: grey;
}
`;

export default ForgotStyled;
