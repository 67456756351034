const fixedFloat = (item, digits = 2) => {
  if (item) {
    return (Number(Number(item)
      .toFixed(digits)));
  }
  return item;
};

const fixedFloatAndCommas = (item, maxDigit = 2, minDigit = 0) => (
  item ? item.toLocaleString('en', {
    maximumFractionDigits: maxDigit,
    minimumFractionDigits: minDigit,
  }) : item);

const fixedFloatAndComma = (item, maxDigit = 0, minDigit = 0) => (
  item ? parseFloat(item.toString()).toLocaleString('en', {
    maximumFractionDigits: maxDigit,
    minimumFractionDigits: minDigit,
  }) : item);

const numberPresentor = fixedFloatAndCommas;

const convertToHigherSize = (fileSize, divisor) => fileSize / divisor;

// eslint-disable-next-line consistent-return
const fileSizeSuffixBasedOnRound = {
  0: 'bytes',
  1: 'KB',
  2: 'MB',
  3: 'GB',
  4: 'TB',
};

const convertFileSizeWithSuffix = (fileSize) => {
  let convertedSize = 0;
  let round = 0;
  if (fileSize > 1000000000) {
    convertedSize = convertToHigherSize(fileSize, 1000000000);
    round = 3;
  } else if (fileSize > 1000000) {
    convertedSize = convertToHigherSize(fileSize, 1000000);
    round = 2;
  } else if (fileSize > 1000) {
    convertedSize = convertToHigherSize(fileSize, 1000000);
    round = 1;
  } else {
    convertedSize = fileSize;
    round = 0;
  }

  return fixedFloatAndCommas(convertedSize) + fileSizeSuffixBasedOnRound[round];
};

export {
  fixedFloat, fixedFloatAndCommas, numberPresentor, fixedFloatAndComma, convertFileSizeWithSuffix,
};
