import gql from 'graphql-tag';
import { DOWNLOAD_REPORT, TOGGLE_STATE, UPLOAD } from '../../common/GqlAPI';
import { DISTRIBUTOR_FRAGMENT, VENDOR_FRAGMENT } from '../../common/GqlFragments';

// TODO include vat in grn amount fragment

const GRN_AMOUNT_FRAGMENT = gql`
  fragment GrnAmountFragment on GrnAmount {
    total
    subTotal
    taxAmount
    billDiscount
    tradeDiscount
    taxableAmount
  }
`;

const GRN_FRAGMENT = gql`
  fragment GrnFragment on GoodsReceivedNote {
    id
    status
    remarks
    damages
    vendorId
    shortages
    isEditable
    invoiceDate
    invoiceNumber
    distributorId
    createdAt  
    Vendor {
     ...VendorFragment
    }
    amount {
    ... GrnAmountFragment
    }
    Distributor {
     ...DistributorFragment
    }
    updatedAmount {
      ...GrnAmountFragment
    }
  }
  ${VENDOR_FRAGMENT}
  ${DISTRIBUTOR_FRAGMENT}
  ${GRN_AMOUNT_FRAGMENT}
`;

const GET_GRN_LIST = gql`
  query getGrn($offset: Int, $limit: Int, $filter: FilterInput) {
    goodsReceivedNotes(offset: $offset, limit: $limit, filter: $filter) {
      rows {
      ...GrnFragment
      }
      count
    }
  }
  ${GRN_FRAGMENT}
`;

const query = {
  getGrnList: GET_GRN_LIST,
};

const mutation = {
  toggleState: TOGGLE_STATE,
  upload: UPLOAD,
  downloadReport: DOWNLOAD_REPORT,
};

export {
  GET_GRN_LIST,
  GRN_FRAGMENT,
  GRN_AMOUNT_FRAGMENT,
  DOWNLOAD_REPORT,
};

export { query, mutation };
