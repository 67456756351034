import React, { Component } from 'react';
import './error.css';

class errorPage extends Component {
  render() {
    return (
      <div className="error-wrap">
        <div className="error-container">
          <div className="error-img">
            <img src="/image/error-illustrations.png" alt="Error Illustrations" />
          </div>
          <div className="error-type">404</div>
          <div className="error-content">
            <h1>Page not Found</h1>
            <p>Whoops! We do not have the page you are looking for...</p>
            <p>You may have mistyped the address or the page may have moved.</p>
            <div className="error-link">
              <a href="/login">Go to dashboard</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default errorPage;
