import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';


const crudRequestConfig = {
  [EVENT_OPERATION.UPDATE_STATUS]: {
    objectMapper: ({ distributorIds, distributorGroupId, territoryId, status }) => {

      return { distributorIds, distributorGroupId, territoryId, status };
    },
    responseName: 'toggleStateForDistributorGroup',
    message: 'SubD group updated successfully',
  },
};
export { crudRequestConfig }
