import { compose } from 'recompose';
import Outlets from './Outlets';
import withRequestTracker from '../../../../../utils/composition/withRequestTracker';
import { ASSIGN_OUTLETS, mutation, query } from './API';

const composedRouteOutlets = compose(
  ASSIGN_OUTLETS,
  withRequestTracker({
    mutation,
    query,
  }),
)(Outlets);
export default composedRouteOutlets;
