import gql from 'graphql-tag';
import { graphql } from 'react-apollo/graphql';

const CREATE_LEDGER = graphql(gql`
    mutation($input: LedgerInput!){
        createLedger(input: $input){
            customerId
            title
            address
            panNumber
            typeId
            openingBalanceType
            openingBalance
            id
        }
    }
`, {
  props: ({ mutate }) => ({
    createLedger: variables => mutate({
      variables,
    }),
  }),
});

const GET_BILLING_CUSTOMERS = gql`
  query billingCustomers($distributorId:Int!, $searchText:String){
    billingCustomers(distributorId: $distributorId, searchText: $searchText){
      rows {
            title
            address
            typeId
            panNo
            customerId
            openingBalanceType
            openingBalance
            id
            creditDay
            creditLimit
          } 
      count
    }
  }
`;

const mutation = {
  createLedger: CREATE_LEDGER,
};

const query = {
  billingCustomers: GET_BILLING_CUSTOMERS,
};

export {
  mutation, CREATE_LEDGER, query,
};
