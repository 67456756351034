import React, { Component, Fragment } from 'react';
import { PropTypes } from 'prop-types';
import Button from '../../../../components/Button';
import { CustomSelect, Icon } from '../../../../components';
import EVENT_OPERATION from '../../../../data/enums/EventOperation';

const propTypes = {
  data: PropTypes.shape({
    date: PropTypes.string,
    repetition: PropTypes.bool,
    userId: PropTypes.number,
    userTitle: PropTypes.string,
    routeId: PropTypes.number,
    lineId: PropTypes.number,
    visitFrequencyId: PropTypes.number,
  }),
  onDialogSubmit: PropTypes.func,
  crudMode: PropTypes.string,
};

const defaultProps = {
  onDialogSubmit: () => null,
};

class Dialog extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);

    const { data } = props;

    this.state = {
      refsObj: {
        route: null,
      },
      id: null,
      visitFrequency: {
        id: null,
        title: '',
      },
      data: {
        date: '2020-01-01',
        repetition: !!data.repetition,
        userId: data.userId || null,
        userTitle: data.userTitle || '',
        routeId: data.routeId || null,
        lineId: data.lineId || null,
        visitFrequencyId: data.visitFrequencyId || null,
        assignedRoute: data.assignedRoute || true,
      },
      crudMode: props.crudMode || EVENT_OPERATION.CREATE,
      enableErrorDisplay: false,
    };
  }

  componentDidMount() {
  }

  handleButtonSubmit = () => {
    const { data, visitFrequency } = this.state;

    if (!data.routeId) {
      this.setState({ enableErrorDisplay: true });
    }
    data.visitFrequencyId = visitFrequency.id;

    const { onDialogSubmit } = this.props;

    onDialogSubmit(data);

    // call the server with data
  };

  handleDropDownChange = (route) => {
    const { data } = this.state;
    data.routeId = route.id;
    data.visitFrequencyId = route.VisitFrequency.id;

    this.setState({
      data,
      visitFrequency: route.VisitFrequency,
    });
  };

  getVisitFrequencyTitle = (visitFrequencyId) => {
    const { routeVisitFrequencyList } = this.props;

    const visitFrequency = routeVisitFrequencyList
      .find(vFrequency => vFrequency.id === visitFrequencyId);

    return visitFrequency ? visitFrequency.label : '-';
  };

  handleRepeatButtonClick = (value = false) => {
    const { data } = this.state;
    data.repetition = value;
    this.setState({ data });
  };

  handleIconClose = () => {
    const { onDialogClose } = this.props;
    onDialogClose();
  };

  handleRouteAssignmentClick = (value = true) => {
    const { data } = this.state;
    data.assignedRoute = value;
    this.setState({ data });
  };

  getVisitFrequencyClassName = (frequencyId) => {
    return frequencyId ? `btn status_${frequencyId}` : 'btn';
  };


  render() {
    const {
      data,
      refsObj,
      enableErrorDisplay,
      crudMode,
    } = this.state;

    const {
      routeList,
    } = this.props;

    const filteredRouteList = routeList.filter(route => (
      data.assignedRoute ? route.assignedTo === data.userId : route.assignedTo !== data.userId
    ));

    return (
      <Fragment>
        <div className="roster-popup-inner">
          <div className="header">
            <h3>{crudMode === EVENT_OPERATION.UPDATE ? 'Edit Assigned Route' : 'Assign Route'}</h3>
            <Icon className="cursor-pointer" iconName="times"
                  onClick={() => this.handleIconClose()} />
          </div>
          <div className="body">
            <div className="user-title">
              <span className="sub-title">DSE</span>
              <h4>{data.userTitle}</h4>
              <div className="assigned-wrap">
                <div className="inline-radio-label">
                  <div>
                    <input
                      type="radio"
                      checked={data.assignedRoute}
                      onChange={() => this.handleRouteAssignmentClick(true)}
                    />
                    <span>Assigned Route</span>
                  </div>
                </div>
                <div className="inline-radio-label">
                  <div>
                    <input
                      type="radio"
                      checked={!data.assignedRoute}
                      onChange={() => this.handleRouteAssignmentClick(false)}
                    />
                    <span>Unassigned Route</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="route-frequency-wrap">
              <div className="route-frequency">
                <div className="route">
                  <span>Route</span>
                  <CustomSelect
                    ref={(ref) => {
                      refsObj.route = ref;
                      return null;
                    }}
                    clearable={false}
                    className="custom-select"
                    placeholder="Select Route"
                    getOptionLabel={({ title }) => title}
                    getOptionValue={({ id }) => id}
                    options={filteredRouteList}
                    enableValiation
                    enableErrorDisplay={enableErrorDisplay}
                    value={routeList.filter(({ id }) => id === data.routeId)}
                    onChange={route => this.handleDropDownChange(route)}
                  />
                </div>
                <div className="frequency">
                  <span>Frequency</span>
                  <div className={this.getVisitFrequencyClassName(data.visitFrequencyId)}>
                    {this.getVisitFrequencyTitle(data.visitFrequencyId)}
                  </div>
                </div>
              </div>
              <div className="repeat-block">
                <div className="inline-radio-label">
                  <div>
                    <input
                      type="radio"
                      name="relatedType"
                      checked={data.repetition}
                      onChange={() => this.handleRepeatButtonClick(true)}
                    />
                    <span>Repeat</span>
                  </div>
                </div>
                <div className="inline-radio-label">
                  <div>
                    <input
                      type="radio"
                      name="relatedType"
                      checked={!data.repetition}
                      onChange={() => this.handleRepeatButtonClick(false)}
                    />
                    <span>Once</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            (crudMode === EVENT_OPERATION.UPDATE && !data.repetition) ? (
              <div className="edit-text">
                <Icon iconName="exclamation-circle" />
                <span
                  className="ml-4"
                >
                  This change will assign route for the selected date only.
                </span>
              </div>
            ) : null
          }
          <div className={data.routeId ? 'assign-btn' : 'assign-btn disabled'}>
            <Button title="Assign" primary onClick={() => this.handleButtonSubmit()} />
          </div>
        </div>
      </Fragment>
    );
  }
}

Dialog.propTypes = propTypes;

Dialog.defaultProps = defaultProps;

export default Dialog;
