import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import {
  PROMOTION_FRAGMENT,
  CRITERIA_FRAGMENT,
  DISBURSEMENT_FRAGMENT,
  PROMOTION_AREA_FRAGMENT,
} from '../API';

import { query as focusedSKUQuery } from '../../../configuration/focusedSKU/API';
import { query as SBDQuery } from '../../../configuration/sbd/API';

const CUSTOM_GROUP_FRAGMENT = gql`
    fragment CustomGroupFragment on CustomGroup {
        criteria {
            ...CriteriaFragment
        }
        skus {
            id
            criteria {
                ...CriteriaFragment
            }
        }
    }
  ${CRITERIA_FRAGMENT}
`;

const PROMOTION_DETAILS_FRAGMENT = gql`
    fragment promotionDetailFragment on Promotion {
        ...promotionFragment
        promotionAreas {
            ...promotionAreaFragment
        }
        categoryIds
        channelCategoryInfo {
            channelId
            categoryIds
        }
        catalogInfo {
            catalogId
            catalogDetailIds
        }
        scope
        relatedType
        criteria {
            ...CriteriaFragment
        }
        disbursement {
            ...DisbursementFragment
        }
        customGroupStatus
        customGroupDetails {
            ...CustomGroupFragment
        }
        SKUs {
            id
            title
        }
        FocusedSKU{
            id
            start
            end
            active
        }
        SBD{
            id
            start
            end
            skuList {
                groupId
                skus
            }
        }
    }
    ${PROMOTION_FRAGMENT}
    ${CRITERIA_FRAGMENT}
    ${DISBURSEMENT_FRAGMENT}
    ${PROMOTION_AREA_FRAGMENT}
    ${CUSTOM_GROUP_FRAGMENT}
`;

const GET_PROMOTION_DETAILS = gql`
    query promotions($id: String!) {
        promotions(filter: {
            filters: [
                {
                    column: "id",
                    value: [$id]
                },
                {
                    column: "active",
                    value: ["true", "false"]
                }
            ]
        }) {
            count
            rows {
                ...promotionDetailFragment
            }
        }
    }
    ${PROMOTION_DETAILS_FRAGMENT}
`;

const TOGGLE_STATE = graphql(gql`
    mutation ($ids: [Int]!, $type: statefulEntities $active: Boolean!) {
        toggleState(ids: $ids, type:$type, active: $active)
    }`, {
  props: ({ mutate }) => ({
    toggleState: variables => mutate({
      variables,
    }),
  }),
});

const UPDATE_PROMOTION = graphql(
  gql`mutation($id: Int!, $input: PromotionInput!) {
      updatePromotion(id: $id, input: $input) {
          id
      }
  }
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      updatePromotion: variables => mutate({
        variables,
      }),
    }),
  },
);


const CREATE_PROMOTION = graphql(
  gql`
      mutation($input: PromotionInput!) {
          createPromotion(input: $input) {
              ...promotionDetailFragment
          }
      }
      ${PROMOTION_DETAILS_FRAGMENT}
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      createPromotion: variables => mutate({
        variables,
      }),
    }),
  },
);

const query = {
  getPromotionDetail: GET_PROMOTION_DETAILS,
  getFocusedSKUListWithTitle: focusedSKUQuery.getFocusedSKUListWithTitle,
  getSBDListWithTitle: SBDQuery.getSBDListWithTitle,
};

const mutation = {
  toggleState: TOGGLE_STATE,
  createPromotion: CREATE_PROMOTION,
  updatePromotion: UPDATE_PROMOTION,
};

export {
  CREATE_PROMOTION,
  TOGGLE_STATE,
  UPDATE_PROMOTION,
};

export { query, mutation };
