import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const GET_SBD_BASIC_DETAIL = gql`
    query getSBDBasicDetail($value: String) {
        sbds(filter: {
            filters:[
                {
                    column:"id",
                    value: [$value]
                },
                {
                    column:"active",
                    value: ["true", "false"]
                }
            ]
        }){
            rows {
                id
                title
                start
                end
                active
                SbdDetails {
                    id
                    channelId
                    categoryId
                    brandId
                    skus
                }
            }
        }

    }
`;

const GET_BRAND_DETAILS = gql`
    query getBrandDetails($catalogId: Int){
        catalogDetails(catalogId: $catalogId){
            rows {
                id
                title
                SKUs {
                    id
                    title
                }
            }
        }
    }
`;

const GET_SKU_DETAILS = gql`
    query getBrandsSkus($value: Int) {
            catalogLevelDetails(
                catalogId: 3
                catalogDetailId: $value
            ){
                count
                rows{
                    id
                    title
                    SKUs{
                        id
                        title
                    }
                }
            }
    }
`;

const GET_SBD_SKUS_COUNT = gql`
    query getSBDCount($sbdId: Int, $channelId: Int, $categoryId: Int, $brandId: Int ) {
        getSbdDetails(sbdId: $sbdId, channelId:$channelId, categoryId: $categoryId, brandId: $brandId){
            count
            rows{
                sbdId
                Channel{
                    id
                    count
                }
                Category{
                    id
                    count
                }
                Brand{
                    id
                    count
                }
                SkuGroup{
                    id
                    count
                    skus
                }
            }
    
        }
    }
`;

const CREATE_SBD_SKUS = graphql(gql`
    mutation createSBDSkus($input: SbdDetailsInput!) {
        insertSbdDetails(input:$input){
            id
            channelId
            categoryId
            brandId
            skus
        }
    }`, {
  props: ({ mutate }) => ({
    insertSbdDetails: variables => mutate({
      variables,
    }),
  }),
});

const DELETE_SBD_SKU = graphql(gql`
    mutation deleteSBDSku($id: Int!) {
        deleteSbdDetails(id:$id){
            id
            channelId
            categoryId
            brandId
            skus
        }
    }`, {
  props: ({ mutate }) => ({
    deleteSbdDetails: variables => mutate({
      variables,
    }),
  }),
});

const query = {
  getBrandDetails: GET_BRAND_DETAILS,
  getSkuDetails: GET_SKU_DETAILS,
  getSBDBasicDetail: GET_SBD_BASIC_DETAIL,
  getSBDCount: GET_SBD_SKUS_COUNT,
};

const mutation = {
  insertSbdDetails: CREATE_SBD_SKUS,
  deleteSbdDetails: DELETE_SBD_SKU,
};

export { query, mutation, CREATE_SBD_SKUS, DELETE_SBD_SKU };
