import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import {
  Form, Input, Icon,
} from '../../../../../components';
import withLoading from '../../../../../utils/composition/withLoading';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import CustomSelect from '../../../../../components/CustomSelect/CustomSelect';
import CustomAutoComplete from '../../../../../components/CustomAutoComplete';

const propTypes = {
  handleInputChange: PropTypes.func,
  data: PropTypes.instanceOf(Object),
  enableErrorDisplay: PropTypes.bool,
  update: PropTypes.instanceOf(Object),
  handleDropDownChange: PropTypes.func,
  refsObj: PropTypes.instanceOf(Object),
  outletList: PropTypes.instanceOf(Array),
  distributorList: PropTypes.instanceOf(Array),
};

const defaultProps = {
  data: {},
  refsObj: {
    title: '',
  },
  enableErrorDisplay: true,
  update: {
    type: EVENT_OPERATION.CREATE,
    status: false,
  },
  outletList: [],
  distributorList: [],
  handleDropDownChange: () => null,
  /**
   * agruments:
   * first param: input event (required),
   * second param: data's first depth targeted key,
   * third param:
   *  only if object depth greater than one,
   *  should contain list of object keys in accordance of depth upto end i.e target key
   */
  handleInputChange: () => null,

};

const PrnSkuForm = ({ ...props }) => {
  const {
    data,
    refsObj,
    subDUser,
    vendorList,
    permission,
    distributorList,
    loadingGrnStatus,
    handleInputChange,
    invoiceNumberList,
    enableErrorDisplay,
    handleDropDownChange,
    invoiceNumberStatus,
  } = props;

  const onInvoiceNumberSelection = (formattedValue, name) => {
    handleInputChange({ formattedValue, target: { name } });
  };

  return (
    <Form className="grn-form">
      <Row>
        <Col md={6}>
          <Row>
            {
              !subDUser && (
                <Fragment>
                  <Col className="mb-24" md={12}>
                    <CustomSelect
                      enableValidation
                      labelContent="Distributor"
                      options={distributorList}
                      className="custom-select"
                      placeholder="Select Distributors"
                      getOptionValue={({ id }) => id}
                      getOptionLabel={({ title }) => title}
                      enableErrorDisplay={enableErrorDisplay}
                      ref={ref => refsObj.distributorId = ref}
                      onChange={event => handleDropDownChange(event.id, ['distributorId'], 'distributor', event)}
                      value={distributorList.filter(({ id }) => id === data.distributorId)}
                    />
                  </Col>
                  <Col md={6} />
                </Fragment>
              )
            }
          </Row>
          <Row>
            <Col className="mb-24" md={6}>
              <div className="grn-label-text">
                {/*  <label>GRN Number</label>
                <input
                  name="grnInvoiceNumber"
                  type="text"
                  value={data.grnInvoiceNumber}
                  list="invoiceNumberListId"
                  autoComplete="off"
                  onChange={e => inputChange(e, 'input')}
                />
                <datalist id={ invoiceNumberList.length > 0 ? 'invoiceNumberListId' : 'invoiceNumberListIdTemporary'}>
                  { invoiceNumberList.map((item,key) => <option key={key} value={item} />)}
                </datalist> */}
                <CustomAutoComplete
                  name="grnInvoiceNumber"
                  onChange={handleInputChange}
                  dropDownList={invoiceNumberList}
                  labelContent="GRN Number"
                  placeholder="GRN Number"
                  dropDownValueKey={null}
                  dropDownDisplayKey={null}
                  enableValidation
                  ref={ref => refsObj.grnInvoiceNumber = ref}
                  enableErrorDisplay={enableErrorDisplay}
                  disabled={loadingGrnStatus}
                  onDropDownSelection={onInvoiceNumberSelection}
                />
                { invoiceNumberStatus && (
                <div className="success-label">
                  <Icon iconName="check-circle-full" />
                  <span>Valid Number</span>
                </div>
                )}
              </div>
            </Col>
            {
            !permission.BILLING && (
              <Col className="mb-24" md={6}>
                <Input
                  name="prnInvoiceNumber"
                  type="text"
                  value={data.prnInvoiceNumber}
                  labelContent="PRN Invoice Number"
                  placeholder="PRN Invoice Number"
                  onChange={event => handleInputChange(event)}
                />
              </Col>
            )
          }
            <Col className="mb-24" md={6}>
              <CustomSelect
                enableValidation
                labelContent="Vendor"
                options={vendorList}
                className="custom-select"
                placeholder="Select Vendors"
                getOptionValue={({ id }) => id}
                getOptionLabel={({ name }) => name}
                enableErrorDisplay={enableErrorDisplay}
                ref={ref => refsObj.vendorId = ref}
                onChange={event => handleDropDownChange(event.id, ['vendorId'])}
                value={vendorList.filter(({ id }) => id === data.vendorId)}
              />
            </Col>
            <Col className="mb-24" md={12}>
              <Input
                type="text"
                name="reason"
                enableValidation
                value={data.reason}
                placeholder="Add Remarks"
                labelContent="Remarks"
                ref={ref => refsObj.reason = ref}
                enableErrorDisplay={enableErrorDisplay}
                onChange={event => handleInputChange(event)}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

PrnSkuForm.propTypes = propTypes;

PrnSkuForm.defaultProps = defaultProps;

const PrnSkuFormWithErrorAndLoading = withLoading(PrnSkuForm);

export default PrnSkuFormWithErrorAndLoading;
