import styled from 'styled-components';

const CancelStyled = styled.div`
      display:flex;
      flex-direction:column;
          form{
            order:2;
            margin-top:24px;
          input{
            border:1px solid #E7E7ED;
            background-color:#F1F2F5;
              }
              }
          &>span{
            order:1;
            font-size:14px;
            line-height:17px;
            color:#272833;
          }
`;
export default CancelStyled;
