import { reports as reportsRoute } from '../../../data/enums/Route';
import { getFilterConfig } from '../../../utils/filter';
import { FILTER } from '../../../data/enums/filter';

const title = 'Attendance Report';

const breadCrumb = [reportsRoute];
const filter = getFilterConfig([
  FILTER.USER_ROLE,
  FILTER.SUB_D,
  FILTER.ASM,
  FILTER.STL,
]);

export { breadCrumb as breadCrumbConfig, title, filter as filterConfig };
