import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { fixedFloatAndCommas } from '../../../../utils/conversion';
import Badge from '../../../../components/Badge';
import { normalPresentor } from '../../../../utils/date';
import { USER_ROLE_TITLE } from '../../../../data/enums/UserRole';
import { headerLabelConfig } from '../../../common/HelperFunctions';
import TableHeader from '../../../base/TableHeader';

// todo update the configurations.
const labelMappings = {
  id: 'invoiceNumber',
  date: 'date',
  outlet: 'outlet',
  route: 'route',
  dse: 'dse',
  totalNetValue: 'totalNetValue',
  distributor: 'distributor',
  channel: 'channel',
  category: 'category',
  channelAndCategory: 'channel',
};

const returnTableBody = (data, onRowClick) => {
  const table = {};
  table[labelMappings.id] = (
    <td onClick={() => onRowClick(data.id, data.Distributor.id)}>
      {
      /** todo chip implementation here */
        <span>
          <span className="tab-label">{data.invoiceNumber}</span>
          <Badge light label={data.orderIds.length} />
        </span>
    }

    </td>
  );
  table[labelMappings.date] = (
    <td onClick={() => onRowClick(data.id, data.Distributor.id)}>{normalPresentor(data.invoiceDate)}</td>
  );
  table[labelMappings.outlet] = (
    <td onClick={() => onRowClick(data.id, data.Distributor.id)}>
      <a href={`outlet/details/${data.RetailOutlet.id}`} onClick={e => e.preventDefault()}>
        {data.RetailOutlet ? data.RetailOutlet.title : ''}
      </a>
    </td>
  );
  table[labelMappings.channel] = (
    <td onClick={() => onRowClick(data.id, data.Distributor.id)}>
      {data.Channel && data.Category
        ? `${data.Channel.title}/${data.Category.title}` : ''}
    </td>
  );
  table[labelMappings.route] = (
    <td onClick={() => onRowClick(data.id, data.Distributor.id)}>{data.Route ? data.Route.title : ''}</td>
  );
  table[labelMappings.dse] = (
    <td onClick={() => onRowClick(data.id, data.Distributor.id)}>{data.User ? data.User.fullName : ''}</td>
  );

  table[labelMappings.totalNetValue] = (
    <td
      className="text-right"
      onClick={() => onRowClick(data.id, data.Distributor.id)}
    >
      {fixedFloatAndCommas(data.amountDetails ? data.amountDetails.netAmount || 0 : 0)}
    </td>
  );
  table[labelMappings.distributor] = (
    <td onClick={() => onRowClick(data.id, data.Distributor.id)}>{data.Distributor.title}</td>
  );


  return table;
};


const labelConfig = [
  {
    id: 1,
    label: labelMappings.id,
    title: 'Invoice Number',
    show: true,
    className: 'sorting',
    sortable: true,
  },
  {
    id: 2,
    label: labelMappings.date,
    title: 'Invoice Date',
    show: true,
    className: 'sorting',
    sortable: true,
  },
  {
    id: 3,
    label: labelMappings.outlet,
    title: 'Outlet',
    show: true,
    className: 'sorting',
    sortable: true,
  },
  {
    id: 4,
    label: labelMappings.channel,
    title: 'Channel/Category',
    show: true,
    className: 'sorting',
    sortable: true,
  },
  {
    id: 5,
    label: labelMappings.route,
    title: 'Route',
    show: true,
    className: 'sorting',
    sortable: true,
  },
  {
    id: 6,
    label: labelMappings.dse,
    title: headerLabelConfig[USER_ROLE_TITLE.DSE],
    show: true,
    className: 'sorting',
    sortable: true,
  },
  {
    id: 7,
    label: labelMappings.totalNetValue,
    title: 'Invoice Value',
    show: true,
    className: 'sorting',
    sortable: true,
    align: true,
  },
  {
    id: 8,
    label: labelMappings.distributor,
    title: 'Distributor',
    show: true,
    className: 'sorting',
    sortable: true,
  },
];

const TableBody = ({ data, onRowClick }) => {
  // Expanding/Dragging and collapsing table body.
  // color based upon table body
  // tooltip if it is sorted.
  // active className
  const tableBody = returnTableBody(data, onRowClick);

  return <Fragment>{labelConfig.map(label => (label.show ? tableBody[label.label] : null))}</Fragment>;
};

const TableHeaderConfig = ({ onHeaderClick }) => <TableHeader headerDetails={labelConfig} onHeaderClick={onHeaderClick} />;


TableHeaderConfig.propTypes = {
  onHeaderClick: PropTypes.func,
};

TableBody.propTypes = {
  data: PropTypes.object.isRequired,
};


export {
  TableHeaderConfig as TableHeader,
  TableBody,
};
