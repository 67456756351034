import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

/** const SamplePaginationAPI = graphql(gql`
  mutation ($username: String!, $password: String!) {
    login( username: $username, password: $password)
    {
      token
    }
  }`, {
  // name: 'loginWithServer',
  props: ({ mutate }) => ({
    loginWithServer: (username, password) => mutate({
      variables: {
        username,
        password,
      },
    }),
  }),
});*/

const SamplePaginationAPI = graphql(gql`
  mutation ($username: String!, $password: String!) {
    login( username: $username, password: $password)
    {
      token
    }
  }`, {
  // name: 'loginWithServer',
  props: ({ mutate }) => ({
    loginWithServer: variables => mutate({
      variables,
    }),
  }),
});

export default SamplePaginationAPI;
