import { reports as reportsRoute } from '../../../data/enums/Route';
import { getFilterConfig } from '../../../utils/filter';
import { FILTER } from '../../../data/enums/filter';
import { USER_ROLE_TITLE } from '../../../data/enums/UserRole';
import { headerLabelConfig } from '../../common/HelperFunctions';


const title = `${headerLabelConfig[USER_ROLE_TITLE.DSE]} Productivity`;

const breadCrumb = [
  reportsRoute,
];

const filter = getFilterConfig([FILTER.ROUTE, FILTER.SUB_D, FILTER.TOWN, FILTER.ASM, FILTER.STL, FILTER.DSE]);


export {
  breadCrumb as breadCrumbConfig, filter as filterConfig, title,
};
