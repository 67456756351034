import isObject from 'subscriptions-transport-ws/dist/utils/is-object';
import { has } from './objectPrototypes';
import { TYPENAME } from '../data/enums/GraphQL';

const updateObjectAttribute = (object, parameterList, newAttributeValue) => {
  parameterList.reduce((acc, value, index, list) => {
    if (index === list.length - 1) {
      acc[value] = newAttributeValue;
    }

    return acc[value];
  }, object);

  return object;
};

const checkExistAndReturnValue = (obj = {}, prop = '', defaultValue = null) => {
  if (typeof obj === 'object') {
    return has.call(obj, prop) ? obj[prop] || defaultValue : defaultValue;
  }
  return defaultValue;
};

const clone = list => JSON.parse(JSON.stringify(list));

const convertObjToArray = (obj, dataFormatter) => {
  if (isObject(obj)) {
    // return Object.keys(obj).map(key => ( dataFormatter ? dataFormatter(obj[key],key) : {label: key, value: obj[key]}) ) || [];
    return Object.keys(obj).reduce((acc, key, i) => {
      if (key !== TYPENAME) {
        acc.push(dataFormatter ? dataFormatter(obj[key], key) : { label: key, value: obj[key] });
      }
      return acc;
    }, []);
  }
  return [];
};

export {
  checkExistAndReturnValue, updateObjectAttribute, clone, convertObjToArray,
};
