import React, { Component, Fragment } from 'react';
import {
  breadCrumbConfig, formConfig, getCategoriesObj, crudRequestConfig,
} from './config';
import { FORM_CONFIG } from '../../../../data/enums/config';
import ChannelDetailView from './View';
import { EVENT_OPERATION, EVENT_OPERATION_MAPPER } from '../../../../data/enums/EventOperation';
import { PanelHeader, PanelStyled } from '../../../common/configuration';
import { BreadCrumb, Button } from '../../../../components';
import { channelCategory } from '../../../common/DomainConfig';
import { refValidator } from '../../../../utils/refGenerator';
import { handleFormSubmit, responseInterpreter } from '../../../../utils/crudResponseProcessor';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import withAlert from '../../../../utils/composition/withAlert';
import { getPermissionForChannelCategory } from '../../../base/permission';
import { isError } from '../../../common/HelperFunctions';
import ChannelStyled from '../ChannelStyled';

const propTypes = {};

const defaultProps = {};

class Detail extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    const params = props.match.params ? props.match.params : {};
    const id = (params.id && params.id !== 'create')
      ? parseInt(params.id, 10)
      : null;
    const serverCall = {
      [EVENT_OPERATION.CREATE]: props.createChannel,
      [EVENT_OPERATION.UPDATE]: props.updateChannel,
      [EVENT_OPERATION.DELETE]: props.toggleState,
    };
    this.state = {
      data: formConfig[FORM_CONFIG.MAPPER]({}),
      updatedCategoryIds: [],
      id,
      crudMode: id ? EVENT_OPERATION.READ : EVENT_OPERATION.CREATE,
      enableErrorDisplay: false,
    };
    this.onCRUDSuccess = responseInterpreter(this.onMutationSuccess);
    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.onMutationFailure,
      crudRequestConfig, serverCall);
    this.permission = getPermissionForChannelCategory();
  }

  componentDidMount() {
    const { id } = this.state;
    if (id) this.loadTableData();
  }

  loadTableData = () => {
    const { id } = this.state;
    const { getChannelDetails } = this.props;

    getChannelDetails({
      offset: 0,
      limit: 1,
      filter: {
        filters: [
          { column: 'id', value: [id.toString()] },
          { column: 'active', value: ['true', 'false'] },
        ],
      },
    },
    {
      handleSuccess: (response) => {
        const channel = (response.data.channels && response.data.channels.rows) || [];
        const formattedData = formConfig[FORM_CONFIG.MAPPER](channel[0] || {});
        if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
        this.setState({ data: formattedData });
      },
      handleError: error => this.onAPIRequestFailure(error),
    });
  };

  handleAddButtonClick = () => {
    const { data } = this.state;
    const category = getCategoriesObj({});
    data.categories.push(category);

    this.setState({ data });
  };

  handleCategoryChange = (event, index) => {
    const { data, updatedCategoryIds, crudMode } = this.state;
    const elem = data.categories[index];
    elem[event.target.name] = event.target.value;
    data.categories[index] = elem;

    if (elem.id === 0 && !elem.status) data.categories.splice(index, 1);
    if (crudMode === EVENT_OPERATION.UPDATE) {
      const catIndex = updatedCategoryIds.indexOf(elem.id);
      if (catIndex === -1) updatedCategoryIds.push(elem.id);
    }

    this.setState({ data });
  };

  handleInputChange = (e) => {
    const { data } = this.state;
    data[e.target.name] = e.target.value;

    this.setState({ data });
  };

  getHeader = () => {
    const { crudMode, data } = this.state;
    const header = crudMode === EVENT_OPERATION.UPDATE ? data.title : channelCategory.title;
    if (crudMode === EVENT_OPERATION.READ) return data.title;

    return <><span>{EVENT_OPERATION_MAPPER[crudMode].toLowerCase()}</span> {header}</>;
  };

  handleIconClick = (type) => {
    /** todo: handle delete button click, show confirmation dialog * */
    this.updateCrudMode(type);
  };

  updateCrudMode = (crudMode) => {
    this.setState({ crudMode });
  };

  handleSaveClick = () => {
    const { data, updatedCategoryIds, crudMode } = this.state;
    const getCategoriesValidation = this.updateErrorDisplay();
    const channelValidation = refValidator(formConfig[FORM_CONFIG.REFS_OBJ]);
    if (!getCategoriesValidation || !channelValidation) {
      this.setState({ enableErrorDisplay: true });
    } else {
      this.onFormSubmit(crudMode, { data, updatedCategoryIds });
    }
  };

  handleCancelClick = () => {
    const { crudMode } = this.state;
    if (crudMode === EVENT_OPERATION.CREATE) {
      this.setState({
        data: formConfig[FORM_CONFIG.MAPPER]({}),
      });
    } else {
      this.setState({
        crudMode: EVENT_OPERATION.READ,
      });
    }
  };

  updateErrorDisplay = () => {
    const { data } = this.state;
    let valid = true;
    data.categories.map((item) => {
      const getValidation = refValidator(item[FORM_CONFIG.REFS_OBJ]);
      if (!getValidation) {
        item.displayError = true;
        valid = false;
      }
    });
    this.setState({ data });

    return valid;
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  onMutationSuccess = (type, response) => {
    const data = formConfig[FORM_CONFIG.MAPPER](response || {});
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message);
    this.setState({
      crudMode: EVENT_OPERATION.READ,
      data,
    });
  };

  onMutationFailure = (error) => {
    const { id } = this.state;
    if (id) this.loadTableData();
    this.onAPIRequestFailure(error);
  };


  render() {
    const {
      enableErrorDisplay, data, crudMode, id,
    } = this.state;
    const { serverResponseWaiting } = this.props;
    const header = this.getHeader();
    const showEditButton = crudMode === EVENT_OPERATION.READ && this.permission.update;
    const updateMode = crudMode !== EVENT_OPERATION.READ;
    return (
      <Fragment>
        <div className="section-header">
          <PanelStyled>
            <BreadCrumb list={breadCrumbConfig} />
            <PanelHeader>
              <div className="flex items-center justify-between m-0 flex-1">
                <h2>{header}</h2>
                <div className="flex m-0">
                  {
                    crudMode !== EVENT_OPERATION.READ && (
                      <div>
                        <Button
                          secondary
                          disabled={serverResponseWaiting}
                          small
                          title="Cancel"
                          onClick={() => this.handleCancelClick()}
                        />
                        <Button
                          primary
                          disabled={serverResponseWaiting}
                          small
                          title="Save"
                          onClick={() => this.handleSaveClick()}
                        />
                      </div>
                    )
                  }
                  {
                    showEditButton && (
                    <div className="text-right">
                      <Button
                        outlined
                        iconBtnSmall
                        iconName="pencil"
                        onClick={() => this.handleIconClick(EVENT_OPERATION.UPDATE)}
                      />
                    </div>
                    )
                  }
                </div>
              </div>
            </PanelHeader>
          </PanelStyled>
        </div>
        <ChannelStyled>
          <div className="section-content">
            <div className={!updateMode ? 'disabled' : null}>
              <ChannelDetailView
                enableErrorDisplay={enableErrorDisplay}
                data={data}
                loading={serverResponseWaiting}
                crudMode={crudMode}
                channelRefs={formConfig[FORM_CONFIG.REFS_OBJ]}
                onCategoryChange={this.handleCategoryChange}
                onInputChange={this.handleInputChange}
                onAddButtonClick={this.handleAddButtonClick}
              />
            </div>
          </div>
        </ChannelStyled>
      </Fragment>
    );
  }
}

Detail.propTypes = propTypes;

Detail.defaultProps = defaultProps;

export default withAlert()(Detail);
