import styled from 'styled-components';

const ColumnSelectStyled = styled.div`
          .filter-icon{
            button>span{
              position:relative;
              top:-2px;
            }
          }
      .filter-dropdown-card.column{
        min-width:214px;
        .title-col{
          border-bottom:1px solid #E7E7ED;
          padding:0 16px;
          white-space:nowrap;
          height:48px;
          font-size:12px;
          text-transform:uppercase;
          color:#272833;
          font-weight:500;
          label{
            position:relative;
            top:2px;
            .control-label{
              padding-left: 12px;
            }
          }
          .title{
            display:inline-block;
            line-height:48px;
          }
        }
        .select-li-wrap{
          padding:6px 0;
          height:270px;
          overflow:auto;
        .select-li{
          padding:8px 16px;
          font-size:14px;
          line-height:21px;
          font-weight:500;
          white-space:nowrap;
          .title{
            position:relative;
            top:-1px;
          }
          label{
            .control-label{
              padding-left: 12px;
            }
          }
        }
        }
      }
`;
export default ColumnSelectStyled;
