/* global google */
const setMarkerImage = (marker, pointx, pointy, width, height) => {
    let point = (pointx && pointy) ? new google.maps.Point(pointx, pointy) : null;
    const markerImage = new google.maps.MarkerImage(
        marker,
        null,
        null,
        point,
        new google.maps.Size(width, height)
    )
    return markerImage;
}
export default setMarkerImage;