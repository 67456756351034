import React from 'react';
import { fixedFloatAndComma, fixedFloatAndCommas } from '../../../../utils/conversion';


const check = number => (number === null || isNaN(number)
  ? 0
  : fixedFloatAndComma(number));

const Number = ({
  number, label, size, numberPostfix,
}) => (
  <span style={{ fontSize: `${size}px` }}>
    { numberPostfix ? (isNaN(parseInt(number)) ? 0
      : fixedFloatAndCommas(number)) : check(number) }
    <span style={{ fontSize: `${size - 10}px` }}>{ label }</span>
  </span>
);

Number.propTypes = {

};

export default Number;
