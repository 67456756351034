import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import BuView from './View';
import * as arrayProcessor from '../../../utils/arrayProcessor';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import {
  breadCrumbConfig,
  formConfig,
  responseToState,
} from './config';
import { handleFormSubmit } from '../../../utils/crudResponseProcessor';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { refGenerator } from '../../../utils/refGenerator';
import { dropdownChange, inputChange } from '../../../utils/formHandlers';
import { presentor } from '../../../utils/date';
import { getPermissionForBuSettings } from '../../base/permission';
import { storeInLocalStorage } from '../../common/HelperFunctions';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  getBuData: PropTypes.func.isRequired,
  updateBU: PropTypes.func.isRequired,
  callProcedureDetailList: PropTypes.func.isRequired,
  createCallProcedure: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class BU extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      data: {
        currency: '',
        fiscalYear: {
          start: presentor(new Date()),
          end: presentor(new Date()),
        },
        unitsOfMeasurement: [],
        dateSettings: {
          format: '',
          calendar: '',
        },
        orderForMultipleDistributors: false,
        targetDimension: '',
        geoFencing: '',
        pfd: false,
        multipleDseInRoute: false,
        payment: false,
        srn: false,
        sales: {
          full: false,
          partial: false,
          both: false,
        },
        sbd: false,
        focusedSKU: false,
        orderStatus: {
          received: false,
          invoiced: false,
          dispatched: false,
          delivered: false,
        },
        targets: {
          salesTeamWise: false,
          subDWise: false,
          brandWise: false,
        },
        skuAssortmentMenu: false,
        skuAssortment: {
          channel: false,
          distributorGroup: false,
        },
      },
      crudMode: EVENT_OPERATION.READ,
      DSESequenceData: [],
      MERCHANDISINGSequenceData: [],
      DSESequenceUpdateData: [],
      MERCHANDISINGSequenceUpdateData: [],
      callHolidaySection: false,
    };
    this.permission = getPermissionForBuSettings();
    this.onFormSubmit = handleFormSubmit(
      this.onCRUDSuccess,
      this.onAPIRequestFailure,
    );
    this.formReference = refGenerator(formConfig.validationField);
  }

  componentDidMount() {
    this.getData();
    this.getSequence('DSE');
    this.getSequence('MERCHANDISING');
  }

  getData() {
    const { getBuData, displayAlert } = this.props;
    const { data } = this.state;
    getBuData({}, {
      handleSuccess: (response) => {
        const { settings } = response.data;
        const formattedData = settings !== null ? responseToState(settings) : data;
        this.setState({ data: formattedData });
      },
      handleError: (err) => {
        this.onAPIRequestFailure(err);
      },
    });
  }

  handleCheckBoxClick = (id) => {
    const { checkedList } = this.state;
    const toggledList = arrayProcessor.toggle([...checkedList], id);
    this.setState({ checkedList: toggledList });
  };

  handleDateRangeChange = (name, date) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        ...{
          fiscalYear: {
            ...data.fiscalYear,
            ...{
              [name]: date,
            },
          },
        },
      },
    });
  };

  handleInputChange = (event, firstParam = '', paramList = []) => {
    const { data } = this.state;
    const updatedDetails = inputChange(data, event, firstParam, paramList);

    if (event.target.name === 'skuAssortmentMenu' && !event.formattedValue) {
      updatedDetails.skuAssortment.channel = false;
      updatedDetails.skuAssortment.distributorGroup = false;
    }

    this.setState({ data: updatedDetails });
  };

  handleDropDownChange = (value, parameterRef = [], callBack = () => null) => {
    const { data } = this.state;
    const updatedData = dropdownChange(data, parameterRef, value);
    this.setState({ data: updatedData });
  };

  handleSubmit = () => {
    const { updateBU, displayAlert } = this.props;
    const { callHolidaySection, data } = this.state;
    this.handleSubmitSequenceData('DSE');
    this.handleSubmitSequenceData('MERCHANDISING');
    this.setState({
      callHolidaySection: !callHolidaySection,
    });
    const { skuAssortmentMenu, ...formattedData } = data;

    updateBU({
      input: { ...formattedData },
    }, {
      handleSuccess: (response) => {
        const { storeSettings } = response.data;
        this.setState({
          crudMode: EVENT_OPERATION.READ,
        });
        displayAlert(ALERT_TYPE.INFO, storeSettings);
        storeInLocalStorage('bu-settings', formattedData, 'rosia');
      },
      handleError: (err) => {
        this.onAPIRequestFailure(err);
      },
    });
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
    console.log(error);
  };

  onTargetChange = (e) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        ...{
          targets: {
            brandWise: data.targets.brandWise,
            ...{ [e.target.value]: e.target.checked },
          },
        },
      },
    });
  };

  onSalesChange = (e) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        ...{
          sales: {
            ...{ [e.target.value]: e.target.checked },
          },
        },
      },
    });
  };

  handleEditIconClick = () => {
    this.setState({
      crudMode: EVENT_OPERATION.UPDATE,
    });
  };

  handleCancelButton = () => {
    this.setState({
      crudMode: EVENT_OPERATION.READ,
    });
    this.getData();
  };

  handleMeasurementAdd = () => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        ...{
          unitsOfMeasurement: [
            ...data.unitsOfMeasurement,
            ...[{
              title: null,
              label: null,
            }],
          ],
        },
      },
    });
  };

  handleMeasurementCancel = (title) => {
    const { data } = this.state;
    const { unitsOfMeasurement } = data;
    const filterData = [...unitsOfMeasurement].filter(d => d.title !== title);
    this.setState({
      data: {
        ...data,
        ...{
          unitsOfMeasurement: filterData,
        },
      },
    });
  };

  getSequence = (type) => {
    const { callProcedureDetailList } = this.props;

    callProcedureDetailList({ type }, {
      handleSuccess: (response) => {
        const { rows } = response.data.callProcedureDetails;
        this.setState({
          [`${type}SequenceData`]: rows.map(d => ({
            ...d,
            status: true,
          })),
        });
        this.procedures(rows, type);
      },
    });
  };

  procedures = (procedureData, type) => {
    const creatingProcedure = {
      domain: 'BU',
      refId: 1,
      type,
      callProcedureSequence: procedureData.map(({ id, sequence, active }) => ({
        id: parseInt(id, 10),
        sequence,
        active,
      })),
    };
    this.setState({
      [`${type}SequenceUpdateData`]: creatingProcedure,
    });
  };

  handleSubmitSequenceData = (type) => {
    const { createCallProcedure, displayAlert } = this.props;

    const callSequenceList = this.state[`${type}SequenceUpdateData`];

    const validForServerCall = callSequenceList.callProcedureSequence.some(list => list.active !== null);

    if (validForServerCall) {
      createCallProcedure({
        input: this.state[`${type}SequenceUpdateData`],
      }, {
        handleSuccess: (response) => {
          const { createCallProcedure: callMessage } = response.data;
          displayAlert(ALERT_TYPE.SUCCESS, callMessage.message);
        },
        handleError: (err) => {
          this.onAPIRequestFailure(err);
        },
      });
    }
  };

  render() {
    const {
      data, header, crudMode, DSESequenceData, MERCHANDISINGSequenceData, callHolidaySection,
    } = this.state;
    const { serverResponseWaiting, match } = this.props;
    return (
      <Fragment>
        <BuView
          data={data}
          header={header}
          breadCrumb={breadCrumbConfig}
          loading={serverResponseWaiting}
          onInputChange={this.handleInputChange}
          onCheckBoxClick={this.handleCheckBoxClick}
          handleDropDownChange={this.handleDropDownChange}
          handleDateRangeChange={this.handleDateRangeChange}
          onTargetChange={this.onTargetChange}
          onSalesChange={this.onSalesChange}
          crudMode={crudMode}
          handleButtonSubmit={this.handleSubmit}
          handleButtonCancel={this.handleCancelButton}
          handleEditIconClick={this.handleEditIconClick}
          handleMeasurementAdd={this.handleMeasurementAdd}
          handleMeasurementCancel={this.handleMeasurementCancel}
          DSESequenceData={DSESequenceData}
          MerchandisingSequenceData={MERCHANDISINGSequenceData}
          procedures={this.procedures}
          permission={this.permission}
          params={match.params}
          callHolidaySection={callHolidaySection}
        />
      </Fragment>
    );
  }
}

BU.propTypes = propTypes;

BU.defaultProps = defaultProps;

export default withAlert()(BU);
