import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Icon, Switch } from '../../../components';


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 3;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  borderRadius: 5,

  // change background colour if dragging
  background: isDragging ? 'white' : 'transparent',
  boxShadow: isDragging ? '1px 1px 1px #00000070' : '',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'transparent' : 'transparent',
  padding: grid,
  width: 350,
});


const VerticalDragSwitch = (props) => {
  const { procedures, data: getItems, type } = props;

  const data = getItems.map((d, index) => ({
    id: d.CallProcedure.id.toString(),
    title: d.CallProcedure.title,
    active: d.active === null ? false : d.active,
    sequence: index + 1,
  }));
  const [item, setItem] = useState(data);
  const [checked, setChecked] = useState({});

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      item,
      result.source.index,
      result.destination.index,
    );
    items.forEach((d, i) => {
      d.sequence = i + 1;
    });
    procedures(items, type);
    setItem(items);
  };

  const onToggle = (e, id) => {
    setChecked({
      ...checked,
      [id]: !e,
    });
    item.find(d => d.id === id).active = e;
    setItem(item);
    procedures(item, type);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {item.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    className="form-input"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style,
                    )}
                  >
                    <Switch
                      checked={item.active}
                      labelContent={
                        <div className="drag-content">
                          <Icon iconName="drag" />
                          <span className="drag-number">
                            {index + 1}
                            {' '}
                          </span>
                          <span>{item.title}</span>
                        </div>
                      }
                      onToggle={e => onToggle(e, item.id)}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default VerticalDragSwitch;
