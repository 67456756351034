import React, { Fragment } from 'react';
import { Col } from 'react-flexbox-grid';
import PropTypes from 'prop-types';
import withErrorAndLoadingScreen from '../../../utils/composition/withLoading';
import CustomSelect from '../../../components/CustomSelect';
import { Pagination } from '../../../components';
import OpeningBalanceStyled from './OpeningBalanceStyle';
import { TableBody, TableHeader } from './tableConfig';

const propTypes = {
  componentDisplay: PropTypes.instanceOf(Object),
  distributorList: PropTypes.instanceOf(Array),
};
const defaultProps = {
  componentDisplay: {},
  distributorList: [],
};

const OpeningBalanceView = ({
  data, loading, pagination, onPageChange, onDropDownChange, distributorList, enableErrorDisplay, componentDisplay, inputData, onHeaderClick,
}) => (
  <Fragment>
    <OpeningBalanceStyled>
      <div className="distributor-selection">
        {
            componentDisplay.distributorSelection && (
            <div>
              <Col md={3} className="mt-6">
                <CustomSelect
                  small
                  enableValidation
                  options={distributorList}
                  className="custom-select"
                  placeholder="Select Distributor"
                  getOptionValue={({ id }) => id}
                  getOptionLabel={({ title }) => title}
                  enableErrorDisplay={enableErrorDisplay}
                  onChange={event => onDropDownChange(event.id)}
                  value={distributorList.filter(({ id }) => id === inputData.distributorId) || []}
                />
              </Col>
            </div>
            )
          }
      </div>
      {
          data.list.length > 0 ? (
            <Fragment>
              <div className="table-wrap">
                <table>
                  {
                     TableHeader({ onHeaderClick })
                  }
                  <tbody>
                    {data.list.map(openingBalance => (
                      TableBody({ data: openingBalance })
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                currentPage={pagination.page}
                limit={pagination.limit}
                totalItems={data.total}
                onPageChange={onPageChange}
              />
            </Fragment>
          )
            : inputData.distributorId && <div className="opening-balance-container"><div className="text"> Please upload the opening balance.</div></div>
        }
    </OpeningBalanceStyled>
  </Fragment>
);

OpeningBalanceView.propTypes = propTypes;

OpeningBalanceView.defaultProps = defaultProps;

const OpeningBalanceViewWithErrorAndLoading = withErrorAndLoadingScreen(OpeningBalanceView);

export default OpeningBalanceViewWithErrorAndLoading;
