import React, { Component } from 'react';
import View from './View';
import {
  labelConfig,
} from './tableConfig';
import withAlert from '../../../../utils/composition/withAlert';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import CashWraper from './CashWrapper';
import * as queryService from '../../../base/query.service';
import {
  isError,
} from '../../../common/HelperFunctions';
import {
  CASH_TABS, tabMapper,
} from './config';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../../utils/api';
import { getPermissionForPayment } from '../../../base/permission';


class Cash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pending: {
        list: [],
        total: 0,
      },

      received: {
        list: [],
        total: 0,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
      },
      activeTab: 0,
      tableInfo: labelConfig,

    };
    this.permission = getPermissionForPayment();
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.getPaymentsList,
      props.downloadReport,
      props.displayAlert,
    );
    this.getData = () => {
      const { pending } = this.state;
      return pending;
    };

    this.tabListGetterMapper = {
      [CASH_TABS.PENDING]: this.getPaymentsList,
      [CASH_TABS.RECEIVED]: this.getReceivedList,
    };
  }

  componentDidMount() {
    this.getInitialData();
  }

  getInitialData = () => {
    this.getReceivedList();
    const { activeTab } = this.state;
    this.fetchActiveTabPaginatedList(activeTab);
  }

  getPaymentsList = (tabStatus) => {
    const {
      pending, queryParameters, activeTab,
    } = this.state;
    const { payments } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    const status = tabStatus || tabMapper[activeTab];
    payments(
      {
        limit: queryParameters.pagination.limit,
        offset,
        filter: {
          filters: [...apiFilterProcessor(queryParameters.filter), ...[{
            column: 'collection_type',
            value: ['CASH'],

          },
          {
            column: 'status',
            value: [status.toUpperCase()],

          }]],
          dateRange: queryParameters.date,
          queryString: queryParameters.search,
        },
      },
      {
        handleSuccess: (response) => {
          const payment = (response.data.payments && response.data.payments.rows) || [];
          this.state[status] = {
            total: (response.data.payments && response.data.payments.count) || 0,
            list: payment,
          };
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(pending);
        },
        handleError: error => this.onAPIRequestFailure(error),
      },
    );
  };


 getReceivedList = () => {
   this.getPaymentsList(CASH_TABS.RECEIVED);
 };

    onAPIRequestFailure = (error) => {
      const { displayAlert } = this.props;
      displayAlert(ALERT_TYPE.DANGER, error);
      console.log(error);
    };

    fetchActiveTabPaginatedList = (tab) => {
      const type = tabMapper[tab];
      this.tabListGetterMapper[type]();
    };

setQueryParameters = (queryParams, callBack = () => null) => this.setState(
  { queryParameters: queryParams },
  callBack,
);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  handleTabChange = (tab) => {
    this.setState({
      activeTab: tab,
      tableInfo: labelConfig,
    },
    () => this.fetchActiveTabPaginatedList(tab));
  };

    controlDisplay = (label, value) => {
      const { display } = this.state;
      display[label] = value;
      this.setState(display);
    };

  columnSelectChange = (data) => {
    this.setState({
      tableInfo: data,
    });
  }

  onButtonClick = () => {
    this.getInitialData();
  }

  handleDownloadClick = (reportType, domain) => {
    const { queryParameters } = this.state;

    const filter = {
      filters: [
        ...apiFilterProcessor(queryParameters.filter),
        {
          column: 'collection_type',
          value: ['CASH'],
        },
      ],
      queryString: queryParameters.search,
      dateRange: queryParameters.date,
    };

    this.basePaginationService.handleDownloadClickWithCustomFilter('csv', domain, filter);
  };

  render() {
    const {
      activeTab,
      tableInfo,
      pending,
      received,
      queryParameters,
    } = this.state;
    const { serverResponseWaiting } = this.props;

    return (
      <CashWraper>
        <View
          pending={pending}
          receive={received}
          activeTab={activeTab}
          onTabChange={this.handleTabChange}
          tableConfig={tableInfo}
          columnSelectChange={this.columnSelectChange}
          queryParameters={queryParameters}
          basePaginationService={this.basePaginationService}
          handleFilterChange={this.basePaginationService.handleFilterChange}
          onButtonClick={this.onButtonClick}
          handleDownloadClick={this.handleDownloadClick}
          loading={serverResponseWaiting}
          permission={this.permission}
        />
      </CashWraper>
    );
  }
}

export default withAlert()(Cash);
