import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import UserAccountView from './View';
import history from '../../../../utils/history';
import PageHeader from '../../../base/PageHeader';
import { breadCrumbConfig, filterConfig } from './config';
import { isError } from '../../../common/HelperFunctions';
import { userAccount } from '../../../common/DomainConfig';
import * as queryService from '../../../base/query.service';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import { PanelStyled } from '../../../common/configuration';
import withAlert from '../../../../utils/composition/withAlert';
import { insertUserLabel } from '../../../../data/services/userLabel';
import { getPermissionForUserAccount } from '../../../base/permission';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../../utils/api';
import {
  USER_ACCOUNT,
  USER_CREATE,
} from '../../../../data/enums/Route';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  createUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  getUserList: PropTypes.func.isRequired,
  toggleState: PropTypes.func.isRequired,
  toggleUser: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class UserAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      display: {
        searchBox: false,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { ... queryService.baseQueryParameters.date },
      },
      parentUsers: [],
      roles: [],
    };
    this.permission = getPermissionForUserAccount();
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      props.displayAlert,
    );
    this.getData = () => {
      const { data } = this.state;
      return data;
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount() {
    insertUserLabel();
    this.loadTableData();
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  loadTableData = () => {
    const { data, queryParameters } = this.state;
    const { getUserList } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);

    getUserList({
      offset,
      limit: queryParameters.pagination.limit,
      filter: {
        filters: apiFilterProcessor(queryParameters.filter),
        //dateRange: queryParameters.date,
        queryString: queryParameters.search,
      },
    }, {
      handleSuccess: (response) => {
        data.list = (response.data.users && response.data.users.rows) || [];
        data.total = (response.data.users && response.data.users.count) || 0;
        if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
        this.setState(data);
      },
      handleError: (error) => this.onAPIRequestFailure(error)
    });
  };

  setQueryParameters = (queryParams, callBack = () => null) => this.setState(
    { queryParameters: queryParams },
    callBack,
  );

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  fetchDialogData = (data) => {
    this.getParentUsers(data.roleId);
  };

  onIconClick = () => {
    history.push(`/${USER_CREATE}`);
  };

  onTableRowClick = (userId) => {
    history.push(`/${USER_ACCOUNT}/${userId}`);
  };

  onTableDataClick = (e) => {
    e.stopPropagation();
  };

  onToggleActiveStatus = (status, e, id) => {
    e.stopPropagation();
    const { toggleUser, displayAlert } = this.props;

    toggleUser({
      id: parseInt(id, 10),
      active: status,
    }, {
      handleSuccess: () => {
        this.loadTableData();
      },
      handleError: (err) => {
        displayAlert(ALERT_TYPE.DANGER, err);
      },

    });
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  render() {
    const {
      data,
      display,
      queryParameters,
    } = this.state;
    const {
      serverResponseWaiting,
    } = this.props;

    return (
      <Fragment>
          <div className="section-header">
            <PanelStyled>
              <PageHeader
                display={display}
                breadCrumb={breadCrumbConfig}
                config={{
                  title: userAccount.title,
                  create: this.permission.create,
                  download: false,
                  filter: true,
                  search: true,
                }}
                filter={{
                  date: queryParameters.date,
                  menuList: filterConfig.menu,
                  onFilterChange: this.basePaginationService.handleFilterChange,
                }}
                queryParameters={queryParameters}
                controlDisplay={this.controlDisplay}
                handleCreateClick={this.onIconClick}
                resetFilter={this.basePaginationService.resetFilter}
                clearSearchText={this.basePaginationService.clearSearchText}
                handleSearchChange={this.basePaginationService.handleSearchInputChange}
                handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
                handleDownloadClick={this.basePaginationService.handleDownloadClick}
              />
            </PanelStyled>
          </div>

          <div className="section-content">
            <UserAccountView
              data={data}
              permission={this.permission}
              pagination={queryParameters.pagination}
              onIconClick={this.onIconClick}
              loading={serverResponseWaiting}
              onTableRowClick={this.onTableRowClick}
              fetchDialogData={this.fetchDialogData}
              onPageChange={this.basePaginationService.onPageSelect}
              onTableDataClick={this.onTableDataClick}
              onToggleActiveStatus={this.onToggleActiveStatus}
            />
          </div>
      </Fragment>
    );
  }
}

UserAccount.propTypes = propTypes;

UserAccount.defaultProps = defaultProps;

export default withAlert()(UserAccount);
