import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { tableData } from '../../../utils/paginatedListUtils';
import Badge from '../../../components/Badge';

const labelMappings = {
  id: 'id',
  catalog: 'catalog',
  parent: 'parent',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.id,
    title: 'ID',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.catalog,
    title: 'Catalog',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.parent,
    title: 'Parent',
    show: true,
  },
];

const getCatalogTitle = (catalogList, id) => {
  const filteredCatalogList = catalogList.filter(cat => cat.id === id);
  if (filteredCatalogList.length) {
    return filteredCatalogList[0].title;
  }

  return '';
};


const TableHeader = () => (
  <thead className="">
    <tr>
      {labelConfig.map(label => (label.show ? tableData(label.title, label.align, label.classNames)
        : null))}
    </tr>
  </thead>
);

const returnTableBody = (data, parentList) => {
  const table = {};

  table[labelMappings.id] = (
    <td key={`${data.id}-id`}>{data.id}</td>
  );

  table[labelMappings.catalog] = (

    <td key={`${data.id}-catalog`}>

      <span>
        <span className="tab-label">{data.title}</span>
        <Badge light label={data.count} />
      </span>
    </td>
  );
  table[labelMappings.parent] = (
    <td key={`${data.id}-parent`}>{getCatalogTitle(parentList.list, data.parentCatalogId)}</td>
  );

  return table;
};

const TableBody = ({
  data, parentList,
}) => {
  // Expanding/Dragging and collapsing table body.
  // color based upon table body
  // tooltip if it is sorted.
  // active className
  const tableBody = returnTableBody(data, parentList);

  return (

    <tr key={`catalog-${data.dse_id}`}>
      <Link to={`catalog/${data.id}`}>
        {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
      </Link>
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
  parentList: PropTypes.instanceOf(Object),
};

TableBody.defaultProps = {
  data: {},
  parentList: {},
};

export {
  TableHeader,
  TableBody,
  labelConfig,
};
