import React, { Component, Fragment } from 'react';

import SrnView from './View';
import { breadCrumbConfig, filterConfig } from './config';
import PageHeader from '../../base/PageHeader';
import { salesReturn } from '../../common/DomainConfig';
import * as queryService from '../../base/query.service';
import { PanelStyled } from '../../common/configuration';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import { getPermissionForSrn } from '../../base/permission';
import withAlert from '../../../utils/composition/withAlert';
import { SRN_CREATE, SRN_DETAILS } from '../../../data/enums/Route';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';
import { isError } from '../../common/HelperFunctions';
import { DOWNLOAD_DOMAIN } from '../../../data/enums/GraphQL';

class Srn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      display: {
        searchBox: false,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { ...{ ...queryService.baseQueryParameters.date } },
      },
    };
    this.permission = getPermissionForSrn();

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      props.downloadReport,
      props.displayAlert,

    );
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount() {
    this.loadTableData();
  }

  componentDidCatch(error, info) {

  }

  loadTableData = () => {
    const {
      data, queryParameters,
    } = this.state;
    const { getSrnList, displayAlert } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);

    getSrnList({
      offset,
      limit: queryParameters.pagination.limit,
      filter: {
        filters: apiFilterProcessor(queryParameters.filter),
        dateRange: queryParameters.date,
        queryString: queryParameters.search,
      },
    }, {
      handleSuccess: (response) => {
        data.list = response.data.salesReturnNotes ? response.data.salesReturnNotes.rows : [];
        data.total = response.data.salesReturnNotes ? response.data.salesReturnNotes.count : 1;
        if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
        this.setState(data);
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  };

  setQueryParameters = (queryParams, callBack = () => null) => this.setState(
    { queryParameters: queryParams },
    callBack,
  );

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleIconClick = (type, id) => {
    const { history } = this.props;

    switch (type) {
      case EVENT_OPERATION.CREATE:
        history.push(`/${SRN_CREATE}`);
        break;

      default:
        history.push(`/${SRN_DETAILS}/${id}`);
    }
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  render() {
    const {
      data, queryParameters, display,
    } = this.state;
    const { serverResponseWaiting } = this.props;

    return (
      <Fragment>
        <div className="section-header">
          <PanelStyled>
            <PageHeader
              display={display}
              breadCrumb={breadCrumbConfig}
              config={{
                title: salesReturn.title,
                create: this.permission.create,
                download: true,
                filter: true,
                date: true,
                search: true,
              }}
              downloadConfig={{
                domain: DOWNLOAD_DOMAIN.SALES_RETURN_REPORT,
              }}
              filter={{
                date: queryParameters.date,
                menuList: filterConfig.menu,
                onFilterChange: this.basePaginationService.handleFilterChange,
              }}
              queryParameters={queryParameters}
              controlDisplay={this.controlDisplay}
              handleCreateClick={this.handleIconClick}
              resetFilter={this.basePaginationService.resetFilter}
              clearSearchText={this.basePaginationService.clearSearchText}
              handleSearchChange={this.basePaginationService.handleSearchInputChange}
              handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
              handleDownloadClick={this.basePaginationService.handleDownloadClick}
            />
          </PanelStyled>
        </div>
        <div className="section-content">
          <SrnView
            data={data}
            loading={serverResponseWaiting}
            pagination={queryParameters.pagination}
            onRowClick={this.handleIconClick}
            onPageChange={this.basePaginationService.onPageSelect}
          />
        </div>
      </Fragment>
    );
  }
}

export default withAlert()(Srn);
