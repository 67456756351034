import React from 'react';
import PropTypes from 'prop-types';
import { ImageColumn } from '../../../utils/tableUtils';
import { Icon } from '../../../components';
import { timeStampToTime } from '../../../utils/date';

const labelMappings = {
  id: 'id',
  user_name: 'user_name',
  images: 'images',
  location: 'location',
  login_time: 'login_time',
  logout_time: 'logout_time',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.id,
    title: 'id',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.user_name,
    title: 'User Name',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.images,
    title: 'Image',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.location,
    title: 'Location',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.login_time,
    title: 'Login Time',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.logout_time,
    title: 'Logout Time',
    show: true,
  },
];
const TableHeader = () => (
  <thead className="">
    <tr>
      {labelConfig.map(label => (
        <th
          key={label.id}
          className={`${label.align && 'text-right'} ${label.classNames && label.classNames}`}
        >
          {label.title}
        </th>
      ))}
    </tr>
  </thead>
);

const returnTableBody = (
  data,
  handleSliderOpen,
  onMarkerClick,
) => {
  const table = {};

  table[labelMappings.id] = (
    <td key={`${data.id}-id`}>{data.id}</td>
  );
  table[labelMappings.user_name] = (
    <td key={`${data.id}-user-name`}>
      {data.User.fullName}
    </td>
  );
  table[labelMappings.images] = (
    <ImageColumn
      keyTitle={`rp-image-${data.id}`}
      imageList={data.images || []}
      onImageClick={() => null}
    />
  );
  table[labelMappings.location] = (
    <td key={`${data.id}-location`}>
      <Icon iconName="geolocation" onClick={() => onMarkerClick(data.id, data.location)} />
    </td>
  );
  table[labelMappings.login_time] = (
    <td key={`${data.id}-login-time`}>
      {data.login_time ? `${timeStampToTime((data.login_time / 1000))}` : null}
    </td>
  );
  table[labelMappings.logout_time] = (
    <td key={`${data.id}-logout-time`}>
      {data.logout_time ? `${timeStampToTime((data.logout_time / 1000))}` : null}
    </td>
  );
  return table;
};
const TableBody = ({
  data, handleSliderOpen, onMarkerClick,
}) => {
  // Expanding/Dragging and collapsing table body.
  // color based upon table body
  // tooltip if it is sorted.
  // active className
  const tableBody = returnTableBody(data, handleSliderOpen, onMarkerClick);

  return (

    <tr
      key={`${data.id}-${data.route_id}`}
      className="action-row"
    >
      {labelConfig.map(label => (tableBody[label.label]))}
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
  handleSliderOpen: PropTypes.func.isRequired,
  onMarkerClick: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
};

export {
  TableHeader,
  TableBody,
};
