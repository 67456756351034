import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import { REQUIRED_CATALOG_ID } from './config';
import { refGenerator } from '../../../../../utils/refGenerator';
import { Button, CustomSelect } from '../../../../../components/index';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';

const propTypes = {
  index: PropTypes.number,
  arrayLength: PropTypes.number,
  enableErrorDisplay: PropTypes.bool,
  requiredCatalogId: PropTypes.number,
  catalog: PropTypes.instanceOf(Object),
  onButtonClick: PropTypes.func.isRequired,
  onCatalogChange: PropTypes.func.isRequired,
  catalogDetail: PropTypes.instanceOf(Object),
  onCatalogDetailChange: PropTypes.func.isRequired,
  catalogList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
  })),
  validationField: PropTypes.arrayOf(PropTypes.string),
  catalogDetailList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    catalogId: PropTypes.number,
  })),
};

const defaultProps = {
  index: 0,
  catalog: {
    id: '',
    title: '',
    parentCatalogId: '',
  },
  catalogDetail: {
    id: '',
    title: '',
  },
  arrayLength: 0,
  catalogList: [],
  validationField: [],
  catalogDetailList: [],
  enableErrorDisplay: false,
  requiredCatalogId: REQUIRED_CATALOG_ID,
};

class BaseCatalogSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valid: false,
    };
    this.refsObj = refGenerator(props.validationField);
  }

  componentDidMount() {
    const { getStatus } = this.props;
    getStatus(this.getValidationStatus);
  }

  componentDidUpdate(prevProps) {
    const { catalog, catalogDetail } = this.props;
    if ((prevProps.catalog.id !== catalog.id) || (prevProps.catalogDetail.id !== catalogDetail.id)) {
      this.checkValidationStatus();
    }
  }

  checkValidationStatus = () => {
    const valid = this.getValidationStatus();
    this.setState({ valid });
  };

  getValidationStatus = () => (!Object.values(this.refsObj).find(item => item.getValidState() === false));

  render() {
    const { valid } = this.state;
    const {
      index,
      catalog,
      catalogList,
      arrayLength,
      onButtonClick,
      catalogDetail,
      onCatalogChange,
      requiredCatalogId,
      catalogDetailList,
      enableErrorDisplay,
      onCatalogDetailChange,
    } = this.props;
    return (
      <Fragment>
        <Row>
          <Col xs={3}>
            <CustomSelect
              enableValidation
              options={catalogList}
              className="custom-select"
              placeholder="Select Catalog"
              getOptionValue={({ id }) => id}
              getOptionLabel={({ title }) => title}
              enableErrorDisplay={enableErrorDisplay}
              ref={ref => this.refsObj.Catalog = ref}
              onChange={event => onCatalogChange(event, index)}
              value={catalogList.filter(({ id }) => id === catalog.id)}
            />
          </Col>
          <Col xs={6}>
            <CustomSelect
              grey
              enableValidation
              placeholder="Search"
              className="custom-select"
              options={catalogDetailList}
              getOptionValue={({ title }) => title}
              getOptionLabel={({ title }) => title}
              enableErrorDisplay={enableErrorDisplay}
              ref={ref => this.refsObj.CatalogDetail = ref}
              onChange={event => onCatalogDetailChange(event, index)}
              value={catalogDetailList.filter(({ title }) => title === catalogDetail.title)}
            />
          </Col>
          {
            index === arrayLength - 1
            && catalog.id !== requiredCatalogId
            && (
              <Col xs={12}>
                <div className="select-btn">
                  <Button
                    secondary
                    small
                    disabled={!valid}
                    iconName="plus"
                    onClick={() => onButtonClick(EVENT_OPERATION.CREATE)}
                  />
                </div>

              </Col>
            )
          }
        </Row>
      </Fragment>);
  }
}

BaseCatalogSelect.propTypes = propTypes;

BaseCatalogSelect.defaultProps = defaultProps;

export default BaseCatalogSelect;
