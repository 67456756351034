import {
  compose,
} from 'recompose';
import Detail from './Detail';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';
import {
  query,
} from './API';

const ComposedDetail = compose(
  withRequestTracker({
    query,
  }),
)(Detail);

export default ComposedDetail;
