import { FILTER } from '../../../data/enums/filter';
import { getFilterConfig } from '../../../utils/filter';
import { configuration as configurationRoute } from '../../../data/enums/Route';

const breadCrumb = [
  configurationRoute,
];

const filter = getFilterConfig([FILTER.TOWN, FILTER.STATUS]);

export {
  breadCrumb as breadCrumbConfig,
  filter as filterConfig,
};
