import gql from 'graphql-tag';


const GET_PRODUCTIVE_COVERAGE = gql`
    query getCoverageDetails($type: coverageType!, $filter:FilterInput) {
        getCoverageDetails(type: $type, filter:$filter) {
            ##rows {
            coverage
            details {
                id
                name
                parent_id
                parent_name
                coverage
                active
                active_percent
            }
            ##  }
            ## count
        }
    }
`;

const query = {
  getProductiveCoverage: GET_PRODUCTIVE_COVERAGE,
};

export { query };
