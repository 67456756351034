import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import View from './View';
import { refGenerator } from '../../../../../../utils';
import LedgerAdd from '../../../../../components/LedgerAdd';
import { inputChange } from '../../../../../../utils/formHandlers';
import { ALERT_TYPE } from '../../../../../../data/enums/AlertType';
import { refValidator } from '../../../../../../utils/refGenerator';
import withAlert from '../../../../../../utils/composition/withAlert';
import { cashLedger, requiredFields } from './config';
import { customerDetailMapper, customerMapper } from '../config';

const propTypes = {
  permission: PropTypes.shape({
    BILLING: PropTypes.bool,
    INVOICE: PropTypes.bool,
  }),
  enableErrorDisplay: PropTypes.bool,
};

const defaultProps = {
  permission: {
    BILLING: true,
    INVOICE: true,
  },
  enableErrorDisplay: false,
};

class CustomerDetail extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      outletId: nextProps.outletId,
    };
  }

  constructor(props) {
    super(props);
    const billingStatus = props.permission.BILLING || false;
    this.state = {
      data: customerDetailMapper({}),
      outletId: props.outletId,
      showDialog: false,
      customerList: [],
      cashLedgerAdded: false,
    };

    this.formRefs = refGenerator(billingStatus ? requiredFields.billing : requiredFields.nonBilling);
    /** todo: write function to fetch user permission in utils page */
  }

  componentDidMount() {
    const { getStatus, getDetails } = this.props;
    getStatus(this.getValidationStatus);
    getDetails(this.exportData);
  }

  componentDidUpdate(prevProps, prevState) {
    const { outletId, cashLedgerAdded } = this.state;
    const { permission } = this.props;
    if (outletId !== prevState.outletId) {
      this.getCustomerList();
    }
    if (!cashLedgerAdded && !permission.BILLING) {
      this.addCashLedger();
    }
  }

  /* getUserPermission = (label) => {
    // todo fetch user permission here
    const permission = {
      SALES: {
        BILLING: true,
      },
    };
    return permission[label];
  }; */

  // event: event received from dom
  // firstParam: first Level Parameters
  // paramList: hierarchy in nestedObject : ['name', 'children', 'value']
  handleInputChange = (event, firstParam = '', paramList = []) => {
    const { data } = this.state;

    const updatedData = inputChange(data, event, firstParam, paramList);
    this.setState({ data: updatedData });
  };

  addCashLedger = () => {
    const { customerList } = this.state;
    customerList.push(cashLedger);

    this.setState({ customerList, cashLedgerAdded: true });
  };

  handleDropDownChange = (value, parameterRef = [], callBack = () => null) => {
    const { data, customerList } = this.state;

    /** handling dropdown of ledgers only */
    // const updatedDetails = dropdownChange(data, parameterRef, value);
    const filteredCustomer = customerMapper(customerList.filter(item => item.id === value)[0]);
    data.customer = filteredCustomer;
    this.setState({ data });
  };

  getValidationStatus = () => {
    const { permission } = this.props;
    if (!permission.BILLING) {
      delete this.formRefs.customerId;
      delete this.formRefs.remarks;
    }

    return (refValidator(this.formRefs));
  };

  exportData = () => {
    const { data } = this.state;

    return data;
  };

  handleDialogSubmit = (ledger) => {
    const { data } = this.state;
    const { displayAlert } = this.props;
    const { customerList } = this.state;
    const formattedLedger = customerMapper(ledger) || {};
    if (formattedLedger.id) {
      customerList.push(formattedLedger);
      data.customer = formattedLedger;
      // debugger;
      this.setState({ customerList, showDialog: false, data });
    } else {
      this.setState({ showDialog: false });
      displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Customer Id not available');
    }

    /** post newly created user details and
     * update state customer data after receiving confirmation response */
  };

  getCustomerList = () => {
    const {
      getCustomers, displayAlert, getOutletDetail, outletId,
    } = this.props;
    const { customerList } = this.state;

    getOutletDetail({
      id: outletId.toString(),
    }, {
      handleSuccess: (response) => {
        // eslint-disable-next-line max-len
        const outletCustomer = response.data.retailOutlets
          && response.data.retailOutlets.rows[0].Customers
          || [];
        const customers = [...outletCustomer, ...customerList];
        this.setState({ customerList: customers });
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
    /** fetch ledger list here */
  };

  toggleDialogAppearance = () => {
    const { showDialog } = this.state;

    this.setState({ showDialog: !showDialog });
  }


  render() {
    const {
      data, customerList, showDialog, checkBoxStatus, outletId,
    } = this.state;
    const { permission, enableErrorDisplay } = this.props;

    return (
      <Fragment>
        {
          showDialog && (
            <LedgerAdd
              onCancel={this.toggleDialogAppearance}
              onSubmit={this.handleDialogSubmit}
              id={outletId}
              billingUser={permission.BILLING}
            />
          )
        }
        <View
          data={data}
          customerList={customerList}
          refsObj={this.formRefs}
          permission={permission}
          checkboxStatus={checkBoxStatus}
          enableErrorDisplay={enableErrorDisplay}
          handleInputChange={this.handleInputChange}
          handleDropDownChange={this.handleDropDownChange}
          handleIconClick={this.toggleDialogAppearance}
        />
      </Fragment>
    );
  }
}

CustomerDetail.propTypes = propTypes;

CustomerDetail.defaultProps = defaultProps;

export default withAlert()(CustomerDetail);
