import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Input } from '../index';
import Menu from '../Menu';
import { LabelStyled } from '../Input/TextStyled';

const propTypes = {
  asyncMode: PropTypes.bool,
  searchText: PropTypes.string,
  dropDownUpdater: PropTypes.func,
  dropDownValueKey: PropTypes.string,
  name: PropTypes.string,
  onDropDownSelection: PropTypes.func,
  dropDownDisplayKey: PropTypes.string,
  dropDownList: PropTypes.instanceOf(Array),
  selectedDropDowns: PropTypes.instanceOf(Array),
  labelContent: PropTypes.string,
  placeholder: PropTypes.string,
  fieldName: PropTypes.string,
};

const DEFAULT_ATTRIBUTE_VALUE = 0;
const FIELD_NAME = 'applicableSkus';
const ATTRIBUTE_NAME = 'moq';
const ATTRIBUTE_DISPLAY_NAME = 'MOQ';

const defaultProps = {
  dropDownUpdater: () => null,
  dropDownValueKey: 'id',
  dropDownDisplayKey: 'title',
  name: 'name',
  dropDownList: [],
  searchText: '',
  asyncMode: false,
  selectedDropDowns: [], // {id: 1, moq: 13}
  onDropDownSelection: () => null,
  labelContent: '',
  placeholder: '',
  fieldName: FIELD_NAME,
};

class AutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: props.searchText,
    };

    this.reference = {
      autoComplete: React.createRef(),
    };
  }

  handleInputChange = (event, label = 'searchText') => {
    const { asyncMode, dropDownUpdater } = this.props;
    this.setState({ searchText: event.target.value });

    if (asyncMode) {
      // trigger the call back TODO
      dropDownUpdater(event.target.value);
    }
  };

  handleFieldInputChange = (event, index) => {
    const { onDropDownSelection, selectedDropDowns } = this.props;

    const value = Number(event.target.value) || DEFAULT_ATTRIBUTE_VALUE;
    // name is catalogInfo: name.
    selectedDropDowns[index][ATTRIBUTE_NAME] = value;

    const { fieldName } = this.props;

    onDropDownSelection(selectedDropDowns, fieldName);
  };

  getDropDownListForUserInput = () => {
    const { searchText } = this.state;
    const {
      selectedDropDowns, dropDownDisplayKey, dropDownList, asyncMode, dropDownValueKey,
    } = this.props;

    const validDropDownCriteria = (el) => {
      const searchCriteria = el[dropDownDisplayKey]
        .toLowerCase()
        .includes(searchText.toLowerCase());

      const selectedIdList = selectedDropDowns.map(dropDown => dropDown[dropDownValueKey]);

      const unSelectedCriteria = selectedIdList.indexOf(el[dropDownValueKey]) === -1;

      return searchCriteria && unSelectedCriteria;
    };

    return dropDownList.filter(validDropDownCriteria) || [];
  };

  handleCrossIconClick = (menu) => {
    const {
      selectedDropDowns, onDropDownSelection, dropDownValueKey, asyncMode, name,
    } = this.props;

    const indexInSelectedList = selectedDropDowns.findIndex(el => el[dropDownValueKey] === menu[dropDownValueKey]);

    selectedDropDowns.splice(indexInSelectedList, 1);

    onDropDownSelection(selectedDropDowns, name);
  };

  onDropDownSelection = (dropDown) => {
    const {
      selectedDropDowns, onDropDownSelection, asyncMode, name,
    } = this.props;

    if (asyncMode) {
      selectedDropDowns.push(dropDown);
    } else {
      selectedDropDowns.push({
        id: dropDown.id,
        [ATTRIBUTE_NAME]: dropDown[ATTRIBUTE_NAME] || DEFAULT_ATTRIBUTE_VALUE,
      });
    }

    onDropDownSelection(selectedDropDowns, name);
  };

  getSelectedDropDowns = () => {
    const {
      selectedDropDowns, asyncMode, dropDownList, dropDownValueKey, dropDownDisplayKey
    } = this.props;

    if (asyncMode) {
      return selectedDropDowns;
    }

    const transformedDropDownList = [];

    selectedDropDowns.forEach(
      (dropDown) => {
        const el = dropDownList.find(element => element[dropDownValueKey] === dropDown[dropDownValueKey]);
        if (el) {
          el[ATTRIBUTE_NAME] = (dropDown && dropDown[ATTRIBUTE_NAME]) || DEFAULT_ATTRIBUTE_VALUE;
          transformedDropDownList.push(el);
        }
      },
    );

    return transformedDropDownList;
  };

  render() {
    const { searchText } = this.state;
    const { dropDownDisplayKey, labelContent, placeholder } = this.props;
    const selectedDropDowns = this.getSelectedDropDowns();
    const updatedDropDownList = this.getDropDownListForUserInput();

    const menuHeader = (
      <Fragment>
        {
          labelContent !== undefined ? <LabelStyled>{labelContent}</LabelStyled> : ''
        }
        <div className="catalog">
          <Input
            name="promotionId"
            type="text"
            value={searchText}
            placeholder={placeholder}
            autoComplete="off"
            onChange={event => this.handleInputChange(event, 'searchText')}
          />
        </div>
      </Fragment>
    );

    return (
      <div>
        <div className="catalog">
          <Menu
            block
            header={menuHeader}
            hideOnClick
            ref={this.reference.autoComplete}
          >
            <div className="autocomplete-list">
              {
                updatedDropDownList
                  .map(dropDown => (
                    <div
                      key={`selection-${dropDown.dropDownDisplayKey}`}
                      onClick={() => this.onDropDownSelection(dropDown)}>
                    <span>
                      {
                        dropDown[dropDownDisplayKey]
                      }
                    </span>
                    </div>
                  ))
              }
            </div>
          </Menu>
        </div>
        <div className="suggested-autocomplete-list-wrap">
          {
            selectedDropDowns.map((item, index) => (
              <div className="suggested-autocomplete-list">
                <div className="catalog-placeholder"><span
                  className="text">{item[dropDownDisplayKey]}</span></div>
                <div className="moq-placeholder" data-placeholder={ATTRIBUTE_DISPLAY_NAME}>
                  <Input
                    name="title"
                    type="number"
                    enableValidation
                    value={item[ATTRIBUTE_NAME]}
                    onChange={event => this.handleFieldInputChange(event, index)}
                  />
                </div>
                <span className="ic-close" onClick={() => this.handleCrossIconClick(item)} />
              </div>
            ))
          }
        </div>
      </div>
    );
  }
}

AutoComplete.propTypes = propTypes;

AutoComplete.defaultProps = defaultProps;

export default AutoComplete;
