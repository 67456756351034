import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { GRN_FRAGMENT } from '../API';
import {
  IMAGE_FRAGMENT, LINE_PRICE_FRAGMENT,
} from '../../../common/GqlFragments';
import { SKU_FRAGMENT, SKU_RATE_FRAGMENT } from '../../../configuration/sku/API';
import {
  GET_DISTRIBUTORS, GET_SKU_WITH_BATCH, GET_VENDORS, UPLOAD,
} from '../../../common/GqlAPI';

const GRN_LINE_FRAGMENT = gql`
  fragment GrnLine on GoodsReceivedNotesLine {
    id
    skuId
    damages
    quantity
    shortages
    expiryDate
    skuBatchId
    updatedQuantity
    manufactureDate
    SKU {
      ...SkuFragment
    }
    damageImages {
      ...ImageFragment
    }
    SkuBatch {
      id
      ...SkuRateFragment
    }
    priceDetails {
      ...LinePriceFragment
    }
    updatedPriceDetails {
      ...LinePriceFragment
    }
    
  }
  ${SKU_FRAGMENT}
  ${IMAGE_FRAGMENT}
  ${SKU_RATE_FRAGMENT}
  ${LINE_PRICE_FRAGMENT}
`;

const GET_GRN_DETAIL = gql`
  query getGrnDetail ($id: Int!) {
    findGRN(id: $id) {
      ...GrnFragment
      lines {
        ...GrnLine
      }
    }
  }
  ${GRN_FRAGMENT}
  ${GRN_LINE_FRAGMENT}
`;

const CREATE_GRN = graphql(gql`
  mutation ($input: GoodsReceivedNoteInput!) {
    createGrn(input: $input)
    {
      ...GrnFragment
    }
  } ${GRN_FRAGMENT}
  `, {
  props: ({ mutate }) => ({
    createGRN: variables => mutate({
      variables,
    }),
  }),
});

const UPDATE_GRN = graphql(gql`
  mutation ($id: Int!, $input: GoodsReceivedNoteInput!) {
    updateGrn(id: $id, input: $input)
    {
      ...GrnFragment
    }
  } ${GRN_FRAGMENT}
  `, {
  props: ({ mutate }) => ({
    updateGRN: variables => mutate({
      variables,
    }),
  }),
});

const APPROVE_GRN = graphql(gql`
  mutation ($id: Int!, $input: GoodsReceivedNoteInput!) {
    approveGRN(id: $id, input: $input)
    {
      ...GrnFragment
    }
  } ${GRN_FRAGMENT}
  `, {
  props: ({ mutate }) => ({
    approveGRN: variables => mutate({
      variables,
    }),
  }),
});


const query = {
  getVendors: GET_VENDORS,
  getSkus: GET_SKU_WITH_BATCH,
  getGrnDetail: GET_GRN_DETAIL,
  getDistributors: GET_DISTRIBUTORS,

};

const mutation = {
  upload: UPLOAD,
  createGRN: CREATE_GRN,
  updateGRN: UPDATE_GRN,
  approveGRN: APPROVE_GRN,
};

export {
  GET_GRN_DETAIL,
  CREATE_GRN,
  UPDATE_GRN,
  APPROVE_GRN,
  GRN_LINE_FRAGMENT,
};

export { query, mutation };
