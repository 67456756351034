import gql from 'graphql-tag';

const GET_SKU_DETAIL_COVERAGE = gql`
    query getSKUDetailCoverage($id: Int!, $type:coverageType!, $filter: FilterInput ) {
        getSkuDetailCoverage(id: $id, type: $type, filter: $filter) {
            coverage
            rows {
                id
                name
                parent_name
                coverage
                active_coverage
                sku_coverage
                coverage_percent
            }
            count
        }
    }
`;

const query = {
  getSKUDetailCoverage: GET_SKU_DETAIL_COVERAGE,
};


export { query };
