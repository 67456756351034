import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import './outlet.css';
import Column from './Column';
import OutletContext from './OutletContext';
import { RouteContext } from '../../../config';
import { OUTLET_TYPES, formConfig } from '../config';
import { DialogWrapper } from '../../../../../common';
import { refGenerator } from '../../../../../../utils';
import { CustomSelect } from '../../../../../../components';
import { ALERT_TYPE } from '../../../../../../data/enums/AlertType';
import withAlert from '../../../../../../utils/composition/withAlert';
import withLoading from '../../../../../../utils/composition/withLoading';

const propTypes = {
  onRemoveFromRoute: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  onAssignToRoute: PropTypes.func.isRequired,
  onAssignToOthers: PropTypes.func.isRequired,
  routeList: PropTypes.objectOf(PropTypes.shape({
    list: PropTypes.shape({}),
  })),
  data: PropTypes.arrayOf(PropTypes.shape([{}])),
};

const defaultProps = {
  routeList: {
    list: [],
  },
  data: [],
};

const mapData = d => ({ ...d, id: d.id.toString() });

class ManageOutlets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      outlets: props.data,
      columns: {},
      checked: {
        assigned: {},
        other: {},
        unassigned: {},
      },
      icons: false,
      checkedData: [],
      dialog: {
        type: '',
        element: '',
      },
      header: 'Transfer Outlets',
      outletState: '',
      outletAction: '',
      routeId: '',
    };

    this.formReference = refGenerator(formConfig.validationField);
  }

  static getDerivedStateFromProps(props, state) {
    const { assigned, outletsInAnotherRoute, unassigned } = props.data;
    return {
      columns: {
        assigned: {
          id: 'assigned',
          title: 'Assigned',
          items: assigned.outlets.map(mapData),
        },
        other: {
          id: 'other',
          title: 'Assigned to Others',
          items: outletsInAnotherRoute.outlets.map(mapData),
        },
        unassigned: {
          id: 'unassigned',
          title: 'Unassigned',
          items: unassigned.outlets.map(mapData),
        },
      },
      columnOrder: ['assigned', 'other', 'unassigned'],
    };
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  clearCheckBox = () => {
    this.setState({
      checked: {},
      checkedData: [],
      icons: false,
      outletAction: '',
      routeId: '',
    });
  }

   onDragEnd = (result) => {
     const { state } = this;
     const { onRemoveFromRoute, onAssignToRoute, displayAlert } = this.props;
     if (!result.destination) {
       return;
     }

     if (result.type === 'column') {
       // if the list is scrolled it looks like there is some strangeness going on
       // with react-window. It looks to be scrolling back to scroll: 0
       // I should log an issue with the project
       const columnOrder = this.reorder(
         state.columnOrder,
         result.source.index,
         result.destination.index,
       );
       this.setState({
         ...state,
         columnOrder,
       });
       return;
     }

     // reordering in same list
     if (result.source.droppableId === result.destination.droppableId) {
       const column = state.columns[result.source.droppableId];
       const items = this.reorder(
         column.items,
         result.source.index,
         result.destination.index,
       );

       // updating column entry
       const newState = {
         ...state,
         columns: {
           ...state.columns,
           [column.id]: {
             ...column,
             items,
           },
         },
       };
       this.setState(newState);
       return;
     }

     // moving between lists
     const sourceColumn = state.columns[result.source.droppableId];
     const destinationColumn = state.columns[result.destination.droppableId];
     const item = sourceColumn.items[result.source.index];

     // 1. remove item from source column
     const newSourceColumn = {
       ...sourceColumn,
       items: [...sourceColumn.items],
     };
     newSourceColumn.items.splice(result.source.index, 1);

     // 2. insert into destination column
     const newDestinationColumn = {
       ...destinationColumn,
       items: [...destinationColumn.items],
     };
     // in line modification of items
     newDestinationColumn.items.splice(result.destination.index, 0, item);

     const newState = {
       ...state,
       columns: {
         ...state.columns,
         [newSourceColumn.id]: newSourceColumn,
         [newDestinationColumn.id]: newDestinationColumn,
       },
     };

     if (destinationColumn.id === 'unassigned') {
       onRemoveFromRoute([parseInt(item.id, 10)]);
     }
     if (destinationColumn.id === 'other') {
       displayAlert(ALERT_TYPE.DANGER, 'Please transfer from assigned section');
     }
     if (destinationColumn.id === 'assigned') {
       onAssignToRoute({
         [OUTLET_TYPES[sourceColumn.id.toUpperCase()]]: [parseInt(item.id, 10)],
       });
     }
     this.setState({ ...newState });
   }

    onCheckBoxClick = (event, id, outletState) => {
      const { checked, checkedData } = this.state;
      if (event.target.checked) {
        checkedData.push(parseInt(id, 10));
      } else {
        this.setState({ checkedData: checkedData.filter(d => d.toString() !== id.toString()) });
      }
      this.setState({
        checked: {
          ...checked,
          [id]: event.target.checked,
        },
        icons: true,
        outletState,
      });
    }

    onFormSubmit = () => {
      const {
        outletState, checkedData, outletAction, routeId,
      } = this.state;
      const { onAssignToOthers, onRemoveFromRoute, onAssignToRoute } = this.props;

      if (outletAction === 'remove') {
        onRemoveFromRoute(checkedData);
      }
      if (outletAction !== 'remove' && outletState !== 'assigned') {
        onAssignToRoute({
          [OUTLET_TYPES[outletState.toUpperCase()]]: checkedData,
        });
      }
      if (routeId) {
        onAssignToOthers(
          { [OUTLET_TYPES[outletState.toUpperCase()]]: checkedData },
          routeId,
        );
      }
      this.clearCheckBox();
    }

    onRemoveOutlets = () => {
      this.setState({ outletAction: 'remove' });
    }

    setRouteId = (id) => {
      this.setState({ routeId: id });
    }

    render() {
      const {
        checked, icons, columnOrder, columns, header, outletState, outletAction, checkedData,
      } = this.state;
      const { routeList } = this.props;
      return (
        <DialogWrapper
          onDialogSubmit={this.onFormSubmit}
          formConfig={formConfig}
          refsObj={this.formReference}
          header={header}
          renderDialog={({
            handleDialogDropDownChange,
            dialogData,
            enableErrorDisplay,
          }) => (
            <div className="small-dialog">
              {
                outletAction !== 'remove' && outletState === 'assigned'
                  ? (
                    <div>
                      <span className="title">Please select route to transfer outlets</span>
                      <label>Route</label>
                      <CustomSelect
                        clearable={false}
                        className="custom-select"
                        placeholder="Select Routes"
                        getOptionLabel={({ title }) => title}
                        getOptionValue={({ id }) => id}
                        options={routeList.list}
                        enableValidation
                        enableErrorDisplay={enableErrorDisplay}
                        value={routeList.list.filter(d => d.id === dialogData.id)}
                        onChange={(e) => {
                          handleDialogDropDownChange(e.id, ['id']);
                          this.setRouteId(e.id);
                        }}
                      />
                    </div>
                  )
                  : <span className="title">Do you want to assign this outlet to this Route ??</span>
          }
            </div>
          )}
          render={({ onDialogItemClick }) => (
            <RouteContext.Consumer>
              {
                ({ permission }) => (
                  <div className={`manage-outlet-content ${!permission.update ? 'disabled' : ''}`}>
                    <OutletContext.Provider
                      value={{
                        checked,
                        onCheckBoxClick: this.onCheckBoxClick,
                        icons,
                        onTransferClick: onDialogItemClick,
                        outletState,
                        onRemoveOutlets: this.onRemoveOutlets,
                        checkedData,
                      }}
                    >
                      <DragDropContext onDragEnd={this.onDragEnd}>
                        <div className="app">
                          <Droppable
                            droppableId="all-droppable"
                            direction="horizontal"
                            type="column"
                          >
                            {provided => (
                              <div
                                className="columns"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {columnOrder.map((columnId, index) => (
                                  <Column
                                    key={columnId}
                                    column={columns[columnId]}
                                    index={index}
                                  />
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </div>
                      </DragDropContext>
                    </OutletContext.Provider>
                  </div>)
              }
            </RouteContext.Consumer>
          )}
        />

      );
    }
}

ManageOutlets.propTypes = propTypes;

ManageOutlets.defaultProps = defaultProps;

const Outlets = withLoading(ManageOutlets);

export default withAlert()(Outlets);
