import gql from 'graphql-tag';
import { SALES_RETURN_FRAGMENT } from '../API';
import { SKU_FRAGMENT, SKU_RATE_FRAGMENT } from '../../../configuration/sku/API';
import { SCHEMAS } from '../../../../data/enums/GraphQL';

const LINE_AMOUNT_FRAGMENT = gql`
    fragment LineAmountFragment on ${SCHEMAS.SRNLineAmount}{
        grossAmount
        rate
        discountAmount
        netAmount
        taxAmount
        taxableAmount
        promotionDiscount
        taxAmount
        topUpDiscount
        subTotal
        billDiscount
        tradeDiscount
    }
`;

const SRN_LINE_FRAGMENT = gql`
    fragment SrnLine on ${SCHEMAS.SRNLines}{
        id
        orderId
        quantity
        skuBatchId
        stockType
        srnId
        SKU {
            ...SkuFragment
        }
        SKUBatch {
            id
            ...SkuRateFragment
        }
        amountDetails {
            ...LineAmountFragment
        }
    }
    ${SKU_FRAGMENT}
    ${SKU_RATE_FRAGMENT}
    ${LINE_AMOUNT_FRAGMENT}
`;

const GET_SRN_DETAIL = gql`
    query getSrnDetail($srnId: Int!) {
        srnDetails(srnId: $srnId) {
            ...SalesReturnFragment
            Distributor {
                id
                title
                servicesUsed {
                    logistic {
                        status
                    }
                    delivery {
                        status
                    }
                    billing {
                        status
                        url
                    }
                }
            }
            SRNDetails {
                ...SrnLine
            }
        }
    }
    ${SRN_LINE_FRAGMENT}
    ${SALES_RETURN_FRAGMENT}
`;

const query = {
  getSrnDetails: GET_SRN_DETAIL,
};

export { query, GET_SRN_DETAIL };
