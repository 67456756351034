import React, { Component } from 'react';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { PanelHeader } from '../../configuration';
import {
  BreadCrumb, Button, Filter, Icon,
} from '../../../../components';
import { WIDGETS } from '../../../../data/enums/config';
import PageHeaderStyled from './PageHeaderStyled';

const propTypes = {};
const defaultProps = {
  actions: {
    [WIDGETS.SEARCH]: false,
    [WIDGETS.FILTER]: false,
    [WIDGETS.DOWNLOAD]: false,
    [WIDGETS.UPLOAD]: false,
    [WIDGETS.DELETE]: false,
    [WIDGETS.UPDATE]: false,
    [WIDGETS.CREATE]: false,
    [WIDGETS.MANUAL]: false,
    [WIDGETS.PRINT]: false,
  },
  notification: {
    status: true,
  },
  getActionConfig: () => ({}),
};

class PageHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actionConfigs: {},
    };
  }

  componentDidMount() {
    this.configureActionConfigs();
  }

  fetchActionConfig = (type) => {
    const { getActionConfig } = this.props;
    const config = getActionConfig(type);

    return config;
  };

  configureActionConfigs = () => {
    const { actions } = this.props;
    const actionConfigs = {};
    Object.keys(actions)
      .forEach((key, index) => {
        actionConfigs[key] = this.fetchActionConfig(key);
      });
    this.setState({ actionConfigs });
  };

  render() {
    const {
      breadCrumb,
      title,
      actions,
      getActionConfig,
      notification,
    } = this.props;
    const { actionConfigs } = this.state;
    return (
      <>
        {breadCrumb.length > 0 && <BreadCrumb list={breadCrumb} />}
        <PageHeaderStyled>
          <PanelHeader>
            <h2>{title}</h2>
            <div className="filter-wrap ">
              {
                actions[WIDGETS.SEARCH] && <span> this is search</span>
              }
              {
                actions[WIDGETS.PRINT] && (
                  <span className="icon-wrapper">
                    <Icon
                      iconName="print"
                      onClick={() => actionConfigs[WIDGETS.PRINT].handler()}
                    />
                  </span>
                )
              }
              {
                actions[WIDGETS.FILTER] && (
                  <Filter
                    metaData={actionConfigs[WIDGETS.FILTER].data ? actionConfigs[WIDGETS.FILTER].data.filter : () => null}
                    menuList={actionConfigs[WIDGETS.FILTER].data.menuList || []}
                    onFilterChange={actionConfigs[WIDGETS.FILTER].handler() || null}
                  />
                )
              }
              {
                actions[WIDGETS.DOWNLOAD] && <span> this is download</span>
              }
              {
                actions[WIDGETS.UPLOAD]
                && (
                  <Button
                    secondary
                    onClick={() => actions[WIDGETS.UPLOAD].handler(WIDGETS.UPLOAD, {})}
                  >
                    <Icon iconName="upload" />
                    <span>Upload</span>
                  </Button>
                )
              }
              {
                actions[WIDGETS.DELETE] && <span> this is delete</span>
              }
              {
                actions[WIDGETS.UPDATE]
                && (
                  <Button secondary onClick={actionConfigs[WIDGETS.UPDATE].handler()}>
                    <Icon iconName="pencil" />
                  </Button>
                )
              }
              {
                actions[WIDGETS.MANUAL] && <span />
              }
              {
                actions[WIDGETS.CREATE]
                && (
                  <Button
                    outlined
                    iconName="plus"
                    onClick={() => actionConfigs[WIDGETS.CREATE].handler(EVENT_OPERATION.CREATE, {})}
                  >
                    <span>{EVENT_OPERATION.CREATE.toLowerCase()}</span>
                  </Button>
                )
              }
            </div>
          </PanelHeader>
        </PageHeaderStyled>
      </>
    );
  }
}

PageHeader.propTypes = propTypes;
PageHeader.defaultProps = defaultProps;


export default PageHeader;
