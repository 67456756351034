import React from 'react';
import { Link } from 'react-router-dom';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { tableData } from '../../../utils/paginatedListUtils';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { normalPresentor } from '../../../utils/date';

// todo update the configurations.
const labelMappings = {
  date: 'date',
  amount: 'amount',
  reason: 'reason',
  vendor: 'vendor',
  retailOutlet: 'retailOutlet',
  actionHolders: 'actionHolders',
  GRNInvoiceNumber: 'GRNInvoiceNumber',
  PRNInvoiceNumber: 'PRNInvoiceNumber',
};


const returnTableBody = (data) => {
  const table = {};
  table[labelMappings.PRNInvoiceNumber] = <td>{data.prnInvoiceNumber}</td>;
  table[labelMappings.date] = <td>{normalPresentor(data.date)}</td>;
  table[labelMappings.GRNInvoiceNumber] = <td>{data.grnInvoiceNumber}</td>;
  table[labelMappings.vendor] = <td>{data.Vendor.name}</td>;
  table[labelMappings.amount] = (
    <td className="text-right">{fixedFloatAndCommas(data.prnAmount.total)}</td>
  );
  table[labelMappings.reason] = <td>{data.reason}</td>;

  return table;
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.PRNInvoiceNumber,
    title: 'Purchase Return Number',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.date,
    title: 'Date',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.GRNInvoiceNumber,
    title: 'GRN Number',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.vendor,
    title: 'Vendor',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.amount,
    title: 'PR Amount',
    align: true,
    show: true,
  },
  {
    id: 7,
    label: labelMappings.reason,
    title: 'Remarks',
    show: true,
  },
  {
    id: 8,
    label: labelMappings.actionHolders,
    title: '',
    show: false,
  },
];

const TableBody = (data, onRowClick) => {
  // Expanding/Dragging and collapsing table body.
  // color based upon table body
  // tooltip if it is sorted.
  // active className
  const tableBody = returnTableBody(data);

  return (
    <tr onClick={() => onRowClick(EVENT_OPERATION.UPDATE, data.id)}>
      <Link to={`prn/details/${data.id}`}>
        {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
      </Link>
    </tr>
  );
};

const TableHeader = () => (
  <thead className="">
    <tr>
      {labelConfig.map(label => (label.show ? tableData(label.title, label.align) : null))}
    </tr>
  </thead>
);


export { TableHeader, TableBody };
