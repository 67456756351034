import React from 'react';
import PropTypes from 'prop-types';
import MonthlyDetail from './monthyDetail';
import DseDetails from './dseDetails';
import Inventory from './inventory';
import Delivery from './delivery';
import RouteCoverage from './routeCoverage';
import CallageChart from './callageChart';
import SalesChart from './salesChart';
import UserDetails from './userDetails';
import UserStatusWrapper from './userStatusWrapper/UserStatusWrapper';
import withErrorAndLoadingScreen from '../../utils/composition/withLoading';
import DashWrapper from './DashWrapper';

const View = ({
  userWiseView, data, dimensions, handleModuleClick, syncTime, displayGoldenPoint,
}) => {
  const {
    dseDetail, inventoryDetail, deliveryDetail, routeCoverageView, userStatus,
    callage, sales,
  } = userWiseView;

  return (
    <DashWrapper>
      <div className="row">
        <div className="col-lg-4">
          <UserDetails syncTime={syncTime} title={`${data.user.firstName || ''} ${data.user.lastName || ''}`} />
        </div>
        <div className="col-lg-8">
          {/* <MonthlyDetail data={data.monthlyDetail} onModuleClick={handleModuleClick} /> */}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          {dseDetail && (<DseDetails data={data.dseDetails} displayGoldenPoint={displayGoldenPoint} />)}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          {inventoryDetail && (<Inventory data={data.inventoryDetails} />)}
          {deliveryDetail && (<Delivery data={data.deliveryDetails} />)}
        </div>
        <div className="col-lg-6">
          {routeCoverageView && (<RouteCoverage data={data.dseDetails.rows} />)}
        </div>
      </div>
      {
          userStatus && (
            <UserStatusWrapper
              dimensions={dimensions}
              data={data.userStatus}
            />
          )
        }
      {/* <div className="row">
        <div className="col-lg-6">
          {
          callage && (
            <CallageChart
              dimensions={dimensions}
              data={data.callage}
            />
          )
        }
        </div>
        <div className="col-lg-6">
          {
          sales && (
            <SalesChart
              dimensions={dimensions}
              data={data.sales}
              onModuleClick={handleModuleClick}
            />
          )
        }
        </div>
      </div> */}
    </DashWrapper>
  );
};

View.propTypes = {};
const LoadingView = withErrorAndLoadingScreen(View);

export default LoadingView;
