import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import withLoading from '../../../../../utils/composition/withLoading';
import { EMPTY, INVALID } from '../../../../../data/enums/ErrorMessage';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import {
  CustomSelect, Form, Input, SingleDatePicker,
} from '../../../../../components';
import withAlert from '../../../../../utils/composition/withAlert';

const propTypes = {
  uploadCallBack: PropTypes.func,
  dropDownCallBack: PropTypes.func,
  handleInputChange: PropTypes.func,
  update: PropTypes.instanceOf(Object),
  handleDropDownChange: PropTypes.func,
  handleMultipleUpload: PropTypes.func,
  onUploadRemove: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  data: PropTypes.instanceOf(Object),
  skuList: PropTypes.instanceOf(Array),
  refsObj: PropTypes.instanceOf(Object),
  skuBatchList: PropTypes.instanceOf(Array),
};

const defaultProps = {
  data: {},
  refsObj: {
    title: '',
  },
  skuList: [],
  skuBatchList: [],
  enableErrorDisplay: true,
  uploadCallBack: () => null,
  onUploadRemove: () => null,
  dropDownCallBack: () => null,
  handleDropDownChange: () => null,
  handleMultipleUpload: () => null,
  update: {
    type: EVENT_OPERATION.CREATE,
    status: false,
  },
  /**
   * agruments:
   * first param: input event (required),
   * second param: data's first depth targeted key,
   * third param:
   *  only if object depth greater than one,
   *  should contain list of object keys in accordance of depth upto end i.e target key
   */
  handleInputChange: () => null,

};

const SRNForm = ({ ...props }) => {
  const {
    data,
    update,
    refsObj,
    skuList,
    getState,
    updateState,
    skuBatchList,
    stockTypeList,
    inputCallBack,
    onUploadRemove,
    dropDownCallBack,
    handleInputChange,
    enableErrorDisplay,
    handleDropDownChange,
    handleMultipleUpload,
    invoiceNumberStatus,
  } = props;

  return (
    <Form>
      {/* <div className="scrollable-body-vertical"> */}
      <Row>
        <Col md={12}>
          <CustomSelect
            enableValidation
            disabled={invoiceNumberStatus}
            options={skuList}
            labelContent="SKU"
            className="custom-select"
            placeholder="Select SKU"
            getOptionValue={({ id }) => id}
            getOptionLabel={({ title }) => title}
            ref={ref => refsObj.skuId = ref}
            enableErrorDisplay={enableErrorDisplay}
            onChange={event => handleDropDownChange(event.id, ['skuId'], dropDownCallBack)}
            value={skuList.filter(({ id }) => id === data.skuId)}
          />
        </Col>
        <Col md={4} className="mt-24">
          <CustomSelect
            disabled={!data.skuId || invoiceNumberStatus}
            enableValidation
            options={skuBatchList}
            labelContent="Batch"
            className="custom-select"
            placeholder="Select Batch"
            getOptionValue={({ id }) => id}
            getOptionLabel={({ batchDetails }) => (batchDetails.batchNumber ? batchDetails.batchNumber.replace(/_/g, ' ') : batchDetails.batchNumber)}
            ref={ref => refsObj.skuBatchId = ref}
            enableErrorDisplay={enableErrorDisplay}
            defaultValue={skuBatchList[0]}
            onChange={event => handleDropDownChange(event.id, ['skuBatchId'], dropDownCallBack)}
            value={skuBatchList.filter(({ id }) => id === data.skuBatchId)}
          />
        </Col>
        <Col md={4}>
          <div className="single-date-picker">
            <label>Manufacturing (MFG) Date</label>
            <SingleDatePicker
              name="manufactureDate"
              date={data.manufactureDate}
              disabled={invoiceNumberStatus}
              onChange={(name, date) => (
                handleInputChange({
                  target: {
                    name: 'manufactureDate',
                  },
                  formattedValue: date,
                })
              )}
              maxDate={data.expiryDate}
            />
          </div>
        </Col>
        <Col md={4}>
          <div className="single-date-picker">
            <label>Expiry (EXP) Date</label>
            <SingleDatePicker
              name="expiryDate"
              date={data.expiryDate}
              disabled={invoiceNumberStatus}
              onChange={(name, date) => (
                handleInputChange({
                  target: {
                    name: 'expiryDate',
                  },
                  formattedValue: date,
                })
              )}
              minDate={data.manufactureDate}
            />
          </div>
        </Col>
        <Col className="mt-24 , mb-24" md={4}>
          <Input
            name="returnQuantity"
            type="number"
            rule="isInt"
            labelContent="Return Quantity (QTY)"
            enableValidation
            value={data.returnQuantity}
            enableErrorDisplay={enableErrorDisplay}
            ref={ref => refsObj.returnQuantity = ref}
            onChange={event => handleInputChange(event, '', [], invoiceNumberStatus ? inputCallBack : () => null)}
            argument={{
              min: 1,
              allow_leading_zeroes: false,
            }}
            errorMessage={data.returnQuantity ? INVALID : EMPTY}
          />
        </Col>
        <Col className="mt-24" md={4}>
          <Input
            name="rate"
            type="number"
            rule="isFloat"
            labelContent="Rate"
            enableValidation
            argument={{ min: 1 }}
            value={data.updatedAmountDetails.rate}
            enableErrorDisplay={enableErrorDisplay}
            ref={ref => refsObj.rate = ref}
            disabled={invoiceNumberStatus}
            onChange={event => handleInputChange(event, 'updatedAmountDetails')}
            errorMessage={data.updatedAmountDetails.rate ? INVALID : EMPTY}
          />
        </Col>
        <Col className="mt-24" md={4}>
          <Input
            name="promotionDiscount"
            type="number"
            labelContent="Discount"
            enableValidation
            rule="isFloat"
            argument={{
              min: 0,
              max: data.updatedAmountDetails.rate * data.returnQuantity,
            }}
            value={data.updatedAmountDetails.promotionDiscount + data.updatedAmountDetails.topUpDiscount}
            enableErrorDisplay={enableErrorDisplay}
            ref={ref => refsObj.discount = ref}
            disabled={invoiceNumberStatus}
            onChange={event => handleInputChange(event, 'updatedAmountDetails')}
            errorMessage={data.updatedAmountDetails.promotionDiscount ? INVALID : EMPTY}
          />
        </Col>
        <Col md={4} className="mt-24">
          <CustomSelect
            enableValidation
            options={stockTypeList}
            labelContent="Stock Type"
            className="custom-select"
            placeholder="Select"
            getOptionValue={({ value }) => value}
            getOptionLabel={({ title }) => title}
            ref={ref => refsObj.stockType = ref}
            enableErrorDisplay={enableErrorDisplay}
            onChange={event => handleDropDownChange(event.value, ['stockType'])}
            value={stockTypeList.filter(({ value }) => value === data.stockType)}
          />
        </Col>
      </Row>
      {/* </div> */}
    </Form>
  );
};

SRNForm.propTypes = propTypes;

SRNForm.defaultProps = defaultProps;

const SRNFormWithErrorAndLoading = withLoading(SRNForm);
const SRNFormWithAlert = withAlert()(SRNFormWithErrorAndLoading);

export default SRNFormWithAlert;
