import styled from 'styled-components';

const SearchStyled = styled.div`
  .search-wrapper {
    text-align:right; 
    .icon{
      position:relative;
      top:-2px;
    }
    .search-input{
      display:flex;
      flex-wrap:wrap;
      min-width:32px;
      max-width:193px;
      height:32px;
      border:1px solid #CDCED9;
      background-color:#fff;
      border-radius:4px;
      justify-content:flex-end;
      transition: all 0.5 s ease-in-out;
      &:hover{
        background-color: #F7F8F9;
        color: #272833;
        transition: all 0.3s ease-in-out;
      }
      &.bg-color{
      background-color:#F0F5FF;
      border:1px solid #80ACFF;
        }
      .form-input{
        width:calc(100% - 32px);
        label{
        display:none;
        }
      input{
        height:29px;
        border:0;
        padding-right:0;
        background-color:transparent;
      }
    }
    }
      button.search{
    border:0;
    background-color:transparent; 
      }
  }
`;

export default SearchStyled;
