import {
  compose,
} from 'recompose';
import Detail from './Detail';
import { query } from '../API';
import withRequestTracker from '../../../../../utils/composition/withRequestTracker';

const ComposedSKUDistributionDetail = compose(
  withRequestTracker({
    query,
  }),
)(Detail);

export default ComposedSKUDistributionDetail;
