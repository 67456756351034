import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Pagination } from '../../../components';
import { TableBody, TableHeader } from './tableConfig';
import { dataProps, paginationProps } from '../../../data/enums/Component';
import withErrorAndLoadingScreen from '../../../utils/composition/withLoading';

const propTypes = {
  data: dataProps.value,
  pagination: paginationProps.value,
  onPageChange: PropTypes.func,
  onCheckBoxClick: PropTypes.func,
  onIconClick: PropTypes.func,
  checkedList: PropTypes.array,
  breadCrumb: PropTypes.array,
  filter: PropTypes.object,
  onButtonClick: PropTypes.func,
  onFilterChange: PropTypes.func,
};

const defaultProps = {
  checkedList: [],
  breadCrumb: [],
  filter: {},
  onIconClick: () => null,
  onPageChange: () => null,
  onButtonClick: () => null,
  onCheckBoxClick: () => null,
  data: dataProps.defaultValue,
  onFilterChange: () => null,
  pagination: paginationProps.defaultValue,
};

const View = ({ ...props }) => {
  const {
    data,
    onPageChange,
    onIconClick,
    pagination,
  } = props;
  return (
    <Fragment>
      <div className="table-wrap prn">
        <table>
          {
            TableHeader()
          }

          <tbody>
          {data.list.map(grn => (
            TableBody(grn, onIconClick)
          ))}
          </tbody>
        </table>

      </div>
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </Fragment>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withErrorAndLoadingScreen(View);

export default LoginViewWithErrorAndLoading;
