import React from 'react';
import { Button } from '../../../../../components';
import { compareList } from '../../../../../utils/arrayProcessor';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';

const extraInformationMapper = {
  otherDiscount: '1', /** topUpDiscount * */
  billDiscount: '2',
  stockReplacement: '3',
};
const getLastRowElements = (callOrderValue, stockReplacement) => ({
  [extraInformationMapper.billDiscount]: callOrderValue && callOrderValue.billDiscount > 0,
  [extraInformationMapper.otherDiscount]: callOrderValue && callOrderValue.topUpDiscount > 0,
  [extraInformationMapper.stockReplacement]: stockReplacement.count > 0,
});

const getExtraRows = (obj, replacementKey) => {
  const truthyElements = Object.keys(obj).filter(item => obj[item] === true);
  if (truthyElements.length > 0) {
    return truthyElements.reduce((acc, item, index) => {
      if (item !== replacementKey) {
        acc.push({ [item]: true, [replacementKey]: false });
      } else {
        const accLength = acc.length;
        if (accLength !== 0) {
          acc[accLength - 1][item] = true;
        } else acc.push({ [item]: true });
      }
      return acc;
    }, []);
  }
  return [];
};

const InformationRows = ({ onIconClick, call }) => {
  const { callOrderValue = {}, stockReplacement = {} } = call;
  const obj = getLastRowElements(callOrderValue, stockReplacement);
  const key = extraInformationMapper.stockReplacement;
  const topUpDiscountFragment = () => (
    <>
      <td className="text-center">
        <span className="top-up-indicator" />
        Top Up Discount
      </td>
      <td className="text-right bold">
        {fixedFloatAndCommas(callOrderValue.topUpDiscount)}
      </td>
      <td />
    </>
  );
  const billDiscountFragment = () => (
      <>
        <td
          className="text-center clickable"
          onClick={() => onIconClick(EVENT_OPERATION.READ_SUB_TYPE,
            call)}
        >
          <span className="bill-discount-indicator" />
        Bill Discount(
          <span className="bold">
            {fixedFloatAndCommas(
              (callOrderValue.billDiscount
              / callOrderValue.subTotal) * 100, 2,
            )}
          %
          </span>
        )
        </td>
        <td className="text-right bold">
          {fixedFloatAndCommas(callOrderValue.billDiscount)}
        </td>
        <td />
      </>
  );

  const replacementFragment = status => (
    status ? (
      <>
        <td colSpan={5}>
          <Button
            secondary
            small
            title=" Stock Replacement"
            onClick={() => onIconClick(EVENT_OPERATION.REPLACED,
              call)}
          >
            <p className="count">{stockReplacement.count}</p>
          </Button>
        </td>
        <td />
      </>
    ) : <><td colSpan={5} /> <td /></>
  );

  const elementMapper = key => (params) => {
    switch (key) {
      case extraInformationMapper.otherDiscount: return topUpDiscountFragment();

      case extraInformationMapper.billDiscount: return billDiscountFragment();
      default:
        return replacementFragment(params);
    }
  };

  return (
    <>
      {
        getExtraRows(obj, key).map(item => (
          <tr className="stock-replace-tr">
            { Object.keys(item).reverse().map(elem => elementMapper(elem)(item[elem]))
          }
          </tr>
        ))
      }
    </>
  );
};

const areEqual = (prevProps, nextProps) => {
  const { call } = nextProps;
  const { callOrderValue, stockReplacement } = call;

  return (
    (compareList(prevProps.call.callOrderValue, callOrderValue))
      && (compareList(prevProps.call.stockReplacement, stockReplacement)
      )
  );
};

export default React.memo(InformationRows, areEqual);
