import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { salesForce as salesForceRoute } from '../../../data/enums/Route';
import { MESSAGE_EVENT_OPERATION } from '../../../data/enums/SuccessMessage';
import { getFilterConfig } from '../../../utils/filter';
import { FILTER } from '../../../data/enums/filter';

const breadCrumb = [
  salesForceRoute,
];

const title = 'Merchandising Device';
const filter = getFilterConfig([FILTER.CATEGORY]);

const formConfig = {
  mapper: element => ({
    id: element.id || '',
    title: element.title || '',
    categoryIds: element.categoryIds ? element.categoryIds : [],
  }),
  validationField: ['title', 'territoryId'],
  validationRequired: true,
};

const crudRequest = {
  [EVENT_OPERATION.CREATE]: {
    objectMapper: data => ({
      title: data.title,
      categoryIds: data.categoryIds,
    }),
    responseName: 'createMerchandiseDevice',
    message: `${title} ${MESSAGE_EVENT_OPERATION.CREATE}`,
  },
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: data => ({
      id: data.id,
      title: data.title,
      categoryIds: data.categoryIds,
    }),
    responseName: 'updateMerchandiseDevice',
    message: `${title} ${MESSAGE_EVENT_OPERATION.UPDATE}`,
  },
  [EVENT_OPERATION.DELETE]: {
    objectMapper: data => ({
      ids: [data.id],
      type: 'MerchandiseDevice',
      active: false,
    }),
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: data => ({
      ids: data.checkedList,
      type: 'MerchandiseDevice',
      active: false,
    }),
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.BULK_DELETE}`,
  },
};

export {
  crudRequest, breadCrumb as breadCrumbConfig, formConfig, title, filter as filterConfig,
};
