import { graphql } from 'react-apollo/graphql';
import gql from 'graphql-tag';
import { TOGGLE_STATE } from '../../../user/userRole/API';

const CREATE_SBD = graphql(gql`
    mutation ($input: SBDInput!) {
        createSBD(input: $input)
        {
            id 
            title
            start
            end
            active
        }
    }`, {
  props: ({ mutate }) => ({
    createSBD: variables => mutate({
      variables,
    }),
  }),
});

const UPDATE_SBD = graphql(gql`
    mutation ($input: SBDInput!, $id: Int!) {
        updateSBD(input: $input, id: $id)
        {
            id
            title
            start
            end
            active
        }
    }`, {
  props: ({ mutate }) => ({
    updateSBD: variables => mutate({
      variables,
    }),
  }),
});

const REPLICATE_SBD = graphql(gql`
    mutation ($input: SBDInput!, $id: Int!) {
        replicateSbd(input: $input, id: $id)
        {
            id
            title
            start
            end
            active
        }
    }`, {
  props: ({ mutate }) => ({
    replicateSbd: variables => mutate({
      variables,
    }),
  }),
});

const mutation = {
  createSBD: CREATE_SBD,
  updateSBD: UPDATE_SBD,
  replicateSbd: REPLICATE_SBD,
  toggleState: TOGGLE_STATE,
};

export {
  mutation, CREATE_SBD, UPDATE_SBD, REPLICATE_SBD,
};
