import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { DOWNLOAD_REPORT } from '../../common/GqlAPI';

const GET_ATTENDANCE_LIST = gql`
  query listAttendanceHistory($offset: Int, $limit: Int, $filter: FilterInput) {
    listAttendanceHistory(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        id
        attendaceDate
        user_id
        login_time
        location{
          latitude
          longitude
        }
        images{
          src:image
          thumbnail
        }
        logout_time
        User{
          fullName
          phoneNumber
          Role{
            name
          }
          Distributor{
            title
          }
        }
      }
      count
    }
  }
`;

const query = {
  listAttendanceHistory: GET_ATTENDANCE_LIST,
};
const mutation = {
  downloadReport: DOWNLOAD_REPORT,
};
export { query, mutation, DOWNLOAD_REPORT };
