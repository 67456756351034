import gql from 'graphql-tag';
import { TOGGLE_STATE } from '../../common/GqlAPI';

const SUB_D_FRAGMENT = gql`
    fragment SubDFragment on Distributor {
        id
        title
        description
        townId
    }
`;

const SUB_D_SEMI_DETAIL_FRAGMENT = gql`
    fragment SubDFragment on Distributor {
        id
        title
        description
        callRosterLocked
        Towns {
            id
            title
        }
        type
        active
        address
    }
`;

const SUB_D_DETAILS_FRAGMENT = gql`
    fragment SubDDetailsFragment on Distributor {
        ...SubDFragment
        address
        phoneNumber
        email
        labels
        panNumber
        vatNumber
        owner
        ownerContact
        Towns {
            id
            title
        }
        type
        active
        servicesUsed {
            logistic {
                status
            }
            delivery {
                status
            }
            billing {
                status
                url
            }
        }
        linkedSubDs
        location {
            latitude
            longitude
        }
        secondaryContact {
            name
            mobileNumber
            email
        }
        marketPlacePermissions {
            daraz
            meroKirana
            sastoDeal
        }
        appPermissions {
            sales
            retailer
            supervisor
            merchandiser
        }
        erpDetails {
            businessPartnerId
            ledgerId
        }
        vendorLedgerId
    }
    ${SUB_D_FRAGMENT}
`;

/** const GET_SUB_D_LIST = gql`
 query distributors($offset: Int, $limit: Int, $filter: [FilterInput]) {
    distributors(offset: $offset, limit: $limit, filter: $filter) {
      rows {
      ...SubDFragment
      }
      count
    }
  }
 ${SUB_D_FRAGMENT}
 `; */

const GET_SUB_D_LIST = gql`
    query distributors($offset: Int, $limit: Int, $filter: FilterInput) {
        distributors(offset: $offset, limit: $limit, filter: $filter) {
            rows {
                ...SubDDetailsFragment
            }
            count
        }
    }
    ${SUB_D_DETAILS_FRAGMENT}
`;

const GET_SUB_D_SUMMARY_LIST = gql`
    query distributors($offset: Int, $limit: Int, $filter: FilterInput) {
        distributors(offset: $offset, limit: $limit, filter: $filter) {
            rows {
                ...SubDFragment
            }
            count
        }
    }
    ${SUB_D_SEMI_DETAIL_FRAGMENT}
`;


const query = {
  getSubDList: GET_SUB_D_LIST,
  getSubDSummaryList: GET_SUB_D_SUMMARY_LIST,
};

const mutation = {
  toggleState: TOGGLE_STATE,
};

export {
  SUB_D_FRAGMENT, SUB_D_DETAILS_FRAGMENT, GET_SUB_D_LIST, TOGGLE_STATE,
};

export { query, mutation };
