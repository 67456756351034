import React, {Component} from 'react';
import {CheckBox} from '../../components';
import {Col, Row} from 'react-flexbox-grid';

class DemoCheckbox extends Component {
    render() {
        return (
            <div className="demo-section button-wrap">
                <div className="demo-section-inner">
                    <div className="demo-header">
                        <h2>Checkboxes</h2>
                        <p>With checkboxes, users can select multiple options in a list of options.</p>
                    </div>
                    <div className="demo-body">
                        <div className="demo-content">
                            <div className="demo-content-list">
                                <div className="demo-sample">
                                    <Row>
                                        <Col xs={3}>
                                            <div>
                                                <div className="bg-light-grey">
                                                    <div className="demo-checkbox-container">
                                                        <CheckBox/>
                                                    </div>
                                                </div>
                                                <h6 style={{marginBottom: 30, textAlign: 'center'}}>Default</h6>
                                            </div>
                                        </Col>

                                        <Col xs={3}>
                                            <div>
                                                <div className="bg-light-grey">
                                                    <div className="demo-checkbox-container">
                                                        <CheckBox checked/>
                                                    </div>
                                                </div>
                                                <h6 style={{marginBottom: 30, textAlign: 'center'}}>Checked</h6>
                                            </div>
                                        </Col>

                                        <Col xs={3}>
                                            <div>
                                                <div className="bg-light-grey">
                                                    <div className="demo-checkbox-container">
                                                        <CheckBox active disabled checked/>
                                                    </div>
                                                </div>
                                                <h6 style={{marginBottom: 30, textAlign: 'center'}}>Active + Disabled</h6>
                                            </div>
                                        </Col>

                                        <Col xs={3}>
                                            <div>
                                                <div className="bg-light-grey">
                                                    <div className="demo-checkbox-container">
                                                        <CheckBox disabled/>
                                                    </div>
                                                </div>
                                                <h6 style={{marginBottom: 30, textAlign: 'center'}}>Default Disabled</h6>
                                            </div>
                                        </Col>

                                        <Col xs={3}>
                                            <div>
                                                <div className="bg-light-grey">
                                                    <div className="demo-checkbox-container">
                                                        <CheckBox labelText="With Label"/>
                                                    </div>
                                                </div>
                                                <h6 style={{marginBottom: 30, textAlign: 'center'}}>Default with Label</h6>
                                            </div>
                                        </Col>

                                        <Col xs={3}>
                                            <div>
                                                <div className="bg-light-grey">
                                                    <div className="demo-checkbox-container">
                                                        <CheckBox checked labelText="With Active Label"/>
                                                    </div>
                                                </div>
                                                <h6 style={{marginBottom: 30, textAlign: 'center'}}>Active with Label</h6>
                                            </div>
                                        </Col>

                                        <Col xs={3}>
                                            <div>
                                                <div className="bg-light-grey">
                                                    <div className="demo-checkbox-container">
                                                        <CheckBox active disabled checked labelText="With disabled Label"/>
                                                    </div>
                                                </div>
                                                <h6 style={{marginBottom: 30, textAlign: 'center'}}>Active + Disabled Label</h6>
                                            </div>
                                        </Col>

                                        <Col xs={3}>
                                            <div>
                                                <div className="bg-light-grey">
                                                    <div className="demo-checkbox-container">
                                                        <CheckBox disabled labelText="With disabled Label"/>
                                                    </div>
                                                </div>
                                                <h6 style={{marginBottom: 30, textAlign: 'center'}}>Default + Disabled Label</h6>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DemoCheckbox;
