import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DialogFormWrapper } from '../../common';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import { formConfig } from './config';
import PasswordField from '../../login/resetPassword/PasswordField';
import withAlert from '../../../utils/composition/withAlert';
import {
  Form,
} from '../../../components';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  getStatus: PropTypes.func.isRequired,
  resetDialog: PropTypes.func.isRequired,
  data: PropTypes.string,
  type: PropTypes.string,
};

const defaultProps = {
  data: '',
  type: '',
};

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      incorrectMessage: {
        message: '',
      },
    };
  }

     onAPIRequestFailure = (error) => {
       const { displayAlert } = this.props;
       displayAlert(ALERT_TYPE.DANGER, error);
     };

  changePassword = (type, dialogData) => {
    const {
      changePassword, getStatus, resetDialog, id,
    } = this.props;

    const { incorrectMessage } = this.state;
    if (dialogData.newPassword !== dialogData.confirmPassword) {
      getStatus("Passwords don't match", 'err');
    } else {
      changePassword(
        {
          userId: id,
          oldPassword: dialogData.password,
          newPassword: dialogData.newPassword,
        }, {
          handleSuccess: (response) => {
            incorrectMessage.message = (response.data.changePassword);
            this.setState(incorrectMessage);
            if (incorrectMessage.message === 'Incorrect Current Password.') {
              getStatus('Incorrect Current Password.', 'err');
            } else {
              resetDialog();
              getStatus('Password Changed Successfully', '');
            }
          },
        },
      );
    }
  };

  render() {
    const {
      data, type, resetDialog,
    } = this.props;

    return (
      <div>
        {type && (
          <DialogFormWrapper
            dialogElement={data}
            onDialogCancel={resetDialog}
            onDialogSubmit={this.changePassword}
            formConfig={formConfig[type]}
            refsObj={this.formReference}
            type={type}
            renderDialog={(
              {
                dialogData,
                handleInputChange,
                refsObj,
                enableErrorDisplay,
              },

            ) => (
              type && (
              <Form>
                <PasswordField
                  label="Old Password"
                  value={dialogData.password}
                  name="password"
                  onInputChange={event => handleInputChange(event)}
                  enableErrorDisplay={enableErrorDisplay}
                  enableValidation
                  refsObj={refsObj}
                />
                <PasswordField
                  label="New Password"
                  value={dialogData.newPassword}
                  name="newPassword"
                  onInputChange={event => handleInputChange(event)}
                  enableErrorDisplay={enableErrorDisplay}
                  enableValidation
                  refsObj={refsObj}
                />
                <PasswordField
                  label="Repeat New Password"
                  value={dialogData.confirmPassword}
                  onInputChange={event => handleInputChange(event)}
                  enableErrorDisplay={enableErrorDisplay}
                  name="confirmPassword"
                  enableValidation
                  refsObj={refsObj}
                />
              </Form>
              )
            )
           }
          />
        )}
      </div>
    );
  }
}

ChangePassword.propTypes = propTypes;

ChangePassword.defaultProps = defaultProps;

export default withAlert()(ChangePassword);
