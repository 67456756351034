import styled from 'styled-components';

const MapViewStyled = styled.div`
    .flex-container{
        display:flex;
        flex-wrap:wrap;
    }
    .mapParam{
    flex-basis:738px;
    flex-basis:68%;
    width:68%;
    padding:32px 48px;
    .parameters{
    display:flex;
    justify-content:space-between;
    }
    .get-outlet-btn{
    display:flex;
    align-items:center;
    &>span{
    margin-left:16px;
        color: #272833;
        font-size: 14px;
        font-weight: 500;
    }
    }
    .map-wrapper-inner{
    #map{
    height:73vh;
    }
    .filter-dropdown-card{
    left:0;
    min-width:550px;
    }
    .absolute-edit{
    width:83px;
    height:16px;
    border:1px solid #47C464;
    margin:auto;
    position:absolute;
    z-index:1;
    margin-left: auto;
    margin-right: auto;
    left: 34px;
    right: 0;
    top:58px;
    background-color:#fff;
    font-size:10px;
    color:#287D3C;
    font-weight:500;
    text-align:center;
    line-height:14px;
    cursor:pointer;
    }
    .btns-wrapper{
    position: absolute;
    bottom:0;
    left:20px;
    z-index:1;
    width:calc(100% - 2 * 20px);
    padding:12px 16px;
    background-color:#FFFFFF;
    display:flex;
    justify-content:space-between;
    align-items:center;
    .right-btns,
    .left-btns{
        display:flex;
    }
    .left-btns{
        button{
        display:flex;
        align-items:center;
        img{
            width:10px;
            height:15px;
            margin-right:10px;
        }
        }
    }
    &>span{
        font-size:10px;
        line-height:10px;
        color:#272833;
    }
    }
    /* custom css edit of map */
    .gmnoprint{
    top:45px;
    }
    .gm-style-cc{
    opacity:0;
    }
    .gmnoprint .gm-style-cc{
    display:none;
    }
}
}
`;

export default MapViewStyled;
