import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import RateForm from '../RateForm';
import { rateFormConfig } from '../config';
import { DialogFormWrapper } from '../../../../common';
import { Button, Icon } from '../../../../../components';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { hyphenPresentor } from '../../../../../utils/date';
import { numberPresentor } from '../../../../../utils/conversion';


const propTypes = {
  crudMode: PropTypes.string,
  rates: PropTypes.arrayOf(PropTypes.shape({
    usageDate: PropTypes.instanceOf(Object),
    priceDetails: PropTypes.instanceOf(Object),
    batchDetails: PropTypes.instanceOf(Object),
  })),
  getRates: PropTypes.func,
};

const defaultProps = {
  rates: [],
  crudMode: EVENT_OPERATION.READ,
  getRates: () => {
  },
};

class GridDialogLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: {
        type: '',
        element: '',
        index: null,
      },
      rates: [...props.rates],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { rates } = prevState;
    if (nextProps.rates.length > rates.length) {
      return { rates: nextProps.rates };
    }

    return { rates };
  }

  componentDidMount() {
    const { getRates } = this.props;
    getRates(this.exportData);
  }

  onDialogOkClick = (type, dialogData) => {
    const { rates, dialog } = this.state;
    const transformedData = {
      ...dialogData,
      priceDetails: {
        rlp: dialogData.priceDetails ? Number(dialogData.priceDetails.rlp) : 0,
        dlp: dialogData.priceDetails ? Number(dialogData.priceDetails.dlp) : 0,
        vatPercentage: dialogData.priceDetails ? dialogData.priceDetails.vatPercentage : 0.13,
        mrpStatus: dialogData.priceDetails ? dialogData.priceDetails.mrpStatus : true,
        mrpSrp: dialogData.priceDetails ? Number(dialogData.priceDetails.mrpSrp) : 0,
      },
    };
    if (type === EVENT_OPERATION.UPDATE) {
      rates[dialog.index] = transformedData;
      this.setState({ rates });
    } else {
      rates.push(transformedData);
      this.setState(rates);
    }
  };

  updateDialog = (type, element = {}, index) => {
    this.setState({
      dialog: {
        type,
        element,
        index,
      },
    });
  };

  resetDialog = () => {
    this.setState({
      dialog: {
        type: '',
        element: '',
        index: null,
      },
    });
  };


  exportData = () => {
    const { rates } = this.state;
    return rates;
  };

  render() {
    const { rates, dialog } = this.state;
    const { crudMode } = this.props;
    const { type } = dialog;

    const propRatesLength = this.props.rates.length;

    return (
      <Fragment>
        <div>
          <div className="config-panel-header">
            <label>SKU Batch / Rate</label>
          </div>
          <div className="config-panel-content sku-batch">
            {type && (
              <DialogFormWrapper
                onDialogSubmit={this.onDialogOkClick}
                formConfig={rateFormConfig[type]}
                dialogElement={dialog.element}
                onDialogCancel={this.resetDialog}
                type={type}
                renderDialog={
                  ({
                    handleInputChange, dialogData, enableErrorDisplay, refsObj,
                  }) => (
                    <Fragment>
                      <RateForm
                        show
                        data={dialogData}
                        enableErrorDisplay={enableErrorDisplay}
                        handleInputChange={handleInputChange}
                        refsObj={refsObj}
                      />
                    </Fragment>
                  )}
              />
            )}
            <div className="table-wrap">
              <table>
                <thead>
                  <tr>
                    <th>Batch</th>
                    <th>Updated Date</th>
                    <th className="text-center">MFG Date</th>
                    <th className="text-center">EXP Date</th>
                    <th className="text-right">DLP</th>
                    <th className="text-right">RLP</th>
                    <th className="text-right">MRP / SRP</th>
                    <th className="text-right">VAT</th>
                    { crudMode !== EVENT_OPERATION.CREATE && <th className="text-center">Status</th>}
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {
                  rates.map((item, key) => (
                    <tr key={key}>
                      <td>{item.batchDetails.batchNumber.replace(/_/g, ' ')}</td>
                      <td>{item.updatedAt ? hyphenPresentor(item.updatedAt) : hyphenPresentor(new Date())}</td>
                      <td className="text-center">
                        {item.usageDate && item.usageDate.manufacture !== '0000-00-00' ? hyphenPresentor(item.usageDate.manufacture) : '-'}
                      </td>
                      <td className="text-center">
                        {item.usageDate && item.usageDate.expiry !== '0000-00-00' ? hyphenPresentor(item
                          .usageDate.expiry) : '-'}
                      </td>
                      <td className="text-right">{item.priceDetails.dlp}</td>
                      <td className="text-right">{item.priceDetails.rlp}</td>
                      <td className="text-right">{item.priceDetails.mrpSrp}</td>
                      <td className="text-right">{numberPresentor(0.13 * item.priceDetails.rlp)}</td>
                      { crudMode !== EVENT_OPERATION.CREATE
                      && (
                      <td className="text-center">
                        <span className={`status-label ${item.active ? 'active' : 'passive'}`}>
                          {item.active ? 'ACTIVE' : 'INACTIVE'}
                        </span>
                      </td>
                      )
                      }
                      <td>
                        {
                          key < propRatesLength ? null : (
                            <Icon
                              iconName="pencil"
                              onClick={() => this.updateDialog(EVENT_OPERATION.UPDATE, item, key)}
                            />
                          )
                        }
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={12}>
                      <Button
                        secondary
                        small
                        iconName="plus"
                        onClick={() => this.updateDialog(EVENT_OPERATION.CREATE, {})}
                      />
                    </td>
                  </tr>
                </tbody>

              </table>
            </div>
          </div>
        </div>
      </Fragment>);
  }
}

GridDialogLayout.propTypes = propTypes;

GridDialogLayout.defaultProps = defaultProps;

export default GridDialogLayout;
