import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DialogFormWrapper } from '../../../../common';
import Confirmation from '../../../../common/DialogConfirmation';
import withAlert from '../../../../../utils/composition/withAlert';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';

const propTypes = {
  type: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  element: PropTypes.instanceOf(Object),
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  element: {},
  serverResponseWaiting: false,
  type: EVENT_OPERATION.CREATE,
};

class CreateDialog extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
  }

  render() {
    const {
      type, element, onClose, onSubmit,
    } = this.props;

    return (
      <DialogFormWrapper
        formConfig={{ title: 'Create SBD Group' }}
        dialogElement={element}
        onDialogSubmit={onSubmit}
        onDialogCancel={onClose}
        type={EVENT_OPERATION.CREATE}
        controlDialogClose
        renderDialog={
              ({
                refsObj,
                dialogData,
                handleInputChange,
                enableErrorDisplay,
                handleDropDownChange,
              }) => (
                Confirmation(type)
              )}
      />
    );
  }
}

CreateDialog.propTypes = propTypes;

CreateDialog.defaultProps = defaultProps;

export default withAlert()(CreateDialog);
