import React, { Fragment } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { Input } from '../../../../components/index';
import { colSize } from '../../../../data/enums/Styles';

const colGenerator = (item, key) => (
  <Col className={`mb-48 ${item.className || ''}`} md={item.colSpace || colSize.xxl} key={key}>
    { (item.title || item.value) && (
    <Input
      name={item.title}
      type="text"
      value={item.value}
      labelContent={item.title}
      placeholder={item.title}
    />
    )}
  </Col>
);

const SummaryView = ({ data = [], colType = colSize.md }) => (
  <Fragment>
    <Row>
      <Col md={colType}>
        <Row>
          {
              data.map((item, key) => (colGenerator(item, key)))
            }
        </Row>
      </Col>
    </Row>
  </Fragment>
);

export default SummaryView;
