import React, { Fragment } from 'react';
import { EVENT_OPERATION } from '../data/enums/EventOperation';
import { CheckBox, Icon, Button } from '../components';
import Menu from '../components/Menu';
import { PERMISSION_OBJ } from '../data/enums/Permission';

const tableData = (title, type, classNames) => (
  <th
    key={title}
    className={`${type && 'text-right'} ${classNames && classNames}`}
  >
    {title}
  </th>
);

const rowActionHolders = (
  element = {}, onClickHandler = () => null, type = [], attribute = 'id',
) => (
  <td className="actions text-right" onClick={e => e.stopPropagation()}>
    {
      type.includes(EVENT_OPERATION.UPDATE) && (
        <span onClick={() => onClickHandler(EVENT_OPERATION.UPDATE, element,
          attribute)}
        >
          <Icon iconName="pencil" />
        </span>
      )
    }
    {
      type.includes(EVENT_OPERATION.DELETE) && (
        <span onClick={() => onClickHandler(EVENT_OPERATION.DELETE, element,
          attribute)}
        >
          <Icon iconName="trash" />
        </span>
      )
    }
  </td>
);

/** menu triggering icon and menu list * */
const defaultMenuConfigList = {
  [EVENT_OPERATION.UPDATE]: {
    title: 'Edit',
    icon: 'pencil',
    permissionDerivedBy: 'update',
    type: EVENT_OPERATION.UPDATE,
  },
  [EVENT_OPERATION.DELETE]: {
    title: 'Delete',
    icon: 'trash',
    permissionDerivedBy: 'delete',
    type: EVENT_OPERATION.DELETE,
  },
  [EVENT_OPERATION.REPLICATE]: {
    title: 'Replicate',
    icon: 'copy',
    permissionDerivedBy: 'update',
    type: EVENT_OPERATION.REPLICATE,
  },
};

const menuAction = (
  menuConfigList, headerConfig, onMenuItemClick, data,
  permission = PERMISSION_OBJ,
) => {
  const menuHeader = (
    <Button
      iconName={headerConfig.menuIcon}
    />
  );
  return (
    <Fragment>
      <Menu label="" header={menuHeader}>
        <div className="filter-content text-left">
          <ul>
            {
              menuConfigList.reduce((acc, item) => {
                if (permission[item.permissionDerivedBy || 'read']) {
                  acc.push((
                    <li
                      key={item.title}
                      onClick={(e) => {
                        e.preventDefault();
                        onMenuItemClick(item.type, data);
                      }}
                    >
                      <span>
                        <Icon iconName={item.icon} />
                        {item.title}
                      </span>
                    </li>
                  ));
                }

                return acc;
              }, [])
            }
          </ul>
        </div>
      </Menu>
    </Fragment>
  );
};

const tableHeaderWithCheckBox = (dataListLength, checkedListLength, primaryCheckboxHandler, permission,
  labelConfig, labelMappings) => (
    <thead className="">
      <tr>
        {labelConfig.map(label => (label.show
          ? label.label === (labelMappings.checkbox || 'checkbox')
            ? (dataListLength > 0 && permission.delete) && (
            <th>
              <CheckBox
                checked={(dataListLength === checkedListLength)}
                onCheckBoxClick={e => primaryCheckboxHandler(e)}
              />
            </th>
            )
            : tableData(label.title, label.align, label.classNames)
          : null))}
      </tr>
    </thead>
);

const checkAndResetPaginatedData = (data, stateSetter) => {
  if (data.list.length > 0) {
    const newData = { ...data };
    newData.list = [];
    newData.total = 1;
    stateSetter(newData);
  }
};

const menuActionWithTitle = (
  menuConfigList, headerConfig, onMenuItemClick, data,
  cardTitle,
  permission = PERMISSION_OBJ,
) => {
  const menuHeader = (
    <Button
      iconName={headerConfig.menuIcon}
    />
  );
  return (
    <Fragment>
      <Menu label="" header={menuHeader}>
        <div className="filter-content text-left">
          <ul>
            {
              menuConfigList.reduce((acc, item) => {
                if (permission[item.permissionDerivedBy || 'read']) {
                  acc.push((
                    <li key={item.title} onClick={() => onMenuItemClick(item.type, data, cardTitle)}>
                      <span>
                        <Icon iconName={item.icon} />
                        {item.title}
                      </span>
                    </li>
                  ));
                }

                return acc;
              }, [])
            }
          </ul>
        </div>
      </Menu>
    </Fragment>
  );
};

export {
  tableData, rowActionHolders, menuAction, defaultMenuConfigList, tableHeaderWithCheckBox,
  checkAndResetPaginatedData, menuActionWithTitle,
};
