import gql from 'graphql-tag';
import { graphql } from 'react-apollo/graphql';

const CATALOG_FRAGMENT = gql`
    fragment CatalogFragment on Catalog {
        id
        title
        parentCatalogId
        count
    }
`;

const GET_CATALOG_LIST = gql`
    query {
        catalogs {
            rows {
                ...CatalogFragment
            }
        }
    }
    ${CATALOG_FRAGMENT}
`;


const CREATE_CATALOG = graphql(
  gql`
      mutation($title: String!, $territoryId: Int!) {
          createCatalog(title: $title, territoryId: $territoryId) {
              id
              title
              territoryId
          }
      }
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      createCatalog: variables => mutate({
        variables,
      }),
    }),
  },
);

const query = {
  getCatalogList: GET_CATALOG_LIST,
};

const mutation = {
  createCatalog: CREATE_CATALOG,
};


export {
  CATALOG_FRAGMENT,
};

export { query, mutation };
