import React, { Component } from 'react';
import PropTypes from 'prop-types';

import * as queryService from '../../../../base/query.service';
import { getPermissionForSubDReplenishment } from '../../../../base/permission';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { SUB_D_ASSORTMENT, SUB_D_ASSORTMENT_DETAILS } from '../../../../../data/enums/Route';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import { crudRequestConfig } from '../components/ReplenishmentDialog/config';
import { breadCrumbConfig, dataMapper, PermissionContext } from './config';
import withAlert from '../../../../../utils/composition/withAlert';
import SubDAssortmentDetailView from './View';
import ReplenishmentDialog from '../components/ReplenishmentDialog';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  getSubDAssortmentDetails: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class Details extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    const params = props.match.params || {};
    const id = params.id ? parseInt(params.id, 10) : 0;
    this.state = {
      dialog: {
        type: '',
        element: '',
      },
      queryParameters: {
        sort: queryService.baseQueryParameters.sort,
        date: { ...queryService.baseQueryParameters.date },
        filter: queryService.baseQueryParameters.filter,
        search: queryService.baseQueryParameters.search,
        pagination: queryService.baseQueryParameters.pagination,
      },
      data: dataMapper({}, id),
      activeTab: 0,
    };
    this.id = id;
    this.getData = () => {
      const { data } = this.state;
      return data;
    };

    this.permission = getPermissionForSubDReplenishment();

    const { downloadReport, displayAlert } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadData,
      downloadReport,
      displayAlert,
    );
  }

  componentDidMount() {
    this.loadData();
  }

  setQueryParameters = (queryParams, callBack = () => null) => this.setState(
    { queryParameters: queryParams },
    callBack,
  );

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  loadData = () => {
    const { getSubDAssortmentDetails, displayAlert } = this.props;
    getSubDAssortmentDetails({
      limit: 1,
      offset: 0,
      filter: { filters: [{ column: 'id', value: [this.id.toString()] }] },
    }, {
      handleSuccess: (response) => {
        const replenishmentGroup = response.data.replenishmentGroups
          && response.data.replenishmentGroups.rows
          && response.data.replenishmentGroups.rows.length > 0
          && response.data.replenishmentGroups.rows[0];
        this.setState({ data: replenishmentGroup });
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  }

  handleIconClick = (type, element) => {
    const { history } = this.props;
    const id = element ? element.id || '' : '';

    switch (type) {
      case EVENT_OPERATION.UPDATE:
      case EVENT_OPERATION.DELETE:
        this.setState({
          dialog: {
            type,
            element,
          },
        });
        break;
      default:
        history.push(`/${SUB_D_ASSORTMENT_DETAILS}/${id}`);
    }
  };

  handleSubmit = (type, response) => {
    const { displayAlert, history } = this.props;
    displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message);
    if (type === EVENT_OPERATION.UPDATE) this.setState({ data: response });
    else { history.push(`/${SUB_D_ASSORTMENT}`); }
  };

  handleClose = () => {
    this.setState({
      dialog: {
        type: '',
        element: '',
      },
    });
  };


  handleApiFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
    this.handleClose();
  };

  handleTabChange = (tabIndex) => {
    this.setState({ activeTab: tabIndex });
  };


  render() {
    const {
      dialog, data, activeTab,
    } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <PermissionContext.Provider value={{ permission: this.permission }}>
        <SubDAssortmentDetailView
          data={data}
          loading={serverResponseWaiting}
          activeTab={activeTab}
          dialog={dialog}
          onIconClick={this.handleIconClick}
          permission={this.permission}
          onTabChange={this.handleTabChange}
          breadCrumbConfig={breadCrumbConfig}
        />
        {
          dialog.type && (
            <div className="sbd-modal">
              <ReplenishmentDialog
                type={dialog.type}
                element={dialog.element}
                onSubmit={this.handleSubmit}
                onClose={this.handleClose}
                onApiFailure={this.handleApiFailure}
              />
            </div>
          )
        }
      </PermissionContext.Provider>
    );
  }
}

Details.propTypes = propTypes;

Details.defaultProps = defaultProps;

export default withAlert()(Details);
