import React, { Component, Fragment } from 'react';
import { TableBody, TableHeader } from '../tableConfig';
import SplitPayment from '../../../../components/SplitPayment';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import withImageSlider from '../../../../../utils/composition/withImageSlider';
import { Pagination } from '../../../../../components';
import withErrorAndLoadingScreen from '../../../../../utils/composition/withLoading';
import ReportTableStyled from '../../../../reports/ReportTableStyled';
import BulkSelect from '../../../../components/BulkSelect';

class Pending extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: {
        type: '',
        element: '',
      },
    };
  }

    handleIconClick = (type, element = {}) => {
      this.setState({
        dialog: {
          type,
          element,
        },
      });
    };

      resetDialog = () => {
        this.setState({
          dialog: {
            type: '',
            element: '',
          },
        });
      };

      render() {
        const {
          dialog,
        } = this.state;
        const { type } = dialog;
        const {
          tableConfig, pending, pagination, onPageChange, handleSliderOpen, fetchAllData, permission,
        } = this.props;
        const filterTableConfig = tableConfig.filter(d => d.label !== 'alias');

        return (
          <Fragment>
            <ReportTableStyled>
              {type && (
              <SplitPayment
                data={dialog.element}
                type={type}
                resetDialog={this.resetDialog}
                fetchAllData={fetchAllData}
              />
              )}

              <BulkSelect
                data={pending.list}
                fetchAllData={fetchAllData}
                Buttons={[{
                  label: 'Received',
                  status: EVENT_OPERATION.CHEQUE_RECEIVED,
                }]}
                render={(state, primaryCheckboxHandler, secondaryCheckboxHandler) => (

                  <div className="table-wrap">
                    <table>
                      {
                  TableHeader({
                    tableConfig: filterTableConfig,
                    dataListLength: pending.list.length,
                    primaryCheckboxHandler,
                    checkedListLength: state.checkedList.length,
                    permission,
                  })
                }

                      <tbody>
                        {pending.list.map(pendings => (
                          TableBody({
                            data: pendings,
                            onIconClick: this.handleIconClick,
                            tableConfig: filterTableConfig,
                            handleSliderOpen,
                            checkedList: state.checkedList,
                            secondaryCheckboxHandler,
                            section: 'pending',
                            permission,
                          })
                        ))}
                      </tbody>
                    </table>

                  </div>
                )}
              />

              <Pagination
                currentPage={pagination.page}
                limit={pagination.limit}
                totalItems={pending.total}
                onPageChange={onPageChange}
              />
            </ReportTableStyled>
          </Fragment>
        );
      }
}

const PendingWithErrorAndLoading = withErrorAndLoadingScreen(Pending);

const PendingWithImageSlider = withImageSlider(PendingWithErrorAndLoading);

export default PendingWithImageSlider;
