import axios from 'axios';

const BASE_URL = 'https://arc.rosia.one/api/v1';

class ArcAPI {
  constructor() {
    this.config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
  }

  async getChannels() {
    try {
      const { data } = await axios.get(`${BASE_URL}/channel/list?withCategory=1`);
      return data;
    } catch (e) {
      throw e;
    }
  }

  async getTown() {
    try {
      const { data } = await axios.get(`${BASE_URL}/town/list`);
      return data;
    } catch (e) {
      throw e;
    }
  }

  async getOutlets(limit, channelsIds, categoriesIds, townsIds) {
    try {

      let channelIdsString = [];
      let categoriesIdsString = [];
      let townsIdsString = [];
      let channelIdsUrlString, categoryIdsUrlString, townIdsUrlString;

      if (channelsIds) {
        //for channelIdsUrlString
        channelIdsString = channelsIds.map(channelId => {
          return `channelId[]=${channelId}&`
        })
        channelIdsUrlString = channelIdsString.join("")
      }

      if (categoriesIds) {
        //for categoryIdsUrlString
        categoriesIdsString = categoriesIds.map(categoryId => {
          return `categoryId[]=${categoryId}&`
        })
        categoryIdsUrlString = categoriesIdsString.join("")

      }

      if (townsIds) {
        //for townIdsUrlString
        townsIdsString = townsIds.map(townId => {
          return `townId[]=${townId}&`
        })
        townIdsUrlString = townsIdsString.join("")

      }

      const { data } = await axios.get(`
      ${BASE_URL}/outlet/list?${channelIdsUrlString}${categoryIdsUrlString}${townIdsUrlString}page=0&limit=${limit}
      `);
      return data;
    } catch (e) {
      throw e;
    }
  }

  async generateRoutes(postData) {
    try {
      const { data } = await axios.post(`${BASE_URL}/outlet/generate-route`, postData);
      return data;
    } catch (e) {
      throw e;
    }
  }

  async generateCSV(outlets) {
    try {
      const { data } = await axios({
        method: 'post',
        url: `${BASE_URL}/route/download`,
        data: outlets,
        responseType: 'blob',
      });
      return data;
    } catch (e) {
      throw e;
    }
  }
}

const arcApi = new ArcAPI();

export default arcApi;
