import gql from 'graphql-tag';
import { apolloClient } from './common';
// import * as baseDataService from './base';

// based upon the domain updated, trigger the events that will fetchAndSetDataToLocalStorage
const SUBSCRIPTION_QUERY = gql(`
     subscription leaveRequestNotification {
           leaveRequestNotification {
              id
              type
              domain
          }
      }
`);

const observable = apolloClient
  .subscribe({
    query: SUBSCRIPTION_QUERY,
  });

const initializeSubscription = () => {
  observable.subscribe({
    next(data) {
      // debugger;
      // Based upon Domain, update the respective domain.
    },
    error(err) {
      // debugger;
      // Notify the user of the failure
    },
  });
};

export { initializeSubscription };

export default observable;
