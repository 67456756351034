import {
  compose,
} from 'recompose';
import Callage from './Callage';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  query,
} from './API';

const ComposedCallage = compose(
  withRequestTracker({
    query,
  }),
)(Callage);

export default ComposedCallage;
