import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  TableHeader,
  TableBody,
  filterConfig,
  getFixedFilterParam,
} from './tableConfig';
import CoverageView from '../../View';
import PageHeader from '../../../../base/PageHeader';
import { skuDistributionLink } from '../tableConfig';
import {
  COVERAGE_PARENT_DETAIL_MAPPER,
} from '../../../productive-coverage/detail/tableConfig';
import { has } from '../../../../../utils/objectPrototypes';
import { apiFilterProcessor } from '../../../../../utils/api';
import * as queryService from '../../../../base/query.service';
import { PanelStyled } from '../../../../common/configuration';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import { COVERAGE_TYPE } from '../../../../../data/enums/GraphQL';
import withAlert from '../../../../../utils/composition/withAlert';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';
import { reports, SKU_DISTRIBUTION } from '../../../../../data/enums/Route';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  location: PropTypes.instanceOf(Object),
  displayAlert: PropTypes.func.isRequired,
  getSKUDetailCoverage: PropTypes.func.isRequired,
};

const defaultProps = {
  location: {
    state: {},
  },
  serverResponseWaiting: false,
};

class Detail extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    const { state = {} } = props.location;
    this.state = {
      data: {
        list: [],
        coverage: 0,
      },
      display: {
        searchBox: false,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
        type: COVERAGE_PARENT_DETAIL_MAPPER[state.type || COVERAGE_TYPE.CATEGORY],
      },
    };
    const { downloadReport, displayAlert } = props;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadReport,
      displayAlert,
    );
    this.id = has.call(props.match.params, 'id')
      ? parseInt(props.match.params.id, 10) : 0;
    this.skuId = parseInt(state.skuId || 0, 10);
    this.breadCrumbConfig = [
      { ...reports, state }, {
        ...skuDistributionLink,
      },
      {
        id: 1,
        title: 'SKU Distribution',
        path: `/${SKU_DISTRIBUTION}/${state.skuId}`,
        state,
        active: true,
      }];
  }

  componentDidMount() {
    this.loadTableData();
  }

  loadTableData = () => {
    const { queryParameters, data } = this.state;
    const { getSKUDetailCoverage } = this.props;

    getSKUDetailCoverage({
      id: this.skuId,
      type: queryParameters.type,
      filter: {
        filters: [
          ...apiFilterProcessor(queryParameters.filter),
          ...getFixedFilterParam(queryParameters.type, this.id),
        ],
        queryString: queryParameters.search,
        dateRange: queryParameters.date,
      },
    }, {
      handleSuccess: (response) => {
        const { getSkuDetailCoverage = {} } = response.data;
        data.list = (getSkuDetailCoverage && getSkuDetailCoverage.rows) || [];
        data.total = (getSkuDetailCoverage && getSkuDetailCoverage.rows.length) || 0;
        data.coverage = getSKUDetailCoverage.coverage || 0;
        this.setState({ data });
      },
      handleError: (err) => {
        console.log(err);
        this.onAPIRequestFailure(err);
      },
    });
  };

  setQueryParameters = (queryParams, callBack = () => null) => this.setState(
    { queryParameters: queryParams },
    callBack,
  );

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  render() {
    const {
      data, queryParameters, display,
    } = this.state;
    const { serverResponseWaiting, location } = this.props;
    const { state = {} } = location;

    return (
      <Fragment>
        <div className="section-header">
          <PanelStyled>
            <PageHeader
              display={display}
              breadCrumb={this.breadCrumbConfig}
              config={{
                title: `${state.title} ${data.coverage ? `: ${fixedFloatAndCommas(data.coverage)}%` : ''}`,
                create: false,
                download: false,
                filter: false,
                search: true,
                date: true,
                singleDate: false,
              }}
              filter={{
                date: queryParameters.date,
                menuList: filterConfig.menu,
                onFilterChange: this.basePaginationService.handleFilterChange,
              }}
              queryParameters={queryParameters}
              controlDisplay={this.controlDisplay}
              handleSingleDateRangeChange={this.basePaginationService.handleSingleDateRangeChange}
              handleSearchChange={this.basePaginationService.handleSearchInputChange}
            />
          </PanelStyled>
        </div>
        <div className="section-content">
          <CoverageView
            data={data}
            permission={this.permission}
            pagination={queryParameters.pagination}
            handleRowClick={this.handleTableRowClick}
            loading={serverResponseWaiting}
            viewType={queryParameters.type}
            TableHeader={TableHeader}
            TableBody={TableBody}
            onPageChange={this.basePaginationService.onPageSelect}
          />
        </div>
      </Fragment>
    );
  }
}

Detail.propTypes = propTypes;

Detail.defaultProps = defaultProps;

export default withAlert()(Detail);
