import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { dataProps, paginationProps } from '../../../data/enums/Component';
import withErrorAndLoadingScreen from '../../../utils/composition/withLoading';
import { Pagination } from '../../../components';
import { TableHeader, TableBody } from './tableConfig';
import BulkDelete from '../../components/BulkDelete';
import { STATEFUL_ENTITIES } from '../../../data/enums/GraphQL';
import { PERMISSION_OBJ } from '../../../data/enums/Permission';

const propTypes = {
  data: dataProps.value,
  pagination: paginationProps.value,
  onPageChange: PropTypes.func,
  onCheckBoxClick: PropTypes.func,
  onIconClick: PropTypes.func,
  checkedList: PropTypes.instanceOf(Array),
  breadCrumb: PropTypes.instanceOf(Array),
  onPromotionItemClick: PropTypes.func,
  onBulkDelete: PropTypes.func,
  onReplicateTypeClick: PropTypes.func,
  permission: PropTypes.shape({
    read: PropTypes.bool,
    create: PropTypes.bool,
    update: PropTypes.bool,
    delete: PropTypes.bool,
  }),
};

const defaultProps = {
  data: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
  onPageChange: () => null,
  onCheckBoxClick: () => null,
  onIconClick: () => null,
  checkedList: [],
  breadCrumb: [],
  onPromotionItemClick: () => null,
  permission: PERMISSION_OBJ,
};

const View = ({ ...props }) => {
  const {
    data,
    pagination,
    permission,
    onIconClick,
    onPageChange,
    onBulkDelete,
    onPromotionItemClick,
    onReplicateTypeClick,
    getCheckedListData,
    showBulkSelect,
  } = props;

  return (
    <Fragment>
      <BulkDelete
        data={data.list}
        type={STATEFUL_ENTITIES.PROMOTION}
        handler={onBulkDelete}
        replicate
        showBulkSelect={showBulkSelect}
        getCheckedListData={getCheckedListData}
        onReplicateClick={onIconClick}
        onReplicateTypeClick={onReplicateTypeClick}
        render={(state, primaryCheckboxHandler, secondaryCheckboxHandler) => (
          <div className="table-wrap promotion">
            <table>
              {
                TableHeader({
                  dataListLength: data.list.length,
                  checkedListLength: state.checkedList.length,
                  primaryCheckboxHandler,
                  permission,
                })
              }

              <tbody>
                {data.list.map(promotion => (
                  TableBody({
                    data: promotion,
                    checkedList: state.checkedList,
                    secondaryCheckboxHandler,
                    permission,
                  })
                ))}
              </tbody>
            </table>
          </div>
        )
        }
      />
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </Fragment>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withErrorAndLoadingScreen(View);

export default LoginViewWithErrorAndLoading;
