import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import OutletView from './View';
import { DialogWrapper } from '../../../common';
import PageHeader from '../../../base/PageHeader';
import UploadForm from '../../../common/CSVUploadForm';
import { isError } from '../../../common/HelperFunctions';
import { PanelStyled } from '../../../common/configuration';
import * as queryService from '../../../base/query.service';
import { FORM_CONFIG } from '../../../../data/enums/config';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import withAlert from '../../../../utils/composition/withAlert';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { OUTLET_CREATE, OUTLET_DETAILS } from '../../../../data/enums/Route';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../../utils/api';
import OutletWrapStyled from './OutletWrapStyled';
import { getPermissionForOutlet, getPermissionToCreateOutlet } from '../../../base/permission';
import {
  formConfig,
  filterConfig,
  breadCrumbConfig,
  crudRequestConfig,
} from './config';
import { crudResponseProcessor } from '../../../../utils/crudResponseProcessor';
import { PROGRAM } from '../../../../data/enums/Permission';
import { Icon } from '../../../../components';

const propTypes = {
  getOutletList: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class Outlet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      display: {
        searchBox: false,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        // date: { ... queryService.baseQueryParameters.date },
      },
    };

    this.enableOutletCreation = getPermissionToCreateOutlet();
    this.permission = getPermissionForOutlet();

    const { downloadReport, displayAlert } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadReport,
      displayAlert,
    );
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount() {
    this.loadTableData();
  }

  setQueryParameters = (queryParams, callBack = () => null) => this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  /**
   * @param crudType: CREATE, READ, UPDATE, DELETE
   * @param data
   * @returns {Promise<void>} 
   */
  handleFormSubmit = async (crudType, data) => {
    const { upload, toggleState } = this.props;

    // server call management.
    const serverCall = {
      [EVENT_OPERATION.UPLOAD]: upload,
      [EVENT_OPERATION.DELETE]: toggleState,
    };

    // generate data management
    const serverRequestData = await crudRequestConfig[EVENT_OPERATION[crudType]].objectMapper(
      data,
    );

    // generate success Message
    const successMessage = crudRequestConfig[EVENT_OPERATION[crudType]].message;

    serverCall[EVENT_OPERATION[crudType]](serverRequestData, {
      handleSuccess: () => {
        // considered only upload case for data.
        const { displayAlert } = this.props;
        displayAlert(ALERT_TYPE.SUCCESS, successMessage);
        this.loadTableData();
      },
      handleError: (err) => {
        this.onAPIRequestFailure(err);
      },
    });
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleAddButtonClick = () => {
    const { history } = this.props;

    history.push(`/${OUTLET_CREATE}`);
  };

  handleTableRowClick = (type, id) => {
    const { history } = this.props;
    history.push(`/${OUTLET_DETAILS}/${id}`);
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  loadTableData = () => {
    const { data, queryParameters } = this.state;
    const { getOutletList, displayAlert } = this.props;

    const offset = getOffsetFromPagination(queryParameters.pagination);

    getOutletList(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: [
            ...apiFilterProcessor(queryParameters.filter),
          ],
          queryString: queryParameters.search,
        },
      },
      {
        handleSuccess: (response) => {
          data.list = (response.data.retailOutlets
            && response.data.retailOutlets.rows)
            || [];
          data.total = (response.data.retailOutlets
            && response.data.retailOutlets.count)
            || 0;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
        handleError: (error) => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  handleBulkDelete = (type, response) => {
    const { displayAlert } = this.props;
    const { data } = this.state;
    displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message);
    crudResponseProcessor(
      (modifiedData) => {
        this.setState({ data: modifiedData });
      },
      () => data,
    )(type)(response);
  };

  render() {
    const {
      data, queryParameters, display,
    } = this.state;
    const { serverResponseWaiting } = this.props;

    return (
      <OutletWrapStyled>
        <DialogWrapper
          header="Add Outlet"
          fileConfig={formConfig}
          refsObj={formConfig.ref}
          fileUploadRefs={formConfig[FORM_CONFIG.REFS_OBJ]}
          onDialogSubmit={this.handleFormSubmit}
          renderDialog={({
            handleFileUploadChange,
            dialogData,
            enableErrorDisplay,
            fileUploadRefs,
          }) => (
            <Fragment>
              <UploadForm
                show
                refsObj={fileUploadRefs}
                data={dialogData}
                loading={serverResponseWaiting}
                handleFileUpload={handleFileUploadChange}
                enableErrorDisplay={enableErrorDisplay}
              />
              <a href="/sampleOutlet.csv">
                <span className="sample-csv">
                  <Icon iconName="download" />
Sample CSV
                </span>
              </a>

            </Fragment>
          )}
          render={({ onDialogItemClick }) => (
            <Fragment>
                <div className="section-header">
                  <PanelStyled>
                    <PageHeader
                      config={{
                        title: 'Outlet',
                        upload: this.permission.create,
                        create: this.permission.create,
                        date: false,
                        filter: true,
                        search: true,
                        download: true,
                      }}
                      downloadConfig={{
                        domain: PROGRAM.OUTLET,
                      }}
                      filter={{
                        date: queryParameters.date,
                        menuList: filterConfig.menu,
                        onFilterChange: this.basePaginationService.handleFilterChange,
                      }}
                      breadCrumb={breadCrumbConfig}
                      display={display}
                      queryParameters={queryParameters}
                      handleUploadClick={onDialogItemClick}
                      handleCreateClick={this.handleAddButtonClick}
                      resetFilter={this.basePaginationService.resetFilter}
                      clearSearchText={
                        this.basePaginationService.clearSearchText
                      }
                      handleSearchChange={
                        this.basePaginationService.handleSearchInputChange
                      }
                      handleDateRangeChange={
                        this.basePaginationService.handleDateRangeChange
                      }
                      handleDownloadClick={
                        this.basePaginationService.handleDownloadClick
                      }
                      controlDisplay={this.controlDisplay}
                    />
                  </PanelStyled>
                </div>
                <div className="section-content">
                  <OutletView
                    data={data}
                    loading={serverResponseWaiting}
                    onIconClick={onDialogItemClick}
                    permission={this.permission}
                    pagination={queryParameters.pagination}
                    onTableRowClick={this.handleTableRowClick}
                    onPageChange={this.basePaginationService.onPageSelect}
                    onBulkDelete={this.handleBulkDelete}
                  />
                </div>
            </Fragment>
          )}
        />
      </OutletWrapStyled>
    );
  }
}

Outlet.propTypes = propTypes;

Outlet.defaultProps = defaultProps;

export { Outlet };

export default withAlert()(Outlet);
