import React from 'react';
import PropTypes from 'prop-types';
import { ORDER_HISTORY_VIEW } from '../../../../../../data/enums/GraphQL';
import { headerLabelConfig } from '../../../../../common/HelperFunctions';
import TableHeader from '../../../../../base/TableHeader';
import { normalPresentor } from '../../../../../../utils/date';
import { fixedFloatAndCommas } from '../../../../../../utils/conversion';

const labelMappings = {
  brand: 'brand',
  numbOfSku: 'numbOfSku',
  orderQuantity: 'orderQuantity',
  date: 'date',
  orderValue: 'orderValue',
};

const viewBasedHeaderConfig = {
  [ORDER_HISTORY_VIEW.BRAND]: [{
    id: 1,
    label: labelMappings.brand,
    title: 'Brand',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.numbOfSku,
    title: 'No. of SKUs',
    show: true,
    className: 'text-right',
  },
  {
    id: 3,
    label: labelMappings.orderQuantity,
    title: 'Ordered Quantity',
    show: true,
  }],
  [ORDER_HISTORY_VIEW.DATE]: [{
    id: 4,
    label: labelMappings.date,
    title: 'Date',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.orderValue,
    title: 'Order Value',
    show: true,
  },
  ],
};


const sumOrdered = (data) => {
  const quantitySum = data
    .map(invoice => invoice.quantity);
  const sum = quantitySum.reduce((prev, curr) => prev + curr, 0);
  return sum;
};

const orderValue = (data) => {
  const subTotal = data
    .map(SKU => SKU.Rates * SKU.quantity);
  const sum = subTotal.reduce((prev, curr) => prev + curr, 0);
  return sum;
};

const TableHeaderConfig = ({ viewType }) => (
  <TableHeader
    headerDetails={viewBasedHeaderConfig[viewType]}
  />
);

const getTableTd = (data, labelConfig) => ({
  brand: (
    <td key={`${labelConfig.id}-brand`}>
      <span>
        {data ? data[0].group : ''}
      </span>
    </td>
  ),
  numbOfSku: (
    <td key={`${labelConfig.id}-numberOfSku`} className="text-right">
      {data ? data.length : ''}
    </td>
  ),
  orderQuantity: (
    <td key={`${labelConfig.id}-orderQuantity`} className="text-right">
      <p>{data ? fixedFloatAndCommas(sumOrdered(data)) : ''}</p>
    </td>
  ),
  date: (
    <td key={`${labelConfig.id}-date`}>
      <span>
        {normalPresentor(data[0].group)}
      </span>
    </td>
  ),
  orderValue: (
    <td key={`${labelConfig.id}-orderValue`} className="text-right">
      <div>{data ? fixedFloatAndCommas(orderValue(data)) : ''}</div>
    </td>
  ),
});

const returnTableBody = (data, labelConfig = []) => labelConfig.map((item) => {
  const tableRow = getTableTd(data, item);
  return item.show && tableRow[item.label];
});

const TableBody = ({
  data,
  viewType,
}) => {
  const tableBody = returnTableBody(data, viewBasedHeaderConfig[viewType]);
  return (
    tableBody.map(row => row)
  );
};

TableBody.propTypes = {
  viewType: PropTypes.string,
};

TableHeaderConfig.propTypes = {
  viewType: PropTypes.string,
};

TableHeaderConfig.defaultProps = {
  viewType: ORDER_HISTORY_VIEW.BRAND,
};


export { TableHeaderConfig as TableHeader, TableBody, headerLabelConfig };
