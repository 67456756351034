import styled from 'styled-components';

const BaseWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  width: 100vw;

  .pswp img {
    max-width: none;
    object-fit: contain;
  }

  .bold {
    font-weight: 600;
  }
  .darkshade-bg {
    background-color: #f1f2f5 !important;
  }

  .sorting {
    cursor: pointer;
    .sorting-arrow{
      svg {
        margin-bottom: 2px;
      }
    }
  }
  /* select styling start */
  .zindex-2__control {
    .zindex-2__placeholder,
    .zindex-2__single-value {
      font-weight: 500;
      font-size: 16px;
    }
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .zindex-2__menu {
    z-index: 2;
    font-size: 16px;
  }
  /* select styling end*/
  .capitalize {
    text-transform: capitalize;
  }

  .section-panel {
    .section-content {
      height: 100%;
      border-top: 1px solid #e7e7ed;
      overflow: auto;
      &.r-detail-content {
        overflow: unset;
        margin-top: -9px;
      }
      &.no-border {
        border-top: 0;
      }
      &.section-tab {
        border-top: 0;
        height: calc(100vh - 108px);
        .react-tabs__tab-list {
          border-bottom: 1px solid #e7e7ed;
        }
      }
    }
  }

  /* Fixed Header  Start*/
  .section-panel {
    height: 100vh;
    overflow: hidden;
    #calendar-tab,
    .create-ui-inner,
    .route-wrap,
    .tab-section {
      height: calc(100vh - 120px - 9px - 32px);
      overflow: auto;

      &.tab-less-height {
        height: calc(100vh - 120px);
      }
    }
    /* height for sub d route and outlet detail with tabs start */
    #detail-tab {
      .create-ui-inner {
        &.tab-less-height {
          height: calc(100vh - 120px - 9px - 32px);
        }
      }
    }
    /* height for sub d route and outlet detail with tabs end */
    .section-content {
      height: calc(100vh - 118px);
      /* &.custom-height {
        height: 100%;
        overflow: unset;
      } */
    }
    .table-wrap {
      & > table {
        border-top: 0;
        position: relative;
        border-top: 0;
        & > thead {
          & > tr {
            & > th {
              position: sticky;
              top: -1px;
              z-index: 1;
            }
          }
        }
        tbody {
          tr {
            & > a {
              display: contents;
              text-decoration: none;
              color: #272833;
              & > td {
                border-left: 0;
              }
            }
            td {
              a {
                text-decoration: none;
                color: #272833;
                &:hover {
                  opacity: 0.6;
                  transition: 0.2 ease-in-out all;
                }
              }
            }
          }
        }
      }
    }
  }

  /* Fixed Header End*/
  .section-header {
    background: #fff;
    .filter-wrap {
      position: relative;
      z-index: 2;
      & > div {
        margin-right: 16px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  /* badge styling */
  .badge_border .light {
    min-width: 100px;
    padding: 3px 6px;
    border-radius: 0;
    border: 1px solid #cdced9;
    font-size: 10px;
    line-height: 10px;
    color: #6b6c7e;
    background-color: #fff;
    text-transform: uppercase;
    margin-top: 3px;
  }

  /* //TabsUI */
  .react-tabs {
    position: relative;
  }
  .tab-label {
    display: inline-block;
    margin-right: 8px;
  }
  .react-tabs__tab-list {
    padding: 0 48px 16px;
    list-style: none;
    background: #fff;
    line-height: 32px;
  }
  .react-tabs__tab {
    display: inline-block;
    min-width: 80px;
    padding: 0 16px;
    text-align: center;
    color: #6b6c7e;
    font-weight: 600;
    cursor: pointer;
    font-size: 14px;
    &-panel {
      padding: 0;
    }
    &:hover,
    &:focus {
      color: #272833;
    }
    &.react-tabs__tab--selected {
      border-bottom: 2px solid #0b5fff;
      color: #272833;
      outline: 0;
    }
  }
  .light {
    background: #eef2fa;
    border-color: #eef2fa;
  }
  .react-tabs__tab--selected {
    .light {
      background: #0b5fff;
      color: #fff;
      position: relative;
      top: -1px;
      border-color: #0b5fff;
    }
  }

  /* //CreateUI */
  .create-ui {
    .react-tabs__tab-list {
      padding: 0;
      margin-bottom: 16px;
    }
    .react-tabs__tab {
      min-width: 130px !important;
    }
    .react-tabs__tab.react-tabs__tab--selected {
      border-bottom-width: 2px !important;
      line-height: 32px !important;
    }
    .tab-label {
      margin: 0 !important;
    }
    .tab-section {
      /* border-top: 1px solid #E7E7ED; */
      .pswp-gallery {
        padding: 24px 48px;
      }
    }

    .top-section {
      display: flex;
      padding: 20px 0 25px;
      justify-content: space-between;
      align-items: center;
    }
    h1 {
      font-size: 24px;
      font-weight: bold;
      color: #272833;
      margin: 0;
    }
  }

  .create-ui h2,
  .outlet-wrap h2 {
    font-size: 14px;
    font-weight: normal;
    color: #6b6c7e;
    margin-top: 0;
    padding-bottom: 5px;
    border-bottom: 1px solid #cdced9;
    margin-bottom: 24px;
  }

  .route-tab-content .create-ui-inner {
    padding-bottom: 32px;
  }

  .create-ui-inner {
    padding: 32px 48px 0;
    background: #fbfbfc;
    height: calc(100vh - 115px);
  }

  .create-ui input {
    background: #f1f2f5;
    border-color: #e7e7ed;
  }

  .create-ui .form-select-input {
    display: flex;
    flex-wrap: wrap;
  }

  .create-ui .form-select-input > div {
    flex: 1;
  }

  .create-ui .form-input input + span,
  .create-ui .form-select-input > div + span {
    color: red;
    display: block;
    width: 100%;
    font-weight: normal;
    text-align: right;
  }

  .create-ui tr td .light {
    margin-left: 8px;
  }

  /* //Utilities */
  .hidden {
    display: none;
  }

  .items-center {
    align-items: center;
  }

  .justify-between {
    justify-content: space-between;
  }

  .flex-1 {
    flex: 1;
  }

  .section-panel {
    width: calc(100vw - 64px);
    background: #fbfbfc;
    height: 100vh;
    overflow: auto;
    @media print {
      width: 100%;
      height: auto;
      background: #fff;
    }
  }

  .sec-nav-wrap {
    position: relative;
    color: #000;
    z-index: 1;
    font-size: 12px;
    font-weight: 600;
    opacity: 0.5;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #f1f2f5;
    width: 252px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover,
    &:focus {
      opacity: 1;
      transition: all 0.3s ease-in-out;
    }

    &:last-child {
      margin-bottom: 0;
      padding: 0;
      border: none;
    }
  }
  .flex-display {
    display: flex;
  }

  .custom-control-label-text + div {
    margin-left: 3px;
    align-self: center;
  }

  /* Order-Processing Promotion part */
  .promotion-popup {
    .table {
      max-height: 335px;
      overflow-y: scroll;
    }
    tfoot td {
      position: sticky;
      bottom: 0;
      z-index: 111;
      background: #f1f2f5;
    }
  }

  /* Order-Processing Promotion part  end*/
  .form-input .custom-control-label-text {
    display: inline-block;
    margin-bottom: 4px;
    color: #272833;
    font-size: 14px;
    font-weight: 600;
  }
  .status-switch {
    .form-input {
      display: block;
      margin-bottom: 0 !important;
    }
    .custom-control-label-text {
      display: block;
      margin-top: 0;
      margin-right: 0;
    }
  }

  .date-picker-single {
    .filter-dropdown-card {
      min-width: 320px;
    }
  }

  .single-date-picker {
    margin: 24px 0;
    label {
      color: #272833;
      font-size: 14px;
      font-weight: 600;
      width: auto;
      margin: 0 0 4px;
      display: inline-block;
      + div {
        margin: 0;
        width: 100%;
        top: 0;
      }
    }
    .moment {
      padding: 10px 50px 10px 12px;
      height: 40px;
      .ic-date-icon {
        top: 10px;
      }
    }
    .datepicker-wrapper > div {
      width: 100%;
    }
    .filter-dropdown-card {
      left: 0;
      right: auto;
      min-width: 320px;
      padding: 16px;
    }
  }

  /* //GRN Page */
  .mb-48 {
    margin-bottom: 48px;
  }
  .mb-48:last-child {
    margin-bottom: 0;
  }
  .mt-24 {
    margin-top: 24px;
  }
  .scrollable-body-vertical {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .form-select-input {
    margin-bottom: 24px;
  }
  .form-select-border {
    border: 1px solid #ea7179;
    border-radius: 4px;
    .zindex-2__control {
      background-color: #fcedee;
    }
    .zindex-2__control--is-focused {
      border: 1px solid #ea7179;
    }
  }
  .custom-file-upload {
    border: 1px solid #cdced9;
    padding: 10px;
    border-radius: 4px;
    display: inline-block;
  }
  input.input-file-upload {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: inline-block;
    cursor: pointer;
  }
  .custom-file-upload-wrap {
    position: relative;
    overflow: hidden;
    width: 38px;
    height: 40px;
    display: inline-block;
    top: 22px;
    .error-input {
      margin: 0;
      position: absolute;
      width: 38px;
      height: 40px;
      overflow: hidden;
      opacity: 0;
      cursor: pointer;
      z-index: 1;
      top: 0;
    }
  }
  .attachment-item {
    width: 38px;
    height: 40px;
    background: #ccc;
    margin-top: 21px;
    border-radius: 4px;
    display: inline-block;
    position: relative;
    img {
      width: 38px;
      height: 40px;
      object-fit: cover;
      display: block;
      object-position: top;
    }
    .del-icon {
      position: absolute;
      top: -8px;
      right: -8px;
      background: #ff5f5f;
      border: 2px solid #fff;
      border-radius: 50%;
      text-align: center;
      display: inline-block;
      font-weight: bold;
      font-size: 12px;
      width: 20px;
      height: 20px;
      line-height: 14px;
      color: #fff;
      cursor: pointer;
    }
  }

  /* //Month Picker */
  .month-picker-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px;
    font-size: 14px;
    .bold {
      font-weight: bold;
    }
  }
  .month-date-list-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
  }
  .month-date-list {
    padding: 10px 5px;
    cursor: pointer;
    width: 35px;
    height: 35px;
    margin-bottom: 10px;
    transition: 0.2s;
    border-radius: 50%;
    text-align: center;
    &:hover,
    &:focus {
      background-color: #ccc;
      color: #fff;
    }
    &.active {
      background-color: #0d69ff;
      color: #fff;
    }
  }
  .month-picker-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    text-indent: -9999px;
    overflow: hidden;
    white-space: nowrap;
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: center;
    cursor: pointer;
    &.left {
      background-image: url('/image/icons/left-arrow.svg');
    }
    &.right {
      background-image: url('/image/icons/right-arrow.svg');
    }
  }
  .filter-btn.add {
    svg.icon {
      margin-top: -3px;
    }
  }

  .simple-popup {
    .filter-dropdown-card {
      min-width: 225px;
      max-width:300px;
      padding: 10px 12px;
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.16);
      top: -20px;
      right: 30px;
      top: calc(100% - 75px);
      right: 45px;
      .filter-label {
        padding: 0 0 8px 0;
        text-transform: capitalize;
        font-weight: 600;
      }
      .filter-content {
        padding: 8px 0 30px;
        color: #6b6c7e;
        font-size: 14px;
        span{
          white-space:normal
        }
      }
      svg.icon {
        width: 12px;
        height: 12px;
      }
    }
    .filter-item {
      button {
        padding: 4px 7px;
        &:hover {
          background-color: #f7f8f9;
          color: #272833;
        }
      }
    }
  }

  .popup-footer {
    border-top: 1px solid rgba(231, 231, 237, 0.8);
    padding-top: 8px;
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 10px;
      > span:last-child {
        color: #6b6c7e;
      }
    }
  }

  .simple-popup-actions {
    .filter-item {
      button {
        padding: 4px 7px;
        background: transparent;
        transition: 0.2s ease-out;

        &:hover,
        &:focus {
          background-color: #f7f8f9;
          color: #272833;
        }
      }
    }
    .filter-dropdown-card {
      min-width: 130px;
      padding: 8px 0;
      top: 115%;
      right: 0;
      .filter-content {
        padding: 0;
        font-size: 14px;
        li {
          padding: 9px 16px;
          &:last-child {
            margin-bottom: 0;
          }
          &:hover {
            background: #f0f5ff;
          }
          span {
            color: #272833;
            font-size: 14px;
            line-height: 21px;
            white-space: nowrap;
          }
        }
      }
      li {
        cursor: pointer;
      }
      svg.icon {
        width: 16px;
        height: 16px;
        margin-right: 16px;
        color: #6b6c7e;
      }
    }
  }
  .auto-margin {
    margin: auto;
  }

  /* // SBD */
  .simple-popup-action {
    .filter-item {
      svg.icon {
        cursor: pointer;
        background: transparent;
        width: 32px;
        height: 32px;
        padding: 8px;
        border-radius: 4px;
        transition: 0.2s ease-out;

        &:hover,
        &:focus {
          background: #f1f2f5;
        }
      }
    }

    .filter-dropdown-card {
      min-width: 126px;
      padding: 20px 16px;
      top: 95px;
      transform: translateY(-50%);
      right: 18px;

      .filter-content {
        padding: 0;
        font-size: 14px;

        li {
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      li {
        cursor: pointer;
      }

      svg.icon {
        width: 16px;
        height: 16px;
        margin-right: 16px;
      }
    }
  }

  .auto-complete-pop-up {
    .filter-dropdown-card {
      min-width: 140px;
      padding: 0;
      top: 115%;
      left: 0;
      box-shadow: none;
      border: none;

      .autocomplete-list {
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.16);
        padding: 8px 0;
        border-radius: 4px;
        border-width: 1px;
        border-style: solid;
        border-color: rgb(231, 231, 237);
        border-image: initial;
      }
    }
  }

  .section-content.invoice {
    padding: 40px 48px;
    background: #fbfbfc;

    .panel {
      background: #fff;
      box-shadow: 0 4px 8px 0 rgba(39, 40, 51, 0.12);
      border-radius: 0 0 4px 4px;
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }

      h3 {
        font-size: 14px;
        margin: 0;
        font-weight: 600;
        text-transform: uppercase;
        color: #272833;
        line-height: 21px;
        padding: 6px 25px;
        background: #f1f2f5;
        border-bottom: 1px solid #cdced9;
      }
    }

    .panel-content {
      padding: 32px 48px;

      label {
        display: block;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        margin-bottom: 4px;
      }

      span {
        color: #6b6c7e;
      }
    }

    .invoice-form {
      margin-top: 24px;
    }

    .modal-inner form > div:first-child {
      height: 350px;
      overflow-y: scroll;
    }
  }

  .add-ledger-btn {
    position: relative;

    > span {
      position: absolute;
      top: -10px;
      left: 65px;
      display: inline-block;
      font-weight: 600;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      user-select: none;
      border: 1px solid #c1c7d0;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      touch-action: manipulation;
      cursor: pointer;
      background-image: none;
      letter-spacing: 0;
      line-height: 22px;
      border-radius: 4px;
      outline: none;
      text-transform: capitalize;
      padding: 4px 7px;
      font-size: 14px;
      background-color: #fafbfc;
      color: #030913;

      &:hover,
      &:focus {
        .icon {
          color: #fff !important;
        }

        background-color: #0e69ff;
        color: #ffffff;
        border-color: #0e69ff;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
          border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      }
    }
  }

  .invoice-view-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .invoice-title {
      margin: 0;
    }

    .invoice-stats {
      padding: 24px 20px;
      border-bottom: 1px solid rgba(57, 58, 74, 0.2);
      border-top: 1px solid rgba(57, 58, 74, 0.2);
      margin: 0 -20px 24px;

      label {
        display: block;
        color: #272833;
        font-weight: 600;
        margin-bottom: 4px;
        line-height: 21px;
      }

      span {
        color: #6b6c7e;
      }
    }
  }

  .invoice-table-info {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;

    .inner {
      font-size: 14px;
      color: #6b6c7e;
      padding: 24px 48px 0 0;

      > .row {
        max-width: 400px;
        .trade-discount {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .form-select-input {
            margin-bottom: 0;
            label {
              display: none;
            }
            .zindex-2__control {
              min-width: 80px;
              height: 32px;
              min-height: 32px;
              .zindex-2__single-value {
                font-size: 14px;
              }
              .zindex-2__indicator {
                padding: 6px;
              }
              .zindex-2__indicator-separator {
                display: none;
              }
            }
          }
        }
        .trade-discount-input {
          .form-input {
            width: 119px;
            display: inline-block;
          }
        }
      }

      .total-text {
        text-transform: uppercase;
      }

      .row {
        margin-bottom: -16px;
      }
      .col-md-8,
      .col-md-4 {
        margin-bottom: 18px;
      }

      span {
        padding-right: 8px;
        display: inline-block;
      }

      input[type='number'] {
        font-size: 14px;
        padding: 2px 8px;
        height: 32px;
        border-color: #e7e7ed;
        background: #fff;
        text-align: right;
      }

      .pt-4 {
        padding-top: 4px;
      }
    }

    .total-bottom {
      width: 100%;
      border-top: 1px solid #cdced9;
      padding: 24px 40px 0 48px;
      margin: 24px 0 0;
      text-align: right;
      display: flex;
      justify-content: flex-end;

      .total-bottom-inner {
        color: #6b6c7e;
        font-size: 14px;
        width: 290px;
      }

      .total-text {
        text-transform: uppercase;
      }

      .total-number {
        color: #272833;
        font-weight: 600;
        padding-right: 8px;
      }
    }
  }

  .invoice-table-wrap {
    margin: 0 -48px;

    table {
      border-top: none;

      th {
        background: transparent;
      }
    }
  }

  /*InventoryUI*/
  .prn-page-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    > ul {
      width: 100%;
    }
  }
  .scrollable-body-vertical {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  //Outlet UI
  .outlet-wrap {
    .form-input-wrap {
      .map-wrapper {
        margin-top: 16px;
      }
    }
  }
  /* table styling overide of style css */
  table {
    thead {
      tr {
        height: 48px;

        th {
          padding: 7px 12px;
          width: auto;
          white-space: nowrap;

          &:first-child {
            padding: 7px 12px 7px 48px;
          }

          &:last-child {
            padding: 7px 48px 7px 12px;
          }

          &.checks {
            width: 88px;
            min-width: 88px;
          }
        }
      }
    }

    tbody {
      tr {
        height: 56px;
        background-color: #ffffff;

        &:hover {
          background: #f0f5ff;
        }

        td {
          padding: 9px 12px;
          white-space: normal;

          &:first-child {
            padding: 9px 12px 9px 48px;
            white-space: nowrap;
          }

          &:last-child {
            padding: 9px 48px 9px 12px;
          }

          &.collapsible-body-td {
            padding: 0;
          }
        }
      }
    }

    tfoot {
      td {
      }
    }
  }

  /* table styling overide of style css  end*/

  //Route UI
  .route-button-wrap {
    padding: 0;
    margin-top: 48px;
  }

  .map-wrapper {
    #map {
      margin-top: 24px;
    }
  }

  /* //Subd */

  .ml-16 {
    margin-left: 16px;
  }

  .m-0 {
    margin: 0;
  }

  .user-title {
    > span {
      display: flex;
      align-items: center;
    }

    .light {
      margin-left: 16px;
    }
  }

  .map-wrapper-inner {
    .absolute {
      position: absolute;
      top: 16px;
      left: 16px;
      z-index: 99;
      color: #6f7082;

      > span {
        position: absolute;
        top: 5px;
        left: 12px;
      }
    }

    input {
      width: auto;
      background: #fff;
      border: 1px solid #cdced9;
      padding: 5px 12px 5px 36px;
      font-size: 14px;
      font-weight: 600;
      color: #6b6c7e;
      line-height: 20px;
      height: auto;
    }
  }

  .create-ui #ledger-tab .form-select-input {
    display: block;
  }

  .custom-date {
    label + div {
      margin: 0;
      display: block;
      flex: 1;
      /* width: calc(100% - 260px); */
    }

    .moment {
      border-color: #e7e7ed;
      background: #f1f2f5;
      color: #6b6c7e;
      padding: 10px 14px;
      font-size: 14px;

      .ic-date-icon {
        top: 9px;
      }
    }

    .filter-dropdown-card {
      min-width: 310px;
    }

    .rdr-Day.is-selected {
      border-radius: 50%;
    }

    .rdr-Day:hover {
      color: #fff;
    }
  }

  .holiday-status-checkbox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    label {
      &:first-child {
        padding-top: 2px;
      }
    }

    .checkbox-list {
      width: calc(100% - 460px);
      display: flex;
      flex: 1;
      align-items: center;

      label {
        width: auto;

        &:first-child {
          padding-top: 0px;
        }
      }
    }
  }

  .status-content {
    display: flex;

    > div {
      margin-right: 32px;

      &:last-child {
        margin-right: 0;
      }
    }

    label {
      width: auto;
    }

    .custom-control-label-text {
      color: #272833;
      font-weight: 600;
    }
  }

  .checkbox-horizontal {
    margin-bottom: 24px;

    > div {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    label {
      display: flex;
      align-items: center;
      cursor: pointer;

      span {
        color: #272833;
        font-weight: 600;
        padding-left: 4px;
      }

      input {
        flex: none;
      }
    }
  }

  .brand-wise-wrap {
    .text {
      font-size: 10px;
      color: #6b6c7e;
      line-height: 10px;
      margin-top: -26px;
      display: block;
    }
  }

  .drag-content {
    display: flex;
    align-items: center;

    .icon {
      color: #6b6c7e;
    }
  }

  .drag-number {
    border: 1px solid #a7a9bc;
    font-size: 10px;
    font-weight: 600;
    line-height: 10px;
    text-align: center;
    color: #6b6c7e;
    padding: 2px 7px;
    margin: 0 16px 0 8px;
    display: inline-block;
  }

  /* //Merchandising */
  .merchandising-config-panel {
    .modal-inner {
      max-width: 640px;
    }
  }

  /* //SBD UI */
  .sbd-modal {
    .modal-inner {
      max-width: 395px;
    }
  }

  /* //BU Page */
  .placeholder {
    position: relative;
    margin-bottom: 24px;
  }
  .placeholder::after {
    position: absolute;
    right: 35px;
    top: 10px;
    content: attr(data-placeholder);
    opacity: 0.4;
  }
  .permission-choose {
    margin-top: 18px;

    > label {
      display: block;
      font-size: 10px;
      font-weight: normal;
      color: #6b6c7e;
      line-height: 10px;
      margin-bottom: 4px;
    }

    > div {
      display: flex;
    }

    .radio-list {
      margin-right: 24px;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }
    }

    .text {
      font-size: 14px;
      font-weight: 600;
      color: #272833;
      display: inline-block;
      margin-left: 8px;
    }
  }

  .sample-csv {
    font-size: 14px;
    padding-top: 20px;
  }

  .mt-5 {
    margin-top: 5px;
  }

  .positive {
    color: #50d2a0;
  }

  .negative {
    color: #ff5f5f;
  }

  /* Modal Styling for diff compo start */
  /* return create class is only for srn prn and grn modal only */
  .return-create {
    .modal-inner {
      .single-date-picker .datepicker-wrapper {
        .filter-dropdown-card {
          left: 103%;
          right: auto;
          min-width: 320px;
          padding: 16px;
          top: -375%;
        }
      }
      &.create {
        max-width: unset;
        width: 644px;
      }
      &.update {
        max-width: unset;
        width: 644px;
      }
      &.read {
        max-width: 1000px;
      }
    }
    .action-icon {
      cursor: pointer;
    }
  }
  .modal-inner {
    &.upload {
      max-width: unset;
      width: 580px;
      .dialog {
        padding: 0;
        form {
          .mb-24 {
            position: relative;
            right: -45px;
            padding-top: 16px;
          }
        }
      }
    }
  }

  /* create grn styling start */
  .pad-48 {
    padding: 48px;
    background: rgba(231, 231, 237, 0.2);
  }
  /* types of select  */
  .selectTypeSmall {
    .select-css {
      min-height: 32px;
      margin-bottom: 0;
    }
    .zindex-2__control {
      min-height: 30px;
      .zindex-2__single-value,
      .zindex-2__placeholder {
        font-weight: 500;
        font-size: 14px;
      }
      .zindex-2__dropdown-indicator {
        padding: 6px;
      }
    }
  }
  .selectTypeGrey {
    .zindex-2__control {
      background: #f1f2f5;
    }
  }
  .filter-dropdown-card .filter-label .iconBtnSmall .icon {
    position: relative;
    top: -2px;
  }

  /* cash styling */
  .section-panel .section-content.section-tab.cash-tab {
    height: calc(100vh - 116px);
    overflow: hidden;
  }
`;
export default BaseWrapper;
