import { dashboard as dashboardRoute } from '../../../../data/enums/Route';
import { getFilterConfig } from '../../../../utils/filter';
import { FILTER } from '../../../../data/enums/filter';
import { USER_ROLE_TITLE } from '../../../../data/enums/UserRole';
import { headerLabelConfig } from '../../../common/HelperFunctions';

const breadCrumb = [
  dashboardRoute,
  {
    id: 1,
    title: headerLabelConfig[USER_ROLE_TITLE.DSE],
    path: '#',
    active: true,
  },
];

const filter = getFilterConfig([FILTER.SKU]);


export {
  breadCrumb as breadCrumbConfig, filter as filterConfig,
};
