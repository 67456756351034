import { breadCrumb as breadCrumbConfig } from '../config';
import { CATALOG_LEVEL } from '../../../../../data/enums/Catalog';
import { hierarchy as catalogHierarchy } from './config';

const stateToRequestMapper = (request) => {
  const {
    title,
    alias,
    catalogId,
    hierarchy,
    images,

  } = request;
  return {
    catalogId,
    title,
    alias,
    parentCatalogDetailId: hierarchy[catalogHierarchy[catalogId - 1].name],
    images,
  };
};

const getBaseUserData = id => ({
  catalogId: id,
  title: '',
  alias: '',
  hierarchy: {
    principal: 0,
    product_group: 0,
    brand: 0,
    market_catalog: 0,
    sub_brand: 0,
    sub_brand_form: 0,
  },
  images: [],
});

const filterHOC = (hierarchy, level) => {
  const filterLevel = hierarchy.find(d => d.catalogId === level);
  return filterLevel ? filterLevel.catalogDetailId : 0;
};

const responseToStateMapper = (data, hierarchy) => ({
  title: data.title,
  alias: data.alias,
  hierarchy: {
    principal: filterHOC(hierarchy, CATALOG_LEVEL.PRINCIPAL),
    product_group: filterHOC(hierarchy, CATALOG_LEVEL.PRODUCT_GROUP),
    brand: filterHOC(hierarchy, CATALOG_LEVEL.BRAND),
    market_catalog: filterHOC(hierarchy, CATALOG_LEVEL.MARKET_CATALOG),
    sub_brand: filterHOC(hierarchy, CATALOG_LEVEL.SUB_BRAND),
    sub_brand_form: filterHOC(hierarchy, CATALOG_LEVEL.SUB_BRAND_FORM),
  },
  images: data.images,
  catalogId: data.catalogId,
});

const breadCrumb = [
  ...breadCrumbConfig,
];

const hierarchy = {
  [CATALOG_LEVEL.PRINCIPAL]: {
    label: 'Principal',
    name: 'principal',
  },
  [CATALOG_LEVEL.PRODUCT_GROUP]: {
    label: 'Product Group',
    name: 'product_group',
  },
  [CATALOG_LEVEL.BRAND]: {
    label: 'Brand',
    name: 'brand',
  },
  [CATALOG_LEVEL.SUB_BRAND]: {
    label: 'Sub Brand',
    name: 'sub_brand',
  },
  [CATALOG_LEVEL.MARKET_CATALOG]: {
    label: 'Market Catalog',
    name: 'market_catalog',
  },
  [CATALOG_LEVEL.SKU_FAMILY]: {
    label: 'SKU Family',
    name: 'sku_family',
  },
  [CATALOG_LEVEL.SUB_BRAND_FORM]: {
    label: 'Sub Brand Form',
    name: 'sub_brand_form',
  },
};

export {
  getBaseUserData,
  stateToRequestMapper,
  breadCrumb as breadCrumbConfig,
  hierarchy,
  responseToStateMapper,
};
