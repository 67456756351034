import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Checkbox from '../Checkbox';
import { Icon } from '../index';

const propTypes = {
  /**
   * @default []
   * group of filters for dropdown
   */
  selectionList: PropTypes.array,
  /**
   * @default itemList
   * children for specific filter
   */
  itemList: PropTypes.array,
  /**
   * @default null
   * onFilter change
   */
  onFilterChange: PropTypes.func,
  /**
   * @default null
   * onSelection of filter group in dropdown
   */
  onFilterGroupSelect: PropTypes.func,
  /**
   * @default null
   * delete of filter group in dropdown
   */
  onGroupDelete: PropTypes.func, // onGroupDelete.
  /**
   * @default {}
   * detail for this filter
   */
  filter: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
  }), // filter detail
  /**
   * @default null
   * get option list for dropdown
   */
  getOptionList: PropTypes.func,
  /**
   * @default []
   * selected children for this specific filter
   */
  selectedItemList: PropTypes.array,
  /**
   * @default []
   * selected Id list for this filter
   */
  selectedIdList: PropTypes.array,
  /**
   * @default null
   * on CheckBox click in filter
   */
  onCheckBoxClick: PropTypes.func,
};

const defaultProps = {
  selectionList: [],
  itemList: [],
  filter: {
    id: '',
    title: '',
    label: '',
    value: '',
  },
  selectedItemList: [],
  selectedIdList: [],
  onCheckBoxClick: () => null,
  getOptionList: () => null,
};

// 17.0: Remove componentWillMount, componentWillReceiveProps, and componentWillUpdate .
// (Only the new “UNSAFE_” lifecycle names will work from this point forward.)

const getSelectedItemChips = (elementList, handleIconClick) => (
  <div className="selected-filter-item-wrap">
    {elementList.map(element => (
      <div className="selected-filter-item">
        {element.title}

        <div className="filter-close">
          <Icon
            iconName="times"
            iconWidth="14px"
            iconHeight="14px"
            onClick={() => handleIconClick(element)}
          />
        </div>
      </div>))}
  </div>);

class BaseFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      // selection list for drop down add the filter with selection list
      selectionList: this.getUpdatedSelectionList(props),
      filter: {
        id: props.filter.id,
        title: props.filter.title,
        label: props.filter.label,
        value: props.filter.value,
        selectedItemList: props.filter.selectedItemList || [],
        selectedIdList: props.filter.selectedIdList || [],
        selected: props.filter.selected || false,
      },
      optionList: [],
      filterText: '',
      showMenuOptions: false, // display menu or not
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // TODO compare filter and prevState and update the view accordingly
    const { filter } = nextProps;

    return { filter };
  }

  handleFilterGroupDelete = () => {
    const { onGroupDelete } = this.props;
    const { filter } = this.state;
    onGroupDelete(filter);
  };

  handleFilterInputChange = (e) => {
    this.setState({ filterText: e.target.value });
  };

  setWrapperRef = (node) => {
    if (node) {
      this.wrapperRef = node;
    }
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        showMenuOptions: false,
        searchText: '',
      });
    } else {
      this.setState({ showMenuOptions: true });
    }
  };

  getFilteredOptionList = () => {
    const { optionList, filterText } = this.state;
    const lowerCase = filterText.toLowerCase();
    const filteredOptionList = optionList.length > 0 ? optionList.filter(option => option.title.toLowerCase()
      .includes(lowerCase)) : [];
    return filteredOptionList;
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('touchstart', this.handleClickOutside);
    this.setOptionList(this.state.filter);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('touchstart', this.handleClickOutside);
  }

  setOptionList = async (filter) => {
    if (filter) {
      const { getOptionList } = this.props;
      const optionList = await getOptionList(filter.label);
      this.setState({ optionList });
    }
  };

  onSelectChange = (event) => {
    const { onFilterGroupSelect } = this.props;
    const oldFilter = { ...this.state.filter };
    const filter = {};
    filter.id = event.id;
    filter.title = event.title;
    filter.label = event.label;
    filter.value = event.value;
    filter.selected = event.selected || false;
    filter.selectedIdList = event.selectedIdList || [];
    filter.selectedItemList = event.selectedItemList || [];
    this.setState({
      // filter,
      showMenuOptions: true,
    }, () => {
      onFilterGroupSelect({ ...event }, { ...oldFilter });
      this.setOptionList(filter);
    });
  };

  // handle the functionality upon the checkbox click.
  onCheckBoxClick = (element) => {
    const { filter } = this.state;
    let { selectedItemList, selectedIdList, onCheckBoxClick } = this.props;
    const elementId = element.id;
    const idIndex = selectedIdList.indexOf(elementId);
    // todo checkbox processor over here
    if (idIndex > -1) {
      selectedIdList.splice(idIndex, 1);
      selectedItemList = selectedItemList.filter(d => d.id !== element.id);
    } else {
      selectedIdList.push(elementId);
      selectedItemList.push(element);
    }
    onCheckBoxClick(filter.label, selectedItemList, selectedIdList);
  };

  getUpdatedSelectionList = (props) => {
    const { selectionList, filter } = props;
    const newSelectionList = [];
    selectionList.map(element => newSelectionList.push(element));
    if (newSelectionList.indexOf(filter) < 0 && filter.label !== 'userOption') {
      newSelectionList.push(filter);
    }
    return newSelectionList;
  };

  render() {
    const {
      showMenuOptions, selectionList, filter, filterText,
    } = this.state;
    const { selectedIdList, selectedItemList } = this.props;
    const filteredOptionList = this.getFilteredOptionList();
    const selectedItemChips = getSelectedItemChips(selectedItemList, this.onCheckBoxClick);
    return (
      <div className="filter-flex">
        <div className="filter-close">
          <Icon
            iconName="times"
            iconWidth="14px"
            iconHeight="14px"
            onClick={() => this.handleFilterGroupDelete()}
          />
        </div>
        <div className="hidden">
          <span>
state Filter
            {JSON.stringify(this.state.filter)}
          </span>
          <span>
state OptionList
            {JSON.stringify(this.state.optionList)}
          </span>
          <span>
filter text
            {JSON.stringify(this.state.filterText)}
          </span>
        </div>
        <div className="filter-select-input">
          <div>
            <Select
              clearable={false}
              className="custom-select"
              classNamePrefix="filter-select"
              placeholder="Select Filter"
              getOptionLabel={({ title }) => `${title}`}
              getOptionValue={({ id }) => id}
              value={selectionList.filter(({ label }) => label === filter.label)}
              options={selectionList}
              enableValiation
              onChange={e => this.onSelectChange(e)}
            />
          </div>
        </div>
        <div className="filter-contain-wrap">
          <span className="filter-contain-text">Contains</span>
          <div ref={node => this.setWrapperRef(node)}>
            <div className="filter-contain-input-wrap">
              {selectedItemChips}
              <input
                type="text"
                id="myText"
                placeholder="Search"
                value={filterText}
                onChange={e => this.handleFilterInputChange(e)}
                autoComplete="off"
              />
            </div>
            {showMenuOptions && (
              <div className="filter-contain-group">
                {filteredOptionList.map(option => (
                  <div className="filter-contain-item">
                    <Checkbox
                      checked={selectedIdList.includes(option.id)}
                      onCheckBoxClick={() => this.onCheckBoxClick(option)}
                    />
                    <span className="filter-item-name">{option.title}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

BaseFilter.propTypes = propTypes;

BaseFilter.defaultProps = defaultProps;

export default BaseFilter;
