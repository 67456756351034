import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { dataProps, paginationProps } from '../../../data/enums/Component';
import withErrorAndLoadingScreen from '../../../utils/composition/withLoading';
import { Pagination } from '../../../components';
import { TableHeader, TableBody } from './tableConfig';
import { PERMISSION_OBJ } from '../../../data/enums/Permission';
import ReportTableStyled from '../ReportTableStyled';
import withImageSlider from '../../../utils/composition/withImageSlider';

const propTypes = {
  data: dataProps.value,
  pagination: paginationProps.value,
  onPageChange: PropTypes.func,
  onCheckBoxClick: PropTypes.func,
  onIconClick: PropTypes.func,
  checkedList: PropTypes.instanceOf(Array),
  onBulkDelete: PropTypes.func.isRequired,
  onReplicateTypeClick: PropTypes.func,
  permission: PropTypes.instanceOf(Object),
  showBulkSelect: PropTypes.bool,
  getCheckedListData: PropTypes.func,
};

const defaultProps = {
  data: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
  onPageChange: () => null,
  onReplicateTypeClick: () => null,
  onCheckBoxClick: () => null,
  onIconClick: () => null,
  checkedList: [],
  permission: PERMISSION_OBJ,
  showBulkSelect: false,
  getCheckedListData: () => null,
};

function View({ ...props }) {
  const {
    data,
    pagination,
    permission,
    onIconClick,
    onPageChange,
    onBulkDelete,
    onReplicateTypeClick,
    getCheckedListData,
    showBulkSelect,
    handleSliderOpen,
    onMarkerClick,
  } = props;
  return (
    <ReportTableStyled>
      <div className='table-wrap'>
        <table>
          {TableHeader()}
          <tbody>
            {data.list.map((ar) =>
              TableBody({ data: ar, handleSliderOpen, onMarkerClick })
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </ReportTableStyled>
  );
}
View.propTypes = propTypes;

View.defaultProps = defaultProps;

const AttendanceViewWithErrorAndLoading = withErrorAndLoadingScreen(View);
const AttendanceViewWithImageSlider = withImageSlider(
  AttendanceViewWithErrorAndLoading
);

export default AttendanceViewWithImageSlider;
