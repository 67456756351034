import { compose } from 'recompose';

import Receipts from './Outstandings';
import { query, UPDATE_TRANSACTION_STATUS } from './API';
import { DOWNLOAD_REPORT } from '../../../common/GqlAPI';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';

const composedReceipt = compose(DOWNLOAD_REPORT, UPDATE_TRANSACTION_STATUS, withRequestTracker({
  query,
  mutation: {
    downloadReport: DOWNLOAD_REPORT,
    updateRetailOutletTransactionStatus: UPDATE_TRANSACTION_STATUS,
  },
}))(Receipts);

export default composedReceipt;
