import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImageUpload from '../../components/ImageUpload';
import { Thumbnail } from '../../../components/ImageViewer';


const propTypes = {
  image: PropTypes.string,
  getImages: PropTypes.func.isRequired,
  showEditButton: PropTypes.func.isRequired,
  domain: PropTypes.string,
};

const defaultProps = {
  image: '',
  domain: '',
};

class ChangePhoto extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    return { image: nextProps.image };
  }

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      images: [{
        image: '',
        thumbnail: props.image || '',
        created_at: '',
      },
      ],
    };
  }

    toggleShow = (state = false) => {
      this.setState({ show: state });
    }

     handleSubmit = (url) => {
       const { getImages } = this.props;
       this.setState({
         images: [{ image: `${url}`, thumbnail: `${url}` }],
       });
       getImages(url);
     };


     render() {
       const { domain, showEditButton, data } = this.props;
       const { show, images } = this.state;
       return (
<>
  { show && (
  <ImageUpload
    onClose={this.toggleShow}
    onSubmit={(type, url) => {
      this.handleSubmit(url);
      this.toggleShow(false);
    }}
    domain={domain}
  />
  )}
  {
    <div className="newProfile">

      { images.map(image => (
        <>
          {
            (image.thumbnail === '') ? (
              <Thumbnail image={data.profileImage ? data.profileImage[0].image : '/image/userProfile.svg'} />
            )
              : (<Thumbnail image={image.thumbnail} className="" />)
          }
        </>
      ))}
      { !showEditButton && (
      <div className="change-photo" onClick={() => this.toggleShow(true)}>
        Change Photo
      </div>
      )}
    </div>
      }
    </>
       );
     }
}

ChangePhoto.propTypes = propTypes;
ChangePhoto.defaultProps = defaultProps;

export default ChangePhoto;
