import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReplenishmentModelView from './View';
import { ALERT_TYPE } from '../../../../../../data/enums/AlertType';
import withAlert from '../../../../../../utils/composition/withAlert';
import { inputChange, debouncer } from '../../../../../../utils/formHandlers';


const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  groupId: PropTypes.number.isRequired,
  getReplenishmentGroupDetail: PropTypes.func.isRequired,
  updateReplenishmentModel: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class ReplenishmentModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        stockDaysHolding: '',
        availabilityDays: '',
        averageDays: '',
        sufficiencyDays: {
          max: '', min: '',
        },
        sufficiencyMatrix: {
          under: '',
          ideal: '',
          over: '',
        },
        sufficiencyWeightage: {
          availability: '',
          sufficiency: '',
          overStock: '',
        },
      },
    };
  }

  componentDidMount() {
    this.loadData();
  }

  checkIfIntOrFloatOrString = (data, entity) => {
    if (entity === 'format') {
      return data === '' ? 0 : data;
    }
    return data === 0 ? '' : data;
  }

  formattedData = (data, entity) => ({
    stockDaysHolding: this.checkIfIntOrFloatOrString(data.stockDaysHolding, entity),
    availabilityDays: this.checkIfIntOrFloatOrString(data.availabilityDays, entity),
    averageDays: this.checkIfIntOrFloatOrString(data.averageDays, entity),
    sufficiencyMatrix: {
      under: this.checkIfIntOrFloatOrString(data.sufficiencyMatrix.under, entity),
      ideal: this.checkIfIntOrFloatOrString(data.sufficiencyMatrix.ideal, entity),
      over: this.checkIfIntOrFloatOrString(data.sufficiencyMatrix.over, entity),
    },
    sufficiencyWeightage: {
      availability: this.checkIfIntOrFloatOrString(data.sufficiencyWeightage.availability, entity),
      sufficiency: this.checkIfIntOrFloatOrString(data.sufficiencyWeightage.sufficiency, entity),
      overStock: this.checkIfIntOrFloatOrString(data.sufficiencyWeightage.overStock, entity),
    },
    sufficiencyDays: {
      max: this.checkIfIntOrFloatOrString(data.sufficiencyDays ? data.sufficiencyDays.max : 0, entity),
      min: this.checkIfIntOrFloatOrString(data.sufficiencyDays ? data.sufficiencyDays.min : 0, entity),
    },
  });

  loadData = () => {
    const { getReplenishmentGroupDetail, groupId } = this.props;
    const { data } = this.state;

    getReplenishmentGroupDetail({
      id: groupId,
    }, {
      handleSuccess: (response) => {
        const replenishmentConfig = response.data.getReplenishmentGroupDetail.replenishmentConfig || data;
        this.setState({
          data: this.formattedData(replenishmentConfig, 'response'),
        });
      },
      handleError: (error) => {
        this.onAPIRequestFailure(error);
      },
    });
  }

  onHandleInputChange = (data) => {
    const { updateReplenishmentModel, groupId, displayAlert } = this.props;
    const input = this.formattedData(data, 'format');
    updateReplenishmentModel({
      id: groupId,
      input,
    }, {
      handleSuccess: (response) => {
        const updatedReplenishmentModel = (response.data.updateReplenishmentModel && response.data.updateReplenishmentModel.replenishmentConfig) || {};
        const updatedData = this.formattedData(updatedReplenishmentModel, 'response');
        this.setState({
          data: updatedData,
        }, () => {
          displayAlert(ALERT_TYPE.SUCCESS, 'Updated Successfully');
        });
      },
      handleError: (error) => {
        this.onAPIRequestFailure(error);
      },
    });
  }

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  onInputChange = (e, firstParam, paramList) => {
    const { data } = this.state;
    const updatedDetails = inputChange(data, e, firstParam, paramList);
    this.setState({
      data: updatedDetails,
    }, () => {
      debouncer(this.onHandleInputChange, 1800)(updatedDetails);
    });
  }

  render() {
    const { data } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <ReplenishmentModelView
        data={data}
        loading={serverResponseWaiting}
        onInputChange={this.onInputChange}
      />
    );
  }
}

ReplenishmentModel.propTypes = propTypes;
ReplenishmentModel.defaultProps = defaultProps;

export default withAlert()(ReplenishmentModel);
