import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import TerritoryView from './View';
import TerritoryForm from './Form';
import { DialogWrapper } from '../../common';
import PageHeader from '../../base/PageHeader';
import { territory } from '../../common/DomainConfig';
import * as queryService from '../../base/query.service';
import { PanelStyled } from '../../common/configuration';
import { refGenerator } from '../../../utils/refGenerator';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { insertUserLabel } from '../../../data/services/userLabel';
import { getPermissionForTerritory } from '../../base/permission';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';
import { crudResponseProcessor, handleFormSubmit } from '../../../utils/crudResponseProcessor';
import {
  crudSuccess as crudRequestConfig, breadCrumbConfig, formConfig, filterConfig,
} from './config';
import { isError } from '../../common/HelperFunctions';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  createTerritory: PropTypes.func.isRequired,
  toggleState: PropTypes.func.isRequired,
  updateTerritory: PropTypes.func.isRequired,
  getTerritoryList: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class Territory extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      display: {
        searchBox: false,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
      },
    };

    this.permission = getPermissionForTerritory();
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      props.displayAlert,
    );

    this.getData = () => {
      const { data } = this.state;
      return data;
    };
    const serverCall = {
      [EVENT_OPERATION.CREATE]: props.createTerritory,
      [EVENT_OPERATION.UPDATE]: props.updateTerritory,
      [EVENT_OPERATION.DELETE]: props.toggleState,
    };
    this.onCRUDSuccess = crudResponseProcessor((data, type) => {
      const { displayAlert } = this.props;
      this.setState(data);
      displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message);
    }, this.getData,
      ['title']);
    this.onFormSubmit = handleFormSubmit(
      this.onCRUDSuccess,
      this.onAPIRequestFailure,
      crudRequestConfig,
      serverCall,
    );
    this.formReference = refGenerator(formConfig.validationField);
  }

  componentDidMount() {
    insertUserLabel();
    this.loadTableData();
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  loadTableData = () => {
    const {
      data, queryParameters,
    } = this.state;
    const { getTerritoryList } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);

    getTerritoryList({
      offset,
      limit: queryParameters.pagination.limit,
      filter: {
        filters: apiFilterProcessor(queryParameters.filter),
        queryString: queryParameters.search,
      },
    }, {
      handleSuccess: (response) => {
        data.list = (response.data.territories && response.data.territories.rows) || [];
        data.total = (response.data.territories && response.data.territories.count) || 0;
        if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
        this.setState(data);
      },
      handleError: (error) => { this.onAPIRequestFailure(error); },
    });
  };

  setQueryParameters = (queryParams, callBack = () => null) => this.setState(
    { queryParameters: queryParams },
    callBack,
  );

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  handleBulkDelete = (type, response) => this.onCRUDSuccess(type)(response);

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  render() {
    const {
      data, queryParameters, display,
    } = this.state;
    const {
      serverResponseWaiting,
    } = this.props;
    return (
      <Fragment>
        <div>
          <DialogWrapper
            onDialogSubmit={this.onFormSubmit}
            formConfig={formConfig}
            refsObj={this.formReference}
            renderDialog={
              (
                {
                  onDialogSubmit, handleDialogInputChange, dialogData,
                  enableErrorDisplay,
                },
              ) => (
                  <Fragment>
                    <TerritoryForm
                      show
                      data={dialogData}
                      onFormSubmit={onDialogSubmit}
                      loading={serverResponseWaiting}
                      enableErrorDisplay={enableErrorDisplay}
                      handleInputChange={handleDialogInputChange}
                      refsObj={this.formReference}
                    />
                  </Fragment>
                )}
            render={({ onDialogItemClick }) => (
              <Fragment>
                  <div className="section-header">
                    <PanelStyled>
                      <PageHeader
                        display={display}
                        breadCrumb={breadCrumbConfig}
                        config={{
                          title: territory.title,
                          create: this.permission.create,
                          download: false,
                          filter: true,
                          search: true,
                        }}
                        filter={{
                          date: queryParameters.date,
                          menuList: filterConfig.menu,
                          onFilterChange: this.basePaginationService.handleFilterChange,
                        }}
                        queryParameters={queryParameters}
                        controlDisplay={this.controlDisplay}
                        handleCreateClick={onDialogItemClick}
                        resetFilter={this.basePaginationService.resetFilter}
                        clearSearchText={this.basePaginationService.clearSearchText}
                        handleSearchChange={this.basePaginationService.handleSearchInputChange}
                        handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
                        handleDownloadClick={this.basePaginationService.handleDownloadClick}
                      />
                    </PanelStyled>
                  </div>
                  <div className="section-content">
                    <TerritoryView
                      data={data}
                      pagination={queryParameters.pagination}
                      onIconClick={onDialogItemClick}
                      loading={serverResponseWaiting}
                      onPageChange={this.basePaginationService.onPageSelect}
                      onBulkDelete={this.handleBulkDelete}
                      permission={this.permission}
                    />
                  </div>
              </Fragment>
            )}
          />
        </div>
      </Fragment>);
  }
}

Territory.propTypes = propTypes;

Territory.defaultProps = defaultProps;

export default withAlert()(Territory);
