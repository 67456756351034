import React, { Component } from 'react';
import PropTypes from 'prop-types';
import View from './View';
import { EVENT_OPERATION } from '../../../../../../data/enums/EventOperation';
import withAlert from '../../../../../../utils/composition/withAlert';
import { ALERT_TYPE } from '../../../../../../data/enums/AlertType';
import { apiFilterProcessor, getOffsetFromPageAndLimit } from '../../../../../../utils/api';
import * as queryService from '../../../../../base/query.service';

const propTypes = {
  createLedger: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  id: PropTypes.number.isRequired,
  getLedgers: PropTypes.func.isRequired,
  billingUser: PropTypes.bool.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class LedgerInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      outletId: props.id,
      crudMode: EVENT_OPERATION.CREATE,
      data: {
        list: [],
        total: 0,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: {
          ...queryService.baseQueryParameters.filter,
          retail_outlet_id: [props.id || 0],
        },
      },
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const { queryParameters, date, data } = this.state;

    const { getLedgers } = this.props;

    const offset = getOffsetFromPageAndLimit(
      queryParameters.pagination.page,
      queryParameters.pagination.limit,
    );

    const filterString = apiFilterProcessor(queryParameters.filter);

    getLedgers({
      offset,
      limit: 1000,
      filter: {
        filters: filterString,
        dateRange: date,
        queryString: '',
        sort: {},
      },
    }, {
      handleSuccess: (response) => {
        data.list = response.data.customers.rows;
        data.total = response.data.customers.count;
        this.setState({ data });
      },
      handleError: (error) => {
        this.onAPIRequestFailure(error);
      },
    });
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleCreateClick = () => {
    this.setState({ show: true });
  };

  onCancel = () => {
    this.setState({ show: false });
  };

  onSubmit = (dialogData) => {
    this.setState({
      show: false,
    }, () => this.getData());
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  render() {
    const {
      show, data, outletId,
    } = this.state;
    const { serverResponseWaiting, billingUser, distributorId } = this.props;
    return (
      <View
        data={data.list}
        onCancel={this.onCancel}
        onSubmit={this.onSubmit}
        handleCreateClick={this.handleCreateClick}
        show={show}
        loading={serverResponseWaiting}
        id={outletId}
        billingUser={billingUser}
        distributorId={distributorId}
      />
    );
  }
}

LedgerInfo.propTypes = propTypes;

LedgerInfo.defaultProps = defaultProps;

export default withAlert()(LedgerInfo);
