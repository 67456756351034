import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';
import {
  Button, CheckBox,
  Icon, Input,
} from '../../../../../components';
import { Col } from '../../../../../components/Grid';
import { dataProps } from '../../../../../data/enums/Component';
import { INVALID } from '../../../../../data/enums/ErrorMessage';
import { fixedFloatAndCommas, fixedFloat } from '../../../../../utils/conversion';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import withErrorAndLoadingScreen from '../../../../../utils/composition/withLoading';
import { menuAction } from '../../../../../utils/paginatedListUtils';
import { normalPresentor } from '../../../../../utils/date';

const propTypes = {
  data: dataProps.value,
  onPageChange: PropTypes.func,
  onCheckBoxClick: PropTypes.func,
  onIconClick: PropTypes.func,
  onButtonClick: PropTypes.func,
  date: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }).isRequired,
  skuLines: PropTypes.instanceOf(Array),
  update: PropTypes.instanceOf(Object),
  breadCrumb: PropTypes.instanceOf(Array),
  checkedList: PropTypes.instanceOf(Array),
  onFilterChange: PropTypes.func.isRequired,
};

const defaultProps = {
  checkedList: [],
  breadCrumb: [],
  onIconClick: () => null,
  onPageChange: () => null,
  onButtonClick: () => null,
  onCheckBoxClick: () => null,
  data: dataProps.defaultValue,
  skuLines: [],
  update: {
    type: EVENT_OPERATION.CREATE,
    status: false,
  },
};

const menuConfigList = [
  {
    title: 'Edit',
    icon: 'pencil',
    permissionDerivedBy: 'update',
    type: EVENT_OPERATION.UPDATE,
  },
  {
    title: 'Delete',
    icon: 'trash',
    permissionDerivedBy: 'delete',
    type: EVENT_OPERATION.DELETE,
  },
];

const TableView = ({ ...props }) => {
  const {
    update,
    skuLines,
    onIconClick,
    checkedList,
    checkBoxStatus,
    totalOrders,
    invoiceNumberStatus,
    onPrimaryCheckBoxClick,
    onSecondaryCheckBoxClick,
  } = props;
  let sn = 0;
  return (
    <Fragment>
      {checkedList.length > 0 && invoiceNumberStatus && (
        <div className="selected-status">
          <div className="content-center">
            <div>
              <CheckBox checked disabled />
              <p className="text">
                <span>{checkedList.length}</span>
                of
                <span>{totalOrders}</span>
                items selected
              </p>
            </div>
            <div className="del-btn" onClick={() => onIconClick(EVENT_OPERATION.BULK_DELETE, {})}>
              <Icon iconName="cross" />
            </div>
          </div>
        </div>
      )}
      <div className="table-wrap">
        <table>
          <thead>
            <tr>
              { invoiceNumberStatus && skuLines.length
                ? (
                  <th>
                    <CheckBox
                      checked={(totalOrders === checkedList.length) && totalOrders !== 0}
                      onCheckBoxClick={e => onPrimaryCheckBoxClick(e)}
                    />
                  </th>
                ) : '' }
              <th>S.No.</th>
              <th>SKU</th>
              <th>Batch</th>
              <th>Stock Type</th>
              <th>MFG Date</th>
              <th>EXP Date</th>
              { invoiceNumberStatus && <th className="text-right">Quantity</th> }
              <th className="text-right">Return Quantity</th>
              <th className="text-right">Rate</th>
              <th className="text-right">Amount</th>
              <th className="text-center">Promotion</th>
              <th className="text-right">Discount</th>
              <th className="text-right">Net Amount</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {
          skuLines.map((sku, index) => (
            <Fragment>
              {
                sku.Lines.map((order, key, array) => {
                  sn += 1;
                  return (
                    <tr key={order.id}>
                      { invoiceNumberStatus ? (
                        <td>
                          <CheckBox
                            checked={checkBoxStatus(order.id)}
                            onCheckBoxClick={e => onSecondaryCheckBoxClick(order, e.currentTarget.checked)}
                          />
                        </td>
                      ) : '' }
                      <td>{sn}</td>
                      <td>{order.SKU && order.SKU.title}</td>
                      <td>
                        {order.SKUBatch && order.SKUBatch.batchDetails.batchNumber
                          ? order.SKUBatch.batchDetails.batchNumber.replace(/_/g, ' ')
                          : order.SKUBatch.batchDetails.batchNumber }
                      </td>
                      <td>{order.stockType}</td>
                      <td className="text-center">
                        {order.SKUBatch
                          ? order.SKUBatch.usageDate
                            ? (normalPresentor(order.SKUBatch.usageDate.manufacture) === 'Invalid date'
                              ? '-' : normalPresentor(order.SKUBatch.usageDate.manufacture)) : null : null}
                      </td>
                      <td className="text-center">
                        {order.SKUBatch
                          ? order.SKUBatch.usageDate ? (normalPresentor(order.SKUBatch.usageDate.expiry) === 'Invalid date'
                            ? '-' : normalPresentor(order.SKUBatch.usageDate.expiry)) : null : null}
                      </td>

                      { invoiceNumberStatus && <td className="text-right">{order.updatedQuantity}</td> }
                      <td className="text-right">{order.returnQuantity}</td>
                      <td className="text-right">{order.updatedAmountDetails && order.updatedAmountDetails.rate}</td>
                      <td className="text-right">
                        {fixedFloatAndCommas((order.updatedAmountDetails
                            && (order.updatedAmountDetails.amount || order.updatedAmountDetails.rate
                              * order.returnQuantity || 0)) || 0)}
                      </td>
                      <td className="text-center">
                        {
                            (key === 0 && sku.promotionId)
                              ? (
                                <img
                                  src="/image/icons/Promotion.svg"
                                  alt="promotion-img"
                                  className="action-icon"
                                  onClick={() => onIconClick(EVENT_OPERATION.READ, sku)}
                                />
                              ) : null
                          }
                      </td>
                      <td className="text-right">
                        {fixedFloatAndCommas((order.updatedAmountDetails
                            && (order.updatedAmountDetails.promotionDiscount
                              + order.updatedAmountDetails.topUpDiscount)) || 0)}
                      </td>
                      <td className="text-right">{fixedFloatAndCommas(order.updatedAmountDetails.subTotal)}</td>

                      <td className="text-right simple-popup-actions" onClick={e => e.stopPropagation()}>
                        {menuAction(menuConfigList, { menuIcon: 'ellipsis-v' },
                          onIconClick, order, { update: true, delete: !invoiceNumberStatus })}
                      </td>

                    </tr>
                  );
                })
              }
            </Fragment>

          ))}
          </tbody>
        </table>
        { !invoiceNumberStatus && (
        <div className="grn-sku-add">
          <Button
            secondary
            iconBtnSmall
            iconName="plus"
            disabled={update.type === EVENT_OPERATION.APPROVE}
            onClick={() => onIconClick(EVENT_OPERATION.CREATE, {})}
          />
        </div>
        )
      }
      </div>
    </Fragment>);
};

TableView.propTypes = propTypes;

TableView.defaultProps = defaultProps;

const TableViewWithErrorAndLoading = withErrorAndLoadingScreen(TableView);

const totalPropTypes = {
  onInputChange: PropTypes.func,
  priceDetails: PropTypes.instanceOf(Object),
};

const totalDefaultProps = {
  onInputChange: () => null,
};

const TotalView = ({ ...props }) => {
  const {
    viewType,
    refsObj,
    enableErrorDisplay,
    priceDetails,
    onInputChange,
    invoiceNumberStatus,
  } = props;

  return (
    <Fragment>
      <div className="grn-sku-update">
        <div className="grn-sku-update-inner">
          <Row>
            <Col md={6}>
              <span>Sub Total</span>
            </Col>
            <Col md={6}>
              <div>
                {fixedFloatAndCommas(priceDetails.subTotal)}
                {' '}
              </div>
            </Col>
            <Col md={6} className="mt-5">
              <span>Bill Discount</span>
            </Col>
            <Col md={6}>
              <Input
                name="billDiscount"
                type="number"
                enableValidation
                rule="isFloat"
                ref={ref => refsObj.billDiscount = ref}
                argument={{
                  min: 0,
                  max: (priceDetails.subTotal || 0) - (priceDetails.tradeDiscount || 0),
                }}
                value={fixedFloat(priceDetails.billDiscount) || 0}
                enableErrorDisplay={enableErrorDisplay}
                onChange={event => onInputChange(event)}
                disabled={invoiceNumberStatus || (viewType === EVENT_OPERATION.DETAILS)}
                errorMessage={INVALID}
              />
            </Col>
            <Col md={6} className="mt-5">
              <span>Trade Discount</span>
            </Col>
            <Col md={6}>
              <Input
                name="tradeDiscount"
                type="number"
                enableValidation
                rule="isFloat"
                argument={{
                  min: 0,
                  max: (priceDetails.subTotal || 0) - (priceDetails.billDiscount || 0),
                }}
                ref={ref => refsObj.tradeDiscount = ref}
                value={fixedFloat(priceDetails.tradeDiscount) || 0}
                enableErrorDisplay={enableErrorDisplay}
                onChange={event => onInputChange(event)}
                disabled={invoiceNumberStatus || (viewType === EVENT_OPERATION.DETAILS)}
                errorMessage={INVALID}
              />
            </Col>
            <Col md={6}>
              <span>Taxable Amount</span>
            </Col>
            <Col md={6}>
              <div>
                {' '}
                {fixedFloatAndCommas(priceDetails.taxableAmount)}
                {' '}
              </div>
            </Col>
            <Col md={6}>
              <span>VAT</span>
            </Col>
            <Col md={6}>
              <div>
                {fixedFloatAndCommas(priceDetails.taxAmount)}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="grn-sku-update-total">
        <span>Total</span>
        <div>
          {fixedFloatAndCommas(priceDetails.netAmount)}
        </div>
      </div>
    </Fragment>
  );
};

TableView.propTypes = propTypes;

TableView.defaultProps = defaultProps;

TotalView.protoType = totalPropTypes;

TotalView.protoType = totalDefaultProps;

export { TableViewWithErrorAndLoading as TableView, TotalView };
