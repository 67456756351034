import styled from 'styled-components';


const TitleTextWrap = styled.div`

svg{
    g{
        text{
            color:#6B6C7E;
            font-weight:400;
            fill:#6B6C7E;
            &:last-child{
                fill:#272833;
            }
        }
    }
}

`;

export default TitleTextWrap;
