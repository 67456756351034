import { getTodaysDate } from '../../../../utils/date';
import { FORM_CONFIG } from '../../../../data/enums/config';
import { refGenerator } from '../../../../utils/refGenerator';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../../data/enums/SuccessMessage';
import { customerMapper } from './summaryDetails/config';
import { STOCK_TYPE } from '../../../common/DomainConfig';
import { SRN } from '../../../../data/enums/Route';
import { breadCrumbConfig } from '../config';


const title = 'Create SRN';

const breadCrumb = [
  ...breadCrumbConfig,
  {
    id: 1,
    title: 'SRN',
    path: `/${SRN}`,
    active: true,
  },
];

const summaryDetailsMapper = (element, distributorId = 0) => ({
  id: element.id || '',
  date: element.date || getTodaysDate(),
  salesInvoiceNumber: element.invoiceNumber || '',
  salesReturnInvoiceNumber: element.salesReturnInvoiceNumber || '',
  Customer: customerMapper(element.Customer || {}),
  RetailOutlet: {
    id: element.RetailOutlet ? element.RetailOutlet.id : '',
    title: element.RetailOutlet ? element.RetailOutlet.title : '',
    Customers: element.RetailOutlet ? element.RetailOutlet.Customers : [],
  },
  Distributor: { id: element.Distributor ? element.Distributor.id || 0 : distributorId },
  reason: element.reason || '',
  cashStatus: element.cashStatus || false,
});

const orderFormatter = (orders = []) => {
  let totalValidOrders = 0;
  const updatedOrders = orders.reduce((orderAcc, order) => {
    const Lines = order.Lines.reduce((acc, item) => {
      if (item.updatedQuantity) {
        item.returnQuantity = item.updatedQuantity || 0;
        item.stockType = STOCK_TYPE.SALEABLE.value;
        item.updatedAmountDetails = item.updatedAmountDetails || {};
        totalValidOrders += 1;
        acc.push(item);
      }

      return acc;
    }, []);
    if (Lines.length > 0) {
      orderAcc.push({ ...order, Lines });
    }
    return orderAcc;
  }, []);

  return { orders: [...updatedOrders], totalValidOrders };
};

const totalPriceMapper = element => ({
  billDiscount: element.billDiscount || 0,
  tradeDiscount: element.tradeDiscount || 0,
  promotionDiscount: element.promotionDiscount || 0,
  discountAmount: element.discountAmount || 0,
  topUpDiscount: element.topUpDiscount || 0,
  grossAmount: element.grossAmount || 0,
  subTotal: element.subTotal || 0,
  taxAmount: element.taxAmount || 0,
  taxableAmount: element.taxableAmount || 0,
  netAmount: element.netAmount || 0,
});

const detailsFormConfig = {
  [FORM_CONFIG.MAPPER]: element => summaryDetailsMapper(element),
  [FORM_CONFIG.TITLE]: `${EVENT_OPERATION.UPDATE} SRN`,
  [FORM_CONFIG.REFS_OBJ]: refGenerator(['outletId', 'reason', 'salesInvoiceNumber']),
  [FORM_CONFIG.VALIDATION_REQUIRED]: true,
};

const formMapper = (element, distributorId) => {
  const orderDetails = element.Orders ? orderFormatter(element.Orders) : { orders: [], totalValidOrders: 0 };

  return {
    ...summaryDetailsMapper(element, distributorId),
    uuid: element.uuid || '',
    orders: orderDetails.orders || [],
    totalValidOrders: orderDetails.totalValidOrders || 0,
    amount: totalPriceMapper(element.amountDetails ? element.amountDetails || {} : {}),
    billDiscountDetails: element.billDiscountDetail ? element.billDiscountDetail || [] : [],
  };
};

const getFormattedSkuLines = (list, oldBillStatus)=> (
  list.map((item) => {
    const {
      amount, ...srnLineAmount
    } = item.updatedAmountDetails;
    return {
      orderId: oldBillStatus ? null : item.id,
      skuId: item.skuId,
      skuBatchId: item.skuBatchId,
      stockType: item.stockType,
      returnQuantity: item.returnQuantity,
      quantity: item.updatedQuantity,
      srnLineAmount,
    };
  }));

const getPayload = data => ({
  invoiceNumber: data.details.salesInvoiceNumber,
  srnInvoiceNumber: data.details.srnInvoiceNumber,
  reason: data.details.reason,
  distributorId: data.details.Distributor.id,
  retailOutletId: data.details.RetailOutlet.id,
  customerId: data.details.Customer ? data.details.Customer.id : null,
  srnLines: getFormattedSkuLines(data.returnOrders, data.oldBillStatus),
  srnAmount: data.amount,
  isOldBill: data.oldBillStatus,
  type: data.srnType,
});

const crudSuccess = {
  [EVENT_OPERATION.CREATE]: {
    objectMapper: data => ({ input: getPayload(data) }),
    responseName: 'returnSales',
    message: `SRN ${MESSAGE_EVENT_OPERATION.CREATE}`,
  },
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: data => ({
      id: data.id,
      input: getPayload(data),
    }),
    responseName: 'updateSrn',
    message: `SRN ${MESSAGE_EVENT_OPERATION.UPDATE}`,
  },
  [EVENT_OPERATION.APPROVE]: {
    objectMapper: data => ({
      id: data.id,
      input: getPayload(data),
    }),
    responseName: 'approveSrn',
    message: `SRN ${MESSAGE_EVENT_OPERATION.APPROVE}`,
  },
  [EVENT_OPERATION.DELETE]: {
    objectMapper: data => ({ id: data.id }),
    responseName: 'cancelSrn',
    message: `${title} ${MESSAGE_EVENT_OPERATION.CREATE}`,
  },
};

export {
  crudSuccess,
  breadCrumb as breadCrumbConfig,
  title,
  detailsFormConfig,
  formMapper,
  summaryDetailsMapper,
  totalPriceMapper,
};
