import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { HEADER_ICONS } from './config';
import MonthlyView from './MonthlyView';
import WeeklyView from './WeeklyView';
import WeekTitle from './WeeklyView/WeekTitle';
import Button from '../../../components/Button';
import MonthTitle from './MonthlyView/MonthTitle';
import { CustomSelect } from '../../../components';
import dseEvents from './MonthlyView/monthyViewData';
import { CALENDAR_PREVIEW } from '../../../data/enums';
import withLoading from '../../../utils/composition/withLoading';
import RosterPrintViewStyled from './RosterPrintViewStyled';
import { getDayMonthTitleFromDate, getMonthYearTitleFromDate } from './date';

const propTypes = {
  calendarHeader: PropTypes.node,
  calendarPreview: PropTypes.string,
  week: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
  month: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
  dateArray: PropTypes.instanceOf(Array),
  weekStartDay: PropTypes.string,
  onHeaderIconClick: PropTypes.func,
};

const defaultProps = {
  calendarHeader: null,
  calendarPreview: 'WEEKLY',
  week: {
    start: '2010-01-01',
    end: '2020-01-08',
  },
  month: {
    start: '2010-01-01',
    end: '2020-01-31',
  },
  dateArray: [],
  weekStartDay: 'SUN',
  onHeaderIconClick: () => null,
};

const View = ({ ...props }) => {
  const {
    calendarHeader,
    calendarPreview,
    week,
    dateArray,
    month,
    weekStartDay,
    onMonthChange,
    onWeekChange,
    onCalendarClick,
    onHeaderIconClick,
    weekEndList,
    holidayList,
    rosterData,
    onSubDSelect,
    subDList,
    activeSubD,
    routeList,
    userList,
    leaveList,
    subDDetails,
    printMode,
    activeUserId,
  } = props;

  const activeUser = userList.find(user => user.id === activeUserId) || {
    firstName: '',
    lastName: '',
  };

  return (
    <div className="roster-wrapper">
      {
        !printMode ? (
          <div className="roster-top-filter">
            <div className="roster-top-search">
              <CustomSelect
                small
                clearable={false}
                className="custom-select"
                placeholder="Select Sub D"
                getOptionLabel={({ title }) => title}
                getOptionValue={({ id }) => id}
                options={subDList}
                enableValiation
                value={subDList.filter(({ id }) => id === activeSubD)}
                onChange={route => onSubDSelect(route)}
              />
              <div className="vertical-divider" />
              <div className="week-month-btn">
                <Button
                  primary={calendarPreview === CALENDAR_PREVIEW.WEEKLY}
                  title="Weekly"
                  onClick={() => onHeaderIconClick(HEADER_ICONS.WEEKLY)}
                />
                <Button
                  primary={calendarPreview === CALENDAR_PREVIEW.MONTHLY}
                  title="Monthly"
                  onClick={() => onHeaderIconClick(HEADER_ICONS.MONTHLY)}
                />
              </div>
            </div>
            <div className="roster-date-select">
              {
                calendarPreview === CALENDAR_PREVIEW.MONTHLY
                  ? (<MonthTitle month={month} onMonthChange={onMonthChange} />) : null
              }
              {
                calendarPreview === CALENDAR_PREVIEW.WEEKLY
                  ? (<WeekTitle weekRange={week} onWeekChange={onWeekChange} />) : null
              }
            </div>
            <div className="roster-action">
              <Button primary title="Today" onClick={() => onHeaderIconClick(HEADER_ICONS.TODAY)} />
              <div className="vertical-divider" />
              <Button
                outlined
                iconName="copy"
                onClick={() => onHeaderIconClick(HEADER_ICONS.REPLICATION)}
              />
              <Button
                outlined
                iconName="print"
                onClick={() => onHeaderIconClick(HEADER_ICONS.PRINT)}
              />
            </div>
          </div>
        ) : null
      }

      {
        printMode ? (
          <RosterPrintViewStyled>
            <div className="roster-print-wrap">
              <div className="roster-print-header">
                {
                  calendarPreview === CALENDAR_PREVIEW.WEEKLY ? (
                    <Fragment>
                      <h2 className="weekly-title">{subDDetails.title}</h2>
                      <p className="weekly-address">{subDDetails.address}</p>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <h2 className="user">
                        {activeUser.firstName}
                        {activeUser.lastName}
                      </h2>
                      <h3 className="monthly-title">{subDDetails.title}</h3>
                      <p className="monthly-address">{subDDetails.address}</p>
                    </Fragment>
                  )
                }
              </div>
              <div className="roster-print-date">
                <h3>
                  {
                    calendarPreview === CALENDAR_PREVIEW.WEEKLY
                      ? `${getDayMonthTitleFromDate(week.start)} - ${getDayMonthTitleFromDate(week.end)}`
                      : `${getMonthYearTitleFromDate(month.start)}`
                  }
                </h3>
                <span>Call Roster</span>
              </div>
              {
                calendarPreview === CALENDAR_PREVIEW.MONTHLY
                  ? (
                    <MonthlyView
                      header={calendarHeader}
                      data={rosterData.length > 0 ? rosterData[0].data : []}
                      userList={userList}
                      month={month}
                      events={dseEvents}
                      weekStartDay={weekStartDay}
                      weekEndList={weekEndList}
                      holidayList={holidayList}
                      leaveList={leaveList}
                    />
                  ) : (
                    <WeeklyView
                      header={calendarHeader}
                      userList={userList}
                      weekRange={week}
                      weekEndList={weekEndList}
                      data={rosterData}
                      dateList={dateArray}
                      weekStartDay={weekStartDay}
                      holidayList={holidayList}
                      leaveList={leaveList}
                      onCalendarClick={onCalendarClick}
                    />
                  )
              }
            </div>
          </RosterPrintViewStyled>
        ) : null
      }
      {
        calendarPreview === CALENDAR_PREVIEW.MONTHLY && !printMode
          ? (
            <MonthlyView
              header={calendarHeader}
              data={rosterData.length > 0 ? rosterData[0].data : []}
              userList={userList}
              month={month}
              events={dseEvents}
              weekStartDay={weekStartDay}
              weekEndList={weekEndList}
              holidayList={holidayList}
              leaveList={leaveList}
            />
          ) : null
      }
      {
        calendarPreview === CALENDAR_PREVIEW.WEEKLY && !printMode
          ? (
            <WeeklyView
              header={calendarHeader}
              userList={userList}
              weekRange={week}
              weekEndList={weekEndList}
              data={rosterData}
              dateList={dateArray}
              weekStartDay={weekStartDay}
              holidayList={holidayList}
              leaveList={leaveList}
              onCalendarClick={onCalendarClick}
            />
          ) : null
      }

    </div>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const ViewWithLoading = withLoading(View);

export default ViewWithLoading;
