import { compose } from 'recompose';
import ReplenishmentModel from './ReplenishmentModel';
import { query, mutation, UPDATE_REPLINESHMENT_GROUP } from './API';
import withRequestTracker from '../../../../../../utils/composition/withRequestTracker';

const composedReplenishmentModel = compose(
  UPDATE_REPLINESHMENT_GROUP,
  withRequestTracker({ query, mutation }),
)(ReplenishmentModel);

export default composedReplenishmentModel;
