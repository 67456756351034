import gql from 'graphql-tag';
import { graphql } from 'react-apollo';


const REPLENISHMENT_FRAGMENT = gql`
  fragment ReplenishmentFragment on StockGroupDetail {
    replenishmentConfig {
      stockDaysHolding
      availabilityDays
      averageDays
      sufficiencyMatrix {
        under 
        ideal
        over
      }
      sufficiencyDays {
        max
        min
      }
      sufficiencyWeightage {
        availability
        sufficiency
        overStock
      }
    }
  }
`;

const GET_REPLENISHMENT_GROUP_DETAIL = gql`
    query getReplenishmentGroupDetail($id: Int!) {
        getReplenishmentGroupDetail(id: $id) {
         ...ReplenishmentFragment
        }
    }
    ${REPLENISHMENT_FRAGMENT}
`;

const UPDATE_REPLINESHMENT_GROUP = graphql(gql`
    mutation ($id: Int!, $input: ReplenishmentConfigInput!) {
          updateReplenishmentModel(id: $id, input: $input){
            replenishmentConfig {
              stockDaysHolding
              availabilityDays
              averageDays
              sufficiencyMatrix {
                under 
                ideal
                over
              }
              sufficiencyDays {
                max
                min
              }
              sufficiencyWeightage {
                availability
                sufficiency
                overStock
              }
            }
        }
    }
    `, {
  props: ({ mutate }) => ({
    updateReplenishmentModel: variables => mutate({
      variables,
    }),
  }),
});


const query = {
  getReplenishmentGroupDetail: GET_REPLENISHMENT_GROUP_DETAIL,
};
const mutation = {
  updateReplenishmentModel: UPDATE_REPLINESHMENT_GROUP,
};

export { query, mutation, UPDATE_REPLINESHMENT_GROUP };
