import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import {
  AMOUNT_DETAIL_FRAGMENT,
  PROMOTION_TITLE_FRAGMENT,
} from '../../../common/GqlFragments';
import {
  GET_DISTRIBUTORS_SERVICES, GET_OUTLETS, GET_SKU_WITH_BATCH,
} from '../../../common/GqlAPI';
import {
  INVOICE_FRAGMENT, ORDER_DETAIL_FRAGMENT,
} from '../../orderProcessing/API';
import { GET_SRN_DETAIL } from '../details/API';

const GET_INVOICE_BY_INVOICE_NUMBER = gql`
    query getInvoiceByInvoiceNumber($invoiceNumber: String!) {
        invoiceDetailsByNumber(invoiceNumber: $invoiceNumber) {
            ...InvoiceFragment,
            id
            Orders{
                promotionId
                Promotion {
                    ...PromotionTitleFragment
                }
                Lines {
                    ...OrderDetailFragment,
                    updatedQuantity,
                    updatedAmountDetails {
                        ...AmountDetailFragment
                    }
                }

            }
        }
    }
    ${INVOICE_FRAGMENT}
    ${PROMOTION_TITLE_FRAGMENT}
    ${AMOUNT_DETAIL_FRAGMENT}
    ${ORDER_DETAIL_FRAGMENT}
`;

const VALIDATE_SRN_DATA = gql`
    query validateSrnData($input: SrnValidateInput!) {
        validateSrnData(input : $input) {
            status
            Data {
                ...OrderDetailFragment,
                updatedQuantity,
                updatedAmountDetails {
                    ...AmountDetailFragment
                }
             
            }
        }
    }
   ${ORDER_DETAIL_FRAGMENT}
    ${AMOUNT_DETAIL_FRAGMENT}
`;


/* const VALIDATE_SRN_DATA = graphql(gql`
    query ($input: SrnValidateInput!) {
      validateSrnData(input: $input) {
          status
          Data {
              ...OrderDetailFragment
          }
      }
    }
  ${ORDER_DETAIL_FRAGMENT}
  `,
{
  props: ({ mutate }) => ({
    validateSrnData: variables => mutate({
      variables,
    }),
  }),
}); */

const CREATE_SRN = graphql(gql`
    mutation($input: SRNInput!) {
        returnSales(input: $input)
        {
            id
            SRNInvoiceNumber
        }
    }
`, {
  props: ({ mutate }) => ({
    createSRN: variables => mutate({
      variables,
    }),
  }),
});

const UPDATE_SRN = graphql(gql`
    mutation ($id: Int!, $input: GoodsReceivedNoteInput!) {
        updateGrn(id: $id, input: $input)
        {
            id
            SRNInvoiceNumber
        }
    }
`, {
  props: ({ mutate }) => ({
    updateSRN: variables => mutate({
      variables,
    }),
  }),
});

const APPROVE_SRN = graphql(gql`
    mutation ($id: Int!, $input: GoodsReceivedNoteInput!) {
        approveGRN(id: $id, input: $input)
        {
            id
            SRNInvoiceNumber
        }
    }
`, {
  props: ({ mutate }) => ({
    approveSRN: variables => mutate({
      variables,
    }),
  }),
});

const GET_INVOICE_NUMBER_LIST = gql`
    query getInvoiceNumberList( $invoiceString: String!, $distributorId: Int!) {
        searchInvoiceNumber(invoiceString: $invoiceString, distributorId: $distributorId) {
            invoiceNumbers
            matched
        }
    }
`;


const query = {
  getOutlets: GET_OUTLETS,
  getSkus: GET_SKU_WITH_BATCH,
  getDistributors: GET_DISTRIBUTORS_SERVICES,
  getInvoiceDetail: GET_INVOICE_BY_INVOICE_NUMBER,
  getSrnDetail: GET_SRN_DETAIL,
};

const mutation = {
  createSRN: CREATE_SRN,
};

export {
  CREATE_SRN,
  UPDATE_SRN,
  APPROVE_SRN,
  GET_INVOICE_NUMBER_LIST,
  VALIDATE_SRN_DATA,
};

export { query, mutation };
