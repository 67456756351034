import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { BreadCrumb, Button } from '../../../../../components/index';
import { ORDER_FULFILMENT } from '../../../../../data/enums/Route';
import Table from './table';
import CustomerDetail from './customerDetail';
import { PanelStyled } from '../../../../common/configuration';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import withAlert from '../../../../../utils/composition/withAlert';
import { handleFormSubmit } from '../../../../../utils/crudResponseProcessor';
import { crudSuccess as crudRequestConfig, detailMapper, breadCrumb } from './config';
import { clone } from '../../../../../utils/arrayProcessor';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import {
  getPermissionForOrderFulfilment,
} from '../../../../base/permission';
import { getUserRole } from '../../../../../data/services';
import { USER_ROLE } from '../../../../../data/enums';
import SalesInvoiceStyled from './SalesInvoiceStyled';
import withLoading from '../../../../../utils/composition/withLoading';
import { handlePrint, headerLabelConfig } from '../../../../common/HelperFunctions';
import PanelCard from '../../../../../components/Cards/PanelCard';
import { USER_ROLE_TITLE } from '../../../../../data/enums/UserRole';
import { DOMAIN } from '../../../../../data/enums/config';

const propTypes = {
  breadCrumb: PropTypes.array,
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  invoiceOrders: PropTypes.func.isRequired,
};

const defaultProps = {
  breadCrumb: [],
  serverResponseWaiting: false,
};

class SalesInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: detailMapper({}),
      enableErrorDisplay: false,
      permission: { BILLING: true, INVOICE: true },
    };
    const serverCall = {
      [EVENT_OPERATION.CREATE]: props.invoiceOrders,
    };
    this.onCRUDSuccess = this.responseProcessor(this.handleInvoiceSuccess);
    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.onAPIRequestFailure,
      crudRequestConfig, serverCall);
    // this.loggedInUserPermission = getPermissionForSalesInvoice();
    this.permission = getPermissionForOrderFulfilment();
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const data = detailMapper(JSON.parse(localStorage.getItem('activeOrder'))) || {};

    this.setState({ data }, () => this.derivePermission());
  };

  derivePermission = () => {
    const { data } = this.state;
    const billingStatus = data.Distributor.servicesUsed.billing.status || false;
    let invoiceStatus = this.permission.update;
    if (billingStatus && this.permission.update) {
      invoiceStatus = !(getUserRole() === USER_ROLE.DISTRIBUTOR_ADMIN);
    }
    this.setState({
      permission: {
        BILLING: billingStatus,
        INVOICE: invoiceStatus,
      },
    });
  }

  handleSubmit = () => {
    const valid = this.getValidationStatus();
    if (valid) {
      this.createInvoice();
    } else {
      this.setState({ enableErrorDisplay: true });
    }
  };

  handleCancel = () => {
    this.directToMainPage();
  };

  getValidationStatus = () => {
    const detailsStatus = this.getCustomerDetailsValidation();
    const tableStatus = this.getAmountDetailsValidation();

    return (detailsStatus && tableStatus);
  };

  createInvoice = () => {
    const { data } = this.state;
    const updatedData = clone(data);
    updatedData.amountDetails = this.getAmountDetails();
    updatedData.customerDetails = this.getCustomerDetails();
    this.onFormSubmit(EVENT_OPERATION.CREATE, updatedData);
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
    console.log(error);
  };

  responseProcessor = (callBack) => {
    const onCRUDSuccess = type => (response) => {
      callBack(
        response,
        type,
      );
    };

    return onCRUDSuccess;
  };

  handleInvoiceSuccess = (response, type) => {
    const { permission, data } = this.state;
    const { displayAlert } = this.props;
    if (permission.BILLING) {
      displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message);
      setTimeout(() => {
        handlePrint(
          {
            distributorServices: data.Distributor.servicesUsed,
            invoiceNumber: response.invoiceNumber,
            firstCopy: true,
            module: DOMAIN.SALES,
          },
          this.directToMainPage,
        );
      }, 800);
    } else {
      displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message, this.directToMainPage);
    }
  }


  directToMainPage = () => {
    /** direct to Order Fulfilment page */
    const { history } = this.props;
    history.push(`/${ORDER_FULFILMENT}`);
  };

  render() {
    const {
      data,
      permission,
      enableErrorDisplay,
    } = this.state;
    const {
      loading,
    } = this.props;
    /** todo: render according to due invoice page design */
    return (
      <SalesInvoiceStyled>
        <div className="section-header border-b">
          <PanelStyled>
            <BreadCrumb list={breadCrumb} />
            <div className="invoice-view-wrap">
              <h2 className="invoice-title">
                {data.RetailOutlet.title}
              </h2>
              <div className="invoice-inner">
                <Button small secondary disabled={loading} onClick={() => this.handleCancel()}>
                    Cancel
                </Button>
                {
                    this.permission.update && (
                      <Button small primary disabled={loading} onClick={() => this.handleSubmit()}>
                        Invoice
                      </Button>
                    )
                  }
              </div>
            </div>
          </PanelStyled>
        </div>
        <div className="section-content invoice">
          <div className="invoice-stats">
            <PanelCard cardTitle="Details">
              <div className="customer-info">
                <Row>
                  <Col md={3}>
                    <div className="info">
                      <label>Outlet ID</label>
                      <span>{data.RetailOutlet.id}</span>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="info">
                      <label>Category</label>
                      <span>{data.RetailOutlet.Category.title}</span>
                    </div>
                  </Col>
                  {/* <Col md={3}>
                        <div className="info">
                          <label>Address</label>
                          <span>{data.RetailOutlet.address}</span>
                        </div>
                      </Col> */}
                  <Col md={3}>
                    <div className="info">
                      <label>PAN</label>

                      <span>{data.RetailOutlet.panNumber}</span>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="info">
                      <label>{headerLabelConfig[USER_ROLE_TITLE.DSE]}</label>
                      <span>{data.User.fullName}</span>
                    </div>
                  </Col>
                </Row>
              </div>
              <CustomerDetail
                loading={loading}
                outletId={data.RetailOutlet.id}
                distributorId={data.Distributor.id}
                permission={permission}
                enableErrorDisplay={enableErrorDisplay}
                getDetails={childMethod => this.getCustomerDetails = childMethod}
                getStatus={childMethod => this.getCustomerDetailsValidation = childMethod}
              />
            </PanelCard>
            <PanelCard cardTitle="SKU">
              <div className="invoice-panel-wrap">
                <div className="invoice-table-wrap">
                  <Table
                    data={data.selectedOrders}
                    amountDetails={data.callOrderValue || {}}
                    enableErrorDisplay={enableErrorDisplay}
                    getDetails={childMethod => this.getAmountDetails = childMethod}
                    getStatus={childMethod => this.getAmountDetailsValidation = childMethod}
                  />
                </div>
              </div>
            </PanelCard>
          </div>
        </div>
      </SalesInvoiceStyled>
    );
  }
}

SalesInvoice.propTypes = propTypes;

SalesInvoice.defaultProps = defaultProps;

export default withLoading(withAlert()(SalesInvoice));
