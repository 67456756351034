import React, { Component, Fragment } from 'react';
import { OUTLET_TYPES } from '../config';
import { compareList, filterItems } from '../../../../../../utils/arrayProcessor';
import { OUTLET_DETAILS } from '../../../../../../data/enums/Route';
import { defaultOutletVisibility } from './config';
import renderDom from '../../../../../../utils/renderDom';
import { checkExistAndReturnValue } from '../../../../../../utils/objectProcessor';
import { RouteContext } from '../../../config';

const propTypes = {};
const defaultProps = {
  outletVisibility: defaultOutletVisibility,
};

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      google: window.google,
      infowindow: new window.google.maps.InfoWindow(),
      data: props.data,
      map: '',
      mapData: [],
      markers: [],
    };
  }

  static getDerivedStateFromProps(nextProps) {
    // debugger;
    return {
      mapData: nextProps.mapData,
      data: nextProps.data,
    };
  }

  componentDidMount() {
    this.loadMap();
  }

  componentDidUpdate(prevProps, prevState) {
    const { outletVisibility } = this.props;
    const { map, data } = this.state;

    if (!compareList(prevProps.data, data) || (!compareList(prevProps.outletVisibility, outletVisibility))) {
      // debugger;
      this.loadMarkerToMap(map);
    }
  }

  loadMap = () => {
    const { google, data, mapData } = this.state;
    const map = new google.maps.Map(document.getElementById('map'), {
      center: { lat: 28.3949, lng: 84.124 },
      zoom: 7.5,
      styles: [],
      gestureHandling: 'cooperative',
    });
    // this.loadMarkerToMap(map);
    this.setState({ map });
  };

  loadMarkerToMap = (map) => {
    const { markers, data } = this.state;
    const { outletVisibility } = this.props;

    let configuredMarkers = [];
    this.clearMarkers(markers);
    if (outletVisibility[OUTLET_TYPES.ASSIGNED]) {
      configuredMarkers = configuredMarkers.concat(this.configureMarker(map,
        data[OUTLET_TYPES.ASSIGNED].outlets || [], OUTLET_TYPES.ASSIGNED) || []);
    }
    if (outletVisibility[OUTLET_TYPES.UNASSIGNED]) {
      configuredMarkers = configuredMarkers.concat(this.configureMarker(map,
        data[OUTLET_TYPES.UNASSIGNED].outlets || [], OUTLET_TYPES.UNASSIGNED) || []);
    }
    if (outletVisibility[OUTLET_TYPES.OTHER]) {
      configuredMarkers = configuredMarkers.concat(this.configureMarker(map,
        data[OUTLET_TYPES.OTHER].outlets || [], OUTLET_TYPES.OTHER) || []);
    }
    // debugger;
    if (configuredMarkers.length > 0) {
      map.panTo(configuredMarkers[0].getPosition());
      map.setZoom(15);
    }
    this.setState({ markers: configuredMarkers });
  };

  configureMarker = (map, list, type) => {
    const { google } = this.state;
    const markers = [];

    list.forEach((item) => {
      const marker = new google.maps.Marker({
        position: {
          lat: item.geoLocation.latitude || 0,
          lng: item.geoLocation.longitude || 0,
        },
        map,
        icon: this.getIcon(type),
        id: item.id,
        type,
      });
      marker.addListener('click', () => this.handleMarkerClick(marker));
      markers.push(marker);
    });

    return markers;
  };

  handleMarkerClick = (marker) => {
    const {
      infowindow, map, data,
    } = this.state;
    const filteredData = filterItems(data[marker.type].outlets || [], marker.id)[0] || {};
    infowindow.close();
    const div = document.createElement('div');
    renderDom(this.getInfoWindowView(filteredData, marker.type), div);
    infowindow.setContent(div);
    infowindow.open(map, marker);
  };

  handleRemoveFromRoute = (id) => {
    const { onRemoveFromRoute } = this.props;

    onRemoveFromRoute([id]);
  };

  handleAssignToRoute = (id, type) => {
    const { onAssignToRoute } = this.props;

    onAssignToRoute({ [type]: [id] });
  };

  getInfoWindowView = (data, type) => (
    <div className="popover-content">
      <div className="popover-inner">
        <h3 onClick={() => this.openInNewWindow(`${OUTLET_DETAILS}/${data.id}`)}>{data.title}</h3>
        <ul>
          <li>
            ID:
            {' '}
            <span>{data.id}</span>
          </li>
          <li>
            Channel:
            {' '}
            <span>{checkExistAndReturnValue(data.Category.Channel, 'title')}</span>
          </li>
          <li>
            Category:
            {' '}
            <span>{checkExistAndReturnValue(data.Category, 'title')}</span>
          </li>
          <li>
            Address:
            {' '}
            <span>{data.address}</span>
          </li>
        </ul>
        {this.getButtonType(type, data)}
      </div>
    </div>
  );

  getButtonType = (type, data) => {
    const title = type === OUTLET_TYPES.ASSIGNED ? 'Remove From Route' : 'Assign To Route';
    const { permission = {} } = this.context;

    return (
      <Fragment>
        {
          permission.update && (
            <a
              className="popover-btn"
              onClick={() => {
                type === OUTLET_TYPES.ASSIGNED
                  ? this.handleRemoveFromRoute(data.id)
                  : this.handleAssignToRoute(data.id, type);
              }}
            >
              {title}
            </a>
          )
        }
      </Fragment>
    );
  };

  getIcon = (type) => {
    switch (type) {
      case OUTLET_TYPES.ASSIGNED:
        return (`${process.env.PUBLIC_URL}/image/icons/ic_geolocation_red.svg`);
      case OUTLET_TYPES.UNASSIGNED:
        return (`${process.env.PUBLIC_URL}/image/icons/ic_geolocation_blue.svg`);
      case OUTLET_TYPES.OTHER:
        return (`${process.env.PUBLIC_URL}/image/icons/ic_geolocation_purple.svg`);
      default:
        return '';
    }
  };

  clearMarkers = (markers) => {
    if (markers.length !== 0) {
      markers.forEach((d, i) => {
        markers[i].setMap(null);
      });
    }
  };

  directToPage = (url) => {
    const { history } = this.props;
    history.push(`/${url}`);
  };

  openInNewWindow = (url) => {
    const newWin = window.open(`${window.origin}/${url}`);
    if (!newWin || newWin.closed || typeof newWin.closed === 'undefined') {
      alert('Please enable pop for this site');
    }
  };

  render() {
    const { data } = this.props;

    return (
      <div>
        <div id="map" style={{ position: 'inherit !important', height: '700px' }} />
      </div>
    );
  }
}

Map.propTypes = propTypes;
Map.defaultProps = defaultProps;
Map.contextType = RouteContext;

export default Map;
