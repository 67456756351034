import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from '../../../components';

const propTypes = {
  label: PropTypes.string,
  primaryKey: PropTypes.string,
  list: PropTypes.instanceOf(Array),
  countList: PropTypes.instanceOf(Array),
  countListPrimaryKey: PropTypes.string,
};

const defaultProps = {
  list: [],
  countList: [],
  label: 'title',
  primaryKey: 'id',
  countListPrimaryKey: 'id',
};

const VerticalListWithCount = ({
  title, onItemClick, primaryKey, list, label, activeLine,
  countList, countListPrimaryKey,
}) => {
  const getLineCount = (line) => {
    const countItem = countList.find(item => item[primaryKey] === line[countListPrimaryKey]) || {};
    return countItem.count ? <Badge light label={countItem.count} /> : '';
  };
  const getFormattedList = () => list.map(d => (
    <li
      key={d[primaryKey]}
      onClick={() => onItemClick(d[primaryKey], d)}
      className={d[primaryKey] === activeLine ? 'active' : 'inActive'}
    >
      <p className="title">
        {d[label]}
      </p>
      {getLineCount(d)}
    </li>
  ));
  return (
    <>
      <div className="panel one">
        <p className="p-title">{title}</p>
        <ul>
          {getFormattedList()}
        </ul>
      </div>
    </>
  );
};

VerticalListWithCount.propTypes = propTypes;

VerticalListWithCount.defaultProps = defaultProps;

export default VerticalListWithCount;
