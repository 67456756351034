import React from 'react';
import CheckBox from '../../../../../../components/Checkbox';
import OutletContext from './OutletContext';

const getStyle = ({ draggableStyle, virtualStyle, isDragging }) => {
  // If you don't want any spacing between your items
  // then you could just return this.
  // I do a little bit of magic to have some nice visual space
  // between the row items
  const combined = {
    ...virtualStyle,
    ...draggableStyle,
  };

  // Being lazy: this is defined in our css file
  const grid = 12;

  // when dragging we want to use the draggable style for placement, otherwise use the virtual style
  const result = {
    ...combined,
    height: isDragging ? combined.height : combined.height - grid,
    left: isDragging ? combined.left : combined.left + grid,
    width: isDragging
      ? draggableStyle.width
      : combined.width,
    marginBottom: grid,
    boxShadow: '0 2px 4px 0 rgba(39,40,51,0.12)',
    padding: '16px',
    borderRadius: '4px',
    fontSize: ' 12px',
    fontWeight: 600,
    color: '#272833',
    outline: 'none',
    background: '#fff'
  };

  return result;
};

const Item = ({
  provided, item, style, isDragging, outletState,
}) => (
  <OutletContext.Consumer>
    {
      ({ checked, onCheckBoxClick }) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          style={getStyle({
            draggableStyle: provided.draggableProps.style,
            virtualStyle: style,
            isDragging,
          })}
          className={`item ${checked[item.id] && 'active'} ${isDragging ? 'is-dragging' : ''}`}
        >

          <CheckBox
            onCheckBoxClick={event => onCheckBoxClick(event, item.id, outletState)}
            checked={checked[item.id]}
          />
          <div className="route-drag-title">
            <h6 style={{
              margin: '0 0 8px',
              fontSize: '12px',
              lineHeight: '15px',
              fontWeight: '600',
            }}>
              <a style={{
                textDecoration: 'none',
                color: '#6B6C7E',
              }} href="javascript:void(0)" onClick={() => { window.open(`/sales/outlet/details/${item.id}`); }}>
                {item.title}
              </a>
            </h6>
            <div>
              <span style={{
                fontSize: '10px',
                lineHeight: '10px',
                fontWeight: 'normal',
                color: '#6B6C7E',
                marginRight: '8px',
              }} className="number">{item.id}</span>
              <span style={{
                fontSize: '10px',
                lineHeight: '10px',
                fontWeight: 'normal',
                color: '#6B6C7E',
                position: 'relative',
              }} className="sub">{item.Category.title}</span>
            </div>
          </div>
        </div>
      )}
  </OutletContext.Consumer>
);

export default Item;
