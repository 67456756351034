import React from 'react';
import PropTypes from 'prop-types';
import { tableData } from '../../../utils/paginatedListUtils';
import { fixedFloatAndComma, fixedFloatAndCommas } from '../../../utils/conversion';

const labelMappings = {
  distributor: 'distributor',
  town: 'town',
  route: 'route',
  outletsInCoverage: 'outletsInCoverage',
  activeOutlets: 'activeOutlets',
  coverage: 'coverage',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.distributor,
    title: 'Distributor',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.town,
    title: 'Town',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.route,
    title: 'Route',
    show: true,
  }, {
    id: 4,
    label: labelMappings.outletsInCoverage,
    title: 'Outlets In Coverage',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.activeOutlets,
    title: 'Active Outlets',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.coverage,
    title: 'Coverage %',
    show: true,
  },
];

const TableHeader = () => (
  <thead className="">
    <tr>
      {labelConfig.map(label => (label.show
        ? tableData(label.title, label.align, label.classNames) : null))}
    </tr>
  </thead>
);

const returnTableBody = (data) => {
  const table = {};

  table[labelMappings.distributor] = (
    <td key={`${data.dse_id}-distributor`}>
      {data.distributor}
    </td>
  );
  table[labelMappings.town] = (
    <td key={`${data.dse_id}-town`}>
      {data.town}
    </td>
  );
  table[labelMappings.route] = (
    <td key={`${data.dse_id}-route`}>
      {data.route}
    </td>
  );
  table[labelMappings.outletsInCoverage] = (
    <td key={`${data.dse_id}-outletsInCoverage`}>
      {fixedFloatAndComma(data.coverage_outlets)}
    </td>
  );
  table[labelMappings.activeOutlets] = (
    <td key={`${data.dse_id}-activeOutlets`}>
      {fixedFloatAndComma(data.active_outlets)}
    </td>
  );
  table[labelMappings.coverage] = (
    <td key={`${data.dse_id}-coverage`}>
      {fixedFloatAndCommas(data.active_percent)}
    </td>
  );

  return table;
};

const TableBody = ({
  data,
}) => {
  // Expanding/Dragging and collapsing table body.
  // color based upon table body
  // tooltip if it is sorted.
  // active className
  const tableBody = returnTableBody(data);

  return (

    <tr key={`distCoverage-${data.dse_id}`}>
      {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
};

TableBody.defaultProps = {
  data: {},
};

export {
  TableHeader,
  TableBody,
};
