import gql from 'graphql-tag';
import { JWT } from '../../environment';
import {
  query as userGroupQuery,
  GET_ROLE_LIST
} from '../../views/configuration/user/userRole/API';
import { GET_USER_LIST } from '../../views/configuration/user/userAccount/API';
// import { GET_ROLE_LIST } from '../../views/configuration/user/userRole/API';


import { apolloClient, clientResolver, DEFAULT_QUERY_VARIABLES, errorProcessor } from './common';

const TABLE = {
  USER_GROUP: 'user_group',
  USER_LIST: 'user_list',
  ASM: 'asm',
  STL: 'stl',
  DSE: 'dse',
};

// LOAD ASM, STL, DSE

const getUser = () => {
  const user = localStorage.getItem(JWT.LOCAL_STORAGE.APP_STORAGE.NAME);

  return JSON.parse(user);
};

const getUserBillingCredentials = () => {
  const user = getUser();

  return user.billingCredentials || {};
};
/** ******************************* User List *************************************** */

const userListQuery = gql`
    query userList {
        user_list {
            id,
            roleId,
            firstName,
            lastName,
            parentUserId
        }
    }
`;

const getUserListFromServer = async (filterInp) => apolloClient
  .query({
    query: GET_USER_LIST,
    variables: {
      ...DEFAULT_QUERY_VARIABLES,
      filter: filterInp || {},
    },
  })
  .then(res => res.data.users.rows || [])
  .catch(err => errorProcessor(err));


const setUserList = (data) => {
  clientResolver
    .then(client => client.writeQuery({
      query: userListQuery,
      data: {
        [TABLE.USER_LIST]: data,
      },
    }));
};

const getUserList = async (roleId = null, parentIds = []) => clientResolver
  .then(client => client.readQuery({ query: userListQuery }))
  .then((response) => {
    const userList = response[TABLE.USER_LIST] || [];

    if (!roleId && !parentIds.length) {
      return userList;
    }

    const userFilteredByRole = roleId
      ? userList.filter(user => user.roleId === roleId) : [...userList];

    return parentIds.length
      ? userFilteredByRole.filter(user => parentIds.includes(user.parentId))
      : [...userFilteredByRole];
  });


// return User List
// TODO get the chained UserList for service
const getChainedUserList = (parentId, test = [1, 2, 3]) => {

};

const fetchAndSetUserList = async () => {
  const userList = await getUserListFromServer();
  setUserList(userList);
};

/** ******************************* User Group *************************************** */

const clientUserGroupQuery = gql`
    query getUserGroup {
        user_group {
            id,
            name,
        }
    }
`;

const getUserGroupFromServer = async () => apolloClient
  .query({
    query: userGroupQuery.getUserGroupList,
  })
  .then(res => res.data.userGroups.rows || [])
  .catch(err => errorProcessor(err));


const setUserGroup = (data) => {
  clientResolver
    .then(client => client.writeQuery({
      query: clientUserGroupQuery,
      data: {
        [TABLE.USER_GROUP]: data,
      },
    }));
};

const getUserGroup = async () => clientResolver
  .then(client => client.readQuery({ query: clientUserGroupQuery }))
  .then(response => response[TABLE.USER_GROUP]);


const fetchAndSetUserGroup = async () => {
  const userRole = await getUserGroupFromServer();
  setUserGroup(userRole);
};

/** ******************************* End of User Group *************************************** */

const setUser = () => {

};

const setToken = () => {

};


/** const getUserRole = () => {
  const user = localStorage.getItem(JWT.LOCAL_STORAGE.APP_STORAGE.NAME);
  const parsedUser = JSON.parse(user);

  return parsedUser ? parsedUser.roleId : null;
}; */

const getToken = () => {

};

/** ******************************* User Role From Server *************************************** */

const getUserRole = async () => apolloClient
  .query({
    query: GET_ROLE_LIST,
    variables: {
      ...DEFAULT_QUERY_VARIABLES,
    },
  })
  // .then(res => res.data.roles.rows || [])
  .then((res) => {
    const rolesList = res.data.roles.rows || [];

    const modifiedRoleList = rolesList.map(
      role => ({
        id: role.id,
        title: role.name,
      }),
    );

    return modifiedRoleList;
  })
  .catch(err => errorProcessor(err));

/** ******************************* End of User List  *************************************** */

export {
  setUser,
  setToken,
  getToken,
  getUser,
  getUserRole,
  getUserListFromServer as getUserList,
  setUserList,
  fetchAndSetUserList,
  getUserGroupFromServer as getUserGroup,
  fetchAndSetUserGroup,
  getUserListFromServer,
  getUserGroupFromServer,
  getUserBillingCredentials,
};
