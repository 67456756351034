import PropTypes from 'prop-types';
import React, { Fragment, Component } from 'react';
import SubDReplenishmentView from './View';
import PageHeader from '../../base/PageHeader';
import { isError } from '../../common/HelperFunctions';
import { PanelStyled } from '../../common/configuration';
import * as queryService from '../../base/query.service';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { getOffsetFromPagination } from '../../../utils/api';
import { breadCrumbConfig, formConfig } from './config';
import { subdStockReplenishment } from '../../common/DomainConfig';
import { DialogFormWrapper } from '../../common';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { fileParser } from '../../../utils/parser';
import { UPLOADABLES } from '../../../data/enums/GraphQL';
import DialogEntities from './page-components/DialogEntities';
import PageHeaderButtons from './page-components/PageHeaderButtons';
import UploadSection from './page-components/UploadSection';
import SubDRepStyled from './SubDRepStyled';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  getReplenishmentReport: PropTypes.func.isRequired,
  getDistributors: PropTypes.func.isRequired,
  saveOrderReport: PropTypes.func.isRequired,
  shareOrderReport: PropTypes.func.isRequired,
  upload: PropTypes.func.isRequired,
  downloadReport: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class SubDReplenishment extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      display: {
        searchBox: false,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
      },
      distributorList: [],
      distributor: {
        id: '',
        title: '',
      },
      dialog: {
        type: '',
        element: '',
      },
      fileName: '',
      showPaginatedList: false,
      disableGenerateButton: true,
      users: [],
      userIds: [],
    };

    const { downloadReport, displayAlert } = props;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.getReplenishmentReport,
      downloadReport,
      displayAlert,
    );

    this.setData = (data) => {
      this.setState({ data });
    };

    this.getData = () => {
      const { data } = this.state;
      return data;
    };
  }

  componentDidMount() {
    this.loadTableData();
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  loadTableData = () => {
    const { getDistributors, getUsers } = this.props;
    getDistributors({ limit: 1000 }, {
      handleSuccess: (response) => {
        this.setState({ distributorList: response.data.distributors.rows });
      },
      handleError: (error) => {
        this.onAPIRequestFailure(error);
      },
    });
    getUsers({
      limit: 1000,
      filter: {
        filters: [{
          column: 'role_id',
          value: ['5', '6'],
        }],
      },
    }, {
      handleSuccess: (response) => {
        this.setState({ users: response.data.users.rows });
      },
      handleError: (error) => {
        this.onAPIRequestFailure(error);
      },
    });
  }

  getReplenishmentReport = () => {
    this.setState({
      showPaginatedList: true,
    });
    const {
      data, queryParameters, distributor,
    } = this.state;
    const { getReplenishmentReport } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);

    getReplenishmentReport(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: [{
            column: 'distributor_id',
            value: [distributor.id.toString()],
          }],
        },
      },
      {
        handleSuccess: (response) => {
          const { getStockReplenishmentReport } = response.data;
          data.list = (getStockReplenishmentReport
            && getStockReplenishmentReport.rows) || [];
          data.total = (getStockReplenishmentReport
            && getStockReplenishmentReport.count) || 0;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState({
            data,
            disableGenerateButton: true,
          });
        },
        handleError: (error) => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };

  handleDownloadClick = (reportType, domain) => {
    const { distributor: { id } } = this.state;

    const filter = {
      filters: [
        {
          column: 'distributor_id',
          value: [id.toString()],
        },
      ],
    };

    this.basePaginationService.handleDownloadClickWithCustomFilter('csv', domain, filter);
  };


  setQueryParameters = (queryParams, callBack = () => null) => this.setState(
    { queryParameters: queryParams },
    callBack,
  );

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  getFileData = async (data) => {
    const fileData = await fileParser(data.file)
      .then(file => ({
        input: {
          file,
          type: UPLOADABLES.REPLENISHMENT,
        },
      }));
    return fileData;
  };

  onFormSubmit = async (Operation, data) => {
    const { upload, displayAlert } = this.props;
    const { file: { name } } = data;

    const inputData = await this.getFileData(data);
    upload({
      ...inputData,
    }, {
      handleSuccess: (reponse) => {
        this.setState({
          fileName: name,
          disableGenerateButton: false,
        });
        displayAlert(ALERT_TYPE.SUCCESS, 'File Uploaded Successfully');
      },
      handleError: (err) => {
        this.onAPIRequestFailure(err);
        this.setState({
          fileName: '',
        });
      },
    });
  };

  setStateDialog = (type, element) => {
    this.setState({
      dialog: {
        type,
        element,
      },
    });
  }

  updateDialog = () => {
    this.setStateDialog(EVENT_OPERATION.UPLOAD, '');
  };

  resetDialog = () => {
    this.setStateDialog('', '');
  };

  saveButtonClick = () => {
    this.setStateDialog(EVENT_OPERATION.SAVE, '');
  };

  shareButtonClick = () => {
    this.setStateDialog(EVENT_OPERATION.SHARE, '');
  };

  handleDistributorSelect = (event) => {
    this.setState({
      distributor: {
        id: event.id,
        title: event.title,
      },
    });
  };

  handleUserChange = (event) => {
    this.setState({
      userIds: event.map(d => parseInt(d.id, 10)),
    });
  }

  regenerateReport = () => {
    this.setState({
      showPaginatedList: false,
      fileName: '',
      disableGenerateButton: true,
      distributor: {
        id: '',
        title: '',
      },
    });
  }

  onShareReport = () => {
    const { shareOrderReport, displayAlert } = this.props;
    const { distributor, userIds } = this.state;

    if (userIds.length > 0) {
      shareOrderReport({
        userIds,
        distributorIds: distributor.id,
      },
      {
        handleSuccess: (response) => {
          displayAlert(ALERT_TYPE.SUCCESS, response.data.shareOrderReport);
          this.setState({
            userIds: [],
          });
        },
        handleError: (err) => {
          this.onAPIRequestFailure(err);
        },
      });
    }
  }

  onSaveReport = () => {
    const { saveOrderReport, displayAlert } = this.props;

    const { id } = this.state.distributor;

    saveOrderReport({
      distributorIds: [parseInt(id, 10)],
    },
    {
      handleSuccess: (response) => {
        displayAlert(ALERT_TYPE.SUCCESS, response.data.saveOrderReport);
      },
      handleError: (err) => {
        this.onAPIRequestFailure(err);
      },
    });
  }

  submitFunction = (type) => {
    switch (type) {
      case EVENT_OPERATION.SHARE:
        return this.onShareReport;
      case EVENT_OPERATION.SAVE:
        return this.onSaveReport;
      default:
        return this.onFormSubmit;
    }
  }

  render() {
    const {
      data, queryParameters, display, distributorList, distributor,
      dialog, fileName, showPaginatedList, disableGenerateButton, users, userIds,
    } = this.state;
    const { element, type } = dialog;
    const { serverResponseWaiting } = this.props;
    return (
      <SubDRepStyled>
        {type && (
          <DialogFormWrapper
            onDialogSubmit={this.submitFunction(type)}
            formConfig={formConfig[type]}
            dialogElement={element}
            onDialogCancel={this.resetDialog}
            activeStatus
            type={type}
            renderDialog={
              ({
                dialogData, enableErrorDisplay, handleFileUpload, refsObj,
              }) => (
                <DialogEntities
                  type={type}
                  dialogData={dialogData}
                  serverResponseWaiting={serverResponseWaiting}
                  handleFileUpload={handleFileUpload}
                  enableErrorDisplay={enableErrorDisplay}
                  refsObj={refsObj}
                  userList={users}
                  userIds={userIds}
                  onUserSelect={this.handleUserChange}
                />
              )}
          />
        )}
        <Fragment>
          <div className="section-header">
            <PanelStyled>
              <PageHeader
                display={display}
                breadCrumb={breadCrumbConfig}
                config={{
                  title: subdStockReplenishment.title,
                  create: false,
                  download: false,
                  filter: false,
                  search: false,
                  date: false,
                }}
              >
                <PageHeaderButtons
                  getReplenishmentReport={this.getReplenishmentReport}
                  serverResponseWaiting={serverResponseWaiting}
                  showPaginatedList={showPaginatedList}
                  regenerateReport={this.regenerateReport}
                  disableGenerateButton={disableGenerateButton}
                />
              </PageHeader>
            </PanelStyled>
          </div>
          <div className="section-content">
            <UploadSection
              distributorList={distributorList}
              distributor={distributor}
              showPaginatedList={showPaginatedList}
              updateDialog={this.updateDialog}
              fileName={fileName}
              handleDistributorSelect={this.handleDistributorSelect}
            />
            {
              showPaginatedList && (
                <SubDReplenishmentView
                  data={data}
                  permission={this.permission}
                  pagination={queryParameters.pagination}
                  distributor={distributor}
                  loading={serverResponseWaiting}
                  onPageChange={this.basePaginationService.onPageSelect}
                  saveButtonClick={this.saveButtonClick}
                  shareButtonClick={this.shareButtonClick}
                  onHandleClick={this.handleDownloadClick}
                />
              )
            }
          </div>
        </Fragment>
      </SubDRepStyled>
    );
  }
}

SubDReplenishment.propTypes = propTypes;

SubDReplenishment.defaultProps = defaultProps;

export default withAlert()(SubDReplenishment);
