import { compose } from 'recompose';
import Received from './Received';
import { GET_PROMOTION_VALIDATION } from '../API';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';

const composedReceived = compose(withRequestTracker({
  query: { getPromotionValidation: GET_PROMOTION_VALIDATION },
}))(Received);

export default composedReceived;
