import styled from 'styled-components';

const PageHeaderStyled = styled.div`
 .filter-wrap {
  svg {
    cursor: pointer;
  }
 }
 .icon-wrapper {
    display: inline-block;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border: 1px solid  #CDCED9;
    cursor: pointer;
    font-size: 16px;
    line-height: 22px;
    border-radius: 4px;
    outline: none;
    margin-right: 16px;
    padding: 4px 7px;
    background-color: #FFFFFF;
    color: #6B6C7E;
    .icon{
      position:relative;
      top:-2px;
      cursor: pointer;
    }
      .search{
    border:0;
    background-color:transparent; 
      }
  }
`;

export default PageHeaderStyled;
