import gql from 'graphql-tag';
import { GET_TERRITORIES } from '../../../../common/GqlAPI';
import { graphql } from 'react-apollo';

const GET_DISTRIBUTOR_GROUP_COUNT = gql`
query getDistributorGroupCount($distributorGroupId: Int!){
    getCountForDistributorDetail(distributorGroupId: $distributorGroupId) {
        id:territoryId
        count
    }
}
`;

const GET_DISTRIBUTOR_GROUP_DETAIL = gql`
    query getDistributorGroupDetail($distributorGroupId: Int!, $territoryId: Int!) {
        getDistributorGroupDetails(distributorGroupId: $distributorGroupId, territoryId: $territoryId) {
            territoryId
            distributorId
            title
            active
            used
        }
    }
`;

const TOGGLE_SUBD_IN_SUBD_GROUP = graphql(gql`
    mutation ($distributorGroupId: Int!, $territoryId: Int!, 
        $distributorIds: [Int!],  $status: Boolean!) {
        toggleStateForDistributorGroup(distributorGroupId: $distributorGroupId, territoryId: $territoryId,
            distributorIds: $distributorIds,  status: $status
        ){
            id:territoryId
            count
        }
    }`, {
    props: ({ mutate }) => ({
        toggleStateForDistributorGroup: variables => mutate({
            variables,
        }),
    }),
});


const query = {
  getTerritoryList: GET_TERRITORIES,
  getDistributorGroupDetails: GET_DISTRIBUTOR_GROUP_DETAIL,
  getDistributorGroupCount: GET_DISTRIBUTOR_GROUP_COUNT,
};
const mutation = {
    toggleStateForDistributorGroup: TOGGLE_SUBD_IN_SUBD_GROUP
};

export { query, mutation, TOGGLE_SUBD_IN_SUBD_GROUP};
