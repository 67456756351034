import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, CustomSelect } from '../../../../components';
import FormContentStyled from '../../../../components/global-css/FormContentStyled';

const propTypes = {
  fileName: PropTypes.string,
  handleDistributorSelect: PropTypes.func.isRequired,
  updateDialog: PropTypes.func.isRequired,
  showPaginatedList: PropTypes.bool.isRequired,
  distributorList: PropTypes.arrayOf(PropTypes.object).isRequired,
  distributor: PropTypes.object,
};

const defaultProps = {
  fileName: '',
};

const UploadSection = (props) => {
  const {
    distributorList, distributor, showPaginatedList, updateDialog,
    fileName, handleDistributorSelect,
  } = props;
  return (
    <Fragment>
      {
        !showPaginatedList && (
          <div className="upload-section">
            <FormContentStyled>
              <div className="form-input-wrap">
                <CustomSelect
                  enableValidation
                  options={distributorList}
                  className="custom-select"
                  labelContent="Sub D"
                  placeholder="Select Distributor"
                  getOptionValue={({ id }) => id}
                  getOptionLabel={({ title }) => title}
                  onChange={(event) => {
                    handleDistributorSelect(event);
                  }}
                  value={distributorList.filter(({ id }) => id === distributor.id) || []}
                />
                <div className="form-input">
                  <label>SIT & PSO</label>
                  <div className="upload-input">
                    <Button secondary iconName="upload" onClick={() => updateDialog()} disabled={distributor.id === ''}>
                  Upload
                    </Button>
                    <div className="no-file">{fileName ? <span>{fileName}</span> : 'No file Selected'}</div>
                  </div>
                </div>
              </div>
            </FormContentStyled>
          </div>

        )
      }
    </Fragment>
  );
};
UploadSection.propTypes = propTypes;

UploadSection.defaultProps = defaultProps;

export default UploadSection;
