import styled from 'styled-components';
const LegendStyled = styled.div`
 svg {
     g{
         text{
             text-transform:uppercase;
             fill:#6B6C7E;
             &:last-child{
                 fill:#272833;
             }
         }
     }
 }
    
    
`;
export default LegendStyled;
