import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { GET_TERRITORIES } from '../../../../../common/GqlAPI';

const GET_DISTRIBUTOR_GROUP_COUNT = gql`
query getCountForReplenishmentDistributor($stockGroupId: Int!){
  getCountForReplenishmentDistributor(stockGroupId: $stockGroupId) {
        id:territoryId
        count
    }
}
`;

const GET_REPLENISHMENT_GROUP_DETAIL = gql`
    query getReplenishmentGroupDetail($id: Int!, $territoryId: Int) {
        getReplenishmentGroupDetail(id: $id, territoryId: $territoryId) {
            distributorDetails {
              title
              distributorId
              territoryId
              replenishmentGroupId
              active
              used
            }
        }
    }
`;

const TOGGLE_SUBD_IN_SUBD_GROUP = graphql(gql`
    mutation ($stockGroupId: Int!, $territoryId: Int!, 
        $distributorIds: [Int!],  $status: Boolean!) {
          toggleStateForReplenishmentGroup(stockGroupId: $stockGroupId, territoryId: $territoryId,
            distributorIds: $distributorIds,  status: $status
        ){
            id:territoryId
            count
        }
    }`, {
  props: ({ mutate }) => ({
    toggleStateForReplenishmentGroup: variables => mutate({
      variables,
    }),
  }),
});


const query = {
  getTerritoryList: GET_TERRITORIES,
  getReplenishmentGroupDetail: GET_REPLENISHMENT_GROUP_DETAIL,
  getCountForReplenishmentDistributor: GET_DISTRIBUTOR_GROUP_COUNT,
};
const mutation = {
  toggleStateForReplenishmentGroup: TOGGLE_SUBD_IN_SUBD_GROUP,
};

export { query, mutation, TOGGLE_SUBD_IN_SUBD_GROUP };
