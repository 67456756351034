import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CheckBox } from '../../../components';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import {
  menuAction,
  tableHeaderWithCheckBox,
} from '../../../utils/paginatedListUtils';
import { PERMISSION_OBJ } from '../../../data/enums/Permission';

const labelMappings = {
  checkbox: 'checkbox',
  id: 'id',
  title: 'title',
  town: 'Towns',
  address: 'address',
  phoneNumber: 'phoneNumber',
  owner: 'owner',
  actionHolders: 'actionHolders',
};

const menuConfigList = [
  {
    title: 'Delete',
    icon: 'trash',
    permissionDerivedBy: 'delete',
    type: EVENT_OPERATION.DELETE,
  },
];

const getTownTitleList = (townList = []) => {
  let townTitles = '';

  townList.forEach((town, index) => {
    townTitles = index === 0 ? `${town.title}` : `${townTitles}, ${town.title}`;
  });

  return townTitles;
};

const returnTableBody = (
  data,
  checkedList,
  onIconClick,
  onSecondaryCheckBoxClick,
  permission
) => {
  const table = {};
  table[labelMappings.checkbox] = permission.delete && (
    <td onClick={(e) => e.stopPropagation()}>
      <CheckBox
        checked={checkedList.includes(data.id)}
        onCheckBoxClick={() => onSecondaryCheckBoxClick(data.id)}
      />
    </td>
  );
  table[labelMappings.id] = <td>{data.id}</td>;
  table[labelMappings.title] = <td>{data.title}</td>;
  table[labelMappings.town] = <td>{getTownTitleList(data.Towns)}</td>;
  table[labelMappings.address] = <td>{data.address}</td>;
  table[labelMappings.phoneNumber] = <td>{data.phoneNumber}</td>;
  table[labelMappings.owner] = <td>{data.owner}</td>;
  table[labelMappings.actionHolders] = (
    <td
      className='text-right simple-popup-actions'
      onClick={(e) => e.stopPropagation()}
    >
      {menuAction(
        menuConfigList,
        { menuIcon: 'ellipsis-v' },
        onIconClick,
        data,
        permission
      )}
    </td>
  );

  return table;
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.checkbox,
    title: '',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.id,
    title: 'ID',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.title,
    title: 'Title',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.town,
    title: 'Town',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.address,
    title: 'Address',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.phoneNumber,
    title: 'Phone',
    show: true,
  },
  {
    id: 7,
    label: labelMappings.owner,
    title: 'Owner',
    show: true,
  },
  {
    id: 8,
    label: labelMappings.actionHolders,
    title: '',
    show: true,
  },
];

const TableBody = ({
  data,
  checkedList,
  onIconClick,
  secondaryCheckboxHandler,
  permission,
  onViewClick,
}) => {
  // Expanding/Dragging and collapsing table body.
  // color based upon table body
  // tooltip if it is sorted.
  // active className
  const tableBody = returnTableBody(
    data,
    checkedList,
    onIconClick,
    secondaryCheckboxHandler,
    permission
  );

  return (
    <tr
      key={data.id}
      style={{ cursor: 'pointer' }}
      onClick={() => onViewClick(EVENT_OPERATION.UPDATE, data.id)}
    >
      <Link to={`sub-d/details/${data.id}`}>
        {labelConfig.map((label) =>
          label.show ? tableBody[label.label] : null
        )}
      </Link>
    </tr>
  );
};

TableBody.propTypes = {
  checkedList: PropTypes.instanceOf(Array),
  data: PropTypes.instanceOf(Object),
  onIconClick: PropTypes.func.isRequired,
  onViewClick: PropTypes.func.isRequired,
  permission: PropTypes.instanceOf(Object),
  secondaryCheckboxHandler: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
  checkedList: [],
  permission: PERMISSION_OBJ,
};

const TableHeader = ({
  dataListLength,
  checkedListLength,
  primaryCheckboxHandler,
  permission,
}) =>
  tableHeaderWithCheckBox(
    dataListLength,
    checkedListLength,
    primaryCheckboxHandler,
    permission,
    labelConfig,
    labelMappings
  );

TableHeader.propTypes = {
  checkedListLength: PropTypes.number,
  dataListLength: PropTypes.number,
  permission: PropTypes.instanceOf(Object),
  primaryCheckboxHandler: PropTypes.func.isRequired,
};

TableHeader.defaultProps = {
  checkedListLength: 0,
  dataListLength: 0,
  permission: PERMISSION_OBJ,
};

export { TableHeader, TableBody };
