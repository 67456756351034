import {
  compose,
} from 'recompose';
import Role from './Role';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';
import {
  query, mutation, CREATE_ROLE, UPDATE_ROLE,
} from './API';
import { TOGGLE_STATE } from '../../../common/GqlAPI';

const ComposedRole = compose(
  CREATE_ROLE,
  UPDATE_ROLE,
  TOGGLE_STATE,
  withRequestTracker({
    query,
    mutation,
  }),
)(Role);

export default ComposedRole;
