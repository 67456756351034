import React from 'react';
import PropTypes from 'prop-types';
import { dataProps } from '../../../data/enums/Component';
import ClassficationWrapper from './ClassificationWrapper';
import Card from '../../components/Card';
import withErrorAndLoadingScreen from '../../../utils/composition/withLoading';

const propTypes = {
  data: dataProps.value,
  channel: dataProps.value,
  category: dataProps.value,
  onItemClick: PropTypes.func.isRequired,
  handleSearchInput: PropTypes.func.isRequired,
  handleSearchCategory: PropTypes.func.isRequired,
  handleIconClick: PropTypes.func.isRequired,
  onChannelClick: PropTypes.func.isRequired,
  handleSearchChannel: PropTypes.func.isRequired,
  tradeId: PropTypes.string,
  channelId: PropTypes.string,
  cardTitle: PropTypes.string,
};

const defaultProps = {
  data: dataProps.defaultValue,
  channel: dataProps.defaultValue,
  category: dataProps.defaultValue,
  tradeId: '',
  channelId: '',
  cardTitle: '',
};

const View = ({ ...props }) => {
  const {
    data, onItemClick, channel, handleSearchInput, handleIconClick, tradeId, onChannelClick, channelId, category, cardTitle,
  } = props;

  return (
    <div className="create-ui">
      <ClassficationWrapper>
        <div className="create-ui-inner tab-less-height">
          <Card
            data={data.list}
            onItemClick={onItemClick}
            handleSearchInput={handleSearchInput}
            title="Trade"
            selectedId={tradeId}
            cardTitle={cardTitle}
            handleIconClick={handleIconClick}
          />
          { tradeId && (
            <Card
              data={channel.list}
              onItemClick={onChannelClick}
              handleSearchInput={handleSearchInput}
              selectedId={channelId}
              title="Channel"
              handleIconClick={handleIconClick}
            />
          )}
          { channelId && (
            <Card
              data={category.list}
              handleIconClick={handleIconClick}
              handleSearchInput={handleSearchInput}
              selectedId={channelId}
              title="Category"
            />
          )}
        </div>
      </ClassficationWrapper>


    </div>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const ClassificationViewWithErrorAndLoading = withErrorAndLoadingScreen(View);

export default ClassificationViewWithErrorAndLoading;
