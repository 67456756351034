import styled from 'styled-components';

const CardStyled = styled.div`

.card-pannel {
    margin-right: 8px;
    background: #fff;
    border-radius: 4px;
    width: 215px;
    height: calc(100vh - 216px);
    border: 1px solid #E7E7ED;
    box-shadow:0 4px 8px 0 rgba(39,40,51,0.12);

      .title-section{
           text-transform: uppercase;
           padding: 24px;
            height:58px;
            border-bottom: 1px solid #E7E7ED;
            font-size: 10px;
            line-height: 10px;
            color: #6B6C7E;
            font-weight: normal;
            display: flex;
            justify-content: space-between;
                svg{
                    height: 12px;
                    width: 12px;
                }
        }

      .body-section{
          padding: 12px ;
          overflow-x: hidden;
          overflow-y: auto;
          max-height: 360px;
          min-height: 360px;
      .list-section{
        margin-top:16px;
          li{
            display:flex;
            cursor: pointer;
            margin-bottom:12px;
            height: 32px;
            color:#6B6C7E;
            font-size: 12px;
            font-weight: normal;
            line-height: 15px;
            padding: 8px 0px;
              &.active {
            background: #F7F8F9;
            border-radius: 4px;
            color: #272833;
            position: relative;
            span{
              min-width: 165px;
            }
           .filter-item{
                margin-right: 6px;
            button {
                svg {
                  background:#F7F8F9;
                }
            }
          }

            &:after {
              content: '';
              position: absolute;
              top: -18px;
              right: 12px;
              display: block;
              margin: 30px auto;
              width: 8px;
              height: 8px;
              border-top: 2px solid #0b5fff;
              border-left: 2px solid #0b5fff;
              transform: rotate(135deg);
              border-radius: 2px 2px 0 2px;
            }
          }
          &.inActive{
              span{
              min-width: 165px;
            }
          }
          .filter-item{
                margin-right: 6px;
            button {
                line-height: 12px;
                padding:0px;
                svg {
                  width: 12px;
                  height: 16px;
                }
            }
          }
          .filter-dropdown-card {
            min-width: 96px;
            left: -7px;
            height: 136px;
            .filter-content {
              padding: 18px 0px;

            li {
                margin-bottom: 0px;
                font-size: 14px;
                line-height: 21px;
                color: #272833;
                padding-left: 16px;
                svg { 
                  width: 0px;
                  height: 0px;
                  }
                  &:hover{
                    background-color: #DEEBFF;
                    color:rgb(39, 40, 51);
                }
            }
          }
          }
          }
          }
         }
}

`;

export default CardStyled;
