const REQUEST = {
  MUTATION: 'Mutation',
  QUERY: 'Query',
};

const getEnumFromObject = object => Object.freeze({ ...object });
const SCHEMAS = getEnumFromObject({
  SRNLineAmount: 'SRNLineAmount',
  SRNLines: 'SRNLines',
});

const UPLOADABLES = getEnumFromObject({
  SKU: 'SKU',
  GRN: 'GRN',
  TARGET: 'TARGET',
  SUBD: 'SUBD',
  OUTLET: 'OUTLET',
  LEDGER: 'LEDGER',
  BRAND_TARGET: 'BRANDTARGET',
  OPENING_BALANCE: 'OPENINGBALANCE',
  REPLENISHMENT: 'PENDINGGRN',
});

const STATEFUL_ENTITIES = {
  USER: 'User',
  ROUTE: 'Route',
  CHANNEL: 'Channel',
  CATEGORY: 'Category',
  RETAIL_OUTLET: 'RetailOutlet',
  FOCUSED_SKU: 'FocusedSKU',
  SBD: 'SBD',
  MERCHANDISE_DEVICE: 'MerchandiseDevice',
  SKU: 'SKU',
  SKU_BATCH_RATE: 'SKUBatchRate',
  PROMOTION: 'Promotion',
  TOWN: 'Town',
  TERRITORY: 'Territory',
  Distributor: 'Distributor',
  SUB_D_ASSORTMENT: 'DistributorGroup',
  REPLENISHMENT: 'StockGroup',
  CATALOG_DETAILS: 'CatalogDetail',
  CASH: 'Cash',
  TRADE: 'Trade',
};

const DOWNLOAD_DOMAIN = {
  SALES: 'sales',
  SALES_MV: 'materialized_view',
  PURCHASE: 'purchase',
  SALES_RETURN: 'sales_return',
  PURCHASE_RETURN: 'purchase_return',
  JOURNAL_VOUCHER: 'journal_voucher',
  VAT_LEDGER: 'vat_ledger',
  STOCK_LEDGER: 'STOCK_LEDGER',
  STOCK_JOURNAL: 'stock_journal',
  SALES_VAT: 'sales_vat',
  PURCHASE_VAT: 'purchase_vat',
  VAT_REPORT: 'vat_report',
  PURCHASE_RETURN_VAT: 'purchase_return_vat',
  NET_SALES_VAT: 'net_sales_vat',
  NET_PURCHASE_VAT: 'net_purchase_vat',
  SRN_VAT: 'srn_vat',
  LEDGER: 'ledger_report',
  AGEING_REPORT: 'ageing_report',
  PAYMENT: 'payment',
  DATABASE: 'database',
  OUTLET: 'retail_outlet',
  SUB_D_ASSORTMENT: 'Distributor Group',
  USER_TARGET_SAMPLE: 'USER_TARGET_SAMPLE',
  USER_BRAND_TARGET_SAMPLE: 'USER_BRAND_TARGET_SAMPLE',
  DISTRIBUTOR_TARGET_SAMPLE: 'DISTRIBUTOR_TARGET_SAMPLE',
  DISTRIBUTOR_BRAND_TARGET_SAMPLE: 'DISTRIBUTOR_BRAND_TARGET_SAMPLE',
  RECEIPT: 'Receipt',
  CHEQUES: 'PAYMENT_CHEQUE',
  POST_DATED_CHEQUE: 'PAYMENT_POST_DATED_CHEQUE',
  CASH: 'Cash',
  OUTSTANDING_REPORT: 'OUTSTANDING_REPORT',
  SALES_RETURN_REPORT: 'SALES_RETURN_REPORT',
};

const TYPENAME = '__typename';

const PROMOTION_TYPES = {
  NORMAL: 'NORMAL',
  CURRENT_BILL: 'CURRENT_BILL',
  TOP_UP: 'TOP_UP',
  NEXT_BILL: 'NEXT_BILL',
  DIRECT_REIMBURSEMENT: 'DIRECT_REIMBURSEMENT',
};

const PROMOTION_SCOPE = {
  SINGLE: 'SINGLE',
  GROUP: 'GROUP',
};

const IMAGE_UPLOAD_DOMAIN = {
  SKU: 'skus',
  CATALOGS: 'catalogs',
  RECEIPTS: 'receipts',
  PROFILE: 'profile',
};

const COVERAGE_TYPE = {
  CHANNEL: 'CHANNEL',
  CATEGORY: 'CATEGORY',
  SUB_D: 'DISTRIBUTOR',
  ROUTE: 'ROUTE',
  BRAND: 'BRAND',
};

const ORDER_HISTORY_VIEW = {
  BRAND: 'BRAND',
  DATE: 'DATE',
};

export {
  REQUEST,
  SCHEMAS,
  STATEFUL_ENTITIES,
  UPLOADABLES,
  PROMOTION_TYPES,
  PROMOTION_SCOPE,
  TYPENAME,
  DOWNLOAD_DOMAIN,
  IMAGE_UPLOAD_DOMAIN,
  COVERAGE_TYPE,
  ORDER_HISTORY_VIEW,
};
