import {
  compose,
} from 'recompose';
import Dispatched from './Dispatched';
import { DELIVER_INVOICE } from '../API';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';

const ComposedDispatched = compose(
  DELIVER_INVOICE,
  withRequestTracker({
    query: {},
    mutation: { deliverInvoice: DELIVER_INVOICE },
  }),
)(Dispatched);

export default ComposedDispatched;
