
import styled from 'styled-components';

const AssortmentPanelStyled = styled.div`
  .panel-section{
    display:flex;
    .panel{
      background-color:#FFFFFF;
      border-radius: 4px;
        .p-title{
          margin:0;
          padding:24px ;
          font-size:10px;
          line-height:10px;
          color:#6B6C7E;
          text-transform:uppercase;
        }
        ul{
          height: calc(100vh - 285px);
          overflow-y: auto;
          padding: 0 12px;
          li{
            padding:9px 12px;
            font-size:12px;
            line-height:15px;
            font-weight:500;
            color:#6B6C7E;
            display:flex;
            justify-content:space-between;
            align-items:center;
            margin-bottom:12px;
            text-transform:capitalize;
            p{
            margin:0;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 125px;
            }
            .light{
             margin-right:16px;
            }
          
            &.active{
              background-color:#F7F8F9;
              color:#272833;
              border-radius: 4px;
              position:relative;
              &:after {
                  content: '';
                  position: absolute;
                  top: 13px;
                  right: 12px;
                  display: block;
                  width: 12px;
                  height: 12px;
                  border-top: 2px solid #0b5fff;
                  border-left: 2px solid #0b5fff;
                  -webkit-transform: rotate(135deg);
                  -ms-transform: rotate(135deg);
                  transform: rotate(135deg);
                  border-radius: 2px 2px 0 2px;
              }
            }
            &:hover{
              cursor:pointer;
            }
            &.inactive{
              &:hover{
                color: #0b5fff;
              }
            }
          }
        }
      &.two,
      &.one{
        width:215px;
        display:inline-block;
        margin-right:4px;
      }

      /* third panel styling only */
      &.three{
        flex:1;
        .panel-title{
          padding:14px 24px;
          display:flex;
          align-items:center;
          justify-content:space-between;
          .p-title{
            margin:0;
            padding:0;
          }
        }
        .div-tbody .collapsible-inner,
        .div-tbody .header-wrap,
        .div-thead{
          border-bottom:1px solid #E7E7ED;
          display:flex;
          padding:12px 24px;
          align-items:center;
          justify-content:space-between;
          background-color:#F1F2F5;
          font-size:12px;
          line-height:15px;
          color:#6B6C7E;
          font-weight:500;
          .status{
            display:flex;
            align-items:center;
            .form-input{
              margin-left:12px;
            }
          }
        }

        .div-tbody{
          height: calc(100vh - 358px);
          overflow-y: auto;
          background-color:#FBFBFC;
          .header{
            .collapsible-inner,
            .header-wrap{
              background-color:#FFFFFF;
              padding:15px 24px;
            }
            .header-wrap{
              cursor:pointer;
              &:hover{
                background: #F0F5FF;
              }
                &>span{
                  position: relative;
                &::before{      
                    content:'';   
                    width: 0; 
                    height: 0; 
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                    border-top: 6px solid #6B6C7E;
                    transform: rotate(0deg);
                    transition: all 0.23s ease-in-out;
                    position: absolute;
                     top: 6px;
                      left: 0;
                  }
                  .text{
                    margin-left:30px;
                  } .badge{
                    margin-left: 8px;
                  }

                }
            }
           
            &.active{
              margin:16px 0;
              transition: all 0.4s ease-out;
              box-shadow: 0 4px 8px 0 rgba(39,40,51,0.12);
                .header-wrap{
                 background-color:#F0F5FF;
                 border-bottom:1px solid #0B5FFF;
                 span::before{
                  transform: rotate(180deg);
                  transition: all 0.23s ease-in-out;
                  border-top:6px solid #0B5FFF;
                  top:5px;
                 }
                }
                .collapsible-inner{
                  &>span{
                    margin-left:30px;
                  }
                  /* &:hover{
                    background: #F0F5FF;
                  } */
                }
            }

            &.inactive{
              margin:0;
              transition: all 0.4s ease-in;
              top:28px;
            }
          }
        }
/* only for table inside third panel */
.table-wrap{
    max-height: unset;
    height: calc(100vh - 286px);
    overflow-y: auto;
    border-radius: 0 0 4px 4px;
        table{
          border:0;
          td,
          th{
            &:first-child{
              padding:7px 12px 7px 24px;
              border-left:0;
            }
            &:last-child{
              padding:7px 24px 7px 12px;
              border-right:0;
            }
          }
          .switch{
            span{
              position:relative;
              top:-7px;
            }
          &>div{
            display:inline-block;
            margin-left:16px;
          }
          }
        }
      }
    }
    }
  }
`;

export default AssortmentPanelStyled;
