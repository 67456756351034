import {
  compose,
} from 'recompose';
import ChangePassword from './ChangePassword';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import { mutation, CHANGE_PASSWORD } from './API';

const ComposeChangePassword = compose(
  CHANGE_PASSWORD,
  withRequestTracker({
    mutation,
  }),
)(ChangePassword);

export default ComposeChangePassword;
