import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import View from './View';
import { ORDER_TABS } from '../config';
import {
  clone, filterIds, filterItems,
} from '../../../../utils/arrayProcessor';
// eslint-disable-next-line import/named
import { crudSuccess as crudRequestConfig, orderFormatter } from './config';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import withAlert from '../../../../utils/composition/withAlert';
import { INSTOCK, PRIMARY } from '../../../../data/enums/GeneralConstants';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { handleFormSubmit } from '../../../../utils/crudResponseProcessor';
import { getFlattenedOrders } from '../../../common/HelperFunctions';

const propTypes = {
  stateSetter: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  handlePassiveTabListChange: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class Invoiced extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      selectedOrders: [],
      activeCall: {
        index: -1,
        value: '',
        sellerId: '',
      },
    };

    const serverCall = {
      [EVENT_OPERATION.DISPATCHED]: props.dispatchInvoice,
      [EVENT_OPERATION.DELIVERED]: props.deliverInvoice,
    };
    this.onFormSubmit = handleFormSubmit(
      this.onServerRequestSuccess,
      this.onAPIRequestFailure,
      crudRequestConfig,
      serverCall,
    );
    this.permission = this.getUserPermission();
  }

  componentDidMount() {
    this.resetActiveCall();
  }

  getUserPermission = () => ({ GRN: {} })
    // todo fetch user permission here
  ;

  resetActiveCall = () => {
    const { activeCall } = this.state;
    activeCall.index = '';
    activeCall.value = '';
    activeCall.sellerId = '';
    this.setState({ activeCall });
  };


  handleRowClick = (callId, sellerId) => {
    const { onCallRowClick, data } = this.props;
    const { activeCall } = this.state;
    if (callId !== activeCall.value) {
      const indexInDataList = data.list.findIndex(
        element => element.Distributor.id === sellerId && element.id === callId,
      );
      activeCall.value = callId;
      activeCall.index = indexInDataList;
      this.setState({ activeCall });

      onCallRowClick(callId, sellerId);
    }
  };

  getCheckboxStatus = (type, orderId) => {
    const { selectedOrders } = this.state;
    if (type === PRIMARY) {
      const validChildren = this.getValidOrderId().length;
      return (selectedOrders.length === validChildren) && validChildren > 0;
    }

    return (selectedOrders.indexOf(orderId) > -1);
  };

  handlePrimaryCheckBoxClick = (event) => {
    let selectedOrdersList = [];
    if (event.currentTarget.checked) {
      selectedOrdersList = this.getValidOrderId();
    } else {
      selectedOrdersList = [];
    }
    this.setState({ selectedOrders: selectedOrdersList });
  };

  handleSecondaryCheckboxClick = (orderId) => {
    const { selectedOrders } = this.state;
    const index = selectedOrders.indexOf(orderId);
    if (index > -1) {
      selectedOrders.splice(index, 1);
    } else {
      selectedOrders.push(orderId);
    }

    this.setState(selectedOrders);
  };

  getValidOrderId = () => {
    const { data, activeLine } = this.props;
    const index = data.list.findIndex(item => (
      item.id === activeLine.id && item.Distributor.id === activeLine.sellerId));
    const activeOrder = data.list[index] || {};
    const { Orders = [] } = activeOrder;
    const ids = filterIds(Orders, INSTOCK, 'inStock');
    return ids || [];
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
    console.log(error);
  };

  onServerRequestSuccess = type => (response) => {
    const {
      displayAlert, data, stateSetter, handlePassiveTabListChange,
    } = this.props;
    const dataList = clone(data.list);
    displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message);
    const selectedCall = filterItems(dataList, response.id)[0] || {};
    const selectedCallIndex = dataList.findIndex(call => call.id === selectedCall.id);
    dataList.splice(selectedCallIndex, 1);
    data.list = dataList;
    data.total -= 1;
    stateSetter(data, ORDER_TABS.INVOICED);
    handlePassiveTabListChange(response.id, ORDER_TABS[type], 1);
  };

  getActiveLineOrderDetails = () => {
    const { data, activeLine } = this.props;
    const index = data.list.findIndex(item => (
      item.id === activeLine.id && item.Distributor.id === activeLine.sellerId));
    const activeOrder = data.list[index] || {};
    const { Orders = [] } = activeOrder;

    return { Orders, invoiceId: activeOrder.id };
  };


  getDispatchedOrderDetails = () => {
    /** todo: after implementing partial dispatch get flattened  and modified selectedOrders only */
    const flattenedActiveLineOrdersDetails = this.getActiveLineOrderDetails();
    const flattenedActiveLineOrders = getFlattenedOrders(flattenedActiveLineOrdersDetails.Orders);
    const modifiedOrders = flattenedActiveLineOrders.map(order => orderFormatter(order));

    return { modifiedOrders, invoiceId: flattenedActiveLineOrdersDetails.invoiceId };
  };

  handleOrderStateChange = (type) => {
    const { data } = this.props;
    const ordersDetails = this.getDispatchedOrderDetails();

    this.onFormSubmit(type, {
      orderDetails: ordersDetails.modifiedOrders,
      invoiceId: ordersDetails.invoiceId,
    });
  };

  handlePageChange = (pagination) => {
    const { onPageChange } = this.props;
    onPageChange(ORDER_TABS.INVOICED, pagination);
  };

  render() {
    const {
      selectedOrders,
    } = this.state;
    const {
      data,
      activeLine,
      displayAlert,
      onPageChange,
      onHeaderClick,
      serverResponseWaiting,
    } = this.props;

    return (
      <Fragment>
        <View
          data={data}
          activeLine={activeLine}
          onPageChange={this.handlePageChange}
          selectedOrders={selectedOrders}
          loading={serverResponseWaiting}
          onRowClick={this.handleRowClick}
          onOrderStatusChangeClick={this.handleOrderStateChange}
          checkBoxStatus={this.getCheckboxStatus}
          onPrimaryCheckBoxClick={this.handlePrimaryCheckBoxClick}
          onSecondaryCheckBoxClick={this.handleSecondaryCheckboxClick}
          onHeaderClick={onHeaderClick}
        />
      </Fragment>
    );
  }
}

Invoiced.propTypes = propTypes;

Invoiced.defaultProps = defaultProps;

export default withAlert()(Invoiced);
