import { getTodaysDate } from '../../../../../utils/date';
import { FORM_CONFIG } from '../../../../../data/enums/config';
import { refGenerator } from '../../../../../utils/refGenerator';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';

const title = 'GRN';

const formMapper = element => ({
  id: element.id || '',
  skuId: element.skuId || 0,
  skuBatchId: element.skuBatchId || 0,
  expiryDate: element.expiryDate || getTodaysDate(),
  manufactureDate: element.manufactureDate || getTodaysDate(),
  quantity: element.quantity || 0,
  priceDetails: {
    rate: element.priceDetails ? element.priceDetails.rate : 0,
    amount: element.priceDetails ? element.priceDetails.amount : 0,
    discount: element.priceDetails ? element.priceDetails.discount : 0,
    netAmount: element.priceDetails ? element.priceDetails.netAmount : 0,
  },
  damages: element.damages || 0,
  shortages: element.shortages || 0,
  damageImages: element.damageImages || [],
});

const requiredList = [
  'skuId', 'expiryDate', 'manufactureDate', 'quantity',
  'rate', 'discount',
];

const formConfig = {
  [EVENT_OPERATION.CREATE]: {
    [FORM_CONFIG.TITLE]: 'Add SKU',
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    [FORM_CONFIG.MAPPER]: () => formMapper({}),
    [FORM_CONFIG.REFS_OBJ]: refGenerator([...requiredList]),
  },
  [EVENT_OPERATION.UPDATE]: {
    [FORM_CONFIG.TITLE]: 'Update SKU',
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    [FORM_CONFIG.MAPPER]: element => formMapper(element),
    [FORM_CONFIG.REFS_OBJ]: refGenerator([...requiredList]),
  },
  [EVENT_OPERATION.DELETE]: {
    [FORM_CONFIG.TITLE]: 'DELETE',
    [FORM_CONFIG.VALIDATION_REQUIRED]: false,
    [FORM_CONFIG.MAPPER]: (element = {}) => ({ id: element.id || '' }),
  },
};

const totalViewRefs = refGenerator(['billDiscount', 'tradeDiscount']);

const updateRate = (state, stateUpdater, selectedBatch) => {
  state.priceDetails.rate = selectedBatch.priceDetails.dlp;
  stateUpdater(state);
};

const updateDamageImages = (state, stateUpdater, value) => {
  const dummyImageUrl = '/image/rosia-logo.svg';
  /** todo instead of dummyImageUrl add value */
  const image = {
    image: '',
    thumbnail: value,
  };
  state.damageImages.push(image);
  stateUpdater(state);
};

const removeDamageImage = (getState, uploadState, item) => {
  const dialogState = getState();
  const updatedDamageImages = dialogState.damageImages.filter(image => image.thumbnail !== item.thumbnail);
  dialogState.damageImages = [...updatedDamageImages];
  uploadState(dialogState);
};

const updateSkuLine = (element, sku, skuBatch) => ({
  ...element,
  priceDetails: {
    ...element.priceDetails,
    amount: element.quantity * element.priceDetails.rate,
    netAmount: element.quantity * element.priceDetails.rate - element.priceDetails.discount,
  },
  SKU: {
    id: sku.id,
    title: sku.title,
  },
  SkuBatch: {
    id: skuBatch.id,
    batchDetails: { batchNumber: skuBatch.batchDetails.batchNumber },
    usageDate: {
      manufactureDate: element.manufactureDate,
      expiryDate: element.expiryDate,
    },
  },
});

export {
  formConfig,
  formMapper as listMapper,
  title,
  updateRate,
  totalViewRefs,
  updateSkuLine,
  updateDamageImages,
  removeDamageImage,
};
