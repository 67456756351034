import styled from 'styled-components';


const DonutStyled = styled.div`
svg{
    g{
        text{
            text-transform:uppercase;
            fill:#272833;
            &:last-child{
                fill:#6B6C7E;
            }
         
        }
    }
}
`;

export default DonutStyled;
