import gql from 'graphql-tag';

const REPLENISHMENT_GROUP_FRAGMENT = gql`
fragment ReplenishmentGroupFragment on StockGroup  {
    id
    title
    active
}`;

const GET_REPLENISHMENT_GROUPS = gql`
    query getReplenishmentGroups($offset: Int, $limit: Int, $filter: FilterInput) {
        replenishmentGroups(offset: $offset, limit: $limit, filter: $filter) {
           rows {
               ...ReplenishmentGroupFragment
           }
            count
        }
    }
    ${REPLENISHMENT_GROUP_FRAGMENT}
`;

const query = {
  getReplenishmentGroupsList: GET_REPLENISHMENT_GROUPS,
};
const mutation = {

};

export { query, mutation, GET_REPLENISHMENT_GROUPS };
