import { reports as reportsRoute } from '../../data/enums/Route';

const breadCrumb = [
  reportsRoute,
];

const status = {
  0: 'INACTIVE',
  1: 'ACTIVE',
  2: 'ON LEAVE',
};

export {
  breadCrumb as breadCrumbConfig,
  status,
};
