const getEnumFromObject = object => Object.freeze({ ...object });

const UPLOADABLES = getEnumFromObject({
  USER_TARGET: 'TARGET',
  USER_BRAND_TARGET: 'BRANDTARGET',
  SUB_D_TARGET: 'SUBDTARGET',
  SUB_D_BRAND_TARGET: 'SUBDBRANDTARGET',
  OPENING_BALANCE: 'OPENINGBALANCE',
});


export { UPLOADABLES };
