import styled from 'styled-components';

const GRNDetailTable = styled.div`
.table-wrap{
 &>table {
  tbody,thead{
  td,th{
    width:auto;
    white-space:nowrap;
    &:first-child{
    white-space:unset;
    }
  }
  }
  }
}

`;

export default GRNDetailTable;
