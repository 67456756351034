import React from 'react';
import PropTypes from 'prop-types';
import TableHeader from '../../../base/TableHeader';
import { fixedFloatAndCommas } from '../../../../utils/conversion';
import { CALLAGE } from '../../../../data/enums/Dashboard';
import { ImageColumn } from '../../../../utils/tableUtils';

const labelMappings = {
  id: 'id',
  outlet: 'outlet',
  route_sequence: 'route_sequence',
  category: 'category',
  target: 'target',
  received: 'received',
  reason: 'reason',
  sync_time: 'sync_time',
  callImage: 'callImage',
  // golden_points: 'golden_points',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.outlet,
    title: 'Outlet',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.route_sequence,
    className: 'text-center',
    title: 'Sequence',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.category,
    title: 'Category',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.target,
    className: 'text-right',
    title: 'PFD Target',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.received,
    className: 'text-right',
    title: 'Received',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.reason,
    title: 'Reason',
    show: true,
  },
  {
    id: 7,
    label: labelMappings.sync_time,
    title: 'Order Time',
    show: true,
  },
  {
    id: 8,
    label: labelMappings.callImage,
    title: 'Call Image',
    show: true,
  },
];


const returnTableBody = (data, handleSliderOpen) => {
  const table = {};
  const checkForStatus = {
    [CALLAGE.successful]: 'border-l-g',
    [CALLAGE.unsuccessful]: 'border-l-r',
    notperformed: 'border-l-t',
  };
  table[labelMappings.outlet] = (
    <td key={`outlet-${data.id}-title`}>
      <div className={checkForStatus[data.status.toLowerCase()]}>
        {data.title}
      </div>
    </td>
  );
  table[labelMappings.route_sequence] = (
    <td key={`outlet-${data.id}-route_sequence`} className="text-center">{data.route_sequence === 0 ? '-' : data.route_sequence }</td>
  );
  table[labelMappings.category] = (
    <td key={`outlet-${data.id}-category`}>{data.category}</td>
  );
  table[labelMappings.target] = (
    <td key={`outlet-${data.id}-target`} className="text-right">{fixedFloatAndCommas(data.target)}</td>
  );
  table[labelMappings.received] = (
    <td key={`outlet-${data.id}-received`} className="text-right">{fixedFloatAndCommas(data.achievementValue)}</td>
  );
  table[labelMappings.reason] = (
    <td key={`outlet-${data.id}-reason`}>{data.status === 'UNSUCCESSFUL' ? data.notes : ''}</td>
  );
  table[labelMappings.sync_time] = (
    <td key={`outlet-${data.id}-sync_time`}>{data.sync_time}</td>
  );
  table[labelMappings.callImage] = (
    <ImageColumn
      keyTitle={`rp-image-${data.id}`}
      imageList={data.callImage || []}
      onImageClick={handleSliderOpen}
    />
  );
  return table;
};

const TableBody = ({
  data, handleSliderOpen,
}) => {
  const tableBody = returnTableBody(data, handleSliderOpen);

  return (
    <tr>
      {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

const TableHeaderConfig = () => <TableHeader headerDetails={labelConfig} />;


TableBody.propTypes = {
  data: PropTypes.instanceOf('object').isRequired,
};


export {
  TableHeaderConfig as TableHeader,
  TableBody,
};
