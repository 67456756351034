import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../../components';


const propTypes = {
  getReplenishmentReport: PropTypes.func.isRequired,
  regenerateReport: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  showPaginatedList: PropTypes.bool,
  disableGenerateButton: PropTypes.bool,
};

const defaultProps = {
  serverResponseWaiting: false,
  showPaginatedList: false,
  disableGenerateButton: true,
};

const PageHeaderButtons = (props) => {
  const {
    getReplenishmentReport, serverResponseWaiting, showPaginatedList,
    regenerateReport, disableGenerateButton,
  } = props;
  return (
    <Fragment>
      {
        !showPaginatedList && (
          <Button
            primary
            small
            onClick={() => getReplenishmentReport()}
            className={disableGenerateButton || serverResponseWaiting ? 'disabled' : ''}
          >
            <span>Generate</span>
          </Button>
        )
      }
      {
        showPaginatedList && (
          <Button
            secondary
            small
            onClick={() => regenerateReport()}
          >
            <span>Re-Generate</span>
          </Button>
        )
      }
    </Fragment>
  );
};

PageHeaderButtons.propTypes = propTypes;

PageHeaderButtons.defaultProps = defaultProps;

export default PageHeaderButtons;
