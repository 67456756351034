import styled from 'styled-components';

const AssortmentDetailStyled = styled.div`
.assortment-detail{
  padding:32px 48px;
  .select-section-wrap{
    margin-bottom: 24px;
  .select-section{
    position:relative;
    display: flex;
    align-items: center;
    .form-select-input{
      width:215px;
      margin-bottom:0px;
      label{
        display:none;
      }
      .zindex-2__placeholder{
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 151px;
      }
    }
    .light{
      position:absolute;
      left: 152px;
    }
  }
}
}
`;

export default AssortmentDetailStyled;
