import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { ORDER_FULFILMENT } from '../../../../../data/enums/Route';
import { breadCrumb as invoiceRoutes } from '../../config';


const breadCrumb = [
  ...invoiceRoutes,
  {
    id: '',
    title: 'Order Fulfilment',
    path: `/${ORDER_FULFILMENT}`,
    active: true,
  },
  {
    id: '',
    title: 'Sales Invoice',
    path: '#',
    active: true,
  },
];
const defaultBillingObj = {
  status: false,
  url: '',
};
const detailMapper = element => ({
  Distributor: {
    id: element.Distributor ? element.Distributor.id : 0,
    title: element.Distributor ? element.Distributor.title : '',
    servicesUsed: element.Distributor ? {
      billing: element.Distributor.servicesUsed ? element.Distributor.servicesUsed.billing || defaultBillingObj : defaultBillingObj,
    } : { billing: defaultBillingObj },
  },
  RetailOutlet: {
    id: element.RetailOutlet ? element.RetailOutlet.id : 0,
    title: element.RetailOutlet ? element.RetailOutlet.title : '',
    address: element.RetailOutlet ? element.RetailOutlet.address : '',
    panNumber: element.RetailOutlet ? element.RetailOutlet.panNumber : '',
    Category: {
      title: element.RetailOutlet
        ? element.RetailOutlet.Category ? element.RetailOutlet.Category.title : '' : '',
    },
  },
  selectedOrders: element.selectedOrders || [],
  selectedIds: element.selectedIds || [],
  User: { fullName: element.User ? element.User.fullName : '' },
  callOrderValue: element.callOrderValue ? element.callOrderValue : {},
});

const customerMapper = element => ({
  id: element.id ? element.id || '' : element.customerId || '',
  name: element.name ? element.name || '' : element.title || '',
  address: element.address || '',
  panNumber: element.panNumber ? element.panNumber || null : element.panNo || null,
});

const customerDetailMapper = element => ({
  customer: element.customer ? element.customer : customerMapper({}),
  invoiceNumber: element.invoiceNumber ? element.invoiceNumber : null,
  remarks: element.remarks ? element.remarks : '',
  cashStatus: element.cashStatus ? element.cashStatus : false,
});

const getPayload = (data) => {
  /** todo create payload details accrding to api payload requirement */
  const details = {
    orderIds: data.selectedIds,
    invoiceNumber: data.customerDetails.invoiceNumber,
    customerId: data.customerDetails.customer.id || null,
    tradeDiscountValue: data.amountDetails.tradeDiscountValue,
  };

  return details;
};

const crudSuccess = {
  [EVENT_OPERATION.CREATE]: {
    objectMapper: data => (getPayload(data)),
    responseName: 'invoiceOrders',
    message: 'Orders Invoiced Successfully',
  },
};


export {
  breadCrumb, detailMapper, crudSuccess, customerMapper, customerDetailMapper,
};
