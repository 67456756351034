import React from 'react';
import PropTypes from 'prop-types';
import { Form, FileInput } from '../../components/index';
import withLoading from '../../utils/composition/withLoading';
import { EMPTY, INVALID } from '../../data/enums/ErrorMessage';
import { isObjectEmpty } from '../../utils/objectPrototypes';
import CSVUploadStyled from './CSVUploadStyled';

const propTypes = {
  id: PropTypes.string,
  data: PropTypes.shape({
    file: PropTypes.instanceOf(Object),
  }),
  handleFileUpload: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  refsObj: PropTypes.instanceOf(Object),
  accept: PropTypes.string,
  displayAlert: PropTypes.func,
  disableOnInvalidCSV: PropTypes.func,

};

const defaultProps = {
  id: '',
  data: {
    file: {},
  },
  refsObj: {
    uploadSku: '',
  },
  accept: 'text/csv',
  enableErrorDisplay: false,
  handleFileUpload: () => null,
  displayAlert: () => null,
  disableOnInvalidCSV: () => null,

};

const CSVUploadForm = ({ ...props }) => {
  const {
    id,
    data,
    accept,
    refsObj,
    enableErrorDisplay,
    handleFileUpload,
    displayAlert,
    disableOnInvalidCSV,
  } = props;

  return (
    <CSVUploadStyled>
      <div className="svg">
        <img alt="csvUpload" src="/image/icons/files icon.svg" />
      </div>
      <Form>
        <FileInput
          id={id}
          ref={ref => refsObj.uploadEntity = ref}
          name="csvUpload"
          className="input-file-upload"
          accept={accept}
          errorMessage={isObjectEmpty(data.file) ? EMPTY : INVALID}
          disabled={!isObjectEmpty(data.file)}
          enableErrorDisplay={enableErrorDisplay}
          onChange={e => handleFileUpload(e)}
          file={data.file}
          displayAlert={displayAlert}
          disableOnInvalidCSV={disableOnInvalidCSV}
        />
      </Form>
    </CSVUploadStyled>
  );
};

CSVUploadForm.propTypes = propTypes;

CSVUploadForm.defaultProps = defaultProps;

const UploadViewWithErrorAndLoading = withLoading(CSVUploadForm);

export default UploadViewWithErrorAndLoading;
