import gql from 'graphql-tag';
import { graphql } from 'react-apollo/graphql';

const LOG_OUT = graphql(
  gql`
      mutation {
          logout
      }
  `,
  {
    props: ({ mutate }) => ({
      logout: variables => mutate({
        variables,
      }),
    }),
  },
);

const mutation = {
  logout: LOG_OUT,
};

export {
  LOG_OUT
};

export { mutation };
