import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { dataProps, paginationProps } from '../../../../../../data/enums/Component';
import { TableHeader, TableBody } from './tabelConfig';
import withImageSlider from '../../../../../../utils/composition/withImageSlider';
import withErrorAndLoadingScreen from '../../../../../../utils/composition/withLoading';
import { Pagination } from '../../../../../../components';

const propTypes = {
  data: PropTypes.shape({
    total: PropTypes.number,
    limit: PropTypes.number,
  }),
  handleSliderOpen: PropTypes.func.isRequired,
  pagination: paginationProps.value,
  onPageChange: PropTypes.func,
};
const defaultProps = {
  data: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
  onPageChange: () => null,
};

const View = ({ ...props }) => {
  const {
    data,
    handleSliderOpen,
    onPageChange,
    pagination,
  } = props;

  return (
    <Fragment>
      <div className="table-wrap">
        <table>
          {
            TableHeader()
          }
          <tbody>
            {data.list.map(callHistory => (
              TableBody({
                data: callHistory,
                handleSliderOpen,
              })
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </Fragment>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const ViewWithErrorAndLoading = withErrorAndLoadingScreen(View);

const ViewWithImageSlider = withImageSlider(ViewWithErrorAndLoading);

export default ViewWithImageSlider;
