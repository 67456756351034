import styled from 'styled-components';

const DetailWraper = styled.div`
 .split {
    .modal-inner{
        max-width: 900px;
    }
 }
 .read{
    .modal-inner{
        max-width: 1000px;
    }
    .text{
        color: #6B6C7E;
        font-size: 14px;
    }
    .table{
        margin-top: 24px;
            th {
                 &:nth-child(n+3) {
                   text-align: right;
                }
            }
        tbody {
           tr {
             td {
                 &:nth-child(n+3) {
                   text-align: right;
                }
            }
          }
        }
        tfoot{
            background:#f1f2f5;
          tr{
              height: 59px;
              font-weight: 600;
              td {
                   &:nth-child(n+2) {
                     text-align: right;
                  }
              }
          }
        }
    }
 }
 .replaced{
    .modal-inner{
        max-width: 1124px;
       .table-wrap table{
            thead tr th{
                &:first-child {
            padding-left: 24px;
        }
        &:last-child{
                min-width: 300px;
        }}
        tbody tr td{
            &:first-child {
            padding-left: 24px;
        }
        &:last-child {
            /* padding: 0px 24px 9px 12px; */
            }
        }
        }
            .form-select-input {
                margin-bottom: 0px;
                .zindex-2__control{
                    max-height: 30px;
                    min-height:0px;
                }
            }
    }
 }
 .modal-inner.replaced{
    .table-wrap{
      min-height:175px;
      table{

        th,
        td{
             padding:9px 16px;
                &:first-child{
                  padding-left:24px;
                }
                 &:last-child{
                  padding-right:24px;
                }
                &.checks{
                  min-width:60px;
                  padding-right:0;
                }
                  &:nth-last-child(5){
                    text-transform:capitalize;
                    }
        }
         
           tbody{
              tr{
              td{
                .form-input{
                  display:flex;
                  flex-direction:row-reverse;
                  input{
                    height:32px;
                    width:72px;
                    /* border:1px solid #CDCED9; */
                  }
                }
                .form-input,
                .form-select-input{
                  font-size:14px;
                  color:#6B6C7E;
                  .form-select-border{
                    /* border:1px solid #CDCED9 ;     */
                    .zindex-2__control{
                    border-style:unset;
                    border-color:unset;
                    .zindex-2__placeholder{
                      font-size:inherit;
                      color:inherit;
                    }
                  }
                .zindex-2__control{
                    border-style:unset;
                    border-color:unset;
                    .zindex-2__placeholder{
                      font-size:inherit;
                      color:inherit;
                    }
                  }
                  }
                  input{                     
                      font-size:inherit;
                      color:inherit;
                      /* background-color:#F1F2F5; */
                  }
                  .select-css{
                    .zindex-2__control{
                      /* background-color:#F1F2F5; */
                    }
                  }
                  label{
                    display:none;
                  }
                }
              }
            }
        }
      }
    }
  } 
  .read_sub_type {
  .modal-inner{
        max-width: 850px;
    }
    th, td {
                 &:nth-child(n+3) {
                   text-align: right;
                }
            }
    
    tfoot{
            background:#f1f2f5;
          tr{
              height: 59px;
              font-weight: 600;
              td {
                   &:nth-child(n+2) {
                     text-align: right;
                  }
              }
          }
        }
 }
`;

export default DetailWraper;
