import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../../components/Icon';
import Badge from '../../../../components/Badge';
import { USER_ROLE } from '../../../../data/enums';
import { getUserRole } from '../../../../data/dao';
import { normalPresentor } from '../../../../utils/date';
import { fixedFloatAndCommas } from '../../../../utils/conversion';
import { DISTRIBUTOR_SERVICES } from '../../../common/DomainConfig';
import { getParametersBasedOnType } from '../dispatched/tableConfig';
import { getDistributorServiceStatus, handlePrint, headerLabelConfig } from '../../../common/HelperFunctions';
import { USER_ROLE_TITLE } from '../../../../data/enums/UserRole';
import { DOMAIN } from '../../../../data/enums/config';
import TableHeader from '../../../base/TableHeader';

// todo update the configurations.
const labelMappings = {
  dispatchNumber: 'dispatchNumber',
  id: 'invoiceNumber',
  date: 'date',
  outlet: 'outlet',
  route: 'route',
  dse: 'dse',
  totalNetValue: 'totalNetValue',
  distributor: 'distributor',
  channel: 'channel',
  category: 'category',
  channelAndCategory: 'channel',
  actionHolders: 'actionHolder',
};

const returnTableBody = (delivered, onRowClick) => {
  const table = {};
  const userRole = getUserRole();
  const printStatus = getDistributorServiceStatus(
    delivered.Distributor, DISTRIBUTOR_SERVICES.BILLING,
  ) && userRole === USER_ROLE.BILLING_USER;
  const data = {
    ...delivered,
    ...getParametersBasedOnType(delivered),
  };
  table[labelMappings.dispatchNumber] = (
    <td onClick={() => onRowClick(data.id, data.Distributor.id)}>
      {
        /** todo chip implementation here */
        <span>
          <span className="tab-label">
            {data.dispatchNumber}
          </span>
          <Badge light label={data.orderIds.length} />
        </span>
      }
    </td>
  );
  table[labelMappings.id] = (
    <td onClick={() => onRowClick(data.id, data.Distributor.id, data.mappedId, data.orderType)}>
      {data.invoiceNumber}
    </td>
  );
  table[labelMappings.date] = (
    <td onClick={() => onRowClick(data.id, data.Distributor.id, data.mappedId, data.orderType)}>
      {normalPresentor(data.invoiceDate)}
    </td>
  );
  table[labelMappings.outlet] = (
    <td onClick={() => onRowClick(data.id, data.Distributor.id, data.mappedId, data.orderType)}>
      <a href={`outlet/details/${data.RetailOutlet.id}`} onClick={e => e.preventDefault()}>
        {data.RetailOutlet ? data.RetailOutlet.title : ''}
      </a>
    </td>
  );
  table[labelMappings.channel] = (
    <td onClick={() => onRowClick(data.id, data.Distributor.id, data.mappedId, data.orderType)}>
      {data.Channel && data.Category
        ? `${data.Channel.title}/${data.Category.title}` : ''}
    </td>
  );
  table[labelMappings.route] = (
    <td onClick={() => onRowClick(data.id, data.Distributor.id, data.mappedId, data.orderType)}>
      {data.Route ? data.Route.title : ''}
    </td>
  );
  table[labelMappings.dse] = (
    <td onClick={() => onRowClick(data.id, data.Distributor.id, data.mappedId, data.orderType)}>
      {data.User ? data.User.fullName : ''}
    </td>
  );

  table[labelMappings.totalNetValue] = (
    <td
      className="text-right"
      onClick={() => onRowClick(data.id, data.Distributor.id, data.mappedId, data.orderType)}
    >
      {fixedFloatAndCommas(data.amountDetails ? data.amountDetails.netAmount || 0 : 0)}
    </td>
  );
  table[labelMappings.distributor] = (
    <td onClick={() => onRowClick(data.id, data.Distributor.id, data.mappedId, data.orderType)}>
      <div style={{ display: 'flex' }}>
        <span>{ data.Distributor ? data.Distributor.title : ''}</span>
        {printStatus && (
        <span>
          <Icon
            iconName="print"
            onClick={() => handlePrint({
              distributorServices: data.Distributor.servicesUsed,
              invoiceNumber: data.invoiceNumber,
              firstCopy: false,
              module: DOMAIN.SALES,
            })}
          />
        </span>)}
      </div>
    </td>
  );

  return table;
};


const labelConfig = [
  {
    id: 1,
    label: labelMappings.dispatchNumber,
    title: 'Dispatch Number',
    show: true,
    className: 'sorting',
    sortable: true,
  },
  {
    id: 2,
    label: labelMappings.id,
    title: 'Invoiced/Replaced Number',
    show: true,
    className: 'sorting',
    sortable: true,
  },
  {
    id: 3,
    label: labelMappings.date,
    title: 'Invoiced/Replaced Date',
    show: true,
    className: 'sorting',
    sortable: true,
  },
  {
    id: 4,
    label: labelMappings.outlet,
    title: 'Outlet',
    show: true,
    className: 'sorting',
    sortable: true,
  },
  {
    id: 5,
    label: labelMappings.channel,
    title: 'Channel/Category',
    show: true,
    className: 'sorting',
    sortable: true,
  },
  {
    id: 6,
    label: labelMappings.route,
    title: 'Route',
    show: true,
    className: 'sorting',
    sortable: true,
  },
  {
    id: 7,
    label: labelMappings.dse,
    title: headerLabelConfig[USER_ROLE_TITLE.DSE],
    show: true,
    className: 'sorting',
    sortable: true,
  },
  {
    id: 8,
    label: labelMappings.totalNetValue,
    title: 'Invoiced/Replaced Value',
    show: true,
    className: 'sorting',
    sortable: true,
    align: true,
  },
  {
    id: 9,
    label: labelMappings.distributor,
    title: 'Distributor',
    show: true,
    className: 'sorting',
    sortable: true,
  },
];

const TableBody = ({ data, onRowClick }) => {
  // Expanding/Dragging and collapsing table body.
  // color based upon table body
  // tooltip if it is sorted.
  // active className
  const tableBody = returnTableBody(data, onRowClick);

  return <Fragment>{labelConfig.map(label => (label.show ? tableBody[label.label] : null))}</Fragment>;
};

const TableHeaderConfig = ({ onHeaderClick }) => <TableHeader headerDetails={labelConfig} onHeaderClick={onHeaderClick} />;


TableHeaderConfig.propTypes = {
  onHeaderClick: PropTypes.func,
};

TableBody.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func.isRequired,
};


export {
  TableHeaderConfig as TableHeader,
  TableBody,
};
