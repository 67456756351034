import gql from 'graphql-tag';

const LEDGER_FRAGMENT = gql`
    fragment LedgerFragment on Customer {
        customerId
        title
        address
        panNumber
        type
        openingBalance
        openingBalanceType
    }
`;

const GET_LEDGERS = gql`
  query getLedgers($offset:Int, $limit: Int, $filter: FilterInput ){
      customers(offset: $offset, limit: $limit, filter: $filter){
          rows {
            ...LedgerFragment
          }
          count
      }
  }
  ${LEDGER_FRAGMENT}
`;

const query = {
  getLedgers: GET_LEDGERS,
};

export {
  query, GET_LEDGERS,
};
