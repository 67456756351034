import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../../../data/enums/SuccessMessage';
import { ROUTE } from '../../../../../data/enums/Route';
import { FORM_CONFIG } from '../../../../../data/enums/config';
import { refGenerator } from '../../../../../utils';
import { fileParser } from '../../../../../utils/parser';
import { breadCrumbConfig } from '../../config';

const breadCrumb = [
  ...breadCrumbConfig,
  {
    id: 1,
    title: 'Route',
    path: `/${ROUTE}`,
    active: true,
  },
];

const title = 'Outlet';

const formConfig = {
  [FORM_CONFIG.MAPPER]: element => ({
    file: element.file || {},
  }),
  [FORM_CONFIG.TITLE]: `${EVENT_OPERATION.UPLOAD} ${title}`,
  [FORM_CONFIG.REFS_OBJ]: refGenerator([
    'title',
    'visitType',
    'deliveryType',
    'visitCategory',
    'visitFrequency',
    'town',
  ]),
  [FORM_CONFIG.VALIDATION_REQUIRED]: true,
};

const crudSuccess = {
  [EVENT_OPERATION.CREATE]: {
    objectMapper: data => ({
      title: data.title,
    }),
    responseName: 'createChannel',
    message: `${title} ${MESSAGE_EVENT_OPERATION.CREATE}`,
  },
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: data => ({
      ...data,
    }),
    responseName: 'updateRetailOutlet',
    message: `${title} ${MESSAGE_EVENT_OPERATION.UPDATE}`,
  },
  [EVENT_OPERATION.DELETE]: {
    objectMapper: data => ({
      ids: [data.id],
      type: 'Channel',
      active: false,
    }),
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: data => ({
      ids: [data.id],
      type: 'Channel',
      active: false,
    }),
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
  [EVENT_OPERATION.UPLOAD]: {
    objectMapper: async (data) => {
      const fileData = await fileParser(data.file)
        .then(file => ({
          file,
          type: title,
        }));
      return fileData;
    },
    responseName: 'importCSV',
    message: `${title} ${MESSAGE_EVENT_OPERATION.UPLOAD}`,
  },
};

// eslint-disable-next-line no-unused-vars
const apiTransformer = () => {

};


export {
  crudSuccess, breadCrumb as breadCrumbConfig, formConfig,
};
