import React from 'react';
import PropTypes, { instanceOf } from 'prop-types';
import Menu from '../Menu';
import CheckBox from '../Checkbox';
import ColumnSelectStyled from './ColumnSelectStyled';

const defaultProps = {
  labelConfig: [],
  onColumnSelectChange: () => {},
};

const propTypes = {
  labelConfig: PropTypes.arrayOf(instanceOf(Object)),
  onColumnSelectChange: PropTypes.func,
};


const columnSelect = ({ ...props }) => {
  const { labelConfig, onColumnSelectChange } = props;

  const onCheckBoxClick = (item, index) => {
    const label = item;
    label.show = !label.show;
    labelConfig[index] = label;
    onColumnSelectChange(labelConfig);
  };

  const onAllSelect = (e) => {
    labelConfig.forEach((d) => {
      d.show = e.target.checked;
    });
    return onColumnSelectChange(labelConfig);
  };


  const checked = !labelConfig.map(d => d.show).includes(false);

  return (
    <ColumnSelectStyled>
      <Menu label="column">
        <div className="title-col">
          <CheckBox onCheckBoxClick={e => onAllSelect(e)} checked={checked} />
          <span className="title"> Select Columns</span>
        </div>
        <div className="select-li-wrap">
          {labelConfig.map((data, index) => (
            (data.label === 'checkbox' || data.label === 'actionHolders') ? ''
              : (
                <div key={data.title} className="select-li">
                  <CheckBox onCheckBoxClick={() => onCheckBoxClick(data, index)} checked={data.show} />
                  <span className="title">{data.title}</span>
                </div>
              )
          ))}
        </div>

      </Menu>
    </ColumnSelectStyled>
  );
};

columnSelect.defaultProps = defaultProps;

columnSelect.propTypes = propTypes;

export default columnSelect;
