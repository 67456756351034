import React from 'react';
import { tableData, menuAction } from '../../../../utils/paginatedListUtils';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { fixedFloatAndCommas } from '../../../../utils/conversion';
import { Badge } from '../../../../components';

const labelMappings = {
  outlet: 'outlet',
  assignedTo: 'assignedTo',
  amount: 'amount',
  overDue: 'overDue',
  status: 'status',
  distributor: 'distributor',
  route: 'route',
  actionHolders: 'actionHolders',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.outlet,
    title: 'Outlet',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.assignedTo,
    title: 'Assigned To',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.route,
    title: 'Route',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.amount,
    title: 'Amount',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.overDue,
    title: 'Over Due',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.status,
    title: 'Status',
    show: true,
  },
  {
    id: 7,
    label: labelMappings.distributor,
    title: 'Distributor',
    show: false,
  },
  {
    id: 5,
    label: labelMappings.actionHolders,
    title: '',
    show: true,
  },
];

const menuConfigList = [
  {
    title: 'In Progress',
    type: EVENT_OPERATION.IN_PROGRESS,
  }, {
    title: 'Stop',
    type: EVENT_OPERATION.STOP,
  }, {
    title: 'Black List',
    type: EVENT_OPERATION.BLACKLIST,
  },
];

const TableHeader = tableConfig => (
  <thead className="">
    <tr>{tableConfig.map(label => (label.show ? tableData(label.title, label.align, label.classNames) : null))}</tr>
  </thead>
);

const returnTableBody = (data, onStatusChanges) => {
  const table = {};
  table[labelMappings.outlet] = (
    <td key={`outstandings-${data.id}-outlet`}>{data.title}</td>
  );
  table[labelMappings.assignedTo] = (
    <td key={`outstandings-${data.id}-dse`}>{(data.route && data.route.user) ? data.route.user.fullName : ''}</td>
  );
  table[labelMappings.route] = (
    <td key={`outstandings-${data.id}-route`}>{data.route ? data.route.title : ''}</td>
  );
  table[labelMappings.amount] = (
    <td key={`outstandings-${data.id}-amount`}>{data.billDetails && data.billDetails.outstanding ? fixedFloatAndCommas(data.billDetails.outstanding) : 0}</td>
  );
  table[labelMappings.overDue] = (
    <td key={`outstandings-${data.id}-overDue`}>{(data.billDetails && data.billDetails.creditDays) ? `${Math.sign(data.billDetails.creditDays) === -1 ? '' : '+'}${fixedFloatAndCommas(data.billDetails.creditDays)} days` : 'N/A'}</td>
  );
  table[labelMappings.status] = (
    <td key={`outstandings-${data.id}-status`}>
      {data.transactionStatus && (
      <span className={data.transactionStatus.toLowerCase()}>
        <span className="badge_border">
          <Badge light>{data.transactionStatus.replace('_', ' ')}</Badge>
        </span>
      </span>
      )}
    </td>
  );
  table[labelMappings.distributor] = (
    <td key={`outstandings-${data.id}-distributor`}>{data.distributor.title}</td>
  );
  table[labelMappings.actionHolders] = (
    <td
      className="text-right simple-popup-actions"
      onClick={e => e.stopPropagation()}
    >
      {menuAction(menuConfigList, { menuIcon: 'ellipsis-v' }, onStatusChanges, data)}
    </td>
  );

  return table;
};


const TableBody = ({
  data, tableConfig, onStatusChanges,
}) => {
  // Expanding/Dragging and collapsing table body.
  // color based upon table body
  // tooltip if it is sorted.
  // active className
  const tableBody = returnTableBody(data, onStatusChanges);
  return (
    <tr key={`productivity-${data.id}-details`}>
      {tableConfig.map(label => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

export {
  TableHeader,
  TableBody,
  labelConfig,
};
