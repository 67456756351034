import { getTodaysDate } from '../../../../../utils/date';
import { FORM_CONFIG } from '../../../../../data/enums/config';
import { refGenerator } from '../../../../../utils/refGenerator';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { STOCK_TYPE } from '../../../../common/DomainConfig';

const title = 'GRN';

const STOCK_TYPE_LIST = [STOCK_TYPE.SALEABLE, STOCK_TYPE.DAMAGED, STOCK_TYPE.EXPIRED];

const priceMapper = element => ({
  rate: element.rate || 0,
  amount: element.amount || 0,
  discount: element.discount || 0,
  taxAmount: element.taxAmount || 0,
  netAmount: element.netAmount || 0,
  grossAmount: element.grossAmount || 0,
  billDiscount: element.billDiscount || 0,
  tradeDiscount: element.tradeDiscount || 0,
});

const formMapper = element => ({
  id: element.grnDetailId || element.id || '',
  grnDetailId: element.id || '',
  skuId: element.skuId || null,
  skuBatchId: element.skuBatchId || null,
  batchNumber: element.SkuBatch ? element.SkuBatch.batchDetails.batchNumber : '',
  expiryDate: element.SkuBatch ? element.SkuBatch.usageDate ? element.SkuBatch.usageDate.expiry
    || getTodaysDate() : getTodaysDate() : getTodaysDate(),
  manufactureDate: element.SkuBatch ? element.SkuBatch.usageDate
    ? element.SkuBatch.usageDate.manufacture || getTodaysDate() : getTodaysDate() : getTodaysDate(),
  referenceQuantity: element.quantity || 0,
  updatedQuantity: element.updatedQuantity || 0,
  updatedQuantityReference: element.updatedQuantityReference || 0,
  updatedPriceDetails: element.updatedPriceDetails ? priceMapper(element.updatedPriceDetails) : priceMapper({}),
  priceDetails: element.priceDetails ? priceMapper(element.priceDetails) : priceMapper({}),
  stockType: element.stockType || STOCK_TYPE.SALEABLE.value,
});

const requiredList = [
  'skuId', 'skuBatchId', 'updatedQuantity',
  'rate', 'discount', 'amount', 'netAmount',
];

const formConfig = {
  [EVENT_OPERATION.CREATE]: {
    [FORM_CONFIG.TITLE]: 'Add SKU',
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    [FORM_CONFIG.MAPPER]: (element = {}) => formMapper(element),
    [FORM_CONFIG.REFS_OBJ]: refGenerator([...requiredList]),
  },
  [EVENT_OPERATION.UPDATE]: {
    [FORM_CONFIG.TITLE]: 'Update SKU',
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    [FORM_CONFIG.MAPPER]: (element = {}) => formMapper(element),
    [FORM_CONFIG.REFS_OBJ]: refGenerator([...requiredList]),
  },
  [EVENT_OPERATION.DELETE]: {
    [FORM_CONFIG.TITLE]: 'DELETE',
    [FORM_CONFIG.VALIDATION_REQUIRED]: false,
    [FORM_CONFIG.MAPPER]: (element = {}) => ({ id: element.id || '' }),
  },
};

const totalViewRefs = refGenerator(['billDiscount', 'tradeDiscount']);

const calculateRelativeDiscount = (referenceAmount = 1, referenceDiscount, amount) => (
  amount * referenceDiscount) / (referenceAmount || 1
);

const getLineAmountDetails = (element, grnTotalAmount) => {
  const netAmount = (element.updatedQuantity || 0)
    * (element.updatedPriceDetails.rate || 0)
    - element.updatedPriceDetails.discount || 0;
  const billDiscount = calculateRelativeDiscount(
    grnTotalAmount.subTotal,
    grnTotalAmount.billDiscount,
    netAmount,
  ) || 0;
  const tradeDiscount = calculateRelativeDiscount(
    grnTotalAmount.subTotal,
    grnTotalAmount.tradeDiscount,
    netAmount,
  ) || 0;
  const taxableAmount = netAmount - billDiscount - tradeDiscount;
  const taxAmount = taxableAmount * 0.13;
  const grossAmount = taxableAmount + taxAmount;

  return {
    netAmount,
    billDiscount,
    tradeDiscount,
    taxableAmount,
    taxAmount,
    grossAmount,
  };
};

const updateSkuLine = (element, sku, skuBatch, grnTotalAmount) => {
  const lineAmountDetails = getLineAmountDetails(element, grnTotalAmount);

  return ({
    ...element,
    updatedPriceDetails: {
      ...element.updatedPriceDetails,
      amount: element.updatedQuantity * element.updatedPriceDetails.rate,
      netAmount: lineAmountDetails.netAmount,
      billDiscount: lineAmountDetails.billDiscount,
      tradeDiscount: lineAmountDetails.tradeDiscount,
      grossAmount: lineAmountDetails.grossAmount,
      taxAmount: lineAmountDetails.taxAmount,
    },
    SKU: {
      id: sku.id,
      title: sku.title,
    },
    SkuBatch: {
      id: skuBatch.id,
      batchDetails: { batchNumber: skuBatch.batchDetails.batchNumber },
      usageDate: {
        manufacture: element.manufactureDate,
        expiry: element.expiryDate,
      },
    },
  });
};

const updateRate = (state, stateUpdater, selectedBatch) => {
  state.updatedPriceDetails.rate = selectedBatch.priceDetails.dlp;
  stateUpdater(state);
};

export {
  formConfig,
  title,
  updateRate,
  totalViewRefs,
  STOCK_TYPE_LIST,
  updateSkuLine,
  getLineAmountDetails,
  calculateRelativeDiscount,
};
