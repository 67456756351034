import gql from 'graphql-tag';
import client from '../../apiClient/apollo';
import {
  CALL_FRAGMENT
} from '../../views/sales/orderProcessing/API';
import { ORDER_DETAIL_FRAGMENT } from '../../views/sales/orderProcessing/API';
// TODO: handle Error in Data Fetch


const query = gql`
    query getActiveOrders {
        activeOrder {
            ...CallFragment
            selectedOrders {
                ...OrderDetailFragment
            }
        }
    }
    ${CALL_FRAGMENT}
    ${ORDER_DETAIL_FRAGMENT}
`;


const insertOrders = (orders) => {
  client.writeQuery({
    query,
    data: {
      activeOrder: orders,
    },
  });
};

const getActiveOrder = () => {
  return client.readQuery({ query });
};


const setActiveOrder = async (orders) => {
  insertOrders(orders);
};

export {
  setActiveOrder,
  getActiveOrder,
};
