import styled from 'styled-components';

const LeaveMgmtStyled = styled.div`
.section-content table {
td,th{
  &:nth-last-child(2){
    text-align:center;
  }
}
  tbody{
    td{
      &:last-child{
        width:19%;
      }
        .light{
          min-width:83px;
          text-align:center;
          height:24px;
          line-height:15px;
          font-size:12px;
          font-weight:600;
          white-space:nowrap;
        }
      .pending{
        border:0;
        padding:0;
        .light{
            border: 1px solid #CDCED9;
                color: #6B6C7E;
        }
      }
       .approved{
        .light{
           border: 1px solid #47C464;
          color: #287D3C
        }
      }

      .rejected{
        .light{
          border: 1px solid rgba(218, 20, 20, 0.4);
          color: #B95000;
        }
      }
    }
  }

}

`;

export default LeaveMgmtStyled;
