import gql from 'graphql-tag';
import { graphql } from 'react-apollo/graphql';
import { DOWNLOAD_REPORT } from '../../common/GqlAPI';

const DELIVERY_TYPE_FRAGMENT = gql`
    fragment DeliveryTypeFragment on RouteDeliveryType {
        id
        label
        title
    }
`;

const VISIT_TYPE_FRAGMENT = gql`
    fragment VisitTypeFragment on RouteVisitType {
        id
        label
        title
    }
`;

const VISIT_CATEGORY_FRAGMENT = gql`
    fragment Visitment on RouteVisitCategory {
        id
        label
        title
    }
`;

const VISIT_FREQUENCY_FRAGMENT = gql`
    fragment VisitFrequencyFragment on RouteVisitFrequency {
        id
        label
        title
        numberOfDays
    }
`;


const ROUTE_FRAGMENT = gql`
    fragment RouteFragment on Route {
        id
        title
        assignedTo
        townId
        active
        retailOutletCount
        VisitType {
            ...VisitTypeFragment
        }
        DeliveryType {
            ...DeliveryTypeFragment
        }
        VisitCategory {
            ...Visitment
        }
        VisitFrequency {
            ...VisitFrequencyFragment
        }
        RetailOutlets{
            id
            title
            geoLocation{
            latitude
            longitude
            }
            routeId
        }
        Town {
            id
            title
        }
    }
    ${VISIT_TYPE_FRAGMENT}
    ${DELIVERY_TYPE_FRAGMENT}
    ${VISIT_CATEGORY_FRAGMENT}
    ${VISIT_FREQUENCY_FRAGMENT}
`;


const GET_ROUTE_LIST = gql`
    query getRoutes($offset: Int, $limit: Int, $filter: FilterInput) {
        routes(offset: $offset, limit: $limit, filter: $filter) {
            rows {
                ...RouteFragment
            }
            count
        }
    }
    ${ROUTE_FRAGMENT}
`;


const GET_ROUTE_TITLE_LIST = gql`
    query getRoutes($offset: Int, $limit: Int, $filter: FilterInput) {
        routes(offset: $offset, limit: $limit, filter: $filter) {
            rows {
                title
                id
                assignedTo
                VisitFrequency {
                    ...VisitFrequencyFragment
                }
            }
            count
        }
    }
    ${VISIT_FREQUENCY_FRAGMENT}
`;

const GET_ROUTE_VISIT_FREQUENCY_LIST = gql`
    query routeVisitFrequencyList{
        routeVisitFrequencyList{
            rows {
                ...VisitFrequencyFragment
            }
            count
        }
    }
    ${VISIT_FREQUENCY_FRAGMENT}
`;

const GET_ROUTE_VISIT_CATEGORY_LIST = gql`
    query routeVisitCategories{
        routeVisitCategories{
            rows {
                ...Visitment
            }
            count
        }
    }
    ${VISIT_CATEGORY_FRAGMENT}
`;

const GET_ROUTE_VISIT_TYPE_LIST = gql`
    query routeVisitTypes{
        routeVisitTypes{
            rows {
                ...VisitTypeFragment
            }
            count
        }
    }
    ${VISIT_TYPE_FRAGMENT}
`;

const UPDATE_ROUTE = graphql(
    gql`
      mutation($id: Int!, $input: RouteInput!) {
          updateRoute(id: $id, input: $input) {
              ...RouteFragment
          }
      }
      ${ROUTE_FRAGMENT}
  `,
    {
        // name: 'loginWithServer',
        props: ({ mutate }) => ({
            updateRoute: variables => mutate({
                variables,
            }),
        }),
    },
);

const CREATE_ROUTE = graphql(
    gql`
      mutation($input: RouteInput!) {
          createRoute(input: $input) {
              ...RouteFragment
          }
      }
      ${ROUTE_FRAGMENT}
  `,
    {
        // name: 'loginWithServer',
        props: ({ mutate }) => ({
            createRoute: variables => mutate({
                variables,
            }),
        }),
    },
);

const query = {
    getRouteList: GET_ROUTE_LIST,
    getRouteTitleList: GET_ROUTE_TITLE_LIST,
    getRouteVisitFrequencyList: GET_ROUTE_VISIT_FREQUENCY_LIST,
    getRouteVisitTypeList: GET_ROUTE_VISIT_TYPE_LIST,
    getRouteVisitCategoryList: GET_ROUTE_VISIT_CATEGORY_LIST,
};

const mutation = {
    createRoute: CREATE_ROUTE,
    updateRoute: UPDATE_ROUTE,
    downloadReport: DOWNLOAD_REPORT,
};

export {
    ROUTE_FRAGMENT,
    GET_ROUTE_VISIT_FREQUENCY_LIST,
    GET_ROUTE_VISIT_TYPE_LIST,
    GET_ROUTE_VISIT_CATEGORY_LIST,
    GET_ROUTE_LIST,
    GET_ROUTE_TITLE_LIST,
    CREATE_ROUTE,
    UPDATE_ROUTE,
    DOWNLOAD_REPORT,
};

export { query, mutation };
