import React, { useState } from 'react';
import { Search, Switch } from '../../../../../components';

const FocusedSkuDistribution = ({ ...props }) => {
  const {
    skuList, checkedSkus, getStatus, skus, handleSubmit,
  } = props;

  const [skuLists, setSkuLists] = useState(skuList);

  const handleInput = (text) => {
    const search = d => d.title.replace(/\s+/g, '').toLowerCase().search(text) !== -1;
    const filterData = skuList.filter(d => search(d));
    setSkuLists(filterData);
  };

  return (
    <div className="sbd-panel flex-1 sbd-table">
      <div className="sbd-panel-list">
        <div className="sbd-detail-table">
          <span className="info-text">
                          ↙↙ Please choose tabs and enable Focused SKU for each SKU.
          </span>
          <div className="dropdown-head">
            <h3>Focused SKU Distribution</h3>
            <Search handleSearchInput={handleInput} />
          </div>
          <div className="table-wrap">
            <table>
              <thead>
                <tr>
                  <th>SKU</th>
                  <th className="text-right">Status</th>
                </tr>
              </thead>
              <tbody>
                {skuLists.map(d => (
                  <tr key={d.id} className={checkedSkus.includes(d.id) ? 'list-active' : ''}>
                    <td>{d.title}</td>
                    <td className="text-right">
                      {<Switch
                        checked={getStatus(d.id) || skus.includes(d.id)}
                        onToggle={e => handleSubmit(d.id, e)}
                      />}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FocusedSkuDistribution;
