import * as arrayProcessor from './arrayProcessor';
import { EVENT_OPERATION } from '../data/enums/EventOperation';

/**
 * @function callBack : update state in Primary Component
 * @param getData: get the state value for data{list: [], total}
 * @param updateKeyList: keyList to update while editing
 * @param primaryKey: primary Key of Data List
 * @return {onCRUDSuccess}: return
 */
const crudResponseProcessor = (callBack, getData, updateKeyList = ['title'], primaryKey = 'id') => {
  const onCRUDSuccess = (type) => {
    switch (type) {
      case EVENT_OPERATION.CREATE:
      case EVENT_OPERATION.REPLICATE:
        return (response) => {
          const data = getData();
          data.list = arrayProcessor.add(data.list, response);
          data.total += 1;
          callBack(
            data,
            type,
          );
        };
      case EVENT_OPERATION.UPDATE:
        return (response) => {
          const data = getData();
          data.list = arrayProcessor.update(data.list, response, updateKeyList, primaryKey);
          callBack(
            data,
            type,
          );
        };
      case EVENT_OPERATION.DELETE:
        return (response) => {
          const data = getData();
          data.list = arrayProcessor.remove(data.list, response.ids);
          data.total -= 1;
          callBack(
            data,
            type,
          );
        };
      case EVENT_OPERATION.BULK_DELETE:
        return (response) => {
          const data = getData();
          data.list = arrayProcessor.remove(data.list, response.ids);
          data.total -= response.ids.length;
          callBack(
            data,
            type,
          );
        };
      case EVENT_OPERATION.UPDATE_STATUS:
        return (response) => {
          const data = getData();
          data.list = arrayProcessor.toggleState(data.list, response.ids || []);
          callBack(
            data,
            type,
          );
        };
      default:
        return (response) => {
          callBack(
            response,
            type,
          );
        };
    }
  };

  return onCRUDSuccess;
};

const responseInterpreter = (callBack) => {
  const onCRUDSuccess = type => (response, payload) => {
    callBack(
      type,
      response,
      payload,
    );
  };

  return onCRUDSuccess;
};

const handleFormSubmit = (successCallBack, failureCallBack, config, serverCall) => {
  const onFormSubmit = (type, entity = {}) => {
    let mapper = {};
    let transformedData = {};
    switch (type) {
      case EVENT_OPERATION.UPLOAD:
        mapper = config[EVENT_OPERATION[type]];
        mapper.objectMapper(entity)
          .then((data) => {
            serverCall[EVENT_OPERATION[type]](data, {
              handleSuccess: response => successCallBack(EVENT_OPERATION[type])(
                response.data[config[EVENT_OPERATION[type]].responseName],
              ),
              handleError: response => failureCallBack(response),
            });
          });
        break;
      case EVENT_OPERATION[type]:
        mapper = config[EVENT_OPERATION[type]];
        transformedData = mapper.objectMapper(entity);
        // hot fix
        if (!transformedData.type) {
          if (!transformedData.hasOwnProperty('status')) {
            transformedData.type = 'CatalogDetail';
          }
        }
        serverCall[EVENT_OPERATION[type]](transformedData, {
          handleSuccess: response => successCallBack(EVENT_OPERATION[type])(
            response.data[config[EVENT_OPERATION[type]].responseName], transformedData,
          ),
          handleError: response => failureCallBack(response),
        });
        break;
      default:
        break;
    }
  };

  return onFormSubmit;
};

export { crudResponseProcessor, handleFormSubmit, responseInterpreter };
