import { getFilterConfig } from '../../../../utils/filter';
import { FILTER } from '../../../../data/enums/filter';
import { CHANNEL_ASSORTMENT, configuration as configurationRoute } from '../../../../data/enums/Route';

const breadCrumb = [
  configurationRoute,
  {
    id: 2,
    title: 'Channel Assortment',
    path: `/${CHANNEL_ASSORTMENT}`,
    active: true,
  },
];

const filter = getFilterConfig([FILTER.STATUS]);


export { breadCrumb as breadCrumbConfig, filter as filterConfig };
