import { GET_SUB_D_ASSORTMENT } from '../API';
import { DOWNLOAD_REPORT } from '../../../common/GqlAPI';

const query = {
  getSubDAssortmentDetails: GET_SUB_D_ASSORTMENT,
};

const mutation = {
  downloadReport: DOWNLOAD_REPORT,
};

export { query, mutation };
