import { configuration } from '../../../data/enums/Route';

const title = 'Sub D Assortment';

const breadCrumb = [configuration];

export {
  breadCrumb as breadCrumbConfig,
  title as subDAssortmentTitle,
};
