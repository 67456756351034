import React, { Component } from 'react';
import { isError } from 'lodash';
import { PanelStyled } from '../../../common/configuration';
import PageHeader from '../../../base/PageHeader';
import { DOWNLOAD_DOMAIN } from '../../../../data/enums/GraphQL';
import * as queryService from '../../../base/query.service';
import View from './View';
import {
  breadCrumbConfig, filterConfig, title,
} from './config';
import { labelConfig } from './tableConfig';
import OutstandingWrapper from './OutstandingWrapper';
import { getOffsetFromPagination, apiFilterProcessor } from '../../../../utils/api';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import withAlert from '../../../../utils/composition/withAlert';
import { DialogFormWrapper } from '../../../common';
import confirmationGenerator from '../../../common/DialogConfirmation';

class Outstandings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        // date: { ...queryService.baseQueryParameters.date },
      },
      tableInfo: labelConfig,
      type: '',
      outletId: '',
    };
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      props.downloadReport,
      props.displayAlert,
    );
    this.getData = () => {
      const { data } = this.state;
      return data;
    };
  }

  componentDidMount = () => {
    this.loadTableData();
  }

    setQueryParameters = (queryParams, callBack = () => null) => this.setState(
      { queryParameters: queryParams },
      callBack,
    );

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

    columnSelectChange = (data) => {
      this.setState({
        tableInfo: data,
      });
    }

    onAPIRequestFailure = (error) => {
      const { displayAlert } = this.props;
      displayAlert(ALERT_TYPE.DANGER, error);
      console.log(error);
    };

    loadTableData = () => {
      const {
        queryParameters, data,
      } = this.state;
      const { getOutstandingsList } = this.props;
      const offset = getOffsetFromPagination(queryParameters.pagination);
      getOutstandingsList(
        {
          limit: queryParameters.pagination.limit,
          offset,
          filter: {
            filters: [...apiFilterProcessor(queryParameters.filter)],
            dateRange: queryParameters.date,
            queryString: queryParameters.search,
          },
        },
        {
          handleSuccess: (response) => {
            data.list = (response.data.outstandingReports && response.data.outstandingReports.rows) || [];
            data.total = (response.data.outstandingReports && response.data.outstandingReports.count);
            if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
            this.setState(data);
          },
          handleError: error => this.onAPIRequestFailure(error),
        },
      );
    };

    onDialogSubmit = () => {
      const { updateRetailOutletTransactionStatus, displayAlert } = this.props;
      const { outletId, type, data } = this.state;
      updateRetailOutletTransactionStatus({
        id: outletId,
        transactionStatus: type,
      }, {
        handleSuccess: (response) => {
          const responseData = response.data.updateRetailOutletTransactionStatus;
          data.list.forEach((d) => {
            if (d.id === responseData.id) {
              d.transactionStatus = responseData.transactionStatus;
            }
          });
          this.setState({
            data,
          }, () => {
            displayAlert(ALERT_TYPE.SUCCESS, 'Successfully Updated');
          });
        },
        handleError: err => this.onAPIRequestFailure(err),
      });
    }

    onStatusChanges = (type, data) => {
      this.setState({
        type,
        outletId: data.id,
      });
    }

    render() {
      const {
        data,
        queryParameters,
        tableInfo,
        type,
      } = this.state;
      const { serverResponseWaiting } = this.props;
      return (
        <OutstandingWrapper>
          <div className="section-header">
            <PanelStyled>
              <PageHeader
                breadCrumb={breadCrumbConfig}
                config={{
                  title,
                  create: false,
                  download: true,
                  filter: true,
                  upload: false,
                  date: false,
                  search: true,
                  columSelect: true,
                }}
                downloadConfig={{
                  domain: DOWNLOAD_DOMAIN.OUTSTANDING_REPORT,
                }}
                filter={{
                  // date: queryParameters.date,
                  menuList: filterConfig.menu,
                  onFilterChange: this.basePaginationService.handleFilterChange,
                }}
                labelConfig={tableInfo}
                queryParameters={queryParameters}
                columnSelectChange={this.columnSelectChange}
                controlDisplay={this.controlDisplay}
                handleCreateClick={this.onReceiptsClick}
                resetFilter={this.basePaginationService.resetFilter}
                clearSearchText={this.basePaginationService.clearSearchText}
                handleSearchChange={this.basePaginationService.handleSearchInputChange}
                handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
                handleDownloadClick={this.basePaginationService.handleDownloadClick}
              />
            </PanelStyled>
          </div>
          {type && (
          <DialogFormWrapper
            formConfig={{ title: 'Update Status' }}
            onDialogSubmit={() => this.onDialogSubmit()}
            onDialogCancel={() => { this.setState({ type: '' }); }}
            type={type}
            renderDialog={() => (
                <>
                  {
                     confirmationGenerator(type)
                    }
                </>
            )}
          />
          )
          }
          <div className="section-content">
            <View
              data={data}
              pagination={queryParameters.pagination}
              loading={serverResponseWaiting || false}
              onPageChange={this.basePaginationService.onPageSelect}
              tableConfig={tableInfo}
              onStatusChanges={this.onStatusChanges}
            />
          </div>
        </OutstandingWrapper>
      );
    }
}

export default withAlert()(Outstandings);
