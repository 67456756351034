import gql from 'graphql-tag';
import { DOWNLOAD_REPORT, GET_DISTRIBUTORS, UPLOAD } from '../../common/GqlAPI';
import { SKU_FRAGMENT } from '../../configuration/sku/API';

const OPENING_BALANCE_FRAGMENT = gql`
    fragment OpeningBalanceFragment on OpeningBalance {
        id
        SKU {
            ...SkuFragment
        }
        distributorId
        balance
    }
    ${SKU_FRAGMENT}
`;

const GET_OPENING_BALANCE_LIST = gql`
    query getOpeningBalanceList($offset: Int, $limit: Int, $filter: FilterInput) {
        openingBalance(offset: $offset, limit: $limit, filter: $filter){
            rows{
                ...OpeningBalanceFragment
            }
            count
        }
    }
    ${OPENING_BALANCE_FRAGMENT}
`;

const query = {
  getDistributors: GET_DISTRIBUTORS,
  getOpeningBalanceList: GET_OPENING_BALANCE_LIST,
};
const mutation = { upload: UPLOAD, downloadReport: DOWNLOAD_REPORT };

export { query, mutation };
