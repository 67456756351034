import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CheckBox } from '../../../components';
import { normalPresentor } from '../../../utils/date';
import { tableHeaderWithCheckBox } from '../../../utils/paginatedListUtils';
import { uniqueArrayElementString } from '../../../utils/utilities';

const labelMappings = {
  checkbox: 'checkbox',
  id: 'id',
  title: 'title',
  status: 'status',
  type: 'type',
  brand: 'brand',
  startDate: 'startDate',
  endDate: 'endDate',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.checkbox,
    title: '',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.id,
    title: 'ID',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.title,
    title: 'Title',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.status,
    title: 'Status',
    show: true,
    classNames: 'text-center',
  },
  {
    id: 5,
    label: labelMappings.type,
    title: 'Type',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.brand,
    title: 'Brand',
    show: true,
  },
  {
    id: 7,
    label: labelMappings.startDate,
    title: 'Start Date',
    show: true,
  },
  {
    id: 8,
    label: labelMappings.endDate,
    title: 'End Date',
    show: true,
  },
];

const TableHeader = ({
  dataListLength, checkedListLength, primaryCheckboxHandler, permission,
}) => tableHeaderWithCheckBox(
  dataListLength,
  checkedListLength,
  primaryCheckboxHandler,
  permission,
  labelConfig,
  labelMappings,
);


const returnTableBody = (
  data,
  checkedList,
  onSecondaryCheckBoxClick,
  permission,
) => {
  const table = {};
  table[labelMappings.checkbox] = permission.delete && (
    <td onClick={e => e.stopPropagation()}>
      <CheckBox
        checked={checkedList.includes(data.id)}
        onCheckBoxClick={() => onSecondaryCheckBoxClick(data.id)}
      />
    </td>
  );
  table[labelMappings.id] = (<td key={`${data.id}-id`}>{data.id}</td>
  );
  table[labelMappings.title] = (
    <td key={`${data.id}-title`}>{data.title}</td>
  );
  table[labelMappings.status] = (
    <td key={`${data.id}-status`} className="text-center">
      <span
        className={`status-label ${
          data.active ? 'active' : 'passive'
        }`}
      >
        {data.active ? 'ACTIVE' : 'INACTIVE'}
      </span>
    </td>);
  table[labelMappings.type] = (
    <td key={`cash-${data.id}-type`}>{data.type.replace(/_/g, ' ')}</td>);
  table[labelMappings.brand] = (
    <td key={`cash-${data.id}-brand`} className="capitalize">
      {uniqueArrayElementString(
        data.skus.map(d => d.brand.title),
      ).replace(',', '/')}
    </td>);
  table[labelMappings.startDate] = (
    <td key={`cash-${data.id}-startDate`}>{normalPresentor(data.startDate)}</td>);
  table[labelMappings.endDate] = (
    <td key={`cash-${data.id}-endDate`}>{normalPresentor(data.endDate)}</td>
  );

  return table;
};

const TableBody = ({
  data,
  checkedList,
  secondaryCheckboxHandler,
  permission,
}) => {
  // Expanding/Dragging and collapsing table body.
  // color based upon table body
  // tooltip if it is sorted.
  // active className

  const tableBody = returnTableBody(
    data,
    checkedList,

    secondaryCheckboxHandler,
    permission,
  );

  return (
    <tr>
      <Link to={`promotion/details/${data.id}`}>
        {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
      </Link>
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  checkedList: PropTypes.instanceOf(Array),
};

TableBody.defaultProps = {
  checkedList: [],
};

export { TableHeader, TableBody };
