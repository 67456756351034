import {
  compose,
} from 'recompose';
import Image from './Image';

import withRequestTracker from '../../../../../../utils/composition/withRequestTracker';

import {
  query,
} from '../General/API';

const ComposedRetailOutlet = compose(
  withRequestTracker({
    query,
  }),
)(Image);

export default ComposedRetailOutlet;
