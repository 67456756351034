import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { PanelHeader, PanelStyled } from '../../../../common/configuration';
import {
  BreadCrumb, Button,
} from '../../../../../components';
import withAlert from '../../../../../utils/composition/withAlert';
import {
  breadCrumbConfig,
  formConfig as form,
} from './config';
import { RECEIPTS } from '../../../../../data/enums/Route';
import { refValidator, refGenerator } from '../../../../../utils/refGenerator';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import View from './View';
import { dropdownChange } from '../../../../../utils/formHandlers';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import ReceiptWrapper from '../ReceiptWrapper';
import { OFFSET } from '../../../../../data/enums/GeneralConstants';
import history from '../../../../../utils/history';

const propTypes = {
  createPayment: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  serverResponseWaiting: false,
};
class Create extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: form.mapper({}),
      enableErrorDisplay: false,
      menu: {
        collectionType: [],
        routeList: [],
        outletList: [],
        roleList: [],
        userList: [],
        bankList: [],
      },
      crudMode: EVENT_OPERATION.CREATE,

    };

    this.formReference = refGenerator(form.validationField);
  }

  componentDidMount() {
    this.loadDataForDropDown();
  }

   fetchOutlets = () => {
     const { getOutlets } = this.props;
     const { data } = this.state;
     getOutlets({
       limit: 1000,
       offset: OFFSET,
       filter: {
         filters: [{
           column: 'route_id',
           value: [data.RetailOutlet.toString()],
         }],
       },
     }, {
       handleSuccess: (res) => {
         const { menu } = this.state;
         menu.outletList = [...(res.data.retailOutlets && (res.data.retailOutlets.rows || []))
          || []];
         this.setState({ menu });
       },
       handleError: err => this.onAPIRequestFailure(err),
     });
   };

   loadRouteList = () => {
     const { getRouteList } = this.props;

     getRouteList({
       offset: 0,
       limit: 5000,
     }, {
       handleSuccess: (res) => {
         const { menu } = this.state;
         menu.routeList = [...(res.data.routes && (res.data.routes.rows || []))
             || []];
         this.setState({ menu });
       },
       handleError: err => this.onAPIRequestFailure(err),
     });
   };

   loadRoleList = () => {
     const { getRoles } = this.props;

     getRoles({
       offset: 0,
       limit: 5000,
     }, {
       handleSuccess: (res) => {
         const { menu } = this.state;
         menu.roleList = [...(res.data.roles && (res.data.roles.rows || []))
             || []];
         this.setState({ menu });
       },
       handleError: err => this.onAPIRequestFailure(err),
     });
   };

   fetchUsers = () => {
     const { getUsers } = this.props;
     const { data } = this.state;
     getUsers({
       limit: 1000,
       offset: OFFSET,
       filter: {
         filters: [{
           column: 'role_id',
           value: [data.Role.toString()],
         }],
       },
     }, {
       handleSuccess: (res) => {
         const { menu } = this.state;
         menu.userList = [...(res.data.users && (res.data.users.rows || []))
          || []];
         this.setState({ menu });
       },
       handleError: err => this.onAPIRequestFailure(err),
     });
   };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
    console.log(error);
  };

    loadDataForDropDown = () => {
      const { menu } = this.state;
      const collectionListType = [
        { title: 'CASH', id: 1, value: 'CASH' },
        { title: 'CHEQUE', id: 2, value: 'CHEQUE' },
        { title: 'POST DATED CHEQUE', id: 3, value: 'POST_DATED_CHEQUE' },
      ];
      menu.collectionType = collectionListType;
      this.setState({ menu });
      this.loadRouteList();
      this.loadRoleList();
      this.fetchBank();
    };

handleInputChange = (event, firstParam = '', paramList = []) => {
  const { data } = this.state;
  if (firstParam) {
    if (paramList.length > 1) {
      paramList.reduce((acc, value, index, list) => {
        if (index === list.length - 1) {
          return acc[value] = event.formattedValue;
        }
        return acc[value];
      }, data);
    } else {
      data[firstParam][event.target.name] = event.formattedValue;
    }
  } else {
    data[event.target.name] = event.formattedValue;
  }
  this.setState({ data });
};

    handleDropDownChange = (value, parameterRef = [], name, callBack = () => null) => {
      const { data } = this.state;

      const updatedData = dropdownChange(data, parameterRef, value);
      switch (name) {
        case 'route':
          this.setState({ data: updatedData }, () => this.fetchOutlets());
          break;
        case 'role':
          this.setState({ data: updatedData }, () => this.fetchUsers());
          break;
        case 'bank':
          this.setState({ data: updatedData });
          break;
        default:
          this.setState({ data: updatedData });
          break;
      }
    };

    fetchBank=() => {
      const { getBankList } = this.props;
      getBankList(
        {},
        {
          handleSuccess: (res) => {
            const { menu } = this.state;
            menu.bankList = [...(res.data.banks)
             || []];
            this.setState({ menu });
          },
          handleError: err => this.onAPIRequestFailure(err),
        },
      );
    }

      handleImagesFetch = (images) => {
        const { data } = this.state;
        this.setState({
          data: {
            ...data,
            details: {
              ...data.details,
              image: images,
            },
          },
        });
      }

      transformData = data => ({
        outletId: data.outletId,
        collectionType: data.collectionType,
        amount: data.amount,
        remarks: data.remarks,
        date: data.date,
        collectorId: data.User,
        details: {
          chequeNumber: data.details ? data.details.chequeNumber.toString() : 0,
          bank: data.details ? data.details.bank : '',
          image: data.details ? data.details.image : [],
          valuedDate: data.details ? data.details.valuedDate : '',
        },
      });

     handleButtonSubmit = () => {
       const { data, crudMode } = this.state;
       const { createPayment, displayAlert } = this.props;
       const formValidation = refValidator(this.formReference);
       if (!formValidation) {
         this.setState({ enableErrorDisplay: true });
       } else {
         const formattedData = this.transformData(data);

         if (crudMode === EVENT_OPERATION.CREATE) {
           createPayment(
             {
               input: formattedData,
             },
             {
               handleSuccess: (res) => {
                 displayAlert(ALERT_TYPE.SUCCESS, 'Receipt Created');
                 this.setState({ crudMode: EVENT_OPERATION.READ });
                 setTimeout(() => {
                   history.push(`/${RECEIPTS}`);
                 }, 900);
               },
               handleError: (err) => {
                 this.onAPIRequestFailure(err);
               },
             },
           );
         }
       }
     };

      handleButtonCancel = () => {
        const { crudMode } = this.state;
        if (crudMode === EVENT_OPERATION.CREATE) {
          this.setState({
            data: form.mapper({}),
          });
        } else {
          this.setState({ crudMode: EVENT_OPERATION.READ });
        }
      };

      render() {
        const { data, enableErrorDisplay, menu } = this.state;
        const {
          serverResponseWaiting,
        } = this.props;

        return (
          <ReceiptWrapper>
            <div className="section-header">
              <PanelStyled>
                <BreadCrumb list={breadCrumbConfig} />
                <PanelHeader>
                  <h2>Create Receipt</h2>
                  <div className="flex m-0">
                    {
                      <div>
                        <Button
                          small
                          secondary
                          onClick={() => this.handleButtonCancel()}
                        >
                          <span>
                      Cancel
                          </span>
                        </Button>
                        <Button
                          small
                          primary
                          onClick={() => this.handleButtonSubmit()}
                          disabled={serverResponseWaiting}
                        >
                          <span>
                      Save
                          </span>
                        </Button>
                      </div>
                  }

                  </div>
                </PanelHeader>
              </PanelStyled>
              <div className="section-content pad-48">
                <View
                  data={data}
                  menu={menu}
                  loading={serverResponseWaiting}
                  onDropDownChange={this.handleDropDownChange}
                  onInputChange={this.handleInputChange}
                  handleImagesFetch={this.handleImagesFetch}
                  refsObj={this.formReference}
                  enableErrorDisplay={enableErrorDisplay}
                />
              </div>
            </div>
          </ReceiptWrapper>
        );
      }
}

Create.propTypes = propTypes;

Create.defaultProps = defaultProps;

export default withAlert()(Create);
