import gql from 'graphql-tag';
import { DOWNLOAD_REPORT } from '../../common/GqlAPI';

const GET_DIST_COVERAGE_LIST = gql`
    query getDistributionCoverage($offset: Int, $limit: Int, $filter: FilterInput) {
        getDistributionCoverage(offset: $offset, limit: $limit, filter: $filter) {
            rows {
                route
                distributor
                town
                coverage_outlets
                active_outlets
                active_percent
            }
            count
        }
    }
`;

const query = {
  getDistributionCoverage: GET_DIST_COVERAGE_LIST,
};

const mutation = {
  downloadReport: DOWNLOAD_REPORT,
};

export {
  DOWNLOAD_REPORT,
};

export { query, mutation };
