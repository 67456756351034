import {
  compose,
} from 'recompose';
import Dialog from './Dialog';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';
import {
  query,
} from '../../route/API';

const ComposedRoute = compose(
  withRequestTracker({
    query,
  }),
)(Dialog);

export default ComposedRoute;
