import { sales as salesRoute, collection as collectionRoute } from '../../../../data/enums/Route';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { FILTER } from '../../../../data/enums/filter';
import { getFilterConfig } from '../../../../utils/filter';

const title = 'Cheques';

const breadCrumbConfig = [
  salesRoute,
  collectionRoute,
];

const CHEQUES_TABS = {
  PENDING: 'pending',
  RECEIVED: 'cheque_received',
  RECONCILED: 'reconciled',
  DISHONORED: 'dishonored',
};

const tabMapper = {
  0: CHEQUES_TABS.PENDING,
  1: CHEQUES_TABS.RECEIVED,
  2: CHEQUES_TABS.RECONCILED,
  3: CHEQUES_TABS.DISHONORED,
};

const filterConfig = getFilterConfig([FILTER.SUB_D,
  FILTER.ROUTE, FILTER.DSE,
  FILTER.AMOUNT,
  FILTER.BANK,
]);

const formConfig = {
  [EVENT_OPERATION.UPDATE]: {
    title: 'Add Alias ',
  },
};


export {
  title, breadCrumbConfig, tabMapper, CHEQUES_TABS, filterConfig, formConfig,
};
