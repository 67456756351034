import React from 'react';
import { Input } from '../../../../../../components';
import withErrorAndLoadingScreen from '../../../../../../utils/composition/withLoading';
import FormContentStyled from '../../../../../../components/global-css/FormContentStyled';


const ReplenishmentModelView = ({ data, onInputChange }) => (
  <div className="panel-section create-ui-inner ">
    <FormContentStyled>
      <h3>General Settings</h3>
      <div className="form-input-wrap">
        <Input
          value={data.stockDaysHolding}
          name="stockDaysHolding"
          type="number"
          rule="matches"
          labelContent="Days of Stock Holding:"
          argument={
                          /^[0-9]*$/
                        } /* matches alphanumeric, underscore ,'-' & ' ' */
          placeholder="0"
          enableErrorDisplay={false}
          enableValidation
          onChange={(e) => { onInputChange(e, 'stockDaysHolding', ['stockDaysHolding']); }}
        />
        <Input
          value={data.availabilityDays}
          name="availabilityDays"
          type="number"
          rule="matches"
          labelContent="Availability Days:"
          argument={
                          /^[0-9]*$/
                        } /* matches alphanumeric, underscore ,'-' & ' ' */
          placeholder="0"
          enableErrorDisplay={false}
          enableValidation
          onChange={(e) => { onInputChange(e, 'availabilityDays', ['availabilityDays']); }}
        />
        <Input
          value={data.averageDays}
          name="averageDays"
          type="number"
          rule="matches"
          labelContent="Average Days:"
          argument={
                          /^[0-9]*$/
                        } /* matches alphanumeric, underscore ,'-' & ' ' */
          placeholder="0"
          enableErrorDisplay={false}
          enableValidation
          onChange={(e) => { onInputChange(e, 'averageDays', ['averageDays']); }}
        />
      </div>
    </FormContentStyled>
    <FormContentStyled>
      <h3>Sufficiency Days</h3>
      <div className="form-input-wrap">
        <Input
          value={data.sufficiencyDays ? data.sufficiencyDays.min : 0}
          name="min"
          type="number"
          rule="matches"
          labelContent="Minimum Days"
          argument={
                          /^[0-9]*$/
                        } /* matches alphanumeric, underscore ,'-' & ' ' */
          placeholder="0"
          enableErrorDisplay={false}
          enableValidation
          onChange={(e) => { onInputChange(e, 'sufficiencyDays', ['sufficiencyDays', 'min']); }}
        />
        <Input
          value={data.sufficiencyDays ? data.sufficiencyDays.max : 0}
          name="max"
          type="number"
          rule="matches"
          labelContent="Maximum Days"
          argument={
                          /^[0-9]*$/
                        } /* matches alphanumeric, underscore ,'-' & ' ' */
          placeholder="0"
          enableErrorDisplay={false}
          enableValidation
          onChange={(e) => { onInputChange(e, 'sufficiencyDays', ['sufficiencyDays', 'max']); }}
        />
      </div>
    </FormContentStyled>
    <FormContentStyled>
      <h3>Sufficiency</h3>
      <div className="form-input-wrap">
        <Input
          value={data.sufficiencyMatrix.under}
          name="under"
          type="number"
          rule="matches"
          labelContent={`Below ${data.sufficiencyDays.min === '' ? 'x' : data.sufficiencyDays.min} Days`}
          argument={
                          /^[0-9]*$/
                        } /* matches alphanumeric, underscore ,'-' & ' ' */
          placeholder="0"
          enableErrorDisplay={false}
          enableValidation
          onChange={(e) => { onInputChange(e, 'sufficiencyMatrix', ['sufficiencyMatrix', 'under']); }}
        />
        <Input
          value={data.sufficiencyMatrix.ideal}
          name="ideal"
          type="number"
          rule="matches"
          labelContent={`${data.sufficiencyDays.min === '' ? 'x' : data.sufficiencyDays.min} - 
          ${data.sufficiencyDays.max === '' ? 'x' : data.sufficiencyDays.max} days`}
          argument={
                          /^[0-9]*$/
                        } /* matches alphanumeric, underscore ,'-' & ' ' */
          placeholder="0"
          enableErrorDisplay={false}
          enableValidation
          onChange={(e) => { onInputChange(e, 'sufficiencyMatrix', ['sufficiencyMatrix', 'ideal']); }}
        />
        <Input
          value={data.sufficiencyMatrix.over}
          name="over"
          type="number"
          rule="matches"
          labelContent={`${data.sufficiencyDays.max === '' ? 'x' : data.sufficiencyDays.max} and above`}
          argument={
                          /^[0-9]*$/
                        } /* matches alphanumeric, underscore ,'-' & ' ' */
          placeholder="0"
          enableErrorDisplay={false}
          enableValidation
          onChange={(e) => { onInputChange(e, 'sufficiencyMatrix', ['sufficiencyMatrix', 'over']); }}
        />
      </div>
    </FormContentStyled>
    <FormContentStyled>
      <h3>Score Weightage</h3>
      <div className="form-input-wrap">
        <Input
          value={data.sufficiencyWeightage.availability}
          name="availability"
          type="number"
          rule="matches"
          labelContent="Availability:"
          argument={
                          /^[0-9]*$/
                        } /* matches alphanumeric, underscore ,'-' & ' ' */
          placeholder="0"
          enableErrorDisplay={false}
          enableValidation

          onChange={(e) => { onInputChange(e, 'sufficiencyWeightage', ['sufficiencyWeightage', 'availability']); }}
        />
        <Input
          value={data.sufficiencyWeightage.sufficiency}
          name="sufficiency"
          type="number"
          rule="matches"
          labelContent="Sufficiency:"
          argument={
                          /^[0-9]*$/
                        } /* matches alphanumeric, underscore ,'-' & ' ' */
          placeholder="0"
          enableErrorDisplay={false}
          enableValidation

          onChange={(e) => { onInputChange(e, 'sufficiencyWeightage', ['sufficiencyWeightage', 'sufficiency']); }}
        />
        <Input
          value={data.sufficiencyWeightage.overStock}
          name="overStock"
          type="number"
          rule="matches"
          labelContent="Overstock:"
          argument={
                          /^[0-9]*$/
                        } /* matches alphanumeric, underscore ,'-' & ' ' */
          placeholder="0"
          enableErrorDisplay={false}
          enableValidation
          onChange={(e) => { onInputChange(e, 'sufficiencyWeightage', ['sufficiencyWeightage', 'overStock']); }}

        />
      </div>
    </FormContentStyled>
  </div>
);

export default withErrorAndLoadingScreen(ReplenishmentModelView);
