import { createContext } from '../../../../utils/context';
import { configuration, SUB_D_ASSORTMENT } from '../../../../data/enums/Route';

const SubDAssortmentPermissionContext = createContext();

const breadCrumbConfig = [
  configuration,
  {
    id: 1,
    title: 'Sub D Assortment',
    path: `/${SUB_D_ASSORTMENT}`,
    active: true,
  },
];
const dataMapper = (element, id) => ({
  id: element.id || id || '',
  title: element.title || 'Sub D Group',
})
export { SubDAssortmentPermissionContext as PermissionContext, dataMapper, breadCrumbConfig  };
