import PropTypes from 'prop-types';
import React, { Component } from 'react';

import PromotionInfo from './info/View';
import PromotionScope from './scope';
import PromotionCondition from './conditions';
import ProductCatalog from './scope/ProductCatalog';
import PromotionDisbursement from './disbursements';
import { getCurrentDay } from '../../../../utils/date';
import { has } from '../../../../utils/objectPrototypes';
import { clone } from '../../../../utils/objectProcessor';
import { SKU_CATALOG_LEVEL } from '../../../../data/enums';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import withAlert from '../../../../utils/composition/withAlert';
import { PROMOTION } from '../../../../data/enums/Route';
import { BreadCrumb, Button, Icon } from '../../../../components';
import { getPermissionForPromotion } from '../../../base/permission';
import { EVENT_OPERATION, EVENT_OPERATION_MAPPER } from '../../../../data/enums/EventOperation';
import { PanelHeader, PanelStyled } from '../../../common/configuration';
import { refGenerator, refValidator } from '../../../../utils/refGenerator';
import { inputChange, dropdownChange } from '../../../../utils/formHandlers';
import { getDataFromLocalStorage, CLIENT_STORAGE_TABLE } from '../../../../data/services';
import {
  title,
  breadCrumbConfig,
  form as formConfig,
  stateToRequestProcessor,
  responseToStateProcessor,
  CUSTOM_SKU_GROUPS,
} from './config';
import PromotionDetailStyled from './PromotionDetailStyled';
import PanelCard from '../../../../components/Cards/PanelCard';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  updatePromotion: PropTypes.func.isRequired,
  createPromotion: PropTypes.func.isRequired,
  getPromotionDetail: PropTypes.func.isRequired,
  getFocusedSKUListWithTitle: PropTypes.func.isRequired,
  getSBDListWithTitle: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};


const promotionCriteriaBaseForCustomGroups = {
  operation: {
    max: {
      value: 0,
      // greater than equals
      operator: 'GREATER_THAN_EQUALS', // PROMOTION_CRITERIA_OPERATION.filter(el => el.id === 2).value,
    },
    min: {
      value: 0,
      // greater than equals
      operator: 'GREATER_THAN_EQUALS', // PROMOTION_CRITERIA_OPERATION.filter(el => el.id === 2).value,
    },
  },
  dimension: 'QUANTITY', // PROMOTION_CRITERIA_DIMENSION.filter(el => el.id === 1).value,
  skuCount: 1,
  allowMultiple: true,
};

const promotionScopeBase = {
  categoryIds: [],
  channelCategoryInfo: {
    channelId: null,
    categoryIds: [],
  },
  catalogInfo: {
    catalogId: 8,
    catalogDetailIds: [],
    applicableSkus: [],
  },
  customGroupDetails: {
    sku: {
      id: null,
      criteria: promotionCriteriaBaseForCustomGroups,
    },
    criteria: promotionCriteriaBaseForCustomGroups,
  },
};

const customGroupBase = {
  skus: [promotionScopeBase.customGroupDetails.sku],
  criteria: promotionScopeBase.customGroupDetails.criteria,
};

const getPromotionId = props => ((props && props.match && props.match.params && has.call(props.match.params, 'id'))
  ? parseInt(props.match.params.id, 10) : 0);

const getPromotionInitialState = (props = null) => ({
  info: {
    promotionId: getPromotionId(props),
    title: '',
    startDate: getCurrentDay(),
    endDate: getCurrentDay(),
    active: true,
    description: '',
  },
  scope: {
    categoryList: [],
    catalogGroupId: [],
    channelList: [],
    type: '', // Normal, Current Bill, Next Bill, Reimbursement
    relatedType: '', // Focused SKU, Disbursement
    // applicableSkus: [],
    relatedId: '',
    promotionAreas: {
      subDIdList: [],
      townIdList: [],
    },
    catalogInfo: [
      {
        ...promotionScopeBase.catalogInfo,
      },
    ],
    channelCategoryInfo: [
      {
        ...promotionScopeBase.channelCategoryInfo,
      },
    ],
    customGroupDetails: [
      /** {
        skus: [promotionScopeBase.customGroupDetails.sku],
        criteria: promotionScopeBase.customGroupDetails.criteria,
      }, */
    ],
    customGroupStatus: false,
    categoryIds: [],
  },
  criteria: {
    dimension: 'QUANTITY', // promotion criteria dimension
    operation: {
      max: {
        value: 1,
        operator: 'EQUALS', // promotion criteria operator
      },
      min: {
        value: 1,
        operator: 'EQUALS', // promotion criteria operator
      },
    },
    type: 'NORMAL',
    skuCount: 1,
    allowMultiple: false,
  },
  disbursement: {
    dimension: 'FREE_SKU', // PROMOTION disbursement type
    value: 1,
    // catalogId: 0,
    // freeSkuId: [], // 1,2,3,4,5
    freeSKUs: [],
    allowMultiple: false,
    searchText: '',
  },
  menu: {
    catalogList: [],
    scopeCatalogChildren: [],
    scopeCatalogGroupChildren: [],
    channelList: [],
    categoryList: [],
    disbursementCatalogChildren: [],
    focusedSKUList: [],
    sbdList: [],
    subDList: [],
    townList: [],
  },
  displayErrorInForm: false,
  crudMode: getPromotionId(props) ? EVENT_OPERATION.READ : EVENT_OPERATION.CREATE,
});

class PromotionDetail extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = getPromotionInitialState(props);
    this.permission = getPermissionForPromotion();
    this.formReference = {
      info: refGenerator(formConfig.validationField.info),
      disbursement: refGenerator(formConfig.validationField.disbursement),
      scope: refGenerator(formConfig.validationField.scope),
    };
    const serverCall = {
      [EVENT_OPERATION.CREATE]: props.createPromotion,
      [EVENT_OPERATION.UPDATE]: props.updatePromotion,
    };

    this.onFormSubmit = (crudMode, data) => {
      // eslint-disable-next-line react/prop-types
      const { history } = this.props;
      serverCall[crudMode](data, {
        handleSuccess: (res) => {
          if (crudMode === EVENT_OPERATION.UPDATE) {
            const id = res.data.updatePromotion.id;
            history.push(`/${PROMOTION}`);
          }

          if (crudMode === EVENT_OPERATION.CREATE) {
            const promotionId = res.data.createPromotion.id;
            history.push(`/${PROMOTION}`);
          }
        },
        handleError: (error) => {
          this.onAPIRequestFailure(error);
        },
      });
    };
  }

  componentDidMount() {
    const { info: { promotionId } } = this.state;

    if (promotionId) {
      this.getData(promotionId);
    }

    this.loadData();
    // this.loadFocusedSKU();
    // this.loadSBDList();
  }

  getState = () => this.state;

  getData = (id) => {
    const { getPromotionDetail } = this.props;
    getPromotionDetail({ id: id.toString() }, {
      handleSuccess: (response) => {
        const data = response.data.promotions.rows[0];
        const state = this.getState();
        const updatedState = responseToStateProcessor(data, state);
        updatedState.crudMode = EVENT_OPERATION.READ;
        const { catalogInfo: { catalogId } } = data;
        const promotionScopeCatalogLevel = catalogId || SKU_CATALOG_LEVEL;
        this.updateMenuForPromotionScopeCatalogChildren(promotionScopeCatalogLevel);

        this.setState(updatedState);
      },
      handleError: (error) => {
        this.onAPIRequestFailure(error);
      },
    });
  };

  loadFocusedSKU = () => {
    const { menu } = this.state;
    const { getFocusedSKUListWithTitle } = this.props;

    getFocusedSKUListWithTitle({
      limit: 100,
      offset: 0,
    }, {
      handleSuccess: (response) => {
        menu.focusedSKUList = response.data.focusedSKUs ? response.data.focusedSKUs.rows || [] : [];
        this.setState({ menu });
      },
      handleError: (error) => {
      },
    });
  };

  loadSBDList = () => {
    const { getSBDListWithTitle } = this.props;
    const { menu } = this.state;

    getSBDListWithTitle({
      limit: 100,
      offset: 0,
    }, {
      handleSuccess: (response) => {
        menu.sbdList = response.data.sbds.rows;
        this.setState({ menu });
      },
      handleError: (error) => {
      },
    });
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleDateRangeChange = (label, value) => {
    const { info } = this.state;
    info[label] = value;
    this.setState({ info });
  };

  handleDropDownChange = label => (value, parameterRef = [], callBack = () => null) => {
    const { [label]: previousState } = this.state;
    const updatedState = dropdownChange(previousState, parameterRef, value);
    this.setState({ [label]: updatedState });
  };

  handleAutoCompleteChange = key => (value, fieldName, index) => {
    const { [key]: keyValue } = this.state;

    // Refactor with switch case
    if (key === 'scope' && fieldName === 'catalogInfo') {
      keyValue.catalogInfo[index].catalogDetailIds = [...value];
    } else if (key === 'scope' && fieldName === 'applicableSkus') {
      keyValue.catalogInfo[index].applicableSkus = [...value];
    } else if (key === 'scope' && fieldName === CUSTOM_SKU_GROUPS.SKU) {
      keyValue.customGroupDetails[index].skus = [...value]; // {id: null, criteria: {.....}}
    } else if (key === 'scope' && fieldName === CUSTOM_SKU_GROUPS.HEADER) {
      keyValue.customGroupDetails[index].criteria = value; // {id: null, criteria: {.....}}
    } else if (key === 'scope' && fieldName === 'categoryList') {
      keyValue.categoryIds = [...value];
    } else if (key === 'scope' && fieldName === 'channelCategoryInfo') {
      const newValue = value.map(va => va);
      keyValue.channelCategoryInfo[index].categoryIds = [...newValue];
    } else {
      [keyValue][fieldName] = value;
    }

    this.setState({ [key]: keyValue });
  };

  handleInputChange = label => (event, firstParam = '', paramList = []) => {
    const { [label]: prevState } = this.state;
    const updatedDetails = inputChange(prevState, event, firstParam, paramList);
    this.setState({ [label]: updatedDetails });
  };

  updateMenuForPromotionScopeCatalogChildren = (level) => {
    const { menu } = this.state;
    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.CATALOG_CHILDREN, level)
      .then(
        (response) => {
          menu.scopeCatalogChildren = response;
          this.setState({
            menu,
          });
        },
      );
  };

  updateMenuForPromotionScopeGroupCatalogChildren = (level, index) => {
    const { menu } = this.state;
    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.CATALOG_CHILDREN, level)
      .then(
        (response) => {
          menu.scopeCatalogGroupChildren.splice(index, 0, response);
          this.setState({
            menu,
          });
        },
      );
  };

  handleScopeDropDownChange = (value, index = 0, field = 'catalogInfo') => {
    const { scope } = this.state;

    if (field === 'catalogInfo') {
      scope[field][index].catalogId = value;
      scope[field][index].catalogDetailIds = [];
      this.updateMenuForPromotionScopeCatalogChildren(value);
    } else if (field === 'relatedType') {
      scope[field] = value;
      scope.relatedId = '';
    } else if (field === 'promotionAreas') {
      scope[field][index] = value;
    } else if (field === 'channelCategoryInfo') {
      scope.channelCategoryInfo[index].channelId = value;
      scope.channelCategoryInfo[index].categoryIds = [];
    } else if (field === 'customGroupDetails') {
      scope.catalogGroupId[index] = value;
      this.updateMenuForPromotionScopeGroupCatalogChildren(value, index);
    } else {
      scope[field] = value;
    }

    this.setState({ scope });
  };

  handleDisbursementDropDownChange = (value, parameterRef = []) => {
    const { disbursement, menu } = this.state;

    if (parameterRef[0] === 'catalogId') {
      disbursement.catalogId = value;
      disbursement.freeSkuId = [];
      disbursement.searchText = '';
      // this.setState({ disbursement });
      getDataFromLocalStorage(CLIENT_STORAGE_TABLE.CATALOG_CHILDREN, value)
        .then((response) => {
          menu.disbursementCatalogChildren = response;
          this.setState({
            menu,
            disbursement,
          });
        });
    } else {
      this.handleDropDownChange('disbursement')(value, parameterRef);
    }
  };

  handleButtonSubmit = () => {
    // edit or create based upon the mode
    const { crudMode } = this.state;
    const updatedData = stateToRequestProcessor(this.state);
    const { formReference } = this;
    // call the request processor over here.
    this.setState({ displayErrorInForm: true }, () => {
      const validationOnInfo = refValidator(formReference.info);
      const validationOnDisbursement = refValidator(formReference.disbursement);
      const validationOnScope = refValidator(formReference.scope);
      if (validationOnInfo && validationOnDisbursement && validationOnScope) {
        this.onFormSubmit(crudMode, { ...updatedData });
      }
    });
  };

  handleButtonCancel = () => {
    const { crudMode } = this.state;
    if (crudMode === EVENT_OPERATION.UPDATE) {
      // TODO: store backup, restore garnu paryo
      const { info } = this.state;
      this.getData(info.promotionId);
    } else {
      const newState = getPromotionInitialState(null);
      this.setState(newState);
    }
  };

  handleScopeIconClick = (action, field, index = 0) => {
    const { scope } = this.state;

    if (action === 'remove') {
      scope[field].splice(index, 1);
    }

    if (action === 'add') {
      const baseObj = promotionScopeBase[field];
      scope[field].push({ ...baseObj });
    }

    this.setState({ scope });
  };

  loadData() {
    const { menu, scope, disbursement } = this.state;
    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.CATALOG)
      .then((response) => {
        menu.catalogList = response || [];
        this.setState({ menu });
      });

    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.CHANNEL)
      .then((response) => {
        menu.channelList = response || [];
        this.setState({ menu });
      });

    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.CATEGORY)
      .then((response) => {
        menu.categoryList = response || [];
        this.setState({ menu });
      });

    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.TOWN)
      .then((response) => {
        menu.townList = response || [];
        this.setState({ menu });
      });

    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.SUB_D)
      .then((response) => {
        menu.subDList = response || [];
        this.setState({ menu });
      });

    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.CATALOG_CHILDREN, SKU_CATALOG_LEVEL)
      .then(
        (response) => {
          menu.disbursementCatalogChildren = response || [];
          this.setState({
            menu,
            scope,
          });
        },
      );

    // if catalog Scope is assigned, update scope catalog children.
    if (scope.catalogInfo[0]) {
      getDataFromLocalStorage(CLIENT_STORAGE_TABLE.CATALOG_CHILDREN, scope.catalogInfo[0].catalogId)
        .then((response) => {
          menu.scopeCatalogChildren = response || [];
          this.setState({ scope });
        });
    }
  }

  handleCustomGroupAddCatalogClick = () => {
    const { scope } = this.state;

    scope.customGroupDetails.push(clone(customGroupBase));

    this.setState({ scope });
  };

  handleCustomGroupCrossClick = (index) => {
    const { scope } = this.state;

    scope.customGroupDetails.splice(index, 1);

    this.setState({ scope });
  };

  getHeader = () => {
    const { crudMode, info } = this.state;
    const header = crudMode === EVENT_OPERATION.UPDATE ? info.title : title;
    if (crudMode === EVENT_OPERATION.READ) return info.title;

    return <><span>{EVENT_OPERATION_MAPPER[crudMode].toLowerCase()}</span> {header}</>;
  };

  render() {
    const {
      disbursement, menu, criteria, scope, info, crudMode, displayErrorInForm,
    } = this.state;

    const { serverResponseWaiting } = this.props;

    const updateMode = crudMode !== EVENT_OPERATION.READ;
    const headerTitle = this.getHeader();

    return (
      <PromotionDetailStyled>
        <div className="section-header">
          <PanelStyled>
            <BreadCrumb list={breadCrumbConfig} />
            <PanelHeader>
              <h2>{headerTitle}</h2>
              <div className="flex m-0">
                {
                    updateMode && (
                      <div>
                        <Button
                          small
                          secondary
                          disabled={serverResponseWaiting}
                          onClick={() => this.handleButtonCancel()}
                        >
                          <span>
                      Cancel
                          </span>
                        </Button>
                        <Button
                          small
                          primary
                          disabled={serverResponseWaiting}
                          onClick={() => this.handleButtonSubmit()}
                        >
                          <span>
                      Save
                          </span>
                        </Button>
                      </div>
                    )
                  }
                {
                    ((crudMode !== EVENT_OPERATION.CREATE && !updateMode) && this.permission.update) && (
                      <Button
                        secondary
                        iconBtnSmall
                        disabled={updateMode}
                        onClick={() => {
                          this.setState({
                            crudMode: EVENT_OPERATION.UPDATE,
                          });
                        }}
                        className="ml-16"
                      >
                        <Icon iconName="pencil" />
                      </Button>
                    )
                  }
              </div>
            </PanelHeader>
          </PanelStyled>
        </div>
        <div className="section-content pad-48">
          <div className={!updateMode ? 'disabled' : null}>
            <PanelCard cardTitle="Promotion info">
              <PromotionInfo
                loading={serverResponseWaiting}
                info={info}
                refsObj={this.formReference.info}
                enableErrorDisplay={displayErrorInForm}
                onInputChange={this.handleInputChange('info')}
                onDateRangeChange={this.handleDateRangeChange}
              />
            </PanelCard>
            <PanelCard cardTitle=" Scope of Promotion">
              <PromotionScope
                scope={scope}
                selectedCategoryIds={scope.categoryIds}
                refsObj={this.formReference.scope}
                channelList={menu.channelList}
                categoryList={menu.categoryList}
                sbdsList={menu.sbdList}
                focusedSKUsList={menu.focusedSKUList}
                townList={menu.townList || []}
                subDList={menu.subDList}
                onIconClick={this.handleScopeIconClick}
                enableErrorDisplay={displayErrorInForm}
                onDropDownChange={this.handleScopeDropDownChange}
                onAutoCompleteChange={this.handleAutoCompleteChange('scope')}
              />
            </PanelCard>
            <PanelCard cardTitle="product catalog">
              <ProductCatalog
                onAddCatalogClick={this.handleCustomGroupAddCatalogClick}
                onCustomGroupCrossClick={this.handleCustomGroupCrossClick}
                scope={scope}
                customGroupStatus={scope.customGroupStatus}
                refsObj={this.formReference.scope}
                channelList={menu.channelList}
                categoryList={menu.categoryList}
                catalogList={menu.catalogList}
                sbdsList={menu.sbdList}
                focusedSKUsList={menu.focusedSKUList}
                townList={menu.townList || []}
                subDList={menu.subDList}
                customGroupList={scope.customGroupDetails}
                onIconClick={this.handleScopeIconClick}
                enableErrorDisplay={displayErrorInForm}
                catalogChildrenList={menu.scopeCatalogChildren}
                // catalogGroupChildrenList={menu.scopeCatalogGroupChildren}
                onInputChange={this.handleInputChange('scope')}
                onDropDownChange={this.handleScopeDropDownChange}
                onAutoCompleteChange={this.handleAutoCompleteChange('scope')}
              />
            </PanelCard>
            <PanelCard cardTitle="Condition">
              <PromotionCondition
                customGroupStatus={scope.customGroupStatus}
                criteria={criteria}
                enableErrorDisplay={displayErrorInForm}
                onInputChange={this.handleInputChange('criteria')}
                onDropDownChange={this.handleDropDownChange('criteria')}
              />
              {' '}

            </PanelCard>
            <PanelCard cardTitle="DISBURSEMENT">
              <PromotionDisbursement
                disbursement={disbursement}
                catalogList={menu.catalogList}
                refsObj={this.formReference.disbursement}
                enableErrorDisplay={displayErrorInForm}
                catalogChildrenList={menu.disbursementCatalogChildren}
                onInputChange={this.handleInputChange('disbursement')}
                onDropDownChange={this.handleDisbursementDropDownChange}
                onAutoCompleteChange={this.handleAutoCompleteChange('disbursement')}
              />
            </PanelCard>
          </div>
        </div>
      </PromotionDetailStyled>
    );
  }
}

PromotionDetail.propTypes = propTypes;

PromotionDetail.defaultProps = defaultProps;

export { promotionScopeBase };

export default withAlert()(PromotionDetail);
