import { compose } from 'recompose';
import SplitPayment from './SplitPayment';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import { query, mutation, SPLIT_PAYMENT } from './API';

const composeSplitPayment = compose(
  SPLIT_PAYMENT,
  withRequestTracker({
    query,
    mutation,
  }),
)(SplitPayment);

export default composeSplitPayment;
