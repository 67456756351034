import React, { Fragment } from 'react';
import { TotalView } from '../create/table/View';
import { PanelStyled } from '../../../common/configuration';
import SummaryView from '../../../common/detailViews/summaryView';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import PageHeader from '../../../common/detailViews/pageHeader/PageHeader';
import { colSize } from '../../../../data/enums/Styles';
import TableView from './TableView';
import PanelCard from '../../../../components/Cards/PanelCard';

const SrnView = ({
  data, title, breadCrumb, handlePrintClick, actionWidgets, getActionConfig,
}) => (
  <Fragment>
    <div className="section-header">
      <PanelStyled>
        <PageHeader
          breadCrumb={breadCrumb}
          title={title}
          actions={{ ...actionWidgets }}
          getActionConfig={getActionConfig}
        />
      </PanelStyled>
    </div>
    <div className="section-content pad-48">
      <PanelCard cardTitle="details">
        <SummaryView
          data={data.summary || []}
          colType={colSize.l}
        />
      </PanelCard>
      <PanelCard cardTitle="sku" skuClassStatus>
        <TableView
          skuLines={data.lines}
        />
        <TotalView
          priceDetails={data.srnAmount}
          refsObj={{}}
          viewType={EVENT_OPERATION.DETAILS}
        />
      </PanelCard>
    </div>
  </Fragment>
);

export default SrnView;
