import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';

const crudRequestConfig = {
  [EVENT_OPERATION.DISPATCHED]: {
    objectMapper: replacementId => ({
      replacementId
    }),
    responseName: 'dispatchReplacementOrder',
    message: 'Orders dispatched successfully.',
  },
};

export { crudRequestConfig }
