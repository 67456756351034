const colors = [
    '#4B9BFF',
    '#FFB46E',
    '#FF5F5F',
    '#50D2A0',
    '#FF73C3',
    '#9BE169',
    '#AF78FF',
    '#FFD76E',
    '#5FC8FF',
    '#89A7E0',
    '#0DBADC',
    '#21F9DD',
    '#C07369',
    '#6099B3',
    '#E0EA7E',
];

export default colors;