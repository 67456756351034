import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
// import { GET_CATALOG_DETAIL_LIST } from '../../../configuration/catalog/details/API';

const USER_TARGET_FRAGMENT = gql`
    fragment UserTargetMonthFragment on UserTargetPerMonth {
        id
        name
        target
        monthName
        buTargetId
        year
        role
        month
        isChildPresent
        childUsers : ChildUsers {
            id
            name
            target
            isChildPresent
            role
            childUsers : ChildUsers {
                id
                name
                target
                isChildPresent
                role
                childUsers : ChildUsers {
                    id
                    name
                    target
                    isChildPresent
                    role
                    childUsers : ChildUsers {
                        id
                        name
                        target
                        isChildPresent
                        role
                        childUsers : ChildUsers {
                            id
                            name
                            target
                            isChildPresent
                            role
                        }
                    }
                }
            }
        }
    }
`;

const GET_USER_TARGET = gql`
    query getUserTarget($buTargetId: Int!) {
        userTargetPerMonth(buTargetId: $buTargetId) {
            ...UserTargetMonthFragment
        }
    }
    ${USER_TARGET_FRAGMENT}
`;

const GET_USER_BRAND_TARGET = gql`
    query getUserBrandTarget($buTargetId: Int!, $userId: Int) {
        userBrandTargets(buTargetId: $buTargetId, userId: $userId) {
            buTargetId                       
            id                       
            userId                       
            brandId                       
            target                     
            dimension                     
            brandName                     
            name                     
            year                       
            month                       
            monthName                     
            total                     
            role                     
        }
    }
`;

const GET_SUBD_TARGET = gql`
    query getSubDTarget($buTargetId: Int!) {
        subdTargetPerMonth(buTargetId: $buTargetId) {
            id
            target
            title
            year
            month
            monthName
        }
    }
`;

const GET_SUBD_BRAND_TARGET = gql`
    query getSubDBrandTarget($buTargetId: Int!, $distributorId: Int) {
        subdBrandTargets(buTargetId: $buTargetId, distributorId: $distributorId) {
            title
            year
            monthName
            brandName
            target
            total
        }
    }
`;

const CREATE_USER_TARGET = graphql(gql`
    mutation ($input: BUTargetInput!) {
        setUserTarget(input: $input)
        {
            ...BuTargetFragment
        }
    }
    ${USER_TARGET_FRAGMENT}
`, {
  props: ({ mutate }) => ({
    setUserTarget: variables => mutate({
      variables,
    }),
  }),
});

const UPDATE_USER_TARGET = graphql(gql`
    mutation ($input: BUTargetInput!) {
        setUserTarget(input: $input)
        {
            ...BuTargetFragment
        }
    }
    ${USER_TARGET_FRAGMENT}
`, {
  props: ({ mutate }) => ({
    setUserTarget: variables => mutate({
      variables,
    }),
  }),
});

const CREATE_EDIT_USER_TARGET = graphql(gql`
mutation ($buTargetId: Int!, $userId: Int!, $target: Float!){
    createOrEditUserTarget( buTargetId: $buTargetId, userId: $userId, target: $target )
    {
        id        
userId        
target       
title      
brandId      
buTargetId        
allocated       
unAllocated       
dimension      
monthName      
month        
year        
    }
}

`, {
  props: ({ mutate }) => ({
    createOrEditUserTarget: variables => mutate({
      variables,
    }),
  }),
});

const CREATE_EDIT_USER_BRAND_TARGET = graphql(gql`
mutation ($buTargetId: Int!, $userId: Int!, $target: Float!, $userBrandTargets: [UserBrandTargetInput]){
    createOrEditUserBrandTarget( buTargetId: $buTargetId, userId: $userId, target: $target, userBrandTargets: $userBrandTargets )
    {
       buTargetId                  
id                  
userId                  
brandId                  
target                
dimension                 
brandName                 
name                 
year                  
month                  
monthName                 
total                
role                     
    }
}

`, {
  props: ({ mutate }) => ({
    createOrEditUserBrandTarget: variables => mutate({
      variables,
    }),
  }),
});

const GET_CATALOG_DETAIL_LIST = gql`
    query getCatalogDetailList($catalogId: Int, $limit:Int, $offset:Int, $filter: FilterInput){
        catalogDetails(catalogId: $catalogId, limit: $limit, offset:$offset, filter: $filter){
            rows {
                id
                title
            }
            count
        }
    }
`;

const query = {
  getUserTarget: GET_USER_TARGET,
  getSubDTarget: GET_SUBD_TARGET,
  getUserBrandTarget: GET_USER_BRAND_TARGET,
  getSubDBrandTarget: GET_SUBD_BRAND_TARGET,
  getCatalogDetailList: GET_CATALOG_DETAIL_LIST,
};

const mutation = {
  createUserTarget: CREATE_USER_TARGET,
  updateUserTarget: UPDATE_USER_TARGET,
  createOrEditUserTarget: CREATE_EDIT_USER_TARGET,
  createOrEditUserBrandTarget: CREATE_EDIT_USER_BRAND_TARGET,

};

export {
  USER_TARGET_FRAGMENT,
  UPDATE_USER_TARGET,
  CREATE_USER_TARGET,
  CREATE_EDIT_USER_TARGET,
  CREATE_EDIT_USER_BRAND_TARGET,
};

export {
  query, mutation, GET_USER_TARGET, GET_SUBD_TARGET,
};
