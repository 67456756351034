import {
  compose,
} from 'recompose';
import SubDReplenishment from './SubDReplenishment';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  query, DOWNLOAD_REPORT, mutation, UPLOAD, SHARE_REPORT, SAVE_REPORT,
} from './API';

const ComposedSubDReplenishment = compose(
  DOWNLOAD_REPORT,
  UPLOAD,
  SHARE_REPORT, SAVE_REPORT,
  withRequestTracker({
    query,
    mutation,
  }),
)(SubDReplenishment);

export default ComposedSubDReplenishment;
