import { FILTER } from '../../../../data/enums/filter';
import { COVERAGE_TYPE } from '../../../../data/enums/GraphQL';
import { getFilterConfig } from '../../../../utils/filter';
import {
  BRAND_COVERAGE,
} from '../../../../data/enums/Route';

const VIEW_LIST = [
  { id: 1, label: COVERAGE_TYPE.CHANNEL },
  { id: 2, label: COVERAGE_TYPE.CATEGORY },
  { id: 3, label: COVERAGE_TYPE.SUB_D },
  { id: 4, label: COVERAGE_TYPE.ROUTE },
];

const brandDistributionLink = {
  id: 1,
  title: 'Brand Coverage',
  path: `/${BRAND_COVERAGE}`,
  active: true,
};

const filterBasedOnView = {
  [COVERAGE_TYPE.CHANNEL]: [],
  [COVERAGE_TYPE.CATEGORY]: [FILTER.CHANNEL],
  [COVERAGE_TYPE.ROUTE]: [FILTER.SUB_D],
  [COVERAGE_TYPE.SUB_D]: [],
};

const computeFilterConfig = type => (getFilterConfig([...(filterBasedOnView[type] || [])]));

const filterConfig = getFilterConfig([FILTER.CHANNEL]);
export {
  brandDistributionLink,
  computeFilterConfig,
  VIEW_LIST,
  filterConfig,
};
