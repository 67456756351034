import {
  compose,
} from 'recompose';
import BrandCoverage from './BrandCoverage';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  query, DOWNLOAD_REPORT, mutation,
} from './API';

const ComposedBrandCoverage = compose(
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  }),
)(BrandCoverage);

export default ComposedBrandCoverage;
