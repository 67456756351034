import {
  compose,
} from 'recompose';
import ChannelAssortment from './ChannelAssortment';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  query,
} from './API';

const ComposedChannelAssortment = compose(
  withRequestTracker({
    query,
  }),
)(ChannelAssortment);

export default ComposedChannelAssortment;
