import React from 'react';
import PropTypes from 'prop-types';
import { Input, Form, CustomSelect } from '../../../components';
import withLoading from '../../../utils/composition/withLoading';

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    territoryId: PropTypes.number,
  }),
  handleInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  handleDropDownChange: PropTypes.func,
  refsObj: PropTypes.instanceOf(Object),
  territories: PropTypes.instanceOf(Array),
};

const defaultProps = {
  handleInputChange: () => null,
  enableErrorDisplay: true,
  data: {
    name: '',
    territoryId: 0,
  },
  refsObj: {},
  territories: [],
  handleDropDownChange: () => null,
};

const TownForm = ({ ...props }) => {
  const {
    data, enableErrorDisplay, handleInputChange, refsObj, territories, handleDropDownChange,
  } = props;
  return (
    <Form>
      <CustomSelect
        ref={(ref) => {
          refsObj.territoryId = ref;
          return null;
        }}
        clearable={false}
        className="custom-select"
        placeholder="Select Details"
        getOptionLabel={({ title }) => title}
        getOptionValue={({ id }) => id}
        options={territories}
        enableValiation
        enableErrorDisplay={enableErrorDisplay}
        value={territories.filter(({ id }) => id === data.territoryId)}
        onChange={e => handleDropDownChange(e.id, ['territoryId'])}
        labelContent='Details'
      />
      <Input
        ref={ref => refsObj.title = ref}
        value={data.title}
        name="title"
        type="text"
        placeholder="Name"
        enableErrorDisplay={enableErrorDisplay}
        enableValidation
        onChange={event => handleInputChange(event)}
        labelContent='Town'
      />
    </Form>
  );
};

TownForm.propTypes = propTypes;

TownForm.defaultProps = defaultProps;

const TownViewWithErrorAndLoading = withLoading(TownForm);

export default TownViewWithErrorAndLoading;
