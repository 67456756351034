import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../../../components';
import { ORDER_TYPE, OrderFulFilmentContext } from '../../config';
import { detailsLabelConfig, DetailTableBody } from './tableConfig';
import { tableData } from '../../../../../utils/paginatedListUtils';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { replacedDetailsLabelConfig, ReplacedDetailTableBody } from '../../replaced/detail/tableConfig';

const propTypes = {
  activeLine: PropTypes.shape({
    id: PropTypes.number,
    sellerId: PropTypes.number,
  }),
  data: PropTypes.instanceOf(Array),
  handleSliderOpen: PropTypes.func,
  onOrderStatusChangeClick: PropTypes.func.isRequired,
  orderDetails: PropTypes.instanceOf(Object),
};

const defaultProps = {
  data: [],
  activeLine: {
    id: '',
    sellerId: 0,
  },
  orderDetails: {
    dispatchNumber: '',
    invoiceId: 0,
    orderType: ORDER_TYPE.NORMAL,
  },
  handleSliderOpen: () => null,
};

const getLabelConfigAndBodyRenderer = (orderType) => {
  if (orderType === ORDER_TYPE.REPLACEMENT) {
    return {
      labelConfigList: replacedDetailsLabelConfig,
      TableBody: ReplacedDetailTableBody,
    };
  }

  return {
    labelConfigList: detailsLabelConfig,
    TableBody: DetailTableBody,
  };
};

const DispatchedDetailChildView = ({
  data, orderDetails, onOrderStatusChangeClick, activeLine, onIconClick, amountDetails, loading,
  handleSliderOpen, billDiscountDetail,
}) => {
  const activeLabel = 0;
  const { labelConfigList, TableBody } = getLabelConfigAndBodyRenderer(orderDetails.orderType);

  return (
    <OrderFulFilmentContext.Consumer>
      {
        ({ permission }) => (
          <Fragment>
            <tr>
              {
                labelConfigList.map(label => (label.show
                  ? tableData(label.title, label.align, label.classNames)
                  : null))
              }
            </tr>
            <Fragment>
              {
                data.map(order => order.Lines.map((sku, index) => (
                  <tr>
                    {TableBody({
                      data: sku,
                      index,
                      onIconClick,
                      order,
                      handleSliderOpen,
                    })}
                  </tr>
                )))
              }
            </Fragment>
            { amountDetails.billDiscount > 0 && (
              <tr>
                <td colSpan={4} />
                <td
                  className="text-right clickable"
                  onClick={() => onIconClick(EVENT_OPERATION.READ_SUB_TYPE,
                    { billDiscountDetail, callOrderValue: { ...amountDetails } })}
                >
                  <span className="bill-discount-indicator" />
                  Bill Discount(
                  <span className="bold">
                    {fixedFloatAndCommas(
                      (amountDetails.billDiscount
                        / amountDetails.subTotal) * 100, 2,
                    )}
                    %
                  </span>
                  )
                </td>
                <td className="text-right">{fixedFloatAndCommas(amountDetails.billDiscount)}</td>
              </tr>
            )}
            { amountDetails.topUpDiscount > 0 && (
              <tr>
                <td colSpan={4} />
                <td className="text-center">
                  <span className="top-up-indicator" />
                  Top Up Discount
                </td>
                <td className="text-right">{fixedFloatAndCommas(amountDetails.topUpDiscount)}</td>
              </tr>
            )}
            { amountDetails.tradeDiscount > 0 && (
            <tr>
              <td colSpan={4} />
              <td className="text-right">
                <span className="trade-discount-indicator" />
                  Trade Discount
              </td>
              <td className="text-right">{fixedFloatAndCommas(amountDetails.tradeDiscount)}</td>
            </tr>
            )}
            {
              permission.update && (
                <tr>
                  <td colSpan="75%">
                    <div className="text-right">
                      <Button
                        primary
                        small
                        disabled={loading}
                        onClick={() => onOrderStatusChangeClick(EVENT_OPERATION.DELIVERED,
                          orderDetails.orderType === ORDER_TYPE.REPLACEMENT
                            ? { dispatchNumber: orderDetails.dispatchNumber } : orderDetails)}
                      >
                        <span>Deliver</span>
                      </Button>
                    </div>
                  </td>
                </tr>
              )
            }
          </Fragment>
        )
      }
    </OrderFulFilmentContext.Consumer>
  );
};


DispatchedDetailChildView.propTypes = propTypes;

DispatchedDetailChildView.defaultProps = defaultProps;

export default DispatchedDetailChildView;
