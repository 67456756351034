import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import ChannelAssortmentView from './View';
import PageHeader from '../../base/PageHeader';
import { channelAssortment } from '../../common/DomainConfig';
import * as queryService from '../../base/query.service';
import { PanelStyled } from '../../common/configuration';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { insertUserLabel } from '../../../data/services/userLabel';
import { getPermissionForTerritory } from '../../base/permission';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';
import {
  breadCrumbConfig, filterConfig,
} from './config';
import { isError } from '../../common/HelperFunctions';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  getChannelList: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class ChannelAssortment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      display: {
        searchBox: false,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
      },
    };

    this.permission = getPermissionForTerritory();
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      props.displayAlert,
    );

    this.getData = () => {
      const { data } = this.state;
      return data;
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount() {
    insertUserLabel();
    this.loadTableData();
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  loadTableData = () => {
    const {
      data, queryParameters,
    } = this.state;
    const { getChannelList } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);

    getChannelList({
      offset,
      limit: queryParameters.pagination.limit,
      filter: {
        filters: apiFilterProcessor(queryParameters.filter),
        queryString: queryParameters.search,
      },
    }, {
      handleSuccess: (response) => {
        data.list = (response.data.channels && response.data.channels.rows) || [];
        data.total = (response.data.channels && response.data.channels.count) || 0;
        if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
        this.setState(data);
      },
      handleError: (error) => { this.onAPIRequestFailure(error); },
    });
  };

  setQueryParameters = (queryParams, callBack = () => null) => this.setState(
    { queryParameters: queryParams },
    callBack,
  );

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleClick = (id) => {
    const { history } = this.props;
    history.push(`channel-assortment/details/${id}`);
  };

  render() {
    const {
      data, queryParameters, display,
    } = this.state;
    const {
      serverResponseWaiting,
    } = this.props;
    return (
      <Fragment>
          <div className="section-header">
            <PanelStyled>
              <PageHeader
                display={display}
                breadCrumb={breadCrumbConfig}
                config={{
                  title: channelAssortment.title,
                  create: false,
                  download: false,
                  filter: false,
                  search: true,
                }}
                filter={{
                  date: queryParameters.date,
                  menuList: filterConfig.menu,
                  onFilterChange: this.basePaginationService.handleFilterChange,
                }}
                queryParameters={queryParameters}
                controlDisplay={this.controlDisplay}
                resetFilter={this.basePaginationService.resetFilter}
                clearSearchText={this.basePaginationService.clearSearchText}
                handleSearchChange={this.basePaginationService.handleSearchInputChange}
                handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
                handleDownloadClick={this.basePaginationService.handleDownloadClick}
              />
            </PanelStyled>
          </div>
          <div className="section-content">
            <ChannelAssortmentView
              data={data}
              pagination={queryParameters.pagination}
              loading={serverResponseWaiting}
              onPageChange={this.basePaginationService.onPageSelect}
              permission={this.permission}
              onHandleClick={this.handleClick}
            />
          </div>
      </Fragment>
    );
  }
}

ChannelAssortment.propTypes = propTypes;

ChannelAssortment.defaultProps = defaultProps;

export default withAlert()(ChannelAssortment);
