import React, { Component } from 'react';
import PropTypes from 'prop-types';
import history from '../../../utils/history';
import withAlert from '../../../utils/composition/withAlert';
import ForgotStyled from './ForgotStyled';
import {
  Form, Button, DialogBody, DialogFooter, DialogHeader,
} from '../../../components';
import PasswordField from './PasswordField';
import { refGenerator, refValidator } from '../../../utils/refGenerator';
import validationFields from './config';
import { ALERT_TYPE } from '../../../data/enums/AlertType';

const propTypes = {
  resetPasswordFromWeb: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
};

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: {
        newPassword: '',
        repeatPassword: '',
      },
      token: props.match.params.token,
      enableFormValidation: false,
      formReference: refGenerator(validationFields.validationField),
    };
  }

    onAPIRequestFailure = (error) => {
      const { displayAlert } = this.props;
      displayAlert(ALERT_TYPE.DANGER, error);
    };

     handleInputChange=(e) => {
       const { password } = this.state;
       password[e.target.name] = e.target.value;
       this.setState({ password });
     }

backToLogin=() => {
  history.push('/login');
}

passwordSubmit = () => {
  history.push('/login');
}

handleSubmit=(event) => {
  event.preventDefault();
  const { password, token, formReference } = this.state;
  const { resetPasswordFromWeb, displayAlert } = this.props;
  const formValidation = refValidator(formReference);

  if (!formValidation) {
    this.setState({ enableFormValidation: true });
  } else if (password.newPassword === password.repeatPassword) {
    this.setState({
      password,
    });

    resetPasswordFromWeb({
      newPassword: password.newPassword,
      token,
    },
    {
      handleSuccess: (response) => {
        displayAlert(ALERT_TYPE.SUCCESS, 'Password changed successfully.', this.passwordSubmit);
      },
      handleError: (error) => {
        this.onAPIRequestFailure(error);
      },
    });
  } else {
    displayAlert(ALERT_TYPE.CUSTOM_DANGER, "Password didn't match ");
  }
}

render() {
  const { password, enableFormValidation, formReference } = this.state;
  return (
    <ForgotStyled>
      <div className="top-section">
        <img src="/image/evolve-logo.svg" alt="Rosia V3" />
      </div>
      <div className="modal-wrapper">
        <div className="modal-inner">
          <DialogHeader>
            <h2>Choose a new Password</h2>
          </DialogHeader>
          <DialogBody>
            <Form>
              <PasswordField
                label="New Password"
                value={password.newPassword}
                name="newPassword"
                enableValidation
                enableErrorDisplay={enableFormValidation}
                onInputChange={this.handleInputChange}
                refsObj={formReference}
              />
              <PasswordField
                label="Repeat Password"
                value={password.repeatPassword}
                enableErrorDisplay={enableFormValidation}
                name="repeatPassword"
                enableValidation
                onInputChange={this.handleInputChange}
                refsObj={formReference}
              />
            </Form>
          </DialogBody>
          <DialogFooter>
              <>
                <Button borderless onClick={this.backToLogin}>
                  <span>Back to Login</span>
                </Button>
                <Button
                  primary
                  onClick={this.handleSubmit}
                >
                  <span>Submit</span>
                </Button>
                  </>
          </DialogFooter>
        </div>
      </div>
      <p>© 2019 Evolve Pvt Ltd</p>
    </ForgotStyled>
  );
}
}

ForgotPassword.propTypes = propTypes;

export default withAlert()(ForgotPassword);
