import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Pagination } from '../../../components';
import CustomSelect from '../../../components/CustomSelect';
import withErrorAndLoadingScreen from '../../../utils/composition/withLoading';
import { PanelStyled } from '../../common/configuration';
import { StockAdjusStyled } from './StockAdjusStyled';
import { TableBody, TableHeader } from './tableConfig';

const propTypes = {
  componentDisplay: PropTypes.instanceOf(Object),
  distributorList: PropTypes.instanceOf(Array),
};
const defaultProps = {
  componentDisplay: {},
  distributorList: [],
};

const StockAdjustmentView = ({
  data,
  loading,
  inputData,
  pagination,
  permission,
  onIconClick,
  onPageChange,
  distributorList,
  componentDisplay,
  onDropDownChange,
  enableErrorDisplay,
  onHeaderClick,
}) => (
  <StockAdjusStyled>
    {
          componentDisplay.distributorSelection && (
          <PanelStyled>
            <div>
              <CustomSelect
                small
                enableValidation
                options={distributorList}
                className="custom-select"
                placeholder="Select Distributor"
                getOptionValue={({ id }) => id}
                getOptionLabel={({ title }) => title}
                enableErrorDisplay={enableErrorDisplay}
                onChange={event => onDropDownChange(event.id)}
                value={distributorList.filter(({ id }) => id === inputData.distributorId) || []}
              />
            </div>
          </PanelStyled>
          )
    }
    <Fragment>
      {
          data.list.length > 0 ? (
            <div className="table-wrap">
              <table>
                {TableHeader({ onHeaderClick })}
                <tbody>
                  {data.list.map(stockAdjustment => (
                    TableBody({
                      data: stockAdjustment,
                      onIconClick,
                      permission,
                    })
                  ))}
                </tbody>
              </table>
            </div>) : null

        }
    </Fragment>
    { data.list.length > 0 && (
    <Pagination
      currentPage={pagination.page}
      limit={pagination.limit}
      totalItems={data.total}
      onPageChange={onPageChange}
    />
    )}
  </StockAdjusStyled>
);

StockAdjustmentView.propTypes = propTypes;

StockAdjustmentView.defaultProps = defaultProps;

const StockAdjustmentViewWithErrorAndLoading = withErrorAndLoadingScreen(StockAdjustmentView);

export default StockAdjustmentViewWithErrorAndLoading;
