import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Table from './table';
import { Button } from '../../../../components';
import { PanelStyled } from '../../../common/configuration';
import SummaryDetails from './summaryDetails';
import PageHeader from '../../../common/detailViews/pageHeader/PageHeader';
import { FORM_CONFIG } from '../../../../data/enums/config';
import withErrorAndLoadingScreen from '../../../../utils/composition/withLoading';
import PanelCard from '../../../../components/Cards/PanelCard';

const propTypes = {
  skus: PropTypes.array,
  title: PropTypes.string,
  data: PropTypes.object,
  loading: PropTypes.bool,
  vendorList: PropTypes.array,
  breadCrumb: PropTypes.array,
  permission: PropTypes.object,
  handlePrnCancel: PropTypes.func,
  distributorId: PropTypes.number,
  handlePrnSubmit: PropTypes.func,
  distributorList: PropTypes.array,
  enableErrorDisplay: PropTypes.bool,
  invoiceNumberStatus: PropTypes.bool,
  detailsFormConfig: PropTypes.object,
  displayAlert: PropTypes.func.isRequired,
  getTableDetails: PropTypes.func.isRequired,
  getSummaryDetails: PropTypes.func.isRequired,
  onInvoiceNumberChange: PropTypes.func.isRequired,
  getTableValidationStatus: PropTypes.func.isRequired,
  getDetailsValidationStatus: PropTypes.func.isRequired,
};

const defaultProps = {
  skus: [],
  data: {},
  title: '',
  breadCrumb: [],
  permission: {},
  vendorList: [],
  distributorId: 0,
  distributorList: [],
  detailsFormConfig: {},
  enableErrorDisplay: false,
  handlePrnCancel: () => null,
  handlePrnSubmit: () => null,
  invoiceNumberStatus: false,
  loading: false,
};

const CreatePrnView = ({
  data,
  skus,
  title,
  subDUser,
  permission,
  breadCrumb,
  vendorList,
  displayAlert,
  distributorId,
  handlePrnSubmit,
  handlePrnCancel,
  distributorList,
  getTableDetails,
  getSummaryDetails,
  detailsFormConfig,
  enableErrorDisplay,
  invoiceNumberStatus,
  onInvoiceNumberChange,
  loading,
  handleValueUpdate,
  getTableValidationStatus,
  getDetailsValidationStatus,
}) => (
  <Fragment>
    <div className="section-header">
      <PanelStyled>
        <div className="prn-page-header">
          <PageHeader
            breadCrumb={breadCrumb}
            title={title}
          />
          <div className="inventory-submit-btn">
            <Button
              small
              disabled={loading}
              secondary
              onClick={() => handlePrnCancel()}
            >
              <span>
                  Cancel
              </span>
            </Button>
            <Button
              small
              primary
              disabled={loading}
              onClick={() => handlePrnSubmit()}
            >
              <span>
                  Save
              </span>
            </Button>
          </div>
        </div>
      </PanelStyled>
    </div>
    <div className="section-content pad-48">
      <PanelCard cardTitle="detail">
        <SummaryDetails
          subDUser={subDUser}
          vendorList={vendorList}
          permission={permission}
          getDetails={getSummaryDetails}
          formConfig={detailsFormConfig}
          loading={loading}
          distributorList={distributorList}
          getStatus={getDetailsValidationStatus}
          enableErrorDisplay={enableErrorDisplay}
          invoiceNumberStatus={invoiceNumberStatus}
          handleValueUpdate={handleValueUpdate}
          onInvoiceNumberChange={onInvoiceNumberChange}
          data={detailsFormConfig[FORM_CONFIG.MAPPER](data || {})}
        />
      </PanelCard>

      <PanelCard cardTitle="sku" skuClassStatus>
        <Table
          data={data}
          skuList={skus}
          permission={permission}
          displayAlert={displayAlert}
          getDetails={getTableDetails}
          distributorId={distributorId}
          getStatus={getTableValidationStatus}
          enableErrorDisplay={enableErrorDisplay}
          invoiceNumberStatus={invoiceNumberStatus}
        />
      </PanelCard>
    </div>
  </Fragment>
);

CreatePrnView.propTypes = propTypes;

CreatePrnView.defaultProps = defaultProps;

export default withErrorAndLoadingScreen(CreatePrnView);
