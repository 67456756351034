import styled from 'styled-components';

const UserProfileStyled = styled.div`
  
.mt-10{
  margin-top: 10px;
}
    .user-page-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    
    > ul {
      width: 100%;
    }
  }
.config-wrap{
  padding: 52px 99px;
  border-top: 1px solid #E7E7ED;
.config-panel-content{
  .form-input {
      margin-bottom: 16px;
      display: flex;
      /* flex-wrap: wrap; */
       &:last-child {
        margin-bottom: 24px;
      }
      }
      .form-select-input {
          display: flex;
          >div{
            width: 100%;
          }
      }
    }
       label {
      margin: 8px 0 5px 0;
      min-width: 237px;
      font-weight: 600;
      font-size: 14px;
      display: inline-block;
    }



  .config-pannel-header{
    display: block;
    padding-bottom: 4px;
    font-size: 14px;
    color: #6b6c7e;
    border-bottom: 1px solid #cdced9;
    margin-bottom: 20px;
    label{
    font-weight: 400;
    }
  }
  .security{
    position: relative;
  .icon{
    position: absolute;
    bottom: 13px;
    right: 16px;
    cursor: pointer;
    color: grey;
}
.form-input{
  &:last-child {
  margin-bottom: 4px;
  }
}}
.change-pw{
  text-align: right;
   height: 21px;
  color: #0B5FFF;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  cursor: pointer;
}
.change-photo{
  margin-top:16px;
  height: 21px;
  color: #272833;
  font-size: 14px;
  font-weight: 600;
  width: 166px;
  text-align: center;
  cursor: pointer;
  &:hover{
    color: #0B5FFF;
  }
}


  }
 .modal-wrapper{
.modal-inner.update{
 .password{
 position: relative;

  .eye{
    position: absolute;
    top: 34px;
    bottom: 0;
    right: 16px;
    cursor: pointer;
    color: grey;
}
}
.form-input{
  margin-bottom: 24px;
  }
  }
} 

.newProfile{
  .thumbnail-img {

  height: 163px;
    width: 163px;
    /* border-radius: 4px; */
    margin: 0px 4px;
    border-radius: 50%;
    /* border: 1px solid red; */
    }
    }
 `;

export default UserProfileStyled;
