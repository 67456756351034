import React, { Component, Fragment } from 'react';
import { TableBody, TableHeader } from '../tableConfig';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import SplitPayment from '../../../../components/SplitPayment';
import { Pagination } from '../../../../../components';
import BulkSelect from '../../../../components/BulkSelect';
import withAlert from '../../../../../utils/composition/withAlert';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import withErrorAndLoadingScreen from '../../../../../utils/composition/withLoading';


class Pending extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: {
        type: '',
        element: '',
      },
    };
  }

    handleIconClick = (type, element = {}) => {
      this.setState({
        dialog: {
          type,
          element,
        },
      });
    };

      resetDialog = () => {
        this.setState({
          dialog: {
            type: '',
            element: '',
          },
        });
      };

      getStatus = (status, statusType) => {
        const { displayAlert } = this.props;
        displayAlert(ALERT_TYPE[statusType === 'err' ? 'INFO' : 'SUCCESS'], status);
      }

      render() {
        const {
          dialog,
        } = this.state;
        const { type } = dialog;
        const {
          tableConfig, pendings, onPageChange, fetchAllData, permission,
        } = this.props;
        return (
          <Fragment>
            {type && (
              <SplitPayment
                data={dialog.element}
                type={type}
                resetDialog={this.resetDialog}
                fetchAllData={fetchAllData}
                getStatus={this.getStatus}
              />
            )}
            <BulkSelect
              data={pendings.list}
              fetchAllData={fetchAllData}
              Buttons={[{
                label: 'Received',
                status: EVENT_OPERATION.RECEIVED,
              }]}
              render={(state, primaryCheckboxHandler, secondaryCheckboxHandler) => (
                <div className="table-wrap">
                  <table>
                    {
                    TableHeader({
                      tableConfig,
                      dataListLength: pendings.list.length,
                      primaryCheckboxHandler,
                      checkedListLength: state.checkedList.length,
                      permission,
                    })
                  }

                    <tbody>
                      {pendings.list.map(pending => (
                        TableBody({
                          data: pending,
                          handleIconClick: this.handleIconClick,
                          checkedList: state.checkedList,
                          tableConfig,
                          secondaryCheckboxHandler,
                          section: 'pending',
                          permission,
                        })
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            />
            <Pagination
              currentPage={pendings.page}
              limit={pendings.limit}
              totalItems={pendings.total}
              onPageChange={onPageChange}
            />
          </Fragment>
        );
      }
}

export default withAlert()(withErrorAndLoadingScreen(Pending));
