import PropTypes from 'prop-types';
import React from 'react';
import { getWeekDays, WEEK_DAYS_TITLE_MAPPER } from '../date';

/**
 * @type {{weekStartDay: *}}
 */
const propTypes = {
  weekStartDay: PropTypes.number,
  weekEndList: PropTypes.instanceOf(Array),
};

const defaultProps = {
  weekStartDay: 1,
  weekEndList: [],
};

const weekStartResolver = (weekEndList) => {
  const lastWeekEndDay = weekEndList.length > 0 ? weekEndList[weekEndList.length - 1] : null;

  if (!lastWeekEndDay) {
    return 1;
  }

  return lastWeekEndDay === 6 ? 0 : lastWeekEndDay + 1;
};

const classNameResolver = (day, weekEndList) => (weekEndList.includes(WEEK_DAYS_TITLE_MAPPER[day])
  ? 'calendar-week-title gray' : 'calendar-week-title secondary');

const Header = ({ weekStartDay, weekEndList }) => {
  const daysList = getWeekDays(weekStartDay);

  return (
    <div>
      {
        daysList.map(day => (
          <div className={classNameResolver(day, weekEndList)}>
            <span>{day}</span>
          </div>
        ))
      }
    </div>
  );
};

Header.propTypes = propTypes;

Header.defaultProps = defaultProps;

export default Header;
