import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import withLoading from '../../../utils/composition/withLoading';
import UploadForm from '../../common/CSVUploadForm';

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  handleInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  refsObj: PropTypes.instanceOf(Object),
};

const defaultProps = {
  data: {
    name: '',
    description: '',
  },
  refsObj: {
    title: '',
  },
  enableErrorDisplay: true,
  handleInputChange: () => null,
};

const TargetAchievementForm = ({ ...props }) => {
  const {
    handleFileUpload,
    dialogData,
    enableErrorDisplay,
    fileUploadRefs,
    serverResponseWaiting,
  } = props;

  return (
    <Fragment>
      <UploadForm
        show
        refsObj={fileUploadRefs}
        data={dialogData}
        loading={serverResponseWaiting}
        handleFileUpload={handleFileUpload}
        enableErrorDisplay={enableErrorDisplay}
      />
    </Fragment>
  );
};

TargetAchievementForm.propTypes = propTypes;

TargetAchievementForm.defaultProps = defaultProps;

const TargetAchievementFormWithErrorAndLoading = withLoading(TargetAchievementForm);

export default TargetAchievementFormWithErrorAndLoading;
