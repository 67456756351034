import {
    blueMarker,
    darkblueMarker,
    green2Marker,
    greenMarker,
    lightblueMarker,
    marker12,
    marker13,
    marker14,
    marker15,
    marker16,
    orangeMarker,
    pinkMarker,
    purpleMarker,
    redMarker,
    startMarker,
    yellowMarker,
    lastMarker,
    startFlag,
    endFlag,
    circleRedMarker
} from './Images';


const iconUrls = {
    markerColor: [
        blueMarker,
        orangeMarker,
        redMarker,
        greenMarker,
        pinkMarker,
        green2Marker,
        purpleMarker,
        yellowMarker,
        lightblueMarker,
        darkblueMarker,
        marker12,
        marker13,
        marker14,
        marker15,
        marker16
    ],
    startingPoint: startMarker,
    lastMarker,
    startFlag,
    endFlag,
    circleRedMarker
};

export default iconUrls;