import styled from 'styled-components';

const OutstandingWrapper = styled.div`

 .table-wrap{
       td{
        .filter-dropdown-card .filter-content{
         >ul svg{ 
          
            width: 0px;
    height: 0px;
    margin-right: 0px;
          }
        }
      
        .light{
          min-width: 90px;
          padding: 3px 4px;
          text-align:center;
          line-height:10px;
          font-size:10px;
          font-weight:600;
          white-space:nowrap;
          border-radius: 2px;
        }
      .in_progress{
        border:0;
        padding:0;
        .light{
           border: 1px solid #47C464;
          color: #287D3C
        }
      }
      .stopped{
        .light{
          border: 1px solid rgba(218, 20, 20, 0.4);
          color: #DA1414;
        }
      }
        .blacklisted{
        .light{
          border: 1px solid #A7A9BC;
          color: #272833;
        }
      }
    }
  }
  `;

export default OutstandingWrapper;
