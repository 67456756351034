import React from 'react';
import PropTypes from 'prop-types';
import withLoading from '../../../utils/composition/withLoading';
import { Button, Pagination } from '../../../components';
import { dataProps, paginationProps } from '../../../data/enums/Component';
import ReportTableStyled from '../../reports/ReportTableStyled';
import { PanelHeader } from '../../common/configuration';
import { subdStockReplenishment } from '../../common/DomainConfig';
import { Download } from '../../components/Download';
import { TableBody, TableHeader } from './tableConfig';

const propTypes = {
  data: dataProps.value,
  pagination: paginationProps.value,
  onPageChange: PropTypes.func,
  onCheckBoxClick: PropTypes.func,
  onIconClick: PropTypes.func,
};


const defaultProps = {
  data: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
  onPageChange: () => null,
  onCheckBoxClick: () => null,
  onIconClick: () => null,
};

const View = ({ ...props }) => {
  const {
    data,
    onPageChange,
    pagination,
    distributor,
    saveButtonClick,
    shareButtonClick,
    onHandleClick,
  } = props;
  return (
    <>
      <PanelHeader className="subd-sec-header">
        <h3>{`${subdStockReplenishment.title} for ${distributor.title}.`}</h3>
        <div className="subd-btns">
          <Download
            downloadConfig={{
              domain: subdStockReplenishment.domain,
            }}
            handleDownloadClick={onHandleClick}
          />
          <div>
            <Button className="save-btn" secondary iconBtnSmall onClick={() => saveButtonClick()}><img src=" /image/icons/save.svg" alt="save-file" /></Button>
          </div>
          <div>
            <Button secondary iconBtnSmall iconName="share" onClick={() => shareButtonClick()} />
          </div>
        </div>
      </PanelHeader>
      <ReportTableStyled>
        <div className="table-wrap">
          <table>
            {
            TableHeader()
          }
            <tbody>
              {data.list.map(replenishment => (
                TableBody({ data: replenishment })
              ))}
            </tbody>
          </table>
        </div>
      </ReportTableStyled>
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
        className="page-default"
      />
    </>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withLoading(View);

export default LoginViewWithErrorAndLoading;
