import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import StockLedgerView from './View';
import PageHeader from '../../base/PageHeader';
import { getUser } from '../../../data/services';
import { formConfig } from '../stockAdjustment/config';
import * as queryService from '../../base/query.service';
import { stockLedger } from '../../common/DomainConfig';
import { PROGRAM } from '../../../data/enums/Permission';
import { filterConfig, breadCrumbConfig } from './config';
import { FORM_CONFIG } from '../../../data/enums/config';
import { PanelStyled } from '../../common/configuration';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { ADMIN_ROLE_GROUP, DISTRIBUTOR_ASSOCIATED_ROLES } from '../../../data/enums/UserRole';
import { VIEW_TYPE } from '../../../data/enums/GeneralConstants';
import { getPermissionForStockLedger } from '../../base/permission';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';
import { checkAndResetPaginatedData } from '../../../utils/paginatedListUtils';
import { getCurrentDay } from '../../../utils/date';
import { getSortValueAfterValueCheck } from '../../common/HelperFunctions';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  serverResponseWaiting: PropTypes.bool,
  getStockLedgerList: PropTypes.func.isRequired,
  getDistributors: PropTypes.func.isRequired,
  downloadReport: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class StockLedger extends Component {
  constructor(props) {
    super(props);
    this.userInfo = getUser();
    this.state = {
      data: {
        list: [],
        total: 1,
      },
      display: {
        searchBox: false,
      },
      queryParameters: {
        sort: {},
        date: { start: getCurrentDay(), end: getCurrentDay() },
        filter: queryService.baseQueryParameters.filter,
        search: queryService.baseQueryParameters.search,
        pagination: queryService.baseQueryParameters.pagination,
      },
      distributorList: [],
      dialog: {
        type: '',
        element: formConfig[FORM_CONFIG.MAPPER]({
          distributorId: this.userInfo.Distributor.length > 0 ? this.userInfo.Distributor[0].id : 0,
        }),
      },
    };
    this.permission = getPermissionForStockLedger();
    this.componentConfig = stockLedger.componentsDisplay(this.permission);
    this.getData = () => {
      const { data } = this.state;
      return data;
    };
    this.setData = (data) => {
      this.setState({ data });
    };

    this.permission = getPermissionForStockLedger();

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      props.downloadReport,
      props.displayAlert,
    );
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount() {
    if (ADMIN_ROLE_GROUP.includes(this.userInfo.roleId)
    || DISTRIBUTOR_ASSOCIATED_ROLES.includes(this.userInfo.roleId)) {
      this.getDistributorsList();
    } else this.loadTableData();
  }

  loadTableData = () => {
    const {
      data, queryParameters, dialog,
    } = this.state;
    const { getStockLedgerList, displayAlert } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);

    getStockLedgerList({
      offset,
      limit: queryParameters.pagination.limit,
      filter: {
        filters: [...apiFilterProcessor(queryParameters.filter),
          {
            column: 'distributor_id',
            value: [dialog.element.distributorId.toString() || ''],
          }],
        dateRange: queryParameters.date,
        queryString: queryParameters.search,
        sort: getSortValueAfterValueCheck(queryParameters.sort),
      },
    }, {
      handleSuccess: (response) => {
        data.list = (response.data && response.data.stockLedgers && response.data.stockLedgers.rows) || [];
        data.total = (response.data && response.data.stockLedgers && response.data.stockLedgers.count) || 0;
        this.setState(data);
      },
      handleError: (error) => {
        checkAndResetPaginatedData(data, this.setData);
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  };

  getDistributorsList = () => {
    const { getDistributors, displayAlert } = this.props;
    getDistributors({ limit: 1000 }, {
      handleSuccess: (response) => {
        this.setState({ distributorList: response.data.distributors.rows });
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  };

  setQueryParameters = (queryParams, callBack = () => null) => this.setState(
    { queryParameters: queryParams },
    callBack,
  );

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  stateSetter = (stateField, value) => {
    this.setState({ [stateField]: value });
  };

  handleDistributorSelect = (distributorId) => {
    const { dialog } = this.state;
    dialog.element.distributorId = distributorId;

    this.setState({ dialog }, () => this.loadTableData());
  };

  handleDownloadClick = (reportType, domain) => {
    const { queryParameters, dialog } = this.state;

    const filter = {
      filters: [
        ...apiFilterProcessor(queryParameters.filter),
        {
          column: 'distributor_id',
          value: [dialog.element.distributorId.toString() || ''],
        },
      ],
      queryString: queryParameters.search,
      dateRange: queryParameters.date,
    };

    this.basePaginationService.handleDownloadClickWithCustomFilter('csv', domain, filter);
  };

  render() {
    const {
      data, queryParameters, display, filter, distributorList, dialog,
    } = this.state;
    const { serverResponseWaiting } = this.props;
    const { element } = dialog;

    return (
      <Fragment>
        <div className="section-header">
          <PanelStyled>
            <PageHeader
              display={display}
              breadCrumb={breadCrumbConfig}
              config={{
                title: stockLedger.title,
                filter: true,
                download: true,
                date: true,
                search: true,
              }}
              filter={{
                date: queryParameters.date,
                metaData: filter,
                menuList: filterConfig.menu,
                onFilterChange: this.basePaginationService.handleFilterChange,
              }}
              downloadConfig={{
                type: VIEW_TYPE.SUMMARY,
                domain: PROGRAM.STOCK_LEDGER,
                apiTitle: stockLedger.apiTitles.downloadSummary,

              }}
              queryParameters={queryParameters}
              controlDisplay={this.controlDisplay}
              resetFilter={this.basePaginationService.resetFilter}
              onFilterChange={this.basePaginationService.handleFilterChange}
              handleDownloadClick={this.handleDownloadClick}
              handleSearchChange={this.basePaginationService.handleSearchInputChange}
              handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
            />
          </PanelStyled>
        </div>
        <div className="section-content">
          <StockLedgerView
            data={data}
            inputData={element}
            breadCrumb={breadCrumbConfig}
            loading={serverResponseWaiting}
            pagination={queryParameters.pagination}
            componentDisplay={this.componentConfig}
            onPageChange={this.basePaginationService.onPageSelect}
            distributorList={distributorList}
            onDropDownChange={this.handleDistributorSelect}
            onHeaderClick={this.basePaginationService.handleTableSorting}
          />
        </div>
      </Fragment>
    );
  }
}

StockLedger.propTypes = propTypes;

StockLedger.defaultProps = defaultProps;

export default withAlert()(StockLedger);
