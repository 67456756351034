import React from 'react';
import { Link } from 'react-router-dom';
import SectionWithTitle from '../components/SectionWithTitle';
import NumberCard from '../components/NumberCard';
import Number from '../components/Number';
import MonthlyWrap from '../monthyDetail/MonthlyWrap';
import { fixedFloatAndComma } from '../../../utils/conversion';

const Inventory = ({ data }) => {
  const {
    cfr, availability, sufficiency, currentStock,
  } = data;
  return (
    <MonthlyWrap id="inventory">
      <SectionWithTitle title="inventory">
        <NumberCard title="cfr" subTitle="">
          <Number label="%" number={fixedFloatAndComma(cfr)} size={24} numberPostfix />
        </NumberCard>
        <Link to="/reports/subd-stock-availability">
          <NumberCard title="availability" subTitle="">
            <Number label="%" number={fixedFloatAndComma(availability)} size={24} numberPostfix />
          </NumberCard>
        </Link>
        <Link to="/reports/subd-stock-availability">
          <NumberCard title="sufficiency" subTitle="">
            <Number label="%" number={fixedFloatAndComma(sufficiency)} size={24} numberPostfix />
          </NumberCard>
        </Link>
        <Link to="/inventory/stock-ledger">
          <NumberCard title="current stock" subTitle="(cases)">
            <Number label="" number={currentStock} size={24} />
          </NumberCard>
        </Link>
      </SectionWithTitle>
    </MonthlyWrap>
  );
};

Inventory.propTypes = {};

export default Inventory;
