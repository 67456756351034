import { salesForce as salesForceRoute } from '../../../data/enums/Route';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { CONFIRMATION_CONFIG, FORM_CONFIG } from '../../../data/enums/config';
import { refGenerator } from '../../../utils';


const breadCrumb = [
  salesForceRoute,
];

const formConfig = {
  [EVENT_OPERATION.UPLOAD]: {
    [FORM_CONFIG.MAPPER]: element => ({
      file: element.file || {},
    }),
    [FORM_CONFIG.TITLE]: 'File Upload',
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['uploadEntity']),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
  [EVENT_OPERATION.SHARE]: {
    [FORM_CONFIG.TITLE]: 'Share with',
  },
  [EVENT_OPERATION.SAVE]: {
    [FORM_CONFIG.TITLE]: 'Confirmation Dialog',
  },
  ...CONFIRMATION_CONFIG,
};


export {
  breadCrumb as breadCrumbConfig, formConfig,
};
