import React, { Component, Fragment } from 'react';
import View from './View';
import { DialogWrapper } from '../../../../../common';
import ContactDialogWrapper from './ContactDialogWrapper';
import { dateTimeFormat } from '../../../../../../utils/date';

class Detail extends Component {
  render() {
    const {
      data,
      menu,
      crudMode,
      refsObj,
      loading,
      onInputChange,
      enableToUpdate,
      enableErrorDisplay,
      handleButtonSubmit,
      handleButtonCancel,
      onDropDownChange,
      handleEditIconClick,
      getGeoLocation,
      retailOutletHistory,
      getRetailOutletHistory,
      id,
    } = this.props;
    const title = 'Verified Details';
    return (
      <ContactDialogWrapper>
        <DialogWrapper
          title={title}
          footer
          withOutPadding
          renderDialog={() => (
            <div className="table-wrap">
              <table>
                <thead>
                  <tr>
                    <th>Updated Numbers</th>
                    <th>Updated By</th>
                    <th>Updated Time</th>
                  </tr>
                </thead>
                <tbody>
                  {retailOutletHistory.map(contact => (
                    <tr key={`contact-details-${contact.outletid}`}>
                      <td className={contact.isverified ? '' : 'txt-color'}>
                        {contact.phonenumber}
                        {contact.isverified ? (
                          <img src="/image/icons/VerifiedIcon.svg" />
                        ) : ''}
                      </td>
                      <td className={contact.isverified ? '' : 'txt-color'}>{contact.name}</td>
                      <td className={contact.isverified ? '' : 'txt-color'}>{dateTimeFormat(contact.updatedtime)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          render={({ onDialogItemClick }) => (
            <Fragment>
              <View
                data={data}
                id={id}
                menu={menu}
                crudMode={crudMode}
                refsObj={refsObj}
                loading={loading}
                onInputChange={onInputChange}
                onIconClick={onDialogItemClick}
                enableToUpdate={enableToUpdate}
                enableErrorDisplay={enableErrorDisplay}
                handleButtonSubmit={handleButtonSubmit}
                handleButtonCancel={handleButtonCancel}
                onDropDownChange={onDropDownChange}
                handleEditIconClick={handleEditIconClick}
                getGeoLocation={getGeoLocation}
                getRetailOutletHistory={getRetailOutletHistory}
                retailOutletHistory={retailOutletHistory}
              />
            </Fragment>
          )}
        />
      </ContactDialogWrapper>
    );
  }
}

export { Detail };

export default Detail;
