import React from 'react';
import PropTypes from 'prop-types';
import withLoading from '../../../utils/composition/withLoading';
import { Pagination } from '../../../components';
import { dataProps, paginationProps } from '../../../data/enums/Component';
import ReportTableStyled from '../ReportTableStyled';
import { TableBody, TableHeader } from './tableConfig';
import BulkSelect from '../../components/BulkSelect';
import EVENT_OPERATION from '../../../data/enums/EventOperation';

const propTypes = {
  data: dataProps.value,
  pagination: paginationProps.value,
  onPageChange: PropTypes.func,
  onCheckBoxClick: PropTypes.func,
  onIconClick: PropTypes.func,
  tableConfig: PropTypes.instanceOf(Object),
};


const defaultProps = {
  data: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
  onPageChange: () => null,
  onCheckBoxClick: () => null,
  onIconClick: () => null,
  tableConfig: {},
};

const View = ({ ...props }) => {
  const {
    data,
    onPageChange,
    pagination,
    tableConfig,
  } = props;

  return (
    <ReportTableStyled>
      <BulkSelect
        data={data.list}
        onIconClick={() => {}}
        Buttons={[{
          label: 'Dishonor',
          status: EVENT_OPERATION.DISHONOR,
        }, {
          label: 'Received',
          status: EVENT_OPERATION.RECEIVED,
        }]}
        render={(state, primaryCheckboxHandler, secondaryCheckboxHandler) => (
          <div className="table-wrap">
            <table>
              {
            TableHeader({
              tableConfig,
              dataListLength: data.list.length,
              primaryCheckboxHandler,
              checkedListLength: state.checkedList.length,
            })
          }
              <tbody>
                {data.list.map(productivity => (
                  TableBody({
                    data: productivity,
                    checkedList: state.checkedList,
                    tableConfig,
                    secondaryCheckboxHandler,
                  })
                ))}
              </tbody>
            </table>
          </div>)
        }
      />
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </ReportTableStyled>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withLoading(View);

export default LoginViewWithErrorAndLoading;
