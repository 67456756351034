import React from 'react';
import PropTypes from 'prop-types';
import { formConfig as form, SHELF_LIFE_REQUIRED_TAGS } from './config';
import Switch from '../../../../components/Switch/Switch';
import { Input, CustomSelect } from '../../../../components/index';
import { EMPTY, INVALID } from '../../../../data/enums/ErrorMessage';
import withLoading from '../../../../utils/composition/withLoading';
import EVENT_OPERATION from '../../../../data/enums/EventOperation';
import FormContentStyled from '../../../../components/global-css/FormContentStyled';

const propTypes = {
  data: PropTypes.instanceOf(Object),
  handleInputChange: PropTypes.func,
  handleDropDownChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  refsObj: PropTypes.instanceOf(Object),
  crudMode: PropTypes.string,
  uomList: PropTypes.instanceOf(Array).isRequired,
};

const defaultProps = {
  data: form.mapper({}),
  refsObj: {
    title: '',
  },
  enableErrorDisplay: true,
  crudMode: EVENT_OPERATION.READ,
  /**
   * agruments:
   * first param: input event (required),
   * second param: data's first depth targeted key,
   * third param:
   *  only if object depth greater than one,
   *  should contain list of object keys in accordance of depth upto end i.e target key
   */
  handleInputChange: () => null,
  handleDropdownChange: () => null,
};

const CreateSkuForm = ({ ...props }) => {
  const {
    data,
    refsObj,
    handleInputChange,
    handleDropDownChange,
    enableErrorDisplay,
    uomList,
    skuTagList,
    caseUnitList,
  } = props;

  return (
        <>
          <FormContentStyled>
            <h3 className="w-100">General Details</h3>
            <div className="form-input-wrap">
              <Input
                name="title"
                type="text"
                enableValidation
                value={data.title}
                labelContent="Name"
                placeholder="Name"
                ref={ref => refsObj.title = ref}
                enableErrorDisplay={enableErrorDisplay}
                onChange={event => handleInputChange(event)}
              />
              <Input
                type="text"
                name="description"
                placeholder="Manufacture Description"
                enableValidation
                labelContent="Manufacture Description"
                value={data.description}
                enableErrorDisplay={enableErrorDisplay}
                ref={ref => refsObj.manufactureDescription = ref}
                onChange={event => handleInputChange(event)}
              />
              <Input
                type="text"
                name="shortDescription"
                placeholder="Short Description"
                labelContent="Short Description"
                value={data.shortDescription}
                onChange={event => handleInputChange(event)}
              />
              <Switch
                onToggle={(checked, event) => {
                  handleInputChange(
                    {
                      formattedValue: checked,
                      target: { name: 'powerSku', type: 'boolean' },
                    },
                  );
                }
                }
                checked={data.powerSku}
                name="powerSku"
                labelContent="PSKU"
              />
            </div>
          </FormContentStyled>
          <FormContentStyled>
            <h3 className="w-100">SKU Details</h3>
            <div className="form-input-wrap">
              {/* <Input
                ref={ref => refsObj.fpc = ref}
                value={data.details.fpc}
                name="fpc"
                type="text"
                labelContent="FPC"
                enableErrorDisplay={enableErrorDisplay}
                enableValidation
                onChange={event => handleInputChange(event, 'details')}
              /> */}
              <Input
                ref={ref => refsObj.upc = ref}
                value={data.details.unitsPerCase}
                name="unitsPerCase"
                type="number"
                rule="isInt"
                argument={{ min: 0, allow_leading_zeroes: false }}
                labelContent="UPC"
                enableErrorDisplay={enableErrorDisplay}
                enableValidation
                errorMessage={data.details.unitsPerCase > 0 ? INVALID : EMPTY}
                onChange={event => handleInputChange(event, 'details')}
              />
              <Input
                ref={ref => refsObj.ean = ref}
                value={data.details.ean}
                name="ean"
                type="number"
                rule="isInt"
                labelContent="EAN"
                errorMessage={INVALID}
                enableErrorDisplay={enableErrorDisplay}
                enableValidation
                onChange={event => handleInputChange(event, 'details')}
              />
              <Input
                ref={ref => refsObj.gtin = ref}
                value={data.details.gtin}
                name="gtin"
                type="number"
                rule="isInt"
                argument={{ min: 0, allow_leading_zeroes: false }}
                labelContent="GTIN"
                errorMessage={data.details.gtin > 0 ? INVALID : EMPTY}
                enableErrorDisplay={enableErrorDisplay}
                enableValidation
                onChange={event => handleInputChange(event, 'details')}
              />
              <Input
                value={data.details.skuCode}
                name="skuCode"
                type="text"
                placeholder="Sku Code"
                labelContent="SKU Code"
                onChange={event => handleInputChange(event, 'details')}
              />

              <Input
                value={data.details.skuProductCode}
                name="skuProductCode"
                type="text"
                placeholder="Vendor Code"
                labelContent="Vendor Code"
                enableErrorDisplay={enableErrorDisplay}
                enableValidation
                onChange={event => handleInputChange(event, 'details')}
              />

              <Input
                value={data.details.erpCode}
                name="erpCode"
                type="text"
                placeholder="ERP Code"
                onChange={event => handleInputChange(event, 'details')}
                labelContent="ERP Code"
              />

              <Input
                value={data.details.standardUnit}
                name="standardUnit"
                type="number"
                decimalWithPrecedingZero
                argument={{ min: 0 }}
                labelContent="Standard Unit"
                onChange={event => handleInputChange(event, 'details')}
              />
              {/* <Input
                ref={ref => refsObj.packagingUnit = ref}
                value={data.details.packagingUnit}
                name="packagingUnit"
                type="text"
                placeholder="Packaging Unit"
                labelContent="Packaging Unit"
                enableErrorDisplay={enableErrorDisplay}
                enableValidation
                onChange={event => handleInputChange(event, 'details')}
              /> */}
              <Input
                value={data.details.minimumOrderQuantity}
                name="minimumOrderQuantity"
                type="number"
                rule="isInt"
                argument={{ min: 0, allow_leading_zeroes: false }}
                labelContent="Min. Order Qty"
                enableValidation
                enableErrorDisplay={enableErrorDisplay}
                errorMessage={data.details.minimumOrderQuantity > 0 ? INVALID : EMPTY}
                onChange={event => handleInputChange(event, 'details')}
              />

              <CustomSelect
                labelContent="UOM"
                name="uom"
                placeholder="Search"
                className="custom-select"
                options={uomList}
                getOptionValue={({ label }) => label}
                getOptionLabel={({ title }) => title}
                onChange={event => handleDropDownChange(event.label, ['uom'])}
                value={uomList.filter(({ label }) => label === data.uom)}
              />

              <Input
                value={data.dimension.length}
                name="length"
                type="number"
                rule="isFloat"
                placeholder="Length   (in Meters)"
                argument={{ min: 0 }}
                onChange={event => handleInputChange(event, 'dimension')}
                labelContent="Length  (in Meters)"
              />
              <Input
                value={data.dimension.width}
                name="width"
                type="number"
                rule="isFloat"
                placeholder="Width    (in Meters)"
                argument={{ min: 0 }}
                onChange={event => handleInputChange(event, 'dimension')}
                labelContent="Width"
              />

              <Input
                value={data.dimension.height}
                name="height"
                type="number"
                rule="isFloat"
                placeholder="Height   (in Meters)"
                argument={{ min: 0 }}
                onChange={event => handleInputChange(event, 'dimension')}
                labelContent="Height"
              />
              <Input
                value={data.weight}
                name="weight"
                type="number"
                rule="isFloat"
                placeholder="Weight   (in Grams)"
                argument={{ min: 0 }}
                onChange={event => handleInputChange(event)}
                labelContent="Weight"
              />
              <CustomSelect
                isMulti
                labelContent="SKU Tags"
                className="custom-select"
                options={skuTagList}
                placeholder="SKU Tags"
                getOptionValue={item => item}
                getOptionLabel={item => item}
                onChange={(event) => {
                  const skuTags = event.map(d => d);
                  handleDropDownChange(skuTags, ['skuTags']);
                }}
                value={skuTagList
                  .filter(item => (data.skuTags ? data.skuTags.includes(item) : false))}
              />
              {
                data.skuTags.includes(SHELF_LIFE_REQUIRED_TAGS.NON_FOOD) && (
                  <Input
                    value={data.shelfLife}
                    name="shelfLife"
                    type="text"
                    placeholder="Shelf Life (in Months)"
                    enableErrorDisplay={enableErrorDisplay}
                    enableValidation
                    errorMessage={data.shelfLife ? EMPTY : INVALID}
                    onChange={event => handleInputChange(event)}
                    ref={ref => (refsObj.shelfLife = ref)}
                    labelContent="Shelf Life"
                  />
                )
              }
              <CustomSelect
                labelContent="Case Unit"
                name="caseUnit"
                placeholder="Case Unit"
                className="custom-select"
                options={caseUnitList}
                getOptionValue={item => item}
                getOptionLabel={item => item}
                onChange={event => handleDropDownChange(event, ['caseUnit'])}
                value={caseUnitList.filter(label => label === data.caseUnit)}
              />
              <Input
                value={data.caseReportingUnit}
                name="caseReportingUnit"
                type="text"
                placeholder="UPC Reporting Unit"
                onChange={event => handleInputChange(event)}
                labelContent="UPC Reporting Unit"
              />
              <Switch
                onToggle={(checked, event) => {
                  handleInputChange(
                    {
                      formattedValue: checked,
                      target: { name: 'oneTimePurchase', type: 'boolean' },
                    },
                  );
                }
                }
                checked={data.oneTimePurchase}
                name="oneTimePurchase"
                labelContent="One Time Purchase"
              />
              <Input
                value={data.oneTimePurchaseQuantity}
                name="oneTimePurchaseQuantity"
                type="number"
                placeholder="Purchase Quantity"
                disabled={!data.oneTimePurchase}
                onChange={event => handleInputChange(event)}
                labelContent="Purchase Quantity"
              />
            </div>
          </FormContentStyled>
   </>
  );
};

CreateSkuForm.propTypes = propTypes;

CreateSkuForm.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withLoading(CreateSkuForm);

export default LoginViewWithErrorAndLoading;
