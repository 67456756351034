import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SectionWithTitle from '../components/SectionWithTitle';
import DSETableStyled from './DSETableStyled';
import { fixedFloatAndComma, fixedFloatAndCommas } from '../../../utils/conversion';
import { USER_ROLE_TITLE } from '../../../data/enums/UserRole';
import { headerLabelConfig } from '../../common/HelperFunctions';
import {
  Badge,
} from '../../../components';

const propTypes = {
  data: {
    rows: PropTypes.arrayOf(PropTypes.instanceOf({})).isRequired,
    total: PropTypes.objectOf(PropTypes.instanceOf({})).isRequired,
  },
  displayGoldenPoint: PropTypes.bool.isRequired,
};

const DseDetails = ({ data, displayGoldenPoint }) => {
  const { rows, total } = data;

  return (
    <SectionWithTitle title={headerLabelConfig[USER_ROLE_TITLE.DSE]} id="dse-detail">
      <DSETableStyled>
        <div className="dse-table-wrap">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Route</th>
                <th>Scheduled</th>
                <th>Successful</th>
                <th>Unsuccessful</th>
                <th>Remaining</th>
                <th>Productivity</th>
                {displayGoldenPoint && <th>Golden Point</th>}
                <th>PFD Target</th>
                <th>Received</th>
              </tr>
            </thead>
            <tbody>
              {rows.length !== 0 ? rows.map(dse => (
                <tr key={dse.id}>
                  <td
                    style={{ borderLeft: `4px solid ${dse.active ? dse.color : '#CDCED9'}` }}
                    className={dse.active ? '' : 'dse-disabled'}
                  >
                    <Link to={`/dashboard/dse-detail/${dse.id}`}>
                      <div className="flex-display ">

                        {dse.profileImage
                          ? <img src={dse.profileImage[0].image} alt="user-image" />
                          : <img className="user-image" src="/image/icons/user.svg" alt="user-image" />
                        }


                        <div className="user-name">
                          <span>
                            {dse.name}
                          </span>
                          <span className={(dse.status === 0) ? 'inactive'
                            : (dse.status === 2) ? 'onleave'
                              : 'active'
                          }
                          >
                            <span className="badge_border">
                              <Badge light>
                                {(dse.status === 0) ? 'INACTIVE'
                                  : (dse.status === 2) ? 'ON LEAVE'
                                    : 'ACTIVE'
                                }
                              </Badge>
                            </span>
                          </span>
                        </div>
                      </div>
                    </Link>
                  </td>
                  <td className={dse.active ? '' : 'dse-disabled'}>{dse.route}</td>
                  <td className={dse.active ? '' : 'dse-disabled'}>{dse.scheduled}</td>
                  <td className={dse.active ? '' : 'dse-disabled'}>{dse.successful}</td>
                  <td className={dse.active ? '' : 'dse-disabled'}>{dse.unsuccessful}</td>
                  <td className={dse.active ? '' : 'dse-disabled'}>{dse.remaining}</td>
                  <td className={dse.active ? '' : 'dse-disabled'}>{dse.productivity && `${fixedFloatAndComma(dse.productivity)}%`}</td>
                  {displayGoldenPoint && (
                    <td className={dse.active ? '' : 'dse-disabled'}>
                      {fixedFloatAndCommas(dse.goldenpoint)}
                    </td>
                  )}
                  <td className={dse.active ? '' : 'dse-disabled'}>
                    {fixedFloatAndCommas(dse.target)}
                  </td>
                  <td className={dse.active ? '' : 'dse-disabled'}>
                    {fixedFloatAndCommas(dse.received)}
                  </td>
                </tr>
              )) : <tr className="loading" />}

            </tbody>
            <tfoot>
              <tr>
                <td>Total</td>
                <td />
                <td>{total.scheduled}</td>
                <td>{total.successful}</td>
                <td>{total.unsuccessful}</td>
                <td>{total.remaining}</td>
                <td>{`${fixedFloatAndComma(total.productivity)}%`}</td>
                {displayGoldenPoint && <td>{fixedFloatAndCommas(total.goldenpoint)}</td>}
                <td>{fixedFloatAndCommas(total.target)}</td>
                <td>{fixedFloatAndCommas(total.received)}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </DSETableStyled>
    </SectionWithTitle>

  );
};

DseDetails.propTypes = propTypes;

export default DseDetails;
