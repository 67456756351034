import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
// import { navigationConfig } from './config';
// TODO enable event listener once it gets done.
import {
  addMouseClickListener,
  removeMouseClickListener,
} from '../../utils/event';
import { has } from '../../utils/objectPrototypes';
import { Icon } from '../../components/index';
import history from '../../utils/history';
import { ALERT_TYPE } from '../../data/enums/AlertType';
import WithAlert from '../../utils/composition/withAlert';
import { JWT } from '../../environment';
import withLoading from '../../utils/composition/withLoading';

const propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class Navigation extends Component {
  state = {
    showSecondaryNavigation: false,
    primaryNavigation: '',
    secondaryNavigation: [],
    // eslint-disable-next-line react/no-unused-state
    hasError: false,
    config: [],
  };

  navigationRef = React.createRef();

  static getDerivedStateFromError(error) {
    return { hasError: false };
  }

  componentDidMount() {
    addMouseClickListener(this.onMouseClick);
    this.loadConfig();
  }

  loadConfig = () => {
    const parsedConfig = localStorage.getItem(JWT.SESSION_STORAGE.APP_STORAGE.PAGE);

    const config = JSON.parse(parsedConfig).sort((a, b) => a.id - b.id) || [];
    this.setState({ config });
  };

  componentWillUnmount() {
    removeMouseClickListener(() => null);
  }

  onMouseClick = (event) => {
    // eslint-disable-next-line max-len
    const clickedInsideDiv = this.navigationRef && this.navigationRef.current
      && this.navigationRef.current.contains(event.target);
    if (!clickedInsideDiv) {
      this.setState({ showSecondaryNavigation: false });
    }
  };

  handlePrimaryNavigationClick = (element) => {
    if (element.title === 'Dashboard') {
      history.push('/dashboard');
      this.setState({
        showSecondaryNavigation: false,
      });
    } else {
      this.setState({
        showSecondaryNavigation: true,
        primaryNavigation: element.title,
        secondaryNavigation: element.children,
      });
    }
  };

  getPrimaryNavigation = () => this.state.config.map((element) => {
    const splittedPrimaryUrl = window.location.pathname.split('/')[1];
    const isActive = splittedPrimaryUrl
      && splittedPrimaryUrl.toUpperCase() === element.icon.toUpperCase();
    return (
      <div
        key={`primary-nav-${element.title}`}
        className={isActive ? 'menu-item-link active' : 'menu-item-link'}
        data-text={element.title}
        onClick={() => this.handlePrimaryNavigationClick(element)}
      >
        <div className="img-holder">
          <img
            src={`/image/navigation-icons/${element.icon}.svg`}
            alt={element.title}
          />
        </div>

        <p>{element.title}</p>
      </div>
    );
  });

  getSecondaryNavigation = (baseNavigationLevel = false) => {
    const { secondaryNavigation } = this.state;

    const secNav = secondaryNavigation.map((nav) => {
      if (!has.call(nav, 'children') || !nav.children) {
        if (baseNavigationLevel) {
          return (
            <div className="no-title-nav">{this.getNavigationLink(nav)}</div>
          );
        }
        return this.getNavigationLink(nav);
      }

      return (
        <div className="sec-nav-content">
          <h3 className="sec-title">{nav.title}</h3>
          {
            nav && nav.children ? (
              <div className="sec-nav-inner">
                {nav.children.map((secNavigation) => {
                  if (!has.call(secNavigation, 'children')) {
                    return this.getNavigationLink(secNavigation);
                  }

                  return (
                    <div className="sec-nav-sub-menu">
                      <h3 className="sec-nav-link-title">{secNavigation.title}</h3>
                      {secNavigation.children.map(tertiaryNavigation => this.getNavigationLink(tertiaryNavigation))}
                    </div>
                  );
                })}
              </div>
            ) : null
          }
        </div>
      );
    });

    const secondaryNavigationHeader = this.getSecondaryNavigationHeader();

    return (
      <Fragment>
        <Fragment>{secondaryNavigationHeader}</Fragment>
        <div className="no-title-nav-wrap">
          {secNav}
        </div>
      </Fragment>
    );
  };

  getSecondaryNavigationHeader = () => {
    const { primaryNavigation } = this.state;
    return <h3 className="title">{primaryNavigation}</h3>;
  };

  onNavURLClick = () => {
    this.setState({ showSecondaryNavigation: false });
  };

  getNavigationLink = (nav) => {
    const isActive = window.location.pathname.includes(nav.url);
    return (
      <div className={isActive ? 'sec-nav-link active' : 'sec-nav-link'}>
        <Link to={nav.url} onClick={() => this.onNavURLClick()}>
          {nav.title}
        </Link>
      </div>
    );
  };

  profile = () => {
    history.push('/user-profile');
  };

  logOut = (e) => {
    e.stopPropagation();
    const { logout, displayAlert } = this.props;
    logout({}, {
      handleSuccess: () => {
        history.push('/login');
        localStorage.clear();
        sessionStorage.clear();
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  };

  render() {
    const { firstName, lastName } = JSON.parse(localStorage.getItem('rosia-detail'));
    const { showSecondaryNavigation } = this.state;
    const baseNavigationLevel = true;
    const secondaryNavigation = this.getSecondaryNavigation(
      baseNavigationLevel,
    );
    return (
      <Fragment>
        <div ref={this.navigationRef}>
          <div className="menu-wrap">
            <div className="primary-nav">{this.getPrimaryNavigation()}</div>
            <div className="other-nav text-center">
              <div className="nav-list">
                <Icon iconName="bell-on" />
              </div>
              <div className="nav-list profile" onClick={() => this.profile()}>
                <span>
                  {`${firstName.split('')[0].toUpperCase()}${lastName.split('')[0].toUpperCase()}`}
                </span>
              </div>
              <div className="nav-list ">
                <img
                  src="/image/navigation-icons/logout.svg"
                  alt="logoutico"
                  onClick={this.logOut}
                />
              </div>
            </div>
          </div>
          {showSecondaryNavigation && (
            <div>
              <div className="off-canvas-sec-nav">{secondaryNavigation}</div>
            </div>
          )}
        </div>

        {showSecondaryNavigation && <div className="navigation-overlay" />}
      </Fragment>
    );
  }
}

Navigation.propTypes = propTypes;

Navigation.defaultProps = defaultProps;

export default WithAlert()(withLoading(Navigation));
