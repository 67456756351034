import gql from 'graphql-tag';
import { TOGGLE_STATE } from '../../common/GqlAPI';

const CHANNEL_CATEGORY_FRAGMENT = gql`
    fragment ChannelCategoryFragment on Channel {
        id
        title
        status: active
        type: channelType
        categories: Categories {
            id
            title
            active,
        }
    }
`;
const SUMMARY_CHANNEL_FRAGMENT = gql`
    fragment SummaryChannelFragment on Channel {
        id
        title
       active
        categories: Categories { id }
    }
`;

const GET_NEW_CHANNEL_LIST = gql`
    query getChannel($offset: Int, $limit: Int, $filter: FilterInput) {
        channels(offset: $offset, limit: $limit, filter: $filter) {
            rows {
                ...ChannelCategoryFragment
            }
            count
        }
    }
    ${CHANNEL_CATEGORY_FRAGMENT}
`;

const query = {
  getChannelList: GET_NEW_CHANNEL_LIST,
};

const mutation = {
  toggleState: TOGGLE_STATE
};

export { query, mutation, CHANNEL_CATEGORY_FRAGMENT, GET_NEW_CHANNEL_LIST };
