import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Icon,
  Badge, BreadCrumb,
} from '../../../../../components/index';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import withLoading from '../../../../../utils/composition/withLoading';
import { PanelStyled, PanelHeader } from '../../../../common/configuration';
import OutletDetailWrap from '../../../../sales/route-outlet/outlet/detail/OutletDetailStyled';
import General from './General';

const propTypes = {
  type: PropTypes.string,
  billingUsers: PropTypes.instanceOf(Array),
  data: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    roleId: PropTypes.number,
    lastName: PropTypes.string,
    password: PropTypes.string,
    firstName: PropTypes.string,
    phoneNumber: PropTypes.string,
    parentUserId: PropTypes.number,
  }),
  onInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  handleDropDownChange: PropTypes.func,
  refsObj: PropTypes.instanceOf(Object),
  menu: PropTypes.instanceOf(Object).isRequired,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  parentUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  ),
  handleButtonCancel: PropTypes.func.isRequired,
  handleButtonSubmit: PropTypes.func.isRequired,
  crudMode: PropTypes.oneOf([
    EVENT_OPERATION.READ,
    EVENT_OPERATION.CREATE,
    EVENT_OPERATION.UPDATE]).isRequired,
  billingPermission: PropTypes.bool.isRequired,
  billingStatus: PropTypes.bool.isRequired,
  handleEditIconClick: PropTypes.func.isRequired,
  breadCrumb: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  data: {
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    password: '',
    roleId: 0,
    parentUserId: 0,
    billingStatus: true,
  },
  refsObj: {},
  roles: [],
  parentUsers: [],
  enableErrorDisplay: true,
  type: EVENT_OPERATION.CREATE,
  onInputChange: () => null,
  handleDropDownChange: () => null,
  billingUsers: [],
};

const UserAccountForm = ({ ...props }) => {
  const {
    data,
    roles,
    refsObj,
    parentUsers,
    onInputChange,
    enableErrorDisplay,
    handleDropDownChange,
    handleButtonCancel,
    handleButtonSubmit,
    billingUsers,
    crudMode,
    billingPermission,
    handleEditIconClick,
    menu,
    billingStatus,
    breadCrumb,
    permission,
    enableUniqueFieldError,
    getHeader,
    loading,
    resetPassword,
  } = props;

  const editableOption = ((crudMode !== EVENT_OPERATION.CREATE) && permission.update);
  const showEditButton = crudMode === EVENT_OPERATION.READ;

  // eslint-disable-next-line max-len
  const roleInfo = roles.length !== 0 && data.roleId !== 0 && (roles.find(d => d.id === data.roleId).name);
  const header = getHeader();

  return (
    <Fragment>
      <div className="section-header">
        <PanelStyled>
          <BreadCrumb list={breadCrumb} />
          <PanelHeader>
            <h2 className="user-title">
              {(crudMode === EVENT_OPERATION.CREATE) ? (
                'Create User'
              ) : (
                <span className="badge_border">
                  {header}
                  {crudMode !== EVENT_OPERATION.UPDATE && <Badge light>{roleInfo || 'badge'}</Badge>}
                </span>
              )}
            </h2>
            <div className="flex m-0">
              {crudMode !== EVENT_OPERATION.READ && (
              <div className="button-wrap">
                <Button
                  secondary
                  small
                  disabled={loading}
                  title="Cancel"
                  onClick={() => handleButtonCancel()}
                />
                <Button
                  primary
                  small
                  disabled={loading}
                  title="Save"
                  onClick={() => handleButtonSubmit()}
                />
              </div>
              )}
              {showEditButton ? (
                <div>
                  {
                      permission.update && (
                        <Button
                          secondary
                          iconBtnSmall
                          onClick={handleEditIconClick}
                          className="ml-16"
                        >
                          <Icon iconName="pencil" />
                        </Button>
                      )
                    }
                </div>
              ) : ''
                }
            </div>
          </PanelHeader>

        </PanelStyled>
      </div>
      <div className="section-content section-tab custom-height">
        {
            editableOption ? (

              <OutletDetailWrap>
                <Tabs>
                  <TabList>
                    <Tab>Details</Tab>
                  </TabList>
                  <TabPanel>
                    <General
                      data={data}
                      roles={roles}
                      refsObj={refsObj}
                      parentUsers={parentUsers}
                      onInputChange={onInputChange}
                      enableErrorDisplay={enableErrorDisplay}
                      handleDropDownChange={handleDropDownChange}
                      handleButtonCancel={handleButtonCancel}
                      handleButtonSubmit={handleButtonSubmit}
                      billingUsers={billingUsers}
                      crudMode={crudMode}
                      billingPermission={billingPermission}
                      handleEditIconClick={handleEditIconClick}
                      menu={menu}
                      billingStatus={billingStatus}
                      enableUniqueFieldError={enableUniqueFieldError}
                      resetPassword={resetPassword}
                    />
                  </TabPanel>
                </Tabs>
              </OutletDetailWrap>
            ) : (
              <General
                data={data}
                roles={roles}
                refsObj={refsObj}
                parentUsers={parentUsers}
                onInputChange={onInputChange}
                enableErrorDisplay={enableErrorDisplay}
                handleDropDownChange={handleDropDownChange}
                handleButtonCancel={handleButtonCancel}
                handleButtonSubmit={handleButtonSubmit}
                billingUsers={billingUsers}
                crudMode={crudMode}
                billingPermission={billingPermission}
                handleEditIconClick={handleEditIconClick}
                menu={menu}
                billingStatus={billingStatus}
                enableUniqueFieldError={enableUniqueFieldError}
              />

            )
          }
      </div>
    </Fragment>
  );
};

UserAccountForm.propTypes = propTypes;

UserAccountForm.defaultProps = defaultProps;

const UserAccountDetailForm = withLoading(UserAccountForm);

export default UserAccountDetailForm;
