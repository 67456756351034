import gql from 'graphql-tag';
import { DOWNLOAD_REPORT } from '../../common/GqlAPI';

const GET_PRODUCTIVITY_LIST = gql`
    query getProductivityReport($offset: Int, $limit: Int, $filter: FilterInput) {
        getProductivityReport(offset: $offset, limit: $limit, filter: $filter) {
            rows {
                route
                town
                zone
                distributor
                dse
                stl
                asm
                scheduled
                successful
                unsuccessful
                calls_made
                calls_not_performed
                productivity
            }
            count
        }
    }
`;

const query = {
  getProductivityReport: GET_PRODUCTIVITY_LIST,
};

const mutation = {
  downloadReport: DOWNLOAD_REPORT,
};

export {
  DOWNLOAD_REPORT,
};

export { query, mutation };
