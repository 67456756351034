import PropTypes from 'prop-types';
import React, { Component } from 'react';
import WeeklyView from './Main';
import DSEWeeklyView from './DSEPresentor';
import { WeeklyStyled } from './WeeklyViewStyled';

const propTypes = {
  /**
   * @default null
   * Distributor Name and Header in WeeklyView
   */
  header: PropTypes.node,
  /**
   * @default 20120-01-01, 2020-01-08
   * start and end for week
   */
  weekRange: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
  /**
   * @default []
   * data for WeeklyView
   */
  data: PropTypes.instanceOf(Array),
  /**
   * @default []
   * list of dates in weekRange including start and end
   */
  dateList: PropTypes.instanceOf(Array),
  /**
   * @default null
   * function while clicking calendar
   */
  onCalendarClick: PropTypes.func,
  /**
   * @default []
   * weekEnds for given Sub D
   */
  weekEndList: PropTypes.instanceOf(Array),
};

const defaultProps = {
  header: (<span>Amazon Distributor Patan</span>),
  data: [],
  weekRange: {
    start: '2019-12-01',
    end: '2019-12-07',
  },
  dateList: ['2019-01-01', '2019-01-02', '2019-01-03', '2019-01-04', '2019-01-05', '2019-01-06', '2019-01-07'],
  onCalendarClick: () => null,
  weekEndList: [],
};

class SuperMain extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const {
      weekRange,
      weekEndList,
      data,
      dateList,
      onCalendarClick,
      userList,
      holidayList,
      leaveList,
      header,
    } = this.props;

    return (
      <WeeklyStyled>
        <div className="roster-calendar-wrap">
          <WeeklyView
            header={header}
            weekRange={weekRange}
            weekEndList={weekEndList}
            holidayList={holidayList}
          />
          <DSEWeeklyView
            weekEndList={weekEndList}
            holidayList={holidayList}
            leaveList={leaveList}
            data={data}
            userList={userList}
            dateList={dateList}
            onDseDateClick={onCalendarClick}
          />
        </div>
      </WeeklyStyled>
    );
  }
}

SuperMain.propTypes = propTypes;

SuperMain.defaultProps = defaultProps;

export default SuperMain;
