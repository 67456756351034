import React, { Component } from 'react';
import { extractSingleItemFromList } from '../../../../../../utils/arrayProcessor';
import BulkTransferView from './BulkTransferView';

class BulkTransfer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkedList: props.checkedList,
            primarySelected: false,
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.checkedList !== state.checkedList) {
            return {
                checkedList: props.checkedList,
            };
        }
        return null;
    }

    handlePrimaryCheckboxClick = (e) => {
        const { data } = this.props;
        if (e.target.checked) {
            const checkedList = extractSingleItemFromList(data);
            this.setState({ checkedList });
        } else {
            this.setState({ checkedList: [] });
        }
    };

    handleSecondaryCheckboxClick = (id) => {
        const { checkedList } = this.state;
        const index = checkedList.indexOf(id);
        if (index > -1) {
            checkedList.splice(index, 1);
        } else {
            checkedList.push(id);
        }
        this.setState({ checkedList });
    };

    resetCheckedList = () => {
        this.setState({ checkedList: [] })
    }



    render() {
        const { render, onTransferClick } = this.props;
        const { checkedList } = this.state;

        return (
            <>

                <BulkTransferView
                    checkedListLength={checkedList.length}
                    checkedList={checkedList}
                    onIconClick={onTransferClick}
                />
                {render(
                    this.state,
                    this.handlePrimaryCheckboxClick,
                    this.handleSecondaryCheckboxClick,
                    this.resetCheckedList
                )}
            </>
        )
    }
}

export default BulkTransfer;