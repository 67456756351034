import React from 'react';
import PropTypes from 'prop-types';
import {
  Input, Form, SingleDatePicker, CheckBox,
} from '../../../../components/index';
import withLoading from '../../../../utils/composition/withLoading';
import { hyphenPresentor } from '../../../../utils/date';

const propTypes = {
  data: PropTypes.shape({
    usageDate: PropTypes.instanceOf(Object),
    priceDetails: PropTypes.instanceOf(Object),
    batchDetails: PropTypes.instanceOf(Object),
  }),
  handleInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  refsObj: PropTypes.instanceOf(Object),
};

const defaultProps = {
  data: {
    usageDate: {
      batchNumber: '',
    },
    priceDetails: {
      rlp: 0,
      dlp: 0,
      vatPercentage: 0,
    },
    batchDetails: {
      manufacture: hyphenPresentor(new Date().toDateString()),
      expiry: hyphenPresentor(new Date().toDateString()),
    },
  },
  refsObj: {
    title: '',
  },
  enableErrorDisplay: true,
  /**
     * agruments:
     * first param: input event (required),
     * second param: data's first depth targeted key,
     * third param:
     *  only if object depth greater than one,
     *  should contain list of object keys in accordance of depth upto end i.e target key
     */
  handleInputChange: () => null,

};

const CreateSkuForm = ({ ...props }) => {
  const {
    data,
    refsObj,
    handleInputChange,
    enableErrorDisplay,
  } = props;

  return (
    <Form>
      <Input
        ref={ref => refsObj.batchNumber = ref}
        value={data.batchDetails.batchNumber}
        name="batchNumber"
        type="text"
        placeholder="Batch number"
        enableErrorDisplay={enableErrorDisplay}
        enableValidation
        onChange={event => handleInputChange(event, 'batchDetails')}
        labelContent="Batch"
      />

      <div className="form-input rate-form-input">
        <label>Mfg Date</label>
        <SingleDatePicker
          date={data.usageDate.manufacture}
          name="manufacture"
          placeholder="Manufacture Date"
          enableErrorDisplay={enableErrorDisplay}
          enableValidation
          onChange={(name, date) => handleInputChange({
            target: {
              name: 'manufacture',
              value: hyphenPresentor(date),
            },
            formattedValue: hyphenPresentor(date),
          }, 'usageDate')}
          labelContent="Manufacture Date"
          maxDate={data.usageDate.expiry}
        />
      </div>

      <div className="form-input rate-form-input">
        <label>Exp Date</label>
        <SingleDatePicker
          date={data.usageDate.expiry}
          name="expiry"
          placeholder="Expiry Date"
          enableErrorDisplay={enableErrorDisplay}
          enableValidation
          onChange={(name, date) => handleInputChange({
            target: {
              name: 'expiry',
              value: hyphenPresentor(date),
            },
            formattedValue: hyphenPresentor(date),
          }, 'usageDate')}
          labelContent="Exp Date"
          minDate={data.usageDate.manufacture}
        />
      </div>

      <Input
        ref={ref => refsObj.dlp = ref}
        value={data.priceDetails.dlp}
        name="dlp"
        type="number"
        decimalWithPrecedingZero
        placeholder="DLP"
        argument={{ min: 0 }}
        enableErrorDisplay={enableErrorDisplay}
        enableValidation
        onChange={event => handleInputChange(event, 'priceDetails')}
        labelContent="DLP"
      />
      <Input
        ref={ref => refsObj.rlp = ref}
        value={data.priceDetails.rlp}
        name="rlp"
        type="number"
        decimalWithPrecedingZero
        placeholder="RLP"
        argument={{ min: 0 }}
        enableErrorDisplay={enableErrorDisplay}
        enableValidation
        onChange={event => handleInputChange(event, 'priceDetails')}
        labelContent="RLP"
      />
      <CheckBox
        checked={data.priceDetails.mrpStatus}
        labelText="MRP / SRP"
        onCheckBoxClick={() => handleInputChange({
          target: {
            name: 'mrpStatus',
            value: !data.priceDetails.mrpStatus,
          },
          formattedValue: !data.priceDetails.mrpStatus,
        }, 'priceDetails')}
      />
      <Input
        value={data.priceDetails.mrpSrp}
        name="mrpSrp"
        type="number"
        decimalWithPrecedingZero
        placeholder="MRP / SRP"
        argument={{ min: 0 }}
        enableErrorDisplay={enableErrorDisplay}
        enableValidation
        onChange={event => handleInputChange(event, 'priceDetails')}
      />
      {/* <Input
        ref={ref => refsObj.vatPercentage = ref}
        value={data.priceDetails.vatPercentage}
        name="vatPercentage"
        type="number"
        rule="isFloat"
        placeholder="VAT"
        argument={{ min: 0}}
        disabled={true}
        enableErrorDisplay={enableErrorDisplay}
        enableValidation
        onChange={event => handleInputChange(event, 'priceDetails')}
        labelContent='VAT'
      /> */}
    </Form>
  );
};

CreateSkuForm.propTypes = propTypes;

CreateSkuForm.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withLoading(CreateSkuForm);

export default LoginViewWithErrorAndLoading;
