import styled from 'styled-components';

const PanelStyled = styled.div`
  padding: 16px 48px 25px;
  padding-bottom: ${(props) =>
    props.className === 'padding-0' ? '16px' : ' '};

  .filter-wrap {
    display: flex;
    align-items: center;
  }

  .pos-abs {
    position: absolute;
    top: -66px;
    right: 0;
  }
`;

const PanelHeader = styled.div`
  margin-top: 21px;
  display: flex;
  justify-content: space-between;
  align-items:center;
  h2 {
    margin: 0;
    font-size: 24px;
    color: #272833;
    line-height: 32px;
    &.long-title{
      font-size:18px;
      line-height:24px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width:54%;
    }
    > span {
    text-transform: capitalize;
  }
  }
  
  button.button-create {
    margin-left: 16px;
  }
.filter-wrap .view-btns{
  order:-1;
  border-right:1px solid #CDCED9;
  margin-right:16px !important;
  .table-view-ico{
    svg{
      position:relative;
      top:-2px;
    }
  }
  .map-view-ico{
    margin-right:16px;
  }
}
`;

export { PanelStyled, PanelHeader };
