import React from 'react';
import PropTypes from 'prop-types';
import { tableData } from '../../../utils/paginatedListUtils';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { USER_ROLE_TITLE } from '../../../data/enums/UserRole';
import { headerLabelConfig } from '../../common/HelperFunctions';
import RoundedLetter from '../../components/RoundedLetter';

const labelMappings = {
  outletId: 'outletId',
  outlet: 'outlet',
  status: 'status',
  currentStatus: 'currentStatus',
  channel: 'channel',
  category: 'category',
  distributor: 'distributor',
  route: 'route',
  town: 'town',
  brand: 'brand',
  sku: 'sku',
  dse: 'dse',
  qty: 'qty',
  grossAmount: 'grossAmount',
  netAmount: 'netAmount',
  promotionAmt: 'promotionAmt',
  promotionTitle: 'promotionTitle',
  discountAmt: 'discountAmt',
  discountTitles: 'discountTitles',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.outletId,
    title: 'Outlet ID',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.outlet,
    title: 'Outlet',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.status,
    title: 'Status',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.currentStatus,
    title: 'Current Status',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.channel,
    title: 'Channel',
    show: true,
  }, {
    id: 6,
    label: labelMappings.category,
    title: 'Category',
    show: true,
  },
  {
    id: 7,
    label: labelMappings.distributor,
    title: 'Distributor',
    show: true,
  },
  {
    id: 8,
    label: labelMappings.route,
    title: 'Route',
    show: true,
  },
  {
    id: 9,
    label: labelMappings.town,
    title: 'Town',
    show: true,
  },
  {
    id: 10,
    label: labelMappings.brand,
    title: 'Brand',
    show: true,
  },
  {
    id: 11,
    label: labelMappings.sku,
    title: 'SKU',
    show: true,
  },
  {
    id: 12,
    label: labelMappings.dse,
    title: headerLabelConfig[USER_ROLE_TITLE.DSE],
    show: true,
  },
  {
    id: 13,
    label: labelMappings.qty,
    title: 'QTY',
    show: true,
  },
  {
    id: 14,
    label: labelMappings.grossAmount,
    title: 'Gross Amount',
    show: true,
  },
  {
    id: 15,
    label: labelMappings.netAmount,
    title: 'Net Amount',
    show: true,
  },
  {
    id: 16,
    label: labelMappings.promotionAmt,
    title: 'Promotion Amount',
    show: true,
  },
  {
    id: 17,
    label: labelMappings.promotionTitle,
    title: 'Promotion Title',
    show: true,
  },
  {
    id: 18,
    label: labelMappings.discountAmt,
    title: 'Discount Amount',
    show: true,
  },
  {
    id: 19,
    label: labelMappings.discountTitles,
    title: 'Discount Titles',
    show: true,
  },

];

const TableHeader = () => (
  <thead className="">
    <tr>
      {labelConfig.map(label => (label.show
        ? tableData(label.title, label.align, label.classNames) : null))}
    </tr>
  </thead>
);

const returnTableBody = (data) => {
  const table = {};

  table[labelMappings.outletId] = (
    <td key={`${data.id}-requestedDate`}>{data.outlet_id}</td>
  );
  table[labelMappings.outlet] = (
    <td key={`${data.id}-outlet`}>
      {data.outlet}
    </td>
  );
  table[labelMappings.status] = (
    <td key={`${data.id}-status`}>
      {data.status}
    </td>
  );
  table[labelMappings.currentStatus] = (
    <td key={`${data.id}-currentStatus`}>
      {data.current_status}
    </td>
  );
  table[labelMappings.channel] = (
    <td key={`${data.id}-channel`}>
      {data.channel}
    </td>
  );
  table[labelMappings.category] = (
    <td key={`${data.id}-category`}>
      {data.category}
    </td>
  );
  table[labelMappings.distributor] = (
    <td key={`${data.id}-distributor`}>
      {data.distributor}
    </td>
  );
  table[labelMappings.route] = (
    <td key={`${data.id}-route`}>
      {data.route}
    </td>
  );
  table[labelMappings.town] = (
    <td key={`${data.id}-town`}>
      {data.town}
    </td>
  );
  table[labelMappings.brand] = (
    <td key={`${data.id}-brand`}>{data.brand}</td>
  );
  table[labelMappings.sku] = (
    <td key={`${data.id}-sku`}>
      {data.sku}
    </td>
  );
  table[labelMappings.dse] = (
    <td key={`${data.id}-dse`}>
      {data.dse}
      &nbsp;
      {data.isRetailerOrder ? <RoundedLetter letter="r" color="#FF73C3" /> : ''}
    </td>
  );
  table[labelMappings.qty] = (
    <td key={`${data.id}-qty`}>
      {data.quantity}
    </td>
  );
  table[labelMappings.grossAmount] = (
    <td key={`${data.id}-grossAmount`} className="text-right">
      {fixedFloatAndCommas(data.gross_amount)}
    </td>
  );
  table[labelMappings.netAmount] = (
    <td key={`${data.id}-netAmount`} className="text-right">
      {fixedFloatAndCommas(data.net_amount)}
    </td>
  );
  table[labelMappings.promotionAmt] = (
    <td key={`${data.id}-promotionAmt`} className="text-right">
      {fixedFloatAndCommas(data.promotion_amount)}
    </td>
  );
  table[labelMappings.promotionTitle] = (
    <td key={`${data.id}-promotionTitle`} title={data.promotion_title}>
      {data.promotion_title}
    </td>
  );
  table[labelMappings.discountAmt] = (
    <td key={`${data.id}-discountAmt`} className="text-right">
      {fixedFloatAndCommas(data.discount_amount)}
    </td>
  );
  table[labelMappings.discountTitles] = (
    <td key={`${data.id}-discountTitles`} title={data.bill_discount_titles ? data.bill_discount_titles.toString() : '-'}>
      {data.bill_discount_titles ? data.bill_discount_titles.toString() : '-'}
    </td>
  );

  return table;
};

const TableBody = ({
  data,
}) => {
  // Expanding/Dragging and collapsing table body.
  // color based upon table body
  // tooltip if it is sorted.
  // active className
  const tableBody = returnTableBody(data);

  return (

    <tr key={`osr-${data.id}`}>
      {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
};

TableBody.defaultProps = {
  data: {},
};

export {
  TableHeader,
  TableBody,
};
