import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Input } from '../../../../../components';
import { SingleDatePicker } from '../../../../../components/DatePicker';

const BasicDetail = ({ basicDetail: { title, start, end } }) => (
  <section className="sbd-top-wrap disabled">
    <Row>
      <Col md={4}>
        <Input name="title" type="text" value={title} labelContent="Title" />
      </Col>
      <Col md={4}>
        <div className="custom-date">
          <label>Start Date</label>
          <SingleDatePicker onChange={() => {}} date={start} name="start" labelContent="Start Date" />
        </div>
      </Col>
      <Col md={4}>
        <div className="custom-date">
          <label>End Date</label>
          <SingleDatePicker onChange={() => {}} date={end} name="end" labelContent="End Date" />
        </div>
      </Col>
    </Row>
  </section>
);

export default BasicDetail;
