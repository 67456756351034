import styled from 'styled-components';

const SubDRepStyled = styled.div`
.section-content{
.upload-section{
  padding:48px;
}
.subd-sec-header{
  margin:0;
  padding:30px 48px;
  h3{
  margin:0;
  font-size:14px;
  line-height:21px;
  color:#272833;
}
.subd-btns{
  display:flex;
  &>div{
    margin-right:16px;
    &:last-child{
      margin-right:0;
    }
    button{
      margin:0;
      &.save-btn{
        img{ 
          height:16px;
        width:16px;
        position: relative;
        top:2px;
      }
      }
    }
  }
}
}
   .zindex-2__control{
        background-color:#F1F2F5;
      }
}
`;
export default SubDRepStyled;
