import styled from 'styled-components';

const OpeningBalanceStyled = styled.div`
  background: #fbfbfc;
  height: 100%;
  .mt-6{
    margin-top: 6px;
    padding-left:0;
  }

  .distributor-selection{
    padding: 0px 48px;
  }

  table td:last-child {
    text-align: left;
  }

  .opening-balance-container{
    text-align: center;
    /* margin: 250px; */
  }
  .text{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .modal-inner {
      max-width: 600px;
      .upload{
        padding: 0px;
        background: #F1F2F5;
        padding-bottom: 30px;
      }
      .mb-24{
        position: absolute;
        right: 120px;
        padding-top: 10px;
      }
    }

    table{
      border:0;
    }

  `;

export default OpeningBalanceStyled;
