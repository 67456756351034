/* eslint-disable */
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import RouteView from './View';
import { Button, Loading } from '../../../components';
import { DialogWrapper } from '../../common';
import PageHeader from '../../base/PageHeader';
import UploadForm from '../../common/CSVUploadForm';
import { FORM_CONFIG } from '../../../data/enums/config';
import * as queryService from '../../base/query.service';
import { ROUTE_DETAILS, ROUTE } from '../../../data/enums/Route';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { getPermissionForRoute } from '../../base/permission';
import {
  apiFilterProcessor,
  getOffsetFromPageAndLimit,
} from '../../../utils/api';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';

import {
  formConfig,
  crudSuccess as crudRequestConfig,
  breadCrumbConfig,
  filterConfig,
} from './config';
import { PanelStyled } from '../../common/configuration';
import { isError } from '../../common/HelperFunctions';
import { PROGRAM } from '../../../data/enums/Permission';
import { crudResponseProcessor } from '../../../utils/crudResponseProcessor';
import { Icon } from '../../../components';
import MapView from './MapView/MapView';

const propTypes = {
  getRouteList: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class Route extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      display: {
        searchBox: false,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        // date: { ... queryService.baseQueryParameters.date },
      },
      showMapView: false,
      loadingMapView: false,
    };

    this.permission = getPermissionForRoute();

    const { downloadReport, displayAlert } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.getTableData,
      downloadReport,
      displayAlert,
    );
  }

  componentDidMount() {
    this.getTableData();
  }

  setQueryParameters = (queryParams, callBack = () => null) => this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  getTableData = () => {
    const {
      data,
      queryParameters: { pagination },
      queryParameters,
    } = this.state;

    const { getRouteList, displayAlert } = this.props;

    const offset = getOffsetFromPageAndLimit(pagination.page, pagination.limit);

    getRouteList(
      {
        offset,
        limit: pagination.limit,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          // dateRange: queryParameters.date,
          queryString: queryParameters.search,
        },
      },
      {
        handleSuccess: (response) => {
          data.list = (response.data.routes && response.data.routes.rows) || [];
          data.total = (response.data.routes && response.data.routes.count) || 0;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
        handleError: (error) => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleOnClick = (type, id, title) => {
    const { history } = this.props;

    if (type === EVENT_OPERATION.CREATE) {
      history.push(`/${ROUTE}/create`);
    } else {
      history.push(`/${ROUTE_DETAILS}/${id}`, title);
    }
  };

  handleFormSubmit = async (crudType, data) => {
    const { upload } = this.props;

    // server call management.
    const serverCall = { [EVENT_OPERATION.UPLOAD]: upload };

    // generate data management
    const serverRequestData = await crudRequestConfig[
      EVENT_OPERATION[crudType]
    ].objectMapper(data);

    // generate success Message
    const successMessage = crudRequestConfig[EVENT_OPERATION[crudType]].message;

    serverCall[EVENT_OPERATION[crudType]](serverRequestData, {
      handleSuccess: () => {
        // considered only upload case for data.
        const { displayAlert } = this.props;
        displayAlert(ALERT_TYPE.SUCCESS, successMessage);
        this.loadTableData();
      },
      handleError: (err) => {
        this.onAPIRequestFailure(err);
      },
    });
  };

  handleBulkDelete = (type, response) => {
    const { displayAlert } = this.props;
    const { data } = this.state;
    displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message);
    crudResponseProcessor(
      (modifiedData) => {
        this.setState({ data: modifiedData });
      },
      () => data,
    )(type)(response);
  };

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  showMapView = (toggle) => {
    this.loadingMapView(toggle);
  };

  loadingMapView = (toggle) => {
    this.setState({ loadingMapView: !this.state.showMapView }, () => {
      toggle && setTimeout(() => {
        this.toggleMapView(toggle);
      }), 1000;
    });
  }

  toggleMapView = (toggle) => {
    this.setState({ showMapView: toggle });
  }

  render() {
    const {
      data, queryParameters, display, showMapView, loadingMapView,
    } = this.state;
    const { serverResponseWaiting } = this.props;

    return (
      <Fragment>
        <DialogWrapper
          header="Add Outlet"
          fileConfig={formConfig}
          refsObj={formConfig.ref}
          fileUploadRefs={formConfig[FORM_CONFIG.REFS_OBJ]}
          onDialogSubmit={this.onFormSubmit}
          renderDialog={({
            handleFileUploadChange,
            dialogData,
            enableErrorDisplay,
            fileUploadRefs,
          }) => (
            <Fragment>
              <UploadForm
                show
                refsObj={fileUploadRefs}
                data={dialogData}
                loading={serverResponseWaiting}
                handleFileUpload={handleFileUploadChange}
                enableErrorDisplay={enableErrorDisplay}
              />
            </Fragment>
          )}
          render={({ onDialogItemClick }) => (
            <Fragment>
              <div className="section-header">
                <PanelStyled>
                  <PageHeader
                    config={{
                      title: 'Route',
                      /* upload: this.permission, */
                      create: this.permission.create,
                      date: false,
                      filter: true,
                      search: true,
                      download: true,
                    }}
                    downloadConfig={{
                      domain: PROGRAM.ROUTE,
                    }}
                    filter={{
                      date: queryParameters.date,
                      menuList: filterConfig.menu,
                      onFilterChange: this.basePaginationService
                        .handleFilterChange,
                    }}
                    breadCrumb={breadCrumbConfig}
                    display={display}
                    queryParameters={queryParameters}
                    handleUploadClick={onDialogItemClick}
                    handleCreateClick={this.handleOnClick}
                    resetFilter={this.basePaginationService.resetFilter}
                    clearSearchText={this.basePaginationService.clearSearchText}
                    handleSearchChange={
                      this.basePaginationService.handleSearchInputChange
                    }
                    handleDateRangeChange={
                      this.basePaginationService.handleDateRangeChange
                    }
                    handleDownloadClick={
                      this.basePaginationService.handleDownloadClick
                    }
                    controlDisplay={this.controlDisplay}
                  >
                    <div className="view-btns">
                      <Button
                        secondary
                        iconBtnSmall
                        iconName="list"
                        className="table-view-ico"
                        onClick={() => this.toggleMapView(false)}
                      />
                      <Button
                        className="map-view-ico"
                        secondary
                        iconBtnSmall
                        onClick={() => this.showMapView(true)}
                      >
                        {' '}
                        <img alt="map-view" src="/image/icons/map-view.svg" />
                      </Button>
                    </div>
                  </PageHeader>
                </PanelStyled>
              </div>
              <div className="section-content">
                {!showMapView ? (
                  <RouteView
                    data={data}
                    breadCrumb={breadCrumbConfig}
                    loading={serverResponseWaiting}
                    onIconClick={onDialogItemClick}
                    pagination={queryParameters.pagination}
                    handleRowClick={this.handleOnClick}
                    onPageChange={this.basePaginationService.onPageSelect}
                    onTableHeaderClick={
                      this.basePaginationService.handleTableSorting
                    }
                    onBulkDelete={this.handleBulkDelete}
                    permission={this.permission}
                  />
                ) : (
                  <MapView
                    data={data}
                    filter={queryParameters.filter}
                    search={queryParameters.search}
                    loading={serverResponseWaiting}
                    routeCardClick={this.handleOnClick}
                    loadingMapView={this.loadingMapView}
                  />
                )}
              </div>
              {loadingMapView && <Loading title="Loading Map View..." /> }
            </Fragment>
          )}
        />
      </Fragment>
    );
  }
}

Route.propTypes = propTypes;

Route.defaultProps = defaultProps;

export { Route };

export default withAlert()(Route);
