import React from 'react';
import { Link } from 'react-router-dom';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { tableData } from '../../../utils/paginatedListUtils';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { normalPresentor } from '../../../utils/date';

// todo update the configurations.
const labelMappings = {
  id: 'id',
  date: 'date',
  invoiceNumber: 'invoiceNumber',
  vendor: 'vendor',
  distributor: 'distributor',
  quantity: 'quantity',
  discount: 'discount',
  amount: 'amount',
  shortageAndDamage: 'shortageAndDamage',
  remarks: 'remarks',
  actionHolders: 'actionHolders',
};


const returnTableBody = (data) => {
  const table = {};
  table[labelMappings.id] = <td>{data.id}</td>;
  table[labelMappings.date] = <td>{normalPresentor(data.createdAt)}</td>;
  table[labelMappings.invoiceNumber] = <td>{data.invoiceNumber}</td>;
  table[labelMappings.vendor] = <td>{data.Vendor.name}</td>;
  table[labelMappings.distributor] = <td>{data.Distributor.title}</td>;
  table[labelMappings.discount] = (
    <td className="text-right">
      {fixedFloatAndCommas(
        data.amount.tradeDiscount + data.amount.billDiscount || 0,
      )}
    </td>
  );
  table[labelMappings.amount] = (
    <td className="text-right">
      {fixedFloatAndCommas(data.amount.total || 0)}
    </td>
  );
  table[labelMappings.shortageAndDamage] = (
    <td>{`${data.shortages || 0}/${data.damages || 0}`}</td>
  );
  table[labelMappings.remarks] = <td>{data.remarks}</td>;


  return table;
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.id,
    title: 'GRN ID',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.date,
    title: 'GRN Date',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.invoiceNumber,
    title: 'Invoice Number',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.vendor,
    title: 'Vendor',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.distributor,
    title: 'Distributor',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.discount,
    title: 'Discount',
    align: true,
    show: true,
  },
  {
    id: 7,
    label: labelMappings.amount,
    title: 'Amount',
    align: true,
    show: true,
  },
  {
    id: 8,
    label: labelMappings.shortageAndDamage,
    title: 'S/D',
    show: true,
  },
  {
    id: 9,
    label: labelMappings.remarks,
    title: 'Remarks',
    show: true,
  },
  {
    id: 10,
    label: labelMappings.actionHolders,
    title: '',
    show: false,
  },
];

const TableBody = (data, onIconClick) => {
  // Expanding/Dragging and collapsing table body.
  // color based upon table body
  // tooltip if it is sorted.
  // active className
  const tableBody = returnTableBody(data);

  return (
    <tr onClick={() => onIconClick(EVENT_OPERATION.UPDATE, data)}>
      <Link to={`grn/details/${data.id}`}>
        {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
      </Link>
    </tr>
  );
};

const TableHeader = () => (
  <thead className="">
    <tr>
      {labelConfig.map(label => (label.show ? tableData(label.title, label.align) : null))}
    </tr>
  </thead>
);


export { TableHeader, TableBody };
