import { getTodaysDate } from '../../../../../utils/date';
import { FORM_CONFIG } from '../../../../../data/enums/config';
import { refGenerator } from '../../../../../utils/refGenerator';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { calculateRelativeDiscount } from '../../../../inventory/purchaseReturn/create/table/config';
import { VAT_AMOUNT } from '../../../../../data/enums/GeneralConstants';
import { calculatePromotionItemTotal } from '../../../orderProcessing/received/detail/config';

const title = 'GRN';

const ERROR = {
  NO_ORDER_SELECTED: 'No order selected',
  NO_ORDER_AVAILABLE: 'No order available',
};

const SRN_TYPE = {
  PARTIAL: 'PARTIAL',
  COMPLETE: 'COMPLETE',
};
const STOCK_TYPE = {
  SALEABLE: { title: 'Sellable', value: 'SELLABLE' },
  DAMAGED: { title: 'Damaged', value: 'DAMAGED' },
  EXPIRED: { title: 'Expired', value: 'EXPIRED' },
};
const STOCK_TYPE_LIST = [STOCK_TYPE.SALEABLE, STOCK_TYPE.DAMAGED, STOCK_TYPE.EXPIRED];

const priceMapper = element => ({
  rate: element.rate ? element.rate || 0 : element.rlp || 0,
  grossAmount: element.grossAmount || 0,
  discountAmount: element.discountAmount || 0,
  taxAmount: element.taxAmount || 0,
  netAmount: element.netAmount || 0,
  taxableAmount: element.taxableAmount || 0,
  billDiscount: element.billDiscount || 0,
  tradeDiscount: element.tradeDiscount || 0,
  promotionDiscount: element.promotionDiscount || 0,
  topUpDiscount: element.topUpDiscount || 0,
  subTotal: element.subTotal || 0,
});


const formMapper = element => ({
  id: element.id || '',
  skuId: element.skuId || null,
  skuBatchId: element.skuBatchId || null,
  batchNumber: element.SKUBatch ? element.SKUBatch.batchDetails.batchNumber : '',
  expiryDate: element.SKUBatch ? element.SKUBatch.usageDate ? element.SKUBatch.usageDate.expiry || getTodaysDate() : getTodaysDate() : getTodaysDate(),
  manufactureDate: element.SKUBatch ? element.SKUBatch.usageDate ? element.SKUBatch.usageDate.manufacture || getTodaysDate() : getTodaysDate() : getTodaysDate(),
  updatedQuantity: element.updatedQuantity || 0,
  quantity: element.quantity || 0,
  returnQuantity: element.returnQuantity || 0,
  amountDetails: element.amountDetails || priceMapper({}),
  updatedAmountDetails: priceMapper(element.updatedAmountDetails || {}) || priceMapper({}),
  stockType: element.stockType || STOCK_TYPE.SALEABLE.value,
  promotionId: element.promotionId || null,
  rateDetails: element.rateDetails || {},
  distributorId: element.distributorId || null,
});

const requiredList = [
  'skuId', 'skuBatchId', 'returnQuantity',
  'rate', 'discount', 'stockType',
];

const formConfig = {
  [EVENT_OPERATION.CREATE]: {
    [FORM_CONFIG.TITLE]: 'Add SKU',
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    [FORM_CONFIG.MAPPER]: () => formMapper({}),
    [FORM_CONFIG.REFS_OBJ]: refGenerator([...requiredList]),
  },
  [EVENT_OPERATION.UPDATE]: {
    [FORM_CONFIG.TITLE]: 'Update SKU',
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    [FORM_CONFIG.MAPPER]: element => formMapper(element),
    [FORM_CONFIG.REFS_OBJ]: refGenerator([...requiredList]),
  },
  [EVENT_OPERATION.DELETE]: {
    [FORM_CONFIG.TITLE]: 'DELETE',
    [FORM_CONFIG.VALIDATION_REQUIRED]: false,
    [FORM_CONFIG.MAPPER]: (element = {}) => ({
      id: element.id || '',
      promotionId: element.promotionId || null,
    }),
  },
  [EVENT_OPERATION.READ]: {
    [FORM_CONFIG.MAPPER]: (data) => {
      const total = calculatePromotionItemTotal(data.Lines || []) || {};

      return { ...data, total };
    },
    [FORM_CONFIG.TITLE]: 'Promotion Details',
  },
};

const totalViewRefs = refGenerator(['billDiscount', 'tradeDiscount']);

const getUpdatedLineAmountDetails = (element, totalAmountObj, field = 'updatedAmountDetails') => {
  const subTotal = (element.returnQuantity || 0)
    * (element[field].rate || 0)
    - (element[field].promotionDiscount || 0) - ((element[field].topUpDiscount) || 0);

  const billDiscount = calculateRelativeDiscount(
    totalAmountObj.subTotal,
    totalAmountObj.billDiscount,
    subTotal,
  ) || 0;
  const tradeDiscount = calculateRelativeDiscount(
    totalAmountObj.subTotal,
    totalAmountObj.tradeDiscount,
    subTotal,
  ) || 0;
  const discountAmount = (element[field].promotionDiscount || 0 + element[field].topUpDiscount) + billDiscount + tradeDiscount;
  const taxableAmount = subTotal - billDiscount - tradeDiscount;
  const taxAmount = taxableAmount * VAT_AMOUNT;
  const netAmount = taxableAmount + taxAmount;

  return {
    netAmount,
    billDiscount,
    tradeDiscount,
    taxableAmount,
    taxAmount,
    discountAmount,
    subTotal,
  };
};

const updateSkuLine = (element, sku, skuBatch, totalAmountObj) => {
  const lineAmountDetails = getUpdatedLineAmountDetails(element, totalAmountObj);
  const amount = element.returnQuantity * element.updatedAmountDetails.rate;

  return ({
    ...element,
    updatedAmountDetails: {
      ...element.updatedAmountDetails,
      amount,
      netAmount: lineAmountDetails.netAmount,
      billDiscount: lineAmountDetails.billDiscount,
      tradeDiscount: lineAmountDetails.tradeDiscount,
      grossAmount: amount * (1 + (VAT_AMOUNT || 0)),
      subTotal: lineAmountDetails.subTotal,
      taxableAmount: lineAmountDetails.taxableAmount,
      taxAmount: lineAmountDetails.taxAmount,
      discountAmount: lineAmountDetails.discountAmount,
    },
    SKU: {
      id: sku.id,
      title: sku.title,
    },
    SKUBatch: {
      id: skuBatch.id,
      batchDetails: { batchNumber: skuBatch.batchDetails.batchNumber },
      usageDate: {
        manufacture: element.manufactureDate,
        expiry: element.expiryDate,
      },
    },
  });
};

const prepareSkuLinesForValidation = (order) => {
  const { amount, ...amountDetails } = order.updatedAmountDetails;
  return ({
    orderId: order.id,
    skuBatchRateId: order.skuBatchId,
    skuId: order.skuId,
    distributorId: order.distributorId,
    rateDetails: order.rateDetails,
    quantity: order.quantity,
    returnQuantity: order.returnQuantity,
    promotionId: order.promotionId,
    amountDetails,
  });
};

const updateRate = (state, stateUpdater, selectedBatch) => {
  state.updatedAmountDetails.rate = selectedBatch.priceDetails.rlp;
  stateUpdater(state);
};

export {
  ERROR,
  formConfig,
  title,
  totalViewRefs,
  SRN_TYPE,
  STOCK_TYPE_LIST,
  updateRate,
  updateSkuLine,
  priceMapper,
  getUpdatedLineAmountDetails,
  formMapper as salesLinesMapper,
  prepareSkuLinesForValidation,
};
