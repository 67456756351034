import React, { Component, Fragment } from 'react';
import { TableBody, TableHeader } from '../tableConfig';
import withImageSlider from '../../../../../utils/composition/withImageSlider';
import SplitPayment from '../../../../components/SplitPayment';
import { Pagination } from '../../../../../components';
import withErrorAndLoadingScreen from '../../../../../utils/composition/withLoading';
import ReportTableStyled from '../../../../reports/ReportTableStyled';

class Reconciled extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: {
        type: '',
        element: '',
      },
    };
  }

    handleIconClick = (type, element = {}) => {
      this.setState({
        dialog: {
          type,
          element,
        },
      });
    };

      resetDialog = () => {
        this.setState({
          dialog: {
            type: '',
            element: '',
          },
        });
      };

      render() {
        const {
          dialog,
        } = this.state;
        const { type } = dialog;
        const {
          tableConfig, reconcile, pagination, onPageChange, handleSliderOpen,
        } = this.props;
        return (
          <Fragment>
            <ReportTableStyled>
              {type && (
              <SplitPayment
                data={dialog.element}
                type={type}
                resetDialog={this.resetDialog}
              />
              )}
              <div className="table-wrap">
                <table>
                  {
                  TableHeader({
                    tableConfig,
                  })
                }

                  <tbody>
                    {reconcile.list.map(reconciled => (
                      TableBody({
                        data: reconciled,
                        onIconClick: this.handleIconClick,
                        tableConfig,
                        handleSliderOpen,
                        section: 'reconciled',
                      })
                    ))}
                  </tbody>
                </table>

              </div>
              <Pagination
                currentPage={pagination.page}
                limit={pagination.limit}
                totalItems={reconcile.total}
                onPageChange={onPageChange}
              />
            </ReportTableStyled>
          </Fragment>
        );
      }
}

const ReconciledWithErrorAndLoading = withErrorAndLoadingScreen(Reconciled);

const ReconciledWithImageSlider = withImageSlider(ReconciledWithErrorAndLoading);

export default ReconciledWithImageSlider;
