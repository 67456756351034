import React, { Component, Fragment } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import Input from '../../components/Input/Text';
import Form from '../../components/Input/Form';

class EvolveForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ageNumber: 5,
      rollNumber: 12,
      textField: '',
    };
  }

  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  render() {
    const { ageNumber, textField, rollNumber } = this.state;
    return (
      <Fragment>
        <div style={{ paddingTop: 50 }}>
          <h1 style={{ margin: '0 0 20px' }}>Input</h1>
          <Row>
            <Col xs={6}>
              <Form>
                <Input
                  value={textField}
                  name="textField"
                  type="text"
                  enableErrorDisplay
                  onChange={event => this.handleInputChange(event)}
                  labelContent="Default label"
                />

                <Input
                  value={textField}
                  name="textField"
                  type="text"
                  enableErrorDisplay
                  onChange={event => this.handleInputChange(event)}
                  iconName="check-circle-full"
                  status="success"
                  labelContent="Success label"
                />

                <Input
                  value={textField}
                  name="textField"
                  type="text"
                  enableErrorDisplay
                  onChange={event => this.handleInputChange(event)}
                  iconName="exclamation-full"
                  status="danger"
                  labelContent="Danger label"
                />

                <Input
                  value={ageNumber}
                  name="ageNumber"
                  type="number"
                  placeholder="Number"
                  enableErrorDisplay
                  onChange={event => this.handleInputChange(event)}
                  iconName="warning"
                  status="warning"
                  labelContent="Warning label"
                />

                <Input
                  value={rollNumber}
                  name="rollNumber"
                  type="number"
                  placeholder="Number"
                  enableErrorDisplay
                  onChange={event => this.handleInputChange(event)}
                />
              </Form>
            </Col>
          </Row>
        </div>
      </Fragment>
    );
  }
}

export default EvolveForm;
