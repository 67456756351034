import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import history from '../../../utils/history';
import {
  ERROR_URL,
  JWT,
  LOGIN_URL,
  RESPONSE_CODE,
  APP,
} from '../../../environment';

const {
  CONSTANTS: {
    UNAUTHORIZED,
    FORBIDDEN,
  } = {},
} = APP;

const afterwareLink = new ApolloLink((operation, forward) => forward(operation)
  .map((response) => {
    const context = operation.getContext();
    const headers = (context && context.response && context.response.headers) || null;

    if (headers) {
      const token = headers.get(JWT.HEADER.TOKEN.NAME);
      const refreshToken = headers.get(JWT.HEADER.REFRESH_TOKEN.NAME);

      if (token) {
        localStorage.setItem(JWT.LOCAL_STORAGE.TOKEN.NAME, token);
      }

      if (refreshToken) {
        localStorage.setItem(
          JWT.LOCAL_STORAGE.REFRESH_TOKEN.NAME,
          refreshToken,
        );
      }
    }

    return response;
  }));

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors && graphQLErrors.filter(e => e).length > 0) {
    graphQLErrors.map(({ message = '', status = RESPONSE_CODE.SUCCESS, code }) => {
      if (UNAUTHORIZED === message || status === RESPONSE_CODE.UNAUTHORIZED) {
        console.warn(`You've attempted to access ${UNAUTHORIZED} section`);
        if (
          history
          && history.location
          && history.location.pathname !== LOGIN_URL
        ) {
          history.push(LOGIN_URL);
        }
      }

      if (code === RESPONSE_CODE.UNAUTHORIZED && message === 'The authentication token has expired.') {
        history.push(LOGIN_URL);
      }

      if (FORBIDDEN === message || status === RESPONSE_CODE.FORBIDDEN) {
        console.warn(`You've attempted a ${FORBIDDEN} action`);
        history.push(`${ERROR_URL}/${RESPONSE_CODE.FORBIDDEN}`);
      }
      return null;
    });
  }
  if (networkError && networkError.statusCode === RESPONSE_CODE.UNAUTHORIZED) {
    history.push(LOGIN_URL);
    console.warn(UNAUTHORIZED);
  }
  if (networkError && networkError.statusCode === RESPONSE_CODE.FORBIDDEN) {
    // TODO Do something on network Error
    console.warn(FORBIDDEN);
  }
  if (networkError && networkError.statusCode >= RESPONSE_CODE.SERVER_ERROR) {
    console.warn('SERVER ERROR');
    history.push(`${ERROR_URL}/${networkError.statusCode}`);
  }
});

export { errorLink, afterwareLink };
