import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';

const detailLabelMappings = {
  id: 'id',
  sku: 'sku',
  quantity: 'quantity',
  rate: 'rate',
  promotion: 'promotion',
  value: 'value',
  checkbox: 'checkbox',
  menu: 'menu',
};

const detailsLabelConfig = [
  {
    id: 1,
    label: detailLabelMappings.checkbox,
    title: '',
    show: false,
    action: false,
  },
  {
    id: 2,
    label: detailLabelMappings.id,
    title: 'Order ID',
    show: true,
  },
  {
    id: 3,
    label: detailLabelMappings.sku,
    title: 'SKU',
    show: true,
  },
  {
    id: 4,
    label: detailLabelMappings.quantity,
    title: 'Quantity',
    align: true,
    show: true,
  },
  {
    id: 5,
    label: detailLabelMappings.rate,
    title: 'Rate',
    align: true,
    show: true,
  },
  {
    id: 6,
    label: detailLabelMappings.promotion,
    title: 'Promotion',
    classNames: 'text-center',
    show: true,
  },
  {
    id: 7,
    label: detailLabelMappings.value,
    title: 'Value',
    align: true,
    show: true,
  },
  {
    id: 8,
    label: detailLabelMappings.menu,
    title: '',
    align: true,
    show: false,
  },
];

const returnDetailTableBody = (data, onIconClick, index, order) => {
  const table = {};

  /* table[detailLabelMappings.checkbox] = (
    <td>
      <CheckBox
        disabled={data.inStock !== INSTOCK}
        checked={checkBoxStatus(SECONDARY, data.id)}
        onCheckBoxClick={e => onSecondaryCheckBoxClick(data.id)}
      />

    </td>
  ); */
  table[detailLabelMappings.id] = <td>{data.id}</td>;
  table[detailLabelMappings.sku] = (
    <td>
      {data.billDiscountSku && <span className="bill-discount-indicator" />}
      {data.freeSku && <span className="free-sku-indicator" />}
      {data.SKU ? data.SKU.title : ''}
      {data.focusedSku && <span className="focused-sku-indicator" />}
    </td>
  );
  table[detailLabelMappings.quantity] = <td className="text-right">{data.quantity}</td>;
  table[detailLabelMappings.rate] = <td className="text-right">{data.rateDetails.rlp}</td>;
  table[detailLabelMappings.promotion] = ((index === 0 && order.promotionId) ? (
    <td className="text-center">
      <img
        src="/image/icons/Promotion.svg"
        style={{ cursor: 'pointer' }}
        onClick={() => onIconClick(EVENT_OPERATION.READ, order)}
      />
    </td>
  ) : <td />);
  table[detailLabelMappings.value] = <td className="text-right">{fixedFloatAndCommas(data.amountDetails.subTotal)}</td>;
  return table;
};

const DetailTableBody = ({
  data, onIconClick, index, order,
}) => {
  // Expanding/Dragging and collapsing table body.
  // color based upon table body
  // tooltip if it is sorted.
  // active className
  const tableBody = returnDetailTableBody(data, onIconClick, index, order);

  return <Fragment>{detailsLabelConfig.map(label => (label.show ? tableBody[label.label] : null))}</Fragment>;
};

DetailTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  onSecondaryCheckBoxClick: PropTypes.func.isRequired,
};


export { DetailTableBody, detailsLabelConfig };
