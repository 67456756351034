import { refGenerator } from '../../../utils';
import { FILTER } from '../../../data/enums/filter';
import { getFilterConfig } from '../../../utils/filter';
import { FORM_CONFIG } from '../../../data/enums/config';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { inventory as inventoryRoute } from '../../../data/enums/Route';

const breadCrumb = [
  inventoryRoute,
];

const formConfig = {
  [FORM_CONFIG.MAPPER]: element => ({
    distributorId: element.distributorId || '',
    skuId: element.skuId || 0,
    skuTitle: element.sku ? element.sku.title : '',
    skuBatchId: element.skuBatchId || 0,
    reason: element.reason || '',
    quantity: element.quantity || 0,
    balance: element.balance || 0,
  }),
  [FORM_CONFIG.TITLE]: 'Stock Adjust',
  [FORM_CONFIG.REFS_OBJ]: refGenerator(['reason', 'quantity', 'skuId']),
  [FORM_CONFIG.VALIDATION_REQUIRED]: true,
};

const crudRequestConfig = {
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: (data) => {
      const adjustedQuantity = (data.quantity - data.balance) || 0;
      const { skuTitle, balance, ...payload } = data;
      payload.quantity = adjustedQuantity;
      return { input: payload };
    },
    responseName: 'createStockAdjustment',
    message: 'Stock adjusted successfully!',
  },
};

const filter = getFilterConfig([FILTER.BRAND, FILTER.SUB_BRAND_FORM]);


export {
  formConfig, crudRequestConfig, breadCrumb as breadCrumbConfig, filter as filterConfig,
};
