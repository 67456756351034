import React from 'react';
import PropTypes from 'prop-types';
import { COVERAGE_TYPE } from '../../../../../data/enums/GraphQL';
import {
  returnTableBody,
  generalHeaderConfig, labelMappings,
} from '../tableConfig';
import TableHeader from '../../../../base/TableHeader';

const getFixedFilterParam = (type, id) => {
  if (type === COVERAGE_TYPE.CATEGORY) {
    return [{ column: 'channel_id', value: [id.toString()] }];
  }
  return [{ column: 'distributor_id', value: [id.toString()] }];
};

const filterConfig = {};

const viewBasedHeaderConfig = {
  [COVERAGE_TYPE.CATEGORY]: [{
    id: 1, label: labelMappings.category, title: 'Category', show: true,
  }, ...generalHeaderConfig],
  [COVERAGE_TYPE.ROUTE]: [{
    id: 1, label: labelMappings.route, title: 'Route', show: true,
  }, ...generalHeaderConfig],
};


const TableBody = ({
  data,
  onRowClick,
  viewType,
}) => {
  const tableBody = returnTableBody(data, viewBasedHeaderConfig[viewType]);

  return (
    <tr onClick={() => onRowClick(viewType)}>
      {tableBody.map(row => row)}
    </tr>
  );
};

const TableHeaderConfig = ({ viewType }) => (
  <TableHeader
    headerDetails={viewBasedHeaderConfig[viewType]}
  />
);

TableBody.propTypes = {
  data: PropTypes.instanceOf('object').isRequired,
};

export {
  TableHeaderConfig as TableHeader,
  TableBody,
  getFixedFilterParam,
  filterConfig,
}
