import React, { Component } from 'react';
import DropdownStyled from './DropDownStyled';
import { Icon } from '../../../../../components'
class DropDown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showMenu: false,
        };

        this.showMenu = this.showMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
    }

    showMenu(event) {
        event.preventDefault();

        this.setState({ showMenu: true }, () => {
            document.addEventListener('click', this.closeMenu);
        });
    }

    closeMenu() {
        this.setState({ showMenu: false }, () => {
            document.removeEventListener('click', this.closeMenu);
        });
    }
    render() {
        return (
            <DropdownStyled>
                <span className="dropdown">
                    <Icon className="simple-popup-actions" iconName="ellipsis-v" onClick={this.showMenu} />
                    {
                        this.state.showMenu
                            ? (
                                <ul className="menu">
                                    <li
                                        onClick={() => this.props.showModal(this.props.outletId)}
                                    >Transfer</li>
                                </ul>
                            )
                            : (
                                null
                            )
                    }
                </span>
            </DropdownStyled>
        );
    }
}
export default DropDown;