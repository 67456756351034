import { compose } from 'recompose';
import StockAdjustment from './StockAdjustment';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import { query, mutation, ADJUST_STOCK } from './API';

const composedStockAdjustment = compose(
  ADJUST_STOCK,
  withRequestTracker({ query, mutation }),
)(StockAdjustment);

export default composedStockAdjustment;
