import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { FORM_CONFIG } from '../../../../../data/enums/config';
import { refGenerator } from '../../../../../utils/refGenerator';

const formConfig = {
  [EVENT_OPERATION.UPDATE]: {
    [FORM_CONFIG.MAPPER]: element => ({
      id: element.id || 0,
      quantity: element.quantity || 0,
      baseQuantity: element.quantity || 0,
      rateDetails: {
        rlp: element.rateDetails ? element.rateDetails.rlp : 0,
      },
      amountDetails: {
        netAmount: element.amountDetails ? element.amountDetails.netAmount : 0,
      },
    }),
    [FORM_CONFIG.TITLE]: 'Modify Order',
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['quantity']),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
  [EVENT_OPERATION.DELETE]: {
    [FORM_CONFIG.MAPPER]: element => ({
      id: element.id || 0,
      reason: element.reason || '',
    }),
    [FORM_CONFIG.TITLE]: 'Cancel Order',
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['reason']),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    [FORM_CONFIG.MAPPER]: element => ({
      ids: element.ids || 0,
      reason: element.reason || '',
    }),
    [FORM_CONFIG.TITLE]: 'Cancel Orders',
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['reason']),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
};

const reasonsList = [
  { reason: 'Out of Stock' },
  { reason: 'Insufficient Stock' },
  { reason: 'Wrong Order Entered' },
  { reason: 'Mistake in Promotion' },
  { reason: 'Credit Limit Issue' },
  { reason: 'Payment Aging Issue' },
  { reason: 'PAN Issue' },
];

export { formConfig, reasonsList };
