import React from 'react';
import PropTypes from 'prop-types';
import {
  Input, Form, AutoComplete, Row, Col,
} from '../../../components';
import withLoading from '../../../utils/composition/withLoading';

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    territoryId: PropTypes.number,
  }),
  handleInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  handleDropDownChange: PropTypes.func,
  refsObj: PropTypes.instanceOf(Object),
  onAutoCompleteChange: PropTypes.func.isRequired,
  categoryList: PropTypes.instanceOf(Array).isRequired,
};

const defaultProps = {
  handleInputChange: () => null,
  enableErrorDisplay: true,
  data: {
    name: '',
    territoryId: 0,
  },
  refsObj: {},
  handleDropDownChange: () => null,
};

const TownForm = ({ ...props }) => {
  const {
    data, enableErrorDisplay, handleInputChange, refsObj, categoryList, onAutoCompleteChange,
  } = props;


  return (
    <Form>
      <div className="merchandise-dialog">
        <Row>
          <Col md={6} xs={12}>
            <Input
              ref={ref => refsObj.title = ref}
              value={data.title}
              name="title"
              type="text"
              placeholder="Name"
              labelContent="Name"
              enableErrorDisplay={enableErrorDisplay}
              enableValidation
              onChange={event => handleInputChange(event)}
            />
          </Col>

          <Col md={6} xs={12}>
            <AutoComplete
              name="categoryList"
              labelContent="Category"
              dropDownList={categoryList}
              placeholder="Select Category"
              selectedDropDowns={data.categoryIds}
              onDropDownSelection={onAutoCompleteChange}
            />
          </Col>
        </Row>
      </div>
    </Form>
  );
};

TownForm.propTypes = propTypes;

TownForm.defaultProps = defaultProps;

const TownViewWithErrorAndLoading = withLoading(TownForm);

export default TownViewWithErrorAndLoading;
