import {
  compose,
} from 'recompose';
import OSR from './OSR';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  query, DOWNLOAD_REPORT, mutation,
} from './API';

const ComposedOSR = compose(
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  }),
)(OSR);

export default ComposedOSR;
