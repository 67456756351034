import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import ProductivityView from './View';
import PageHeader from '../../base/PageHeader';
import { isError } from '../../common/HelperFunctions';
import { PanelStyled } from '../../common/configuration';
import * as queryService from '../../base/query.service';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';
import {
  breadCrumbConfig,
  filterConfig,
  title,
} from './config';
import {
  labelConfig,
} from './tableConfig';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  getProductivityReport: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  downloadReport: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class Productivity extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      display: {
        searchBox: false,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
      },
      tableInfo: labelConfig,
    };

    const { downloadReport, displayAlert } = props;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadReport,
      displayAlert,
    );

    this.getData = () => {
      const { data } = this.state;
      return data;
    };
  }

  componentDidMount() {
    this.loadTableData();
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  loadTableData = () => {
    const {
      data, queryParameters,
    } = this.state;
    const { getProductivityReport } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);

    getProductivityReport(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          queryString: queryParameters.search,
          dateRange: queryParameters.date,
        },
      },
      {
        handleSuccess: (response) => {
          data.list = (response.data.getProductivityReport && response.data.getProductivityReport.rows) || [];
          data.total = (response.data.getProductivityReport && response.data.getProductivityReport.count) || 0;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
        handleError: (error) => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };

  setQueryParameters = (queryParams, callBack = () => null) => this.setState(
    { queryParameters: queryParams },
    callBack,
  );

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  columnSelectChange = (data) => {
    this.setState({
      tableInfo: data,
    });
  }

  render() {
    const {
      data, queryParameters, display, tableInfo,
    } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <Fragment>
        <div className="section-header">
          <PanelStyled>
            <PageHeader
              display={display}
              breadCrumb={breadCrumbConfig}
              config={{
                title,
                create: false,
                download: true,
                filter: true,
                search: false,
                date: true,
                columSelect: true,
              }}
              downloadConfig={{
                domain: 'PRODUCTIVITY_REPORT',
              }}
              filter={{
                date: queryParameters.date,
                menuList: filterConfig.menu,
                onFilterChange: this.basePaginationService.handleFilterChange,
              }}
              labelConfig={tableInfo}
              queryParameters={queryParameters}
              columnSelectChange={this.columnSelectChange}
              controlDisplay={this.controlDisplay}
              resetFilter={this.basePaginationService.resetFilter}
              clearSearchText={this.basePaginationService.clearSearchText}
              handleSearchChange={this.basePaginationService.handleSearchInputChange}
              handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
              handleDownloadClick={this.basePaginationService.handleDownloadClick}
            />

          </PanelStyled>
        </div>
        <div className="section-content">
          <ProductivityView
            data={data}
            permission={this.permission}
            pagination={queryParameters.pagination}
            onIconClick={() => {}}
            loading={serverResponseWaiting}
            onPageChange={this.basePaginationService.onPageSelect}
            tableConfig={tableInfo}
          />
        </div>
      </Fragment>
    );
  }
}

Productivity.propTypes = propTypes;

Productivity.defaultProps = defaultProps;

export default withAlert()(Productivity);
