import gql from 'graphql-tag';


const GET_BRAND_DETAIL_COVERAGE = gql`
    query getBrandDetailCoverage($id: Int!, $type:coverageType!, $filter: FilterInput ) {
        getBrandDetailCoverage(id: $id, type: $type, filter: $filter) {
            coverage
            rows {
                id
                name
                parent_name
                coverage
                active_coverage
                brand_coverage
                coverage_percent
            }
            count
        }
    }
`;

const query = {
  getBrandDetailCoverage: GET_BRAND_DETAIL_COVERAGE,
};

export { query };
