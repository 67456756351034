import React from 'react';
import Thumbnail from '../components/ImageViewer/Thumbnail';

const ImageColumn = ({ imageList, onImageClick, keyTitle }) => (
  <td key={keyTitle || 'imageKey'}>
    <span style={{ display: 'flex', cursor: 'pointer' }}>
      {
        imageList.map((image, index, array) => {
          if (index === 0) {
            return (
              <div
                key={`${keyTitle}-${index}`}
                onClick={() => {
                  onImageClick(index, array);
                }}
              >
                <Thumbnail
                  image={image.thumbnail}
                />
              </div>
            );
          }
          if (index === 1) {
            return (
              <span className="relative" key={`${keyTitle}-${index}`}>
                <span
                  className="thumbnail-overlay"
                  onClick={() => {
                    onImageClick(index, array);
                  }}
                >
                  +
                  {array.length - 1}
                </span>
                <Thumbnail
                  image={image.thumbnail}
                />
              </span>
            );
          }
        })
      }
    </span>
  </td>
);

export { ImageColumn };
