import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TableBody, TableHeader } from './tableConfig';
import withLoading from '../../../utils/composition/withLoading';
import { dataProps, paginationProps } from '../../../data/enums/Component';

import { Pagination } from '../../../components';
import { STATEFUL_ENTITIES } from '../../../data/enums/GraphQL';
import BulkDelete from '../../components/BulkDelete';

const propTypes = {
  data: dataProps.value,
  onIconClick: PropTypes.func,
  onPageChange: PropTypes.func,
  onRouteItemClick: PropTypes.func.isRequired,
  pagination: paginationProps.value,
  breadCrumb: PropTypes.instanceOf(Array),
  onTableHeaderClick: PropTypes.func.isRequired,
  handleRowClick: PropTypes.func.isRequired,
};

const defaultProps = {
  data: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
  onPageChange: () => null,
  onIconClick: () => null,
  breadCrumb: [],
};

const View = ({ ...props }) => {
  const {
    data,
    permission,
    pagination,
    onPageChange,
    onBulkDelete,
    handleRowClick,
  } = props;

  // TODO implement on Table Row Click

  return (
    <Fragment>
      <BulkDelete
        data={data.list}
        type={STATEFUL_ENTITIES.ROUTE}
        handler={onBulkDelete}
        render={(state, primaryCheckboxHandler, secondaryCheckboxHandler) => (
          <div className="table-wrap">
            <table>
              {TableHeader({
                dataListLength: data.list.length,
                checkedListLength: state.checkedList.length,
                primaryCheckboxHandler,
                permission,
              })}
              <tbody>
                {data.list.map(route => (
                  TableBody({
                    data: route,
                    checkedList: state.checkedList,
                    secondaryCheckboxHandler,
                    permission,
                    onTableBodyClick: handleRowClick,
                  })
                ))}
              </tbody>
            </table>
          </div>
        )}
      />
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </Fragment>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const ViewWithLoading = withLoading(View);

export default ViewWithLoading;
