import {
  compose,
} from 'recompose';
import SKUCoverage from './SKUCoverage';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  query, DOWNLOAD_REPORT, mutation,
} from './API';

const ComposedSKUCoverage = compose(
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  }),
)(SKUCoverage);

export default ComposedSKUCoverage;
