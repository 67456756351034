import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../../../data/enums/SuccessMessage';
import { OUTLET } from '../../../../../data/enums/Route';
import { FORM_CONFIG } from '../../../../../data/enums/config';
import { refGenerator } from '../../../../../utils';
import { fileParser } from '../../../../../utils/parser';
import { breadCrumbConfig } from '../config';

const breadCrumb = [
  ...breadCrumbConfig,
  {
    id: 1,
    title: 'Outlet',
    path: `/${OUTLET}`,
    active: true,
  },
];

const title = 'Outlet';

const getContactInfo = (contact) => {
  if (Array.isArray(contact)) return contact[0];

  return contact;
};

const formConfig = {
  [FORM_CONFIG.MAPPER]: element => ({
    file: element.file || {},
  }),
  [FORM_CONFIG.TITLE]: `${EVENT_OPERATION.UPLOAD} ${title}`,
  [FORM_CONFIG.REFS_OBJ]: refGenerator([
    'title',
    'channel',
    'category',
    'contactName',
    'phoneNumber',
    'town',
    'latitude',
    'longitude',
    'panNumber',
    'landline',
    'whatsapp',
    'viber',
  ]),
  [FORM_CONFIG.VALIDATION_REQUIRED]: true,
};

const crudSuccess = {
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: data => ({
      ...data,
      Channel: data.Category ? data.Category.Channel : { id: '', title: '' },
      contactInfo: data.contactInfo ? getContactInfo(data.contactInfo) : { name: '', phoneNumber: '' },
    }),
    responseName: 'updateRetailOutlet',
    message: `${title} ${MESSAGE_EVENT_OPERATION.UPDATE}`,
  },
  [EVENT_OPERATION.UPLOAD]: {
    objectMapper: async (data) => {
      const fileData = await fileParser(data.file)
        .then(file => ({
          file,
          type: title,
        }));
      return fileData;
    },
    responseName: 'importCSV',
    message: `${title} ${MESSAGE_EVENT_OPERATION.UPLOAD}`,
  },
};

// eslint-disable-next-line no-unused-vars
const apiTransformer = () => {

};


export {
  crudSuccess, breadCrumb as breadCrumbConfig, formConfig,
};
