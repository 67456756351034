import styled from 'styled-components';

const ReceiptWrapper = styled.div`

  .table-wrap{
       td{
        .light{
          min-width: 90px;
          padding: 3px 4px;
          text-align:center;
          line-height:10px;
          font-size:10px;
          font-weight:600;
          white-space:nowrap;
          border-radius: 2px;
        }
      .pending{
        border:0;
        padding:0;
        .light{
            border: 1px solid #FF8F39;
                color: #B95000;
        }
      }
       .received{
        .light{
           border: 1px solid #47C464;
          color: #287D3C
        }
      }
      .cheque_received{
        .light{
           border: 1px solid #47C464;
          color: #287D3C
        }
      }
      .dishonored{
        .light{
          border: 1px solid rgba(218, 20, 20, 0.4);
          color: #DA1414;
        }
      }
        .reconciled{
        .light{
          border: 1px solid rgba(46,90,172,0.4);
          color: #2E5AAC;
        }
      }
    }
  }

.collector-wrap{
  display: flex;
  .form-select-input{
    width:57.5%;
        label{
          margin-right: 27%; 
        }
  }
  
  .form-input{
    width: 25%;
    margin-left: 8px;
  }
  .user-wrap{
    width:41%;
     .form-select-input{
      width: 100%;
      margin-left: 8px;
      label{
      margin: 0px;
      width: 0px;    
      }
   }
  }
}

.form-input-wrap{
  .form-input,.form-select-input{
    label{
      width: calc(33.57% - 10px );
    }
  }
}
`;

export default ReceiptWrapper;
