import React from 'react';
import { EVENT_OPERATION } from '../../../../../../data/enums/EventOperation';
import { Input, Switch } from '../../../../../../components';
import {
  EMPTY,
  INVALID,
  UNIQUE,
} from '../../../../../../data/enums/ErrorMessage';
import CustomSelect from '../../../../../../components/CustomSelect';
import { USER_ROLE } from '../../../../../../data/enums';
import { uniqueFields } from '../config';
import UserAccountStyled from '../../UserAccountStyled';
import FormContentStyled from '../../../../../../components/global-css/FormContentStyled';

const getParentUsersForRole = (roleId = null, roles = [], users = []) => {
  const selectedRole = roles.find(role => role.id === roleId);

  const parentUserGroupId = selectedRole && selectedRole.UserGroup && selectedRole.UserGroup.parentGroupId;

  const parentRoleIds = roles.filter(role => role.userGroupId === parentUserGroupId);


  return parentRoleIds.length > 0 ? users.filter(user => parentRoleIds.includes(user.roleId)) : users;
};

const General = ({ ...props }) => {
  const {
    data,
    roles,
    refsObj,
    parentUsers,
    onInputChange,
    enableErrorDisplay,
    handleDropDownChange,
    handleButtonCancel,
    handleButtonSubmit,
    billingUsers,
    crudMode,
    billingPermission,
    handleEditIconClick,
    menu,
    billingStatus,
    enableUniqueFieldError,
    resetPassword,
  } = props;
  const checkDistributorRole = data.roleId === USER_ROLE.DSE;

  const townsData = data.townIds || [];

  const distributorsData = data.distributorIds || [];
  const townList = [];
  menu.distributorList.filter(dist => distributorsData.includes(dist.id))
    .forEach(distributor => townList.push(...distributor.Towns));
  const filteredParentList = getParentUsersForRole(data.roleId, roles, parentUsers);

  const checkBillingUser = data.roleId === USER_ROLE.BILLING_USER
    && distributorsData.length === 1 && billingUsers.length > 0 && townsData.length > 0;

  const { Role: { id } } = JSON.parse(localStorage.getItem('rosia-detail'));
  return (
    <UserAccountStyled>
      <div className={`user-form-wrap create-ui-inner 
      ${crudMode === EVENT_OPERATION.CREATE ? 'tab-less-height' : ''}
       ${crudMode === EVENT_OPERATION.READ ? 'disabled' : ''}`}
      >
        <FormContentStyled>
          <h3>General Details</h3>
          <div className="form-input-wrap">
            <Input
              ref={ref => (refsObj.firstName = ref)}
              value={data.firstName}
              name="firstName"
              type="text"
              rule="matches"
              labelContent="First Name"
              argument={
                /^[a-zA-Z, ]+$/
              } /* matches alphanumeric, underscore ,'-' & ' ' */
              placeholder="First Name"
              enableErrorDisplay={enableErrorDisplay}
              enableValidation
              errorMessage={data.firstName ? INVALID : EMPTY}
              onChange={event => onInputChange(event)}
            />
            <Input
              ref={ref => (refsObj.lastName = ref)}
              value={data.lastName}
              name="lastName"
              type="text"
              labelContent="Last Name"
              placeholder="Last Name"
              enableErrorDisplay={enableErrorDisplay}
              enableValidation
              onChange={event => onInputChange(event)}
            />
            <Input
              ref={ref => (!checkDistributorRole ? (refsObj.email = ref) : '')}
              value={data.email}
              name="email"
              type="email"
              placeholder="Email"
              labelContent="Email"
              enableErrorDisplay={enableErrorDisplay || enableUniqueFieldError[uniqueFields.EMAIL]}
              enableValidation
              externalValidation={!enableUniqueFieldError[uniqueFields.EMAIL]}
              errorMessage={data.email ? enableUniqueFieldError[uniqueFields.EMAIL] ? UNIQUE : INVALID : EMPTY}
              displayMessage={!checkDistributorRole}
              onChange={event => onInputChange(event)}
            />
            {crudMode === EVENT_OPERATION.CREATE && (
              <Input
                ref={ref => (refsObj.password = ref)}
                value={data.password}
                name="password"
                type="password"
                placeholder="Password"
                labelContent="Password"
                enableErrorDisplay={enableErrorDisplay}
                enableValidation
                errorMessage={data.password ? INVALID : EMPTY}
                onChange={event => onInputChange(event)}
              />
            )}
            <Input
              ref={ref => (refsObj.phoneNumber = ref)}
              value={data.phoneNumber}
              name="phoneNumber"
              type="number"
              rule="matches"
              labelContent="Phone Number"
              argument={
                /\b(\d{10}$)\b|\b(\d{7}$)\b/
              } /* matches digit of length 10 or 7 */
              placeholder="Phone Number"
              enableErrorDisplay={enableErrorDisplay || enableUniqueFieldError[uniqueFields.PHONE]}
              enableValidation
              externalValidation={!enableUniqueFieldError[uniqueFields.PHONE]}
              onChange={event => onInputChange(event)}
              errorMessage={data.phoneNumber ? enableUniqueFieldError[uniqueFields.PHONE] ? UNIQUE : INVALID : EMPTY}
            />
            <CustomSelect
              ref={(ref) => {
                refsObj.roleId = ref;
                return null;
              }}
              clearable={false}
              className="custom-select"
              placeholder="Select Role"
              labelContent="Role"
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              options={roles}
              enableValiation
              enableErrorDisplay={enableErrorDisplay}
              value={roles.filter(({ id }) => id === data.roleId)}
              onChange={e => handleDropDownChange(e.id, ['roleId'], handleDropDownChange)
              }
            />
            {data.roleId !== USER_ROLE.SUPER_ADMIN && (
              <CustomSelect
                ref={(ref) => {
                  refsObj.parentUserId = ref;
                  return null;
                }}
                clearable={false}
                className="custom-select"
                placeholder="Select Supervisor"
                labelContent="Supervisor"
                getOptionLabel={({ firstName, lastName }) => `${firstName} ${lastName}`}
                getOptionValue={({ id }) => id}
                options={filteredParentList}
                enableValiation
                enableErrorDisplay={enableErrorDisplay}
                value={parentUsers.filter(
                  ({ id }) => id === data.parentUserId,
                )}
                onChange={e => handleDropDownChange(e.id, ['parentUserId'])}
              />
            )}
            {(((data.roleId > USER_ROLE.MIS_ASSOCIATE) || (data.roleId === USER_ROLE.BRANCH_HEAD)) && data.parentUserId !== 0)
              && (
                <>
                  <CustomSelect
                    ref={(ref) => {
                      refsObj.distributorIds = ref;
                      return null;
                    }}
                    isMulti={!checkDistributorRole}
                    clearable={false}
                    className="custom-select"
                    placeholder="Select Distributor"
                    labelContent="Distributor"
                    getOptionLabel={({ title }) => title}
                    getOptionValue={({ id }) => id}
                    options={menu.distributorList}
                    enableValiation
                    enableErrorDisplay={enableErrorDisplay}
                    value={distributorsData
                      ? menu.distributorList.filter(({ id }) => distributorsData.includes(id)) : []}
                    onChange={(e) => {
                      const distributors = !checkDistributorRole ? e.map(d => d.id) : [e.id];
                      handleDropDownChange(
                        distributors,
                        ['distributorIds'],
                        handleDropDownChange,
                      );
                    }
                    }
                  />
                  <CustomSelect
                    ref={(ref) => {
                      refsObj.townIds = ref;
                      return null;
                    }}
                    isMulti={!checkDistributorRole}
                    clearable={false}
                    className="custom-select"
                    placeholder="Select Town"
                    labelContent="Town"
                    getOptionLabel={({ title }) => title}
                    getOptionValue={({ id }) => id}
                    options={townList}
                    enableValiation
                    enableErrorDisplay={enableErrorDisplay}
                    value={townsData
                      ? menu.townList.filter(({ id }) => townsData.includes(id)) : []}
                    onChange={(e) => {
                      const towns = !checkDistributorRole ? e.map(d => d.id) : [e.id];
                      handleDropDownChange(
                        towns,
                        ['townIds'],
                        handleDropDownChange,
                      );
                    }
                    }
                  />
                </>
              )}
          </div>
        </FormContentStyled>
        { (id === USER_ROLE.ADMIN || id === USER_ROLE.SUPER_ADMIN)
          && (
          <FormContentStyled>
            {crudMode === EVENT_OPERATION.UPDATE
              && (
                <>
                  <h3>Security</h3>
                  <div className="form-input-wrap">
                    <Input type="password" labelContent="Password" value="******" disabled />
                    <div className="reset-pw">
                      {/* <span>Change  </span>
                <span>|</span> */}
                      <span onClick={() => { resetPassword(EVENT_OPERATION.RESET); }}> Reset</span>
                    </div>
                  </div>
            </>
              )}
          </FormContentStyled>
          )}
        {checkBillingUser && (
          <FormContentStyled>
            <h3>Billing Info</h3>
            <div className="form-input-wrap">
              <Switch
                onToggle={(checked, event) => {
                  onInputChange(
                    {
                      formattedValue: !!checked,
                      target: {
                        name: 'active',
                        type: 'boolean',
                        value: !!checked,
                      },
                    },
                    'billingStatus',
                    ['billingStatus'],
                  );
                }}
                checked={billingStatus}
                name="active"
                labelContent="Billing"
              />
              {billingStatus && (
                <CustomSelect
                  ref={(ref) => {
                    refsObj.billingUserId = ref;
                    return null;
                  }}
                  clearable={false}
                  className="custom-select"
                  placeholder="Map Billing User"
                  labelContent="Billing user"
                  getOptionLabel={({ name }) => name}
                  getOptionValue={({ idUsers }) => idUsers}
                  options={billingUsers}
                  enableValiation
                  enableErrorDisplay={enableErrorDisplay}
                  value={billingUsers.filter(
                    ({ idUsers }) => idUsers === data.billingCredentials.user_id,
                  )}
                  onChange={e => handleDropDownChange(
                    e.idUsers,
                    ['billingCredentials', 'user_id'],
                    handleDropDownChange,
                  )
                  }
                />
              )}
            </div>
          </FormContentStyled>
        )}
      </div>
    </UserAccountStyled>
  );
};

General.propTypes = {};

export default General;
