import React, { Component } from 'react';
import RouteActions from '../../components/RouteActions/RouteActions';

class RouteCards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: false,
            multiType: ''
        }
    }

    render() {
        return (
            <div className="route-li-wrap">
                {this.props.routesList.map((route, i) => (
                    <div className="routeRow"
                        key={i}
                        style={{
                            borderLeft: `4px solid ${route.color}`, borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px',
                        }}
                    >
                        {/* <CheckBox
                            onCheckBoxClick={(e) => this.secondaryCheckBoxClick(e)}
                            checked
                        /> */}{/*Might need later*/}

                        <div className="info-li" onClick={e => this.props.routeClick(route.dse, i)} style={{ cursor: 'pointer' }}
                        >
                            <p className="routeText">{`${route.name}`}</p>
                            <p className="outletText">
                                {`${route.travel.length}`} outlets
                            </p>
                        </div>
                        <RouteActions
                            showRenameModal={this.props.showRenameModal}
                            showDeleteModal={this.props.showDeleteModal}
                            routeIndex={i}
                        />
                    </div>
                ))
                }
            </div>
        )
    }
}

export default RouteCards