import gql from 'graphql-tag';
import { graphql } from 'react-apollo/graphql';
import { ROUTE_OUTLET_FRAGMENT } from '../../../../sales/route-outlet/outlet/API';

const ROUTE_FRAGMENT = gql`
    fragment RouteFragment on Route {
        id
        title
    }`;

const GET_ROUTE_LIST = gql`
    query getRoutes($offset: Int, $limit: Int, $filter: FilterInput) {
        routes(offset: $offset, limit: $limit, filter: $filter) {
            rows {
                ...RouteFragment
            }
        }
    }
    ${ROUTE_FRAGMENT}
`;

const GET_ROUTE_DETAILS = gql`
query getRouteRetailOutlets($id: String) {
        routes(filter: {
            filters: [
                {
                    column: "id",
                    value: [$id]
                }
            ]
        }) {
            rows {
                id
                title
                townId
                RetailOutletsInTown{
                   assigned {
                    count
                    outlets {
                      ...RouteOutletFragment
                    }
                  }
                  unassigned {
                    count
                    outlets {
                      ...RouteOutletFragment
                    }
                  }
                  outletsInAnotherRoute {
                    count
                    outlets {
                      ...RouteOutletFragment
                    }
                  }
                }
                
            }
        }
    }
  ${ROUTE_OUTLET_FRAGMENT}
`;

const ASSIGN_OUTLETS = graphql(
  gql`
    mutation($retailOutletIds: [Int]!, $routeId: Int!) {
      assignRetailOutlet(retailOutletIds: $retailOutletIds, routeId: $routeId)
    }
  `,
  {
    props: ({ mutate }) => ({
      assignOutlets: variables => mutate({
        variables,
      }),
    }),
  },
);

const query = {
  getRouteDetails: GET_ROUTE_DETAILS,
  getRouteList: GET_ROUTE_LIST,
};

const mutation = {
  assignOutlets: ASSIGN_OUTLETS,
};

export {
  ASSIGN_OUTLETS,
};

export { query, mutation };
