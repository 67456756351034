import gql from 'graphql-tag';
import { graphql } from 'react-apollo/graphql';
import { TOGGLE_STATE } from '../../common/GqlAPI';

const TOWN_FRAGMENT = gql`
    fragment TownFragment on Town {
        id
        title
        territoryId
        territory:Territory {
            title
        }
    }
`;

/** const GET_TERRITORY_LIST = gql`
 query {
    territories {
      rows {
        id
        title
      }
    }
  }
 `; */

const GET_TERRITORY_LIST = gql`
    query territories($offset: Int, $limit: Int) {
        territories(offset: $offset, limit: $limit) {
            rows {
                id
                title
            }
            count
        }
    }
`;

const GET_TOWN_LIST = gql`
    query getTown($offset: Int, $limit: Int, $filter: FilterInput) {
        towns(offset: $offset, limit: $limit, filter: $filter) {
            rows {
                ...TownFragment
            }
            count
        }
    }
    ${TOWN_FRAGMENT}
`;

const CREATE_TOWN = graphql(
  gql`
      mutation($title: String!, $territoryId: Int!) {
          createTown(title: $title, territoryId: $territoryId) {
              id
              title
              territoryId
              territory: Territory {
                  id 
                  title
              }
          }
      }
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      createTown: variables => mutate({
        variables,
      }),
    }),
  },
);

const UPDATE_TOWN = graphql(
  gql`
      mutation($id: Int!, $title: String!, $territoryId: Int!) {
          updateTown(id: $id, title: $title, territoryId: $territoryId) {
              id
              title
              territoryId
          }
      }
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      updateTown: variables => mutate({
        variables,
      }),
    }),
  },
);

const query = {
  getTownList: GET_TOWN_LIST,
  getTerritoryList: GET_TERRITORY_LIST,
};

const mutation = {
  createTown: CREATE_TOWN,
  updateTown: UPDATE_TOWN,
  toggleState: TOGGLE_STATE,
};

export {
  TOWN_FRAGMENT, CREATE_TOWN, UPDATE_TOWN, GET_TOWN_LIST, GET_TERRITORY_LIST, TOGGLE_STATE,
};

export { query, mutation };
