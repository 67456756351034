import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import {
  Button,
} from '../../../../components/index';
import {
  crudSuccess as crudRequestConfig,
  breadCrumbConfig,
  title, detailsFormConfig, formMapper,
} from './config';
import { GRN } from '../../../../data/enums/Route';
import Table from './table';
import SummaryDetails from './summaryDetails';
import { clone } from '../../../../utils/arrayProcessor';
import { has } from '../../../../utils/objectPrototypes';
import { grnImageUploader } from '../../../../utils/image';
import { PanelStyled } from '../../../common/configuration';
import PageHeader from '../../../common/detailViews/pageHeader/PageHeader';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import withAlert from '../../../../utils/composition/withAlert';
import { RECORD_STATUS } from '../../../../data/enums/RecordStatus';
import { EVENT_OPERATION, EVENT_OPERATION_MAPPER } from '../../../../data/enums/EventOperation';
import { handleFormSubmit } from '../../../../utils/crudResponseProcessor';
import { getPermissionForGrn } from '../../../base/permission';
import { getUser } from '../../../../data/services';
import { USER_ROLE } from '../../../../data/enums';
import PanelCard from '../../../../components/Cards/PanelCard';

const propTypes = {
  getSkus: PropTypes.func.isRequired,
  createGRN: PropTypes.func.isRequired,
  updateGRN: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  getVendors: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  getGrnDetail: PropTypes.func.isRequired,
  getDistributors: PropTypes.func.isRequired,

};

const defaultProps = {
  serverResponseWaiting: false,
};

class CreateGrn extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.userInfo = getUser();
    this.subDUser = this.userInfo.roleId === USER_ROLE.DISTRIBUTOR_ADMIN;
    this.distributorId = this.userInfo.Distributor.length > 0 ? this.userInfo.Distributor[0].id : 0;
    this.state = {
      skus: [],
      data: formMapper({}, this.distributorId),
      vendors: [],
      enableErrorDisplay: false,
      grnId: has.call(props.match.params, 'id') ? parseInt(props.match.params.id, 10) : 0,
      update: {
        type: EVENT_OPERATION.CREATE,
        status: true,
      },
      editable: false,
      approvable: false,
      backUpData: formMapper({}, this.distributorId),
      distributors: [],
    };
    const serverCall = {
      [EVENT_OPERATION.CREATE]: props.createGRN,
      [EVENT_OPERATION.UPDATE]: props.updateGRN,
      [EVENT_OPERATION.APPROVE]: props.approveGRN,

    };
    this.onCRUDSuccess = this.responseProcessor();
    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.onAPIRequestFailure,
      crudRequestConfig, serverCall);
    this.permission = getPermissionForGrn();
  }

  componentDidMount() {
    const { grnId, update } = this.state;
    if (grnId) {
      update.type = EVENT_OPERATION.UPDATE;
      update.status = false;
      this.setState({
        grnId,
        update,
      }, () => this.getGrnDetail(grnId));
    }

    this.getSKUs();
    this.getVendorsList();
    if (!this.subDUser) this.getDistributorsList();
  }

  getGrnDetail = (id) => {
    const { getGrnDetail, displayAlert } = this.props;
    getGrnDetail({ id }, {
      handleSuccess: (response) => {
        const { findGRN = {} } = response.data;
        const editable = findGRN.isEditable && this.permission.update;
        const approvable = this.permission.update && (findGRN.status === RECORD_STATUS.RECEIVED);
        this.setState({
          data: findGRN,
          backUpData: clone(findGRN),
          editable,
          approvable,
        });
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  };

  getVendorsList = () => {
    const { getVendors, displayAlert } = this.props;
    getVendors({}, {
      handleSuccess: (response) => {
        this.setState({ vendors: response.data.vendors.rows });
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  };

  getDistributorsList = () => {
    const { getDistributors, displayAlert } = this.props;
    getDistributors({ limit: 1000 }, {
      handleSuccess: (response) => {
        this.setState({ distributors: response.data.distributors.rows });
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  };

  getSKUs = () => {
    const { getSkus, displayAlert } = this.props;
    getSkus({ limit: 1000, includeAssortment: true }, {
      handleSuccess: (response) => {
        this.setState({ skus: response.data.skus.rows });
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  };

  handleInputChange = (event, firstParam = '', paramList = []) => {
    const { data } = this.state;
    if (firstParam) {
      if (paramList.length > 1) {
        paramList.reduce((acc, value, index, list) => {
          if (index === list.length - 1) {
            return acc[value] = event.formattedValue;
          }
          return acc[value];
        }, data);
      } else {
        data[firstParam][event.target.name] = event.formattedValue;
      }
    } else {
      data[event.target.name] = event.formattedValue;
    }
    this.setState({ data });
  };

  onSubmit = () => {
    const valid = this.getValidationStatus();
    if (valid) {
      this.createGrn();
    } else {
      this.setState({ enableErrorDisplay: true });
    }
  };

  getValidationStatus = () => {
    const detailsStatus = this.getDetailsValidationStatus();
    const tableStatus = this.getTableValidationStatus();

    return (detailsStatus && tableStatus);
  };

  getActionType = () => {
    const { update } = this.state;
    return (update.type !== EVENT_OPERATION.CREATE
      ? update.type === EVENT_OPERATION.UPDATE
        ? EVENT_OPERATION.UPDATE : EVENT_OPERATION.APPROVE : EVENT_OPERATION.CREATE);
  };

  createGrn = () => {
    const { data } = this.state;
    const updatedData = clone(data);
    const type = this.getActionType();
    const tableData = this.getTableDetails();
    updatedData.details = this.getDetails();
    updatedData.lines = tableData.lines;
    updatedData.amount = tableData.amount;
    this.onFormSubmit(type, updatedData);
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
    console.log(error);
  };

  responseProcessor = () => {
    const onAPIRequestSuccess = type => (response) => {
      const { displayAlert } = this.props;
      displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message, this.directToMainPage, response);
      // this.directToMainPage();
    };
    return onAPIRequestSuccess;
  };

  directToMainPage = (response) => {
    const { grnId, update } = this.state;
    const { history } = this.props;
    if (grnId) {
      let approveStatus = this.state.approvable;
      update.status = false;
      if (response.status === RECORD_STATUS.APPROVED) approveStatus = false;
      this.setState({ update, approvable: approveStatus });
      // history.push(`/${GRN_DETAILS}/${grnId}`);
    } else history.push(`/${GRN}`);
  };

  handleCancelClick = () => {
    const { backUpData, grnId, update } = this.state;
    if (grnId) {
      update.type = EVENT_OPERATION.UPDATE;
      update.status = false;
      this.setState({ data: clone(backUpData), update });
    } else this.setState({ data: formMapper({}, this.distributorId) });
  };

  render() {
    const {
      data,
      skus,
      grnId,
      update,
      vendors,
      editable,
      approvable,
      distributors,
      enableErrorDisplay,
    } = this.state;
    const {
      upload,
      displayAlert,
      serverResponseWaiting,
    } = this.props;

    const crudMode = update.status ? EVENT_OPERATION_MAPPER[update.type] : '';
    // const actionType = this.getActionType();
    return (
      <Fragment>
        <div className="section-header">
          <PanelStyled>
            <div className="prn-page-header">
              <PageHeader
                breadCrumb={breadCrumbConfig}
                title={grnId ? `${crudMode} GRN #${data.invoiceNumber}` : title}
              />
              <div className="flex m-0">
                <div>
                  {update.status && (
                <>
                  <Button
                    small
                    secondary
                    disabled={serverResponseWaiting}
                    onClick={() => this.handleCancelClick()}
                  >
                    <span>
                      Cancel
                    </span>
                  </Button>
                  <Button
                    small
                    primary
                    disabled={serverResponseWaiting}
                    onClick={() => this.onSubmit()}
                  >
                    <span>
                    Save
                    </span>
                  </Button>
                </>
                  )}
                </div>
                {
              grnId ? (
                <div>
                  {/* {editable && !update.status && (
                    <Button
                      disabled={!editable || update.type === EVENT_OPERATION.APPROVE}
                      iconBtnSmall
                      secondary
                      onClick={() => {
                        this.setState({
                          update: {
                            type: EVENT_OPERATION.UPDATE,
                            status: true,
                          },
                        });
                      }}
                      className="ml-16"
                    >
                      <Icon iconName="pencil" />
                    </Button>
                  )} */}

                  {approvable && !update.status && (
                    <Button
                      small
                      primary
                      disabled={update.type === EVENT_OPERATION.UPDATE && update.status}
                      onClick={() => {
                        this.setState({
                          update: {
                            type: EVENT_OPERATION.APPROVE,
                            status: true,
                          },
                        });
                      }}
                    >
                      Approve
                    </Button>
                  )}
                </div>
              ) : ''
            }
              </div>
            </div>
          </PanelStyled>
        </div>
        <div className="section-content pad-48">
          <div className={!update.status ? 'disabled' : ''}>
            <PanelCard cardTitle="details">
              <SummaryDetails
                update={update}
                subDUser={this.subDUser}
                enableErrorDisplay={enableErrorDisplay}
                loading={serverResponseWaiting}
                vendorList={vendors}
                distributorList={distributors}
                formConfig={detailsFormConfig}
                dialogElement={data || {}}
                getStatus={childMethod => this.getDetailsValidationStatus = childMethod}
                getDetails={childMethod => this.getDetails = childMethod}
              />
            </PanelCard>
            <PanelCard cardTitle="Sku" skuClassStatus>
              <Table
                data={data}
                skuList={skus}
                update={update}
                imageUploader={grnImageUploader}
                displayAlert={displayAlert}
                enableErrorDisplay={enableErrorDisplay}
                getStatus={childMethod => this.getTableValidationStatus = childMethod}
                getDetails={childMethod => this.getTableDetails = childMethod}
              />
            </PanelCard>
          </div>
        </div>
      </Fragment>
    );
  }
}

CreateGrn.propTypes = propTypes;

CreateGrn.defaultProps = defaultProps;

export default withAlert()(CreateGrn);
