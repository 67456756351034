import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import DetailView from './View';
import Split from './split';
import CancelForm from './cancel';
import UpdateForm from './update';
import { DialogFormWrapper } from '../../../../common';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import withAlert from '../../../../../utils/composition/withAlert';
import { formConfig, crudRequest as crudRequestConfig } from './config';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { handleFormSubmit } from '../../../../../utils/crudResponseProcessor';
import { INSTOCK, PRIMARY } from '../../../../../data/enums/GeneralConstants';
import {
  clone, findAndReplaceItem, remove,
} from '../../../../../utils/arrayProcessor';
import { ORDER_TABS, skuStockValidation } from '../../config';
import PromotionForm from './promotion/PromotionForm';
import DetailWraper from './DetailedStyled';
import { RETURN_TYPE } from '../../../../common/DomainConfig';
import ReplaceStock from './replaceStock';
import BillDiscountView from './billDiscount';

const propTypes = {
  data: PropTypes.shape({
    list: PropTypes.instanceOf(Array),
    total: PropTypes.number,
    limit: PropTypes.number,
    page: PropTypes.number,
  }).isRequired,
  loadingData: PropTypes.bool,
  skuList: PropTypes.instanceOf(Array),
  serverResponseWaiting: PropTypes.bool,
  updateOrder: PropTypes.func.isRequired,
  splitOrder: PropTypes.func.isRequired,
  cancelOrders: PropTypes.func.isRequired,
  stateSetter: PropTypes.func.isRequired,
  updateReplacementOrders: PropTypes.func.isRequired,
  activeCallIndex: PropTypes.number.isRequired,
  handlePassiveTabListChange: PropTypes.func.isRequired,
};

const defaultProps = {
  update: {
    type: '',
    status: false,
  },
  skuList: [],
  selectedOrders: [],
  loadingData: false,
  serverResponseWaiting: false,
};

class Detail extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    return { data: nextProps.data };
  }

  constructor(props) {
    super(props);
    this.state = {
      dialog: {
        type: '',
        element: '',
      },
      selectedOrders: [],
      tempSelectedOrders: [],
      enableErrorDisplay: false,
    };

    const serverCall = {
      [EVENT_OPERATION.UPDATE]: props.updateOrder,
      [EVENT_OPERATION.SPLIT]: props.splitOrder,
      [EVENT_OPERATION.DELETE]: props.cancelOrders,
      [EVENT_OPERATION.BULK_DELETE]: props.cancelOrders,
      [EVENT_OPERATION.REPLACED]: props.updateReplacementOrders,
    };

    this.onFormSubmit = handleFormSubmit(
      this.onServerRequestSuccess,
      this.onAPIRequestFailure,
      crudRequestConfig,
      serverCall,
    );
  }

  componentDidMount() {
    this.resetCheckedList();
  }

  componentDidUpdate(prevProps) {
    const { activeCallIndex } = this.props;
    if (prevProps.activeCallIndex !== activeCallIndex) {
      this.resetCheckedList();
    }
  }

  resetCheckedList = () => {
    this.setState({ selectedOrders: [] });
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  onServerRequestSuccess = (type) => {
    const {
      data,
      stateSetter,
      displayAlert,
      activeCallIndex,
      handlePassiveTabListChange,
    } = this.props;

    let updatedOrdersList = [];
    const dataList = clone(data.list);
    const selectedCallIndex = activeCallIndex;
    const selectedCall = dataList[selectedCallIndex] || {};

    switch (type) {
      case EVENT_OPERATION.UPDATE:
        return (response) => {
          updatedOrdersList = selectedCall.Orders.map((order) => {
            const index = order.Lines.findIndex(data => data.id === response.line.id);
            const updatedOrderLines = [...order.Lines];
            if (index > -1) {
              updatedOrderLines[index] = {
                ...response.line,
                focusedSku: order.Lines[index].focusedSku,
                Promotion: order.Lines[index].Promotion,
              };
            }
            const skuStatus = skuStockValidation(updatedOrderLines);

            return { ...order, Lines: updatedOrderLines, skuStatus };
          });

          dataList[selectedCallIndex] = {
            ...selectedCall,
            callOrderValue: response.callOrderValue
              ? response.callOrderValue
              : selectedCall.callOrderValue,
            Orders: updatedOrdersList,
          };
          data.list = dataList;
          stateSetter(data, ORDER_TABS.RECEIVED);
          displayAlert(ALERT_TYPE.SUCCESS, 'Order Modified');
        };

      case EVENT_OPERATION.DELETE:
      case EVENT_OPERATION.BULK_DELETE:
        return (response) => {
          /* const selectedCallIndex = activeCallIndex;
          const selectedCall = dataList[selectedCallIndex] || {}; */
          let totalLinesCount = 0;
          updatedOrdersList = selectedCall.Orders.map((order) => {
            const updatedOrderLines = remove(clone(order.Lines), response.orderIds) || [];
            totalLinesCount += updatedOrderLines.length;
            const skuStatus = skuStockValidation(updatedOrderLines);
            return { ...order, Lines: updatedOrderLines, skuStatus };
          });
          if (updatedOrdersList.length && totalLinesCount > 0) {
            selectedCall.callOrderValue = response.callOrderValue
              ? response.callOrderValue
              : selectedCall.callOrderValue;
            selectedCall.Orders = [...updatedOrdersList];
            selectedCall.totalOrderCount = updatedOrdersList.length;

            dataList[selectedCallIndex] = { ...selectedCall };
          } else {
            dataList.splice(selectedCallIndex, 1);
            data.total -= 1;
          }

          if (type === EVENT_OPERATION.BULK_DELETE) {
            this.resetCheckedList();
          }
          data.list = dataList;
          stateSetter(data, ORDER_TABS.RECEIVED);
          displayAlert(ALERT_TYPE.SUCCESS, `${response.orderIds.length} Order${response.orderIds.length > 1 ? 's' : ''}  cancelled`);
        };

      case EVENT_OPERATION.SPLIT:
        return (response) => {
          let oldOrderLine = {};
          updatedOrdersList = selectedCall.Orders.map((order) => {
            const skuIndex = order.Lines.findIndex(item => item.id === response.oldOrder.id);
            oldOrderLine = skuIndex > -1 ? { ...order.Lines[skuIndex] } : oldOrderLine;
            let skuStatus = order.skuStatus;
            if (skuIndex > -1) {
              order.Lines.splice(skuIndex, 1);
              skuStatus = skuStockValidation(order.Lines);
              // order.Lines = [...order.Lines, ...(response.newOrders || [])];
            }

            return { ...order, skuStatus };
          });

          const formattedNewOrder = response.newOrders ? response.newOrders.map((order) => {
            const skuStatus = skuStockValidation(order.Lines);
            order.Lines = order.Lines.map(data => ({
              ...data,
              focusedSku: data.focusedSku
                  || oldOrderLine.focusedSku,
            }));
            return { ...order, skuStatus };
          }) : [];

          updatedOrdersList = [...updatedOrdersList, ...formattedNewOrder];

          dataList[selectedCallIndex] = {
            ...selectedCall,
            Orders: updatedOrdersList,
            totalOrderCount: updatedOrdersList.length,
          };
          data.list = dataList;
          stateSetter(data, ORDER_TABS.RECEIVED);
          displayAlert(ALERT_TYPE.SUCCESS, 'Order Splitted');
        };
      case EVENT_OPERATION.REPLACED:
        return (response, payload) => {
          const replacedCount = response.length;
          dataList[selectedCallIndex] = {
            ...selectedCall,
            stockReplacement: {
              ...selectedCall.stockReplacement,
              count: selectedCall.stockReplacement.count - replacedCount,
            },
          };
          data.list = dataList;
          displayAlert(ALERT_TYPE.SUCCESS,
            `Replacement Order ${payload.status.toString().toLocaleLowerCase()} successfully!`);
          stateSetter(data, ORDER_TABS.RECEIVED);
          handlePassiveTabListChange(response, ORDER_TABS[type], replacedCount);
        };
      default:
        return () => null;
    }
  };

  handleIconClick = (type, element = {}) => {
    const { tempSelectedOrders } = this.state;
    let updatedStateItems = {
      element,
      tempSelectedOrders,
    };
    if (type === EVENT_OPERATION.BULK_DELETE) {
      updatedStateItems = this.handleCancelButtonClick(element);
    }
    if (type === EVENT_OPERATION.DELETE) {
      updatedStateItems = this.handleSingleOrdersCancelClick(element, tempSelectedOrders);
    }
    this.setState({
      dialog: {
        type,
        element: updatedStateItems.element,
      },
      tempSelectedOrders: updatedStateItems.tempSelectedOrders,
    });
  };

  handleSingleOrdersCancelClick = (
    { id = 0, callId = 0 }, tempSelectedOrders,
  ) => {
    const activeCall = this.getActiveCall();
    let promotionOrderIds = [];
    activeCall.Orders.forEach((orderWrapper) => {
      if (orderWrapper.promotionId) {
        const idExist = orderWrapper.Lines.some(order => order.id === id);
        if (idExist) {
          const freeSkuExist = orderWrapper.Lines.some(order => order.freeSku);
          if (freeSkuExist) promotionOrderIds = [...this.getLineIds(orderWrapper.Lines)];
        }
      }
    });

    return { element: { id, callId, promotionOrderIds }, tempSelectedOrders };
  };

  getActiveCall = () => {
    const { data } = this.state;
    const { activeCallIndex } = this.props;

    return data.list[activeCallIndex] || {};
  };

  filterInStockAndGetOrderIds = (promotionOrderObj) => {
    if (promotionOrderObj.skuStatus === INSTOCK) {
      return this.getLineIds(promotionOrderObj.Lines) || [];
    }

    return [];
  }

  getInStockLineIDsInActiveCall = () => {
    const { data, activeCallIndex } = this.props;
    const activeCall = data.list[activeCallIndex] || {};
    const { Orders = [] } = activeCall;

    const ids = Orders.map(item => this.filterInStockAndGetOrderIds(item)).flat();

    return ids || [];
  };

  getActiveCallOrderIds = () => {
    const { data, activeCallIndex } = this.props;
    const activeCall = data.list[activeCallIndex] || {};
    const { Orders = [] } = activeCall;
    const lineIds = Orders.map(item => this.getLineIds(item.Lines)).flat();

    return (lineIds || []);
  }

  getCheckboxStatus = (type, orderId, billDiscount) => {
    const { selectedOrders } = this.state;
    if (type === PRIMARY) {
      let validChildren = this.getInStockLineIDsInActiveCall().length;
      if (billDiscount) validChildren = this.getActiveCallOrderIds().length;

      return (selectedOrders.length === validChildren) && validChildren > 0;
    }

    return (selectedOrders.indexOf(orderId) > -1);
  };

  getLineIds = (orders) => {
    const orderIds = orders.map(item => item.id) || [];
    return orderIds;
  };

  handlePrimaryCheckBoxClick = (event, billDiscount) => {
    let selectedOrdersList = [];
    if (event.currentTarget.checked) {
      let inStockOrderIds = this.getInStockLineIDsInActiveCall() || [];
      if (billDiscount) {
        const totalOrders = this.getActiveCallOrderIds();
        if (totalOrders.length !== inStockOrderIds.length) inStockOrderIds = [];
      }
      selectedOrdersList = inStockOrderIds;
    }

    this.setState({ selectedOrders: selectedOrdersList });
  };

  handleSecondaryCheckboxClick = (orders) => {
    const { selectedOrders } = this.state;
    const { data, activeCallIndex } = this.props;
    const orderIds = this.getLineIds(orders);
    const updatedSelectedOrders = orderIds.map((id) => {
      const index = selectedOrders.indexOf(id);
      if (index > -1) selectedOrders.splice(index, 1);
      else selectedOrders.push(id);
    });
    this.setState({ selectedOrders });
  };


  resetDialog = () => {
    this.setState({
      dialog: {
        type: '',
        element: '',
      },
    });
  };

  handleDialogSubmit = (type, data) => {
    const { tempSelectedOrders } = this.state;
    if (type === EVENT_OPERATION.BULK_DELETE) {
      data.ids = tempSelectedOrders;
    }
    this.setState({ enableErrorDisplay: true });
    this.onFormSubmit(type, data);
    this.resetDialog();
  };

  handleCancelButtonClick = (data) => {
    const { selectedOrders } = this.state;
    const activeLineOrders = this.getActiveCallOrderIds();
    const completeFlag = selectedOrders.length === 0 || (activeLineOrders.length === selectedOrders.length);
    const tempSelectedOrders = completeFlag ? activeLineOrders : selectedOrders;
    const modifiedData = { ...data };
    if (completeFlag) modifiedData.type = RETURN_TYPE.COMPLETE;

    return { element: modifiedData, tempSelectedOrders };
  };

  render() {
    const {
      dialog,
      selectedOrders,
    } = this.state;
    const { type } = dialog;
    const {
      data,
      onRowClick,
      onFormSubmit,
      checkBoxStatus,
      onInvoiceClick,
      loading,
      serverResponseWaiting,
      activeCallIndex,
      displayAlert,
      loadingData,
    } = this.props;
    return (
      <Fragment>
        <DetailWraper>
          <div className={type.toLowerCase()}>
            {type && (
              <DialogFormWrapper
                formConfig={formConfig[type]}
                dialogElement={dialog.element}
                onDialogSubmit={this.handleDialogSubmit}
                onDialogCancel={this.resetDialog}
                type={type}
                renderDialog={
              (
                {
                  refsObj,
                  dialogData,
                  handleInputChange,
                  enableErrorDisplay,
                  handleDropDownChange,
                },
              ) => (
                <Fragment>
                  {
                    type === EVENT_OPERATION.UPDATE && (
                      <UpdateForm
                        show
                        refsObj={refsObj}
                        data={dialogData}
                        loading={loading}
                        handleInputChange={handleInputChange}
                        enableErrorDisplay={enableErrorDisplay}
                        handleDropDownChange={handleDropDownChange}
                      />
                    )
                  }
                  {
                    type === EVENT_OPERATION.SPLIT && (
                      <Split
                        dialogElement={dialog.element}
                        onDialogCancel={this.resetDialog}
                        onDialogSubmit={this.handleDialogSubmit}
                        formConfig={formConfig[type]}
                        displayMsg={displayAlert}
                      />
                    )
                  }
                  {
                    (type === EVENT_OPERATION.BULK_DELETE || type === EVENT_OPERATION.DELETE) && (
                      <CancelForm
                        show
                        refsObj={refsObj}
                        data={dialogData}
                        loading={loading}
                        handleInputChange={handleInputChange}
                        enableErrorDisplay={enableErrorDisplay}
                        handleDropDownChange={handleDropDownChange}
                      />
                    )
                  }
                  {
                    type === EVENT_OPERATION.READ && (
                      <PromotionForm
                        data={dialogData}
                        loading={loading}
                      />
                    )
                  }
                  {
                    type === EVENT_OPERATION.REPLACED && (
                      <ReplaceStock
                        dialogElement={dialog.element}
                        onDialogCancel={this.resetDialog}
                        onDialogSubmit={this.handleDialogSubmit}
                        formConfig={formConfig[type]}
                        displayMsg={displayAlert}
                      />
                    )
                  }
                  {
                    type === EVENT_OPERATION.READ_SUB_TYPE && (
                      <BillDiscountView
                        data={dialogData}
                        loading={loading}
                      />
                    )
                  }
                </Fragment>
              )}
              />
            )}
          </div>
        </DetailWraper>
        <DetailView
          data={data.list}
          onRowClick={onRowClick}
          onInvoiceClick={onInvoiceClick}
          selectedOrders={selectedOrders}
          activeCallIndex={activeCallIndex}
          onIconClick={this.handleIconClick}
          checkBoxStatus={this.getCheckboxStatus}
          onPrimaryCheckBoxClick={this.handlePrimaryCheckBoxClick}
          onSecondaryCheckBoxClick={this.handleSecondaryCheckboxClick}
          loading={serverResponseWaiting}
          receivedLoading={loadingData}
        />
      </Fragment>

    );
  }
}

Detail.propTypes = propTypes;

Detail.defaultProps = defaultProps;

export default withAlert()(Detail);
