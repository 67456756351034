import React from 'react';
import SkuFamily from '../../components/skuFamily';
import withLoading from '../../../../../utils/composition/withLoading';
import VerticalListWithCount from '../../../../components/VerticalListWithCount';
import AssortmentPanelStyled from '../../../channelAssortment/details/AssortmentPanelStyled';
import { PermissionContext } from '../config';

const SkuAssortmentView = ({
  productGroups, brandId, productGroupId, brands, assortmentCounts, onBrandLineClick, selectedSkus,
  onProductGroupLineClick, onSwitchChange, skuFamilies, onSearchInputChange, searchText,
}) => (
  <PermissionContext.Consumer>
    {
        ({ permission }) => (
          <section className="assortment-detail darkshade-bg create-ui-inner">
            <AssortmentPanelStyled>
              <div className="panel-section">
                <VerticalListWithCount
                  title="Product Category"
                  list={productGroups}
                  activeLine={productGroupId}
                  onItemClick={onProductGroupLineClick}
                  countList={assortmentCounts.productGroups}
                />
                {
                  productGroupId && (
                    <VerticalListWithCount
                      title="Brands"
                      list={brands || []}
                      activeLine={brandId}
                      onItemClick={onBrandLineClick}
                      countList={assortmentCounts.brands}
                    />
                  )
                }
                {
                  brandId && (
                    <SkuFamily
                      searchText={searchText}
                      skuFamilies={skuFamilies}
                      assortmentCounts={assortmentCounts}
                      onSearchInputChange={onSearchInputChange}
                      onSwitchChange={onSwitchChange}
                      selectedSkus={selectedSkus}
                    />
                  )}
              </div>
            </AssortmentPanelStyled>
          </section>
        )
      }
  </PermissionContext.Consumer>
);


const SkuAssortmentViewWithLoading = withLoading(SkuAssortmentView);

export default SkuAssortmentViewWithLoading;
