import { createContext } from '../../../../../utils/context';
import { REPLENISHMENT } from '../../../../../data/enums/Route';
import { replenishment } from '../../../../common/DomainConfig';
import { breadCrumbConfig as subd } from '../config';

const SubDAssortmentPermissionContext = createContext();

const breadCrumbConfig = [
  ...subd,
  {
    id: 2,
    title: replenishment.title,
    path: `/${REPLENISHMENT}`,
    active: true,
  },
];
const dataMapper = (element, id) => ({
  id: element.id || id || '',
  title: element.title || '',
});
export { SubDAssortmentPermissionContext as PermissionContext, dataMapper, breadCrumbConfig };
