import React, { Component } from 'react';
import RosterDialog from './Dialog';
import RosterReplicationDialog from './Dialog/ReplicationDialog';
import { DialogContext } from './config';
import { CALENDAR_PREVIEW } from '../../../data/enums';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import DeleteConfirmationDialog from './Dialog/DeleteConfirmationDialog';
import RosterStyled from './RosterStyled';

const propTypes = {};

const defaultProps = {};

class RosterDialogProcessor extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);

    this.state = {
      displayDialog: false,
      date: '2012-01-01',
      crudMode: EVENT_OPERATION.CREATE,
      rosterId: null,
      routeId: null,
      visitFrequencyId: null,
      repetition: true,
      selectedRouteList: [], // selected Routes in Weekly and Monthly Unit
    };
  }

  componentDidMount() {
    this.bindMethodToParent();
  }

  closeDialog = () => {
    this.setState({ displayDialog: false });
  };

  bindMethodToParent = () => {
    const { closeChildDialog } = this.props;

    closeChildDialog(this.closeDialog);
  };

  // UPDATE, EDIT and READ.
  handleRosterComponentClick = (event) => {
    const { onUserClick } = this.props;

    if (event.userId) {
      onUserClick(event.userId);
    }

    this.setState({
      displayDialog: true,
      crudMode: EVENT_OPERATION.CREATE,
    }, () => {
      this.setState({
        // displayDialog: true,
        date: event.date,
        selectedRouteList: event.selectedRouteList || [],
      });
    });
  };

  /**
   * date, repetition, visitFrequencyId and routeId from Dialog Component
   * userId: From the activated DSE.
   * @param data
   */
  handleFormSubmit = (data) => {
    const { userId: stateUserId, calendarPreview } = this.props;

    this.handleDialogClose();

    const { rosterId, crudMode } = this.state;

    if (crudMode === EVENT_OPERATION.CREATE) {
      const userId = calendarPreview === CALENDAR_PREVIEW.WEEKLY ? data.userId : stateUserId;
      this.createRoster(userId, data);
    } else {
      this.updateRoster(rosterId, data);
    }
  };

  updateRoster = (id, data) => {
    const { update, displayAlert, loadData } = this.props;

    update({
      id,
      input: {
        repetition: data.repetition || false,
        lineId: data.lineId,
        routeId: data.routeId,
      },
    }, {
      handleSuccess: () => {
        displayAlert(ALERT_TYPE.SUCCESS, 'Successfully Updated');
        loadData();
        this.handleDialogClose();
      },
      handleError: (err) => {
        displayAlert(ALERT_TYPE.DANGER, err);
      },
    });
  };

  createRoster = (userId, data) => {
    const { date } = this.state;
    const { create, displayAlert, subDId } = this.props;
    const { loadData } = this.props;

    create({
      input: {
        lineId: data.lineId,
        routeId: data.routeId,
        repetition: data.repetition,
        visitFrequencyId: data.visitFrequencyId,
        date,
        userId,
        distributorId: subDId,
      },
    }, {
      handleSuccess: () => {
        displayAlert(ALERT_TYPE.SUCCESS, 'Successfully Created');
        loadData();
        this.handleDialogClose();
      },
      handleError: (err) => {
        displayAlert(ALERT_TYPE.DANGER, err);
      },
    });
  };

  handleDialogClose = () => {
    this.setState({
      displayDialog: false, routeId: null, visitFrequencyId: null, repetition: true,
    });
  };

  // Monthly View, user click: fetch All Data
  handleUserClick = (user, calendarPreview = CALENDAR_PREVIEW.MONTHLY) => {
    // load the necessary data.
    const { userTitle } = this.state;
    const { onUserClick } = this.props;
    this.handleDialogClose();
    const requireDataLoading = calendarPreview === CALENDAR_PREVIEW.MONTHLY;
    onUserClick(user.id, requireDataLoading);

    /** this.setState({
      userId: user.id,
      userTitle: user.title || userTitle,
    }, () => {
      this.handleDialogClose();
      loadData(user.id);
    }); */
  };

  removeRosterLine = (callRosterId) => {
    const { removeRosterLine, displayAlert, loadData } = this.props;

    const rosterLine = {
      ids: [callRosterId],
      type: 'CallRoster',
      active: false,
      fieldName: 'active',
    };

    this.handleDialogClose();

    removeRosterLine({ ...rosterLine }, {
      handleSuccess: (res) => {
        displayAlert(ALERT_TYPE.SUCCESS, 'Successfully Removed');
        loadData();
        this.handleDialogClose();
      },
      handleFailure: (data) => {
        displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Failed');
        // this.handleDialogClose()
      },
    });
  };

  /**
   * @param operation: EVENT_OPERATION.UPDATE or Remove
   * @param rosterLine
   */
  handleRosterLineIconClick = (operation, rosterLine) => {
    // if iconName: edit and weeklyEdit

    const { calendarPreview, onUserClick } = this.props;

    if (calendarPreview === CALENDAR_PREVIEW.WEEKLY) {
      onUserClick(rosterLine.user.id);
    }

    const { date, routeId, visitFrequencyId } = rosterLine;

    if (operation === 'edit') {
      this.setState({
        crudMode: EVENT_OPERATION.UPDATE,
        displayDialog: true,
        rosterId: rosterLine.id || null,
        date,
        routeId,
        visitFrequencyId,
        repetition: !!rosterLine.creationIdentifier, // defines if repetition or not
        selectedRouteList: rosterLine.selectedRouteList,
      });
    }

    if (operation === 'delete') {
      this.setState({
        crudMode: EVENT_OPERATION.DELETE,
        displayDialog: true,
        rosterId: rosterLine.id || null,
        date,
        routeId,
        visitFrequencyId,
        selectedRouteList: rosterLine.selectedRouteList,
      });
    }
  };

  onDeleteDialogSubmit = () => {
    const { rosterId } = this.state;
    this.handleDialogClose();
    this.removeRosterLine(rosterId);
  };

  render() {
    const {
      children,
      userList,
      routeList,
      onUserClick,
      userId,
      displayReplicationDialog,
      onReplicationDialogSubmit,
      onReplicationDialogCancel,
      routeVisitFrequencyList,
    } = this.props;

    const {
      displayDialog,
      routeId,
      visitFrequencyId,
      date,
      crudMode,
      repetition,
      selectedRouteList,
    } = this.state;

    const user = userList.find(usr => usr.id === userId);

    const userTitle = user ? `${user.firstName}-${user.lastName}` : '';

    const route = routeList.find(rte => rte.id === routeId);

    const routeTitle = route ? route.title : '';

    const displayDeleteConfirmationDialog = crudMode === EVENT_OPERATION.DELETE && displayDialog;

    // filter the Route that has been selected
    const selectedRouteIdList = selectedRouteList.map(route => route.routeId);
    const filteredRouteList = routeList.filter(route => !selectedRouteIdList.includes(route.id));

    const rosterComponent = (displayDialog && crudMode !== EVENT_OPERATION.DELETE) ? (
      <div className="roster-popup">
        <RosterDialog
          data={{
            userId,
            routeId,
            userTitle,
            repetition,
            visitFrequencyId,
          }}
          crudMode={crudMode}
          routeList={filteredRouteList}
          displayDialog={displayDialog}
          onDialogSubmit={this.handleFormSubmit}
          onDialogClose={this.handleDialogClose}
          routeVisitFrequencyList={routeVisitFrequencyList}
        />
      </div>
    ) : null;

    return (
      <RosterStyled>
        <DialogContext.Provider value={
          {
            handleRosterComponentClick: this.handleRosterComponentClick,
            onCancelClick: this.handleDialogClose,
            onSubmit: this.handleFormSubmit,
            handleUserClick: this.handleUserClick,
            removeRosterLine: this.removeRosterLine,
            handleRosterLineIconClick: this.handleRosterLineIconClick,
            activeUser: userId,
            userList,
            activeDate: date,
            rosterComponent,
            displayDialog,
          }
        }
        >
          {children}
        </DialogContext.Provider>
        {
          displayReplicationDialog ? (
            <div className="modal-wrapper roster">
              <RosterReplicationDialog
                onDialogSubmit={onReplicationDialogSubmit}
                onDialogClose={onReplicationDialogCancel}
              />
            </div>
          ) : null
        }
        {
          displayDeleteConfirmationDialog ? (
            <DeleteConfirmationDialog
              userTitle={userTitle}
              routeTitle={routeTitle}
              onDialogCancel={this.handleDialogClose}
              onDialogSubmit={this.onDeleteDialogSubmit}
            />
          ) : null
        }
      </RosterStyled>
    );
  }
}

RosterDialogProcessor.propTypes = propTypes;

RosterDialogProcessor.defaultProps = defaultProps;

export default withAlert()(RosterDialogProcessor);
