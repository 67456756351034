import React from 'react';
import PropTypes from 'prop-types';
import { tableData } from '../../../utils/paginatedListUtils';
import { ImageColumn } from '../../../utils/tableUtils';
import { timeStampToTime } from '../../../utils/date';
import { Icon } from '../../../components';

const labelMappings = {
  attendaceDate: 'attendaceDate',
  user_fullName: 'User_fullName',
  user_phoneNumber: 'User_phoneNumber',
  user_role_name: 'User_Role_name',
  user_distributor_title: 'User_Distributor_title',
  login_time: 'login_time',
  logout_time: 'logout_time',
  images: 'images',
  location: 'location',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.attendaceDate,
    title: 'Date',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.user_fullName,
    title: 'User',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.user_phoneNumber,
    title: 'Phone Number',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.user_role_name,
    title: 'Role',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.user_distributor_title,
    title: 'Distributor',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.login_time,
    title: 'Login Time',
    show: true,
  },
  {
    id: 7,
    label: labelMappings.logout_time,
    title: 'Logout Time',
    show: true,
  },
  {
    id: 8,
    label: labelMappings.images,
    title: 'Images',
    show: true,
  },
  {
    id: 9,
    label: labelMappings.location,
    title: 'Location',
    show: true,
  },
];

const TableHeader = () => (
  <thead className=''>
    <tr>
      {labelConfig.map((label) =>
        label.show
          ? tableData(label.title, label.align, label.classNames)
          : null
      )}
    </tr>
  </thead>
);

const returnTableBody = (data, handleSliderOpen, onMarkerClick) => {
  const table = {};
  table[labelMappings.attendaceDate] = <td key={`${data.id}-attendaceDate`}>{data.attendaceDate}</td>;

  table[labelMappings.user_fullName] = (
    <td key={`${data.id}-user_fullName`}>{data.User.fullName}</td>
  );
  table[labelMappings.user_phoneNumber] = (
    <td key={`${data.id}-user_phoneNumber`}>{data.User.phoneNumber}</td>
  );
  table[labelMappings.user_role_name] = (
    <td key={`${data.id}-user_role_name`}>{data.User.Role.name}</td>
  );
  table[labelMappings.user_distributor_title] = (
    <td key={`${data.id}-user_distributor_title`}>{data.User.Distributor.map(distributor=> distributor.title) }</td>
  );
  table[labelMappings.login_time] = (
    <td key={`${data.id}-login_time`}>
      {data.login_time ? `${timeStampToTime(data.login_time / 1000)}` : null}
    </td>
  );
  table[labelMappings.logout_time] = (
    <td key={`${data.id}-logout_time`}>
      {data.logout_time ? `${timeStampToTime(data.logout_time / 1000)}` : null}
    </td>
  );
  table[labelMappings.images] = (
    <ImageColumn
      keyTitle={`rp-image-${data.images}`}
      imageList={data.images || []}
      onImageClick={handleSliderOpen}
    />
  );
  table[labelMappings.location] = (
    <td key={`${data.id}-location`} style={{ cursor: 'pointer' }}>
      <Icon
        iconName='geolocation'
        onClick={() => onMarkerClick(data.id, data.location)}
      />
    </td>
  );

  return table;
};

const TableBody = ({ data, handleSliderOpen, onMarkerClick }) => {
  // Expanding/Dragging and collapsing table body.
  // color based upon table body
  // tooltip if it is sorted.
  // active className
  const tableBody = returnTableBody(data, handleSliderOpen, onMarkerClick);

  return (
    <tr key={`osr-${data.id}`}>
      {labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
};

TableBody.defaultProps = {
  data: {},
};

export { TableHeader, TableBody };
