import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';
import {
  Button, CheckBox, Icon, Input,
} from '../../../../../components';
import { Col } from '../../../../../components/Grid';
import { normalPresentor } from '../../../../../utils/date';
import { dataProps } from '../../../../../data/enums/Component';
import { INVALID } from '../../../../../data/enums/ErrorMessage';
import { menuAction } from '../../../../../utils/paginatedListUtils';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import withErrorAndLoadingScreen from '../../../../../utils/composition/withLoading';

const propTypes = {
  data: dataProps.value,
  onIconClick: PropTypes.func,
  onPageChange: PropTypes.func,
  onButtonClick: PropTypes.func,
  onCheckBoxClick: PropTypes.func,
  update: PropTypes.instanceOf(Object),
  skuLines: PropTypes.instanceOf(Array),
  breadCrumb: PropTypes.instanceOf(Array),
  checkedList: PropTypes.instanceOf(Array),
};

const defaultProps = {
  checkedList: [],
  breadCrumb: [],
  onIconClick: () => null,
  onPageChange: () => null,
  onButtonClick: () => null,
  onCheckBoxClick: () => null,
  data: dataProps.defaultValue,
  skuLines: [],
  update: {
    type: EVENT_OPERATION.CREATE,
    status: false,
  },
};

const menuConfigList = [
  {
    title: 'Edit',
    icon: 'pencil',
    permissionDerivedBy: 'update',
    type: EVENT_OPERATION.UPDATE,
  },
  {
    title: 'Delete',
    icon: 'trash',
    permissionDerivedBy: 'delete',
    type: EVENT_OPERATION.DELETE,
  },
];

const TableView = ({ ...props }) => {
  const {
    update,
    viewType,
    skuLines,
    onIconClick,
    checkedList,
    validSkuLength,
    checkBoxStatus,
    invoiceNumberStatus,
    onPrimaryCheckBoxClick,
    onSecondaryCheckBoxClick,
  } = props;
  // debugger;
  return (
    <Fragment>
      {(checkedList.length > 0 && skuLines.length > 0) && (
        <div className="selected-status">
          <div className="content-center">
            <div>
              <CheckBox checked disabled />
              <p className="text">
                <span>{checkedList.length}</span>
                of
                <span>{skuLines.length}</span>
                items selected
              </p>
            </div>
            <div className="del-btn" onClick={() => null}>
              <Icon iconName="cross" />
            </div>
          </div>
        </div>
      )}
      <div className="table-wrap">
        <table>
          <thead>
            <tr>
              {invoiceNumberStatus && skuLines.length
                ? (
                  <th>
                    <CheckBox
                      checked={validSkuLength === checkedList.length}
                      onCheckBoxClick={e => onPrimaryCheckBoxClick(e)}
                    />
                  </th>
                ) : ''}
              <th>S.No.</th>
              <th>SKU</th>
              <th>Batch</th>
              <th className="text-center">MFG Date</th>
              <th className="text-center">EXP Date</th>
              <th>Stock Type</th>
              <th className="text-right">Quantity</th>
              <th className="text-right">Rate</th>
              <th className="text-right">Amount</th>
              <th className="text-right">Discount</th>
              <th className="text-right">Net Amount</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {
            skuLines.map((order, index) => (
              <Fragment>
                <tr key={order.id}>
                  {invoiceNumberStatus ? (
                    <td>
                      <CheckBox
                        checked={checkBoxStatus(order.id)}
                        onCheckBoxClick={e => onSecondaryCheckBoxClick(order)}
                      />
                    </td>
                  ) : ''}
                  <td>{index + 1}</td>
                  <td>{order.SKU.title}</td>
                  <td>
                    {order.SkuBatch
                      ? order.SkuBatch.batchDetails
                        ? order.SkuBatch.batchDetails.batchNumber
                          ? order.SkuBatch.batchDetails.batchNumber.replace(/_/g, ' ')
                          : order.SkuBatch.batchDetails.batchNumber : null : null}
                  </td>
                  <td className="text-center">
                    {order.SkuBatch
                      ? order.SkuBatch.usageDate
                        ? ((normalPresentor(order.SkuBatch.usageDate.manufacture) === 'Invalid date')
                          ? '-' : normalPresentor(order.SkuBatch.usageDate.manufacture)) : null : null}
                  </td>

                  <td className="text-center">
                    {order.SkuBatch
                      ? order.SkuBatch.usageDate
                        ? ((normalPresentor(order.SkuBatch.usageDate.expiry) === 'Invalid date')
                          ? '-' : normalPresentor(order.SkuBatch.usageDate.expiry)) : null : null}
                  </td>
                  <td>{order.stockType}</td>
                  <td className="text-right">{order.updatedQuantity}</td>
                  <td className="text-right">{order.updatedPriceDetails.rate}</td>
                  <td
                    className="text-right"
                  >
                    {fixedFloatAndCommas(order.updatedPriceDetails.amount)}
                  </td>
                  <td
                    className="text-right"
                  >
                    {fixedFloatAndCommas(order.updatedPriceDetails.discount)}
                  </td>
                  <td
                    className="text-right"
                  >
                    {fixedFloatAndCommas(order.updatedPriceDetails.netAmount)}
                  </td>
                  <td className="text-right simple-popup-actions" onClick={e => e.stopPropagation()}>
                    {menuAction(menuConfigList, { menuIcon: 'ellipsis-v' },
                      onIconClick, order, { update: true, delete: !invoiceNumberStatus })}
                  </td>
                </tr>
              </Fragment>

            ))}
          </tbody>
        </table>
        {
          viewType !== EVENT_OPERATION.DETAILS && !invoiceNumberStatus && (
          <div className="grn-sku-add">
            <Button
              iconBtnSmall
              secondary
              iconName="plus"
              onClick={() => onIconClick(EVENT_OPERATION.CREATE, {})}
            />
          </div>
          )
        }
      </div>
    </Fragment>);
};

TableView.propTypes = propTypes;

TableView.defaultProps = defaultProps;

const TableViewWithErrorAndLoading = withErrorAndLoadingScreen(TableView);


const TotalView = ({ ...props }) => {
  const {
    update,
    refsObj,
    enableErrorDisplay,
    priceDetails,
    onInputChange,
    invoiceNumberStatus,
    viewType,
  } = props;

  return (
    <Fragment>
      <div className="grn-sku-update">
        <div className="grn-sku-update-inner">
          <Row>
            <Col md={6}>
              <span>Sub Total</span>
            </Col>
            <Col md={6}>
              <div>
                {fixedFloatAndCommas(priceDetails.subTotal)}
                {' '}
              </div>
            </Col>
            <Col md={6} className="mt-5">
              <span>Bill Discount</span>
            </Col>
            <Col md={6}>
              <Input
                name="billDiscount"
                type="number"
                enableValidation
                rule="isFloat"
                ref={ref => refsObj.billDiscount = ref}
                argument={{
                  min: 0,
                  max: priceDetails.subTotal - priceDetails.tradeDiscount,
                }}
                value={priceDetails.billDiscount}
                enableErrorDisplay={enableErrorDisplay}
                onChange={event => onInputChange(event)}
                disabled={invoiceNumberStatus || (viewType === EVENT_OPERATION.DETAILS)}
                errorMessage={INVALID}
              />
            </Col>
            <Col md={6} className="mt-5">
              <span>Trade Discount</span>
            </Col>
            <Col md={6}>
              <Input
                name="tradeDiscount"
                type="number"
                enableValidation
                rule="isFloat"
                argument={{
                  min: 0,
                  max: priceDetails.subTotal - priceDetails.billDiscount,
                }}
                ref={ref => refsObj.tradeDiscount = ref}
                value={priceDetails.tradeDiscount}
                enableErrorDisplay={enableErrorDisplay}
                onChange={event => onInputChange(event)}
                disabled={invoiceNumberStatus || (viewType === EVENT_OPERATION.DETAILS)}
                errorMessage={INVALID}
              />
            </Col>
            <Col md={6}>
              <span>Taxable Amount</span>
            </Col>
            <Col md={6}>
              <div>
                {' '}
                {fixedFloatAndCommas(priceDetails.taxableAmount)}
                {' '}
              </div>
            </Col>
            <Col md={6}>
              <span>VAT</span>
            </Col>
            <Col md={6}>
              <div>
                {fixedFloatAndCommas(priceDetails.taxAmount)}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="grn-sku-update-total">
        <span>Total</span>
        <div>
          {fixedFloatAndCommas(priceDetails.total)}
        </div>
      </div>
    </Fragment>
  );
};


export { TableViewWithErrorAndLoading as TableView, TotalView };

TableView.propTypes = propTypes;

TableView.defaultProps = defaultProps;

export default TableViewWithErrorAndLoading;
