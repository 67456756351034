import { compose } from 'recompose';
import SkuAssortment from './SkuAssortment';
import { query, mutation, TOGGLE_SKU_IN_SUBD_ASSORTMENT } from './API';
import withRequestTracker from '../../../../../utils/composition/withRequestTracker';

const composedSkuAssortment = compose(
  TOGGLE_SKU_IN_SUBD_ASSORTMENT,
  withRequestTracker({
    query,
    mutation,
  }),
)(SkuAssortment);

export default composedSkuAssortment;
