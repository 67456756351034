import styled from 'styled-components';

const BuHolidayWrapper = styled.div`
    .bu-holidays{
        border-top: none;
        padding: 0px;
    };

    .subd-holiday-wrap .bu-form-content .holiday-status-checkbox .checkbox-list {
    label {
      width: auto;
      margin-bottom: 0;
      margin-right: 20px;
    }
  }

`;

export default BuHolidayWrapper;
