import {
  compose,
} from 'recompose';
import Table from './Table';
import withRequestTracker from '../../../../../utils/composition/withRequestTracker';
import { VALIDATE_SRN_DATA } from '../API';
// import { grnImageUploader } from '../../../../utils/image';

const ComposedCreateSrnTable = compose(
  withRequestTracker({
    query: {
      validateSrnData: VALIDATE_SRN_DATA,
    },
  }),
  // grnImageUploader,
)(Table);

export default ComposedCreateSrnTable;
