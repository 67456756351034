import React, { Component } from 'react';
import Proptypes from 'prop-types';
import withAlert from '../../../utils/composition/withAlert';
import View from './View';
import { inputChange } from '../../../utils/formHandlers';
import BuStyled from '../../configuration/bu/BuStyled';

const staticData = {
  weekendDetails: [],
  holidayCount: {
    casual: 0,
    sick: 0,
    home: 0,
    unpaid: 0,
  },
  holidayDetails: [
    {
      title: '',
      start: new Date(),
      end: new Date(),
    }],
};

const propTypes = {
  serverResponseWaiting: false,
  replicateHoliday: Proptypes.bool.isRequired,
  updateCalendar: Proptypes.func.isRequired,
  getCalendarDetail: Proptypes.func.isRequired,
  createCalendar: Proptypes.func.isRequired,
  callHolidaySection: Proptypes.bool.isRequired,
  params: Proptypes.shape({}),
};

const defaultProps = {
  serverResponseWaiting: false,
};

class HolidaysSection extends Component {
  constructor(props) {
    super(props);
    const { params } = props;
    this.state = {
      data: {
        ...staticData,
        ...{
          domain: params.id ? 'DISTRIBUTOR' : 'BU',
          distributorId: params.id ? parseInt(params.id, 10) : null,
        },
        replicate: false,
      },
      subDistId: 0,
    };
  }

  componentDidMount() {
    const { data } = this.state;
    const { domain, distributorId } = data;
    this.getHolidaysInfo({ domain, distributorId });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { callHolidaySection } = this.props;
    const { replicate } = this.state;

    if (callHolidaySection !== prevProps.callHolidaySection) {
      this.createHolidays();
    }

    if (replicate !== prevState.replicate) {
      const data = {
        domain: 'BU',
        distributorId: null,
      };
      this.getHolidaysInfo(data);
    }
  }

  getHolidaysInfo = (data) => {
    const { getCalendarDetail, params } = this.props;
    getCalendarDetail({ input: data },
      {
        handleSuccess: (response) => {
          const { getCalendar } = response.data;
          const subDistId = getCalendar.domain === 'DISTRIBUTOR' ? { subDistId: getCalendar.id } : {};
          this.setState({
            data: getCalendar,
            ...subDistId,
          });
        },
      });
  };

  handleInputChange = (event, firstParam = '', paramList = []) => {
    const { data } = this.state;
    if (!event.target.checked && paramList.length === 0) {
      this.setState({
        data: {
          ...data,
          ...{
            weekendDetails: data.weekendDetails.filter(d => d !== event.formattedValue),
          },
        },
      });
    } else {
      const updatedDetails = inputChange(data, event, firstParam, paramList);
      if (data.id !== 0 && data.id !== null && paramList.length > 2) {
        updatedDetails.holidayDetails[paramList[1].toString()].edited = true;
      }
      this.setState({ data: updatedDetails });
    }
  };

  handleDateRangeChange = (name, date, index) => {
    const { data } = this.state;
    data.holidayDetails[index] = {
      ...data.holidayDetails[index],
      ...{
        [name]: date,
      },
    };
    this.setState({
      data,
    });
  };

  handleHolidaysAdd = () => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        ...{
          holidayDetails: [
            ...data.holidayDetails,
            ...staticData.holidayDetails,
          ],
        },
      },
    });
  };

  handleCancelHoliday = (title) => {
    const { data } = this.state;
    const { holidayDetails } = data;
    const filterData = [...holidayDetails].filter(d => d.title !== title);
    this.setState({
      data: {
        ...data,
        ...{
          holidayDetails: filterData,
        },
      },
    });
  };

  createHolidays = () => {
    const { updateCalendar, createCalendar, params } = this.props;
    const { subDistId, data: mainData } = this.state;
    const { id, ...data } = mainData;
    const createData = {
      ...data,
      domain: params.id ? 'DISTRIBUTOR' : 'BU',
      distributorId: params.id ? parseInt(params.id, 10) : null,
    };
    const createId = params.id ? subDistId : id;
    if (createId) {
      updateCalendar({ id: createId, input: createData },
        {
          handleSuccess: (response) => {
            const { updateCalendar: updateCalendarData } = response.data;
            this.setState({
              data: updateCalendarData,
            });
          },
        });
    } else {
      createCalendar({ input: createData },
        {
          handleSuccess: (response) => {
            const { createCalendar: createCalendarData } = response.data;
            this.setState({
              data: createCalendarData,
            });
          },
        });
    }
  };

  toggleToReplicate = (toggleState) => {
    this.setState(state => ({
      replicate: toggleState,
      ...state.data,
    }));
  };

  render() {
    const { data, replicate } = this.state;
    const week = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const { serverResponseWaiting, replicateHoliday, className } = this.props;
    return (
      <BuStyled className={className}>
        <View
          holidayData={data}
          week={week}
          loading={serverResponseWaiting}
          handleInputChange={this.handleInputChange}
          handleCancelHoliday={this.handleCancelHoliday}
          handleDateRangeChange={this.handleDateRangeChange}
          handleHolidaysAdd={this.handleHolidaysAdd}
          toggleToReplicate={this.toggleToReplicate}
          replicate={replicate}
          replicateHoliday={replicateHoliday}
        />
      </BuStyled>
    );
  }
}

HolidaysSection.propTypes = propTypes;

HolidaysSection.defaultProps = defaultProps;

export default withAlert()(HolidaysSection);
