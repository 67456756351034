import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import View from './View';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import * as arrayProcessor from '../../../utils/arrayProcessor';
import {
  defaultDateConfig,
  filter as filterConfig,
  ORDER_TABS, ORDER_TYPE,
  OrderFulFilmentContext,
  SERVER_ORDER_STATUS,
  skuStockValidation, storageKeys,
  tabMapper, MTD,
} from './config';
import { LIMIT, PAGE } from '../../../data/enums/GeneralConstants';
import { apiFilterProcessor, getOffsetFromPageAndLimit } from '../../../utils/api';
import { getPermissionForOrderFulfilment } from '../../base/permission';
import {
  fetchFromLocalStorage, imageSliderListFormatter,
  isError,
  storeInLocalStorage,
  getSortValueAfterValueCheck,
} from '../../common/HelperFunctions';
import { generateFilterValue } from '../../../utils/filter';
import { has, isObjectEmpty } from '../../../utils/objectPrototypes';
import { DOMAIN } from '../../../data/enums/config';
import * as queryService from '../../base/query.service';


const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  getReceivedList: PropTypes.func.isRequired,
  getInvoicedList: PropTypes.func.isRequired,
  getDeliveredList: PropTypes.func.isRequired,
  getDispatchedList: PropTypes.func.isRequired,
  getReceivedDetailList: PropTypes.func.isRequired,
  getInvoicedDetailList: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class OrderProcessing extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      received: {
        list: [],
        total: 0,
        limit: LIMIT,
        page: PAGE,
        filterFlag: false,
        initialCall: true,
      },
      invoiced: {
        list: [],
        total: 0,
        limit: LIMIT,
        page: PAGE,
        filterFlag: false,
        initialCall: true,
      },
      dispatched: {
        list: [],
        total: 0,
        limit: LIMIT,
        page: PAGE,
        filterFlag: false,
        initialCall: true,
      },
      delivered: {
        list: [],
        total: 0,
        limit: LIMIT,
        page: PAGE,
        filterFlag: false,
        initialCall: true,
      },
      replaced: {
        list: [],
        total: 0,
        limit: LIMIT,
        page: PAGE,
        filterFlag: false,
        initialCall: true,
      },
      date: {
        ...defaultDateConfig,
      },
      activeTab: 0,
      activeLine: {
        id: 0,
        sellerId: 0,
      },
      filter: {
        ...filterConfig.instance,
      },
      filterMenu: { ...filterConfig.menu || {} },
      searchText: '',
      queryParameters: {
        sort: {},
      },
    };
    this.permission = getPermissionForOrderFulfilment();
    this.persistFilterState = true;
    this.tabListGetterMapper = {
      [ORDER_TABS.RECEIVED]: this.getReceivedPaginatedList,
      [ORDER_TABS.INVOICED]: this.getInvoicedPaginatedList,
      [ORDER_TABS.DISPATCHED]: this.getDispatchedPaginatedList,
      [ORDER_TABS.DELIVERED]: this.getDeliveredPaginatedList,
      [ORDER_TABS.REPLACED]: this.getStockReplacedPaginatedList,
    };
    this.baseService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.getDataOnFilterChange,
    );
  }

  componentDidMount() {
    // TODO, need to update in up comming sprint
    // this.fetchTabsPaginatedList();
    const { location } = this.props;
    if (location.state) {
      this.setState({
        activeTab: location.state.activeTab === 4 ? 4 : 1,
        date: { ...MTD },
      }, () => {
        this.getFilterStateFromStorage();
      });
    } else {
      this.getFilterStateFromStorage();
    }
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  getData = () => {
    const { data } = this.state;

    return data;
  };

  setQueryParameters = (queryParams, callBack = () => null) => this.setState(
    { queryParameters: queryParams },
    callBack,
  );

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  handleFilterChange = (filterValue) => {
    this.setState({ filter: generateFilterValue(filterValue) || {}, filterMenu: filterValue },
      () => {
        this.getTabsTotalCount();
        this.getDataOnFilterChange();
      });
  };

  handleDateRangeChange = (type, value) => {
    const { date } = this.state;
    date.start = value.start;
    date.end = value.end;
    this.setState({ date }, () => {
      this.getTabsTotalCount();
      this.getDataOnFilterChange();
    });
  };

  handleSearchChange = (searchText) => {
    this.setState({ searchText }, () => {
      this.getTabsTotalCount();
      this.getDataOnFilterChange();
    });
  };

  getDataOnFilterChange = () => {
    const {
      received, invoiced, dispatched, delivered, activeTab, replaced,
    } = this.state;

    received.page = PAGE;
    received.filterFlag = true;
    invoiced.page = PAGE;
    invoiced.filterFlag = true;
    dispatched.page = PAGE;
    dispatched.filterFlag = true;
    delivered.page = PAGE;
    delivered.filterFlag = true;
    replaced.page = PAGE;
    replaced.filterFlag = true;

    this.setState({
      received,
      invoiced,
      dispatched,
      delivered,
    }, () => {
      this.fetchActiveTabPaginatedList(activeTab);
    });
  };

  getFilterStateFromStorage = () => {
    const { activeTab } = this.state;
    const newFilterConfig = {
      ...(fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.SALES) || {
        filter: { ...filterConfig.instance },
        filterMenu: { ...filterConfig.menu },
        date: { ...defaultDateConfig },
        searchText: '',
      }),
    };
    this.setState({
      filter: newFilterConfig.filter,
      filterMenu: newFilterConfig.filterMenu,
      date: activeTab === 4 || activeTab === 1 ? MTD : newFilterConfig.date,
      searchText: newFilterConfig.searchText,
    }, () => {
      this.getTabsTotalCount();
      this.fetchActiveTabPaginatedList(activeTab);
      window.addEventListener('beforeunload', () => this.setFilterStateInStorage());
    });
  };

  setFilterStateInStorage = () => {
    const {
      filter, filterMenu, date, searchText,
    } = this.state;

    storeInLocalStorage(storageKeys.FILTER, {
      filter, filterMenu, date, searchText,
    }, DOMAIN.SALES);
  };

  fetchActiveTabPaginatedList = (tab) => {
    const type = tabMapper[tab];
    this.tabListGetterMapper[type]();
  };

  componentWillUnmount() {
    this.setFilterStateInStorage();
    window.removeEventListener('beforeunload', () => {});
  }

  checkIfPaginatedListCallApproved = stateTab => stateTab.initialCall || stateTab.filterFlag;

  getTabsTotalCount = () => {
    const {
      received, invoiced, dispatched, delivered, filter, date, searchText, replaced,
    } = this.state;
    const { getTabsTotal, displayAlert } = this.props;

    getTabsTotal({
      filter: {
        filters: apiFilterProcessor(filter),
        dateRange: date,
        queryString: searchText,
      },
    }, {
      handleSuccess: (response) => {
        const tabsTotalCount = response.data.allOrderCount || {};
        received.total = tabsTotalCount[ORDER_TABS.RECEIVED] || 0;
        invoiced.total = tabsTotalCount[ORDER_TABS.INVOICED] || 0;
        dispatched.total = tabsTotalCount[ORDER_TABS.DISPATCHED] || 0;
        delivered.total = tabsTotalCount[ORDER_TABS.DELIVERED] || 0;
        replaced.total = tabsTotalCount[ORDER_TABS.REPLACED] || 0;
        this.setState({
          received,
          invoiced,
          dispatched,
          delivered,
          replaced,
        });
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  };

  /** get paginated list here */
  getReceivedPaginatedList = () => {
    const { getReceivedList, displayAlert } = this.props;
    const {
      received,
      filter,
      date,
      searchText,
      queryParameters,
    } = this.state;

    const offset = getOffsetFromPageAndLimit(received.page, received.limit);
    if (this.checkIfPaginatedListCallApproved(received)) {
      getReceivedList({
        offset,
        limit: received.limit,
        filter: {
          filters: apiFilterProcessor(filter),
          dateRange: date,
          queryString: searchText,
          sort: getSortValueAfterValueCheck(queryParameters.sort),
        },
      }, {
        handleSuccess: (response) => {
          received.list = (response.data.receivedCalls && response.data.receivedCalls.rows) || [];
          // received.total = (response.data.receivedCalls && response.data.receivedCalls.count) || 0;
          received.filterFlag = false;
          received.initialCall = false;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(received);
        },
        handleError: (error) => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      });
    }
  };

  getInvoicedPaginatedList = () => {
    const { getInvoicedList, displayAlert } = this.props;
    const {
      invoiced, filter, date, searchText, queryParameters,
    } = this.state;
    // all urls for search, filter and pagination
    const offset = getOffsetFromPageAndLimit(invoiced.page, invoiced.limit);
    if (this.checkIfPaginatedListCallApproved(invoiced)) {
      getInvoicedList({
        offset,
        limit: invoiced.limit,
        filter: {
          filters: apiFilterProcessor(filter),
          dateRange: date,
          queryString: searchText,
          sort: getSortValueAfterValueCheck(queryParameters.sort),
        },
      }, {
        handleSuccess: (response) => {
          invoiced.list = (response.data.invoices && response.data.invoices.rows) || [];
          // invoiced.total = (response.data.invoices && response.data.invoices.count) || 0;
          invoiced.filterFlag = false;
          invoiced.initialCall = false;
          this.setState(invoiced);
        },
        handleError: (error) => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      });
    }
  };

  getDispatchedPaginatedList = () => {
    const { getDispatchedList, displayAlert } = this.props;
    const {
      dispatched, filter, date, searchText, queryParameters,
    } = this.state;
    // all urls for search, filter and pagination
    const offset = getOffsetFromPageAndLimit(dispatched.page, dispatched.limit);
    if (this.checkIfPaginatedListCallApproved(dispatched)) {
      getDispatchedList({
        offset,
        limit: dispatched.limit,
        filter: {
          filters: apiFilterProcessor(filter),
          dateRange: date,
          queryString: searchText,
          sort: getSortValueAfterValueCheck(queryParameters.sort),
        },
      }, {
        handleSuccess: (response) => {
          dispatched.list = (response.data.dispatchedInvoices && response.data.dispatchedInvoices.rows) || [];
          // dispatched.total = (response.data.dispatchedInvoices && response.data.dispatchedInvoices.count) || 0;
          dispatched.filterFlag = false;
          dispatched.initialCall = false;
          this.setState(dispatched);
        },
        handleError: (error) => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      });
    }
  };

  getDeliveredPaginatedList = () => {
    const { getDeliveredList, displayAlert } = this.props;
    const {
      delivered, filter, date, searchText, queryParameters,
    } = this.state;
    // all urls for search, filter and pagination
    const offset = getOffsetFromPageAndLimit(delivered.page, delivered.limit);
    if (this.checkIfPaginatedListCallApproved(delivered)) {
      getDeliveredList({
        offset,
        limit: delivered.limit,
        filter: {
          filters: apiFilterProcessor(filter),
          dateRange: date,
          queryString: searchText,
          sort: getSortValueAfterValueCheck(queryParameters.sort),
        },
      }, {
        handleSuccess: (response) => {
          delivered.list = (response.data.deliveredInvoices && response.data.deliveredInvoices.rows) || [];
          // delivered.total = (response.data.deliveredInvoices && response.data.deliveredInvoices.count) || 0;
          delivered.filterFlag = false;
          delivered.initialCall = false;
          this.setState(delivered);
        },
        handleError: (error) => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      });
    }
  };

  getStockReplacedPaginatedList = () => {
    const { getStockReplacedList, displayAlert } = this.props;
    const {
      replaced, filter, date, searchText,
    } = this.state;
    // all urls for search, filter and pagination
    const offset = getOffsetFromPageAndLimit(replaced.page, replaced.limit);
    if (this.checkIfPaginatedListCallApproved(replaced)) {
      getStockReplacedList({
        offset,
        limit: replaced.limit,
        filter: {
          filters: apiFilterProcessor(filter),
          dateRange: date,
          queryString: searchText,
        },
      }, {
        handleSuccess: (response) => {
          replaced.list = (response.data.replacementOrders && response.data.replacementOrders.rows) || [];
          // delivered.total = (response.data.deliveredInvoices && response.data.deliveredInvoices.count) || 0;
          replaced.filterFlag = false;
          replaced.initialCall = false;
          this.setState(replaced);
        },
        handleError: (error) => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      });
    }
  };

  getReceivedDetailList = (id, sellerId) => {
    const { getReceivedDetailList, displayAlert } = this.props;
    const { received } = this.state;
    // all urls for search, filter and pagination
    if (!this.checkIfDetailAlreadyExist(id, sellerId, received.list)) {
      getReceivedDetailList({
        callId: id,
        sellerId,
      }, {
        handleSuccess: (response) => {
          const orders = response.data.callOrders ? response.data.callOrders : [];
          const updatedOrder = this.processCallOrders(orders);
          received.list = arrayProcessor.findAndUpdateItem(
            received.list, id, 'Orders', updatedOrder,
          );
          this.setState(received);
        },
        handleError: (error) => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      });
    }
  };

  getInvoicedDetailList = (id, sellerId, type, refId, orderType) => {
    const { getInvoicedDetailList, displayAlert } = this.props;
    const { [type]: tab } = this.state;
    // all urls for search, filter and pagination
    if (!this.checkIfDetailAlreadyExist(
      id, sellerId, tab.list || [],
    )) {
      if (orderType === ORDER_TYPE.REPLACEMENT) {
        this.fetchReplacedDetailList(refId)
          .then(orders => this.updateActiveLineOrders(
            id,
            [{ Lines: [...orders] }],
            type,
            ORDER_TYPE.REPLACEMENT,
          ));
      } else {
        getInvoicedDetailList({
          input: {
            id: type === ORDER_TABS.INVOICED ? id : refId,
            type: SERVER_ORDER_STATUS[type],
          },
        }, {
          handleSuccess: (response) => {
            const orders = response.data.invoiceDetails
              ? response.data.invoiceDetails.Orders || [] : [];
            this.updateActiveLineOrders(id, orders, type);
          },
          handleError: (error) => {
            displayAlert(ALERT_TYPE.DANGER, error);
          },
        });
      }
    }
  };

  getStockReplacedDetailList = (id, sellerId, type) => {
    const { getReplacedDetailList, displayAlert } = this.props;
    // all urls for search, filter and pagination
    const { [type]: tab } = this.state;
    if (!this.checkIfDetailAlreadyExist(id, sellerId, tab.list || [])) {
      this.fetchReplacedDetailList(id)
        .then(orders => this.updateActiveLineOrders(id, orders, type));
    }
  };

  fetchReplacedDetailList = async (id) => {
    const { getReplacedDetailList, displayAlert } = this.props;
    return new Promise((resolve, reject) => {
      getReplacedDetailList({
        replacementId: id,
      }, {
        handleSuccess: (response) => {
          const orders = (response.data.replacementOrderSummary
            && response.data.replacementOrderSummary) || [];
          const modifiedOrders = orders.map((sku) => {
            sku.images = [...(imageSliderListFormatter(sku.images) || [])];

            return sku;
          });
          resolve(modifiedOrders);
        },
        handleError: (error) => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      });
    });
  };

  checkIfDetailAlreadyExist = (id, sellerId, list, reference = 'id') => {
    const targetedRow = list.find(item => (item[reference] === id
      && (item.Distributor
        ? item.Distributor.id === sellerId
        : false))) || {};
    if (!isObjectEmpty(targetedRow)) {
      return ((has.call(targetedRow, 'Orders')) ? targetedRow.Orders.length > 0 : false);
    }

    return false;
  };

  updateActiveLineOrders = (id, orders, tab) => {
    const {
      invoiced, dispatched, delivered, replaced,
    } = this.state;

    switch (tab) {
      case ORDER_TABS.INVOICED:
        invoiced.list = arrayProcessor.findAndUpdateItem(
          invoiced.list, id, 'Orders', orders,
        ) || [];
        this.setState({ invoiced });
        break;
      case ORDER_TABS.DISPATCHED:
        // eslint-disable-next-line no-case-declarations

        dispatched.list = arrayProcessor.findAndUpdateItem(
          dispatched.list, id, 'Orders', orders,
        ) || [];
        this.setState({ dispatched });
        break;
      case ORDER_TABS.DELIVERED:
        delivered.list = arrayProcessor.findAndUpdateItem(
          delivered.list, id, 'Orders', orders,
        ) || [];
        this.setState({ delivered });
        break;
      case ORDER_TABS.REPLACED:
        replaced.list = arrayProcessor.findAndUpdateItem(
          replaced.list, id, 'Orders', orders,
        ) || [];
        this.setState({ replaced });
        break;
      default:
        break;
    }
  };

  updateActiveTabList = (tab, list, total) => {
    const {
      received, invoiced, dispatched, delivered, replaced,
    } = this.state;
    switch (tab) {
      case ORDER_TABS.RECEIVED:
        received.list = list;
        received.total = total;
        this.setState({ received });
        break;
      case ORDER_TABS.INVOICED:
        invoiced.list = list;
        invoiced.total = total;
        this.setState({ invoiced });
        break;
      case ORDER_TABS.DISPATCHED:
        dispatched.list = list;
        dispatched.total = total;
        this.setState({ dispatched });
        break;
      case ORDER_TABS.DELIVERED:
        delivered.list = list;
        delivered.total = total;
        this.setState({ delivered });
        break;
      case ORDER_TABS.REPLACED:
        replaced.list = list;
        replaced.total = total;
        this.setState({ replaced });
        break;
      default:
        break;
    }
  };


  handleTabChange = (tab) => {
    const { activeLine } = this.state;
    activeLine.id = 0;
    activeLine.sellerId = 0;

    this.setState({
      activeTab: tab,
      activeLine,
    }, () => this.fetchActiveTabPaginatedList(tab));
  };

  handlePassiveTabListChange = (id, tab, count) => {
    /** todo: fetch paginated list with invoiceId filter
     *  todo: update filter object with id,
     * */
    const {
      received, invoiced, dispatched, delivered, replaced,
    } = this.state;
    switch (tab) {
      case ORDER_TABS.RECEIVED:
        received.total += count;
        received.filterFlag = true;
        this.setState({ received },
          () => this.getReceivedPaginatedList());
        break;
      case ORDER_TABS.INVOICED:
        invoiced.total += count;
        invoiced.filterFlag = true;
        this.setState({ invoiced },
          () => this.getInvoicedPaginatedList());
        break;
      case ORDER_TABS.DISPATCHED:
        dispatched.total += count;
        dispatched.filterFlag = true;
        this.setState({ dispatched },
          () => this.getDispatchedPaginatedList());
        break;
      case ORDER_TABS.DELIVERED:
        delivered.total += count;
        delivered.filterFlag = true;
        this.setState({ delivered },
          () => this.getDeliveredPaginatedList());
        break;
      case ORDER_TABS.REPLACED:
        replaced.total += count;
        replaced.filterFlag = true;
        this.setState({ replaced },
          () => this.getStockReplacedPaginatedList());
        break;
      default:
        break;
    }
  };

  handleCallRowClick = (id, sellerId, refId, orderType) => {
    const { activeTab } = this.state;
    const type = tabMapper[activeTab];

    switch (type) {
      case ORDER_TABS.RECEIVED:
        this.getReceivedDetailList(id, sellerId);
        break;
      case ORDER_TABS.INVOICED:
      case ORDER_TABS.DISPATCHED:
      case ORDER_TABS.DELIVERED:
        this.getInvoicedDetailList(id, sellerId, type, refId, orderType);
        break;
      case ORDER_TABS.REPLACED: this.getStockReplacedDetailList(id, sellerId, type);
        break;
      default:
        break;
    }
    this.updateActiveLine(id, sellerId);
  };

  updateActiveLine = (id, sellerId) => {
    this.setState({
      activeLine: {
        id,
        sellerId,
      },
    });
  };

  handlePaginationChange = (type, pagination) => {
    const {
      received, invoiced, dispatched, delivered, replaced,
    } = this.state;
    switch (type) {
      case ORDER_TABS.RECEIVED:
        received.limit = pagination.limit;
        received.page = pagination.page;
        received.filterFlag = true;
        this.setState({ received },
          () => this.getReceivedPaginatedList());
        break;
      case ORDER_TABS.INVOICED:
        invoiced.limit = pagination.limit;
        invoiced.page = pagination.page;
        invoiced.filterFlag = true;
        this.setState({ invoiced },
          () => this.getInvoicedPaginatedList());
        break;
      case ORDER_TABS.DISPATCHED:
        dispatched.limit = pagination.limit;
        dispatched.page = pagination.page;
        dispatched.filterFlag = true;
        this.setState({ dispatched },
          () => this.getDispatchedPaginatedList());
        break;
      case ORDER_TABS.DELIVERED:
        delivered.limit = pagination.limit;
        delivered.page = pagination.page;
        delivered.filterFlag = true;
        this.setState({ delivered },
          () => this.getDeliveredPaginatedList());
        break;
      case ORDER_TABS.REPLACED:
        replaced.limit = pagination.limit;
        replaced.page = pagination.page;
        replaced.filterFlag = true;
        this.setState({ replaced },
          () => this.getStockReplacedPaginatedList());
        break;
      default:
        break;
    }
  };


  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  /* onFilterChange = (field, value) => {
    if (field === 'date') {
      this.setState({ date: value }, () => this.fetchTabsPaginatedList());
    } else {
      this.setState({ filter: field }, () => this.fetchTabsPaginatedList());
    }
  }; */

  /** subscribedService => Obj of subscribed services  */
  fetchTabsPaginatedList = (subscribedService) => {
    this.getReceivedPaginatedList();
    this.getInvoicedPaginatedList();
    this.getDispatchedPaginatedList();
    this.getDeliveredPaginatedList();
  };

  stateGetter = (label = 'received') => this.state[label];

  stateSetter = (stateValue, label = 'received') => {
    this.setState({ [label]: stateValue });
  };

  processCallOrders = (orders, stockValidation = true) => {
    const updatedOrders = orders.map((item) => {
      if (!item.promotionId) {
        return (this.disintegrateNonPromotionOrder(item.Lines || [], stockValidation));
      }
      if (stockValidation) item.skuStatus = skuStockValidation(item.Lines);

      return item;
    });

    return ([].concat(...updatedOrders));
  };

  disintegrateNonPromotionOrder = (orders, stockValidation) => {
    const disintegratedNonPromotionOrder = orders.map((item) => {
      const nonPromotionOrder = {
        id: null,
        promotionId: null,
        Lines: [item],
        skuStatus: skuStockValidation([item]),
      };
      if (stockValidation) nonPromotionOrder.skuStatus = skuStockValidation([item]);

      return nonPromotionOrder;
    });

    return disintegratedNonPromotionOrder;
  };

  render() {
    const {
      date,
      filter,
      activeTab,
      received,
      invoiced,
      replaced,
      delivered,
      dispatched,
      activeLine,
      filterMenu,
      searchText,
    } = this.state;

    const {
      history,
      displayAlert,
      serverResponseWaiting,
    } = this.props;
    return (
      <Fragment>
        <OrderFulFilmentContext.Provider value={{ permission: this.permission }}>
          <View
            date={date}
            history={history}
            stateSetter={this.stateSetter}
            stateGetter={this.stateGetter}
            activeTab={activeTab}
            activeLine={activeLine}
            received={received}
            invoiced={invoiced}
            replaced={replaced}
            delivered={delivered}
            dispatched={dispatched}
            displayAlert={displayAlert}
            loading={serverResponseWaiting}
            filter={filter}
            filterMenu={filterMenu}
            searchText={searchText}
            onDateRangeChange={this.handleDateRangeChange}
            onFilterChange={this.handleFilterChange}
            handleActiveTabListChange={this.updateActiveTabList}
            handlePassiveTabListChange={this.handlePassiveTabListChange}
            onTabChange={this.handleTabChange}
            onCallRowClick={this.handleCallRowClick}
            onPageChange={this.handlePaginationChange}
            onSearchInputChange={this.handleSearchChange}
            persistFilterState={this.persistFilterState}
            onHeaderClick={this.baseService.handleTableSorting}
          />
        </OrderFulFilmentContext.Provider>
      </Fragment>
    );
  }
}

OrderProcessing.propTypes = propTypes;

OrderProcessing.defaultProps = defaultProps;

export default withAlert()(OrderProcessing);
