import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const LEAVE_REQUEST_FRAGMENT = gql`
    fragment LeaveRequestFragment on LeaveRequest {
        id
        leaveType
        reason
        startDate
        endDate
        createdAt
        days
        status
        requestedTo
        RequestedBy {
            id
            firstName
            lastName
        }
        RequestedTo {
            id
            firstName
            lastName
        }
        UserGroup {
            id
            name
            displayName
        }
    }
`;

const LEAVE_REQUEST_SUMMARY_FRAGMENT = gql`
  fragment LeaveRequestSummaryFragment on LeaveRequest {
      id
      leaveType
      startDate
      endDate
      status
      requestedBy
      leaveType
  }
`;

const GET_LEAVE_REQUEST = gql`
    query getLeaveRequest($offset: Int, $limit: Int, $filter: FilterInput) {
        leaveRequests(offset: $offset, limit: $limit, filter: $filter) {
            rows {
                ...LeaveRequestFragment
            }
            count
        }
    }
    ${LEAVE_REQUEST_FRAGMENT}
`;


const APPROVE_LEAVE_REQUEST = graphql(
  gql`
      mutation($status: status!, $input: [ApproveLeaveRequestInput]!) {
          approveLeaveRequest(status: $status, input: $input) {
              message
              leaveRequests {
                  approvedFor
                  approvedBy
                  approvalNotPermittedFor
              }
          }
      }
  `,
  {
    props: ({ mutate }) => ({
      approveLeaveRequest: variables => mutate({
        variables,
      }),
    }),
  },
);

const query = {
  getLeaveRequest: GET_LEAVE_REQUEST,
};

const mutation = {
  approveLeaveRequest: APPROVE_LEAVE_REQUEST,
};

export {
  GET_LEAVE_REQUEST,
  APPROVE_LEAVE_REQUEST,
  LEAVE_REQUEST_SUMMARY_FRAGMENT,
};

export { query, mutation };
