import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import StockAdjustmentView from './View';
import { getUser } from '../../../data/services';
import PageHeader from '../../base/PageHeader';
import { DialogFormWrapper } from '../../common';
import { isError, getSortValueAfterValueCheck } from '../../common/HelperFunctions';
import StockAdjustmentForm from './StockAdjustmentForm';
import * as queryService from '../../base/query.service';
import { PanelStyled } from '../../common/configuration';
import { FORM_CONFIG } from '../../../data/enums/config';
import { stockAdjustment } from '../../common/DomainConfig';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { ADMIN_ROLE_GROUP, DISTRIBUTOR_ASSOCIATED_ROLES } from '../../../data/enums/UserRole';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { getPermissionForStockAdjustment } from '../../base/permission';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';
import {
  formConfig, crudRequestConfig, breadCrumbConfig, filterConfig,
} from './config';
import { crudResponseProcessor, handleFormSubmit } from '../../../utils/crudResponseProcessor';
import { checkAndResetPaginatedData } from '../../../utils/paginatedListUtils';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  getDistributors: PropTypes.func.isRequired,
  createStockAdjustment: PropTypes.func.isRequired,
  getStockAdjustmentList: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class StockAdjustment extends Component {
  constructor(props) {
    super(props);
    this.userInfo = getUser();
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      display: {
        searchBox: false,
        upload: true,
      },
      distributorList: [],
      dialog: {
        type: '',
        element: formConfig[FORM_CONFIG.MAPPER]({
          distributorId: this.userInfo.Distributor.length > 0 ? this.userInfo.Distributor[0].id : 0,
        }),
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: {},
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
      },
    };
    const serverCall = {
      [EVENT_OPERATION.UPDATE]: props.createStockAdjustment,
    };
    this.permission = getPermissionForStockAdjustment();
    this.componentConfig = stockAdjustment.componentsDisplay(this.permission);
    this.getData = () => {
      const { data } = this.state;
      return data;
    };
    this.setData = (data) => {
      this.setState({ data });
    };
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      props.displayAlert,
    );
    this.onCRUDSuccess = crudResponseProcessor((data, type) => {
      const { displayAlert } = this.props;
      this.setState(data);
      displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message);
    }, this.getData, ['balance', 'lastAdjustment', 'lastDate', 'reason', 'negative'], 'skuId');
    this.onFormSubmit = handleFormSubmit(
      this.onCRUDSuccess, this.onAPIRequestFailure, crudRequestConfig, serverCall,
    );
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount() {
    const { dialog } = this.state;
    if (ADMIN_ROLE_GROUP.includes(this.userInfo.roleId)
      || DISTRIBUTOR_ASSOCIATED_ROLES.includes(this.userInfo.roleId)) {
      this.getDistributorsList();
    }
    if (dialog.element.distributorId) this.loadTableData();
  }

  componentDidCatch(error, info) {}

  getDistributorsList = () => {
    const { getDistributors, displayAlert } = this.props;
    getDistributors({ limit: 1000 }, {
      handleSuccess: (response) => {
        this.setState({ distributorList: response.data.distributors.rows });
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  };

  loadTableData = () => {
    const {
      data, queryParameters, dialog,
    } = this.state;
    const { getStockAdjustmentList, displayAlert } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);

    getStockAdjustmentList({
      offset,
      limit: queryParameters.pagination.limit,
      filter: {
        filters: [
          ...apiFilterProcessor(queryParameters.filter),
          { column: 'distributor_id', value: [dialog.element.distributorId.toString() || ''] },
        ],
        // dateRange: queryParameters.date,
        queryString: queryParameters.search,
        sort: getSortValueAfterValueCheck(queryParameters.sort),
      },
    }, {
      handleSuccess: (response) => {
        data.list = response.data.stockAdjustments ? response.data.stockAdjustments.rows : [];
        data.total = response.data.stockAdjustments ? response.data.stockAdjustments.count : 1;
        if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
        this.setState(data);
      },
      handleError: (error) => {
        checkAndResetPaginatedData(data, this.setData);
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  };

  setQueryParameters = (queryParams, callBack = () => null) => this.setState(
    { queryParameters: queryParams },
    callBack,
  );

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleIconClick = (type = '', element) => {
    const { dialog } = this.state;

    this.setState({
      dialog: {
        type,
        element: { ...element },
      },
    });
  };

  handleDistributorSelect = (distributorId) => {
    const { dialog } = this.state;
    dialog.element.distributorId = distributorId;

    this.setState({ dialog }, () => this.loadTableData());
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  resetDialog = () => {
    const { dialog } = this.state;
    this.setState({
      dialog: {
        element: { distributorId: dialog.element.distributorId },
        type: '',
      },
    });
  };

  handleHeaderClick = () => {

  }

  render() {
    const {
      data, queryParameters, display, dialog, distributorList, skuList,
    } = this.state;
    const { serverResponseWaiting } = this.props;
    const { type } = dialog;
    const { element } = dialog;

    return (
      <Fragment>
        {type && (
          <DialogFormWrapper
            onDialogSubmit={this.onFormSubmit}
            formConfig={formConfig}
            dialogElement={element}
            onDialogCancel={this.resetDialog}
            type={type}
            renderDialog={
              ({
                dialogData, enableErrorDisplay, refsObj, handleInputChange,
              }) => (
                <StockAdjustmentForm
                  data={dialogData}
                  loading={serverResponseWaiting}
                  type={type}
                  handleInputChange={handleInputChange}
                  enableErrorDisplay={enableErrorDisplay}
                  refsObj={refsObj}
                />
              )
            }
          />
        )}
        <Fragment>
          <div className="section-header">
            <PanelStyled>
              <PageHeader
                display={display}
                breadCrumb={breadCrumbConfig}
                config={{
                  title: stockAdjustment.title,
                  filter: true,
                  search: true,
                }}
                filter={{
                  // date: queryParameters.date,
                  menuList: filterConfig.menu,
                  onFilterChange: this.basePaginationService.handleFilterChange,
                }}
                queryParameters={queryParameters}
                controlDisplay={this.controlDisplay}
                handleCreateClick={this.handleIconClick}
                resetFilter={this.basePaginationService.resetFilter}
                clearSearchText={this.basePaginationService.clearSearchText}
                handleSearchChange={this.basePaginationService.handleSearchInputChange}
                handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
                handleUploadClick={this.handleIconClick}
              />
            </PanelStyled>
          </div>
          <div className="section-content">
            <StockAdjustmentView
              data={data}
              inputData={element}
              loading={serverResponseWaiting}
              distributorList={distributorList}
              pagination={queryParameters.pagination}
              componentDisplay={this.componentConfig}
              permission={this.permission}
              onIconClick={this.handleIconClick}
              onDropDownChange={this.handleDistributorSelect}
              onPageChange={this.basePaginationService.onPageSelect}
              onHeaderClick={this.basePaginationService.handleTableSorting}
            />
          </div>
        </Fragment>
      </Fragment>
    );
  }
}

StockAdjustment.propTypes = propTypes;

StockAdjustment.defaultProps = defaultProps;

export default withAlert()(StockAdjustment);
