import React from 'react';
import PropTypes from 'prop-types';
import LedgerAdd from '../../../../../components/LedgerAdd';
import TableView from './Table';
import { Button } from '../../../../../../components';
import withErrorAndLoadingScreen from '../../../../../../utils/composition/withLoading';
import LedgerStyled from './LedgerStyled';
import { PanelStyled } from '../../../../../common/configuration';

const propTypes = {
  onCancel: PropTypes.func.isRequired,
  billingUser: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleCreateClick: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape([{}])).isRequired,
  loading: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  distributorId: PropTypes.string.isRequired,
};
const View = (props) => {
  const {
    onCancel, onSubmit, show, handleCreateClick, data, loading, id, billingUser, distributorId,
  } = props;
  return (
    <LedgerStyled>
      <div className="tab-section ledger">

        <PanelStyled>
          <div className="ledger-top-wrap">
            <div className="absolute">
              {data.length}
              <span> Ledgers</span>
            </div>
            <Button iconName="plus" className="primary small " onClick={handleCreateClick}>
              <span>Create</span>
            </Button>
          </div>
        </PanelStyled>


        {show && <LedgerAdd onCancel={onCancel} onSubmit={onSubmit} id={id} billingUser={billingUser} distributorId={distributorId} search />}
        <TableView data={data} loading={loading} billingUser={billingUser} />
      </div>
    </LedgerStyled>
  );
};

View.propTypes = propTypes;

export default withErrorAndLoadingScreen(View);
