import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Row, Col, AutoComplete } from '../../../../../components';

const propTypes = {
  townList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  })),
  subDList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  })),
  onAutoCompleteChange: PropTypes.func,
  promotionAreas: PropTypes.shape({
    townId: PropTypes.number,
    subDId: PropTypes.number,
  }),
};

const defaultProps = {
  subDList: [],
  townList: [],
  promotionAreas: {
    townId: null,
    subDId: null,
  },
  onAutoCompleteChange: () => null,
};

const PromotionArea = ({ ...props }) => {
  const {
    subDList,
    townList,
    onAutoCompleteChange,
    promotionAreas,
  } = props;

  return (
    <Fragment>
      <Row>
        <Col md={8}>
          {
            <Fragment>
              {/* <CustomSelect
                enableValidation
                options={subDList}
                className="custom-select"
                placeholder="Sub D"
                labelContent="Sub D"
                getOptionValue={({ id }) => id}
                getOptionLabel={({ title }) => title}
                onChange={event => onAutoCompleteChange(event.id, 'subDId', 'promotionAreas')}
                value={subDList.filter(({ id }) => id === promotionAreas.subDId)}
              /> */}

              <AutoComplete
                name="subDIdList"
                labelContent="Sub D"
                dropDownList={subDList}
                placeholder="Search"
                selectedDropDowns={promotionAreas.subDIdList}
                onDropDownSelection={onAutoCompleteChange}
              />
            </Fragment>
          }
        </Col>
        {/* <Col md={4}>
          {
            <CustomSelect
              enableValidation
              options={townList}
              className="custom-select"
              placeholder="Town"
              labelContent="Town"
              getOptionValue={({ id }) => id}
              getOptionLabel={({ title }) => title}
              onChange={event => onDropDownChange(event.id, 'townId', 'promotionAreas')}
              value={townList.filter(({ id }) => id === promotionAreas.townId)}
            />
          }
        </Col> */}
      </Row>
    </Fragment>
  );
};

PromotionArea.defaultProps = defaultProps;

PromotionArea.propTypes = propTypes;

export default PromotionArea;
