import { has } from '../../../../utils/objectPrototypes';
import { getDateList, addDaysInDate, subtractDaysInDate } from '../date';

const holidays = [
  {
    id: 12,
    date: '2020-01-22',
    title: 'NepaliKo Holiday',
  },
  {
    id: 12,
    date: '2020-01-22',
    title: 'India Ko Holiday',
  },

];


const holidayResolverPerDay = (holidayList) => {
  const holidayListEachDay = [];

  holidayList.forEach((holiday) => {
    const dateList = getDateList(holiday.startDate, holiday.endDate);
    dateList.forEach((date) => {
      holidayListEachDay.push({
        id: holiday.id,
        title: holiday.title,
        date,
      });
    });
  });

  return holidayListEachDay;
};

const leaveResolverPerDay = (leaveList) => {
  const leaveListEachDay = [];

  leaveList.forEach((leave) => {
    const dateList = leave.startDate === leave.endDate
      ? [leave.startDate] : getDateList(leave.startDate, leave.endDate);

    dateList.forEach((date) => {
      leaveListEachDay.push({
        id: leave.id,
        title: leave.title,
        status: leave.status,
        requestedBy: leave.requestedBy,
        leaveType: leave.leaveType,
        date,
      });
    });
  });

  return leaveListEachDay;
};


const eventCombiner = (routeList = [], holidayList = [], leaveList = []) => {
  const event = {};

  routeList.forEach((route) => {
    const { date } = route;

    if (has.call(event, date)) {
      event[date]['routeList'] = route.routeList;
    } else {
      event[date] = {};
      event[date]['routeList'] = route.routeList;
    }
  });

  holidayList.forEach((holiday) => {
    const { date } = holiday;

    if (has.call(event, date)) {
      event[date]['holiday'] = holiday;
    } else {
      event[date] = {};
      event[date]['holiday'] = holiday;
    }
  });

  leaveList.forEach((leave) => {
    const { date } = leave;

    if (has.call(event, date)) {
      event[date]['leave'] = leave;
    } else {
      event[date] = {};
      event[date]['leave'] = leave;
    }
  });

  return event;
};


const weekExtension = week => ({
  start: subtractDaysInDate(week.start, 7),
  end: addDaysInDate(week.end, 7),
});


export { holidayResolverPerDay, eventCombiner, leaveResolverPerDay, weekExtension };
