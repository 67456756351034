import gql from 'graphql-tag';

const CALLAGE_FRAGMENT = gql`
 fragment CallageFragment on userWiseCallageLine {
  id
  name
  isChildPresent
  role_id
  role
  scheduled
  successful
  unsuccessful
  remaining
  offroute
  productivity
  callage
  childUsers : ChildUsers {
    id
    name
    isChildPresent
    role_id
    role
    scheduled
    successful
    unsuccessful
    remaining
    offroute
    productivity
    callage
    childUsers : ChildUsers {
      id
      name
      isChildPresent
      role_id
      role
      scheduled
      successful
      unsuccessful
      remaining
      offroute
      productivity
      callage
      childUsers : ChildUsers {
             id
            name
            isChildPresent
            role_id
            role
            scheduled
            successful
            unsuccessful
            remaining
            offroute
            productivity
            callage
            childUsers : ChildUsers {
                id
                name
                isChildPresent
                role_id
                role
                scheduled
                successful
                unsuccessful
                remaining
                offroute
                productivity
                callage
                childUsers : ChildUsers {
                    id
                    name
                    isChildPresent
                    role_id
                    role
                    scheduled
                    successful
                    unsuccessful
                    remaining
                    offroute
                    productivity
                    callage
                }
            }
      }
    }
  }
}
`;

const GET_CALLAGE_LIST = gql`
query getUserWiseCallage($userId: Int, $roleId: Int, $filter: FilterInput) {
  getUserWiseCallage(userId: $userId, roleId:$roleId, filter: $filter){ 
      ...CallageFragment
  }
}
${CALLAGE_FRAGMENT}
`;

const query = {
  getCallage: GET_CALLAGE_LIST,
};

export { query };
