import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from '../../../../../components/Grid';
import { SKU_CATALOG_LEVEL } from '../../../../../data/enums';
import withErrorAndLoadingScreen from '../../../../../utils/composition/withLoading';
import { AutoComplete, Button, CustomSelect, Icon, Input } from '../../../../../components';
import PromotionAutoComplete from '../../../../../components/AutoComplete/AutoCompleteWithInput';
import {
  CUSTOM_SKU_GROUPS,
  PROMOTION_CRITERIA_DIMENSION,
  PROMOTION_CRITERIA_OPERATION,
} from '../config';
import {
  moqIdResolver,
  moqIdResolverForApplicableSKUs,
  skuGroupInputToCriteriaResolver,
  customGroupSKUmoqToCriteriaResolver,
} from './promotionCatalog.service';

const propTypes = {
  scope: PropTypes.instanceOf({
    catalogInfo: PropTypes.arrayOf(PropTypes.shape(
      {
        catalogId: PropTypes.number,
        catalogDetailIds: PropTypes.arrayOf(PropTypes.number),
      },
    )), // catalog_level: 1, list: [3,4,5]
  }).isRequired,
  onAutoCompleteChange: PropTypes.func.isRequired,
  onDropDownChange: PropTypes.func.isRequired,
  catalogList: PropTypes.instanceOf(Array),
  catalogChildrenList: PropTypes.instanceOf(Array),
  // catalogGroupChildrenList: PropTypes.instanceOf(Array),
  onIconClick: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  customGroupList: PropTypes.instanceOf(Array),
  onAddCatalogClick: PropTypes.func.isRequired,
  customGroupStatus: PropTypes.bool,
  onCustomGroupCrossClick: PropTypes.func,
};

const defaultProps = {
  catalogList: [],
  catalogChildrenList: [],
  // catalogGroupChildrenList: [],
  customGroupList: [],
  customGroupStatus: false,
  onCustomGroupCrossClick: PropTypes.func,
};

// eslint-disable-next-line consistent-return
const View = ({ ...props }) => {
  const {
    scope,
    catalogList,
    onIconClick,
    customGroupList,
    onDropDownChange,
    onAddCatalogClick,
    catalogChildrenList,
    // catalogGroupChildrenList,
    onAutoCompleteChange,
    customGroupStatus,
    onCustomGroupCrossClick,
    onInputChange,
  } = props;

  // eslint-disable-next-line max-len
  const handleScopeAutoCompleteChange = index => (value, fieldName) => onAutoCompleteChange(value, fieldName, index);

  const handleNormalSKUChange = (value, fieldName) => {
    const skuListWithCriteria = customGroupSKUmoqToCriteriaResolver(value);
    const baseIndex = 0;

    onAutoCompleteChange(skuListWithCriteria, fieldName, baseIndex);
  };

  const handleCustomGroupSKUChange = index => (value, fieldName) => {
    const skuListWithCriteria = customGroupSKUmoqToCriteriaResolver(value);

    onAutoCompleteChange(skuListWithCriteria, fieldName, index);
  };

  const handleCustomGroupChange = (event, index) => {
    const criteria = skuGroupInputToCriteriaResolver(event);

    onAutoCompleteChange(criteria, CUSTOM_SKU_GROUPS.HEADER, index);
  };

  return (
    <div className="scope catalog-wrap">
          <label>Catalog Type</label>
          <div className="mb-24">
            <label className="radio-list">
              <input
                type="radio"
                name="customGroupStatus"
                checked={!customGroupStatus}
                onChange={() => onInputChange({
                  target: {
                    name: 'customGroupStatus',
                    value: false,
                  },
                  formattedValue: false,
                })}
              />
              <span>Normal</span>
            </label>
            <label className="radio-list">
              <input
                type="radio"
                name="customGroupStatus"
                checked={customGroupStatus}
                onChange={() => onInputChange({
                  target: {
                    name: 'customGroupStatus',
                    value: true,
                  },
                  formattedValue: true,
                })}
              />
              <span>Group</span>
            </label>
          </div>
          <Row>
            <Col md={12}>
              {
                !customGroupStatus ? (
                  <Row>
                    <Col md={12} className="mt-24">
                      {
                        scope.catalogInfo.map((catScope, index) => (
                          <Row key={`cat-scope-${catScope}`}>
                            <Col>
                              {
                                index > 0 && (
                                  <div className="suggested-autocomplete-list">
                                    <span
                                      className="ic-close"
                                      onClick={() => onIconClick('remove', 'catalogInfo', index)}
                                    >
                                      Close
                                    </span>
                                  </div>
                                )
                              }
                            </Col>
                            <Col md={3}>
                              <CustomSelect
                                enableValidation
                                options={catalogList}
                                className="custom-select"
                                placeholder="Select Catalog"
                                labelContent="Product Catalog"
                                getOptionValue={({ id }) => id}
                                getOptionLabel={({ title }) => title}
                                onChange={event => onDropDownChange(event.id, index)}
                                value={catalogList.filter(({ id }) => id === catScope.catalogId)}
                              />
                            </Col>
                            <Col md={9}>
                              {
                                catScope.catalogId === SKU_CATALOG_LEVEL ? (
                                  <PromotionAutoComplete
                                    name="applicableSkus"
                                    labelContent="Catalog"
                                    placeholder="Search"
                                    dropDownList={catalogChildrenList}
                                    selectedDropDowns={moqIdResolverForApplicableSKUs(catScope.applicableSkus)}
                                    onDropDownSelection={handleNormalSKUChange}
                                  />
                                ) : (
                                  <div className="catalog">
                                    <AutoComplete
                                      name="catalogInfo"
                                      labelContent="Catalog"
                                      placeholder="Search"
                                      dropDownList={catalogChildrenList}
                                      selectedDropDowns={catScope.catalogDetailIds}
                                      onDropDownSelection={handleScopeAutoCompleteChange(index)}
                                    />
                                  </div>
                                )
                              }
                            </Col>
                          </Row>
                        ))
                      }
                    </Col>
                  </Row>
                ) : null
              }
              {
                (customGroupStatus) ? (
                  <Row>
                    <Col md={12} className="mt-24">
                      {
                        customGroupList.length > 0 ? customGroupList.map((customGroup, index) => {
                          return (
                            <Fragment>
                              <div className='config-panel-header'>{`Group ${index + 1}`}
                                <div className="flex m-0">
                                  <Icon
                                    iconName="times"
                                    primary
                                    onClick={() => onCustomGroupCrossClick(index)}
                                  />
                                </div>
                              </div>
                              <div className="config-catalog-wrap">
                                <Row>
                                  <Col md={3}>
                                    <CustomSelect
                                      disabled={true}
                                      enableValidation
                                      options={PROMOTION_CRITERIA_DIMENSION}
                                      className="custom-select"
                                      placeholder="Select Dimension"
                                      labelContent="Condition"
                                      getOptionValue={({ id }) => id}
                                      getOptionLabel={({ title }) => title}
                                      value={PROMOTION_CRITERIA_DIMENSION.filter(({ id }) => id === 1)}
                                    />
                                  </Col>
                                  <Col md={3}>
                                    <CustomSelect
                                      disabled={true}
                                      enableValidation
                                      options={PROMOTION_CRITERIA_OPERATION}
                                      labelContent="Criteria"
                                      className="custom-select"
                                      placeholder="Select Dimension"
                                      getOptionValue={({ id }) => id}
                                      getOptionLabel={({ title }) => title}
                                      enableErrorDisplay
                                      onChange={event => onDropDownChange(
                                        event.value, ['operation', 'max', 'operator'],
                                      )}
                                      value={PROMOTION_CRITERIA_OPERATION.filter(({ id }) => id === 2)}
                                    />
                                  </Col>
                                  <Col md={3}>
                                    <label>Value</label>
                                    <Input
                                      name="test"
                                      type="number"
                                      placeholder="Value"
                                      value={customGroup.criteria.operation.max.value}
                                      onChange={event => handleCustomGroupChange(event, index)}
                                    />
                                  </Col>
                                </Row>
                              </div>
                              <div className="config-catalog-wrap">
                                <Row className="mb-24">
                                  <Col md={3}>
                                    <CustomSelect
                                      disabled={true}
                                      enableValidation
                                      options={catalogList}
                                      className="custom-select"
                                      placeholder="Select Catalog"
                                      labelContent="Catalog Level"
                                      getOptionValue={({ id }) => id}
                                      getOptionLabel={({ title }) => title}
                                      // onChange={event => onDropDownChange(event.id, index, 'customGroupDetails')}
                                      value={catalogList.filter(({ id }) => id === SKU_CATALOG_LEVEL)}

                                    />
                                  </Col>
                                  <Col md={9}>
                                    <PromotionAutoComplete
                                      name={CUSTOM_SKU_GROUPS.SKU}
                                      fieldName={CUSTOM_SKU_GROUPS.SKU}
                                      labelContent="Catalog"
                                      dropDownList={catalogChildrenList}
                                      dropDownList1={catalogChildrenList}
                                      selectedDropDowns={moqIdResolver(customGroupList, index)}
                                      onDropDownSelection={handleCustomGroupSKUChange(index)}
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </Fragment>
                          );
                        }) : null
                      }
                      <div className="mt-24">
                        <Button iconBtnSmall secondary iconName="plus" onClick={() => onAddCatalogClick()} />
                      </div>
                    </Col>
                  </Row>
                ) : null
              }
            </Col>
          </Row>
    </div>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const PromotionDetailViewWithErrorAndLoading = withErrorAndLoadingScreen(View);

export default PromotionDetailViewWithErrorAndLoading;
