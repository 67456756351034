import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import SubDStockAvailabilityView from './View';
import { getUser } from '../../../data/services';
import PageHeader from '../../base/PageHeader';
import { isError } from '../../common/HelperFunctions';
import * as queryService from '../../base/query.service';
import { PanelStyled } from '../../common/configuration';
import { subdStockAvailability } from '../../common/DomainConfig';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { ADMIN_ROLE_GROUP, DISTRIBUTOR_ASSOCIATED_ROLES } from '../../../data/enums/UserRole';
import { getPermissionForStockLedger } from '../../base/permission';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';
import { breadCrumbConfig, filterConfig } from './config';
import { checkAndResetPaginatedData } from '../../../utils/paginatedListUtils';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  getDistributors: PropTypes.func.isRequired,
  getStockAvailabilityList: PropTypes.func.isRequired,
  downloadReport: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class SubDStockAvailability extends Component {
  constructor(props) {
    super(props);
    this.userInfo = getUser();
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      display: {
        searchBox: false,
        upload: true,
      },
      distributorList: [],
      distributorId: this.userInfo.Distributor.length > 0 ? this.userInfo.Distributor[0].id : 0,
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
      },
    };
    this.getData = () => {
      const { data } = this.state;
      return data;
    };
    this.setData = (data) => {
      this.setState({ data });
    };

    const { displayAlert, downloadReport } = props;
    this.permission = getPermissionForStockLedger();
    this.componentConfig = subdStockAvailability.componentsDisplay(this.permission);
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadReport,
      displayAlert,
    );
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount() {
    const { distributorId } = this.state;
    if (ADMIN_ROLE_GROUP.includes(this.userInfo.roleId)
      || DISTRIBUTOR_ASSOCIATED_ROLES.includes(this.userInfo.roleId)) {
      this.getDistributorsList();
    }
    if (distributorId) this.loadTableData();
  }

  getDistributorsList = () => {
    const { getDistributors, displayAlert } = this.props;
    getDistributors({ limit: 1000 }, {
      handleSuccess: (response) => {
        this.setState({ distributorList: response.data.distributors.rows });
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  };

  loadTableData = () => {
    const {
      data, queryParameters, distributorId,
    } = this.state;
    const { getStockAvailabilityList, displayAlert } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);

    getStockAvailabilityList({
      offset,
      limit: queryParameters.pagination.limit,
      filter: {
        filters: [
          ...apiFilterProcessor(queryParameters.filter),
          { column: 'distributor_id', value: [distributorId.toString() || ''] },
        ],
        queryString: queryParameters.search,
      },
    }, {
      handleSuccess: (response) => {
        data.list = response.data.getStockAvailabilityReport ? response.data.getStockAvailabilityReport.rows : [];
        data.total = response.data.getStockAvailabilityReport ? response.data.getStockAvailabilityReport.count : 1;
        if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
        this.setState(data);
      },
      handleError: (error) => {
        checkAndResetPaginatedData(data, this.setData);
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  };

  setQueryParameters = (queryParams, callBack = () => null) => this.setState(
    { queryParameters: queryParams },
    callBack,
  );

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleDistributorSelect = (distributorId) => {
    this.setState({ distributorId }, () => this.loadTableData());
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  render() {
    const {
      data, queryParameters, display, distributorList, distributorId,
    } = this.state;
    const { serverResponseWaiting } = this.props;

    return (
      <Fragment>
        <div className="section-header">
          <PanelStyled>
            <PageHeader
              display={display}
              breadCrumb={breadCrumbConfig}
              config={{
                title: subdStockAvailability.title,
                filter: distributorId && true,
                search: distributorId && true,
                download: distributorId && true,
              }}
              downloadConfig={{
                domain: subdStockAvailability.domain,
              }}
              filter={{
                // date: queryParameters.date,
                menuList: filterConfig.menu,
                onFilterChange: this.basePaginationService.handleFilterChange,
              }}
              queryParameters={queryParameters}
              controlDisplay={this.controlDisplay}
              resetFilter={this.basePaginationService.resetFilter}
              clearSearchText={this.basePaginationService.clearSearchText}
              handleSearchChange={this.basePaginationService.handleSearchInputChange}
              handleDownloadClick={this.basePaginationService.handleDownloadClick}
              handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
            />
          </PanelStyled>
        </div>
        <div className="section-content">
          <SubDStockAvailabilityView
            data={data}
            distributorId={distributorId}
            loading={serverResponseWaiting}
            componentDisplay={this.componentConfig}
            distributorList={distributorList}
            pagination={queryParameters.pagination}
            permission={this.permission}
            onDropDownChange={this.handleDistributorSelect}
            onPageChange={this.basePaginationService.onPageSelect}
          />
        </div>
      </Fragment>
    );
  }
}

SubDStockAvailability.propTypes = propTypes;

SubDStockAvailability.defaultProps = defaultProps;

export default withAlert()(SubDStockAvailability);
