import React, { Fragment } from 'react';
import {
  Tab, TabList, TabPanel, Tabs,
} from 'react-tabs';
import withErrorAndLoadingScreen from '../../../../utils/composition/withLoading';
import { PanelStyled } from '../../../common/configuration';
import withImageSlider from '../../../../utils/composition/withImageSlider';
import PageHeader from '../../../base/PageHeader';
import { title, breadCrumbConfig, filterConfig } from './config';
import { Row, Col } from '../../../../components/Grid';
import { Badge } from '../../../../components';
import { DOWNLOAD_DOMAIN } from '../../../../data/enums/GraphQL';
import Pending from './pending';
import Received from './received';
import Reconciled from './reconciled';
import Dishonored from './dishonored';

const View = ({ ...props }) => {
  const {
    activeTab,
    onTabChange,
    tableConfig,
    columnSelectChange,
    pending,
    received,
    reconcile,
    dishonore,
    queryParameters, handleFilterChange,
    basePaginationService,
    controlDisplay,
    handleSliderOpen,
    onButtonClick,
    handleDownloadClick,
    permission,
  } = props;

  return (
    <Fragment>
      <div className="section-header">
        <PanelStyled>
          <PageHeader
            breadCrumb={breadCrumbConfig}
            config={{
              title,
              create: false,
              download: true,
              filter: true,
              upload: false,
              date: true,
              search: true,
              columSelect: true,
            }}
            downloadConfig={{
              domain: DOWNLOAD_DOMAIN.RECEIPT,
            }}
            filter={{
              date: queryParameters.date,
              menuList: filterConfig.menu,
              onFilterChange: handleFilterChange,
            }}
            labelConfig={tableConfig}
            queryParameters={queryParameters}
            columnSelectChange={columnSelectChange}
            controlDisplay={controlDisplay}
            resetFilter={basePaginationService.resetFilter}
            clearSearchText={basePaginationService.clearSearchText}
            handleSearchChange={basePaginationService.handleSearchInputChange}
            handleDateRangeChange={basePaginationService.handleDateRangeChange}
            handleDownloadClick={handleDownloadClick}
          />
        </PanelStyled>
      </div>
      <div className="section-content section-tab cash-tab ">
        <Tabs selectedIndex={activeTab} onSelect={tabIndex => onTabChange(tabIndex)}>
          <TabList>
            <Tab>
              <span className="tab-label">Pending</span>
              <Badge light label={pending.total} />
            </Tab>
            <Tab>
              <span className="tab-label">Received</span>
              <Badge light label={received.total} />
            </Tab>
            <Tab>
              <span className="tab-label">Reconciled</span>
              <Badge light label={reconcile.total} />
            </Tab>
            <Tab>
              <span className="tab-label">Dishonored</span>
              <Badge light label={dishonore.total} />
            </Tab>
          </TabList>
          <div className="tab-container">
            <Row>
              <Col sm={12}>
                <TabPanel>
                  <section id="pending-tab">
                    <div id="detail-tab">
                      <div className="create-ui-inner tab-less-height" style={{ padding: '0' }}>
                        <Pending
                          tableConfig={tableConfig}
                          pending={pending}
                          pagination={queryParameters.pagination}
                          onPageChange={basePaginationService.onPageChange}
                          handleSliderOpen={handleSliderOpen}
                          fetchAllData={onButtonClick}
                          permission={permission}
                        />
                      </div>
                    </div>
                  </section>
                </TabPanel>
                <TabPanel>
                  <section id="received-tab">
                    <div id="detail-tab">
                      <div className="create-ui-inner tab-less-height" style={{ padding: '0' }}>
                        <Received
                          tableConfig={tableConfig}
                          received={received}
                          pagination={queryParameters.pagination}
                          onPageChange={basePaginationService.onPageChange}
                          handleSliderOpen={handleSliderOpen}
                          fetchAllData={onButtonClick}
                          permission={permission}
                        />
                      </div>
                    </div>
                  </section>
                </TabPanel>
                <TabPanel>
                  <section id="reconciled-tab">
                    <div id="detail-tab">
                      <div className="create-ui-inner tab-less-height" style={{ padding: '0' }}>
                        <Reconciled
                          tableConfig={tableConfig}
                          reconcile={reconcile}
                          pagination={queryParameters.pagination}
                          onPageChange={basePaginationService.onPageChange}
                          handleSliderOpen={handleSliderOpen}
                        />
                      </div>
                    </div>
                  </section>
                </TabPanel>
                <TabPanel>
                  <section id="dishonored-tab">
                    <div id="detail-tab">
                      <div className="create-ui-inner tab-less-height" style={{ padding: '0' }}>
                        <Dishonored
                          tableConfig={tableConfig}
                          dishonore={dishonore}
                          pagination={queryParameters.pagination}
                          onPageChange={basePaginationService.onPageChange}
                          handleSliderOpen={handleSliderOpen}
                        />
                      </div>
                    </div>
                  </section>
                </TabPanel>
              </Col>
            </Row>
          </div>
        </Tabs>
      </div>

    </Fragment>
  );
};

const PostDatedChequeViewWithErrorAndLoading = withErrorAndLoadingScreen(View);

const PostDatedChequeViewWithImageSlider = withImageSlider(PostDatedChequeViewWithErrorAndLoading);

export default PostDatedChequeViewWithImageSlider;
