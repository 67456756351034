import styled from 'styled-components';

const DeliverStyled = styled.div`

.table-wrap{
  height: calc(100vh - 95px - 124px);
  overflow:auto;
 table{
  tbody, thead{
  td,th{
    width:20%;
    &:nth-child(3),
      &:nth-last-child(2),
      &:nth-child(2),
    &:first-child{
      width:auto;
      white-space:nowrap;
    }
  
  }
  }
 
  }
}
  `;

export default DeliverStyled;
