import styled from 'styled-components';

const SpiltPayStyled = styled.div`

.modal-inner{
  max-width:452px;
  .evolve-dialog__body{
      .total-amount-section{
        display:flex;
        padding-bottom:24px;
        border-bottom:1px solid #E7E7ED;
        justify-content:space-between;
        .total{
          font-size:14px;
          line-height:21px;
          font-weight:500;
        }
        .amount-value{
          font-size:16px;
          line-height:21px;
          font-weight:bold;
        }
      }
      .form-wrap{
        margin:32px 0 8px 0;
        .form-input{
          display:flex;
          justify-content:space-between;
          flex-wrap:wrap;
          align-items:center;
          margin-bottom:24px;
          label{
            flex:1;
            line-height:16px;
            font-weight:500;
          }
          input{
            width:200px;
            margin-left:12px;
          }
          span{
            font-size:16px;
            flex:1;
            text-align:right;
            font-weight:normal;
          }
          &:last-child{
            margin-bottom:0;
          }
          .form-error {
            display:block;
            flex:auto;
          }
        }
      }
  }
}
`;
export default SpiltPayStyled;
