import {
  compose,
} from 'recompose';
import Town from './Catalog';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  query, mutation,
} from './API';

const ComposedCatalog = compose(
  withRequestTracker({
    query,
    mutation,
  }),
)(Town);

export default ComposedCatalog;
