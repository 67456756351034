import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { configuration as configurationRoute } from '../../../data/enums/Route';
import { MESSAGE_EVENT_OPERATION } from '../../../data/enums/SuccessMessage';

const breadCrumb = [
  configurationRoute,
];

const Title = 'BU';

const dateFormat = [{
  id: 'mm/dd/yy',
  title: 'MM/DD/YY',
},
{
  id: 'dd/mm/yy',
  title: 'DD/MM/YY',
},
{
  id: 'yy/mm/dd',
  title: 'YY/MM/DD',
}];

const dateType = [{
  id: 'NEPALI',
  title: 'BS',
},
{
  id: 'ENGLISH',
  title: 'AD',
}];

const responseToState = ({ ...response }) => {
  const {
    currency,
    fiscalYear,
    unitsOfMeasurement,
    dateSettings,
    orderForMultipleDistributors,
    targetDimension,
    geoFencing,
    pfd,
    multipleDseInRoute,
    payment,
    srn,
    orderStatus,
    targets,
    sbd,
    focusedSKU,
    skuAssortment,
  } = response;

  return {
    currency,
    fiscalYear,
    unitsOfMeasurement,
    dateSettings,
    orderForMultipleDistributors,
    targetDimension,
    geoFencing,
    pfd,
    multipleDseInRoute,
    payment,
    srn,
    skuAssortment,
    // show menu if either of one is configured.
    skuAssortmentMenu: skuAssortment.channel || skuAssortment.distributorGroup,
    orderStatus: {
      received: orderStatus ? orderStatus.received : false,
      invoiced: orderStatus ? orderStatus.invoiced : false,
      dispatched: orderStatus ? orderStatus.dispatched : false,
      delivered: orderStatus ? orderStatus.delivered : false,
    },
    targets: {
      salesTeamWise: targets ? targets.salesTeamWise : false,
      subDWise: targets ? targets.subDWise : false,
      brandWise: targets ? targets.brandWise : false,
    },
    sbd,
    focusedSKU,
  };
};

const targetDimension = ['VALUE', 'VOLUME'];

const formConfig = {
  mapper: element => ({
    id: element.id || '',
    title: element.title || '',
    territoryId: (element.Territory ? element.Territory.id : element.territoryId) || '',
  }),
  validationField: ['title', 'territoryId'],
  validationRequired: true,
};

const baseConfig = {
  primaryKey: 'id',
  updateKeys: ['title'],
};

const crudRequest = {
  [EVENT_OPERATION.CREATE]: {
    objectMapper: data => ({ title: data.title, territoryId: Number(data.territoryId) }),
    responseName: 'createTown',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.CREATE}`,
  },
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: data => ({
      id: data.id,
      title: data.title,
      territoryId: Number(data.territoryId),
    }),
    responseName: 'updateTown',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.UPDATE}`,
  },
  [EVENT_OPERATION.DELETE]: {
    objectMapper: data => ({
      id: data.id,
      title: data.title,
    }),
    responseName: 'deleteTown',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: data => ({
      id: data.id,
      title: data.title,
    }),
    responseName: 'updateTown',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
};

export {
  crudRequest, breadCrumb as breadCrumbConfig, formConfig, dateFormat, dateType, responseToState,
  targetDimension,
};
