import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  breadCrumbConfig,
  crudSuccess as crudRequestConfig,
  formattedLines,
  formMapper,
  title,
  totalPriceMapper,
} from './config';
import { PRN } from '../../../../data/enums/Route';
import { clone } from '../../../../utils/arrayProcessor';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import withAlert from '../../../../utils/composition/withAlert';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { handleFormSubmit } from '../../../../utils/crudResponseProcessor';
import CreatePrnView from './View';
import { summaryDetailsFormConfig } from './summaryDetails/config';
import { getUser } from '../../../../data/services';
import { USER_ROLE } from '../../../../data/enums';

const propTypes = {
  getSkus: PropTypes.func.isRequired,
  createPrn: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  getVendors: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  getDistributors: PropTypes.func.isRequired,
  getUpdatedGrnDetail: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class CreatePrn extends Component {
  getUserPermission = () => ({ PRN: { BILLING: true } })
    // todo fetch user permission here
  ;

  getVendorsList = () => {
    const { getVendors, displayAlert } = this.props;
    getVendors({}, {
      handleSuccess: (response) => {
        this.setState({ vendorList: response.data.vendors.rows });
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  };

  getDistributorsList = () => {
    const { getDistributors, displayAlert } = this.props;
    getDistributors({ limit: 1000 }, {
      handleSuccess: (response) => {
        this.setState({ distributorList: response.data.distributors.rows });
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  };

  getSKUs = () => {
    const { getSkus, displayAlert } = this.props;
    getSkus({ limit: 1000 }, {
      handleSuccess: (response) => {
        this.setState({ skus: response.data.skus.rows });
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  };

  getUpdatedValue = (field, value) => {
    this.setState({ [field]: value });
  };

  handleInvoiceNumberChange = (invoiceNumber, exactlyMatchedInvoiceNumber) => {
    const { data } = this.state;
    const { getUpdatedGrnDetail, displayAlert } = this.props;
    if (exactlyMatchedInvoiceNumber) {
      getUpdatedGrnDetail({ invoiceNumber }, {
        handleSuccess: (response) => {
          const invoiceDetail = response.data.findGRNInvoice || formMapper({}, this.distributorId);
          if (invoiceDetail.lines.length > 0) {
            invoiceDetail.lines = formattedLines(invoiceDetail.lines);
          }
          invoiceDetail.inputInvoiceNumber = invoiceNumber;
          this.setState({
            data: invoiceDetail,
            distributorId: invoiceDetail.distributorId,
            invoiceNumberStatus: exactlyMatchedInvoiceNumber,
          });
        },
        handleError: (error) => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      });
    } else if (data.lines) {
      data.lines = [];
      data.updatedAmount = totalPriceMapper({});
      data.amount = totalPriceMapper({});
      data.inputInvoiceNumber = invoiceNumber;
      this.setState({
        distributorId: this.distributorId,
        data,
        invoiceNumberStatus: exactlyMatchedInvoiceNumber,
      });
    }
  };

  onSubmit = () => {
    const valid = this.getValidationStatus();

    if (valid) {
      this.createPrn();
    } else {
      this.setState({ enableErrorDisplay: true });
    }
  };

  getValidationStatus = () => {
    const detailsStatus = this.getDetailsValidationStatus();
    const tableStatus = this.getTableValidationStatus();

    return (detailsStatus && tableStatus);
  };


  createPrn = () => {
    const { invoiceNumberStatus } = this.state;
    let updatedData = {};
    const tableData = this.getTableDetails();
    updatedData = { ...this.getDetails() };
    updatedData.lines = tableData.lines;
    updatedData.amount = tableData.amount;
    updatedData.oldBillStatus = !invoiceNumberStatus;
    updatedData.prnType = tableData.prnType;

    this.onFormSubmit(EVENT_OPERATION.CREATE, updatedData);
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
    console.log(error);
  };

  responseProcessor = () => {
    const onAPIRequestSuccess = type => () => {
      const { displayAlert } = this.props;
      displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message, this.directToMainPage);
    };
    return onAPIRequestSuccess;
  };

  directToMainPage = () => {
    /** direct to Srn page */
    const { history } = this.props;
    history.push(`/${PRN}`);
  };

  handlePrnCancel = () => {
    this.setState({ data: formMapper({}) });
  };

  constructor(props) {
    super(props);
    this.userInfo = getUser();
    this.subDUser = this.userInfo.roleId === USER_ROLE.DISTRIBUTOR_ADMIN;
    this.distributorId = this.userInfo.Distributor.length > 0 ? this.userInfo.Distributor[0].id : 0;
    this.state = {
      skus: [],
      data: formMapper({}, this.distributorId),
      distributorId: this.distributorId,
      vendorList: [],
      distributorList: [],
      enableErrorDisplay: false,
      invoiceNumberStatus: false,
      inputInvoiceNumber: '',
    };
    const serverCall = {
      [EVENT_OPERATION.CREATE]: props.createPrn,
    };
    this.onCRUDSuccess = this.responseProcessor();
    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.onAPIRequestFailure,
      crudRequestConfig, serverCall);
    this.permission = this.getUserPermission();
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount() {
    this.getSKUs();
    this.getVendorsList();
    if (!this.subDUser) this.getDistributorsList();
  }

  render() {
    const {
      data,
      skus,
      vendorList,
      distributorId,
      distributorList,
      enableErrorDisplay,
      invoiceNumberStatus,
    } = this.state;
    const {
      displayAlert,
      serverResponseWaiting,
    } = this.props;

    return (
      <CreatePrnView
        data={clone(data)}
        title={title}
        skus={skus}
        vendorList={vendorList}
        subDUser={this.subDUser}
        displayAlert={displayAlert}
        distributorId={distributorId}
        permission={this.permission}
        breadCrumb={breadCrumbConfig}
        handlePrnSubmit={this.onSubmit}
        distributorList={distributorList}
        handlePrnCancel={this.handlePrnCancel}
        enableErrorDisplay={enableErrorDisplay}
        invoiceNumberStatus={invoiceNumberStatus}
        detailsFormConfig={summaryDetailsFormConfig}
        loading={serverResponseWaiting}
        onInvoiceNumberChange={this.handleInvoiceNumberChange}
        handleValueUpdate={this.getUpdatedValue}
        getSummaryDetails={childMethod => this.getDetails = childMethod}
        getTableDetails={childMethod => this.getTableDetails = childMethod}
        getTableValidationStatus={childMethod => this.getTableValidationStatus = childMethod}
        getDetailsValidationStatus={childMethod => this.getDetailsValidationStatus = childMethod}
      />
    );
  }
}

CreatePrn.propTypes = propTypes;

CreatePrn.defaultProps = defaultProps;

export default withAlert()(CreatePrn);
