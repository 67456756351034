import gql from 'graphql-tag';

const USER_STATUS = gql`
  fragment UserStatusFragment on userStatusList {
    active
    inactive
    leave
  }
`;

const CALLAGE_FRAGMENT = gql`
  fragment CallageDetailFragment on callageDetail {
    scheduled
    successful
    unsuccessful
    remaining
    productivity
  }
`;

const MONTHLY_FRAGMENT = gql`
  fragment MonthlyFragment on monthlyDetail {
    target
    achievement
    coverage
    active
  }
`;

const LAST_SYNC_TIME = gql`
query getLastSyncTime{
    getLastSyncTime
}
`;
const DASHBOARD_INFO = gql`
  query getDashboardInfo(
    $userStatus: Boolean!
    $sales: Boolean!
    $callage: Boolean!
  ) {
    salesForceDashboard {
      user: User {
        id
        firstName
        lastName
      }
      monthlyDetail {
        ...MonthlyFragment
      }
      asm @include(if: $userStatus) {
        ...UserStatusFragment
      }
      stl @include(if: $userStatus) {
        ...UserStatusFragment
      }
      dse @include(if: $userStatus) {
        ...UserStatusFragment
      }
      callage: callageDetail @include(if: $callage) {
        ...CallageDetailFragment
        offroute
      }
      sales: salesDetail @include(if: $sales) {
        target
        achievement
        achievementpercent
        invoiced_amount
        dispatched_amount
        offroute_amount
      }
    }
  }
  ${USER_STATUS}
  ${CALLAGE_FRAGMENT}
  ${MONTHLY_FRAGMENT}
`;

const SUB_D_DASHBOARD_INFO = gql`
    query getSubDDashboardInfo {
        subdDashboard {
            user: User {
                id
                firstName
                lastName
            }
            dseDetails {
              rows {
                  id: user_id
                  name
                  profileImage{
                    image
                    thumbnail
                    created_at
                  }
                  route
                  outlet {
                      id
                      title
                      location {
                        latitude
                        longitude
                      }
                      type
                  }
                  scheduled
                  successful
                  unsuccessful
                  remaining
                  productivity
                  target
                  received
                  goldenpoint
                  active
                  profileImage{
                      image
                  }
                  status
              }
        total {
          scheduled
          successful
          unsuccessful
          remaining
          productivity
          target
          received
          goldenpoint
        }
      }
      deliveryDetails {
        delivered
        dispatched
        invoiced
        salesReturn: salesreturn
        trip
      }
      inventoryDetails {
        cfr
        currentStock
        availability
        sufficiency
      }
      targetDetails {
        target
        achievement
        coverage
        active
        sbd
      }
      displayGoldenPoint
    }
  }
`;

const query = {
    getLastSyncTime: LAST_SYNC_TIME,
    getDashboardInfo: DASHBOARD_INFO,
    getSubDDashboardInfo: SUB_D_DASHBOARD_INFO,
};
const mutation = {};

export { query, mutation };
