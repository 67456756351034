import {
  compose,
} from 'recompose';
import LeaveManagement from './LeaveManagement';
import withRequestTracker from '../../../utils/composition/withRequestTracker';

import {
  query,
  mutation,
  APPROVE_LEAVE_REQUEST,
} from './API';

const ComposedLeaveManagement = compose(
  APPROVE_LEAVE_REQUEST,
  withRequestTracker({
    query,
    mutation,
  }),
)(LeaveManagement);

export default ComposedLeaveManagement;
