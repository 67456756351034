import styled from 'styled-components';

const CashWraper = styled.div`
  .section-tab {
    height: 100%;
     border-top: 1px solid #E7E7ED;
    overflow: auto;
    &.r-detail-content {
      overflow: unset;
      margin-top: -9px;
    }
    &.no-border{
      border-top:0;
    }
    &.section-tab{
      border-top:0;
      height: calc(100vh - 118px);
      .react-tabs__tab-list{
        border-bottom:1px solid #E7E7ED;
      }
    }
     #pending-tab{
        .modal-wrapper{
      .modal-inner.change_status .evolve-dialog__body{
        b{
          text-transform: capitalize;
        }
      }
    }
}
  }

.alias-td{
  display:flex;
  &>div{
    border-radius:50%;
    width:32px;
    height:32px;
    line-height:27px;
    border:1px solid #E7E7ED;
    text-align:center;
    background-color:#FFFFFF;
    &.add{
      position:relative;
      z-index:1;
      cursor: pointer;
    }
    &.count{
      background-color:#4B9BFF;
      color:#FFFFFF;
      font-size:14px;
      line-height:29px;
      border:1px solid #4B9BFF;
      position:relative;
      left:-4px;
      cursor: pointer;
    }
  }
}

.table-wrap{
  overflow: auto;
 .active{
   background: #F0F5FF;
   td{
   border-bottom: 1px solid #0c5fff;
   }
 }   
}
`;

export default CashWraper;
