import gql from 'graphql-tag';
import { graphql } from 'react-apollo/graphql';

const BU_FRAGMENT = gql`
    fragment BUFragment on Settings {
        currency
        fiscalYear {
            start
            end
        }
        unitsOfMeasurement {
            title
            label
        }
        dateSettings {
            format
            calendar
        }
        orderForMultipleDistributors
        targetDimension
        geoFencing
        pfd
        multipleDseInRoute
        payment
        srn
        orderStatus {
            received
            invoiced
            dispatched
            delivered
        }
        targets {
            salesTeamWise
            subDWise
            brandWise
        }
        skuAssortment {
           channel
           distributorGroup 
        }
        sbd
        focusedSKU
    }
`;

const GET_BU_DATA = gql`
    query getBuData {
        settings: settings {
            ...BUFragment
        }
    }
    ${BU_FRAGMENT}
`;
const GET_SEQUENCE = gql`
    query callProcedureDetailList($type: callProcedureType!) {
        callProcedureDetails(type: $type) {
            rows {
                id,
                refId,
                sequence,
                CallProcedure {
                    id,
                    title
                }
                active
                type
            },
        }
    }
`;

const CREATE_BU = graphql(
  gql`
      mutation($input: SettingsInput!) {
          storeSettings(input: $input)
      }
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      createBU: variables => mutate({
        variables,
      }),
    }),
  },
);

const CREATE_CALL_PROCEDURE = graphql(
  gql`
      mutation($input: CallProcedureInput!) {
          createCallProcedure(input: $input){
              message
          }
      }
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      createCallProcedure: variables => mutate({
        variables,
      }),
    }),
  },
);

const UPDATE_BU = graphql(
  gql`
      mutation($input: SettingsInput!) {
          storeSettings(input: $input)
      }
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      updateBU: variables => mutate({
        variables,
      }),
    }),
  },
);

const query = {
  getBuData: GET_BU_DATA,
  callProcedureDetailList: GET_SEQUENCE,
};

const mutation = {
  createBU: CREATE_BU,
  updateBU: UPDATE_BU,
  createCallProcedure: CREATE_CALL_PROCEDURE,
};

export {
  BU_FRAGMENT, CREATE_BU, UPDATE_BU, GET_BU_DATA, CREATE_CALL_PROCEDURE,
};

export { query, mutation };
