export const EMAIL = 'Email is Required';
export const EMPTY = 'Must not be empty';
export const TEXT = 'Text input is Required';
export const GENERAL = 'This is general error';
export const INVALID = 'This input is not valid';
export const UNIQUE = 'Must be unique!';
export const ORDER_SELECTION_ERROR = {
  NO_ORDER_SELECTED: 'No order selected',
  NO_ORDER_AVAILABLE: 'No order available',
};
export const ERROR = '!Error';
export const GET_UNIQUE_ERROR = field => (`${field || 'This input'} should be unique!`);
export const DONT_MATCH = "Password don't match";
