import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import ImageUploadView from './View';
import withAlert from '../../../utils/composition/withAlert';
import {
  getImageObj, IMAGE_STATUS_MAPPER, title,
} from './config';
import EVENT_OPERATION from '../../../data/enums/EventOperation';

const propTypes = {
  domain: PropTypes.string,
};

const defaultProps = {
  domain: '',
};

class ImageUpload extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      image: getImageObj(),
      imageServerUrl: '',
      uploadProgress: 0,
    };
  }

  componentDidMount() {}

  handleImageSelection = (e) => {
    const { image } = this.state;
    const imageObj = { ...image };
    imageObj.file = e.target.files[0];
    imageObj.status = IMAGE_STATUS_MAPPER.PENDING;
    this.setState({ image: { ...image, ...imageObj } }, () => this.handleImageUpload());
  };

  monitorImageUploadProgress = (progressEvent) => {
    const uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);

    this.setState({ uploadProgress });
  }

  handleImageUpload = () => {
    const { image } = this.state;
    const options = {
      params: {
        Key: image.file.name,
        ContentType: image.file.type,
      },
      headers: {
        'Content-Type': image.file.type,
      },
      onUploadProgress: this.monitorImageUploadProgress,
    };

    this.getImageUploadUrl(options).then(async (url) => {
      let imageServerUrl = '';
      let status = IMAGE_STATUS_MAPPER.DANGER;
      if (url) {
        imageServerUrl = await this.uploadImageToImageServer(url, image.file, options);
        if (imageServerUrl) status = IMAGE_STATUS_MAPPER.SUCCESS;
      }
      this.setState({ imageServerUrl, image: { ...image, status } });
    });
  };

  uploadImageToImageServer = (imageUrl, imageFile, options) => axios
    .put(imageUrl, imageFile, options)
    .then(async (res) => {
      const url = await this.getImageUrl(options.params.Key);

      /** todo: replace url with response from server once server starts sending url * */
      const configuredUrl = url
        .split('?')
        .slice(0, -1)
        .join('?');

      return configuredUrl || '';
    })
    .catch((err) => {
      console.log(err);
      return '';
    });

  getImageUploadUrl = async (options) => {
    const { getStorageImageUrl, domain } = this.props;
    return new Promise((resolve, reject) => {
      getStorageImageUrl({
        key: options.params.Key,
        contentType: options.params.ContentType,
        domain,
      }, {
        handleSuccess: res => resolve(res.data.getStoragePutURL || ''),
        handleError: (err) => {
          console.log(err);
          resolve('');
        },
      });
    });
  }

  getImageUrl = async (key) => {
    const { getStorageUrl, domain } = this.props;

    return new Promise((resolve, reject) => {
      getStorageUrl({
        key,
        domain,
      }, {
        handleSuccess: (res) => {
          resolve(res.data.getStorageGetURL || '');
        },
        handleError: (err) => {
          resolve('');
        },
      });
    });
  }

  handleDialogSubmit = (e) => {
    const { onSubmit } = this.props;
    const { imageServerUrl } = this.state;
    onSubmit(EVENT_OPERATION.UPLOAD, imageServerUrl);
  };

  handleDialogCancel = (e) => {
    const { onClose } = this.props;
    onClose();
  };

  render() {
    const { image, uploadProgress, imageServerUrl } = this.state;
    const { serverResponseWaiting } = this.props;

    return (
      <ImageUploadView
        imageObj={image}
        title={title}
        loading={serverResponseWaiting}
        onImageSelection={this.handleImageSelection}
        onDialogSubmit={this.handleDialogSubmit}
        onDialogCancel={this.handleDialogCancel}
        uploadProgress={uploadProgress}
        imageServerUrl={imageServerUrl}
      />
    );
  }
}

ImageUpload.propTypes = propTypes;

ImageUpload.defaultProps = defaultProps;

export default withAlert()(ImageUpload);
