import React from 'react';
import PropTypes from 'prop-types';
import { menuAction } from '../../../utils/paginatedListUtils';
import { PERMISSION_OBJ } from '../../../data/enums/Permission';
import { normalPresentor } from '../../../utils/date';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import TableHeader from '../../base/TableHeader';

const labelMappings = {
  skuId: 'skuId',
  skuTitle: 'skuTitle',
  balance: 'balance',
  adjustment: 'adjustment',
  lastSynced: 'lastSynced',
  reason: 'reason',
  actionHolders: 'actionHolders',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.skuId,
    title: 'SKU ID',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 2,
    label: labelMappings.skuTitle,
    title: 'SKU',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 3,
    label: labelMappings.balance,
    classNames: 'text-right',
    title: 'Quantity in System',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 4,
    label: labelMappings.adjustment,
    classNames: 'text-right',
    title: 'Adjustment',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 5,
    label: labelMappings.lastSynced,
    classNames: 'text-center',
    title: 'Last Synced',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 6,
    label: labelMappings.reason,
    title: 'Reason',
    show: true,
    sortable: false,
    className: '',
  },
  {
    id: 7,
    label: labelMappings.actionHolders,
    title: '',
    show: true,
    sortable: false,
    className: '',
  },
];

const menuConfigList = [
  {
    title: 'Adjust',
    icon: 'pencil',
    permissionDerivedBy: 'update',
    type: EVENT_OPERATION.UPDATE,
  },
];

const TableHeaderConfig = ({ onHeaderClick }) => (
  <TableHeader
    headerDetails={labelConfig}
    onHeaderClick={onHeaderClick}
  />
);


const returnTableBody = (data, onIconClick, permission) => {
  const table = {};
  table[labelMappings.skuId] = <td key={`stockAdjustment-${data.skuId}-skuId`}>{data.sku && data.skuId || ''}</td>;
  table[labelMappings.skuTitle] = <td key={`stockAdjustment-${data.skuId}-title`}>{data.sku && data.sku.title || ''}</td>;
  table[labelMappings.balance] = <td key={`stockAdjustment-${data.skuId}-balance`} className="text-right">{data.balance}</td>;
  table[labelMappings.adjustment] = (
    <td key={`stockAdjustment-${data.skuId}-adjustment`} className="text-right">
      <span className={!data.negative
        ? data.lastAdjustment !== 0
          ? 'positive' : '' : 'negative'}
      >
        {data.lastAdjustment}
      </span>
    </td>
  );
  table[labelMappings.lastSynced] = <td key={`stockAdjustment-${data.skuId}-lastSynced`} className="text-center">{(normalPresentor(data.lastDate) === 'Invalid date') ? ('-') : (normalPresentor(data.lastDate))}</td>;
  table[labelMappings.reason] = <td key={`stockAdjustment-${data.skuId}-reason`}>{data.reason}</td>;
  table[labelMappings.actionHolders] = (
    permission.update && permission.create && (
    <td
      key={`stockAdjustment-${data.skuId}-actions`}
      className="text-right simple-popup-actions"
      onClick={e => e.stopPropagation()}
    >
      {menuAction(menuConfigList, { menuIcon: 'ellipsis-v' }, onIconClick, data, permission)}
    </td>
    )
  );

  return table;
};

const TableBody = ({
  data, onIconClick, permission,
}) => {
  // Expanding/Dragging and collapsing table body.
  // color based upon table body
  // tooltip if it is sorted.
  // active className
  const tableBody = returnTableBody(data, onIconClick, permission);
  return (
    <tr key={data.skuId}>
      {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
  onIconClick: PropTypes.func.isRequired,
  permission: PropTypes.instanceOf(Object),
};

TableHeaderConfig.propTypes = {
  onHeaderClick: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
  checkedList: [],
  permission: PERMISSION_OBJ,
};

export {
  TableHeaderConfig as TableHeader,
  TableBody,
};
