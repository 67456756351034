import {
  compose,
} from 'recompose';
import Sku from './SKU';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  query, mutation,
} from './API';
import { TOGGLE_STATE, UPLOAD } from '../../common/GqlAPI';

const ComposedSku = compose(
  TOGGLE_STATE,
  UPLOAD,
  withRequestTracker({
    query,
    mutation,
  }),
)(Sku);

export default ComposedSku;
