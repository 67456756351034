import { getFilterConfig } from '../../../utils/filter';
import { FILTER } from '../../../data/enums/filter';
import { configuration as configurationRoute } from '../../../data/enums/Route';

const breadCrumb = [
  configurationRoute,
];

const filter = getFilterConfig([FILTER.STATUS]);


export { breadCrumb as breadCrumbConfig, filter as filterConfig };
