import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FocusedSkuDetailView from './View';
import {
  CLIENT_STORAGE_TABLE,
  getDataFromLocalStorage,
} from '../../../../data/services';
import withAlert from '../../../../utils/composition/withAlert';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import FocusedSkuDialog from '../components/FocusedSkuDialog';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';

const propTypes = {
  getBasicDetail: PropTypes.func.isRequired,
  getSkuDetails: PropTypes.func.isRequired,
  getBrandDetails: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  insertFocusedSkuDetails: PropTypes.func.isRequired,
  history: PropTypes.objectOf(Object).isRequired,
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: {
        channelList: [],
        categoryList: [],
      },
      channelId: 0,
      categoryList: [],
      categoryId: 0,
      brandList: [],
      brandId: 0,
      skuList: [],
      skuId: 0,
      focusedSkuId: parseInt(props.match.params.id, 10),
      showBrands: false,
      showSkuList: false,
      skus: [],
      dialog: {
        type: '',
        element: '',
      },
      basicDetail: {},
      checkedSkus: [],
      focusedSKUDetails: {
        Channel: [],
        Category: [],
        Brand: [],
        skus: [],
      },
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount() {
    this.loadDataForDropDown();
    this.getBrandDetails();
    this.getBasicDetails();
    this.getSKUsCount();
  }

  loadDataForDropDown = () => {
    const { menu } = this.state;
    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.CHANNEL).then((response) => {
      menu.channelList = response;
      this.setState({ menu });
    });
    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.CATEGORY).then(
      (response) => {
        menu.categoryList = response;
        this.setState({ menu });
      },
    );
  };

  getBasicDetails = () => {
    const { getBasicDetail, match } = this.props;
    const { id } = match.params;

    getBasicDetail(
      {
        value: id,
      },
      {
        handleSuccess: (response) => {
          const { focusedSKUs } = response.data;
          this.setState({
            basicDetail: focusedSKUs.rows[0],
          });
        },
      },
    );
  };

  getSKUsCount = (data) => {
    const { getFocusSkuCount, match } = this.props;
    const { id } = match.params;

    getFocusSkuCount({
      focusedSkuId: parseInt(id, 10),
      ...data,
    }, {
      handleSuccess: (response) => {
        const { getFocusedSkuDetails } = response.data;
        this.setState({
          focusedSKUDetails: getFocusedSkuDetails,
        });
      },
    });
  };

  getBrandDetails = (id = 3) => {
    const { getBrandDetails, displayAlert } = this.props;

    getBrandDetails(
      {
        catalogId: id,
      },
      {
        handleSuccess: (response) => {
          this.setState({
            brandList: response.data.catalogDetails.rows,
          });
          displayAlert(ALERT_TYPE.SUCCESS, 'Fetched');
        },
      },
    );
  };

  onBrandSelect = (id) => {
    const { getSkuDetails } = this.props;
    this.setState({
      brandId: id,
      skuList: [],
    });

    this.getSKUsCount({
      channelId: this.state.channelId,
      categoryId: this.state.categoryId,
      brandId: id,
    });

    getSkuDetails(
      {
        value: id,
      },
      {
        handleSuccess: (response) => {
          const { catalogLevelDetails } = response.data;
          const catalogSkus = catalogLevelDetails.rows[0].SKUs;
          this.setState({
            skuList: catalogSkus,
            showSkuList: true,
            skus: [],
            checkedSkus: [],
          });
        },
      },
    );
  };

  onChannelSelect = (id) => {
    const { menu } = this.state;
    this.setState({
      categoryList: menu.categoryList.filter(d => d.Channel.id === id),
      channelId: id,
      skus: [],
      checkedSkus: [],
      categoryId: null,
      showBrands: false,
      skuList: [],
      focusedSKUDetails: {
        Channel: [],
        Category: [],
        Brand: [],
        skus: [],
      },
      brandId: 0,
    });
    this.getSKUsCount({
      channelId: id,
    });
  };

  onCategorySelect = (categoryId) => {
    const { channelId, brandId } = this.state;
    this.setState({
      showBrands: true,
      categoryId,
    });
    this.getSKUsCount({
      channelId,
      categoryId,
      brandId,
    });
  };

  handleSubmitSKU = (id, toggleStatus) => {
    const { insertFocusedSkuDetails, displayAlert, deleteFocusedSkuDetails } = this.props;
    const {
      focusedSkuId, channelId, categoryId, brandId, skus, focusedSKUDetails,
    } = this.state;
    const submittingData = {
      focusedSkuId,
      channelId,
      categoryId,
      brandId,
      skus: [id],
      status: toggleStatus,
    };
    if (toggleStatus) {
      skus.push(id);
      insertFocusedSkuDetails({
        input: submittingData,
      }, {
        handleSuccess: (response) => {
          this.getSKUsCount({
            ...submittingData,
          });
          displayAlert(ALERT_TYPE.INFO, 'Update Successfully');
        },
        handleError: (err) => {
          this.handleApiFailure(err);
        },
      });
    } else {
      insertFocusedSkuDetails({
        input: submittingData,
      }, {
        handleSuccess: (response) => {
          this.setState({
            skus: [],
          });
          this.getSKUsCount({
            ...submittingData,
          });
          displayAlert(ALERT_TYPE.INFO, 'Disabled Successfully');
        },
      });
    }
  };

  handleIconClick = (action) => {
    const { basicDetail } = this.state;
    this.setState({
      dialog: {
        type: action,
        element: basicDetail,
      },
    });
  };

  handleDialogClose = () => {
    this.setState({
      dialog: {
        type: '',
        element: {},
      },
    });
  };

  handleDialogSubmit = (type) => {
    const { history } = this.props;

    if (type === EVENT_OPERATION.REPLICATE || type === EVENT_OPERATION.DELETE) {
      history.push('/configuration/focusedSku');
    }
    this.getBasicDetails();
  };

  handleApiFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
    this.handleDialogClose();
  };

  setCheckedSkus = (sku) => {
    const { checkedSkus } = this.state;
    if (!checkedSkus.includes(sku)) {
      this.setState({
        checkedSkus: [...checkedSkus, sku],
      });
    } else {
      this.setState({
        checkedSkus: checkedSkus.filter(d => d !== sku),
      });
    }
  };

  render() {
    const {
      dialog,
    } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <>
        <FocusedSkuDetailView
          {...this.state}
          onIconClick={this.handleIconClick}
          onChannelSelect={this.onChannelSelect}
          loading={serverResponseWaiting}
          onCategorySelect={this.onCategorySelect}
          getSkuDetails={this.onBrandSelect}
          handleSubmit={this.handleSubmitSKU}
          setCheckedSkus={this.setCheckedSkus}
        />
        {
          dialog.type && (
            <div className="sbd-modal">
              <FocusedSkuDialog
                type={dialog.type}
                element={dialog.element}
                onSubmit={this.handleDialogSubmit}
                onClose={this.handleDialogClose}
                onApiFailure={this.handleApiFailure}
              />
            </div>
          )
        }
      </>
    );
  }
}

Details.propTypes = propTypes;

Details.defaultProps = defaultProps;

export default withAlert()(Details);
