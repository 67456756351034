import React from 'react';
import PropTypes from 'prop-types';
import {
  Form, Input, Col, Row,
} from '../../../components';
import withAlert from '../../../utils/composition/withAlert';
import withLoading from '../../../utils/composition/withLoading';
import { EMPTY, INVALID } from '../../../data/enums/ErrorMessage';

const propTypes = {
  handleInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  data: PropTypes.instanceOf(Object),
  refsObj: PropTypes.instanceOf(Object),
};

const defaultProps = {
  data: {},
  refsObj: {
    title: '',
  },
  enableErrorDisplay: true,
  /**
   * agruments:
   * first param: input event (required),
   * second param: data's first depth targeted key,
   * third param:
   *  only if object depth greater than one,
   *  should contain list of object keys in accordance of depth upto end i.e target key
   */
  handleInputChange: () => null,

};

const StockAdjustmentForm = ({ ...props }) => {
  const {
    data,
    refsObj,
    handleInputChange,
    enableErrorDisplay,
  } = props;

  return (
    <Form>
      <div className="scrollable-body-vertical">
        <Row>
          <Col className="mb-24" md={12}>
            <Input
              name="skuId"
              type="text"
              disabled
              labelContent="SKU"
              placeholder="SKU"
              enableValidation
              value={data.skuTitle}
              enableErrorDisplay={enableErrorDisplay}
              ref={ref => refsObj.skuId = ref}
              errorMessage={data.skuId ? INVALID : EMPTY}
            />
          </Col>
          <Col className="mb-24" md={12}>
            <Input
              name="balance"
              type="number"
              rule="isInt"
              labelContent="Quantity in system"
              enableValidation
              value={data.balance}
              disabled
            />
          </Col>
          <Col className="mb-24" md={12}>
            <Input
              name="quantity"
              type="number"
              rule="isInt"
              labelContent="Physical quantity"
              enableValidation
              value={data.quantity}
              argument={{
                min: 1,
                allow_leading_zeroes: false,
              }}
              enableErrorDisplay={enableErrorDisplay}
              onChange={event => handleInputChange(event)}
              errorMessage={data.returnQuantity ? INVALID : EMPTY}
            />
          </Col>
          <Col className="mb-24" md={12}>
            <Input
              name="reason"
              type="text"
              labelContent="Reason"
              placeholder="Reason"
              enableValidation
              value={data.reason}
              enableErrorDisplay={enableErrorDisplay}
              ref={ref => refsObj.reason = ref}
              onChange={event => handleInputChange(event)}
              errorMessage={data.reason ? INVALID : EMPTY}
            />
          </Col>
        </Row>
      </div>
    </Form>
  );
};

StockAdjustmentForm.propTypes = propTypes;

StockAdjustmentForm.defaultProps = defaultProps;

const StockAdjustmentFormWithErrorAndLoading = withLoading(StockAdjustmentForm);
const StockAdjustmentFormWithAlert = withAlert()(StockAdjustmentFormWithErrorAndLoading);

export default StockAdjustmentFormWithAlert;
