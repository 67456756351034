import styled from 'styled-components';

const CSVUploadStyled = styled.div`
   display:flex;
   flex-direction:column;
   justify-content:center;
   background-color:#F1F2F5;
   padding:64px 0;
   .svg,form{
       margin:auto;
       input:focus{
           outline:none;
       }
   }
   .svg img{
       height:59px;
       width:59px;
   }

   & + a {
       span.sample-csv{
        position:absolute;
       bottom:22px;
       left:30px;
       cursor:pointer;
           font-size:14px;
           line-height:21px;
           color:#0B5FFF;
           font-weight:600;
           svg{
                color:#0B5FFF;
                margin-right:6px;
                position:relative;
                top:-2px;
                height:14px;
                width:14px;
           }
       }
   }
`;

export default CSVUploadStyled;
