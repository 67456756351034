import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TableHeader, TableBody } from './tableConfig';
import BulkDelete from '../../../components/BulkDelete';
import Pagination from '../../../../components/Pagination';
import { STATEFUL_ENTITIES } from '../../../../data/enums/GraphQL';
import withImageSlider from '../../../../utils/composition/withImageSlider';
import { dataProps, paginationProps } from '../../../../data/enums/Component';
import withErrorAndLoadingScreen from '../../../../utils/composition/withLoading';

const propTypes = {
  data: dataProps.value,
  pagination: paginationProps.value,
  onPageChange: PropTypes.func,
  onCheckBoxClick: PropTypes.func,
  onIconClick: PropTypes.func.isRequired,
  onBulkDelete: PropTypes.func.isRequired,
};

const defaultProps = {
  onPageChange: () => null,
  onCheckBoxClick: () => null,
  data: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
};

const DetailView = ({ ...props }) => {
  const {
    data,
    onPageChange,
    pagination,
    onIconClick,
    onBulkDelete,
    permission,
    handleSliderOpen,
    onActionClick,

  } = props;

  return (
    <Fragment>
      <BulkDelete
        data={data.list}
        type={STATEFUL_ENTITIES.CATALOG_DETAILS}
        handler={onBulkDelete}
        render={(state, primaryCheckboxHandler, secondaryCheckboxHandler) => (
          <div className="table-wrap prn">
            <table>
              {
                TableHeader({
                  dataListLength: data.list.length,
                  checkedListLength: state.checkedList.length,
                  primaryCheckboxHandler,
                  permission,
                })
              }

              <tbody>
                {data.list.map(srn => (
                  TableBody({
                    data: srn,
                    checkedList: state.checkedList,
                    handleSliderOpen,
                    onIconClick,
                    secondaryCheckboxHandler,
                    permission,
                    onActionClick,
                  })
                ))}
              </tbody>
            </table>

          </div>
        )}
      />
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </Fragment>
  );
};

DetailView.propTypes = propTypes;

DetailView.defaultProps = defaultProps;

const DetailViewWithErrorAndLoading = withErrorAndLoadingScreen(DetailView);

const DetailViewWithImageSlider = withImageSlider(DetailViewWithErrorAndLoading);

export default DetailViewWithImageSlider;
