import React from 'react';
import PropTypes from 'prop-types';
import { tableData } from '../../../utils/paginatedListUtils';
import { fixedFloatAndCommas } from '../../../utils/conversion';

const labelMappings = {
  brand: 'brand',
  sku: 'sku',
  upc: 'upc',
  currentStock: 'currentStock',
  sit: 'sit',
  pso: 'pso',
  doh: 'doh',
  totalStock: 'totalStock',
  orderPcs: 'orderPcs',
  orderCases: 'orderCases',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.brand,
    title: 'Brand',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.sku,
    title: 'SKU',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.upc,
    title: 'UPC',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.currentStock,
    title: 'Current Stock',
    show: true,
    classNames: 'text-right',
  },
  {
    id: 5,
    label: labelMappings.sit,
    title: 'SIT (Stock In Transit)',
    show: true,
    classNames: 'text-right',
  }, {
    id: 6,
    label: labelMappings.pso,
    title: 'PSO (Pending Sales Orders)',
    show: true,
    classNames: 'text-right',
  },
  {
    id: 7,
    label: labelMappings.doh,
    title: 'DOH (Days Of Holding)',
    show: true,
    classNames: 'text-right',
  },
  {
    id: 8,
    label: labelMappings.totalStock,
    title: 'Total Stock',
    show: true,
    classNames: 'text-right',
  },
  {
    id: 9,
    label: labelMappings.orderPcs,
    title: 'Order pcs',
    show: true,
    classNames: 'text-right',
  },
  {
    id: 10,
    label: labelMappings.orderCases,
    title: 'Order Cases',
    show: true,
    classNames: 'text-right',
  },
];

const TableHeader = () => (
  <thead className="">
    <tr>
      {labelConfig.map(label => (label.show
        ? tableData(label.title, label.align, label.classNames) : null))}
    </tr>
  </thead>
);

const returnTableBody = (data) => {
  const table = {};

  table[labelMappings.id] = (
    <td key={`${data.id}-id`}>{data.id}</td>
  );
  table[labelMappings.brand] = (
    <td key={`${data.id}-brand`}>
      {data.brand}
    </td>
  );
  table[labelMappings.sku] = (
    <td key={`${data.id}-sku`}>
      {data.sku}
    </td>
  );
  table[labelMappings.upc] = (
    <td key={`${data.id}-upc`}>
      {fixedFloatAndCommas(data.upc)}
    </td>
  );
  table[labelMappings.currentStock] = (
    <td key={`${data.id}-currentStock`} className="text-right">
      {fixedFloatAndCommas(data.currentStock)}
    </td>
  );
  table[labelMappings.sit] = (
    <td key={`${data.id}-sit`} className="text-right">
      {fixedFloatAndCommas(data.sit)}
    </td>
  );
  table[labelMappings.pso] = (
    <td key={`${data.id}-pso`} className="text-right">
      {fixedFloatAndCommas(data.pso)}
    </td>
  );
  table[labelMappings.doh] = (
    <td key={`${data.id}-doh`} className="text-right">
      {fixedFloatAndCommas(data.doh)}
    </td>
  );
  table[labelMappings.totalStock] = (
    <td key={`${data.id}-totalStock`} lassName="text-right">
      {fixedFloatAndCommas(data.totalStock)}
    </td>
  );
  table[labelMappings.orderPcs] = (
    <td key={`${data.id}-orderPcs`} className="text-right">
      {fixedFloatAndCommas(data.orderPcs)}
    </td>
  );
  table[labelMappings.orderCases] = (
    <td key={`${data.id}-orderCases`} className="text-right">
      {fixedFloatAndCommas(data.orderCases)}
    </td>
  );

  return table;
};

const TableBody = ({
  data,
}) => {
  // Expanding/Dragging and collapsing table body.
  // color based upon table body
  // tooltip if it is sorted.
  // active className
  const tableBody = returnTableBody(data);

  return (

    <tr key={`userCluster-${data.id}`}>
      {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
};

TableBody.defaultProps = {
  data: {},
};


export {
  TableHeader,
  TableBody,
};
