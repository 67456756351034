import styled from 'styled-components';

const InvoiceStyled = styled.div`

.table-wrap{
 table{
  tbody, thead{
  td,th{
    width:19%;
    &:nth-last-child(2),
      &:nth-child(2),
    &:first-child{
      width:auto;
      white-space:nowrap;
    }
  }
  }
  }
}
  `;

export default InvoiceStyled;
