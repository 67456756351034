import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { discountOptions, totalViewRefs } from './config';
import { TableView, TotalView } from './View';
import { compareList } from '../../../../../../utils/arrayProcessor';
import { fixedFloat } from '../../../../../../utils/conversion';

const propTypes = {
  data: PropTypes.instanceOf(Array),
  enableErrorDisplay: PropTypes.bool,
};

const defaultProps = {
  data: [],
  enableErrorDisplay: false,
};

class Table extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  /* static getDerivedStateFromProps(nextProps, prevState) {
    const amountDetails = {
    ...prevState.amountDetails,
        billDiscount: nextProps.amountDetails.billDiscount || 0,
    };
    return {
      amountDetails
    }
  } */

  constructor(props) {
    super(props);
    this.state = {
      amountDetails: {
        total: 0,
        subTotal: 0,
        taxAmount: 0,
        tradeDiscount: 0,
        taxableAmount: 0,
        billDiscount: 0,
        tradeDiscountValue: 0,
        tradeDiscountParam: discountOptions[0].value,
      },
    };
  }

  componentDidMount() {
    const { getStatus, getDetails } = this.props;
    getStatus(this.getValidationStatus);
    getDetails(this.exportData);
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    if (!compareList(data, prevProps.data)) {
      this.calculateLineTotal();
    }
  }

  handleDiscountChange = (e) => {
    const { amountDetails } = this.state;
    amountDetails[e.target.name] = e.formattedValue;
    this.calculateTotal(amountDetails);
  };

  calculateTotal = (amountObj) => {
    const amountDetails = { ...amountObj };
    const calculatedTradeDiscount = (amountDetails.tradeDiscountParam === 'per'
      ? (amountDetails.subTotal - amountDetails.billDiscount) * (amountDetails.tradeDiscount / 100)
      : amountDetails.tradeDiscount);
    const taxableAmount = amountDetails.subTotal
      - amountDetails.billDiscount - calculatedTradeDiscount;
    const vat = 0.13 * taxableAmount;
    amountDetails.tradeDiscountValue = calculatedTradeDiscount;
    amountDetails.taxAmount = vat;
    amountDetails.taxableAmount = taxableAmount;
    amountDetails.total = taxableAmount + vat;
    this.setState({ amountDetails });
  };

  calculateLineTotal = () => {
    const { data } = this.props;
    const { amountDetails } = this.state;
    let subTotal = 0;
    let billDiscount = 0;
    data.map(item => subTotal += item.amountDetails.subTotal);
    data.map(item => billDiscount += item.amountDetails.billDiscount);
    amountDetails.subTotal = subTotal;
    amountDetails.billDiscount = fixedFloat(billDiscount || 0);
    this.calculateTotal(amountDetails);
  };

  getValidationStatus = () => (!Object.values(totalViewRefs)
    .find((item) => {
      if (typeof (item) !== 'string') return (item.getValidState() === false);
    }));

  exportData = () => {
    const { amountDetails } = this.state;
    return amountDetails;
  };

  onTradeParamChange = (e) => {
    const { amountDetails } = this.state;
    amountDetails.tradeDiscountParam = e.value;
    amountDetails.tradeDiscount = 0;
    this.setState({
      amountDetails,
    }, () => this.calculateTotal(amountDetails));
  };

  render() {
    const { data, enableErrorDisplay } = this.props;
    const { amountDetails } = this.state;
    return (
      <Fragment>
        <TableView
          data={data}
        />
        <TotalView
          refsObj={totalViewRefs}
          amountDetails={amountDetails}
          enableErrorDisplay={enableErrorDisplay}
          onInputChange={this.handleDiscountChange}
          onTradeParamChange={this.onTradeParamChange}
        />
      </Fragment>
    );
  }
}

Table.propTypes = propTypes;

Table.defaultProps = defaultProps;

export default Table;
