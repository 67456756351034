import {
  compose,
} from 'recompose';
import Login from './Login';
import {
  query, mutation, LoginAPI, ResetAPI,
} from './LoginAPI';
import withRequestTracker from '../../utils/composition/withRequestTracker';


const ComposedLogin = compose(ResetAPI, LoginAPI, withRequestTracker({
  query,
  mutation,
}))(Login);

export default ComposedLogin;
