import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { TOGGLE_STATE } from '../../../common/GqlAPI';

const USER_GROUP_FRAGMENT = gql`
  fragment UserGroupFragment on UserGroup {
    id
    name
    displayName
    parentGroupId  
  }
`;

const GET_GROUP_LIST = gql`
  query {
    userGroups {
      rows {
        ...UserGroupFragment
      }
    }
  }
  ${USER_GROUP_FRAGMENT}
`;

const USER_ROLE_FRAGMENT = gql`
  fragment UserRoleFragment on Role {
    id
    name
    displayName
    UserGroup {
      ...UserGroupFragment
    }
  }
  ${USER_GROUP_FRAGMENT}
`;

const GET_ROLE_LIST = gql`
  query getUserRoleList {
    roles {
     rows {
       ...UserRoleFragment
     }
     count
    }
  }
  ${USER_ROLE_FRAGMENT}
`;

const CREATE_ROLE = graphql(gql`
  mutation ($name: String!, $userGrpId: Int!, $displayName: String!) {
    createRole(
      input: {
        name: $name, userGroupId: $userGrpId, displayName: $displayName
      }
     )
    {
      ...UserRoleFragment
    }
  }
  ${USER_ROLE_FRAGMENT}
  `, {
  props: ({ mutate }) => ({
    createUserRole: variables => mutate({
      variables,
    }),
  }),
});

const UPDATE_ROLE = graphql(
  gql`
    mutation($id: Int!, $name: String!, $displayName: String!, $userGrpId: Int!) {
      updateRole(id: $id, input: {name: $name, displayName: $displayName, userGroupId: $userGrpId }) {
        ...UserRoleFragment,
        userGroupId
      }
    }
    ${USER_ROLE_FRAGMENT}
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      updateUserRole: variables => mutate({
        variables,
      }),
    }),
  },
);


const query = {
  getUserRoleList: GET_ROLE_LIST,
  getUserGroupList: GET_GROUP_LIST,
};

const mutation = {
  toggleState: TOGGLE_STATE,
  createUserRole: CREATE_ROLE,
  updateUserRole: UPDATE_ROLE,
};

export {
  CREATE_ROLE,
  UPDATE_ROLE,
  TOGGLE_STATE,
  GET_ROLE_LIST,
  USER_ROLE_FRAGMENT,
  USER_GROUP_FRAGMENT,
};

export { query, mutation };
