import React from 'react';
import { Link } from 'react-router-dom';
import { ImageColumn } from '../../../../utils/tableUtils';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import {
  menuAction,
  tableHeaderWithCheckBox,
} from '../../../../utils/paginatedListUtils';
import { CheckBox } from '../../../../components';

const labelMappings = {
  checkbox: 'checkbox',
  title: 'title',
  alias: 'alias',
  images: 'images',
  actionHolders: 'actionHolders',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.checkbox,
    title: '',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.title,
    title: 'Title',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.alias,
    title: 'Alias',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.images,
    title: 'Images',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.actionHolders,
    title: '',
    show: true,
  },
];

const menuConfigList = [
  {
    title: 'Delete',
    icon: 'trash',
    permissionDerivedBy: 'delete',
    type: EVENT_OPERATION.DELETE,
  },
];

const returnTableBody = (
  data,
  checkedList,
  handleSliderOpen,
  onIconClick,
  onActionClick,
  onSecondaryCheckBoxClick,
  permission
) => {
  const table = {};
  table[labelMappings.checkbox] = permission.delete && (
    <td onClick={(e) => e.stopPropagation()}>
      <CheckBox
        checked={checkedList.includes(data.id)}
        onCheckBoxClick={() => onSecondaryCheckBoxClick(data.id)}
      />
    </td>
  );
  table[labelMappings.title] = <td>{data.title}</td>;
  table[labelMappings.alias] = <td>{data.alias}</td>;
  table[labelMappings.images] = (
    <ImageColumn
      keyTitle={`rp-image-${data.id}`}
      imageList={data.images || []}
      onImageClick={handleSliderOpen}
    />
  );
  table[labelMappings.actionHolders] = (
    <td
      className='text-right simple-popup-actions'
      onClick={(e) => e.stopPropagation()}
    >
      {menuAction(
        menuConfigList,
        { menuIcon: 'ellipsis-v' },
        onActionClick,
        data,
        permission
      )}
    </td>
  );
  return table;
};

const TableHeader = ({
  dataListLength,
  checkedListLength,
  primaryCheckboxHandler,
  permission,
}) =>
  tableHeaderWithCheckBox(
    dataListLength,
    checkedListLength,
    primaryCheckboxHandler,
    permission,
    labelConfig,
    labelMappings
  );

const TableBody = ({
  data,
  handleSliderOpen,
  checkedList,
  onIconClick,
  secondaryCheckboxHandler,
  permission,
  onActionClick,
}) => {
  // Expanding/Dragging and collapsing table body.
  // color based upon table body
  // tooltip if it is sorted.
  // active className
  const tableBody = returnTableBody(
    data,
    checkedList,
    handleSliderOpen,
    onIconClick,
    onActionClick,
    secondaryCheckboxHandler,
    permission
  );
  return (
    <tr
      style={{ cursor: 'pointer' }}
      onClick={() => onIconClick(EVENT_OPERATION.DETAILS, data)}
    >
      <Link to={`${data.catalogId}/details/${data.id}`}>
        {labelConfig.map((label) =>
          label.show ? tableBody[label.label] : null
        )}
      </Link>
    </tr>
  );
};

export { TableHeader, TableBody, labelConfig };
