import React from 'react';
import PropTypes from 'prop-types';
import LoginStyled from './LoginStyled';
import { Button, Form, Input } from '../../components';
import withLoading from '../../utils/composition/withLoading';
import { EVENT_OPERATION } from '../../data/enums/EventOperation';

const propTypes = {
  form: PropTypes.shape({
    username: PropTypes.string,
    password: PropTypes.string,
  }),
  loginResponse: PropTypes.shape({
    invalid: PropTypes.bool,
    message: PropTypes.string,
  }),
  onSubmitButtonClick: PropTypes.func,
  handleInputChange: PropTypes.func,
  onIconClick: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
};

const defaultProps = {
  form: {
    username: '',
    password: '',
  },
  loginResponse: {
    invalid: false,
    message: '',
  },
  onSubmitButtonClick: null,
  handleInputChange: null,
  enableErrorDisplay: false,
  onIconClick: null,
};

const LoginView = ({ ...props }) => {
  const {
    form, onSubmitButtonClick, handleInputChange, enableErrorDisplay, loginResponse, onIconClick,
  } = props;
  return (
    <LoginStyled>
      <div className="login__wrapper">
        <div className="login__form">
          <div>
            <div className="login__logo">
              <img src="/image/rosia-logo.svg" alt="logo" />
            </div>
            <div className="login__body">

              <Form>
                {
                    enableErrorDisplay
                    && loginResponse.invalid
                    && (
                      <span className="error-text">
                        {loginResponse.message}
                      </span>
                    )
                  }
                <div className="login__form-content">
                  <Input
                    value={form.username}
                    name="username"
                    type="email"
                    placeholder="Email"
                    labelContent="Email"
                    enableErrorDisplay={enableErrorDisplay}
                    onChange={event => handleInputChange(event)}
                  />
                  <Input
                    value={form.password}
                    name="password"
                    type="password"
                    placeholder="Password"
                    labelContent="Password"
                    enableErrorDisplay={enableErrorDisplay}
                    onChange={event => handleInputChange(event)}
                  />
                </div>
                <div
                  className="forgot-password"
                  onClick={() => onIconClick(EVENT_OPERATION.CREATE, {})}
                >
Forget Password?

                </div>
                <Button primary onClick={event => onSubmitButtonClick(event)}>Sign In</Button>
              </Form>

            </div>
            <div className="copyright">
              <img src="/image/evolve-logo-dark.svg" alt="Evolve logo" />
              <p>© 2019 Evolve Pvt Ltd</p>
            </div>
          </div>
        </div>
        <div className="login__info">
          <div className="login__text">
            <img src="/image/login-illustration.svg" alt="Login Illustration" />
          </div>
        </div>
      </div>
    </LoginStyled>
  );
};

LoginView.propTypes = propTypes;

LoginView.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withLoading(LoginView);

export default LoginViewWithErrorAndLoading;
