import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import withLoading from '../../../../utils/composition/withLoading';
import { dataProps, paginationProps } from '../../../../data/enums/Component';
import { Pagination } from '../../../../components';
import BulkDelete from '../../../components/BulkDelete';
import { STATEFUL_ENTITIES } from '../../../../data/enums/GraphQL';
import { TableBody, TableHeader } from './tableConfig';

const propTypes = {
  data: dataProps.value,
  onPageChange: PropTypes.func,
  pagination: paginationProps.value,
  breadCrumb: PropTypes.instanceOf(Array),
  onIconClick: PropTypes.func.isRequired,
  onTableRowClick: PropTypes.func.isRequired,
  enableOutletCreation: PropTypes.bool,
};

const defaultProps = {
  data: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
  onPageChange: () => null,
  breadCrumb: [],
  enableOutletCreation: false,
};

const View = ({ ...props }) => {
  const {
    data,
    onPageChange,
    pagination,
    onTableRowClick,
    onBulkDelete,
    permission,
    onIconClick,
  } = props;

  return (
    <Fragment>
      <BulkDelete
        data={data.list}
        type={STATEFUL_ENTITIES.RETAIL_OUTLET}
        handler={onBulkDelete}
        render={(state, primaryCheckboxHandler, secondaryCheckboxHandler) => (
          <div className="table-wrap">
            <table>
              {
                  TableHeader({
                    dataListLength: data.list.length,
                    checkedListLength: state.checkedList.length,
                    primaryCheckboxHandler,
                    permission,
                  })
                }
              <tbody>
                {data.list.map(outlet => (
                  TableBody({
                    data: outlet,
                    checkedList: state.checkedList,
                    onIconClick,
                    onTableRowClick,
                    secondaryCheckboxHandler,
                    permission,
                  })
                ))}
              </tbody>
            </table>
          </div>
        )}
      />
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </Fragment>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const ViewWithLoading = withLoading(View);

export default ViewWithLoading;
