import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import { Input, Form } from '../../../../../../components';
import withLoading from '../../../../../../utils/composition/withLoading';

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  handleInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  refsObj: PropTypes.instanceOf(Object),
};

const defaultProps = {
  data: {
    name: '',
    description: '',
  },
  refsObj: {
    title: '',
  },
  enableErrorDisplay: true,
  handleInputChange: () => null,
};

const UpdateView = ({ ...props }) => {
  const {
    data,
    refsObj,
    enableErrorDisplay,
    handleInputChange,
  } = props;
  return (
    <Fragment>
      <Row>
        <Col md={6}>
          <Form>
            <Input
              name="quantity"
              type="number"
              rule="isInt"
              labelContent="Quantity"
              enableValidation
              value={data.quantity}
              enableErrorDisplay={enableErrorDisplay}
              argument={{
                min: 1,
                allow_leading_zeroes: false,
              }}
              ref={ref => refsObj.quantity = ref}
              onChange={event => handleInputChange(event)}
            />
          </Form>
        </Col>
      </Row>
    </Fragment>
  );
};

UpdateView.propTypes = propTypes;

UpdateView.defaultProps = defaultProps;

const ReceivedFormWithErrorAndLoading = withLoading(UpdateView);

export default ReceivedFormWithErrorAndLoading;
