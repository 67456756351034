import React from 'react';
import PropTypes from 'prop-types';
import { tableData } from '../../../../utils/paginatedListUtils';
import { Switch } from '../../../../components/index';
import { PERMISSION_OBJ } from '../../../../data/enums/Permission';

const labelMappings = {
  id: 'id',
  fullName: 'fullName',
  role: 'role',
  email: 'email',
  mobileNo: 'mobileNo',
  status: 'status',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.id,
    title: 'ID',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.fullName,
    title: 'Full Name',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.role,
    title: 'Role',
    show: true,
  }, {
    id: 4,
    label: labelMappings.email,
    title: 'Email',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.mobileNo,
    title: 'Mobile Number',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.status,
    title: 'Status',
    show: true,
  },
];

const TableHeader = () => (
  <thead className="">
    <tr>
      {labelConfig.map(label => (label.show
        ? tableData(label.title, label.align, label.classNames) : null))}
    </tr>
  </thead>
);

const returnTableBody = (data,
  permission, onTableDataClick, onToggleActiveStatus) => {
  const table = {};

  table[labelMappings.id] = (
    <td key={`${data.id}-id`} className={!data.active && 'disabled'}>
      {data.id}
    </td>
  );
  table[labelMappings.fullName] = (
    <td key={`${data.id}-fullName`} className={!data.active && 'disabled'}>
      {`${data.firstName} ${data.lastName}`}
    </td>
  );
  table[labelMappings.role] = (
    <td key={`${data.id}-role`} className={!data.active && 'disabled'}>
      {`${data.role ? data.role.name : ''}`}
    </td>
  );
  table[labelMappings.email] = (
    <td key={`${data.id}-email`} className={!data.active && 'disabled'}>
      {data.email}
    </td>
  );
  table[labelMappings.mobileNo] = (
    <td key={`${data.id}-mobileNo`} className={!data.active && 'disabled'}>{data.phoneNumber}</td>
  );
  table[labelMappings.status] = (
    <td key={`${data.id}-status`} onClick={onTableDataClick}>
      <Switch
        disabled={!permission.update}
        checked={data.active}
        onToggle={(status, e) => onToggleActiveStatus(status, e, data.id)}
      />
    </td>
  );

  return table;
};

const TableBody = ({
  data, onTableRowClick, permission, onTableDataClick, onToggleActiveStatus,
}) => {
  // Expanding/Dragging and collapsing table body.
  // color based upon table body
  // tooltip if it is sorted.
  // active className
  const tableBody = returnTableBody(data, permission, onTableDataClick, onToggleActiveStatus);

  return (

    <tr
      key={`user-${data.id}`}
      style={{ cursor: 'pointer' }}
      onClick={() => data.active && onTableRowClick(data.id)}
    >
      {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
  onTableRowClick: PropTypes.func.isRequired,
  permission: PropTypes.shape({
    read: PropTypes.bool,
    create: PropTypes.bool,
    update: PropTypes.bool,
    delete: PropTypes.bool,
  }),
  onToggleActiveStatus: PropTypes.func.isRequired,
  onTableDataClick: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
  permission: PERMISSION_OBJ,
};

export {
  TableHeader,
  TableBody,
};
