import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import ReportView from './View';
import PageHeader from '../../base/PageHeader';
import { isError, headerLabelConfig } from '../../common/HelperFunctions';
import { PanelStyled } from '../../common/configuration';
import * as queryService from '../../base/query.service';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { getOffsetFromPagination, apiFilterProcessor } from '../../../utils/api';
import {
  filterConfig,
} from './config';
import {
  breadCrumbConfig,
} from '../config';
import { USER_ROLE } from '../../../data/enums';
import { DSE_DETAIL, ATTENDANCE_HISTORY_MAP } from '../../../data/enums/Route';
import { USER_ROLE_TITLE } from '../../../data/enums/UserRole';
/* eslint-disable */
const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  getReport: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        date: { ...queryService.baseQueryParameters.singleDate },
      },
    };

    const { displayAlert } = props;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      displayAlert,
    );

    this.getData = () => {
      const { data } = this.state;
      return data;
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount() {
    this.loadTableData();
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  handleRowClick = (id) => {
    const { history } = this.props;
    const { queryParameters } = this.state;
    history.push(`/${DSE_DETAIL}/${id}`, { date: queryParameters.date });
  };

  loadTableData = () => {
    const {
      data, queryParameters,
    } = this.state;
    const { getAttendanceHistory } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);

    getAttendanceHistory({
      offset,
      limit: queryParameters.pagination.limit,
      filter: {
        filters: apiFilterProcessor(queryParameters.filter),
        queryString: queryParameters.search,
        dateRange: queryParameters.date,
      }
    },
    {
      handleSuccess: (response) => {
          data.list = (response.data.listAttendanceHistory && response.data.listAttendanceHistory.rows) || [];
          data.total = (response.data.listAttendanceHistory && response.data.listAttendanceHistory.count) || 0;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
      },
      handleError: (error) => {
        this.onAPIRequestFailure(error)
      }
    }
    )
  };

  setQueryParameters = (queryParams, callBack = () => null) => this.setState(
    { queryParameters: queryParams },
    callBack,
  );

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  onMarkerClick = (id, location) => {
    const { history } = this.props;
    const { queryParameters } = this.state;
    history.push(`/${ATTENDANCE_HISTORY_MAP}/${id}`, { center: location, queryParameters });
  }

  render() {
    const {
      data, queryParameters, display,
    } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <Fragment>
        <div className="section-header">
          <PanelStyled>
            <PageHeader
              display={display}
              breadCrumb={breadCrumbConfig}
              config={{
                title: headerLabelConfig[USER_ROLE_TITLE.DSE],
                create: false,
                download: false,
                filter: true,
                search: true,
                date: false,
                singleDate: true,
              }}
              filter={{
                date: queryParameters.date,
                menuList: filterConfig.menu,
                onFilterChange: this.basePaginationService.handleFilterChange,
              }}
              queryParameters={queryParameters}
              controlDisplay={this.controlDisplay}
              handleSingleDateRangeChange={this.basePaginationService.handleSingleDateRangeChange}
              handleSearchChange={this.basePaginationService.handleSearchInputChange}
            />
          </PanelStyled>
        </div>
        <div className="section-content">
          <ReportView
            data={data}
            pagination={queryParameters.pagination}
            loading={serverResponseWaiting}
            onPageChange={this.basePaginationService.onPageSelect}
            handleRowClick={this.handleRowClick}
            onMarkerClick={this.onMarkerClick}
          />
        </div>
      </Fragment>
    );
  }
}

Report.propTypes = propTypes;

Report.defaultProps = defaultProps;

export default withAlert()(Report);
