import React, { Fragment } from 'react';
import { detailsLabelConfig, DetailTableBody } from './tableConfig';
import { tableData } from '../../../../../utils/paginatedListUtils';
import { Button } from '../../../../../components';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { OrderFulFilmentContext } from '../../config';

const childView = (
  {
    data,
    loading,
    replacedId,
    handleSliderOpen,
    onOrderStatusChangeClick,
  },
) => (
  <Fragment>
    <tr>
      {
          detailsLabelConfig.map(
            label => (
              !label.action
                ? tableData(label.title, label.align, label.classNames)
                : (
                  <th />
                )

            ),
          )
        }
    </tr>
    <OrderFulFilmentContext.Consumer>
      {
          ({ permission }) => (
            <Fragment>
              <Fragment>
                {
                  data.map((order, index) => (
                    <tr>
                      {DetailTableBody({
                        index,
                        data: order,
                        handleSliderOpen,
                      })}
                    </tr>
                  ))
                }
              </Fragment>

              {
                permission.update && (
                  <tr>
                    <td colSpan="75%">
                      <div className="text-right">
                        <Button
                          small
                          primary
                          disabled={loading}
                          onClick={() => onOrderStatusChangeClick(
                            EVENT_OPERATION.DISPATCHED, replacedId,
                          )}
                        >
                          <span>Dispatch</span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                )
              }
            </Fragment>
          )
        }
    </OrderFulFilmentContext.Consumer>
  </Fragment>

);

export default childView;
