import { FORM_CONFIG } from '../../../data/enums/config';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { refGenerator } from '../../../utils';
import { fileParser } from '../../../utils/parser';
import { MESSAGE_EVENT_OPERATION } from '../../../data/enums/SuccessMessage';
import { openingBalance } from '../../common/DomainConfig';
import { UPLOADABLES } from '../../../data/enums/GraphQL';
import { inventory as inventoryRoute } from '../../../data/enums/Route';
import { getFilterConfig } from '../../../utils/filter';
import { FILTER } from '../../../data/enums/filter';

const breadCrumb = [
  inventoryRoute,
];

const formConfig = {
  [FORM_CONFIG.MAPPER]: element => ({
    distributorId: element.distributorId || '',
    file: element.file || {},
    name: element.name || '',
  }),
  [FORM_CONFIG.TITLE]: 'File Upload',
  [FORM_CONFIG.REFS_OBJ]: refGenerator(['distributorId', 'file']), /** need to add salesInvoiceNumber */
  [FORM_CONFIG.VALIDATION_REQUIRED]: true,
};

const crudRequestConfig = {
  [EVENT_OPERATION.UPLOAD]: {
    objectMapper: async (data) => {
      const fileData = await fileParser(data.file)
        .then(file => ({
          input: {
            file,
            type: UPLOADABLES.OPENING_BALANCE,
            references: [{ type: 'distributor_id', id: data.distributorId }],
          },
        }));

      return fileData;
    },
    responseName: 'importCSV',
    message: `${openingBalance.title} ${MESSAGE_EVENT_OPERATION.UPLOAD}`,
  },
};

const filter = getFilterConfig([FILTER.SKU, FILTER.BRAND, FILTER.SUB_BRAND]);


export {
  formConfig, crudRequestConfig, breadCrumb as breadCrumbConfig, filter as filterConfig,
};
