import React, { useEffect, useState, Fragment } from 'react';
import Input from '../../../components/Input/Text';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { DialogFormWrapper } from '../../common';
import { formConfig } from './config';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import SpiltPayStyled from './SpiltPayStyled';
import { INVALID } from '../../../data/enums/ErrorMessage';

const SplitPayment = ({
  getLedgers, data, type, resetDialog, ...props
}) => {
  const [ledgers, setLedgers] = useState([]);
  const [splitDetails, setSplitDetails] = useState([]);

  const newSplitDetails = [...splitDetails];

  const onInputChange = (e, index, id) => {
    newSplitDetails[index] = {
      ...newSplitDetails[index],
      ledgerId: id,
      amount: parseFloat(e.target.value),
    };
    setSplitDetails(
      newSplitDetails,
    );
  };

  useEffect(() => {
    if (type === EVENT_OPERATION.CREATE) {
      getLedgers({
        offset: 0,
        limit: 100,
        filter: {
          filters: [{
            column: 'retail_outlet_id',
            value: [data.outlet.id.toString()],
          }],
        },
      }, {
        handleSuccess: (response) => {
          setLedgers(
            response.data.customers.rows || [],
          );
        },
      });
    }
  }, []);

  const onDialogSubmit = () => {
    const { splitPayment, fetchAllData, getStatus } = props;
    if (type === EVENT_OPERATION.CREATE && splitDetails.length > 0) {
      splitPayment({
        id: data.id,
        input: splitDetails,
      }, {
        handleSuccess: (response) => {
          getStatus('Ledger splitted successfully', '');
          fetchAllData();
        },
        handleError: (err) => {
          getStatus('Split amount mismatch', 'err');
        },
      });
    } else {
      resetDialog();
      getStatus('Ledger amount input needed', 'err');
    }
  };
  return (
    <SpiltPayStyled>
      <div>
        {type && (
        <DialogFormWrapper
          formConfig={formConfig[type]}
          onDialogSubmit={() => onDialogSubmit()}
          onDialogCancel={resetDialog}
          type={type}
          renderDialog={() => (
            <Fragment>
              {
                <>
                  <div className="total-amount-section">
                    <div className="total">Total Amount</div>
                    <div className="amount-value">{fixedFloatAndCommas(data.amount)}</div>
                  </div>
                  { type === EVENT_OPERATION.CREATE ? (
                    <div className="form-wrap">
                      {ledgers.map((d, index) => (
                        <Input
                          type="number"
                          key={d.customerId}
                          labelContent={d.title}
                          rule="isFloat"
                          enableValidation
                          argument={{
                            max: splitDetails[index] && (splitDetails[index].amount < data.amount),
                            allow_leading_zeroes: false,
                          }}
                          enableErrorDisplay={splitDetails[index] && (splitDetails[index].amount > data.amount)}
                          errorMessage={splitDetails[index] && (splitDetails[index].amount > data.amount) ? INVALID : ''}
                          value={splitDetails[index] ? splitDetails[index].amount : ''}
                          placeholder="NRS."
                          onChange={e => onInputChange(e, index, d.customerId)}
                        />
                      ))}
                    </div>) : (
                      <div className="form-wrap">
                        {data.paymentDetails.map(d => (
                          <div key={d.id} className="form-input ">
                            <label>{d.ledger.title}</label>
                            <span>{fixedFloatAndCommas(d.amount)}</span>
                          </div>
                        ))}
                      </div>
                  )}
              </>
             }
            </Fragment>
          )}
        />
        )}
      </div>
    </SpiltPayStyled>
  );
};

export default SplitPayment;
