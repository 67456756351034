import React, { Component, Fragment } from 'react';
import { PropTypes } from 'prop-types';
import { Button, MonthPicker } from '../../../../components';
import { getDateFromYearAndMonth } from '../date';

const propTypes = {
  onDialogClose: PropTypes.func,
  onDialogSubmit: PropTypes.func,
};

const defaultProps = {
  onDialogClose: () => null,
  onDialogSubmit: () => null,
};

class ReplicationDialog extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);

    this.state = {
      sourceDate: {
        year: 2020,
        month: 1,
      },
      destinationDate: {
        year: 2020,
        month: 2,
      },
    };
  }

  componentDidMount() {}

  handleButtonCancel = () => {
    const { onDialogClose } = this.props;
    onDialogClose();
  };

  handleButtonSubmit = () => {
    // sourceDate and destinationDate.
    const { sourceDate, destinationDate } = this.state;

    const { onDialogSubmit } = this.props;

    const derivedSourceDate = getDateFromYearAndMonth(sourceDate.year, sourceDate.month);
    const derivedDestinationDate = getDateFromYearAndMonth(destinationDate.year, destinationDate.month);

    onDialogSubmit(derivedSourceDate, derivedDestinationDate);
  };

  handleSourceDateSelection = (date) => {
    const { sourceDate } = this.state;
    sourceDate.year = date.year;
    sourceDate.month = date.month;
    this.setState({ sourceDate });
  };

  handleDestinationDateSelection = (date) => {
    const { destinationDate } = this.state;
    destinationDate.year = date.year;
    destinationDate.month = date.month;
    this.setState({ destinationDate });
  };


  render() {
    const {
      sourceDate,
      destinationDate,
    } = this.state;

    return (
      <Fragment>
        <div className="modal-inner">
          <div className="header">
            <h2>Replicate Call Roster</h2>
          </div>
          <div className="body">
            <h3>Please choose which month do you want to replicate from one to another</h3>
            <div className="mb-24">
              <label htmlFor="">From</label>
              <MonthPicker value={sourceDate} onSelect={this.handleSourceDateSelection} />
            </div>
            <div>
              <label>To</label>
              <MonthPicker value={destinationDate} onSelect={this.handleDestinationDateSelection} />
            </div>
          </div>
          <div className="modal-button">
            <Button secondary title="Cancel" onClick={() => this.handleButtonCancel()} />
            <Button primary title="Ok" onClick={() => this.handleButtonSubmit()} />
          </div>
        </div>
        <div className="overlay"></div>
      </Fragment>
    );
  }
}

ReplicationDialog.propTypes = propTypes;

ReplicationDialog.defaultProps = defaultProps;

export default ReplicationDialog;
