import React, { Fragment } from 'react';
import {
  BreadCrumb, Button, Badge,
} from '../../../../components';
import { PanelHeader, PanelStyled } from '../../../common/configuration';
import { breadCrumb } from './config';
import CustomSelect from '../../../../components/CustomSelect';
import withErrorAndLoadingScreen
  from '../../../../utils/composition/withLoading';
import SBDDetailStyled from '../../sbd/details/SBDDetailStyled';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import BasicDetail from './BasicDetail';
import FocusedSkuDistribution from './FocusedSkuDistribution';

const FocusedSkuDetailView = (props) => {
  const {
    onIconClick, menu, onChannelSelect, channelId,
    categoryList, brandList, showBrands, onCategorySelect,
    skuList, getSkuDetails,
    categoryId, brandId, handleSubmit, skus,
    basicDetail, setCheckedSkus, checkedSkus,
    focusedSKUDetails, loading,
  } = props;

  const getCounts = (param, paramId) => paramId !== 0 && focusedSKUDetails[param];

  const channelCount = getCounts('Channel', channelId)
    && getCounts('Channel', channelId).find(d => d.id === channelId)
    && getCounts('Channel', channelId).find(d => d.id === channelId).count;
  const categoryCount = getCounts('Category', channelId);
  const brandCount = getCounts('Brand', channelId);

  const getStatus = id => focusedSKUDetails && focusedSKUDetails.length !== 0
    && focusedSKUDetails.skus.filter(d => d === id)
    && focusedSKUDetails.skus
      .filter(d => d === id).includes(id);

  return (
    <Fragment>
        <div className="section-header">
          <PanelStyled>
            <BreadCrumb list={breadCrumb} />
            <PanelHeader>
              <div className="flex items-center justify-between m-0 flex-1">
                <h2>{basicDetail.title}</h2>
                <div className="flex m-0">
                  <Button
                    secondary
                    iconBtnSmall
                    onClick={() => onIconClick(EVENT_OPERATION.UPDATE)}
                    iconName="pencil"
                  />
                  <Button
                    secondary
                    iconBtnSmall
                    onClick={() => onIconClick(EVENT_OPERATION.DELETE)}
                    iconName="trash"
                  />
                  {(skus.length !== 0 || channelCount !== 0) && (
                    <Button
                      secondary
                      iconBtnSmall
                      onClick={() => onIconClick(EVENT_OPERATION.REPLICATE)}
                      iconName="copy"
                    />
                  )}
                </div>
              </div>
            </PanelHeader>
          </PanelStyled>
        </div>
        <div className="section-content">
          <div className="create-ui">
            <div className="create-ui-inner tab-less-height">
              <SBDDetailStyled>
                <BasicDetail basicDetail={basicDetail} />
                <section className="sbd-bottom-wrap">
                  <div className="first">
                    <CustomSelect
                      small
                      options={menu.channelList}
                      clearable={false}
                      className="custom-select"
                      placeholder="Select Channel"
                      getOptionLabel={({ id, title }) => (
                        <span>
                          {title}
                          {focusedSKUDetails.Channel
                          && focusedSKUDetails.Channel.filter(d => d.id === id)[0] && (
                            <Badge
                              light
                              label={focusedSKUDetails.Channel.filter(d => d.id === id)[0].count}
                            />
                          )}
                        </span>
                      )}
                      getOptionValue={({ id }) => id}
                      value={channelId !== 0
                        ? menu.channelList.filter(d => d.id === channelId) : []}
                      onChange={event => onChannelSelect(event.id)}
                      isSearchable={false}
                    />
                    {channelId !== 0 && channelCount !== 0 && <Badge light label={channelCount || 0} />}
                    {categoryList.length === 0
                    && <span className="info-text">←← Please select any channel to continue.</span>}
                  </div>
                  <div className="sbd-bottom-inner">
                    <div className="sbd-panel">
                      {categoryList.length !== 0 && (
                        <div className="sbd-panel-list">
                          <h3>Category</h3>
                          <ul>
                            {categoryList.map(d => (
                              <li
                                key={d.id}
                                onClick={() => onCategorySelect(d.id)}
                                className={d.id === categoryId ? 'active' : 'inActive'}
                              >
                                {d.title}
                                {' '}
                                {categoryCount && categoryCount.find(catId => catId.id === d.id)
                                && (
                                  <Badge
                                    light
                                    label={categoryCount.find(cat => cat.id === d.id)
                                      ? categoryCount.find(cat => cat.id === d.id).count : 0}
                                  />
                                )}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                      {categoryList.length !== 0 && !showBrands
                      && <span className="info-text">←← Please select any category to continue.</span>}
                    </div>
                    <div className="sbd-panel">
                      {showBrands && (
                        <div className="sbd-panel-list">
                          <h3>Brands</h3>
                          <ul>
                            {brandList.map(d => (
                              <li
                                key={d.id}
                                onClick={() => getSkuDetails(d.id)}
                                className={`${d.id === brandId ? 'active' : 'inActive'} ${loading ? 'disabled-btn' : ''}`}
                              >
                                {d.title}
                                {brandCount && brandCount.find(brandId => brandId.id === d.id)
                                && (
                                  <Badge
                                    light
                                    label={brandCount.find(brandId => brandId.id === d.id).count || 0}
                                  />
                                )}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                      {categoryList.length !== 0 && showBrands && skuList.length === 0 && (
                        <span className="info-text">←← Please select brand to continue</span>)}
                    </div>
                    {skuList.length !== 0
                    && (
                      <FocusedSkuDistribution
                        skuList={skuList}
                        checkedSkus={checkedSkus}
                        setCheckedSkus={setCheckedSkus}
                        getStatus={getStatus}
                        skus={skus}
                        handleSubmit={handleSubmit}
                      />)}
                  </div>
                </section>
              </SBDDetailStyled>
            </div>
          </div>
        </div>
    </Fragment>
  );
};

export default withErrorAndLoadingScreen(FocusedSkuDetailView);
