
import styled from 'styled-components';

const SKUStyled = styled.div`
 .table-wrap.sku {
    tr {
      cursor: pointer;
    }
  }

  /* create sku styling start */
  .config-view-wrap {
    background:rgba(231, 231, 237, 0.2);
    padding: 40px 48px;
    .title {
      margin: 21px 0 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    input {
      background: #F1F2F5;
    }

    .config-inner {
      margin-bottom: 50px;
    }

    .pt-8 {
      padding-top: 8px;
    }

    .form-input:last-child {
      margin-bottom: 0 !important;
    }


    .select-btn {
      button {
        padding: 8px 11px !important;

        &:hover, &:focus {
          .icon {
            color: #fff;
          }
        }
      }
    }

    table {
      th, td {
        &:first-child {
          padding-left: 24px;
        }

        &:last-child {
          padding-right: 24px;
        }

        button {
          padding: 8px 11px;

          &:hover, &:focus {
            .icon {
              color: #fff;
            }
          }
        }
      }
    }
  }
  

    /* create sku styling end */
.config-panel-content.sku-batch{
  .modal-wrapper{
     .modal-inner.create,.modal-inner.update{
      max-width:410px;
      .evolve-dialog__body{
        height:60vh;
        overflow:scroll;
        overflow-x:hidden;
        .moment .ic-date-icon {
          top:14px;
        }
        .filter-dropdown-card{
          right:10px;
          top:81%;
        }

      }
    }
  }
}
/* Image section */
    /* sku create and detail styling start */
  .config-panel-list {
    margin-bottom: 65px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .config-panel-header {
    line-height:21px;
    border-bottom:1px solid #CDCED9;
    display:inline-block;
    width:100%;
    margin-bottom:24px;
    label {
      display: block;
      padding-bottom: 4px;
      font-size: 14px;
      color: #6b6c7e;
    }
  }

  .config-panel-content {
    .table-wrap{
      overflow:auto;
      border:0;
      table{
        border:0;
        thead{
          tr{height:32px;}
          th{
            padding:8px 12px;
            border-right:0;
            border-left:0
          }
        }
        tbody{
          tr{
            height:56px;
          }
          td{
            border-right:0;
            border-left:0;
          }
        }
      }
    }
    .form-input {
      margin-bottom: 24px;
      display: flex;
      flex-wrap: wrap;
    }

    .modal-wrapper .form-input {
      display: block;
    }

    label {
      margin: 8px 0 5px 0;
      min-width: 255px;
      font-weight: 600;
      font-size: 14px;
      display: inline-block;
    }

    .select-btn button {
      padding: 6px 16px;
    }

    .form-select-input {
      margin-bottom: 16px !important;
    }

    label:empty {
      display: none;
    }

    input {
      flex: 1;
    }

    &.sku-list {
      background: #fff;
      padding: 24px;
      margin-top: -24px;
      border-bottom: 1px solid #E7E7ED;
    }

    &.sku-batch {
      background: #fff;
      margin-top: -24px;
      border-bottom: 1px solid #E7E7ED;
    }

    .evolve-dialog__body {
      label {
        min-width: auto;
        margin: 0 0 4px;
      }

      .rate-form-input {
        label + div {
          width: 100%;
        }

        .moment {
          background: #F1F2F5;
          padding: 11px 50px 11px 16px;
        }
      }

      .filter-dropdown-card {
        min-width: auto;
      }
    }

    .form-input input + span,
    .form-select-input > div + span {
      color: red;
      display: block;
      width: 100%;
      font-weight: normal;
      text-align: right;
    }
  }

  /* Image section */
.skuImage{
  display:flex;

   .thumbnail-img {
    height: 64px;
    width: 64px;
    border-radius: 4px;
    margin: 0px;
  }

  .attachment-item{
    width: 64px;
    height: 64px;
    margin-top: 0;
    margin-left: 16px;
  }
}
`;
export default SKUStyled;
