import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { ORDER_DETAIL_FRAGMENT, PROMOTION_ORDER_FRAGMENT } from '../../API';
import { CALL_ORDER_VALUE_FRAGMENT } from '../../../../common/GqlFragments';

const UPDATE_ORDER = graphql(gql`
mutation ($input: UpdateOrderInput!) {
  updateOrder(input: $input)
  {
   line {
       inStock
       freeSku
       focusedSku
        ...OrderDetailFragment
    }
    callOrderValue {
       ...CallOrderValueFragment
    }
  }
} ${ORDER_DETAIL_FRAGMENT}
${CALL_ORDER_VALUE_FRAGMENT}
`, {
  props: ({ mutate }) => ({
    updateOrder: variables => mutate({
      variables,
    }),
  }),
});

const SPLIT_ORDER = graphql(gql`
mutation ($id: Int!, $quantity: [Int]!) {
    splitOrder(id: $id, quantity: $quantity)
    {
      newOrders {
          ...PromotionOrderFragment
          Lines {
              inStock
              freeSku
              focusedSku
          }
      }
      oldOrder {
        Call {
          id
          totalNetAmount
        } 
          id
          callId
      }
    }
  }
  ${PROMOTION_ORDER_FRAGMENT}
`, {
  props: ({ mutate }) => ({
    splitOrder: variables => mutate({
      variables,
    }),
  }),
});

const CANCEL_ORDERS = graphql(gql`
mutation ($orderIds: [Int]!, $reason: String!, $callId: Int!, $type: OrderCancelType, $promotionOrderIds: [Int]) {
    cancelOrders(orderIds: $orderIds, reason: $reason, callId: $callId, type: $type, promotionOrderIds: $promotionOrderIds)
    {
      orderIds
      callId
        callOrderValue {
            ...CallOrderValueFragment
        }
    }
  }
${CALL_ORDER_VALUE_FRAGMENT}
`, {
  props: ({ mutate }) => ({
    cancelOrders: variables => mutate({
      variables,
    }),
  }),
});

const STOCK_REPLACEMENT_ORDER_FRAGMENT = gql`
    fragment StockReplacementOrderFragment on StockReplacementOrder {
        id
        skuId
        skuTitle
        reason
        quantity
        images {
            src: image
            thumbnail
        }
        replacedSkuId: replacementSkuId
        replacedSkuBatchId: replacementSkuBatchId
        replacedQuantity: replacementSkuQuantity
        stockStatus
        status
        skus: exchangeSkus {
            id: skuId,
            batchId,
            rlp
            title,
            stockBalance
        }
    }
`;

const GET_REPLACED_STOCK_DETAILS = gql`
      query getReplacedStockDetails($stockReplacementId: Int!) {
        stockReplacementReceivedDetails(stockReplacementId: $stockReplacementId) {
            id
            notes
            stocks: StockReplacementOrders {
                ...StockReplacementOrderFragment
            }
        }
    }
      ${STOCK_REPLACEMENT_ORDER_FRAGMENT}
`;

const REPLACE_ORDERS = graphql(gql`
mutation ($orderList: [UpdateReplacementOrderInput]!, $status: orderStateEnum) {
    updateReplacementOrders(orderList: $orderList, status: $status )
  }
`, {
  props: ({ mutate }) => ({
    updateReplacementOrders: variables => mutate({
      variables,
    }),
  }),
});

const query = {};

const mutation = {
  updateOrder: UPDATE_ORDER,
  splitOrder: SPLIT_ORDER,
  cancelOrders: CANCEL_ORDERS,
  updateReplacementOrders: REPLACE_ORDERS,
};

export {
  UPDATE_ORDER,
  SPLIT_ORDER,
  CANCEL_ORDERS,
  REPLACE_ORDERS,
};

export { query, mutation, GET_REPLACED_STOCK_DETAILS };
