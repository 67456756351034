import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { WeeklyStyled } from './WeeklyViewStyled';
import { Icon } from '../../../../components';
import { getPrevWeek, getNextWeek, getDayMonthTitleFromDate } from '../date';

const propTypes = {
  weekRange: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
  onWeekChange: PropTypes.func,
};

const ICON_TYPE = {
  NEXT: 'NEXT',
  PREV: 'PREV',
};

const defaultProps = {
  weekRange: {
    start: '2019-12-01',
    end: '2019-12-07',
  },
  onWeekChange: () => null,
};

class WeekTitle extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
  }

  handleHeaderClick = (type) => {
    const { onWeekChange, weekRange } = this.props;

    const week = type === ICON_TYPE.NEXT
      ? getNextWeek(weekRange.start, weekRange.end) : getPrevWeek(weekRange.start, weekRange.end);

    this.setState({}, () => onWeekChange(week.start, week.end));
  };

  render() {
    const { weekRange } = this.props;

    return (
      <WeeklyStyled>
        <div className="week-view">
          <Icon
            iconName="angle-left"
            className="cursor-pointer"
            onClick={
              () => this.handleHeaderClick(ICON_TYPE.PREV)
            }
          />
          <h4>
            {
              `${getDayMonthTitleFromDate(weekRange.start)} - ${getDayMonthTitleFromDate(weekRange.end)}`
            }
          </h4>
          <Icon
            iconName="angle-right"
            className="cursor-pointer"
            onClick={
              () => this.handleHeaderClick(ICON_TYPE.NEXT)
            }
          />
        </div>
      </WeeklyStyled>
    );
  }
}

WeekTitle.propTypes = propTypes;

WeekTitle.defaultProps = defaultProps;

export default WeekTitle;
