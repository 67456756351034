
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import memoize from 'memoize-one';
import DonutChartWithPercent from '../../../components/Chart/DonutChartWithPercentage';
import {
  callageColorSet, callageDataFormatter, defaultCallageData, getDataItem,
} from '../config';
import TitleText from '../../../components/Chart/TitleText';
import Legend from '../../../components/Chart/Legend';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { CALLAGE } from '../../../data/enums/Dashboard';
import { compareList } from '../../../utils/arrayProcessor';
import { convertObjToArray } from '../../../utils/objectProcessor';
import ChartWrap from './ChartWrap';
import SectionWithTitle from '../components/SectionWithTitle';

const propTypes = {};

const defaultProps = {};

class CallageChart extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  static getDerivedStateFromProps(props, state) {
    if (!compareList(props.data, state.serverData)) {
      const formattedData = callageDataFormatter(
        props.data,
        { ...state.scheduledData },
        { ...state.percentData },
      );

      return {
        chartData: formattedData.formattedList,
        serverData: [...props.data],
        scheduledData: formattedData.scheduledData,
        percentData: formattedData.percentData,
      };
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      chartData: convertObjToArray(defaultCallageData),
      serverData: [],
      scheduledData: getDataItem(0, CALLAGE.scheduled),
      percentData: getDataItem(0, CALLAGE.productivity),
    };
    this.title = 'Callage';
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    // this.setState({ data: dataSet }, () => this.calculateScheduleData());
  }

  getScheduledData = memoize((data) => {
    let schedule = 0;
    data.forEach((d) => { if (d.label !== CALLAGE.offroute) schedule += d.value; });
    return getDataItem(fixedFloatAndCommas(schedule || 0), CALLAGE.scheduled);
  });

  render() {
    const { dimensions, data } = this.props;
    const { chartData, percentData, scheduledData } = this.state;

    return (
      <ChartWrap>
        <Link to="/reports/callage">
          <SectionWithTitle title="Callage" id="callage">
            <div className="chart-wrap callage-height">
              <div className="donutchart">
                <DonutChartWithPercent
                  id={this.title}
                  data={chartData}
                  dimensions={dimensions}
                  percent={percentData}
                  colorSet={callageColorSet}
                />
              </div>
              <div className="donutlegend">
                <TitleText
                  id={this.title}
                  dimensions={dimensions}
                  styleAdjustment={{ marginTop: 30 }}
                  data={scheduledData}
                />
                <Legend
                  id={this.title}
                  data={chartData}
                  colorSet={callageColorSet}
                  dimensions={dimensions}
                />
              </div>
            </div>
          </SectionWithTitle>
        </Link>
      </ChartWrap>
    );
  }
}

CallageChart.propTypes = propTypes;

CallageChart.defaultProps = defaultProps;

export default CallageChart;
