import * as baseDataService from './base';
import * as subscriptionService from './subscription';

import { getDataFromLocalStorage, STORAGE_TABLE as CLIENT_STORAGE_TABLE } from './base';

import {
  setUser,
  setToken,
  getUser,
  getUserRole,
} from './user';

import { getPermission, setPermission } from './permission';
import { fetchAndSetTownList, getTown } from './universe';

// TODO: update in up comming sprint.
// MAKE CONSTANT VARIABLES.

subscriptionService.initializeSubscription();

export {
  getDataFromLocalStorage,
  CLIENT_STORAGE_TABLE,
};


const setDataToLocalStorage = async (label, value) => {
  switch (label) {
    case 'permission':
      await setPermission(value);
      break;
    default:
      break;
  }

  return null;
};

export {
  getUserRole,
  getUser,
  setUser,
  setToken,
  getPermission,
  setPermission,
  fetchAndSetTownList,
  getTown,
};


export default baseDataService;
