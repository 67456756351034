import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { tableData } from '../../../utils/paginatedListUtils';
import {
  Badge,
  Button,
} from '../../../components';
import { normalPresentor } from '../../../utils/date';
import { getPermissionToUpdateLeaveRequest } from '../../base/permission';
import { STATUS as LEAVE_STATUS } from '../../../data/enums';

const labelMappings = {
  fullName: 'fullName',
  appliedDate: 'appliedDate',
  userGroup: 'userGroup',
  leaveDate: 'leaveDate',
  days: 'days',
  type: 'type',
  reason: 'reason',
  status: 'status',
  buttonHolders: 'buttonHolders',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.fullName,
    title: 'Full Name',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.appliedDate,
    title: 'Applied Date',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.userGroup,
    title: 'User Group',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.leaveDate,
    title: 'Leave Date',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.days,
    title: 'Days',
    show: true,
  }, {
    id: 6,
    label: labelMappings.type,
    title: 'Type',
    show: true,
  },
  {
    id: 7,
    label: labelMappings.reason,
    title: 'Reason',
    show: true,
  },
  {
    id: 8,
    label: labelMappings.status,
    title: 'Status',
    show: true,
  },
  {
    id: 9,
    label: labelMappings.buttonHolders,
    title: '',
    show: true,
  },

];

const TableHeader = () => (
  <thead className="">
    <tr>
      {labelConfig.map(label => (label.show
        ? tableData(label.title, label.align, label.classNames) : null))}
    </tr>
  </thead>
);

const returnTableBody = (data, loading, onRowButtonClick) => {
  const table = {};

  table[labelMappings.fullName] = (
    <td key={`${data.id}-fullName`}>
      {`${data.RequestedBy.firstName} `}
      {data.RequestedBy.lastName}
    </td>
  );
  table[labelMappings.appliedDate] = (
    <td key={`${data.id}-appliedDate`}>
      {normalPresentor(data.createdAt)}
    </td>
  );
  table[labelMappings.userGroup] = (
    <td key={`${data.id}-userGroup`}>{data.UserGroup.name}</td>
  );
  table[labelMappings.leaveDate] = (
    <td key={`${data.id}-leaveDate`}>
      {`${normalPresentor(data.startDate)} - `}
      {`${normalPresentor(data.endDate)}`}
    </td>
  );
  table[labelMappings.days] = (
    <td key={`${data.id}-days`} className="text-right">
      {data.days}
    </td>
  );
  table[labelMappings.type] = (
    <td key={`${data.id}-type`}>
      {data.leaveType}
    </td>
  );
  table[labelMappings.reason] = (
    <td key={`${data.id}-reason`}>
      {data.reason}
    </td>
  );
  table[labelMappings.status] = (
    <td key={`${data.id}-status`}>
      <span className={data.status.toLowerCase()}>
        <span className="badge_border">
          <Badge light>{data.status}</Badge>
        </span>
      </span>
    </td>
  );
  table[labelMappings.buttonHolders] = (
    <td key={`${data.id}-buttonHolders`}>
      {
                    getPermissionToUpdateLeaveRequest(data) && (
                    <Fragment>
                      <Button
                        outlined
                        disabled={loading}
                        onClick={() => onRowButtonClick(data, LEAVE_STATUS.APPROVED)}
                        className="success"
                      >
                        Approve
                      </Button>
                      <Button
                        outlined
                        disabled={loading}
                        onClick={() => onRowButtonClick(data, LEAVE_STATUS.REJECTED)}
                        className="danger"
                      >
                        Reject
                      </Button>
                    </Fragment>
                    )
                  }
    </td>
  );

  return table;
};

const TableBody = ({
  data, loading, onRowButtonClick,
}) => {
  // Expanding/Dragging and collapsing table body.
  // color based upon table body
  // tooltip if it is sorted.
  // active className
  const tableBody = returnTableBody(data, loading, onRowButtonClick);

  return (

    <tr key={`leaveManagement-${data.id}`}>
      {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
  onRowButtonClick: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
};

export {
  TableHeader,
  TableBody,
};
