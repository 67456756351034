import styled from 'styled-components';

const OutletDetailWrap = styled.div`
  position: relative;
  height:100%;

  .subtitle {
    position: absolute;
    top: -49px;
    left: 24px;
    font-size: 18px;
    font-weight: normal;
    color: #6b6c7e;
  }
  
  ul.list li {
    padding-right: 24px;
  }
  
  .react-tabs {
    position: relative;
    height:100%;

      .react-tabs__tab-panel {
        height: calc(100% - 50px) ;
  }
  }
  
  .tab-label {
    display: inline-block;
    margin-right: 8px;
  }
  
  .react-tabs__tab-list {
    /* margin: 0 48px 16px 48px; */
    list-style: none;
    background: #fff;
  }
  
  .react-tabs__tab {
    display: inline-block;
    padding:0 12px;
    text-align: center;
    color: #6b6c7e;
    font-weight: 600;
    cursor: pointer;
    font-size: 14px;
    height:32px;
    line-height:32px;
    min-width:80px;
    
    &-panel {
        padding: 0;
    }
    
    &:hover, &:focus {
      color: #272833;
    }
    
    &.react-tabs__tab--selected {
      border-bottom: 2px solid #0b5fff;
      color: #272833;
      outline: 0;
    }
  }

  .react-tabs__tab--selected {
    .light {
      background: #0b5fff;
      color: #fff;
      position: relative;
      top: -1px;
    }
  }
  
  section {
    margin-bottom: 40px;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
  
  .container-fluid {
    padding-left: 24px;
    padding-right: 24px;
  }
  
  .tab-section {
    table {
      
      /* th {
        width: 11%;
        
        &:first-child {
          width: 13%;
        }
        
        &:nth-child(2) {
          width: 8%;
        }
        
        &:last-child {
          width: 5%;
        }
      } */
      
      tr {
        &:hover {
          background: #f0f5ff;
        }
        
        &.active {
        background: #f0f5ff;
        
        td {
            border-bottom: 1px solid #0c5fff;
          }
        }
      }
    }
    
    .split-form-wrap {
      table th {
        &:first-child {
          width: 15%;
        }
        &:last-child {
          width: 1%;
        }
      }
    }
    

  }
  
  .stock-label {
    border: 1px solid transparent;
    display: inline-block;
    font-size: 10px;
    border-radius: 2px;
    padding: 3px;
    
    &.IN {
      border-color: #47c464;
      color: #287d3c;
    }
    
    &.OUT {
      border-color: #ff8f39;
      color: #b95000;
    }
  }
  
  .split-form-container {
    .table-wrap {
      max-height: 250px;
      overflow-y: scroll;
      margin-bottom: 24px;
    }
  
    .stats {
      margin: 16px 0 24px;
    }
    
    .split-header {
      label {
        font-size: 12px;
        font-weight: 600;
        display: block;
        color: #6b6c7e;
      }
      
      span {
        display: inline-block;
        font-size: 18px;
        font-weight: 600;
        margin-top: 5px;
      }
    }
    
    .split-body {
      .table-paginate {
        text-align: right;
        padding: 10px 0;
      }
    }
  }
  /* Used for modal popup */
  #ledger-tab {
  
    .modal-inner {
      max-width: 400px;
      
     .evolve-dialog__body {
        height: 440px;
        overflow-y: scroll;
     }
    }
/*     
    .tab-section.ledger {
      position: relative;
      
      .absolute {
        position: absolute;
        top: 34px;
        
        + div {
          margin: 0;
        }
      }
      
      .table-wrap {
        margin-top: 24px;
      } */
      
    }
  }
  /* Outlet wrap tab */
  .outlet-wrap {
    
      .custom-control-label-text + div {
      margin-top: 5px;
    }
    .disabled-section {
      .form-input, .form-select-input , .map-wrapper{
        pointer-events: none;
        opacity: 0.8;
      }
    }
  }

`;

export default OutletDetailWrap;
