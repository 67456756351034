/* global google */
class InfoWindow {
    generateInfoWindow() {
        return new google.maps.InfoWindow({
            content: '<div id="infoWindow">'
        });
    }
}
const infoWindow = new InfoWindow();
export default infoWindow;
