import gql from 'graphql-tag';
import { graphql } from 'react-apollo/graphql';

const TOWN_FRAGMENT = gql`
  fragment TownFragment on Town {
    id
    title
}
`;

const CATEGORY_SUMMARY_FRAGMENT = gql`
fragment CategorySummaryFragment on Category {
    id
    title
}
`;

const GEO_LOCATION_FRAGMENT = gql`
fragment GeoLocationFragment on Location {
    latitude
    longitude
}
`;

const OUTLET_SUMMARY_FRAGMENT = gql` 
fragment OutletSummaryFragment on RetailOutlet {
    id
    rMapId
    title
    geoLocation {
        ...GeoLocationFragment
    }
    Town {
        ...TownFragment
    }
    Category {
        ...CategorySummaryFragment
        Channel {
            id
            title
        }
    }
    Route{
      id
      title
    }
}
${TOWN_FRAGMENT}
${CATEGORY_SUMMARY_FRAGMENT}
${GEO_LOCATION_FRAGMENT}
`;

const GET_OUTLETS_ARC = gql`
query getRetailOutlet($offset: Int, $limit: Int, $filter: FilterInput) {
    retailOutlets(offset: $offset, limit: $limit, filter: $filter) {
        rows {
            ...OutletSummaryFragment
        }
        count
    }
}
${OUTLET_SUMMARY_FRAGMENT}
`;

const GET_ROUTES_ARC = gql`
query getRoutes($offset: Int, $limit: Int, $filter: FilterInput){
  routes(offset: $offset, limit: $limit, filter: $filter) {
  rows {
  id
  title
  Town {
  id
  title
  }
  }
  count
  }
  }`;

const GET_ROSIA_ROUTES = gql`
query getarcroutes($routeId: [Int], $townId: [Int]){
  getARCRoutes(routeIds: $routeId, townIds: $townId) {
    id
    title
    townId
    townName
    start {
      location {
        latitude
        longitude
      }
      outletId
    }
    end {
      location {
        latitude
        longitude
      }
      outletId
    }
    outlets {
      id
      title
      location {
        latitude
        longitude
      }
      channelId
      channelName
      categoryId
      categoryName
      sequence
    }
  }
}
`;

const SAVE_ROUTES_ARC = graphql(
  gql`
  mutation($input:[ARCRouteInput]!){
    createARCRoute(input:$input){
      success
      message
    }
    }
  `,
  {
    props: ({ mutate }) => ({
      saveRoutes: variables => mutate({
        variables,
      }),
    }),
  },
);

export {
  GET_OUTLETS_ARC,
  GET_ROUTES_ARC,
  SAVE_ROUTES_ARC,
  GET_ROSIA_ROUTES,
};
