import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const CHANGE_PAYMENT_STATUS = graphql(gql`
  mutation($ids: [Int], $status: PaymentStatus ){
    changePaymentStatus(ids: $ids, status: $status){
      ids
      message
    }
  }
`, {
  props: ({ mutate }) => ({
    changePaymentStatus: variables => mutate({
      variables,
    }),
  }),
});

export { CHANGE_PAYMENT_STATUS };
