import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CreateSkuForm from './Form';
import CatalogSelect from './catalogSelect/index';
import GridDialog from './gridDialog/index';
import {
  BreadCrumb, Button, Icon,
} from '../../../../components/index';
import { SKU } from '../../../../data/enums/Route';
import { refGenerator } from '../../../../utils/refGenerator';
import withAlert from '../../../../utils/composition/withAlert';
import { handleFormSubmit } from '../../../../utils/crudResponseProcessor';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { clone } from '../../../../utils/arrayProcessor';
import {
  crudSuccess as crudRequestConfig,
  breadCrumbConfig,
  formConfig as form,
  title, MASTER_DATA_TYPES,
} from './config';
import { has } from '../../../../utils/objectPrototypes';
import { PanelStyled, PanelHeader } from '../../../common/configuration';
import { getPermissionForSKU } from '../../../base/permission';
import { addEdit } from '../../../../utils/utilities';
import SKUStyled from '../SKUStyled';
import { dropdownChange } from '../../../../utils/formHandlers';
import Image from './skuImage';

const propTypes = {
  createSKU: PropTypes.func.isRequired,
  updateSKU: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  getCatalogList: PropTypes.func.isRequired,
  getSkuDetail: PropTypes.func.isRequired,
  getCatalogDetail: PropTypes.func.isRequired,
  getBUData: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class CreateSku extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      data: form.mapper({}),
      backUpData: form.mapper({}),
      enableErrorDisplay: false,
      clearData: false,
      uomList: [],
      skuId: has.call(props.match.params, 'id') ? parseInt(props.match.params.id, 10) : 0,
      crudMode: has.call(props.match.params, 'id') ? EVENT_OPERATION.READ
        : EVENT_OPERATION.CREATE,
      skuTagList: [],
      caseUnitList: [],
    };
    const serverCall = {
      [EVENT_OPERATION.CREATE]: props.createSKU,
      [EVENT_OPERATION.UPDATE]: props.updateSKU,
    };
    this.permission = getPermissionForSKU();
    this.onCRUDSuccess = this.responseProcessor();
    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.onAPIRequestFailure,
      crudRequestConfig, serverCall);
    this.formReference = refGenerator(form.validationField);
  }

  componentDidMount() {
    const { skuId } = this.state;
    if (skuId) {
      this.setState({ skuId }, () => this.getSkuDetail(skuId));
    }
    this.getSkuTagsAndCaseUnit();
    this.getUOMList();
  }

  getUOMList = () => {
    const { getBUData } = this.props;

    getBUData({}, {
      handleSuccess: (response) => {
        const { settings } = response.data;
        const uomList = settings ? settings.unitsOfMeasurement : [];

        this.setState({ uomList });
      },
      handleError: (err) => {
        this.onAPIRequestFailure(err);
      },
    });
  }

  getSkuDetail = (id) => {
    const { getSkuDetail, displayAlert } = this.props;
    let data = {};
    getSkuDetail({ id }, {
      handleSuccess: (response) => {
        const { findSKU = {} } = response.data;
        data = form.mapper(findSKU);
        // data.catalogDetails = data.catalogDetails.reverse();
        const backUpData = clone(data);
        this.setState({ data, backUpData });
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  };

  getSkuTagsAndCaseUnit = () => {
    const { getMasterData, displayAlert } = this.props;

    getMasterData({}, {
      handleSuccess: (res) => {
        const { listMasterData = [] } = res.data;
        const dataObj = {
          [MASTER_DATA_TYPES.SKU_TAGS]: [],
          [MASTER_DATA_TYPES.CASE_UNIT]: [],
        };
        if (listMasterData.length > 0) {
          listMasterData.forEach((item) => {
            dataObj[item.type] = [...(item.list) || []];
          });
          this.setState({
            skuTagList: dataObj[MASTER_DATA_TYPES.SKU_TAGS],
            caseUnitList: dataObj[MASTER_DATA_TYPES.CASE_UNIT],
          });
        }
      },
      handleError: (err) => {
        displayAlert(ALERT_TYPE.DANGER, err);
      },
    });
  }

  getFormValidationStatus = () => (!Object.values(this.formReference)
    .find(item => item.getValidState() === false));

  handleInputChange = (event, firstParam = '', paramList = []) => {
    const { data } = this.state;
    if (firstParam) {
      if (paramList.length > 1) {
        paramList.reduce((acc, value, index, list) => {
          if (index === list.length - 1) {
            return acc[value] = event.formattedValue;
          }
          return acc[value];
        }, data);
      } else {
        data[firstParam][event.target.name] = event.formattedValue;
      }
    } else {
      data[event.target.name] = event.formattedValue;
    }
    this.setState({ data });
  };


  handleDropDownChange = (value, parameterRef = [], callBack = () => null) => {
    const { data } = this.state;
    const updatedData = dropdownChange(data, parameterRef, value);

    this.setState({ data: updatedData });
  };

  onSubmit = () => {
    const valid = this.getValidationStatus();
    if (valid) {
      this.createSku();
    } else {
      this.setState({ enableErrorDisplay: true });
    }
  };

  handleButtonCancel = () => {
    const { crudMode, data, backUpData } = this.state;
    const { id } = data;
    if (crudMode === EVENT_OPERATION.CREATE) {
      this.setState({
        data: form.mapper({}), clearData: true,
      }, () => {
        const { data } = this.state;
      });
    } else {
      this.setState({
        data: { ...backUpData },
        crudMode: EVENT_OPERATION.READ,
      });
    }
  };

  getValidationStatus = () => {
    const catalogSelectValidationStatus = this.getCatalogSelectValidationStatus();
    const formValidationStatus = this.getFormValidationStatus();

    return (catalogSelectValidationStatus && formValidationStatus);
  };

  rateProcessor = (prevRates, newRates) => {
    const prevRatesLength = prevRates.length;
    return newRates.slice(prevRatesLength);
  };

  createSku = () => {
    const { data, skuId, crudMode } = this.state;
    const { displayAlert } = this.props;
    const { Rates, ...updatedData } = data;
    const newRatesFromDialog = this.getRates();
    updatedData.rates = this.rateProcessor(Rates, newRatesFromDialog);
    updatedData.catalogDetails = this.getSkuCatalogDetails();
    updatedData.images = this.getImages();
    if (crudMode === EVENT_OPERATION.CREATE && updatedData.rates.length === 0) {
      displayAlert(ALERT_TYPE.WARNING, 'Enter SKU batch and rate');
    } else { this.onFormSubmit(crudMode, updatedData); }
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
    console.log(error);
  };

  responseProcessor = () => {
    const onAPIRequestSuccess = type => () => {
      const { displayAlert } = this.props;
      displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message, this.directToMainPage);
    };

    return onAPIRequestSuccess;
  };

  directToMainPage = () => {
    /** direct to SKU page */
    const { history } = this.props;
    history.push(`/${SKU}`);
  };

  render() {
    const {
      data, enableErrorDisplay, skuId, crudMode, clearData, uomList, skuTagList,
      caseUnitList,
    } = this.state;
    const {
      serverResponseWaiting, getCatalogDetail, displayAlert, getCatalogList,
    } = this.props;
    const updateMode = crudMode === EVENT_OPERATION.UPDATE;
    const createMode = crudMode === EVENT_OPERATION.CREATE;

    return (
      <SKUStyled>
        <div className="section-header border-b">
          <PanelStyled>
            <BreadCrumb list={breadCrumbConfig} />
            <PanelHeader>
              <h2>
                {addEdit(updateMode, skuId ? data.title : title)}
              </h2>
              <div className="flex m-0">
                {(crudMode === EVENT_OPERATION.CREATE || updateMode) && (
                <div>
                  <Button
                    small
                    secondary
                    disabled={serverResponseWaiting}
                    onClick={() => this.handleButtonCancel()}
                  >
                    <span>Cancel</span>
                  </Button>
                  <Button
                    small
                    primary
                    disabled={serverResponseWaiting}
                    onClick={() => this.onSubmit()}
                  >
                    <span>Save</span>
                  </Button>
                </div>
                )}
                {!updateMode ? (
                  (this.permission.update && (!createMode)) && (
                  <Button
                    secondary
                    iconBtnSmall
                    disabled={updateMode}
                    onClick={() => {
                      this.setState({
                        crudMode: EVENT_OPERATION.UPDATE,
                      });
                    }}
                    className="ml-16"
                  >
                    <Icon iconName="pencil" />
                  </Button>
                  )) : ''
                  }
              </div>
            </PanelHeader>
          </PanelStyled>
        </div>
        <div className="section-content">
          <div className={` config-view-wrap  ${createMode || updateMode ? '' : 'disabled'}`}>
            <CreateSkuForm
              data={data}
              uomList={uomList}
              crudMode={crudMode}
              skuTagList={skuTagList}
              caseUnitList={caseUnitList}
              loading={serverResponseWaiting}
              enableErrorDisplay={enableErrorDisplay}
              handleInputChange={this.handleInputChange}
              handleDropDownChange={this.handleDropDownChange}
              refsObj={this.formReference}
            />
            <div className="config-inner">
              <Image
                crudMode={crudMode}
                images={data.images}
                getImages={childMethod => this.getImages = childMethod}
              />

            </div>
            <div className="config-inner">
              <CatalogSelect
                crudMode={crudMode}
                skuId={skuId}
                clearData={clearData}
                displayAlert={displayAlert}
                getCatalogList={getCatalogList}
                skuCatalogDetails={clone(data.catalogDetails)}
                getCatalogDetail={getCatalogDetail}
                enableErrorDisplay={enableErrorDisplay}
                getStatus={childMethod => this.getCatalogSelectValidationStatus = childMethod}
                getSkuCatalogDetails={childMethod => this.getSkuCatalogDetails = childMethod}
              />
            </div>
            <div className="config-inner">
              <GridDialog
                rates={clone(data.Rates)}
                crudMode={crudMode}
                getRates={childMethod => this.getRates = childMethod}
              />
            </div>
          </div>
        </div>
      </SKUStyled>
    );
  }
}

CreateSku.propTypes = propTypes;

CreateSku.defaultProps = defaultProps;

export default withAlert()(CreateSku);
