import React, { Fragment } from 'react';
import SummaryView from '../../../common/detailViews/summaryView';
import PageHeader from '../../../common/detailViews/pageHeader/PageHeader';
import { PanelStyled } from '../../../common/configuration';
import { TableView, TotalView } from '../create/table/View';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import PanelCard from '../../../../components/Cards/PanelCard';

const PrnView = ({ data, title, breadCrumb }) => (
  <Fragment>
    <div className="section-header">
      <PanelStyled>
        <PageHeader
          breadCrumb={breadCrumb}
          title={title}
        />
      </PanelStyled>
    </div>
    <div className="section-content pad-48">
      <PanelCard cardTitle="details">
        <SummaryView
          data={data.summary || []}
        />
      </PanelCard>
      <PanelCard cardTitle="sku" skuClassStatus>
        <TableView
          skuLines={data.lines}
          viewType={EVENT_OPERATION.DETAILS}
        />
        <TotalView
          priceDetails={data.prnAmount}
          refsObj={{}}
          viewType={EVENT_OPERATION.DETAILS}
        />
      </PanelCard>
    </div>
  </Fragment>
);

export default PrnView;
