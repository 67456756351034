import {
  compose,
} from 'recompose';
import SalesInvoice from './SalesInvoice';
import withRequestTracker from '../../../../../utils/composition/withRequestTracker';
import {
  query,
  mutation,
  INVOICE_ORDERS,
} from './API';

const ComposedSalesInvoice = compose(
  INVOICE_ORDERS,
  withRequestTracker({
    query,
    mutation,
  }),
)(SalesInvoice);

export default ComposedSalesInvoice;
