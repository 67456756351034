import React from 'react';
import PropTypes from 'prop-types';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { CheckBox, Switch } from '../../../components';
import {
  defaultMenuConfigList,
  menuAction,
  tableHeaderWithCheckBox,
} from '../../../utils/paginatedListUtils';

// todo update the configurations.
const labelMappings = {
  id: 'id',
  checkbox: 'checkbox',
  title: 'title',
  categories: 'categories',
  type: 'type',
  status: 'status',
  actionHolders: 'actionHolders',
};

const menuConfigList = [defaultMenuConfigList[EVENT_OPERATION.DELETE]];

const labelConfig = [
  {
    id: 1,
    label: labelMappings.checkbox,
    title: '',
    show: true,
    action: true,
  },
  {
    id: 2,
    label: labelMappings.title,
    title: 'Title',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.categories,
    title: 'Categories',
    // classNames: 'text-right',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.type,
    title: 'Type',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.status,
    title: 'Status',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.actionHolders,
    title: '',
    show: true,
  },

];


const returnTableBody = (data, checkedList, onIconClick, onSecondaryCheckBoxClick, permission) => {
  const table = {};
  table[labelMappings.checkbox] = (permission.delete && (
    <td
      onClick={e => e.stopPropagation()}
    >
      <CheckBox
        checked={checkedList.includes(data.id)}
        onCheckBoxClick={() => onSecondaryCheckBoxClick(data.id)}
      />
    </td>
  ));
  table[labelMappings.title] = (
    <td key={`channel-${data.id}-title`}>{data.title}</td>
  );
  table[labelMappings.categories] = (
    <td key={`channel-${data.id}-categories`}>{data.categories ? data.categories.length : null}</td>
  );
  table[labelMappings.type] = (
    <td key={`channel-${data.id}-type`}>{data.type}</td>
  );
  table[labelMappings.status] = (
    <td onClick={e => e.stopPropagation()}>
      {
        <Switch
          onToggle={() => onIconClick(EVENT_OPERATION.UPDATE_STATUS, data)}
          checked={data.status}
          id={`switch-${data.id}`}
          disabled={!permission.update}
        />
      }
    </td>
  );
  table[labelMappings.actionHolders] = (
    <td
      className="text-right simple-popup-actions"
      onClick={e => e.stopPropagation()}
    >
      {menuAction(menuConfigList, { menuIcon: 'ellipsis-v' }, onIconClick, data, permission)}
    </td>
  );

  return table;
};

const TableBody = ({
  data, checkedList, onIconClick, secondaryCheckboxHandler, permission, onRowClick,
}) => {
  // Expanding/Dragging and collapsing table body.
  // color based upon table body
  // tooltip if it is sorted.
  // active className
  const tableBody = returnTableBody(data, checkedList, onIconClick, secondaryCheckboxHandler, permission);

  return (
    <tr onClick={() => onRowClick(EVENT_OPERATION.DETAILS, data.id)}>
      {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

const TableHeader = ({
  dataListLength, checkedListLength, primaryCheckboxHandler, permission,
}) => tableHeaderWithCheckBox(dataListLength, checkedListLength, primaryCheckboxHandler, permission,
  labelConfig, labelMappings);


TableBody.propTypes = {
  data: PropTypes.object.isRequired,
};


export {
  TableHeader,
  TableBody,
};
