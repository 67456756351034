import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import SubDAssortmentView from './View';
import PageHeader from '../../base/PageHeader';
import { isError } from '../../common/HelperFunctions';
import { PanelStyled } from '../../common/configuration';
import * as queryService from '../../base/query.service';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import { getPermissionForSubDAssortment } from '../../base/permission';
import withAlert from '../../../utils/composition/withAlert';
import { subDAssortmentTitle, breadCrumbConfig } from './config';
import { SUB_D_ASSORTMENT_DETAILS } from '../../../data/enums/Route';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import SubDAssortmentDialog from './components/subDAssortmentDialog';
import { crudRequestConfig } from './components/subDAssortmentDialog/config';
import { crudResponseProcessor } from '../../../utils/crudResponseProcessor';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';

const propTypes = {};

const defaultProps = {};

class SubDAssortment extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      data: {
        list: [],
        total: 1,
      },
      dialog: {
        type: '',
        element: '',
      },
      queryParameters: {
        sort: queryService.baseQueryParameters.sort,
        date: { ...queryService.baseQueryParameters.date },
        filter: queryService.baseQueryParameters.filter,
        search: queryService.baseQueryParameters.search,
        pagination: queryService.baseQueryParameters.pagination,
      },
    };
    this.getData = () => {
      const { data } = this.state;
      return data;
    };
    this.setData = (data) => {
      this.setState({ data });
    };
    this.permission = getPermissionForSubDAssortment();

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      props.displayAlert,
    );
  }

  componentDidMount() {
    this.loadTableData();
  }

  loadTableData = () => {
    const { data, queryParameters } = this.state;
    const { getSubDAssortmentList, displayAlert } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);

    getSubDAssortmentList({
      offset,
      limit: queryParameters.pagination.limit,
      filter: {
        filters: apiFilterProcessor(queryParameters.filter),
        // dateRange: queryParameters.date,
        queryString: queryParameters.search,
      },
    }, {
      handleSuccess: (response) => {
        data.list = (response.data.distributorGroups && response.data.distributorGroups.rows) || [];
        data.total = (response.data.distributorGroups && response.data.distributorGroups.count) || 0;
        if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
        this.setState(data);
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  };

  setQueryParameters = (queryParams, callBack = () => null) => this.setState(
    { queryParameters: queryParams },
    callBack,
  );

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleIconClick = (type, element) => {
    const { history } = this.props;
    const id = element ? element.id || '' : '';

    switch (type) {
      case EVENT_OPERATION.DETAILS:
        history.push(`/${SUB_D_ASSORTMENT_DETAILS}/${id}`);

      case EVENT_OPERATION.CREATE:
      case EVENT_OPERATION.UPDATE:
      case EVENT_OPERATION.REPLICATE:
      case EVENT_OPERATION.DELETE:
        this.setState({
          dialog: {
            type,
            element,
          },
        });
        break;
      default:
        history.push(`/${SUB_D_ASSORTMENT_DETAILS}/${id}`);
    }
  };

  handleSubmit = (type, response) => {
    const { displayAlert } = this.props;
    const { setData, getData } = this;
    displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message);
    crudResponseProcessor(setData, getData, ['title'])(type)(response);
  };

  handleClose = () => {
    this.setState({
      dialog: {
        type: '',
        element: '',
      },
    });
  };


  handleApiFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
    this.handleClose();
  };

  render() {
    const { queryParameters, data, dialog } = this.state;
    const { serverResponseWaiting } = this.props;

    return (
      <Fragment>
          <div className="section-header">
            <PanelStyled>
              <PageHeader
                breadCrumb={breadCrumbConfig}
                config={{
                  title: subDAssortmentTitle,
                  create: this.permission.create,
                  download: false,
                  filter: false,
                  search: true,
                }}
                queryParameters={queryParameters}
                handleCreateClick={this.handleIconClick}
                resetFilter={this.basePaginationService.resetFilter}
                clearSearchText={this.basePaginationService.clearSearchText}
                handleSearchChange={this.basePaginationService.handleSearchInputChange}
                handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
                handleDownloadClick={this.basePaginationService.handleDownloadClick}

              />
            </PanelStyled>
          </div>
          <div className="section-content">
            <SubDAssortmentView
              data={data}
              permission={this.permission}
              loading={serverResponseWaiting}
              pagination={queryParameters.pagination}
              onIconClick={this.handleIconClick}
              onTableHeaderClick={
                this.basePaginationService.handleTableSorting
              }
              onBulkDelete={this.handleSubmit}
              onPageChange={this.basePaginationService.onPageSelect}
            />
            {
              dialog.type && (
                <div className="sbd-modal">
                  <SubDAssortmentDialog
                    type={dialog.type}
                    element={dialog.element}
                    onSubmit={this.handleSubmit}
                    onClose={this.handleClose}
                    onApiFailure={this.handleApiFailure}
                  />
                </div>
              )
            }
          </div>
      </Fragment>
    );
  }
}

SubDAssortment.propTypes = propTypes;

SubDAssortment.defaultProps = defaultProps;

export default withAlert()(SubDAssortment);
