import React, { Component } from 'react';
import PropTypes from 'prop-types';
import View from './View';
import * as queryService from '../../../../../base/query.service';
import withAlert from '../../../../../../utils/composition/withAlert';
import { imageSliderListFormatter } from '../../../../../common/HelperFunctions';
import { ALERT_TYPE } from '../../../../../../data/enums/AlertType';
import CallHistoryWrap from './CallHistoryWrap';
import { getP3MStartDay } from '../../../../../../utils/date';

const propTypes = {
  receivedCalls: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class CallHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id || 0,
      data: {
        list: [],
        total: 1,
      },
      queryParameters: {
        date: { ...queryService.baseQueryParameters.date },
        pagination: queryService.baseQueryParameters.pagination,
      },
    };
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      props.displayAlert,
    );
  }

  componentDidMount() {
    this.loadTableData();
  }

loadTableData = () => {
  const { data, id, queryParameters } = this.state;
  const { receivedCalls, displayAlert } = this.props;
  const date = {
    start: getP3MStartDay(),
    end: queryParameters.date.end,
  };
  receivedCalls({
    offset: 0,
    limit: 50,
    filter: {
      filters: [{ column: 'retail_outlet_id', value: [id.toString()] }],
      dateRange: date,
    },
  },
  {
    handleSuccess: (response) => {
      const callHistory = (response.data.calls && response.data.calls.rows) || [];
      data.total = (response.data.calls && response.data.calls.count) || 0;
      data.list = callHistory.map((call) => {
        if (call.images) {
          call.images = [...(imageSliderListFormatter(call.images) || [])];
        }
        return call;
      });
      this.setState(data);
    },
    handleError: (error) => {
      displayAlert(ALERT_TYPE.DANGER, error);
    },
  });
}

setQueryParameters = (queryParams, callBack = () => null) => this.setState(
  { queryParameters: queryParams },
  callBack,
);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  render() {
    const { data, queryParameters } = this.state;
    const { serverResponseWaiting } = this.props;

    return (
      <CallHistoryWrap>
        <View
          data={data}
          loading={serverResponseWaiting}
          pagination={queryParameters.pagination}
          onPageChange={this.basePaginationService.onPageSelect}
        />
      </CallHistoryWrap>
    );
  }
}

CallHistory.propTypes = propTypes;

CallHistory.defaultProps = defaultProps;

export default withAlert()(CallHistory);
