import {
  compose,
} from 'recompose';
import OrderDeliveryProcessing from './OrderProcessing';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  query, mutation,
} from './API';
import { TOGGLE_STATE, UPLOAD } from '../../common/GqlAPI';

const ComposedOrderDeliveryProcessing = compose(
  UPLOAD,
  TOGGLE_STATE,
  withRequestTracker({
    query,
    mutation,
  }),
)(OrderDeliveryProcessing);

export default ComposedOrderDeliveryProcessing;
