import { graphql } from 'react-apollo/graphql';
import gql from 'graphql-tag';
import { TOGGLE_STATE } from '../../../user/userRole/API';

const CREATE_FOCUSED_SKU = graphql(gql`
    mutation ($input: FocusedSKUInput!) {
        createFocusedSKU(input: $input)
        {
            id
            title
            start
            end
            active
        }
    }`, {
  props: ({ mutate }) => ({
    createFocusedSKU: variables => mutate({
      variables,
    }),
  }),
});

const UPDATE_FOCUSED_SKU = graphql(gql`
    mutation ($input: FocusedSKUInput!, $id: Int!) {
        updateFocusedSKU(input: $input, id: $id)
        {
            id
            title
            start
            end
            active
        }
    }`, {
  props: ({ mutate }) => ({
    updateFocusedSKU: variables => mutate({
      variables,
    }),
  }),
});

const REPLICATE_FOCUSED_SKU = graphql(gql`
    mutation ($input: FocusedSKUInput!, $id: Int!) {
        replicateFocusedSKU(input: $input, id: $id)
        {
            id
            title
            start
            end
            active
        }
    }`, {
  props: ({ mutate }) => ({
    replicateFocusedSKU: variables => mutate({
      variables,
    }),
  }),
});

const mutation = {
  createFocusedSKU: CREATE_FOCUSED_SKU,
  updateFocusedSKU: UPDATE_FOCUSED_SKU,
  replicateFocusedSKU: REPLICATE_FOCUSED_SKU,
  toggleState: TOGGLE_STATE,
};

export {
  mutation, CREATE_FOCUSED_SKU, UPDATE_FOCUSED_SKU, REPLICATE_FOCUSED_SKU,
};
