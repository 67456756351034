import styled from 'styled-components';

const ARCStyled = styled.div`
  #infoWindow {
    margin-left: 5px;
    min-width: 250px;
    min-height:270px;
    h3 {
      margin: 0;
      padding-bottom: 12px;
      font-size: 14px;
      line-height:21px;
      font-weight: 600;
      color: #272833;
      border-bottom:1px solid #E7E7ED;
      margin-bottom:16px;
    }
    p {
      margin: 0;
      display: flex;
      margin-bottom: 8px;
      font-size: 12px;
      font-weight: 400;
      color: #6b6c7e;
      span,
      b {
        flex: 1;
      }
      b {
        color: #272833;
        font-weight: 600;
      }
      &:last-child,
      &:last-of-type{
      padding-bottom:16px;
      border-bottom:1px solid #E7E7ED;
      }
    }
    button{
      margin-top:13px;
      background-color:#0B5FFF;
      font-size:14px;
      line-height:21px;
      color:#FFFFFF;
      font-weight:500;
      border-radius:4px;
      width:100%;
      border-top:1px solid #E7E7ED;
      height:32px;
      line-height:32px;
      text-align:center;
      border:0;
    }
  }
  .parameters .zindex-2__control {
    margin-bottom: 24px;
  }
  .section-header {
    .arc-title-btn {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      button {
        height: 32px;
        width: 64px;
        font-size: 14px;
      }
    }
  }
  .section-content {
    border-top: 1px solid #e7e7ed;
    height: calc(100vh - 118px);
  }
  .absolute-eyes,
  .gmnoprint > div > div[role='button'] {
    height: 32px !important;
    width: 32px !important;
    vertical-align: middle;
    line-height: 28px;
    text-align: center;
    margin-right: 8px;
    border: 1px solid #cdced9;
    border-radius: 4px !important;
    background-color: #cdced9;
  }
  .absolute-eyes {
    position: absolute;
    top: 37px !important;
    left: 45% !important;
    transform: translate(40px, -50%);
    z-index: 1;
    svg {
      height: 16px !important;
      width: 16px !important;
      fill: #6b6c7e !important;
    }
  }
  .flex-container {
    display: flex;
    flex-wrap: wrap;
    .mapParam {
      flex-basis: 100%;
      width: 100%;
      padding: 32px 48px;
      .parameters {
        border: 1px solid #e7e7ed;
        border-radius: 4px;
        background-color: #ffffff;
        padding: 24px 0 16px 0;
        box-shadow:0 4px 8px 0 rgba(39,40,51,0.12);
        h4 {
          padding: 0 16px 24px 16px;
          margin: 0;
          padding: 0 16px 24px 16px;
          width: 100%;
          font-size:10px;
          line-height:10px;
          color:#6B6C7E;
          font-weight:400;
          text-transform:uppercase;
          border-bottom:1px solid #E7E7ED;
        }
  
        .left-form-grp {
          .card-in{
            padding:24px 16px;
            border-bottom:1px solid  #E7E7ED;
            &:last-child{
              border:0;
            }
            .form-radio{
            .form-radio-list{
              .radio-list{
                height:21px;
                margin-right:24px;
                margin-bottom:0;
                input{
                  height:16px;
                  width:16px;
                  margin-right:8px;
                  position:relative;
                  top:3px;
                }
                .text{
                  font-size:14px;
                  line-height:21px;
                  color:#272833;
                }
              }
            }
            }
          }
          .zindex-2__control {
            margin-bottom:0;
            border:1px solid #E7E7ED;
            border-radius:4px;
            background-color:#F1F2F5;
            min-height:40px;
            &.zindex-2__control--is-focused{
              box-shadow:unset;
            }
            .zindex-2__single-value,
            .zindex-2__placeholder {
              font-weight: 400;
              font-size:14px;
              color:#6B6C7E;
            }
            .zindex-2__single-value{
              color:#272833;
            }
          }
          .zindex-2__menu {
            z-index: 100;
          }
        }
        .arc-date {
          display: flex;
          flex-direction: column;
          label {
            margin-bottom: 4px;
          }
          .moment {
            margin-bottom:0;
            border:1px solid #E7E7ED;
            border-radius:4px;
            background-color:#F1F2F5;
            height:40px;
            min-width:229px;
            line-height:40px;
            display:flex;
            align-items:center;
            .ic-date-icon{
              top:12px;
            }
          }
          .filter-dropdown-card {
            min-width: unset;
            width: 330px;
            right: -53%;
            top:-359px;
          }
        }
        label {
          font-size: 14px;
          line-height:21px;
          margin-bottom:4px;
          font-weight: 600;
          color: #272833;
        }
        .form-select-input {
          margin-bottom:24px;
          &:last-child{
            margin-bottom:0;
          }
          .select-css {
            min-width: 229px;
          }
        }
      }
      .map-wrapper-inner {
        #map {
          height: 664px;
        }
        .filter-dropdown-card {
          left: 0;
          top: 33px;
        }
        /* custom css edit of map */
        .gmnoprint {
          top: 45px;
        }
        .gm-style-cc {
          opacity: 0;
        }
      }
    }
    .map-wrapper-inner {
      position: relative;
      .absolute {
        position: absolute;
        top: 20px !important;
      }
    }
    /* for filterbtn  */
    .filter-btn {
      display: flex;
      justify-content: space-between;
    }
    .filter-dropdown-card {
      input {
        background: unset;
        border: unset;
        padding: unset;
        font-size: 11px;
        font-weight: unset;
        color: unset;
        width: 100%;
      }
    }
    /* for filterbtn  */
  }
  .gm-style-cc {
    display: none !important;
  }
`;
export default ARCStyled;
