import React from 'react';
import PropTypes from 'prop-types';
import { Input, Icon } from '../../../components';

const propTypes = {
  value: PropTypes.oneOfType(
    [
      PropTypes.string,
      PropTypes.number,
    ],
  ),
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
};

const defaultProps = {
  value: '',
  onInputChange: () => null,
  enableErrorDisplay: false,
};

class PasswordField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }

   togglePasswordVisibility = () => {
     const { showPassword } = this.state;
     this.setState({ showPassword: !showPassword });
   }


   render() {
     const {
       value, label, name, onInputChange, enableErrorDisplay, refsObj, errorMessage,
     } = this.props;
     const { showPassword } = this.state;

     return (
       <>
         <div className="password">
           <Input
             ref={ref => (refsObj.name = ref)}
             type={showPassword ? 'text ' : 'password'}
             placeholder={label}
             labelContent={label}
             name={name}
             value={value}
             enableErrorDisplay={enableErrorDisplay}
             enableValidation
             onChange={e => onInputChange(e)}
             errorMessage={errorMessage}
           />

           <div className="eye">
             <Icon
               iconName={showPassword === true ? 'view' : 'hidden'}
               onClick={() => this.togglePasswordVisibility()}
             />
           </div>
         </div>
       </>
     );
   }
}

PasswordField.propTypes = propTypes;

PasswordField.defaultProps = defaultProps;

export default PasswordField;
