/**
 * Generates WeeklyCalendar Header, Merging Calendar and Distributor Name
 */

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { getDateList } from '../date';
import { WeeklyStyled } from './WeeklyViewStyled';
import WeeklyHeader from './WeeklyHeader';
import { USER_ROLE_TITLE } from '../../../../data/enums/UserRole';
import { headerLabelConfig } from '../../../common/HelperFunctions';

const propTypes = {
  header: PropTypes.node,
  weekRange: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
};

const defaultProps = {
  header: (<span>DSE Horizon Distributors</span>),
  weekRange: {
    start: '2019-12-01',
    end: '2019-12-07',
  },
};

class WeeklyView extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const {
      header, weekRange, weekEndList, holidayList,
    } = this.props;

    const dateList = getDateList(weekRange.start, weekRange.end);

    return (
      <WeeklyStyled>
        <div className="calendar-top-header">
          <div className="title">
            <span>{headerLabelConfig[USER_ROLE_TITLE.DSE]}</span>
            <span className="label">{header}</span>
          </div>
          <div className="calendar-week-header">
            <div>
              {
                dateList.map(date => (
                  <WeeklyHeader
                    date={date}
                    weekEndList={weekEndList}
                    holidayList={holidayList}
                  />
                ))
              }
            </div>
          </div>
        </div>
      </WeeklyStyled>
    );
  }
}

WeeklyView.propTypes = propTypes;

WeeklyView.defaultProps = defaultProps;

export default WeeklyView;
