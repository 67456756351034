import React, { Component } from 'react';
import { isError } from '../../common/HelperFunctions';
import setMarkerImage from '../../configuration/arc/services/setMarkerImage';
import redMarker from '../dse-report/assets/ic_geolocation_red.svg';
import purpleMarker from '../dse-report/assets/ic_geolocation_purple.svg';
import withAlert from '../../../utils/composition/withAlert';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import { timeStampToTime } from '../../../utils/date';
import {
  getOffsetFromPagination,
  apiFilterProcessor,
} from '../../../utils/api';
/* eslint-disable */
class AttendanceReportMap extends Component {
  constructor(props) {
    super(props);

    const {
      location: {
        state: { center },
      },
    } = props;
    const mapCenter = {
      lat: center ? center.latitude : 0,
      lng: center ? center.longitude : 0,
    };

    this.state = {
      center: mapCenter,
      google: window.google,
      data: {
        list: [],
        total: 0,
      },
      infoWindow: new window.google.maps.InfoWindow(),
    };
  }

  componentDidMount() {
    this.loadMap();
  }

  loadData = (map) => {
    const { data } = this.state;
    const {
      listAttendanceHistory,
      location: {
        state: { queryParameters },
      },
    } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);

    listAttendanceHistory(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          queryString: queryParameters.search,
          dateRange: queryParameters.date,
        },
      },
      {
        handleSuccess: (response) => {
          data.list =
            (response.data.listAttendanceHistory &&
              response.data.listAttendanceHistory.rows) ||
            [];
          data.total =
            (response.data.listAttendanceHistory &&
              response.data.listAttendanceHistory.count) ||
            0;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState({ data }, () => {
            this.loadMarkersToMap(map);
          });
        },
        handleError: (error) => {
          this.onAPIRequestFailure(error);
        },
      }
    );
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  loadMap = () => {
    const { center, google } = this.state;
    const map = new google.maps.Map(document.getElementById('map'), {
      center,
      zoom: 15,
      mapTypeControl: false,
      streetViewControl: false,
    });
    this.loadData(map);
  };

  loadMarkersToMap = (map) => {
    const {
      data: { list },
      google,
    } = this.state;
    const {
      match: {
        params: { id },
      },
    } = this.props;

    list.forEach((item) => {
      const marker = new google.maps.Marker({
        position: {
          lat: parseFloat(item.location.latitude),
          lng: parseFloat(item.location.longitude),
        },
        map,
        icon: setMarkerImage(redMarker, null, null, 25, 25),
        class: 'marker',
        iconSize: (100, 100),
      });
      item.id === Number(id) &&
        marker.set('icon', setMarkerImage(purpleMarker, null, null, 25, 25));
      marker.addListener('click', () =>
        this.handleMarkerClick(marker, map, item)
      );
    });
  };

  handleMarkerClick = (marker, map, detail) => {
    const { infoWindow } = this.state;
    const {
      User: { fullName },
      login_time,
      logout_time,
    } = detail;
    infoWindow.close();
    const content = `<div>User: ${fullName}</div>
                    <div>Logged In: ${
                      login_time ? timeStampToTime(login_time / 1000) : ''
                    }</div>
                    <div>Logged Out: ${
                      logout_time ? timeStampToTime(logout_time / 1000) : ''
                    }</div>
                    `;
    infoWindow.setContent(content);
    infoWindow.open(map, marker);
  };

  render() {
    return (
      <>
        <div style={{ height: '100vh ', width: '100%' }} id='map' />
      </>
    );
  }
}

export default withAlert()(AttendanceReportMap);
