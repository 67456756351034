import gql from 'graphql-tag';
import { graphql } from 'react-apollo';


const GET_OUTSTANDING_LIST = gql`
    query getOutstandingsList($offset: Int, $limit: Int, $filter: FilterInput) {
        outstandingReports(offset: $offset, limit: $limit, filter: $filter) {
            rows {
                id
                title
                route: Route {
                    title
                    user: User {
                        fullName
                    }
                }
                billDetails: RetailOutletBillDetails {
                    outstanding
                    creditDays
                }
                transactionStatus
                distributor: Distributor {
                    title
                }
            }
            count
        }
    }
`;

const UPDATE_TRANSACTION_STATUS = graphql(
  gql`
    mutation($id: Int!,$transactionStatus:TransactionStatus){
        updateRetailOutletTransactionStatus(id:$id, transactionStatus:$transactionStatus){
        id
        title
        transactionStatus
      }
    }
  `, {
    props: ({ mutate }) => ({
      updateRetailOutletTransactionStatus: variables => mutate({
        variables,
      }),
    }),
  },
);


const query = {
  getOutstandingsList: GET_OUTSTANDING_LIST,
};

export { query, UPDATE_TRANSACTION_STATUS };
