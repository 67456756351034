import React from 'react';
import PropTypes from 'prop-types';
import TableHeader from '../../base/TableHeader';


const labelMappings = {
  skuId: 'skuId',
  skuTitle: 'skuTitle',
  subD: 'subD',
  openingBalance: 'openingBalance',
  inward: 'inward',
  outward: 'outward',
  adjustment: 'adjustment',
  closingBalance: 'closingBalance',
  damages: 'damages',
  shortages: 'shortages',
  expiry: 'expiry',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.skuId,
    title: 'SKU ID',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 2,
    label: labelMappings.skuTitle,
    title: 'SKU',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 3,
    label: labelMappings.subD,
    title: 'Sub D',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 4,
    label: labelMappings.openingBalance,
    title: 'Opening Balance',
    classNames: 'text-right',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 5,
    label: labelMappings.inward,
    classNames: 'text-right',
    title: 'Inward',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 6,
    label: labelMappings.outward,
    classNames: 'text-right',
    title: 'Outward',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 7,
    label: labelMappings.adjustment,
    classNames: 'text-right',
    title: 'Adjustment',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 8,
    label: labelMappings.closingBalance,
    classNames: 'text-right',
    title: 'Closing Balance',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 9,
    label: labelMappings.damages,
    classNames: 'text-right',
    title: 'Damaged',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 10,
    label: labelMappings.shortages,
    classNames: 'text-right',
    title: 'Shortages',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 10,
    label: labelMappings.expiry,
    classNames: 'text-right',
    title: 'Expired',
    show: true,
    sortable: false,
    className: '',
  },
];

const TableHeaderConfig = ({ onHeaderClick }) => (
  <TableHeader
    headerDetails={labelConfig}
    onHeaderClick={onHeaderClick}
  />
);

const returnTableBody = (data) => {
  const table = {};
  table[labelMappings.skuId] = <td key={`stockLedger-${data.skuId}-skuId`}>{data.skuId}</td>;
  table[labelMappings.skuTitle] = <td key={`stockLedger-${data.skuId}-title`}>{data.SKU ? data.SKU.title : ''}</td>;
  table[labelMappings.subD] = <td key={`stockLedger-${data.skuId}-distributor`}>{data.Distributor ? data.Distributor.title : ''}</td>;
  table[labelMappings.openingBalance] = <td key={`stockLedger-${data.skuId}-openingBalance`} className="text-right">{data.openingBalance}</td>;
  table[labelMappings.inward] = <td key={`stockLedger-${data.skuId}-inward`} className="text-right">{data.inward}</td>;
  table[labelMappings.outward] = <td key={`stockLedger-${data.skuId}-outward`} className="text-right">{data.outward}</td>;
  table[labelMappings.adjustment] = <td key={`stockLedger-${data.skuId}-adjustment`} className="text-right">{data.adjustment}</td>;
  table[labelMappings.closingBalance] = <td key={`stockLedger-${data.skuId}-closingBalance`} className="text-right">{data.closingBalance}</td>;
  table[labelMappings.damages] = <td key={`stockLedger-${data.skuId}-damages`} className="text-right">{data.damages}</td>;
  table[labelMappings.shortages] = <td key={`stockLedger-${data.skuId}-shortages`} className="text-right">{data.shortages}</td>;
  table[labelMappings.expiry] = <td key={`stockLedger-${data.skuId}-expiry`} className="text-right">{data.expiry}</td>;

  return table;
};

const TableBody = ({
  data,
}) => {
  // Expanding/Dragging and collapsing table body.
  // color based upon table body
  // tooltip if it is sorted.
  // active className
  const tableBody = returnTableBody(data);

  return (
    <tr key={`stockLedger-${data.skuId}-details`}>
      {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
};

TableBody.defaultProps = {
  data: {},
};

TableHeaderConfig.propTypes = {
  onHeaderClick: PropTypes.func.isRequired,

};
export {
  TableHeaderConfig as TableHeader,
  TableBody,
};
