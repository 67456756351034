import React, { Fragment } from 'react';
import { EVENT_OPERATION } from '../../../../../../data/enums/EventOperation';
import { Input } from '../../../../../../components';
import CustomSelect from '../../../../../../components/CustomSelect';
import FormContent from '../../../../../components/FormContent';
import { hierarchy } from '../config';
import ImageUploadSection from '../../../../../components/ImageUploadSection';
import { IMAGE_UPLOAD_DOMAIN } from '../../../../../../data/enums/GraphQL';

const DetailContent = ({ ...props }) => {
  const {
    data,
    menu,
    refsObj,
    onInputChange,
    enableErrorDisplay,
    handleDropDownChange,
    crudMode,
    catalogId,
    handleImagesFetch,
  } = props;

  const { catalog, catalogChildrens } = menu;
  if (catalog.length !== 0 && catalogChildrens.length !== 0) {
    const catalogIds = catalog.map(d => d.id);
    const hierarchyList = catalogIds.splice(0, catalogIds.indexOf(catalogId) || 1);
    const catalogChildrenFilter = (id) => {
      const filterCatalogChildren = catalogChildrens.filter(d => d.catalogId === id);
      const filterWithParentId = value => filterCatalogChildren
        .filter(d => d.parentCatalogDetailId === value);
      if (data.hierarchy.principal !== 0 && id === 2) {
        return filterWithParentId(data.hierarchy.principal);
      }
      if (data.hierarchy.product_group !== 0 && id === 3) {
        return filterWithParentId(data.hierarchy.product_group);
      }
      if (data.hierarchy.brand !== 0 && id === 4) {
        return filterWithParentId(data.hierarchy.brand);
      }
      if (data.hierarchy.market_catalog !== 0 && id === 5) {
        return filterWithParentId(data.hierarchy.market_catalog);
      }
      if (data.hierarchy.sub_brand !== 0 && id === 6) {
        return filterWithParentId(data.hierarchy.sub_brand);
      }
      if (data.hierarchy.sub_brand_form !== 0 && id === 7) {
        return filterWithParentId(data.hierarchy.sub_brand_form);
      }

      return filterCatalogChildren;
    };

    return (
      <Fragment>
        <div className={`user-form-wrap create-ui-inner tab-less-height
       ${crudMode === EVENT_OPERATION.READ ? 'disabled' : ''}`}
        >
          <FormContent title="General Details">
          <>
            <Input
              ref={ref => (refsObj.title = ref)}
              value={data.title}
              name="title"
              type="text"
              labelContent="Title"
              placeholder="Title"
              enableErrorDisplay={enableErrorDisplay}
              enableValidation
              onChange={event => onInputChange(event, 'title', ['title'])}
            />
            <Input
              value={data.alias}
              name="alias"
              type="text"
              labelContent="Alias"
              placeholder="Alias"
              onChange={event => onInputChange(event, 'alias', ['alias'])}
            />
            </>
          </FormContent>
          {catalogId !== 1 && (
          <FormContent title="Hierarchy">
            {hierarchyList.map(hierarchyId => (
            <>
              <CustomSelect
                key={hierarchyId}
                ref={(ref) => {
                  refsObj[hierarchy[hierarchyId].name] = ref;
                  return null;
                }}
                clearable={false}
                className="custom-select"
                placeholder="Select"
                labelContent={hierarchy[hierarchyId].label}
                getOptionLabel={({ title }) => title}
                getOptionValue={({ id }) => id}
                options={catalogChildrenFilter(hierarchyId)}
                enableValiation
                enableErrorDisplay={enableErrorDisplay}
                value={catalogChildrenFilter(hierarchyId).filter(d => d.id === data.hierarchy[hierarchy[hierarchyId].name])}
                onChange={(e) => { handleDropDownChange(e.id, ['hierarchy', hierarchy[hierarchyId].name]); }}
              />
            </>
            ))}

          </FormContent>
          )}
          <FormContent title="Images">
            <ImageUploadSection
              getImages={(images) => { handleImagesFetch(images); }}
              images={data.images}
              domain={IMAGE_UPLOAD_DOMAIN.CATALOGS}
            />
          </FormContent>
        </div>
      </Fragment>
    );
  }
  return null;
};

DetailContent.propTypes = {};

export default DetailContent;
