import styled from 'styled-components';

const DSEWeeklyViewStyled = styled.div` 
 
  .yellow-background {
    background: yellow;
  }
  
`;

export { DSEWeeklyViewStyled };
