const MESSAGE_EVENT_OPERATION = {
  CREATE: ' created successfully!',
  UPDATE: ' updated successfully!',
  DELETE: ' deleted successfully!',
  UPLOAD: ' uploaded successfully!',
  APPROVE: ' approved successfully!',
  REPLICATE: 'replicated sucessfully',
  BULK_DELETE: ' multiple records deleted successfully!',
  UPDATE_STATUS: ' status updated successfully!',
};

export { MESSAGE_EVENT_OPERATION };
