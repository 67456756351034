import gql from 'graphql-tag';
import { SKU_RATE_FRAGMENT } from '../../../configuration/sku/API';
import { PURCHASE_RETURN_FRAGMENT } from '../API';
import { SKU_TITLE_FRAGMENT } from '../../../common/GqlFragments';

const PRN_LINE_AMOUNT_FRAGMENT = gql`
  fragment PRNLineAmountFragment on PRNLineAmount {
    rate
    grossAmount
    netAmount
    discount
    billDiscount
    tradeDiscount
  }
`;

const PRN_LINES_FRAGMENT = gql`
  fragment PRNLinesFragment on PRNLines {
    id
    quantity
    stockType
    SKU {
      ...SkuFragment
    }
    SkuBatch {
      ...SkuRateFragment
    }
    amountDetails {
      ...PRNLineAmountFragment
    }
    GRNDetail {
      id
      expiryDate
      manufactureDate
    }
  }
  ${SKU_TITLE_FRAGMENT}
  ${SKU_RATE_FRAGMENT}
  ${PRN_LINE_AMOUNT_FRAGMENT}
`;

const GET_PRN_DETAILS = gql`
  query getPrnDetails($prnId: Int!) {
    prnDetails(prnId: $prnId) {
      ...PurchaseReturnFragment
      PRNDetails {
        ...PRNLinesFragment
      }
    }
  }
  ${PURCHASE_RETURN_FRAGMENT}
  ${PRN_LINES_FRAGMENT}
`;


const query = {
  getPrnDetails: GET_PRN_DETAILS,
};

export {
  PRN_LINE_AMOUNT_FRAGMENT,
  PRN_LINES_FRAGMENT,
  query,
};
