import { EVENT_OPERATION } from '../../../data/enums/EventOperation';

const formConfig = {
  mapper: element => ({
    id: element.id || '',
    title: element.title || '',
  }),
  validationField: ['title'],
  validationRequired: true,
};

const crudSuccess = {
  [EVENT_OPERATION.CREATE]: {
    objectMapper: data => ({ title: data.title }),
    responseName: 'create',
  },
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: data => ({
      id: data.id,
      title: data.title,
    }),
    responseName: 'update',
  },
  [EVENT_OPERATION.DELETE]: {
    objectMapper: data => ({
      id: data.id,
      title: data.title,
    }),
    responseName: 'delete',
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: data => ({
      id: data.id,
      title: data.title,
    }),
    responseName: 'update',
  },
};

export { crudSuccess, formConfig };
