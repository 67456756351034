import React from 'react';
import PropTypes from 'prop-types';
import { CheckBox, Icon } from '../../../components';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import withErrorAndLoadingScreen from '../../../utils/composition/withLoading';
import { getCurrentDay } from '../../../utils/date';

const propTypes = {
  checkedListLength: PropTypes.number,
  dataLength: PropTypes.number,
  onIconClick: PropTypes.func.isRequired,
};
const defaultProps = {
  checkedListLength: 0,
  dataLength: 0,
};

const BulkDeleteView = ({
  dataLength,
  onIconClick,
  checkedListLength,
  replicate,
  // onCancelClick,
  onReplicateClick,
  onReplicateTypeClick,
}) => (checkedListLength > 0 && (
  <div className="selected-status">
    <div className="content-center">
      <div>
        <CheckBox checked className="disabled-btn" />
        <p className="text">
          <span>{checkedListLength}</span>
          of
          <span>{dataLength}</span>
          items selected
        </p>
      </div>
      <div style={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
      >
        <div
          className="del-btn"
          onClick={() => onIconClick(EVENT_OPERATION.BULK_DELETE, {})}
        >
          <Icon iconName="trash" />
        </div>
        {replicate
      && (
      <div
        className="del-btn"
        onClick={() => {
          onReplicateClick(EVENT_OPERATION.REPLICATE, { startDate: getCurrentDay(), endDate: getCurrentDay() });
          onReplicateTypeClick(EVENT_OPERATION.REPLICATE);
        }}
      >
        <Icon iconName="copy" />
      </div>
      )}
      </div>
      {/* <div className="separator" />
      <div className="cancel-btn" onClick={() => onCancelClick()}>
        <Icon iconName="times" />
      </div> */}
    </div>
  </div>
)
);

BulkDeleteView.propTypes = propTypes;

BulkDeleteView.defaultProps = defaultProps;

const BulkDeleteWithErrorAndLoading = withErrorAndLoadingScreen(BulkDeleteView);

export default BulkDeleteWithErrorAndLoading;
