import {
  compose,
} from 'recompose';
import Detail from './Detail';
import withRequestTracker from '../../../../../utils/composition/withRequestTracker';
import {
  query,
  mutation,
  CREATE_USER,
  UPDATE_USER,
  TOGGLE_STATE,
  RESET_PASSWORD,
} from '../API';


const ComposedUserAccountDetail = compose(
  CREATE_USER,
  UPDATE_USER,
  TOGGLE_STATE,
  RESET_PASSWORD,
  withRequestTracker({
    query,
    mutation,
  }),
)(Detail);

export default ComposedUserAccountDetail;
