import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PanelStyled } from '../common/configuration';
import PageHeader from '../base/PageHeader';
import {
  breadCrumbConfig,
  title,
  formConfig,
} from './config';
import { isError } from '../common/HelperFunctions';
import { Button, Icon } from '../../components';
import UserProfileStyled from './UserProfileStyled';
import { EVENT_OPERATION, EVENT_OPERATION_MAPPER } from '../../data/enums/EventOperation';
import { DialogFormWrapper } from '../common';
import View from './View';
import ChangePassword from '../components/ChangePassword';
import withAlert from '../../utils/composition/withAlert';
import { ALERT_TYPE } from '../../data/enums/AlertType';


const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  getUserProfile: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};
class UserProfile extends Component {
  constructor(props) {
    const localData = JSON.parse(localStorage.getItem('rosia-detail'));

    super(props);
    this.state = {
      localData,
      data: {
        list: [],
        total: 0,
      },
      images: '',
      crudMode: EVENT_OPERATION.READ,
      showPassword: false,
      dialog: {
        type: '',
        element: '',
      },
    };
  }

  componentDidMount() {
    this.loadUserData();
  }

  togglePasswordVisibility = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  }

      handleIconClick = (type, element) => {
        this.setState({
          dialog: {
            type,
            element,
          },
        });
      };

    resetDialog = () => {
      this.setState({
        dialog: {
          type: '',
          element: '',
        },
      });
    };

     getStatus = (status, statusType) => {
       const { displayAlert } = this.props;
       displayAlert(ALERT_TYPE[statusType === 'err' ? 'CUSTOM_DANGER' : 'SUCCESS'], status);
     }

 getHeader = () => {
   const { crudMode } = this.state;
   if (crudMode === EVENT_OPERATION.READ) return title;

   return <><span>{EVENT_OPERATION_MAPPER[crudMode].toLowerCase()}</span> {title}</>;
 };

 onAPIRequestFailure = (error) => {
   const { displayAlert } = this.props;
   displayAlert(ALERT_TYPE.DANGER, error);
 };

 loadUserData = () => {
   const { getUserProfile } = this.props;
   const { data } = this.state;
   getUserProfile(
     {
       limit: 5000,
     }, {
       handleSuccess: (response) => {
         data.list = (response.data.users && response.data.users.rows) || [];
         data.total = (response.data.users && response.data.users.count) || 0;
         if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
         this.setState(data);
       },
       handleError: error => this.onAPIRequestFailure(error),
     },
   );
 }


    handleButtonCancel = () => {
      this.setState({
        crudMode: EVENT_OPERATION.READ,
      });
    };

    handleEditIconClick = () => {
      this.setState({
        crudMode: EVENT_OPERATION.UPDATE,
      });
    };

      handleButtonSubmit = () => {
        const {
          crudMode, localData, images, data,
        } = this.state;
        const { updateUser, displayAlert } = this.props;
        const filteredList = data.list.filter(d => d.id === localData.id);
        const userProfileImage = filteredList[0].profileImage;

        const updatedInput = {
          firstName: localData.firstName,
          lastName: localData.lastName,
          phoneNumber: localData.phoneNumber,
          email: localData.email,
          roleId: localData.roleId,
        };

        if (crudMode === EVENT_OPERATION.UPDATE) {
          updateUser(
            (userProfileImage === null) ? {
              id: localData.id,
              input: {
                ...updatedInput,
              },
            } : {
              id: localData.id,
              input: {
                ...updatedInput,
                profileImage: {
                  image: images || userProfileImage[0].image,
                  thumbnail: images || userProfileImage[0].image,
                },
              },
            }, {
              handleSuccess: () => {
                displayAlert(ALERT_TYPE.SUCCESS, 'Profile Updated Successfully');
                this.handleButtonCancel();
              },

              handleError: (error) => {
                this.onAPIRequestFailure(error);
              },
            },
          );
        }
      };


           handleImagesFetch = (images) => {
             this.setState({
               images,
             });
           }

           render() {
             const { serverResponseWaiting } = this.props;
             const {
               crudMode, data, dialog, images, localData,
             } = this.state;
             const filteredList = data.list.filter(d => d.id === localData.id);
             const { type, element } = dialog;
             const showEditButton = crudMode === EVENT_OPERATION.READ;

             return (
               <UserProfileStyled>
                 <div>
                   {type && (
                   <ChangePassword
                     data={element}
                     type={type}
                     resetDialog={this.resetDialog}
                     getStatus={this.getStatus}
                     id={localData.id}
                   />
                   )}
                   <div className="section-panel">
                     <div className="section-header">
                       <PanelStyled>
                         <div className="user-page-header">
                           <PageHeader
                             breadCrumb={breadCrumbConfig}
                             config={{
                               title: this.getHeader(),
                             }}
                           />
                           <div className="flex m-0">
                             {
                      !showEditButton ? (
                        <div>
                          <Button secondary small onClick={this.handleButtonCancel}>
                            <span>Cancel</span>
                          </Button>
                          <Button primary small onClick={this.handleButtonSubmit}>
                            <span>Save</span>
                          </Button>
                        </div>
                      ) : (
                        <Button
                          iconBtnSmall
                          secondary
                          onClick={this.handleEditIconClick}
                          className="ml-16"
                        >
                          <Icon iconName="pencil" />
                        </Button>)
    }
                           </div>
                         </div>
                       </PanelStyled>
                     </div>
                     <div className="section-content">
                       <View
                         data={filteredList}
                         showEditButton={showEditButton}
                         onIconClick={this.handleIconClick}
                         images={images}
                         handleImagesFetch={this.handleImagesFetch}
                         loading={serverResponseWaiting}
                       />

                     </div>
                   </div>

                 </div>
               </UserProfileStyled>
             );
           }
}

UserProfile.propTypes = propTypes;

UserProfile.defaultProps = defaultProps;

export default withAlert()(UserProfile);
