import styled from 'styled-components';

const SalesInvoiceStyled = styled.div`
.customer-info{
  padding-bottom:24px;
  border-bottom:1px solid #E7E7ED;
  .info{
    margin-bottom:24px;
    label{
      font-size:14px;
      line-height:21px;
      color:#272833;
      font-weight:600;
      margin-bottom:10px;
      display:flex;
    }
    span{
      font-size:16px;
      line-height:24px;
      color:#6B6C7E;
      font-weight:400;
    }
  }
}
.pad-top-8{
  padding-top:8px;
}
.invoice-view-wrap{
margin-top:20px;
}
.section-content.invoice .invoice-form{
  padding-top:40px;
  padding-bottom:16px;
  margin-top: 0;
}
.ledger-info{
  margin-top:11px;
  color: #6B6C7E;
}
.table-wrap{
  table{
    border:0;
    thead{
      th{
        border-right:0;
        border-left:0;
      }
    }
    tbody{
      td{
        border-right:0;
        border-left:0;
      }
    }
  }
}
  `;

export default SalesInvoiceStyled;
