import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { GET_CATALOG_DETAIL } from '../../../sku/createSku/API';

const ASSORTMENT_COUNT_BY_PRODUCT_HIERARCHY_FRAGMENT = gql`
    fragment AssortmentCountByProductHierarchyFragment on AssortmentCountByProductHiearachy {
        brands {
            id 
            count
        }
        skuFamilies : productPacks {
            id
            count
        }
        productGroups: productCategories {
            id 
            count
        }
    }
`;

const GET_ASSORTMENT_COUNT_BYPRODUCT_HIERARCHY = gql`
    query getAssortmentCountByProductHierarchy($distributorGroupId: Int!) {
        getDistributorProductHierarchyCount(distributorGroupId: $distributorGroupId) {
            ...AssortmentCountByProductHierarchyFragment
        }
    }
  ${ASSORTMENT_COUNT_BY_PRODUCT_HIERARCHY_FRAGMENT}
`;

const GET_DISTRIBUTOR_ASSORTMENT_SKUS = gql`
    query getDistributorAssortmentSkus($distributorGroupId: Int!, $brandId: Int!) {
        distributorSkuAssortments(distributorGroupId: $distributorGroupId, brandId: $brandId) {
            rows
        }
    }
`;

const TOGGLE_SKU_IN_SUBD_ASSORTMENT = graphql(gql`
    mutation ($distributorGroupId: Int!, $skuIdList: [Int!],  $status: Boolean!) {
        toggleSkuInDistributorAssortment(distributorGroupId: $distributorGroupId, 
            skuIdList: $skuIdList, status: $status
        ){
           count {
                 brands {
                     id
                     count
                 }
                 skuFamilies : productPacks {
                     id
                     count
                 }
                 productGroups: productCategories {
                     id
                     count
                 }
               }
           }
        }
`, {
  props: ({ mutate }) => ({
    toggleSkuInDistributorAssortment: variables => mutate({
      variables,
    }),
  }),
});

const query = {
  getCatalogDetails: GET_CATALOG_DETAIL,
  getDistributorAssortmentSkus: GET_DISTRIBUTOR_ASSORTMENT_SKUS,
  getAssortmentCountByProductHierarchy: GET_ASSORTMENT_COUNT_BYPRODUCT_HIERARCHY,
};
const mutation = {
  toggleSkuInDistributorAssortment: TOGGLE_SKU_IN_SUBD_ASSORTMENT,
};

export {
  query, mutation, TOGGLE_SKU_IN_SUBD_ASSORTMENT, ASSORTMENT_COUNT_BY_PRODUCT_HIERARCHY_FRAGMENT,
};
