import { graphql } from 'react-apollo/index';
import gql from 'graphql-tag';
import { INVOICE_FRAGMENT } from '../../API';

const INVOICE_ORDERS = graphql(gql`
mutation ($orderIds: [Int]!, $invoiceNumber: String, $customerId: Int, $tradeDiscountValue: Float) {
    invoiceOrders(orderIds: $orderIds, invoiceNumber: $invoiceNumber, customerId: $customerId, tradeDiscountValue: $tradeDiscountValue)
    {
      ...InvoiceFragment
        id
    }
  }
  ${INVOICE_FRAGMENT}
  `, {
  props: ({ mutate }) => ({
    invoiceOrders: variables => mutate({
      variables,
    }),
  }),
});

const query = {};

const mutation = {
  invoiceOrders: INVOICE_ORDERS,
};

export {
  INVOICE_ORDERS,
};

export { query, mutation };
