import {
  compose,
} from 'recompose';
import ReceivedDetail from './Detail';
import withRequestTracker from '../../../../../utils/composition/withRequestTracker';
import {
  query,
  mutation,
  UPDATE_ORDER,
  SPLIT_ORDER,
  CANCEL_ORDERS, REPLACE_ORDERS,
} from './API';

const ComposedReceivedDetail = compose(
  UPDATE_ORDER,
  SPLIT_ORDER,
  CANCEL_ORDERS,
  REPLACE_ORDERS,
  withRequestTracker({
    query,
    mutation,
  }),
)(ReceivedDetail);

export default ComposedReceivedDetail;
