import {
  compose,
} from 'recompose';
import BU from './BU';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  CREATE_BU, UPDATE_BU, query, mutation, CREATE_CALL_PROCEDURE,
} from './API';

const ComposedBU = compose(
  CREATE_BU,
  UPDATE_BU,
  CREATE_CALL_PROCEDURE,
  withRequestTracker({
    query,
    mutation,
  }),
)(BU);

export default ComposedBU;
