import styled from 'styled-components';

const SBDDetailStyled = styled.div`
  .modal-inner {
    max-width: 395px;
  }

  .sbd-top-wrap {
    margin: 0 0 24px;
    background: #fbfbfc;
  }

  .custom-date {
    label {
      display: inline-block;
      margin-bottom: 4px;
      color: #272833;
      font-size: 14px;
      font-weight: 600;
    }

    label + div {
      width: 100%;
    }

    .moment {
      padding: 11px 14px;
    }
  }

  .sbd-bottom-wrap {
    margin: 0;
    padding: 24px;
    background: #eff0f4;
    min-height: calc(100vh - 256px);
    
    
    .first {
      display: flex;
      align-items: center;
      position: relative;
      z-index: 15;

      .light {
        position: absolute;
        left: 155px;
        z-index: 9;
      }
    }

    .form-select-input {
      margin: 0 16px 0 0;
      width: 215px;
    }

    .info-text {
      display: inline-block;
      font-size: 10px;
      color: #6B6C7E;
      margin-left: 16px;
    }

    .sbd-bottom-inner {
      margin-top: 16px;
      display: flex;

      .sbd-panel {
        display: flex;
        align-items: flex-start;
        margin-right: 4px;

        &:last-child {
          margin-right: 0;
        }

        .info-text {
          margin-left: 16px;
          margin-top: 20px;
        }

        &.sbd-table {
          position: relative;

          .info-text {
            position: absolute;
            top: -24px;
            left: 24px;
            margin: 0;
          }

          .table-wrap {
            margin: 24px -24px;

            thead tr {

              td {
                position: sticky;
                top: 33px;
                background: #F1F2F5;
                z-index: 10;
              }

              td:last-child {
                padding-right: 24px;
              }
            }
          }

          .item-selected {
            position: sticky;
            top: 20px;
            z-index: 11;
            background: #F0F5FF;
            padding: 14px 24px 14px 24px;
            margin: 24px -24px 0;
            border-bottom: 1px solid #0B5FFF;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .text {
              color: #272833;
              font-size: 14px;
              line-height: 21px;
            }

            + .table-wrap {
              margin-top: 0;
            }
          }

          .sbd-panel-list {
            width: 100%;
          }
        }
      }

      table {
        th:first-child, td:first-child {
          width: 15%;
        }

        th:last-child, td:last-child {
          padding-right: 18px;
        }
      }

      .sbd-panel-list {
        padding: 24px;
        background: #fff;
        border-radius: 4px;
        width: 215px;
        height: calc(100vh - 234px);
        overflow-y: auto;

        ul {
          margin-top: 24px;
        }

        li {
          font-size: 12px;
          font-weight: 600;
          min-height: 36px;
          color: #6B6C7E;
          cursor: pointer;
          margin-left: -12px;
          margin-right: -12px;
          padding: 8px 12px;
          margin-bottom: 12px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &:hover, &:focus {
            color: #0b5fff;
          }

          .light {
            background-color: #F1F2F5;
          }

          &.active {
            background: #F7F8F9;
            border-radius: 4px;
            color: #272833;
            position: relative;

            .light {
              margin-right: 16px;
            }

            &:after {
              content: '';
              position: absolute;
              top: -18px;
              right: 12px;
              display: block;
              margin: 30px auto;
              width: 12px;
              height: 12px;
              border-top: 2px solid #0b5fff;
              border-left: 2px solid #0b5fff;
              transform: rotate(135deg);
              border-radius: 2px 2px 0 2px;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      h3 {
        position: sticky;
        top: -24px;
        background: #fff;
        margin: -24px;
        padding: 24px;
        font-size: 10px;
        line-height: 10px;
        font-weight: normal;
        text-transform: uppercase;
        color: #6B6C7E;
        z-index: 10;
      }
    }
  }

  .sku-group {
    &:nth-child(1n-1) {
      td {
        border-bottom: 0;
      }
    }
  }


  /* Focuse sku styling start */

  .sbd-bottom-wrap .sbd-bottom-inner {
    .sbd-panel.sbd-table {
      position: unset;

      .sbd-panel-list {
        padding: 0;
        height: unset;
        overflow: unset;
        width: unset;
        width: 100%;

        .sbd-detail-table {
          .table-wrap {
            margin: 0;
            background-color: #fff;
            max-height: unset;
            height: calc(100vh - 300px);
            overflow: scroll;
            border-radius: 0 0 4px 4px;

            table {
              position: relative;
              border: 0;

              thead {
                tr {
                  height: 48px;

                  th {
                    top: 0;
                    position: sticky;
                    z-index: 2;
                  }
                }
              }

              tr {
                height: 56px;

                th, td {
                  width: unset;
                  min-width: 62px;
                  white-space: nowrap;

                  &:first-child {
                    padding-left: 24px;
                  }

                  &:last-child {
                    padding-right: 24px;
                    width: 50%;
                  }
                }
              }
            }
          }

          .item-selected + .table-wrap {
            height: calc(100vh - 369px);
          }
        }
      }

      .dropdown-head {
        display: flex;
        justify-content: space-between;
        padding: 17px 24px;

        h3 {
          top: 0;
          margin: 0;
          padding: 0;
          margin-top: 6px !important;
        }
      }

      .item-selected {
        margin: 0;
      }

    }

}
`;

export default SBDDetailStyled;
