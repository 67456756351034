import styled from 'styled-components';

const SubDStyled = styled.div`

.section-panel{
  .section-header{
    button.iconBtnSmall{
      margin-right:16px;
      &:last-child{
        margin-right:0 ;
      }
    }
  }
  .section-content{
  #sub-d-tab{
  .panel-section{
    padding:32px 48px;
    .panel{
      ul{
        height: calc(100vh - 278px);
      }
    }
  }
  }
  }
}

`;

export default SubDStyled;
