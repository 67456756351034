import { FILTER } from '../../../data/enums/filter';
import { getFilterConfig } from '../../../utils/filter';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../data/enums/SuccessMessage';
import { login as loginRoute, test as testRoute } from '../../../data/enums/Route';
import { configuration as configurationRoute } from '../../../data/enums/Route';
import { STATEFUL_ENTITIES } from '../../../data/enums/GraphQL';

const breadCrumb = [
  configurationRoute,
  {
    id: 1,
    title: 'Channel',
    path: '#',
    active: true,
  },
];

const Title = 'Channel';
const CategoryTitle = 'Category';

const CH_CAT_ENUMS = {
  CHANNEL: 'CHANNEL',
  CATEGORY: 'CATEGORY',
};

const filter = getFilterConfig([FILTER.SUB_D, FILTER.TERRITORY]);

const formConfig = {
  [CH_CAT_ENUMS.CHANNEL]: {
    mapper: element => ({
      id: element.id || '',
      title: element.title || '',
      channelType: element.channelType || '',
    }),
    title: 'Channel',
    validationField: ['title', 'channelType'],
    validationRequired: true,
  },
  [CH_CAT_ENUMS.CATEGORY]: {
    mapper: (element) => {
      if (element.channelId) {
        return {
          id: element.id || '',
          title: element.title || '',
          channelId: (element.channelId || ''),
        };
      }

      return {
        title: '',
        channelId: element.id,
      };
    },
    title: 'category',
    validationField: ['title', 'channelId'],
    validationRequired: true,
  },
};

const crudSuccess = {
  [CH_CAT_ENUMS.CHANNEL]: {
    [EVENT_OPERATION.CREATE]: {
      objectMapper: data => ({
        title: data.title,
        channelType: data.channelType,
      }),
      responseName: 'createChannel',
      message: `${Title} ${MESSAGE_EVENT_OPERATION.CREATE}`,
    },
    [EVENT_OPERATION.UPDATE]: {
      objectMapper: data => ({
        id: data.id,
        title: data.title,
        channelType: data.channelType,
      }),
      responseName: 'updateChannel',
      message: `${Title} ${MESSAGE_EVENT_OPERATION.UPDATE}`,
    },
    [EVENT_OPERATION.DELETE]: {
      objectMapper: data => ({
        ids: [data.id],
        type: STATEFUL_ENTITIES.CHANNEL,
        active: false,
      }),
      responseName: 'toggleState',
      message: `${Title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
    },
    [EVENT_OPERATION.BULK_DELETE]: {
      objectMapper: data => ({
        ids: [data.id],
        type:  STATEFUL_ENTITIES.CHANNEL,
        active: false,
      }),
      responseName: 'toggleState',
      message: `${Title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
    },
  },
  [CH_CAT_ENUMS.CATEGORY]: {
    [EVENT_OPERATION.CREATE]: {
      objectMapper: (element) => {
        if (element.channelId) {
          return {
            id: element.id || '',
            title: element.title || '',
            channelId: (element.channelId || ''),
          };
        }

        return {
          title: '',
          channelId: element.id,
        };
      },
      responseName: 'createCategory',
      message: `${CategoryTitle} ${MESSAGE_EVENT_OPERATION.CREATE}`,
    },
    [EVENT_OPERATION.UPDATE]: {
      objectMapper: data => ({
        id: data.id,
        title: data.title,
        channelId: data.channelId,
      }),
      responseName: 'updateCategory',
      message: `${CategoryTitle} ${MESSAGE_EVENT_OPERATION.UPDATE}`,
    },
    [EVENT_OPERATION.DELETE]: {
      objectMapper: data => ({
        ids: [data.id],
        type: STATEFUL_ENTITIES.CATEGORY,
        active: false,
      }),
      responseName: 'toggleState',
      message: `${CategoryTitle} ${MESSAGE_EVENT_OPERATION.DELETE}`,
    },
    [EVENT_OPERATION.BULK_DELETE]: {
      objectMapper: data => ({
        ids: [data.id],
        type: STATEFUL_ENTITIES.CATEGORY,
        active: false,
      }),
      responseName: 'toggleState',
      message: `${CategoryTitle} ${MESSAGE_EVENT_OPERATION.DELETE}`,
    },
  },

};

export {
  crudSuccess, breadCrumb as breadCrumbConfig, formConfig, CH_CAT_ENUMS, filter as filterConfig
};
