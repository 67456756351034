import { STATUS, USER_ROLE } from '../../data/enums';
import { DISTRIBUTOR_SERVICES } from '../common/DomainConfig';
import { DOMAIN } from '../../data/enums/config';
import {
  filterModuleWisePermission,
} from '../../data/services/permission';
import { PROGRAM } from '../../data/enums/Permission';
import { getUserRole } from '../../data/dao';

const getPermissionObject = (create, read, update, remove, approve) => ({
  CREATE: create,
  READ: read,
  UPDATE: update,
  DELETE: remove,
  APPROVE: approve,
});

const getDistributorEnabledServices = (distributor) => {
  const distributorEnabledService = [];
  const servicesObj = distributor.servicesUsed || {};
  Object.keys(servicesObj).forEach((service) => {
    if (servicesObj[service].status) distributorEnabledService.push(service);
  });

  return distributorEnabledService;
};

const getDistributorServiceBasedSRNPermission = (distributorServices, userRole) => {
  const logisticsAndDeliveryDisabledDistributor = !(distributorServices.includes(
    DISTRIBUTOR_SERVICES.DELIVERY || DISTRIBUTOR_SERVICES.LOGISTICS,
  ));
  const permittedUserRoleForCUD = distributorServices.includes(DISTRIBUTOR_SERVICES.BILLING)
    ? [USER_ROLE.DISTRIBUTOR_ADMIN, USER_ROLE.LI] : [];

  return permittedUserRoleForCUD.includes(userRole) && logisticsAndDeliveryDisabledDistributor;
};

const distributorBasedPermission = (domain, distributor, userRole) => {
  const distributorServices = getDistributorEnabledServices(distributor);
  switch (domain) {
    case DOMAIN.SALES_RETURN:
      const permission = getDistributorServiceBasedSRNPermission(distributorServices, userRole);
      return {
        ...getPermissionObject(permission, permission, permission, permission),
        BILLING: distributorServices.includes(DISTRIBUTOR_SERVICES.BILLING),
        LOGISTICS: distributorServices.includes(DISTRIBUTOR_SERVICES.LOGISTICS),
        DELIVERY: distributorServices.includes(DISTRIBUTOR_SERVICES.DELIVERY),
      };

    default: return {};
  }
};

const getPermissionToCreateOutlet = () => {
  const userRole = getUserRole();
  const permittedUserRole = [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN, USER_ROLE.LEADERSHIP];

  return permittedUserRole.includes(userRole);
};

const getPermissionToUpdateLeaveRequest = (leaveRequest) => {
  if (leaveRequest.status !== STATUS.PENDING) {
    return false;
  }

  return true;
};

const getPermissionForBillingUser = (userRole, distributor) => {
  const isSubDAdmin = userRole === USER_ROLE.DISTRIBUTOR_ADMIN;

  // if distributor.servicesUsed.billing.status === true
  const billingUsed = distributor && distributor.servicesUsed && distributor.servicesUsed.billing && distributor.servicesUsed.billing.status;

  return getPermissionObject(
    isSubDAdmin && billingUsed,
    isSubDAdmin && billingUsed,
    isSubDAdmin && billingUsed,
    isSubDAdmin && billingUsed,
  );
};

const getPermissionForSalesInvoice = () => {
  const userRole = getUserRole();

  const permittedUserRole = [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN, USER_ROLE.MIS_ADMIN,
    USER_ROLE.MIS_ASSOCIATE, USER_ROLE.DISTRIBUTOR_ADMIN, USER_ROLE.STL];
  const enableCrudPermission = permittedUserRole.includes(userRole);

  return {
    ...getPermissionObject(
      enableCrudPermission,
      enableCrudPermission,
      enableCrudPermission,
      enableCrudPermission,
    ),
    INVOICE: enableCrudPermission,
  };
};

const getPermissionForLeaveRequest = () => filterModuleWisePermission(PROGRAM.LEAVE_REQUEST);
const getPermissionForOrderFulfilment = () => filterModuleWisePermission(PROGRAM.ORDER_FULLFILLMENT);
const getPermissionForOutlet = () => filterModuleWisePermission(PROGRAM.OUTLET);
const getPermissionForRoute = () => filterModuleWisePermission(PROGRAM.ROUTE);

const getPermissionForGrn = () => filterModuleWisePermission(PROGRAM.GRN);
const getPermissionForOpeningBalance = () => filterModuleWisePermission(PROGRAM.OPENING_BALANCE);
const getPermissionForPrn = () => filterModuleWisePermission(PROGRAM.PURCHASE_RETURN);
const getPermissionForSrn = () => filterModuleWisePermission(PROGRAM.SALES_RETURN);
const getPermissionForStockLedger = () => filterModuleWisePermission(PROGRAM.STOCK_LEDGER);

const getPermissionForBuSettings = () => filterModuleWisePermission(PROGRAM.BU_SETTINGS);
const getPermissionForChannelCategory = () => filterModuleWisePermission(PROGRAM.CHANNEL_CATEGORY);
const getPermissionForFocusedSku = () => filterModuleWisePermission(PROGRAM.FOCUSED_SKU);
const getPermissionForMerchandisingDevices = () => filterModuleWisePermission(PROGRAM.MERCHANDISING_DEVICE);
const getPermissionForPromotion = () => filterModuleWisePermission(PROGRAM.PROMOTION);
const getPermissionForSbd = () => filterModuleWisePermission(PROGRAM.SBD);
const getPermissionForSKU = () => filterModuleWisePermission(PROGRAM.SKU);
const getPermissionForSubD = () => filterModuleWisePermission(PROGRAM.SUB_D);
const getPermissionForTerritory = () => filterModuleWisePermission(PROGRAM.TERRITORY);
const getPermissionForTown = () => filterModuleWisePermission(PROGRAM.TOWN);
const getPermissionForUserAccount = () => filterModuleWisePermission(PROGRAM.USER_ACCOUNT);
const getPermissionForUserRole = () => filterModuleWisePermission(PROGRAM.USER_GROUP);
const getPermissionForStockAdjustment = () => filterModuleWisePermission(PROGRAM.STOCK_ADJUSTMENT);

const getPermissionForSubDAssortment = () => filterModuleWisePermission(PROGRAM.SUBD_ASSORTMENT);
const getPermissionForSubDReplenishment = () => filterModuleWisePermission(PROGRAM.SUBD_REPLENISHMENT);
const getPermissionForCatalog = () => filterModuleWisePermission(PROGRAM.CATALOG);
const getPermissionForPayment = () => filterModuleWisePermission(PROGRAM.ORDER_FULLFILLMENT);


/* const getModuleWisePermission = (module) => {
  switch (module) {
    case PROGRAM.TOWN:
    case PROGRAM.TERRITORY:
      return  filterModuleWisePermission(module);
    case DOMAIN.TERRITORY: return  filterModuleWisePermission(DOMAIN.TERRITORY);
  }
} */


export {
  getPermissionForBillingUser,
  getPermissionToCreateOutlet,
  getPermissionToUpdateLeaveRequest,

  getPermissionForPrn,
  getPermissionForRoute,
  getPermissionForSrn,
  getPermissionForSbd,
  getPermissionForGrn,
  getPermissionForTown,
  getPermissionForSKU,
  getPermissionForSubD,
  getPermissionForOutlet,
  getPermissionForUserRole,
  getPermissionForTerritory,
  getPermissionForPromotion,
  getPermissionForBuSettings,
  getPermissionForFocusedSku,
  getPermissionForUserAccount,
  getPermissionForStockLedger,
  getPermissionForSalesInvoice,
  getPermissionForLeaveRequest,
  getPermissionForOpeningBalance,
  getPermissionForChannelCategory,
  getPermissionForOrderFulfilment,
  getPermissionForStockAdjustment,
  getPermissionForSubDAssortment,
  getPermissionForMerchandisingDevices,
  getPermissionForSubDReplenishment,
  getDistributorEnabledServices,
  getPermissionForCatalog,
  getPermissionForPayment,
};
