import React, { Component } from 'react';
import { ALERT_TYPE } from '../../data/enums/AlertType';
import withErrorAndLoadingScreen from '../../utils/composition/withLoading';
import withAlert from '../../utils/composition/withAlert';
import { isObjectEmpty } from '../../utils/objectPrototypes';
import {
  isAdminUser,
  dataMapper,
  getUserWiseView,
  randomColor,
  roleBasedUserStatus,
  MODULES,
} from './config';
import { getUser } from '../../data/dao';
import { convertObjToArray } from '../../utils/objectProcessor';
import View from './View';
import {
  PFD_SALES_TARGET,
  PRODUCTIVE_COVERAGE,
  TARGET_ACHIEVEMENT_REPORT,
} from '../../data/enums/Route';
import { getFromLocalStorage } from '../common/HelperFunctions';
import { JWT } from '../../environment';

const propTypes = {};

const defaultProps = {
  serverResponseWaiting: false,
};

class Dashboard extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    const sbd = getFromLocalStorage(JWT.LOCAL_STORAGE.BU_SETTINGS) && getFromLocalStorage(JWT.LOCAL_STORAGE.BU_SETTINGS).sbd;
    const dimension = 220;
    this.userInfo = getUser();
    const margin = {
      top: 80,
      left: 80,
      bottom: 80,
      right: 80,
    };
    this.state = {
      dimensions: {
        height: dimension,
        width: dimension,
        margin,
      },
      horizontalBarChart: {
        height: 260,
        width: 450,
        margin,
      },
      filter: 'MTD',
      data: dataMapper({}, this.userInfo.roleId),
      serverData: {},
      syncTime: ' ',
      displayGoldenPoint: sbd,
    };
    this.userWiseView = getUserWiseView(this.userInfo.roleId);
    this.adminUser = isAdminUser(this.userInfo.roleId);
  }

  componentDidMount() {
    this.loadData();
    this.lastSyncTime();
  }

  lastSyncTime = () => {
    const { getLastSyncTime } = this.props;
    getLastSyncTime({}, {
      handleSuccess: (response) => {
        if (response.data) {
          const { getLastSyncTime = ' ' } = response.data;
          const lastPart = (getLastSyncTime && getLastSyncTime.split(' ').pop()) || '';
          this.setState({ syncTime: lastPart });
        }
      },
      handleError: err => this.onAPIRequestFailure(err),
    });
  };

  loadData = () => {
    const { getDashboardInfo, getSubDDashboardInfo } = this.props;

    /** todo : need to fix api issue for monthly detail and user detail in sub dashboard api * */

    if (this.adminUser) {
      getDashboardInfo({
        ...this.userWiseView,
      }, {
        handleSuccess: (response) => {
          if (response.data) {
            const { salesForceDashboard = {} } = response.data;
            this.setState({
              serverData: salesForceDashboard || {},
              data: this.assignDataFormatter(salesForceDashboard || {}),
            });
            // setTimeout(() => {
            //   this.loadData();
            // }, 27000);
          }
        },
        handleError: err => this.onAPIRequestFailure(err),
      });
    } else {
      getSubDDashboardInfo({}, {
        handleSuccess: (response) => {
          if (response.data) {
            const { subdDashboard = {} } = response.data;
            this.setState({
              serverData: subdDashboard || {},
              data: this.assignDataFormatter(subdDashboard || {}),
            });
            // setTimeout(() => {
            //   this.loadData();
            // }, 27000);
          }
        },
        handleError: err => this.onAPIRequestFailure(err),
      });
    }
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  //  loadUserData = () => {
  //    const { getUser } = this.props;
  //    const { userData } = this.state;
  //    getUser(
  //      {
  //        limit: 5000,
  //      }, {
  //        handleSuccess: (response) => {
  //          userData.list = (response.data.users && response.data.users.rows) || [];
  //          userData.total = (response.data.users && response.data.users.count) || 0;
  //          //  if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
  //          this.setState(userData);
  //        },
  //        handleError: error => this.onAPIRequestFailure(error),
  //      },
  //    );
  //  }

  assignDataFormatter = (data) => {
    // return dataMapper(data);

    if (!isObjectEmpty(data)) {
      if (this.adminUser) {
        return { ...data, ...this.adminDataFormatter(data) };
      }

      return { ...dataMapper(data), ...this.subDDataFormatter(data) };
    }
    return dataMapper(data);
  };

  subDDataFormatter = (data) => {
    const formattedData = { ...data };
    /** todo: format data if required in here * */
    /** test purpose * */
    formattedData.monthlyDetail = data.targetDetails;
    formattedData.dseDetails = {
      rows: data.dseDetails.rows.map(d => ({
        ...d,
        ...{ color: randomColor() },
      })),
      total: data.dseDetails.total,
    };
    formattedData.inventoryDetails = data.inventoryDetails;
    formattedData.deliveryDetails = data.deliveryDetails;
    formattedData.displayGoldenPoint = data.displayGoldenPoint;
    return formattedData;
  };

  adminDataFormatter = (data) => {
    const formattedData = { ...data };
    formattedData.sales = convertObjToArray(data.sales) || [];
    formattedData.callage = convertObjToArray(data.callage) || [];
    formattedData.monthlyDetail = data.monthlyDetail || {};
    formattedData.userStatus = roleBasedUserStatus(data, this.userInfo.roleId);

    return formattedData;
  };

  handleModuleClick = (module) => {
    const { history } = this.props;
    switch (module) {
      case MODULES.TARGET_ACHIEVEMENT: history.push(`${TARGET_ACHIEVEMENT_REPORT}`);
        break;
      case MODULES.PFD_SALES_TARGET: history.push(`${PFD_SALES_TARGET}`);
        break;
      case MODULES.PRODUCTIVE_COVERAGE: history.push(`${PRODUCTIVE_COVERAGE}`);
        break;

      default: break;
    }
  };

  // profileImage=() => {
  //   const { data, userData } = this.state;
  //   const filteredList = userData.list.filter(d => d.id === data.dseDetails.rows.id);
  //   console.log(filteredList, 'FL');
  // }

  render() {
    const {
      data, dimensions, syncTime, displayGoldenPoint,
    } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <View
        userWiseView={this.userWiseView}
        data={data}
        dimensions={dimensions}
        loading={serverResponseWaiting}
        handleModuleClick={this.handleModuleClick}
        syncTime={syncTime}
        displayGoldenPoint={displayGoldenPoint}
      />
    );
  }
}

Dashboard.propTypes = propTypes;

Dashboard.defaultProps = defaultProps;

const LoadingDashboard = withErrorAndLoadingScreen(Dashboard);

export default withAlert()(LoadingDashboard);
