import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import {
  formConfig,
  updateSkuLine,
  totalViewRefs,
  updateDamageImages,
  removeDamageImage, updateRate,
} from './config';
import Form from './Form';
import { TableView, TotalView } from './View';
import { DialogFormWrapper } from '../../../../common';
import withAlert from '../../../../../utils/composition/withAlert';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { filterItems, findAndReplaceItem } from '../../../../../utils/arrayProcessor';
import { handleSkuBatchSelect, isConfirmationType } from '../../../../common/HelperFunctions';
import Confirmation from '../../../../common/DialogConfirmation';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';

const propTypes = {
  update: PropTypes.instanceOf(Object),
  data: PropTypes.object.isRequired,
  enableErrorDisplay: PropTypes.bool,
  skuList: PropTypes.instanceOf(Array),
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  update: {
    type: '',
    status: false,
  },
  skuList: [],
  enableErrorDisplay: false,
  serverResponseWaiting: false,
};

class Table extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    return { data: nextProps.data };
  }


  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      data: {
        lines: [],
        amount: {},
      },
      dialog: {
        type: '',
        element: '',
      },
      skuBatchList: [],
    };
  }

  componentDidMount() {
    const { getStatus, getDetails, update } = this.props;
    getStatus(this.getValidationStatus);
    getDetails(this.exportData);
  }

  handleFormSubmit = (type, dialogData) => {
    if (type === EVENT_OPERATION.DELETE) {
      this.handleRowDelete(dialogData);
    } else {
      this.handleDataUpdate(dialogData);
    }
  };

  handleDataUpdate = (dialogData) => {
    const { data } = this.state;
    const modifiedDialogData = this.modifyData(dialogData);
    if (modifiedDialogData.id) {
      data.lines = findAndReplaceItem(data.lines, modifiedDialogData);
    } else {
      modifiedDialogData.id = 1000 + data.lines.length + 1;
      data.lines.push(modifiedDialogData);
    }
    this.setState({ data }, () => this.calculateLineTotal());
  };

  handleRowDelete = (dialogData) => {
    const { data } = this.state;
    const index = data.lines.findIndex(item => item.id === dialogData.id);
    data.lines.splice(index, 1);
    this.setState({ data }, () => this.calculateLineTotal());
  };


  modifyData = (dialogData) => {
    const { skuList } = this.props;
    const { skuBatchList, data } = this.state;
    const selectedSku = filterItems(skuList, dialogData.skuId)[0] || {};
    const selectedBatch = filterItems(skuBatchList, dialogData.skuBatchId)[0] || {};
    const updateDialogData = updateSkuLine(dialogData, selectedSku, selectedBatch);
    return updateDialogData;
  };

  handleIconClick = (type, element = {}) => {
    let rates = [];
    if (element.skuId) {
      const { skuList } = this.props;
      const selectedSku = filterItems(skuList, element.skuId)[0] || {};
      rates = selectedSku.Rates || [];
    }


    this.setState({
      dialog: {
        type,
        element,
      },
      skuBatchList: rates,
    });
  };

  onDropDownChange = (item, value, state, stateUpdater) => {
    const { skuList } = this.props;
    const { skuBatchList } = this.state;

    if (item === 'skuId') {
      const selectedSku = filterItems(skuList, value)[0] || {};
      const { Rates = [] } = selectedSku;
      this.setState({ skuBatchList: Rates }, () => {
        if (Rates.length > 0) {
          state.skuBatchId = Rates[0].id;
          stateUpdater(state);
          handleSkuBatchSelect(Rates[0].id, Rates, state, stateUpdater, updateRate);
        }
      });
    }
    if (item === 'skuBatchId') {
      handleSkuBatchSelect(value, skuBatchList, state, stateUpdater, updateRate);
    }
  };

  /** todo configure payload and response as per image upload api * */

  uploadImage = (file, state, stateUpdater) => {
    const { imageUploader, displayAlert } = this.props;
    imageUploader(file, {
      successCallBack: (response) => {
        updateDamageImages(state, stateUpdater, response.imageURL);
      },
      failureCallBack: (error) => {
        /** *todo remove  updateDamageImage from here */
        updateDamageImages(state, stateUpdater, error.message);
        // displayAlert(ALERT_TYPE.DANGER, error)
      },
    });
  };

  handleDiscountChange = (e) => {
    const { data } = this.state;
    data.amount[e.target.name] = e.formattedValue;
    this.calculateTotal(data);
  };

  calculateTotal = (data) => {
    const taxableAmount = data.amount.subTotal
      - data.amount.billDiscount - data.amount.tradeDiscount;
    const vat = 0.13 * taxableAmount;
    data.amount.taxAmount = vat;
    data.amount.taxableAmount = taxableAmount;
    data.amount.total = taxableAmount + vat;
    this.setState({ data });
  };

  calculateLineTotal = () => {
    const { data } = this.state;
    let subTotal = 0;
    data.lines.map(item => subTotal += item.priceDetails.netAmount);
    data.amount.subTotal = subTotal;
    this.calculateTotal(data);
  };

  resetDialog = () => {
    this.setState({
      dialog: {
        type: '',
        element: '',
      },
    });
  };

  getValidationStatus = () => {
    const { data } = this.state;
    const { displayAlert } = this.props;
    if (data.lines.length > 0) {
      return (!Object.values(totalViewRefs)
        .find(item => (item.getValidState() === false)));
    }
    displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'No Sku lines added');
    return false;
  };

  exportData = () => {
    const { data } = this.state;
    return data;
  };

  render() {
    const {
      data,
      dialog,
      skuBatchList,
    } = this.state;
    const { type } = dialog;
    const {
      update,
      skuList,
      enableErrorDisplay,
      serverResponseWaiting,
    } = this.props;

    const skuLines = data.lines || [];
    const priceDetails = data.amount || {};

    return (
      <Fragment>
        <div className="return-create">
          {type && (
            <DialogFormWrapper
              formConfig={formConfig[type]}
              dialogElement={dialog.element}
              onDialogSubmit={this.handleFormSubmit}
              onDialogCancel={this.resetDialog}
              type={type}
              renderDialog={
                ({
                  refsObj,
                  dialogData,
                  handleInputChange,
                  enableErrorDisplay,
                  handleMultipleUpload,
                  handleFormSubmit,
                  handleFileUpload,
                  handleDropDownChange,
                  getFormValidationStatus,
                  updateState,
                  getState,
                }) => (
                  <Fragment>
                    {
                      (type === EVENT_OPERATION.UPDATE || type === EVENT_OPERATION.CREATE) && (
                        <Form
                          show
                          update={update}
                          refsObj={refsObj}
                          skuList={skuList}
                          data={dialogData}
                          skuBatchList={skuBatchList}
                          loading={serverResponseWaiting}
                          uploadCallBack={this.uploadImage}
                          getState={getState}
                          updateState={updateState}
                          onUploadRemove={removeDamageImage}
                          handleInputChange={handleInputChange}
                          enableErrorDisplay={enableErrorDisplay}
                          dropDownCallBack={this.onDropDownChange}
                          handleDropDownChange={handleDropDownChange}
                          handleMultipleUpload={handleMultipleUpload}
                        />
                      )
                    }
                    {
                      isConfirmationType(type)
                      && Confirmation(type, 1)
                    }
                  </Fragment>
                )}
            />
          )}
          <TableView
            update={update}
            skuLines={skuLines}
            onIconClick={this.handleIconClick}
          />
          <TotalView
            update={update}
            refsObj={totalViewRefs}
            priceDetails={priceDetails}
            enableErrorDisplay={enableErrorDisplay}
            onInputChange={this.handleDiscountChange}

          />
        </div>
      </Fragment>

    );
  }
}

Table.propTypes = propTypes;

Table.defaultProps = defaultProps;

export default withAlert()(Table);
