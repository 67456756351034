import React, { Fragment } from 'react';
import withErrorAndLoadingScreen from '../../../../utils/composition/withLoading';
import { TableHeader, TableBody } from './tableConfig';
import withImageSlider from '../../../../utils/composition/withImageSlider';
import ReceiptWrapper from './ReceiptWrapper';
import ReportTableStyled from '../../../reports/ReportTableStyled';
import { Pagination } from '../../../../components';

const ReceiptView = ({ ...props }) => {
  const {
    handleSliderOpen, tableConfig, pagination, onPageChange, data,
  } = props;
  return (
    <Fragment>
      <ReceiptWrapper>
        <ReportTableStyled>
          <div className="table-wrap">
            <table>
              { TableHeader(tableConfig) }
              <tbody>
                {data.list.map(receipts => (
                  TableBody({
                    data: receipts,
                    handleSliderOpen,
                    tableConfig,
                  })
                ))}
              </tbody>
            </table>
          </div>
        </ReportTableStyled>
      </ReceiptWrapper>
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </Fragment>
  );
};

const ReceiptViewWithErrorAndLoading = withErrorAndLoadingScreen(ReceiptView);

const ReceiptViewWithImageSlider = withImageSlider(ReceiptViewWithErrorAndLoading);

export default ReceiptViewWithImageSlider;
