import React, { Component, Fragment } from 'react';
import { TableBody, TableHeader } from '../tableConfig';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import withImageSlider from '../../../../../utils/composition/withImageSlider';
import SplitPayment from '../../../../components/SplitPayment';
import { Pagination } from '../../../../../components';
import withErrorAndLoadingScreen from '../../../../../utils/composition/withLoading';
import ReportTableStyled from '../../../../reports/ReportTableStyled';
import BulkSelect from '../../../../components/BulkSelect';
import withAlert from '../../../../../utils/composition/withAlert';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';

class Received extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: {
        type: '',
        element: '',
      },
    };
  }

    handleIconClick = (type, element = {}) => {
      this.setState({
        dialog: {
          type,
          element,
        },
      });
    };

      resetDialog = () => {
        this.setState({
          dialog: {
            type: '',
            element: '',
          },
        });
      };

      getStatus = (status, statusType) => {
        const { displayAlert } = this.props;
        displayAlert(ALERT_TYPE[statusType === 'err' ? 'INFO' : 'SUCCESS'], status);
      }

      render() {
        const {
          dialog,
        } = this.state;
        const { type } = dialog;
        const {
          tableConfig, received, pagination, onPageChange, handleSliderOpen, fetchAllData, permission,
        } = this.props;
        return (
          <Fragment>
            <ReportTableStyled>
              {type && (
              <SplitPayment
                data={dialog.element}
                type={type}
                resetDialog={this.resetDialog}
                fetchAllData={fetchAllData}
                getStatus={this.getStatus}
              />
              )}
              <BulkSelect
                data={received.list}
                fetchAllData={fetchAllData}
                Buttons={[{
                  label: 'Dishonor',
                  status: EVENT_OPERATION.DISHONORED,
                  className: 'primary',
                }, {
                  label: 'Reconile',
                  status: EVENT_OPERATION.RECONCILED,
                }]}
                render={(state, primaryCheckboxHandler, secondaryCheckboxHandler) => (
                  <div className="table-wrap">
                    <table>
                      {
                  TableHeader({
                    tableConfig,
                    dataListLength: received.list.length,
                    primaryCheckboxHandler,
                    checkedListLength: state.checkedList.length,
                    permission,
                  })
                }

                      <tbody>
                        {received.list.map(received => (
                          TableBody({
                            data: received,
                            onIconClick: this.handleIconClick,
                            tableConfig,
                            handleSliderOpen,
                            checkedList: state.checkedList,
                            secondaryCheckboxHandler,
                            section: 'received',
                            permission,
                          })
                        ))}
                      </tbody>
                    </table>

                  </div>
                )}
              />
              <Pagination
                currentPage={pagination.page}
                limit={pagination.limit}
                totalItems={received.total}
                onPageChange={onPageChange}
              />
            </ReportTableStyled>
          </Fragment>
        );
      }
}

const ReceivedWithErrorAndLoading = withErrorAndLoadingScreen(Received);

const ReceivedWithImageSlider = withImageSlider(ReceivedWithErrorAndLoading);

export default withAlert()(ReceivedWithImageSlider);
