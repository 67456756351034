import {
  compose,
} from 'recompose';
import ChannelAssortmentDetails from './Details';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';
import {
  query, mutation, TOGGLE_CHANNEL_CATEGORY_SKUS, DOWNLOAD_REPORT,
} from './API';

const ComposedChannelAssortmentDetails = compose(
  TOGGLE_CHANNEL_CATEGORY_SKUS,
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  }),
)(ChannelAssortmentDetails);

export default ComposedChannelAssortmentDetails;
