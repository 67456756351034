import DialogWrapper from './DialogWrapper';
import DialogConfirmation from './DialogConfirmation';
import NewDialogWrapper from './DialogFormWrapper';
import withFormWrapper from '../../utils/composition/withFormWrapper';

const DialogFormWrapper = withFormWrapper(NewDialogWrapper);

export {
  DialogWrapper, DialogConfirmation, DialogFormWrapper, NewDialogWrapper,
};
