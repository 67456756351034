import styled from 'styled-components';

const CatalogStyled = styled.div`
.section-header{
  .flex.m-0{
    .ml-16{
      margin:0;
      margin-right:16px;
      &:last-child{
        margin-right:0;
      }
    }
  }
}

`;
export default CatalogStyled;
