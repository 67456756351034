import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import LoginView from './LoginView';
import history from '../../utils/history';
import { JWT, LOGIN_SUCCESS_BASE_URL } from '../../environment';
import { errorMessageParser } from '../../utils/error';
import { encryptText } from '../../utils/encryption';
import { DialogFormWrapper } from '../common';
import { EVENT_OPERATION } from '../../data/enums/EventOperation';
import Form from './resetPassword/Form';
import formConfig from './config';
import Conformation from './resetPassword/Confirmation';
import { ALERT_TYPE } from '../../data/enums/AlertType';
import withAlert from '../../utils/composition/withAlert';
import headerLabelService from '../base/headerLabel.service';
import { storeInLocalStorage } from '../common/HelperFunctions';
import { DOMAIN } from '../../data/enums/config';

const propTypes = {
  loginWithServer: PropTypes.func.isRequired,
  forgotPassword: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class Login extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      form: {
        username: '',
        password: '',
      },
      loginResponse: {
        invalid: false,
        message: '',
      },
      dialog: {
        type: '',
        element: '',
      },
      forgot: {
        email: '',
        info: '',
      },
      displayFormError: false,
    };
  }

  componentDidMount = () => {
    this.clearDataInStorage();
  };

  onFormSubmit = (event) => {
    // todo clear the local storage.
    event.preventDefault();
    const { form: { username, password }, displayFormError } = this.state;
    if (!displayFormError) {
      this.setState({ displayFormError: true });
    }
    // enable the validation criteria.
    this.clearDataInStorage();
    // todo: get the validation criteria over here, if it meets call to the server.
    const { loginWithServer } = this.props;
    const encryptPassword = encryptText(password, username);

    loginWithServer({
      username,
      password: encryptPassword,
    }, {
      handleSuccess: data => this.onLoginSuccess(data),
      handleError: data => this.onLoginFailure(data),
    });
  };

  clearDataInStorage = () => {
    localStorage.removeItem(JWT.LOCAL_STORAGE.APP_STORAGE.NAME);
    localStorage.removeItem(JWT.LOCAL_STORAGE.TOKEN.NAME);
    localStorage.removeItem(`${DOMAIN.SALES}-FILTER`);
  };

  onLoginSuccess = async (response) => {
    const {
      data: {
        login: {
          User, token, appPermissionData, page,
        },
      },
    } = response;
    const { getBuData } = this.props;
    getBuData({}, {
      handleSuccess: (response) => {
        const { settings } = response.data;
        storeInLocalStorage('bu-settings', settings, 'rosia');
      },
      handleError: (err) => {
        this.onAPIRequestFailure(err);
      },
    });
    localStorage.setItem(JWT.SESSION_STORAGE.APP_STORAGE.PAGE, JSON.stringify(page));
    localStorage.setItem(JWT.LOCAL_STORAGE.APP_STORAGE.NAME, JSON.stringify(User));
    localStorage.setItem(JWT.LOCAL_STORAGE.TOKEN.NAME, token);
    localStorage.setItem(JWT.LOCAL_STORAGE.PERMISSION.NAME, JSON.stringify(appPermissionData));
    // projectInitializer();
    await headerLabelService.setHeaderConfig();
    // await localDataService.loadInitialData();

    history.push(LOGIN_SUCCESS_BASE_URL);
  };

  onLoginFailure = (err) => {
    const loginResponse = {
      invalid: true,
      message: errorMessageParser(err),
    };
    this.setState({ loginResponse });
  };

  handleInputChange = (event) => {
    const { form } = this.state;
    form[event.target.name] = event.target.value;
    this.setState({ form });
  };

  handleIconClick = (type, element) => {
    this.setState({
      dialog: {
        type,
        element,
      },
    });
  };

    resetDialog = () => {
      this.setState({
        dialog: {
          type: '',
          element: '',
        },
      });
    };

    onAPIRequestFailure = (error) => {
      const { displayAlert } = this.props;
      displayAlert(ALERT_TYPE.DANGER, error);
      console.log(error);
    };

     sendEmail = (type, data) => {
       const { forgotPassword, displayAlert } = this.props;
       const { forgot } = this.state;
       forgotPassword({
         email: data.email,
       },
       {
         handleSuccess: (response) => {
           forgot.info = response.data.forgotPassword;
           this.setState({
             forgot: {
               email: data.email,
             },
           });
           if (forgot.info === 'Incorrect Email.') { displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Incorrect Email.'); } else { this.handleIconClick(EVENT_OPERATION.READ); }
         },
         handleError: (error) => {
           this.onAPIRequestFailure(error);
         },
       });
     }

     render() {
       const {
         form, loginResponse, displayFormError, dialog, forgot,
       } = this.state;
       const { type } = dialog;
       const {
         serverResponseWaiting,
       } = this.props;
       return (
         <Fragment>
           <div>
             {type && (
             <DialogFormWrapper
               formConfig={formConfig[type]}
               dialogElement={dialog.element}
               type={type}
               onDialogSubmit={this.sendEmail}
               onDialogCancel={this.resetDialog}
               renderDialog={
                  (
                    {
                      dialogData,
                      handleInputChange,
                      refsObj,
                      enableErrorDisplay,
                    },
                  ) => (
                    <Fragment>
                      {
                      type === EVENT_OPERATION.CREATE ? (
                        <Form
                          data={dialogData}
                          show
                          type={type}
                          handleInputChange={handleInputChange}
                          enableErrorDisplay={enableErrorDisplay}
                          refsObj={refsObj}
                        />)
                        : (
                          <Conformation
                            type={type}
                            onDialogCancel={this.resetDialog}
                            forgot={forgot.email}
                          />
                        )
                      }
                    </Fragment>
                  )}
             />
             )}
             <LoginView
               form={form}
               loading={serverResponseWaiting}
               handleInputChange={this.handleInputChange}
               onSubmitButtonClick={this.onFormSubmit}
               enableErrorDisplay={displayFormError}
               loginResponse={loginResponse}
               onIconClick={this.handleIconClick}
             />
           </div>
         </Fragment>
       );
     }
}

Login.propTypes = propTypes;

Login.defaultProps = defaultProps;

export default withAlert()(Login);
