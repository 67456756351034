const userOption = {
  index: 0,
  title: '',
  label: 'userOption',
  selectedIdList: [],
  selectedItemList: [],
  value: 'user_option',
};

const getMenuListWithUserOption = (menuConfig) => {
  if (!menuConfig.hasOwnProperty('userOption')) {
    menuConfig.userOption = userOption;
  }
  return menuConfig;
};

export { getMenuListWithUserOption, userOption }
