import React from 'react';
import Input from '../../../../../components/Input/Text';

const InputParameters = props => (
  <div className="parameters">
    <Input
      type="number"
      name="routes"
      enableValidation
      labelContent="No of Routes"
      placeholder="0"
      value={props.routesNumber}
      onChange={event => props.onRoutesChange(event)}
    />
    {/* <div className="placeholder" data-placeholder="(in meters)">
      <Input
        type="number"
        name="distance"
        enableValidation
        labelContent="Maximum Distance"
        placeholder="0"
        value={props.maxDistance}
        onChange={event => props.onDistanceChange(event)}
      />
    </div> */}
    <Input
      type="number"
      name="outlets"
      enableValidation
      labelContent="Maximum Outlets"
      placeholder="0"
      value={props.maxOutlets}
      onChange={event => props.onOutletsChange(event)}
    />
  </div>
);
export default InputParameters;
