import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Tab, TabList, TabPanel, Tabs,
} from 'react-tabs';
import { Row, Col } from '../../../components/Grid';

import {
  BreadCrumb, DatePicker, Badge,
} from '../../../components';
import Filter from '../../../components/Filter';
import { dataProps } from '../../../data/enums/Component';
import { PanelStyled, PanelHeader } from '../../common/configuration/index';
import withErrorAndLoadingScreen from '../../../utils/composition/withLoading';
import Received from './received';
import Invoiced from './invoiced';
import Delivered from './delivered';
import Dispatched from './dispatched';
import OrderProcessingWrap from './OrderProcessingStyled';
import { breadCrumb } from './config';
import Search from '../../../components/Search';
import Replaced from './replaced';


const propTypes = {
  data: dataProps.value,
  onPageChange: PropTypes.func,
  onCheckBoxClick: PropTypes.func,
  onIconClick: PropTypes.func,
  breadCrumb: PropTypes.array,
  filter: PropTypes.object,
  onButtonClick: PropTypes.func,
  onFilterChange: PropTypes.func,
  onDateRangeChange: PropTypes.func,
};

const defaultProps = {
  breadCrumb: [],
  filter: {},
  onIconClick: () => null,
  onPageChange: () => null,
  onButtonClick: () => null,
  onCheckBoxClick: () => null,
  data: dataProps.defaultValue,
  onDateRangeChange: () => null,
};

const View = ({ ...props }) => {
  const {
    date,
    loading,
    history,
    received,
    invoiced,
    replaced,
    delivered,
    activeTab,
    activeLine,
    dispatched,
    onTabChange,
    displayAlert,
    onPageChange,
    onFilterChange,
    onDateRangeChange,
    filter,
    filterMenu,
    onCallRowClick,
    handleActiveTabListChange,
    handlePassiveTabListChange,
    stateSetter,
    stateGetter,
    searchText,
    persistFilterState,
    onSearchInputChange,
    onHeaderClick,
  } = props;
  return (
    <Fragment>
      <div className="section-header">
        <PanelStyled className="padding-0">
          <BreadCrumb list={breadCrumb} />
          <PanelHeader>
            <h2>Order Fulfilment</h2>
            <div className="filter-wrap">
              <Search
                searchText={searchText}
                handleSearchInput={onSearchInputChange}
              />
              <DatePicker date={date} onChange={onDateRangeChange} />
              <Filter
                metaData={filter}
                menuList={filterMenu}
                onFilterChange={onFilterChange}
                refreshState={persistFilterState}
              />
            </div>
          </PanelHeader>
        </PanelStyled>
      </div>
      <div className="section-content section-tab">
        <OrderProcessingWrap>
          <Tabs selectedIndex={activeTab} onSelect={tabIndex => onTabChange(tabIndex)}>
            <TabList>
              <Tab>
                <span className="tab-label">Received</span>
                <Badge light label={received.total} />
              </Tab>
              <Tab>
                <span className="tab-label">Invoiced</span>
                <Badge light label={invoiced.total} />
              </Tab>
              <Tab>
                <span className="tab-label">Replaced</span>
                <Badge light label={replaced.total} />
              </Tab>
              <Tab>
                <span className="tab-label">Dispatched</span>
                <Badge light label={dispatched.total} />
              </Tab>
              <Tab>
                <span className="tab-label">Delivered</span>
                <Badge light label={delivered.total} />
              </Tab>
            </TabList>
            <div className="tab-container">
              <Row>
                <Col sm={12}>
                  <TabPanel>
                    <section id="received-tab">
                      <Received
                        data={received}
                        stateSetter={stateSetter}
                        stateGetter={stateGetter}
                        history={history}
                        loading={loading}
                        activeLine={activeLine}
                        onPageChange={onPageChange}
                        displayAlert={displayAlert}
                        onCallRowClick={onCallRowClick}
                        handleActiveTabListChange={handleActiveTabListChange}
                        handlePassiveTabListChange={handlePassiveTabListChange}
                        onHeaderClick={onHeaderClick}
                      />
                    </section>
                  </TabPanel>
                  <TabPanel>
                    <section id="invoiced-tab">
                      <Invoiced
                        data={invoiced}
                        history={history}
                        loading={loading}
                        activeLine={activeLine}
                        stateSetter={stateSetter}
                        stateGetter={stateGetter}
                        onPageChange={onPageChange}
                        displayAlert={displayAlert}
                        onCallRowClick={onCallRowClick}
                        handleActiveTabListChange={handleActiveTabListChange}
                        handlePassiveTabListChange={handlePassiveTabListChange}
                        onHeaderClick={onHeaderClick}
                      />
                    </section>
                  </TabPanel>
                  <TabPanel>
                    <section id="replaced-tab">
                      <Replaced
                        data={replaced}
                        history={history}
                        loading={loading}
                        activeLine={activeLine}
                        stateSetter={stateSetter}
                        stateGetter={stateGetter}
                        onPageChange={onPageChange}
                        displayAlert={displayAlert}
                        onCallRowClick={onCallRowClick}
                        handleActiveTabListChange={handleActiveTabListChange}
                        handlePassiveTabListChange={handlePassiveTabListChange}
                        onHeaderClick={onHeaderClick}
                      />
                    </section>
                  </TabPanel>
                  <TabPanel>
                    <section id="dispatched-tab">
                      <Dispatched
                        data={dispatched}
                        history={history}
                        loading={loading}
                        activeLine={activeLine}
                        stateSetter={stateSetter}
                        stateGetter={stateGetter}
                        onPageChange={onPageChange}
                        displayAlert={displayAlert}
                        onCallRowClick={onCallRowClick}
                        handleActiveTabListChange={handleActiveTabListChange}
                        handlePassiveTabListChange={handlePassiveTabListChange}
                        onHeaderClick={onHeaderClick}
                      />
                    </section>
                  </TabPanel>
                  <TabPanel>
                    <section id="delivered-tab">
                      <Delivered
                        data={delivered}
                        history={history}
                        loading={loading}
                        activeLine={activeLine}
                        stateSetter={stateSetter}
                        stateGetter={stateGetter}
                        onPageChange={onPageChange}
                        displayAlert={displayAlert}
                        onCallRowClick={onCallRowClick}
                        handleActiveTabListChange={handleActiveTabListChange}
                        handlePassiveTabListChange={handlePassiveTabListChange}
                        onHeaderClick={onHeaderClick}
                      />
                    </section>
                  </TabPanel>
                </Col>
              </Row>
            </div>
          </Tabs>
        </OrderProcessingWrap>
      </div>
    </Fragment>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const OrderProcessingView = withErrorAndLoadingScreen(View);

export default OrderProcessingView;
