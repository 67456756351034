import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Detail from './detail';
import { replacedTableConfig } from './tableConfig';
import { BreadCrumb, Pagination } from '../../../../components';
import { dataProps, paginationProps } from '../../../../data/enums/Component';
import withErrorAndLoadingScreen from '../../../../utils/composition/withLoading';
import CTAccordionStyled from '../../../../components/CollapsibleTable/CTAccordionStyled';
import TableHeader from '../../../base/TableHeader';

const propTypes = {
  data: PropTypes.shape({
    list: PropTypes.instanceOf(Array),
    total: PropTypes.number,
    limit: PropTypes.number,
    page: PropTypes.number,
  }),
  pagination: PropTypes.shape({
    page: PropTypes.number,
    limit: PropTypes.number,
    total: PropTypes.number,
  }),
  onPageChange: PropTypes.func.isRequired,
  breadCrumb: PropTypes.instanceOf(Array),
  loading: PropTypes.bool,
  onTableHeaderClick: PropTypes.func,
  onRowClick: PropTypes.func.isRequired,
  stateSetter: PropTypes.func.isRequired,
  activeCallIndex: PropTypes.number.isRequired,
  onOrderStatusChangeClick: PropTypes.func.isRequired,
};

const defaultProps = {
  breadCrumb: [],
  loading: false,
  data: dataProps.defaultValue,
  onTableHeaderClick: () => null,
  pagination: paginationProps.defaultValue,
};

const ReplacedView = ({ ...props }) => {
  const {
    breadCrumb,
    data,
    loading,
    onPageChange,
    onRowClick,
    stateSetter,
    activeCallIndex,
    onTableHeaderClick,
    onOrderStatusChangeClick,
  } = props;

  return (
    <div>
      <div className="tab-section">
        <BreadCrumb list={breadCrumb} />
        <CTAccordionStyled>
          <div className="table-wrap">
            <table>
              <TableHeader
                headerDetails={replacedTableConfig}
                onTableHeaderClick={onTableHeaderClick}
              />
              <Fragment>
                <Detail
                  data={data}
                  loadingData={loading}
                  onRowClick={onRowClick}
                  stateSetter={stateSetter}
                  activeCallIndex={activeCallIndex}
                  onOrderStatusChangeClick={onOrderStatusChangeClick}
                />
              </Fragment>
            </table>
          </div>
        </CTAccordionStyled>

        <Pagination
          currentPage={data.page}
          limit={data.limit}
          totalItems={data.total}
          onPageChange={onPageChange}
        />
      </div>
    </div>
  );
};

ReplacedView.propTypes = propTypes;

ReplacedView.defaultProps = defaultProps;

const ReplacedViewWithErrorAndLoading = withErrorAndLoadingScreen(ReplacedView);

export default ReplacedViewWithErrorAndLoading;
