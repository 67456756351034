import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { normalPresentor } from '../../../utils/date';
import { STATUS } from '../../../data/enums/Status';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { tableData } from '../../../utils/paginatedListUtils';

// todo update the configurations.
const labelMappings = {
  date: 'date',
  status: 'status',
  amount: 'amount',
  ledger: 'ledger',
  reason: 'reason',
  stockType: 'stockType',
  distributor: 'distributor',
  retailOutlet: 'retailOutlet',
  actionHolders: 'actionHolders',
  invoiceNumber: 'invoiceNumber',
  SRNInvoiceNumber: 'SRNInvoiceNumber',
};

const returnTableBody = (data) => {
  const table = {};
  table[labelMappings.invoiceNumber] = <td>{data.invoiceNumber}</td>;

  table[labelMappings.date] = <td>{normalPresentor(data.date)}</td>;

  table[labelMappings.SRNInvoiceNumber] = <td>{data.SRNInvoiceNumber}</td>;

  table[labelMappings.status] = (
    <td>
      {data.approved ? (
        <span>{STATUS.APPROVED}</span>
      ) : (
        <span>{STATUS.PENDING}</span>
      )}
    </td>
  );

  table[labelMappings.distributor] = (
    <td>{data.Distributor ? data.Distributor.title : ''}</td>
  );

  table[labelMappings.retailOutlet] = (
    <td>{data.RetailOutlet ? data.RetailOutlet.title : ''}</td>
  );

  table[labelMappings.amount] = (
    <td className="text-right">
      {fixedFloatAndCommas(data.srnAmount ? data.srnAmount.netAmount || 0 : 0)}
    </td>
  );

  table[labelMappings.ledger] = (
    <td>{data.Customer ? data.Customer.name : ''}</td>
  );

  table[labelMappings.reason] = <td>{data.reason}</td>;

  return table;
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.invoiceNumber,
    title: 'Sales Invoice Number',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.date,
    title: 'Date',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.SRNInvoiceNumber,
    title: 'SRN Invoice Number',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.status,
    title: 'Status',
    show: false,
  },
  {
    id: 5,
    label: labelMappings.distributor,
    title: 'Distributor',
    show: false,
  },
  {
    id: 6,
    label: labelMappings.retailOutlet,
    title: 'Retail Outlet',
    show: true,
  },
  {
    id: 7,
    label: labelMappings.amount,
    title: 'Amount',
    align: true,
    show: true,
  },
  {
    id: 8,
    label: labelMappings.ledger,
    title: 'Ledger',
    show: true,
  },
  {
    id: 9,
    label: labelMappings.reason,
    title: 'Remarks',
    show: true,
  },
  {
    id: 10,
    label: labelMappings.actionHolders,
    title: '',
    show: false,
  },
];

const TableBody = (data, onRowClick) => {
  // Expanding/Dragging and collapsing table body.
  // color based upon table body
  // tooltip if it is sorted.
  // active className
  const tableBody = returnTableBody(data);

  return (
    <tr onClick={() => onRowClick(EVENT_OPERATION.UPDATE, data.id)}>
      <Link to={`srn/details/${data.id}`}>
        {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
      </Link>
    </tr>
  );
};

const TableHeader = () => (
  <thead className="">
    <tr>
      {labelConfig.map(label => (label.show ? tableData(label.title, label.align) : null))}
    </tr>
  </thead>
);

TableBody.propTypes = {
  data: PropTypes.object.isRequired,
};

export { TableHeader, TableBody };
