import styled from 'styled-components';

const ReporTableStyled = styled.div`
.table-wrap{
  td{
   white-space: nowrap;
    max-width: 350px;
    overflow:hidden;
    text-overflow: ellipsis; 
  }
  .action-row {
     cursor: pointer;
  }
}
  `;
export default ReporTableStyled;
