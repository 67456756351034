import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { PanelStyled, PanelHeader } from '../../common/configuration/index';
import { dataProps } from '../../../data/enums/Component';
import withLoading from '../../../utils/composition/withLoading';
import {
  BreadCrumb, CheckBox, Input, Switch, Button, Icon,
} from '../../../components';
import CustomSelect from '../../../components/CustomSelect';
import { targetDimension } from './config';
import { SingleDatePicker } from '../../../components/DatePicker';
import BuStyled from './BuStyled';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import VerticalDragSwitch from '../../components/VerticalDragSwitch';
import { PERMISSION_OBJ } from '../../../data/enums/Permission';
import HolidaysSection from '../../components/HolidaysSection';
import BuHolidayWrapper from './BuHolidayWrapper';
import { presentor } from '../../../utils/date';
import FormContentStyled from '../../../components/global-css/FormContentStyled';
import { USER_ROLE_TITLE } from '../../../data/enums/UserRole';
import { headerLabelConfig } from '../../common/HelperFunctions';

const propTypes = {
  data: dataProps.value,
  onInputChange: PropTypes.func.isRequired,
  handleDropDownChange: PropTypes.func.isRequired,
  handleDateRangeChange: PropTypes.func.isRequired,
  onTargetChange: PropTypes.func.isRequired,
  onSalesChange: PropTypes.func.isRequired,
  breadCrumb: PropTypes.arrayOf(Object),
  crudMode: PropTypes.string.isRequired,
  handleEditIconClick: PropTypes.func.isRequired,
  handleButtonCancel: PropTypes.func.isRequired,
  handleButtonSubmit: PropTypes.func.isRequired,
  handleMeasurementAdd: PropTypes.func.isRequired,
  handleMeasurementCancel: PropTypes.func.isRequired,
  DSESequenceData: PropTypes.arrayOf(Object).isRequired,
  MerchandisingSequenceData: PropTypes.arrayOf(Object).isRequired,
  procedures: PropTypes.func.isRequired,
  permission: PropTypes.shape({
    read: PropTypes.bool,
    create: PropTypes.bool,
    update: PropTypes.bool,
    delete: PropTypes.bool,
  }),
  params: PropTypes.shape({
    params: {
      id: PropTypes.string,
    },
  }).isRequired,
};

const defaultProps = {
  data: dataProps.defaultValue,
  breadCrumb: [],
  permission: PERMISSION_OBJ,
};

const View = ({ ...props }) => {
  const {
    data,
    onInputChange,
    breadCrumb,
    handleDropDownChange,
    handleDateRangeChange,
    onTargetChange,
    onSalesChange,
    crudMode,
    handleEditIconClick,
    handleButtonCancel,
    handleButtonSubmit,
    handleMeasurementAdd,
    handleMeasurementCancel,
    DSESequenceData,
    MerchandisingSequenceData,
    procedures,
    permission,
    params,
    callHolidaySection,
    loading,
  } = props;

  const disableEditButton = crudMode === EVENT_OPERATION.UPDATE;
  const hideEditButton = crudMode !== EVENT_OPERATION.UPDATE;

  return (
    <Fragment>
      <div className="section-header">
        <PanelStyled>
          <BreadCrumb list={breadCrumb} />
          <PanelHeader>
            <h2>{disableEditButton ? 'Edit BU Settings' : 'BU Settings'}</h2>
            <div className="flex m-0">
              {
                  crudMode !== EVENT_OPERATION.READ && (
                    <div>
                      <Button secondary small disabled={loading} onClick={() => handleButtonCancel()}>
                        <span>Cancel</span>
                      </Button>
                      <Button primary small disabled={loading} onClick={() => handleButtonSubmit()}>
                        <span>Save</span>
                      </Button>
                    </div>
                  )}
              {hideEditButton ? (
                <div>
                  {
                      permission.update && (
                        <Button
                          secondary
                          iconBtnSmall
                          disabled={disableEditButton}
                          onClick={handleEditIconClick}
                          className="ml-16"
                        >
                          <Icon iconName="pencil" />
                        </Button>
                      )
                    }
                </div>
              ) : ''
                }
            </div>
          </PanelHeader>
        </PanelStyled>
      </div>
      <div className="section-content">
        <BuStyled className={disableEditButton ? '' : 'disabled'}>
          {/* <div className="bu-form-content"> */}
          {/*  <h2>General Setting</h2> */}
          {/*  <Row> */}
          {/*    <Col md={6}> */}
          {/*      <Input */}
          {/*        value={data.currency || ''} */}
          {/*        name="currency" */}
          {/*        type="text" */}
          {/*        rule="matches" */}
          {/*        labelContent="Currency" */}
          {/*        argument={ */}
          {/*          /\b(^[\w -]*)\b/ */}
          {/*        } */}
          {/*        placeholder="Currency" */}
          {/*        enableErrorDisplay={false} */}
          {/*        enableValidation */}
          {/*        onChange={event => onInputChange(event)} */}
          {/*      /> */}
          {/*      <CustomSelect */}
          {/*        clearable={false} */}
          {/*        className="custom-select" */}
          {/*        placeholder="Select" */}
          {/*        labelContent="Date Format" */}
          {/*        getOptionLabel={({ title }) => title} */}
          {/*        getOptionValue={({ id }) => id} */}
          {/*        options={dateFormat} */}
          {/*        enableValiation */}
          {/*        value={dateFormat.filter(({ id }) => id === 'dd/mm/yy')} */}
          {/*        onChange={e => handleDropDownChange(e.id, ['dateSettings', 'format'], */}
          {/*          handleDropDownChange) */}
          {/*        } */}
          {/*      /> */}
          {/*      <CustomSelect */}
          {/*        clearable={false} */}
          {/*        className="custom-select" */}
          {/*        placeholder="Select" */}
          {/*        labelContent="Date Type" */}
          {/*        getOptionLabel={({ title }) => title} */}
          {/*        getOptionValue={({ id }) => id} */}
          {/*        options={dateType} */}
          {/*        enableValiation */}
          {/*        value={dateType.filter(({ id }) => id === data.dateSettings.calendar)} */}
          {/*        onChange={e => handleDropDownChange(e.id, ['dateSettings', 'calendar'], */}
          {/*          handleDropDownChange) */}
          {/*        } */}
          {/*      /> */}
          {/*    </Col> */}
          {/*  </Row> */}
          {/* </div> */}
          <FormContentStyled>
            <h3>Services</h3>
            <div className="form-input-wrap">
              <div className="placeholder" data-placeholder="(in M)">
                <Input
                  value={data.geoFencing || ''}
                  name="geoFencing"
                  type="number"
                  rule="matches"
                  labelContent={`${headerLabelConfig[USER_ROLE_TITLE.DSE]}'s Order Radius`}
                  argument={
                          /^[0-9]*$/
                        } /* matches alphanumeric, underscore ,'-' & ' ' */
                  placeholder="Order Radius"
                  enableErrorDisplay={false}
                  enableValidation
                  onChange={event => onInputChange(event)}
                />
              </div>
              <Switch
                checked={data.orderForMultipleDistributors}
                labelContent="Order fulfilment by multiple SubDs "
                onToggle={(checked, event) => {
                  onInputChange(
                    {
                      formattedValue: !!checked,
                      target: {
                        name: 'active',
                        type: 'boolean',
                        value: !!checked,
                      },
                    },
                    'orderForMultipleDistributors',
                    ['orderForMultipleDistributors'],
                  );
                }}
              />
              {/* <Switch */}
              {/*  checked={data.multipleDseInRoute} */}
              {/*  labelContent="Support for multiple routes" */}
              {/*  onToggle={(checked, event) => { */}
              {/*    onInputChange( */}
              {/*      { */}
              {/*        formattedValue: !!checked, */}
              {/*        target: { */}
              {/*          name: 'active', */}
              {/*          type: 'boolean', */}
              {/*          value: !!checked, */}
              {/*        }, */}
              {/*      }, */}
              {/*      'multipleDseInRoute', */}
              {/*      ['multipleDseInRoute'], */}
              {/*    ); */}
              {/*  }} */}
              {/* /> */}
              <Switch
                checked={data.pfd}
                labelContent="Enable Plan For the Day"
                onToggle={(checked, event) => {
                  onInputChange(
                    {
                      formattedValue: !!checked,
                      target: {
                        name: 'active',
                        type: 'boolean',
                        value: !!checked,
                      },
                    },
                    'pfd',
                    ['pfd'],
                  );
                }}
              />
              {/* <Switch */}
              {/*  checked={data.payment} */}
              {/*  labelContent="Enable Payment Collection" */}
              {/*  onToggle={(checked, event) => { */}
              {/*    onInputChange( */}
              {/*      { */}
              {/*        formattedValue: !!checked, */}
              {/*        target: { */}
              {/*          name: 'active', */}
              {/*          type: 'boolean', */}
              {/*          value: !!checked, */}
              {/*        }, */}
              {/*      }, */}
              {/*      'payment', */}
              {/*      ['payment'], */}
              {/*    ); */}
              {/*  }} */}
              {/* /> */}
              <Switch
                checked={data.srn}
                labelContent="Enable Sales Returns"
                onToggle={(checked, event) => {
                  onInputChange(
                    {
                      formattedValue: !!checked,
                      target: {
                        name: 'active',
                        type: 'boolean',
                        value: !!checked,
                      },
                    },
                    'srn',
                    ['srn'],
                  );
                }}
              />
              <Switch
                checked={data.sbd}
                labelContent="SBD"
                onToggle={(checked, event) => {
                  onInputChange(
                    {
                      formattedValue: !!checked,
                      target: {
                        name: 'active',
                        type: 'boolean',
                        value: !!checked,
                      },
                    },
                    'sbd',
                    ['sbd'],
                  );
                }}
              />
              <Switch
                checked={data.focusedSKU}
                labelContent="Focused SKU"
                onToggle={(checked, event) => {
                  onInputChange(
                    {
                      formattedValue: !!checked,
                      target: {
                        name: 'active',
                        type: 'boolean',
                        value: !!checked,
                      },
                    },
                    'focusedSKU',
                    ['focusedSKU'],
                  );
                }}
              />
              {/* { data.srn && ( */}
              {/*  <> */}
              {/*    <div className="permission-choose"> */}
              {/*      <label>Choose one</label> */}
              {/*      <div className="checkbox-horizontal"> */}
              {/*        <div className="status-content"> */}
              {/*          <label className="radio-list"> */}
              {/*            <input */}
              {/*              type="radio" */}
              {/*              name="sales" */}
              {/*              value="full" */}
              {/*              checked={data.sales && data.sales.full} */}
              {/*              onChange={e => onSalesChange(e)} */}
              {/*            /> */}
              {/*            <span>Full</span> */}
              {/*          </label> */}
              {/*          <label className="radio-list"> */}
              {/*            <input */}
              {/*              type="radio" */}
              {/*              name="sales" */}
              {/*              value="partial" */}
              {/*              checked={data.sales && data.sales.partial} */}
              {/*              onChange={e => onSalesChange(e)} */}
              {/*            /> */}
              {/*            <span>Partial</span> */}
              {/*          </label> */}
              {/*          <label className="radio-list"> */}
              {/*            <input */}
              {/*              type="radio" */}
              {/*              name="sales" */}
              {/*              value="both" */}
              {/*              checked={data.sales && data.sales.both} */}
              {/*              onChange={e => onSalesChange(e)} */}
              {/*            /> */}
              {/*            <span>Both</span> */}
              {/*          </label> */}
              {/*        </div> */}
              {/*      </div> */}
              {/*    </div> */}
              {/* </>)} */}
              {/* <Switch */}
              {/*  checked={data.segmentation} */}
              {/*  labelContent="Enable Route Segmentation" */}
              {/*  onToggle={(checked, event) => { */}
              {/*    onInputChange( */}
              {/*      { */}
              {/*        formattedValue: !!checked, */}
              {/*        target: { */}
              {/*          name: 'active', */}
              {/*          type: 'boolean', */}
              {/*          value: !!checked, */}
              {/*        }, */}
              {/*      }, */}
              {/*      'segmentation', */}
              {/*      ['segmentation'], */}
              {/*    ); */}
              {/*  }} */}
              {/* /> */}
            </div>
          </FormContentStyled>
          <FormContentStyled>
            <h3>Fiscal Year for Business</h3>
            <div className="form-input-wrap">
              <div className={`form-input custom-date ${data.fiscalYear.start !== '' ? 'disabled' : ''}`}>
                <label><span>Starting Month</span></label>
                <SingleDatePicker
                  date={data.fiscalYear.start || presentor(new Date())}
                  name="start"
                  onChange={(name, date) => {
                    handleDateRangeChange(name, date);
                  }}
                />
              </div>
              <div className={`form-input custom-date ${data.fiscalYear.end !== '' ? 'disabled' : ''}`}>
                <label><span>End Month</span></label>
                <SingleDatePicker
                  date={data.fiscalYear.end || presentor(new Date())}
                  name="end"
                  onChange={(name, date) => {
                    handleDateRangeChange(name, date);
                  }}
                />
              </div>
            </div>
          </FormContentStyled>
          <FormContentStyled>
            <h3>Units of Measurement</h3>
            <div className="form-input-wrap">
              <div className="measurement-wrap">
                <div>
                  <ul className="list-title">
                    <li className="large"><span>Title</span></li>
                    <li><span>Label</span></li>
                  </ul>
                </div>
                <div>
                  {data.unitsOfMeasurement.map((measure, index) => (
                    <div className="list-content" key={index}>
                      <label className="large custom-control-label-text">
                        <Input
                          type="text"
                          name="title"
                          placeholder="title"
                          value={measure.title}
                          onChange={event => onInputChange(event, 'unitsOfMeasurement',
                            ['unitsOfMeasurement', [index], 'title'])}
                        />
                      </label>
                      <label className="custom-control-label-text">
                        <Input
                          type="text"
                          name="label"
                          placeholder="label"
                          value={measure.label}
                          onChange={event => onInputChange(event, 'unitsOfMeasurement',
                            ['unitsOfMeasurement', [index], 'label'])}
                        />
                      </label>
                      <Icon
                        iconName="times"
                        onClick={() => handleMeasurementCancel(measure.title)}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <Button small secondary onClick={() => handleMeasurementAdd()}>
                <Icon iconName="plus" />
                <span>Add</span>
              </Button>
            </div>
          </FormContentStyled>
          {/* <div className="bu-form-content"> */}
          {/*  <h2>Order Status</h2> */}
          {/*  <Row> */}
          {/*    <Col md={6}> */}
          {/*      <div className="status-content"> */}
          {/*        <CheckBox */}
          {/*          checked={data.orderStatus && data.orderStatus.received} */}
          {/*          name="received" */}
          {/*          labelText="Received" */}
          {/*          onCheckBoxClick={(e) => { */}
          {/*            onInputChange( */}
          {/*              { */}
          {/*                formattedValue: !!e.target.checked, */}
          {/*                target: { */}
          {/*                  name: 'active', */}
          {/*                  type: 'boolean', */}
          {/*                  value: !!e.target.checked, */}
          {/*                }, */}
          {/*              }, */}
          {/*              'received', */}
          {/*              ['orderStatus', 'received'], */}
          {/*            ); */}
          {/*          }} */}
          {/*        /> */}
          {/*        <CheckBox */}
          {/*          checked={data.orderStatus && data.orderStatus.invoiced} */}
          {/*          name="invoiced" */}
          {/*          labelText="Invoiced" */}
          {/*          onCheckBoxClick={(e) => { */}
          {/*            onInputChange( */}
          {/*              { */}
          {/*                formattedValue: !!e.target.checked, */}
          {/*                target: { */}
          {/*                  name: 'active', */}
          {/*                  type: 'boolean', */}
          {/*                  value: !!e.target.checked, */}
          {/*                }, */}
          {/*              }, */}
          {/*              'invoiced', */}
          {/*              ['orderStatus', 'invoiced'], */}
          {/*            ); */}
          {/*          }} */}
          {/*        /> */}
          {/*        <CheckBox */}
          {/*          checked={data.orderStatus && data.orderStatus.dispatched} */}
          {/*          name="dispatched" */}
          {/*          labelText="Dispatched" */}
          {/*          onCheckBoxClick={(e) => { */}
          {/*            onInputChange( */}
          {/*              { */}
          {/*                formattedValue: !!e.target.checked, */}
          {/*                target: { */}
          {/*                  name: 'active', */}
          {/*                  type: 'boolean', */}
          {/*                  value: !!e.target.checked, */}
          {/*                }, */}
          {/*              }, */}
          {/*              'dispatched', */}
          {/*              ['orderStatus', 'dispatched'], */}
          {/*            ); */}
          {/*          }} */}
          {/*        /> */}
          {/*        <CheckBox */}
          {/*          checked={data.orderStatus && data.orderStatus.delivered} */}
          {/*          name="delivered" */}
          {/*          labelText="Delivered" */}
          {/*          onCheckBoxClick={(e) => { */}
          {/*            onInputChange( */}
          {/*              { */}
          {/*                formattedValue: !!e.target.checked, */}
          {/*                target: { */}
          {/*                  name: 'active', */}
          {/*                  type: 'boolean', */}
          {/*                  value: !!e.target.checked, */}
          {/*                }, */}
          {/*              }, */}
          {/*              'delivered', */}
          {/*              ['orderStatus', 'delivered'], */}
          {/*            ); */}
          {/*          }} */}
          {/*        /> */}
          {/*      </div> */}
          {/*    </Col> */}
          {/*  </Row> */}
          {/* </div> */}
          <FormContentStyled>
            <h3>Targets</h3>
            <div className="form-input-wrap">
              <CustomSelect
                clearable={false}
                className="custom-select"
                placeholder="Select"
                labelContent="Target Dimension"
                getOptionLabel={id => id}
                getOptionValue={id => id}
                options={targetDimension}
                enableValiation
                value={targetDimension.filter(id => id === data.targetDimension)}
                onChange={e => handleDropDownChange(e, ['targetDimension'],
                  handleDropDownChange)
                      }
              />
              <div className="permission-choose">
                <div className="checkbox-horizontal">
                  <div className="status-content">
                    <label className="radio-list">
                      <input
                        type="radio"
                        name="target"
                        value="salesTeamWise"
                        checked={data.targets && data.targets.salesTeamWise}
                        onChange={e => onTargetChange(e)}
                      />
                      <span>Sales Team Wise</span>
                    </label>
                    <label className="radio-list">
                      <input
                        type="radio"
                        name="target"
                        value="subDWise"
                        checked={data.targets && data.targets.subDWise}
                        onChange={e => onTargetChange(e)}
                      />
                      <span>Sub D Wise</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="brand-wise-wrap">
                <Switch
                  checked={data.targets && data.targets.brandWise}
                  labelContent={<span>Brand Wise Target</span>}
                  onToggle={(checked, event) => {
                    onInputChange(
                      {
                        formattedValue: !!checked,
                        target: {
                          name: 'active',
                          type: 'boolean',
                          value: !!checked,
                        },
                      },
                      'brandWise',
                      ['targets', 'brandWise'],
                    );
                  }}
                />
                <span className="text">Enable brand wise target setting?</span>
              </div>
            </div>
          </FormContentStyled>
          <FormContentStyled>
            <h3>SKU Assortment</h3>
            <div className="form-input-wrap">
              <Switch
                checked={data.skuAssortmentMenu}
                labelContent="Enable SKU Assortment "
                onToggle={(checked, event) => {
                  onInputChange(
                    {
                      formattedValue: !!checked,
                      target: {
                        name: 'skuAssortmentMenu',
                        type: 'boolean',
                        value: !!checked,
                      },
                    },
                    'skuAssortmentMenu',
                    ['skuAssortmentMenu'],
                  );
                }}
              />
              {
                      data.skuAssortmentMenu ? (
                        <div className="checkbox">
                          <CheckBox
                            checked={data.skuAssortment.channel}
                            labelText="Channel"
                            onCheckBoxClick={(e) => {
                              onInputChange(
                                {
                                  formattedValue: !data.skuAssortment.channel,
                                  target: {
                                    name: 'active',
                                    value: 'boolean',
                                    checked: !data.skuAssortment.channel,
                                  },
                                },
                                'skuAssortment',
                                ['skuAssortment', 'channel'],
                              );
                            }}
                          />
                          <CheckBox
                            checked={data.skuAssortment.distributorGroup}
                            labelText="Sub D"
                            onCheckBoxClick={(e) => {
                              onInputChange(
                                {
                                  formattedValue: !data.skuAssortment.distributorGroup,
                                  target: {
                                    name: 'active',
                                    value: 'boolean',
                                    checked: !data.skuAssortment.distributorGroup,
                                  },
                                },
                                'skuAssortment',
                                ['skuAssortment', 'distributorGroup'],
                              );
                            }}
                          />
                        </div>
                      ) : null
                    }
            </div>
          </FormContentStyled>
          <FormContentStyled>
            <h3>Basic Call Procedure Sequence</h3>
            <div className="form-input-wrap">
              <div className="bu-draggable">
                {DSESequenceData.length !== 0
                  && <VerticalDragSwitch procedures={procedures} type="DSE" data={DSESequenceData} />
                  }
              </div>
            </div>
          </FormContentStyled>
          <FormContentStyled>
            <h3>Merchandising Sequence</h3>
            <div className="form-input-wrap">
              <div className="bu-draggable">
                {MerchandisingSequenceData.length !== 0
                  && (
                  <VerticalDragSwitch
                    procedures={procedures}
                    type="MERCHANDISING"
                    data={MerchandisingSequenceData}
                  />
                  )
                  }
              </div>
            </div>
          </FormContentStyled>
          <BuHolidayWrapper>
            <HolidaysSection
              params={params}
              callHolidaySection={callHolidaySection}
              className="bu-holidays"
            />
          </BuHolidayWrapper>
        </BuStyled>
      </div>
    </Fragment>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withLoading(View);

export default LoginViewWithErrorAndLoading;
