let callback;
const initFullscreenControl = (map, refFullScreen, cb) => {
    map.controls[window.google.maps.ControlPosition.RIGHT_TOP].push(refFullScreen);
    callback = cb;
    const elementToSendFullscreen = map.getDiv().firstChild;
    refFullScreen.onclick = () => {
        if (isFullscreen(elementToSendFullscreen)) {
            exitFullscreen();
            return cb(false)
        } else {
            requestFullscreen(elementToSendFullscreen);
            return cb(true)
        }
    };
}

const exitHandler = () => {
    if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
        callback(false)
    }
}

const isFullscreen = (element) => {
    return (
        (document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullScreenElement ||
            document.msFullscreenElement) == element
    );
}

const requestFullscreen = (element) => {
    if (element.requestFullscreen) {
        element.requestFullscreen();
    } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen();
    } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
    } else if (element.msRequestFullScreen) {
        element.msRequestFullScreen();
    }
    document.addEventListener('fullscreenchange', exitHandler);
    document.addEventListener('webkitfullscreenchange', exitHandler);
    document.addEventListener('mozfullscreenchange', exitHandler);
    document.addEventListener('MSFullscreenChange', exitHandler);
}

const exitFullscreen = () => {
    if (document.exitFullscreen) {
        document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
    }
}

export default initFullscreenControl;
