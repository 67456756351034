import {
  compose,
} from 'recompose';
import BulkDelete from './BulkDelete';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import { TOGGLE_STATE } from '../../common/GqlAPI';

const ComposedBulkDelete = compose(
  TOGGLE_STATE,
  withRequestTracker({
    mutation: {
      toggleState: TOGGLE_STATE,
    },
  }),
)(BulkDelete);

export default ComposedBulkDelete;
