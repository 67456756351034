import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import InvoicedDetailChildView from './childView';
import { TableBody } from '../tableConfig';
import AccordionTable from '../../../../../components/CollapsibleTable/AccordionTable';
import { LIMIT, PAGE } from '../../../../../data/enums/GeneralConstants';

const propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.shape({
    list: PropTypes.array,
    total: PropTypes.number,
  }),
  activeLine: PropTypes.shape({
    id: PropTypes.number,
    sellerId: PropTypes.number,
  }),
  stateSetter: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onCallRowClick: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  selectedOrders: PropTypes.instanceOf(Array),
  onOrderStatusChangeClick: PropTypes.func.isRequired,
};

const defaultProps = {
  data: {
    list: [],
    total: 0,
    limit: LIMIT,
    page: PAGE,

  },
  activeLine: {
    id: '',
    sellerId: 0,
  },
  loading: false,
  selectedOrders: [],
};

const InvoicedDetailView = ({ ...props }) => {
  const {
    data,
    loading,
    onRowClick,
    activeLine,
    onOrderStatusChangeClick,
    selectedOrders,
    onIconClick,
    checkBoxStatus,
    onPrimaryCheckBoxClick,
    onSecondaryCheckBoxClick,
  } = props;

  return (
    <tbody>
      {
        <AccordionTable>
          {
          data.map(invoiced => (
            <tr
              key={invoiced.id}
              trigger={TableBody({
                data: invoiced,
                onRowClick,
              })}
            >

              {
                  invoiced.orderIds.length > 0
                    ? (
                      InvoicedDetailChildView({
                        data: invoiced.Orders || [],
                        checkBoxStatus,
                        onPrimaryCheckBoxClick,
                        onSecondaryCheckBoxClick,
                        onOrderStatusChangeClick,
                        selectedOrders,
                        activeLine,
                        amountDetails: invoiced.amountDetails || {},
                        onIconClick,
                        loading,
                        billDiscountDetail: invoiced.billDiscountDetail || [],
                      })
                    ) : (<Fragment />)
                }
            </tr>))}
        </AccordionTable>
    }
    </tbody>
  );
};

InvoicedDetailView.propTypes = propTypes;

InvoicedDetailView.defaultProps = defaultProps;


export default InvoicedDetailView;
