import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import { Form, Icon, Input } from '../../../../../components';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import CustomSelect from '../../../../../components/CustomSelect/CustomSelect';
import CustomAutoComplete from '../../../../../components/CustomAutoComplete';
import { remarksList } from './config';

const propTypes = {
  subDUser: PropTypes.bool,
  handleInputChange: PropTypes.func,
  data: PropTypes.instanceOf(Object),
  enableErrorDisplay: PropTypes.bool,
  update: PropTypes.instanceOf(Object),
  handleDropDownChange: PropTypes.func,
  refsObj: PropTypes.instanceOf(Object),
  outletList: PropTypes.instanceOf(Array),
  distributorList: PropTypes.instanceOf(Array),
};

const defaultProps = {
  data: {},
  refsObj: {
    title: '',
  },
  subDUser: false,
  enableErrorDisplay: true,
  update: {
    type: EVENT_OPERATION.CREATE,
    status: false,
  },
  outletList: [],
  distributorList: [],
  handleDropDownChange: () => null,
  /**
   * agruments:
   * first param: input event (required),
   * second param: data's first depth targeted key,
   * third param:
   *  only if object depth greater than one,
   *  should contain list of object keys in accordance of depth upto end i.e target key
   */
  handleInputChange: () => null,

};

const SrnSkuForm = ({ ...props }) => {
  const {
    data,
    update,
    subDUser,
    refsObj,
    outletList,
    customerList,
    distributorList,
    handleInputChange,
    invoiceNumberList,
    enableErrorDisplay,
    distributorServices,
    handleDropDownChange,
    invoiceNumberStatus,
    loading,
    loadingInvoiceStatus,
  } = props;

  const onInvoiceNumberSelection = (formattedValue, name) => {
    handleInputChange({ formattedValue, target: { name } });
  };

  return (
    <Form className="">
      <Row>
        <Col md={9}>
          <Row>
            {
               !subDUser && (
               <Fragment>
                 <Col className="mb-24" md={8}>
                   <CustomSelect
                     enableValidation
                     labelContent="Distributor"
                     options={distributorList}
                     className="custom-select"
                     placeholder="Select Distributors"
                     getOptionValue={({ id }) => id}
                     getOptionLabel={({ title }) => title}
                     enableErrorDisplay={enableErrorDisplay}
                     ref={ref => refsObj.distributorId = ref}
                     onChange={event => handleDropDownChange(event.id, ['Distributor', 'id'], 'distributor', event)}
                     value={distributorList.filter(({ id }) => id === data.Distributor.id)}
                   />
                 </Col>
               </Fragment>
               )
            }
            <Col className="mb-48" md={4}>
              <div className="grn-label-text">
                <CustomAutoComplete
                  name="salesInvoiceNumber"
                  onChange={handleInputChange}
                  dropDownList={invoiceNumberList}
                  labelContent="Sales Invoice Number"
                  dropDownValueKey={null}
                  dropDownDisplayKey={null}
                  enableValidation
                  ref={ref => refsObj.salesInvoiceNumber = ref}
                  enableErrorDisplay={enableErrorDisplay}
                  disabled={loadingInvoiceStatus}
                  onDropDownSelection={onInvoiceNumberSelection}
                />
                { invoiceNumberStatus && (
                  <div className="success-label">
                    <Icon iconName="check-circle-full" />
                    <span>Valid Number</span>
                  </div>
                )}
              </div>
            </Col>
            {
                !distributorServices.billing.status && (
                <Col className="mb-48" md={4}>
                  <Input
                    name="salesReturnInvoiceNumber"
                    type="text"
                    value={data.salesReturnInvoiceNumber}
                    labelContent="Sales Return Invoice Number"
                    placeholder="Sales Return Invoice Number"
                    onChange={event => handleInputChange(event)}
                  />
                </Col>
                )
              }
            {/* <Col className="mb-24" md={6}>
                <CheckBox
                  labelText="Cash"
                  checked={data.cashStatus}
                  onCheckBoxClick={e => handleInputChange({ formattedValue: e.target.checked, target: { name: 'cashStatus' } })}
                />
              </Col> */}
            <Col className="mb-24" md={8}>
              <CustomSelect
                enableValidation
                options={outletList}
                labelContent="Outlet"
                className="custom-select"
                placeholder="Select Outlet"
                getOptionValue={({ id }) => id}
                getOptionLabel={({ title }) => title}
                ref={ref => refsObj.outletId = ref}
                enableErrorDisplay={enableErrorDisplay}
                disabled={update.type === EVENT_OPERATION.APPROVE}
                onChange={event => handleDropDownChange(event.id, ['RetailOutlet', 'id'], 'outlet')}
                value={outletList.filter(({ id }) => id === data.RetailOutlet.id) || []}
              />
            </Col>
            <Col className="mb-24" md={4}>
              {/* ref={ref => refsObj.customerId = ref} */}
              <CustomSelect
                 /* enableValidation */
                options={customerList}
                labelContent="Ledger"
                className="custom-select"
                placeholder="Select Ledger"
                getOptionValue={({ id }) => id}
                getOptionLabel={({ name }) => name}
                 /* enableErrorDisplay={enableErrorDisplay} */
                disabled={!data.RetailOutlet.id}
                /*  ref={ref => refsObj.customerId = ref} */
                onChange={event => handleDropDownChange(event.id, ['Customer', 'id'], 'customer')}
                value={customerList.filter(({ id }) => id === data.Customer.id)}
              />
            </Col>
            <Col className="mb-24" md={4}>
              <Input
                disabled
                name="address"
                type="text"
                value={data.Customer.address}
                labelContent="Address"
                placeholder="Address"
              />
            </Col>
            <Col className="mb-24" md={4}>
              <Input
                disabled
                name="panNumber"
                type="number"
                value={data.Customer.panNumber}
                labelContent="PAN"
                placeholder="PAN Number"
              />
            </Col>
            <Col className="mb-24" md={8}>
              <CustomSelect
                ref={ref => refsObj.reason = ref}
                enableValidation
                labelContent="Remarks"
                name="reason"
                placeholder="Add Remarks"
                className="custom-select"
                options={remarksList}
                getOptionValue={({ remark }) => remark}
                getOptionLabel={({ remark }) => remark}
                onChange={event => handleDropDownChange(event.remark, ['reason'])}
                value={remarksList.filter(({ remark }) => remark === data.reason)}
                isSearchable={false}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

SrnSkuForm.propTypes = propTypes;

SrnSkuForm.defaultProps = defaultProps;

export default SrnSkuForm;
