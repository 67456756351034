import {
  compose,
} from 'recompose';
import ForgotPassword from './ForgotPassword';
import { query, mutation, ResetPasswordAPI } from './ResetAPI';
import withRequestTracker from '../../../utils/composition/withRequestTracker';


const ComposedReset = compose(ResetPasswordAPI, withRequestTracker({
  query,
  mutation,
}))(ForgotPassword);

export default ComposedReset;
