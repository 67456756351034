import {
  compose,
} from 'recompose';
import Territory from './Territory';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  CREATE_TERRITORY, UPDATE_TERRITORY, query, mutation,
} from './API';
import { TOGGLE_STATE } from '../../common/GqlAPI';


const ComposedTerritory = compose(
  CREATE_TERRITORY,
  UPDATE_TERRITORY,
  TOGGLE_STATE,
  withRequestTracker({
    query,
    mutation,
  }),
)(Territory);

export default ComposedTerritory;
