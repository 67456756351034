import styled from 'styled-components';

const ConfigPanelStyled = styled.div`
    padding-bottom:48px;
   &:last-child{
     padding-bottom:0;
   }
  .config-panel-card{
    background-color:#FFFFFF;
    box-shadow:0 4px 8px 0 rgba(39,40,51,0.12);
    .config-panel-title{
      padding:5px 25px;
      margin:0;
      background: #f1f2f5;
      font-size: 14px;
      font-weight: 600;
      text-transform:uppercase;
      border-bottom:1px solid #CDCED9;
    }
    .config-panel-contents{
      padding:32px 48px 48px 48px;
    }
    &.sku {
      .config-panel-contents{
        padding:48px 0 18px 0;
      }
      .table-wrap {
        overflow-x: auto;
      table{
        border:0;
        margin-bottom: 50px;
        thead{
          tr{
            height:22px;
              th{
                background: none;
                border: none;
                border-bottom: solid 1px #e7e7ed;
                padding: 0 18px;
                font-size:14px;
                line-height:21px;
                color:#6B6C7E;
                &:first-child{
                  padding-left:24px;
                }
                &:last-child{
                  padding-right:24px;
                }
                }
                &:hover{
                  background-color:#ffffff;
                }
          }
        }
      tbody{
        tr{
          height:54px;
        }
      td{
          border-left:0;
          border-right:0;
          white-space:nowrap;
          padding:0 16px;
          font-size:12px;
          line-height:15px;
          &:first-child{
            padding-left:24px;
            border-left:0;
          }
          &:last-child{
            padding-right:24px;
            border-right:0;
          }
        }
      }
        tr{
        transition: all 0.3s ease-in-out;
        &:hover, &:focus {
          background: #eeeeee;
        }
        }
      }
      }
    }
  }
  .pad-0{
      padding:0;
    }

    .grn-sku-add {
    border-bottom: 1px solid #e7e7ed;
    padding: 24px 0 24px 20px;
    margin-top: -50px;

    &:empty {
      border: none;
      padding: 0;
    }
  }

  .grn-sku-update {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    padding: 24px 32px;
    border-bottom: 1px solid #e7e7ed;

    input[type="number"] {
      font-size: 14px;
      padding: 2px 8px;
      height: auto;
      text-align: right;
    }

    &-inner {
      max-width: 263px;
      font-size: 14px;
      color: #6b6c7e;
    }

    .col-md-6 {
      margin-bottom: 16px;
      padding-right: 24px;
    }
  }

  .grn-sku-update-total {
    display: flex;
    justify-content: flex-end;
    padding: 24px 48px;
    font-size: 14px;
    color: #6b6c7e;
    text-align: right;

    > div {
      width: 140px;
      font-weight: 600;
      color: black;
    }
  }
  .grn-config-inner {
    .modal-inner {
      max-width: 640px;
    }
  }


  .grn-label-text label {
    display: inline-block;
    margin-bottom: 4px;
    color: #272833;
    font-size: 14px;
    font-weight: 600;
  }

  .grn-label-text input {
    background-clip: border-box;
    border: 1px solid #C1C7D0;
    height: 40px;
    min-width: 0;
    padding: 8px 16px;
    display: block;
    width: 100%;
    font-size: 1rem;
    line-height: 1.5;
    color: #030913;
    background: #FAFBFC;
    border-radius: 4px;
    box-shadow: none;
    overflow: visible;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  }

  .grn-label-text input:focus {
    color: #272833;
    background-color: #f0f5ff;
    border-color: #80acff;
    outline: 0;
  }

  .grn-label-text input:not([value=""]) {
    border-color: #47C464;
    background: #EDF9F0;
  }

  .grn-label-text input.focus-visible {
    border-color: #47C464;
    background: #EDF9F0;
  }

  .grn-label-text .success-label {
    font-size: 14px;
    font-weight: 600;
    color: #287D3C;
    margin-top: -20px;

    svg {
      margin-right: 4px;
    }
  }


`;
export default ConfigPanelStyled;
