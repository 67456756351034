import React, { Fragment } from 'react';
import { TableHeader, TableBody } from './tableConfig';
import { Pagination } from '../../../../components';
import withErrorAndLoadingScreen from '../../../../utils/composition/withLoading';

const View = ({ ...props }) => {
  const {
    tableConfig, pagination, onPageChange,
    data, onStatusChanges,
  } = props;
  return (
    <Fragment>
      <div className="table-wrap">
        <table>
          { TableHeader(tableConfig) }
          <tbody>
            {data.list.map(outstandings => (
              TableBody({
                data: outstandings,
                tableConfig,
                onStatusChanges,
              })
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </Fragment>
  );
};


export default withErrorAndLoadingScreen(View);
