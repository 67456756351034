import { userprofile } from '../../data/enums/Route';
import { FORM_CONFIG } from '../../data/enums/config';
import { EVENT_OPERATION } from '../../data/enums/EventOperation';
import { refGenerator } from '../../utils/refGenerator';

const title = 'Profile';

const breadCrumb = [
  userprofile,
];

const getUserProfileData = id => ({
  id,
  fullName: '',
  email: '',
  phone: '',
  password: '',
  userGroup: '',
  SUB_D: '',
});


const formConfig = {
  [EVENT_OPERATION.UPDATE]: {
    mapper: element => ({
      password: element.oldpassword || '',
      newPassword: element.newPassword || '',
      confirmPassword: element.confirmPassword || '',
    }),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['password', 'newPassword', 'confirmPassword']),
    [FORM_CONFIG.TITLE]: 'Change Password',
    buttonName: 'Change',
  },
};

export {
  breadCrumb as breadCrumbConfig, title, getUserProfileData, formConfig,
};
