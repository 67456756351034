import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, CheckBox, Search, Switch,
} from '../../../../../components';

const propTypes = {
  skuList: PropTypes.arrayOf([]).isRequired,
  checkedSkus: PropTypes.arrayOf([]).isRequired,
  onCreateGroup: PropTypes.func.isRequired,
  setCheckedSkus: PropTypes.func.isRequired,
  getSBDStatus: PropTypes.func.isRequired,
  skus: PropTypes.arrayOf({
    id: PropTypes.number,
    title: PropTypes.string,
  }).isRequired,
  handleSBDSubmit: PropTypes.func.isRequired,
  skuGroupList: PropTypes.arrayOf({
    id: PropTypes.number,
    count: PropTypes.number,
    skus: PropTypes.array,
  }).isRequired,
  totalSkus: PropTypes.arrayOf({
    id: PropTypes.number,
    title: PropTypes.string,
  }).isRequired,
};

const searchWithData = (data, text) => {
  const search = d => d.title.replace(/\s+/g, '').search(text) !== -1;
  return data.filter(d => search(d));
};

const SBDDistribution = ({ ...props }) => {
  const {
    skuList, checkedSkus, onCreateGroup, setCheckedSkus, getSBDStatus, skus, handleSBDSubmit,
    skuGroupList, totalSkus,
  } = props;
  const [skuLists, setSkuLists] = useState(skuList);
  const [skuGroupLists, setSkuGroupLists] = useState(skuGroupList);

  const handleInput = (text) => {
    const groupSearchFilter = sku => searchWithData(totalSkus, text).filter(d => sku === d.id).length !== 0;
    const groupFilter = group => group.skus.filter(sku => groupSearchFilter(sku));
    const groupFilterData = skuGroupList.filter(group => groupFilter(group).length !== 0);
    setSkuLists(searchWithData(skuList, text));
    setSkuGroupLists(groupFilterData);
  };

  const skuGroupFunction = (skus, i) => {
    if (i === 0) {
      return 'sku-group';
    }
    if (skus.length === 3 && (i === 1)) {
      return 'sku-group';
    }
    if (skus.length === 4 && (i === 1 || i === 2)) {
      return 'sku-group';
    }
    if (skus.length === 5 && (i === 1 || i === 2 || i === 3)) {
      return 'sku-group';
    }
    return null;
  };

  useEffect(() => {
    if (skuList.length !== skuLists.length) {
      setSkuLists(skuList);
    }
    if (skuGroupList.length !== skuGroupLists.length) {
      setSkuGroupLists(skuGroupList);
    }
  }, [skuList, skuGroupList]);

  return (
    <div className="sbd-panel flex-1 sbd-table">
      <div className="sbd-panel-list">
        <div className="sbd-detail-table">
          <span className="info-text">
              ↙↙ Please choose tabs and enable SBD for each SKU. Check multiple SKU to create group SBD.
          </span>
          <div className="dropdown-head">
            <h3>SBD Distribution</h3>
            <Search handleSearchInput={handleInput} />
          </div>
          {checkedSkus.length !== 0
          && (
            <div className="item-selected">
              <span className="text">{`${checkedSkus.length} of ${skuList.length} items selected`}</span>
              <Button primary onClick={() => onCreateGroup()}>Create Group</Button>
            </div>
          )}
          <div className="table-wrap">
            <table>
              <thead>
                <tr>
                  <th />
                  <th>SKU</th>
                  <th className="text-right">Status</th>
                </tr>
              </thead>
              <tbody>
                {skuGroupLists.length !== 0 && skuGroupLists.map(d => (
                  <Fragment key={d.id}>
                    {d.skus.map((id, i) => (
                      <tr key={id} className={skuGroupFunction(d.skus, i)}>
                        <td />
                        <td>{totalSkus.find(d => d.id === id) && totalSkus.find(d => d.id === id).title}</td>
                        <td className="text-right">
                          {i === 0 && (
                            <Switch
                              checked={getSBDStatus(id) || skus.includes(id)}
                              onToggle={e => handleSBDSubmit(d.skus[i], e)}
                            />
                          )}
                        </td>
                      </tr>
                    ))}
                  </Fragment>
                ))}
                {skuLists.map(d => (
                  <tr key={d.id} className={checkedSkus.includes(d.id) ? 'list-active' : ''}>
                    <td>
                      <CheckBox
                        checked={checkedSkus.includes(d.id)}
                        onCheckBoxClick={() => { setCheckedSkus(d.id); }}
                        disabled={getSBDStatus(d.id) || skus.includes(d.id)}
                      />
                    </td>
                    <td>{d.title}</td>
                    <td className="text-right">
                      {<Switch
                        checked={getSBDStatus(d.id) || skus.includes(d.id)}
                        onToggle={e => handleSBDSubmit([d.id], e)}
                      />}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
SBDDistribution.propTypes = propTypes;

export default SBDDistribution;
