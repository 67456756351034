import gql from 'graphql-tag';
import { graphql } from 'react-apollo/graphql';

const TERRITORY_FRAGMENT = gql`
 fragment TerritoryFragment on Territory {
  id
  title
  Towns {
    id
    title
  }
}`;

const GET_TERRITORY_LIST = gql`
 query getTerritory($offset: Int, $limit: Int, $filter: FilterInput) {
    territories(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        ...TerritoryFragment
      }
      count
    }
  }
  ${TERRITORY_FRAGMENT}
`;

const CREATE_TERRITORY = graphql(gql`
  mutation ($title: String!) {
    createTerritory(title: $title)
    {
      id title
    }
  }`, {
  props: ({ mutate }) => ({
    createTerritory: variables => mutate({
      variables,
    }),
  }),
});

const UPDATE_TERRITORY = graphql(gql`
  mutation ($id: Int!, $title: String!) {
    updateTerritory(id: $id, title: $title)
    {
      id title
    }
  }`, {
  props: ({ mutate }) => ({
    updateTerritory: variables => mutate({
      variables,
    }),
  }),
});

const query = { getTerritoryList: GET_TERRITORY_LIST };

const mutation = {
  createTerritory: CREATE_TERRITORY,
  updateTerritory: UPDATE_TERRITORY,
};

export {
  CREATE_TERRITORY, UPDATE_TERRITORY, GET_TERRITORY_LIST, TERRITORY_FRAGMENT,
};

export { query, mutation };
