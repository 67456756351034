import arcApi from './ApiRest'

function downloadRoutes(permanentOptimumRoutes) {
    let routesWithOutlets = permanentOptimumRoutes.filter(
        item => item.distance !== 0,
    );

    let filteredRoutes = routesWithOutlets.map((route, i) => {
        let filteredTravel = route.travel.map(outlet => {
            return {
                category: outlet.category ? outlet.category : outlet.categoryName,

                categoryId: outlet.categoryId,

                channel: outlet.channel ? outlet.channel : outlet.channelName,

                channelId: outlet.channelId,

                distance: isNaN(outlet.distance) ? 0 : outlet.distance === null ? 0 : outlet.distance,

                id: outlet.id,

                lat: outlet.lat,

                lng: outlet.lng,

                name: outlet.name,

                town: outlet.town ? outlet.town : outlet.townName,

                townId: outlet.townId
            }
        })
        return {
            distance: route.distance,
            dse: route.dse,
            name: route.name,
            travel: filteredTravel
        }
    })

    arcApi.generateCSV({ routes: filteredRoutes })
        .then((response) => {
            const file = new Blob([response], {
                type: 'text/csv',
            });
            const url = window.URL.createObjectURL(file);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${Date.now()}`;
            document.body.appendChild(a);
            a.click();
            a.remove();
        });
}

export default downloadRoutes