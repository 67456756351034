import gql from 'graphql-tag';
import { GET_CHANNEL_LIST, GET_CATEGORY_LIST } from '../../views/configuration/channel/API';
import { persistentClient as clientResolver, client as apolloClient } from '../../apiClient/apollo';

const errorProcessorInFilterDataFetch = () => null;

const getChannelFromServer = () => (
  apolloClient
    .query({
      query: GET_CHANNEL_LIST,
      variables: {
        limit: 1000,
        offset: 0,
        filter: {},
      },
    })
    .then(response => response.data.channels.rows)
    .catch(err => errorProcessorInFilterDataFetch(err))
);

const getCategoryFromServer = () => (
  apolloClient
    .query({
      query: GET_CATEGORY_LIST,
      variables: {
        limit: 1000,
        offset: 0,
        filter: {},
      },
    })
    .then(response => response.data.categories.rows)
    .catch(err => errorProcessorInFilterDataFetch(err))
);

const query = gql`
    query getChannel {
        channel {
            id,
            title,
        }
    }
`;

const categoryQuery = gql`
    query getCategory {
        category {
            id,
            title,
            Channel {
                id
            },
        }
    }
`;

const setCategory = (data) => {
  clientResolver.then(client => client.writeQuery({
    query: categoryQuery,
    data: {
      category: data,
    },
  }));
};

const setChannel = (data) => {
  clientResolver.then(client => client.writeQuery({
    query,
    data: {
      channel: data,
    },
  }));
};


const getChannel = async () => clientResolver
  .then(client => client.readQuery({ query }))
  .then(response => response.channel)
  .catch(err => {
    return []
  })

const getCategory = async () => clientResolver.then(
  client => client.readQuery({ query: categoryQuery }),
)
  .then(response => response.category);


const fetchAndSetChannel = async () => {
  const channelList = await getChannelFromServer();
  setChannel(channelList);
};

const fetchAndSetCategory = async () => {
  const categoryList = await getCategoryFromServer();
  setCategory(categoryList);
};

const fetchAndSetChannelCategory = async () => {
  fetchAndSetChannel();
  fetchAndSetCategory();
};

export {
  getChannel,
  getCategory,
  fetchAndSetChannel,
  fetchAndSetCategory,
  fetchAndSetChannelCategory,
};
