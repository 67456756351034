import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Pagination } from '../../../components';
import CustomSelect from '../../../components/CustomSelect';
import withErrorAndLoadingScreen from '../../../utils/composition/withLoading';
import { PanelStyled } from '../../common/configuration';
import { StockAdjusStyled } from '../../inventory/stockAdjustment/StockAdjusStyled';
import { TableBody, TableHeader } from './tableConfig';

const propTypes = {
  distributorList: PropTypes.instanceOf(Array),
  componentDisplay: PropTypes.bool,
};
const defaultProps = {
  componentDisplay: false,
  distributorList: [],
};

const StockAdjustmentView = ({
  data,
  distributorId,
  pagination,
  onPageChange,
  distributorList,
  onDropDownChange,
  enableErrorDisplay,
  componentDisplay,
  filterTableConfig,
}) => (
  <StockAdjusStyled>
    {
    componentDisplay && (
      <PanelStyled>
        <div>
          <CustomSelect
            enableValidation
            options={distributorList}
            className="custom-select"
            placeholder="Select Distributor"
            getOptionValue={({ id }) => id}
            getOptionLabel={({ title }) => title}
            enableErrorDisplay={enableErrorDisplay}
            onChange={event => onDropDownChange(event.id)}
            value={distributorList.filter(({ id }) => id === distributorId) || []}
          />
        </div>
      </PanelStyled>
    )
    }
    <Fragment>

      {
          data.list.length > 0 ? (
            <div className="table-wrap">
              <table>
                {
            TableHeader({ tableConfig: filterTableConfig })
          }
                <tbody>
                  {data.list.map(stockReplacement => (
                    TableBody({ data: stockReplacement, componentDisplay })
                  ))}
                </tbody>
              </table>
            </div>) : null

        }
    </Fragment>
    { data.list.length > 1 && (
    <Pagination
      currentPage={pagination.page}
      limit={pagination.limit}
      totalItems={data.total}
      onPageChange={onPageChange}
    />
    )}
  </StockAdjusStyled>
);

StockAdjustmentView.propTypes = propTypes;

StockAdjustmentView.defaultProps = defaultProps;

const StockAdjustmentViewWithErrorAndLoading = withErrorAndLoadingScreen(StockAdjustmentView);

export default StockAdjustmentViewWithErrorAndLoading;
