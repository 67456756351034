import gql from 'graphql-tag';
import { DOWNLOAD_REPORT } from '../../common/GqlAPI';

const GET_USER_CLUSTER_LIST = gql`
    query getUserClusterReport($offset: Int, $limit: Int, $filter: FilterInput) {
        userClusters(offset: $offset, limit: $limit, filter: $filter) {
            rows {
                id
                userId
                User {
                    id
                    firstName
                    lastName
                }
                RetailOutlet {
                    id
                    title
                    Category {
                        id
                        title
                        Channel {
                            id
                            title
                        }
                    }
                }
                date
                callStatus
                Call {
                    id
                    callDate
                    type
                    callOrderValue {
                        grossAmount
                        netAmount
                    }
                    notes
                    callTime {
                        callStart
                        callEnd
                    }
                }
              
            }
            count
        }
    }
`;

const query = {
  getUserClusterReport: GET_USER_CLUSTER_LIST,
};

const mutation = {
  downloadReport: DOWNLOAD_REPORT,
};

export {
  DOWNLOAD_REPORT,
};

export { query, mutation };
