import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../index';
import { debouncer } from '../../utils/formHandlers';
import Input from '../Input/Text';
import SearchStyled from './SearchStyled';
import Button from '../Button';

const propTypes = {
  hideInput: PropTypes.bool,
  clearText: PropTypes.bool,
  searchText: PropTypes.string,
  labelContent: PropTypes.string,
  displayCrossIcon: PropTypes.bool,
  handleSearchInput: PropTypes.func,
};

const defaultProps = {
  labelContent: '',
  hideInput: true,
  searchText: '',
  clearText: false,
  displayCrossIcon: false,
  handleSearchInput: () => null,
};

class Search extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.searchText && !prevState.value && nextProps.searchText !== prevState.searchText) {
      return {
        value: nextProps.searchText,
        searchText: nextProps.searchText,
        active: nextProps.searchText ? true : !nextProps.hideInput,
      };
    }
    if(nextProps.clearText && prevState.searchText) {
      return {
        value: nextProps.searchText,
        searchText: nextProps.searchText,
      }
    }
    return {
      searchText: nextProps.searchText,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      active: !props.hideInput,
      value: props.searchText,
      searchText: props.searchText,
    };
  }

  componentDidMount() {}

  toggleInputField = () => {
    const { active, value } = this.state;
    const { handleSearchInput } = this.props;
    const searchValueExist = value;
    this.setState({ active: !active, value: '' },
      () => {
        if (searchValueExist) debouncer(handleSearchInput, 300)('');
      });
  };

  handleInputChange = (value) => {
    const { handleSearchInput } = this.props;

    this.setState({ value }, () => debouncer(handleSearchInput, 300)(value));
  };

  clearText = () => {
    const { handleSearchInput } = this.props;
    this.setState({ value: '' }, () => handleSearchInput(''));
  };

  renderInput = () => {
    const { labelContent, displayCrossIcon } = this.props;
    const { value, active } = this.state;
    return (
      <Fragment>
        <Input
          type="text"
          name="search text"
          value={value}
          placeholder="Search"
          labelContent={labelContent}
          onChange={e => this.handleInputChange(e.target.value)}
          autoFocus={active}
        />
        {
          value && displayCrossIcon && (
            <Icon
              onClick={e => this.clearText(e)}
              iconName="trash"
            />
          )
        }
      </Fragment>
    );
  };

  render() {
    const { active } = this.state;
    const { hideInput } = this.props;
    return (
      <SearchStyled>
        <div className="search-wrapper">
          {/**  if input fields only displays after search icon click * */}
          <div className={`search-input ${active ? 'bg-color' : ''}`}>
            {active && hideInput && this.renderInput()}
            {!hideInput && this.renderInput()}
            <div>
              <Button
                iconBtnSmall
                search
                onClick={e => this.toggleInputField(e)}
                iconName="search"
              />
            </div>
          </div>
          {/** if input field display is not controlled by search icon click * */}
        </div>
      </SearchStyled>
    );
  }
}

Search.propTypes = propTypes;

Search.defaultProps = defaultProps;

export default Search;
