import { FILTER } from '../../../data/enums/filter';
import { getFilterConfig } from '../../../utils/filter';
import { inventory as inventoryRoute } from '../../../data/enums/Route';

const breadCrumb = [
  inventoryRoute,
];

const filter = getFilterConfig([FILTER.SUB_D]);

export { breadCrumb as breadCrumbConfig, filter as filterConfig };
