import React, { Component } from 'react';
import MultiDropDownStyled from '../MultiDropDown/MultiDropDownStyled';
import { Icon } from '../../../../../components';

class RouteActions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
    };

    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  showMenu(event) {
    this.setState({ showMenu: true }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  }

  closeMenu() {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  }

  render() {
    return (
      <MultiDropDownStyled>
        <span className="dropdown">
          <button type="button" className="borderless ellipse" onClick={this.showMenu}><Icon className="simple-popup-actions" iconName="ellipsis-v" /></button>
          {
                        this.state.showMenu
                          ? (
                            <ul className="menu">
                              <li onClick={() => this.props.showRenameModal(this.props.routeIndex)}>
                                <Icon className="pencil" iconName="pencil" />
                                <span>Rename</span>
                              </li>
                              <li onClick={() => this.props.showDeleteModal(this.props.routeIndex)}>
                                <Icon className="trash" iconName="trash" />
                                <span>Delete</span>
                              </li>
                            </ul>
                          ) : (null)
                    }
        </span>
      </MultiDropDownStyled>
    );
  }
}
export default RouteActions;
