import {
  compose,
} from 'recompose';
import Classification from './Classification';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  query, mutation, CREATE_TRADE, UPDATE_TRADE, CREATE_CATEGORY, CREATE_CHANNEL, UPDATE_CHANNEL, UPDATE_CATEGORY,
} from './API';
import { TOGGLE_STATE } from '../../common/GqlAPI';

const ComposedClassification = compose(
  CREATE_TRADE,
  UPDATE_TRADE,
  CREATE_CHANNEL,
  UPDATE_CHANNEL,
  CREATE_CATEGORY,
  UPDATE_CATEGORY,
  TOGGLE_STATE,
  withRequestTracker({
    query,
    mutation,
  }),
)(Classification);

export default ComposedClassification;
