import gql from 'graphql-tag';
import { apolloClient, DEFAULT_QUERY_VARIABLES, errorProcessor } from './common';
import {
  GET_ROUTE_VISIT_FREQUENCY_LIST,
  GET_ROUTE_VISIT_TYPE_LIST,
  GET_ROUTE_VISIT_CATEGORY_LIST,
  GET_ROUTE_TITLE_LIST,
} from '../../views/salesForce/route/API';

const getRouteVisitFrequencyList = () => apolloClient.query({
  query: GET_ROUTE_VISIT_FREQUENCY_LIST,
  variables: {
    ...DEFAULT_QUERY_VARIABLES,
  },
})
  .then(response => response.data.routeVisitFrequencyList.rows)
  .catch(err => errorProcessor(err));


const getRouteVisitTypeList = () => apolloClient.query({
  query: GET_ROUTE_VISIT_TYPE_LIST,
  variables: {
    ...DEFAULT_QUERY_VARIABLES,
  },
})
  .then(response => response.data.routeVisitTypes.rows)
  .catch(err => errorProcessor(err));


const getRouteVisitCategoryList = () => apolloClient.query({
  query: GET_ROUTE_VISIT_CATEGORY_LIST,
  variables: {
    ...DEFAULT_QUERY_VARIABLES,
  },
})
  .then(response => response.data.routeVisitCategories.rows)
  .catch(err => errorProcessor(err));

const routeListQuery = gql`
    query routeList {
        routes {
           rows {
               id
               title
           }
        }
    }
`;

const getRouteListFromServer = async () => apolloClient
  .query({
    query: GET_ROUTE_TITLE_LIST,
    variables: {
      ...DEFAULT_QUERY_VARIABLES,
    },
  })
  .then(res => res.data.routes.rows || [])
  .catch(err => errorProcessor(err));


export {
  getRouteVisitFrequencyList,
  getRouteVisitTypeList,
  getRouteVisitCategoryList,
  getRouteListFromServer as getRouteList,
};
