import React, { Fragment } from 'react';
import ARCLoadingStyled from './ARCLoadingStyled';

const ARCLoading = ({ children, classNames, title, ...otherProps }) => {
    return (
        <Fragment>
            <ARCLoadingStyled title={title}  {...otherProps} className="loading-main">
                <div className="loading-wrap">
                    <div className="spinner-wrap">
                        <div className="spinner" />
                    </div>
                    <p style={{ margin: "8px 0" }}>{title}</p>
                </div>
            </ARCLoadingStyled>
        </Fragment>
    );
};

export default ARCLoading;
