import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SplitForm from './SplitForm';
import { getSkuObj } from '../config';
import { NewDialogWrapper } from '../../../../../common';
import { FORM_CONFIG } from '../../../../../../data/enums/config';
import withAlert from '../../../../../../utils/composition/withAlert';
import { EVENT_OPERATION } from '../../../../../../data/enums/EventOperation';
import { debouncer } from '../../../../../../utils/formHandlers';
import { ALERT_TYPE } from '../../../../../../data/enums/AlertType';

const propTypes = {
  update: PropTypes.instanceOf(Object),
  enableErrorDisplay: PropTypes.bool,
  skuList: PropTypes.instanceOf(Array),
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  update: {
    type: '',
    status: false,
  },
  skuList: [],
  enableErrorDisplay: true,
  serverResponseWaiting: false,
};

class Split extends Component {
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      data: props.formConfig[FORM_CONFIG.MAPPER]({}),
      valid: false,
      enableErrorDisplay: false,
    };
    this.message = props.formConfig.MESSAGE;
  }

  componentDidMount() {
    this.setInitialData();
  }

  setInitialData = () => {
    const { dialogElement, formConfig } = this.props;
    const data = formConfig[FORM_CONFIG.MAPPER](dialogElement);
    data.splitList.push(data.skuObj);

    this.setState({ data });
  };

  handleInputChange = (event, index) => {
    const { data } = this.state;
    data.splitList[index].quantity = event.formattedValue;
    this.setState({ data },
      () => debouncer(this.checkForValidation, 500)(data, index));
  };

  checkForValidation = (data, index) => {
    const updateData = { ...data };
    const validation = this.getSplittedSkuValidation(data.splitList[index]);
    data.splitList[index].displayError = !validation || false;
    this.handleQuantityUpdate(data, index);
  };

  getSplittedSkuValidation = (sku) => {
    const { data } = this.state;

    return (!((sku.quantity < data.minimumSplitQuantity) || (sku.quantity > data.quantity)));
  }

  getSplittedSKUsValidation = (updatedData) => {
    const { data } = this.state;
    let validation = true;
    let skuQtyTotal = 0;
    updatedData.splitList.forEach((sku) => {
      if (sku.quantity < data.minimumSplitQuantity) {
        // update for max criteria also
        validation = false;
      }
      if (!Number.isNaN(sku.quantity)) {
        skuQtyTotal += sku.quantity;
      }
    });
    if (skuQtyTotal > data.quantity) {
      validation = false;
    }

    return validation;
  };

  handleQuantityUpdate = (updatedData, index) => {
    const data = { ...updatedData };
    const value = data.rateDetails.rlp * data.splitList[index].quantity - data.splitList[index].quantity * data.unitPromotionAmount;
    data.splitList[index].value = value * 1.13;
    data.totalQuantity += data.splitList[index].quantity;
    data.remainingQuantity = (data.quantity - data.totalQuantity);
    this.setState({ data });
  };

  handleAddButtonClick = () => {
    const { data } = this.state;
    const { dialogElement } = this.props;
    const skuObj = getSkuObj(dialogElement);

    data.splitList.push(skuObj);
    data.totalQuantity += data.minimumSplitQuantity;
    data.remainingQuantity -= data.minimumSplitQuantity;
    this.setState(data);
  };

  getAddOptionStatusInForm = () => {
    const { data } = this.state;
    const splitListLength = data.splitList.length;
    const allottedQuantity = data.splitList.reduce((aggregator, sku) => (!Number.isNaN(sku.quantity) ? aggregator + sku.quantity : aggregator), 0);
    const remainingQuantity = data.quantity - allottedQuantity;
    const lastLine = data.splitList[splitListLength - 1] || {};

    return ((remainingQuantity > 0) && (remainingQuantity >= data.minimumSplitQuantity) && lastLine.quantity > 0);
  };

  handleCrossIconClickInForm = (index) => {
    const { data } = this.state;
    data.splitList.splice(index, 1);
    this.setState({ data });
  };

  validateSkuQuantity = () => {
    const { data } = this.state;
    const skuRefs = data.splitList.map(item => item[FORM_CONFIG.REFS_OBJ]);
    const value = !skuRefs.some(item => (this.getSkuLineValidation(item) === false));

    return value;
  };

  getSkuLineValidation = refsObj => (!Object.values(refsObj)
    .find(item => (item.getValidState() === false)))

  getValidationAndMessage = () => {
    const { data } = this.state;
    const validateSkuLines = this.validateSkuQuantity();
    let message = this.message.INVALID;
    let status = true;
    if (validateSkuLines) {
      const allottedQuantity = data.splitList.reduce((aggregator, sku) => (!Number.isNaN(sku.quantity)
        ? aggregator + sku.quantity : aggregator), 0);
      if (allottedQuantity !== data.quantity) {
        status = false;
        message = this.message.QUANTITY_ERROR;
      }
    } else status = false;

    return { status, message };
  };

  handleDialogSubmit = () => {
    const { data } = this.state;
    const {
      onDialogSubmit, displayAlert, formConfig, displayMsg,
    } = this.props;
    const { status, message } = this.getValidationAndMessage();

    if (!status) {
      displayMsg(ALERT_TYPE.CUSTOM_DANGER, message);
    } else onDialogSubmit(EVENT_OPERATION.SPLIT, data);
  };

  render() {
    const {
      onDialogCancel,
      formConfig,
      loading,
      serverResponseWaiting,
    } = this.props;
    const {
      data,
      enableErrorDisplay,
    } = this.state;

    const enableAddOptionInForm = this.getAddOptionStatusInForm();

    const allottedQuantity = data.splitList.reduce((aggregator, sku) => (!Number.isNaN(sku.quantity) ? aggregator + sku.quantity : aggregator), 0);

    return (
      <div style={{ width: '200px' }}>
        <NewDialogWrapper
          onDialogCancel={onDialogCancel}
          onDialogSubmit={this.handleDialogSubmit}
          formConfig={formConfig}
          enableReset={false}
          renderDialog={
            ({}) => (
              <div className="split-form-wrap">
                <SplitForm
                  show
                  data={data}
                  message={this.message}
                  loading={serverResponseWaiting}
                  enableErrorDisplay={enableErrorDisplay}
                  handleInputChange={this.handleInputChange}
                  handleAddButtonClick={this.handleAddButtonClick}
                  totalAllottedQuantity={allottedQuantity}
                  onCrossIconClick={this.handleCrossIconClickInForm}
                  enableAddOption={enableAddOptionInForm}
                />
              </div>
            )}
        />
      </div>

    );
  }
}

Split.propTypes = propTypes;

Split.defaultProps = defaultProps;

export default withAlert()(Split);
