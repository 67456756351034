import {
  compose,
} from 'recompose';
import Grn from './GRN';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  query, mutation,
} from './API';
import { TOGGLE_STATE, UPLOAD, DOWNLOAD_REPORT } from '../../common/GqlAPI';

const ComposedGrn = compose(
  UPLOAD,
  TOGGLE_STATE,
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  }),
)(Grn);

export default ComposedGrn;
