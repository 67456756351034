import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import View from './View';
import { formConfig } from '../config';
import history from '../../../../../utils/history';
import { refGenerator } from '../../../../../utils';
import { has } from '../../../../../utils/objectPrototypes';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import { refValidator } from '../../../../../utils/refGenerator';
import withAlert from '../../../../../utils/composition/withAlert';
import { EVENT_OPERATION, EVENT_OPERATION_MAPPER } from '../../../../../data/enums/EventOperation';
import {
  getPermissionForBillingUser,
  getPermissionForUserAccount,
} from '../../../../base/permission';
import { debouncer, dropdownChange, inputChange } from '../../../../../utils/formHandlers';
import {
  stateToRequestMapper,
  responseToStateMapper,
  getBaseUserData,
  breadCrumbConfig, uniqueFields,
} from './config';
import { USER_ACCOUNT } from '../../../../../data/enums/Route';
import {
  getDataFromLocalStorage,
  CLIENT_STORAGE_TABLE,
} from '../../../../../data/services';
import { USER_ROLE } from '../../../../../data/enums';
import { STATEFUL_ENTITIES } from '../../../../../data/enums/GraphQL';
import { camelToSnake } from '../../../../../utils/stringProcessor';
import { userAccount } from '../../../../common/DomainConfig';
import { DialogFormWrapper } from '../../../../common';
import PasswordField from '../../../../login/resetPassword/PasswordField';
import UserAccountStyled from '../UserAccountStyled';

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    roleId: PropTypes.number,
    lastName: PropTypes.string,
    password: PropTypes.string,
    firstName: PropTypes.string,
    phoneNumber: PropTypes.string,
    parentUserId: PropTypes.number,
  }),
  onDropDownChange: PropTypes.func,
  handleInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  handleDropDownChange: PropTypes.func,
  refsObj: PropTypes.instanceOf(Object),
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  parentUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  ),
  displayAlert: PropTypes.func.isRequired,
  getBillingUsers: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  data: {
    id: 0,
    firstName: 'fa',
    lastName: 'fda',
    email: '',
    phoneNumber: '1234567654',
    password: '123',
    roleId: 0,
    parentUserId: 0,
    Distributor: [],
    Town: [],
    billingStatus: true,
  },
  refsObj: {
    firstName: '',
  },
  roles: [],
  parentUsers: [],
  billingUsers: [],
  enableErrorDisplay: true,
  onDropDownChange: () => null,
  handleInputChange: () => null,
  handleDropDownChange: () => null,
  serverResponseWaiting: false,
};

class UserAccountDetails extends Component {
  constructor(props) {
    super(props);
    const id = has.call(props.match.params, 'id')
      ? parseInt(props.match.params.id, 10)
      : 0;

    this.state = {
      id,
      data: getBaseUserData(id),
      parentUsers: [],
      roles: [],
      enableFormValidation: false,
      resetFormValidation: false,
      enableUniqueFieldError: {
        [uniqueFields.EMAIL]: false,
        [uniqueFields.PHONE]: false,
      },
      crudMode: id ? EVENT_OPERATION.READ : EVENT_OPERATION.CREATE,
      menu: {
        townList: [],
        distributorList: [],
      },
      billingUsers: [],
      type: '',
      newPassword: '',
      repeatPassword: '',
      resetFormReference: refGenerator(['newPassword', 'repeatPassword']),
    };

    this.formReference = refGenerator(formConfig.validationField);
    this.permission = getPermissionForUserAccount();
  }

  componentDidMount() {
    const { crudMode, data } = this.state;
    if (crudMode !== EVENT_OPERATION.CREATE) {
      this.getUserDetail(data.id);
    }
    this.getUserRole();
    this.getParentUsers();
    this.loadDataForDropDown();

    // if Edit Mode: fetch the user Details
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { townIds, distributorIds, roleId } = this.state.data;
    if (townIds && (prevState.data.townIds.length !== townIds.length) && distributorIds.length === 1
      && roleId === USER_ROLE.BILLING_USER) {
      this.getBillingUsers(distributorIds[0]);
    }
  }

  getHeader = () => {
    const { crudMode, data } = this.state;
    const header = crudMode === EVENT_OPERATION.UPDATE ? `  ${data.firstName} ${data.lastName} ` : userAccount.title;
    if (crudMode === EVENT_OPERATION.READ) return ` ${data.firstName} ${data.lastName} `;
    return (
      <span>
        {EVENT_OPERATION_MAPPER[crudMode].toLowerCase()}
        {' '}
        {header}
      </span>
    );
  };

  handleInputChange = (event, firstParam = '', paramList = []) => {
    const { data } = this.state;
    const { name = '' } = event.target;
    const value = event.formattedValue || (event.target && event.target.value) || '';
    // debugger;
    const updatedDetails = inputChange(data, event, firstParam, paramList);
    this.setState({ data: updatedDetails }, () => debouncer(this.checkIfUnique, 1000)(name, value));
  };

  handleDropDownChange = (value, parameterRef = [], callBack = () => null) => {
    const { data } = this.state;
    const updatedData = dropdownChange(data, parameterRef, value);
    this.setState({ data: updatedData });
  };

  checkIfUnique = async (name, value) => {
    if (name === uniqueFields.EMAIL || name === uniqueFields.PHONE) {
      const uniqueCriteriaStatus = this.isUniqueCriteriaValidated(name, value);
      if (uniqueCriteriaStatus) {
        await this.checkUnique(name, value)
          .then(({ enableUniqueFieldError }) => {
            this.setState({ enableUniqueFieldError });
          });
      }
    }
  };

  isUniqueCriteriaValidated = (field, value) => {
    const { data } = this.state;
    if (field === uniqueFields.EMAIL) {
      return (data[field].includes('@'));
    }

    const item = data[field];
    return (item.toString().length === 10 || item.toString().length === 7);
  };

  checkUnique = (field, value) => {
    const { checkIfAlreadyExist, displayAlert } = this.props;
    const { enableUniqueFieldError } = this.state;

    return new Promise((resolve, reject) => {
      checkIfAlreadyExist({
        type: STATEFUL_ENTITIES.USER,
        column: camelToSnake(field) || '',
        value: (value || 0).toString(),
      }, {
        handleSuccess: (response) => {
          const { isAlreadyExist = false } = response.data;
          if (isAlreadyExist) {
            enableUniqueFieldError[field] = isAlreadyExist;
            resolve({ enableUniqueFieldError });
          } else {
            enableUniqueFieldError[field] = false;
            resolve({ enableUniqueFieldError });
          }
        },
        handleError: (error) => {
          displayAlert(ALERT_TYPE.DANGER, error);
          enableUniqueFieldError[field] = false;
          resolve({ enableUniqueFieldError });
        },
      });
    });
  };

  getUserRole = () => {
    const { getUserRoleList, displayAlert } = this.props;
    getUserRoleList(
      {},
      {
        handleSuccess: (response) => {
          const { rows = [] } = (response.data ? response.data.roles || {} : {});
          this.setState({ roles: rows });
        },
        handleError: (error) => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  getBillingUsers = (subDId) => {
    const { getBillingUsers, displayAlert } = this.props;
    getBillingUsers(
      { distributorId: subDId },
      {
        handleSuccess: (response) => {
          const { billingUsers } = response.data.billingUsers || [];
          this.setState({ billingUsers });
        },
        handleError: (error) => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  getParentUsers = (roleId) => {
    const { getUserList, displayAlert } = this.props;
    getUserList(
      {
        offset: 1,
        limit: 1000,
      },
      {
        handleSuccess: (response) => {
          const { rows = [] } = (response.data ? response.data.users || {} : {});
          this.setState({
            parentUsers: [...rows,
              /* ...[{
                id: null, roleId: null, firstName: 'None', lastName: '',
              }]], */
            ],
          });
        },
        handleError: (error) => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  getUserDetail = (userId) => {
    const { getUser, displayAlert } = this.props;
    getUser(
      {
        filter: {
          filters: [
            {
              column: 'id',
              value: [userId.toString()],
            },
            {
              column: 'active',
              value: ['true', 'false'],
            },
          ],
        },
      },
      {
        handleSuccess: (res) => {
          const filteredDataSet = res.data.users ? res.data.users.rows || [] : [];
          const user = responseToStateMapper(filteredDataSet[0]);
          this.setState({ data: user });
        },
        handleError: (err) => {
          displayAlert(ALERT_TYPE.DANGER, err);
        },
      },
    );
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleButtonSubmit = () => {
    const { data, crudMode } = this.state;

    const { createUser, updateUser, displayAlert } = this.props;

    const formValidation = refValidator(this.formReference);

    if (!formValidation) {
      this.setState({ enableFormValidation: true });
    } else {
      const formattedData = stateToRequestMapper(data);

      if (crudMode === EVENT_OPERATION.CREATE) {
        createUser(
          { ...formattedData },
          {
            handleSuccess: (res) => {
              // eslint-disable-next-line max-len
              // const createdUser = res.data.createUser;
              history.push(`/${USER_ACCOUNT}`);
              // this.setState({ data: createdUser });
              // displayAlert(ALERT_TYPE.SUCCESS, 'User Created');
              // push to userList.
            },
            handleError: (err) => {
              this.onAPIRequestFailure(err);
            },
          },
        );
      }

      if (crudMode === EVENT_OPERATION.UPDATE) {
        updateUser(
          {
            ...formattedData,
            id: data.id,
          },
          {
            handleSuccess: (response) => {
              const formattedUser = responseToStateMapper(
                response.data.updateUser,
              );
              this.setState({
                data: formattedUser,
                crudMode: EVENT_OPERATION.READ,
              });
              displayAlert(ALERT_TYPE.SUCCESS, 'User Updated');
            },
            handleError: (err) => {
              this.onAPIRequestFailure(err);
            },
          },
        );
      }
    }
  };

  // read Mode: clear ALL
  // Update Mode: Fetch Data
  handleButtonCancel = () => {
    const { crudMode, data } = this.state;
    const { id } = data;
    if (crudMode === EVENT_OPERATION.CREATE) {
      this.setState({
        data: getBaseUserData(id),
      });
    } else {
      this.getUserDetail(data.id);
      this.setState({ crudMode: EVENT_OPERATION.READ });
    }
  };

  handleEditIconClick = () => {
    this.setState({ crudMode: EVENT_OPERATION.UPDATE });
  };

  loadDataForDropDown = () => {
    const { menu } = this.state;
    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.TOWN)
      .then((response) => {
        menu.townList = response;
        this.setState({ menu });
      });
    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.SUB_D)
      .then((response) => {
        menu.distributorList = response;
        this.setState({ menu });
      });
  };

  resetPassword = (type) => {
    this.setState({
      type,
    });
  }

  onDialogSubmit = () => {
    const {
      newPassword, repeatPassword, id, resetFormReference,
    } = this.state;
    const formValidation = refValidator(resetFormReference);
    const { resetPassword, displayAlert } = this.props;
    if (!formValidation) {
      this.setState({ resetFormValidation: true });
    } else if (newPassword === repeatPassword) {
      resetPassword({
        password: newPassword,
        userId: id,
      },
      {
        handleSuccess: () => {
          displayAlert(ALERT_TYPE.SUCCESS, 'Password changed successfully.');
          this.resetDialog();
        },
        handleError: (error) => {
          this.onAPIRequestFailure(error);
        },
      });
    } else {
      displayAlert(ALERT_TYPE.CUSTOM_DANGER, "Password didn't match ");
    }
  }

  resetDialog = () => {
    this.setState({
      type: '',
      newPassword: '',
      repeatPassword: '',
    });
  }

  onChangePassword = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  render() {
    const { serverResponseWaiting } = this.props;

    const {
      data,
      roles,
      crudMode,
      parentUsers,
      billingUsers,
      enableFormValidation,
      enableUniqueFieldError,
      menu,
      type,
      newPassword,
      repeatPassword,
      resetFormReference,
      resetFormValidation,
    } = this.state;

    const billingPermission = getPermissionForBillingUser(
      data.roleId,
      data.Distributor,
    );

    return (
      <UserAccountStyled>
        {type && (
        <DialogFormWrapper
          formTitle="Password"
          formConfig={{
            title: 'Reset', buttonName: 'Reset', validationRequired: true, refsObj: resetFormReference,
          }}
          handleFormSubmit={this.onDialogSubmit}
          onDialogCancel={() => this.resetDialog()}
          type={type}
          renderDialog={() => (
            <Fragment>
              <PasswordField
                name="newPassword"
                refsObj={resetFormReference}
                label="New Password"
                enableErrorDisplay={resetFormValidation}
                onInputChange={this.onChangePassword}
                value={newPassword}
                enableValidation
              />
              <PasswordField
                name="repeatPassword"
                refsObj={resetFormReference}
                label="Repeat New Password"
                enableErrorDisplay={resetFormValidation}
                onInputChange={this.onChangePassword}
                value={repeatPassword}
                enableValidation
              />
            </Fragment>
          )}
        />
        )}
        <View
          data={data}
          menu={menu}
          roles={roles}
          crudMode={crudMode}
          parentUsers={parentUsers}
          billingUsers={billingUsers}
          refsObj={this.formReference}
          permission={this.permission}
          breadCrumb={breadCrumbConfig}
          loading={serverResponseWaiting}
          billingStatus={data.billingStatus}
          billingPermission={!billingPermission.CREATE}
          enableErrorDisplay={enableFormValidation}
          onInputChange={this.handleInputChange}
          handleButtonCancel={this.handleButtonCancel}
          handleButtonSubmit={this.handleButtonSubmit}
          enableUniqueFieldError={enableUniqueFieldError}
          handleDropDownChange={this.handleDropDownChange}
          handleEditIconClick={this.handleEditIconClick}
          getHeader={this.getHeader}
          resetPassword={this.resetPassword}
        />
      </UserAccountStyled>
    );
  }
}

UserAccountDetails.propTypes = propTypes;

UserAccountDetails.defaultProps = defaultProps;

export { UserAccountDetails };

export default withAlert()(UserAccountDetails);
