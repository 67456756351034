import gql from 'graphql-tag';
import { graphql } from 'react-apollo/graphql';
import { TOGGLE_STATE } from '../../common/GqlAPI';

const GET_TRADE_LIST = gql`
 query getTradeList($offset: Int, $limit: Int, $filter: FilterInput) {
    trades(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        id
        title
        description
        channel: Channels {
          id
          title
          channelType
          active
          tradeId
        }
      }
      count
    }
  }
`;

const CHANNEL_LIST = gql`
   query getChannel($offset: Int, $limit: Int, $filter: FilterInput) {
        channels(offset: $offset, limit: $limit, filter: $filter) {
            rows {
                id
                title
                channelType
                tradeId
                Trade{
                  title
                }
            }
            count
        }
    }
`;

const CATEGORIES_LIST = gql`
    query getCategories($offset: Int, $limit: Int, $filter: FilterInput) {
        categories(offset: $offset, limit: $limit, filter: $filter) {
            rows {
                id
                title
                channelId
                Channels: Channel{
                  title
                  tradeId
                  Trade{
                    title
                  }
                }

            }
            count
        }
    }
`;

const CREATE_TRADE = graphql(gql`
  mutation ($input: TradeInput!) {
    createTrade(input: $input)
    {
      id
      title
    }
  }`, {
  props: ({ mutate }) => ({
    createTrade: variables => mutate({
      variables,
    }),
  }),
});

const UPDATE_TRADE = graphql(gql`
  mutation ($input: TradeInput!) {
    updateTrade(input: $input)
    {
      id
      title
    }
  }`, {
  props: ({ mutate }) => ({
    updateTrade: variables => mutate({
      variables,
    }),
  }),
});

const CREATE_CHANNEL = graphql(gql`
  mutation ($input: ChannelInput!) {
    createChannel(input: $input)
    {
      id
      title
      tradeId
    }
  }`, {
  props: ({ mutate }) => ({
    createChannel: variables => mutate({
      variables,
    }),
  }),
});

const UPDATE_CHANNEL = graphql(gql`
  mutation ($input: ChannelUpdateInput!) {
    updateChannel(input: $input)
    {
      id
      title
    }
  }`, {
  props: ({ mutate }) => ({
    updateChannel: variables => mutate({
      variables,
    }),
  }),
});

const CREATE_CATEGORY = graphql(
  gql`
    mutation($title: String!, $channelId: Int!) {
      createCategory(title: $title, channelId: $channelId) {
        id
        title
        channelId 
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      createCategory: variables => mutate({
        variables,
      }),
    }),
  },
);

const UPDATE_CATEGORY = graphql(gql`
  mutation ($id: Int!, $title: String!, $channelId: Int!) {
    updateCategory(id: $id, title:$title, channelId:$channelId )
    {
      id
      title
    }
  }`, {
  props: ({ mutate }) => ({
    updateCategory: variables => mutate({
      variables,
    }),
  }),
});

const query = {
  getTradeList: GET_TRADE_LIST,
  getChannelList: CHANNEL_LIST,
  getCategoryList: CATEGORIES_LIST,
};

const mutation = {
  createTrade: CREATE_TRADE,
  updateTrade: UPDATE_TRADE,
  createChannel: CREATE_CHANNEL,
  updateChannel: UPDATE_CHANNEL,
  createCategory: CREATE_CATEGORY,
  updateCategory: UPDATE_CATEGORY,
  toggleState: TOGGLE_STATE,
};

export {
  CREATE_TRADE, UPDATE_TRADE, CREATE_CHANNEL, CREATE_CATEGORY, UPDATE_CHANNEL, UPDATE_CATEGORY,
};

export { query, mutation };
