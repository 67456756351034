import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import {
  Input,
} from '../../../../../../components';
import withLoading from '../../../../../../utils/composition/withLoading';
import { EVENT_OPERATION } from '../../../../../../data/enums/EventOperation';
import { EMPTY, INVALID } from '../../../../../../data/enums/ErrorMessage';
import CustomSelectWithBtn from '../../../../../../components/CustomSelectWithBtn';

const propTypes = {
  handleInputChange: PropTypes.func,
  data: PropTypes.instanceOf(Object),
  enableErrorDisplay: PropTypes.bool,
  update: PropTypes.instanceOf(Object),
  handleDropDownChange: PropTypes.func,
  refsObj: PropTypes.instanceOf(Object),
  vendorList: PropTypes.instanceOf(Array),
  distributorList: PropTypes.instanceOf(Array),
};

const defaultProps = {
  data: {},
  refsObj: {
    title: '',
  },
  enableErrorDisplay: true,
  update: {
    type: EVENT_OPERATION.CREATE,
    status: false,
  },
  vendorList: [],
  distributorList: [],
  handleDropDownChange: () => null,
  /**
   * agruments:
   * first param: input event (required),
   * second param: data's first depth targeted key,
   * third param:
   *  only if object depth greater than one,
   *  should contain list of object keys in accordance of depth upto end i.e target key
   */
  handleInputChange: () => null,

};

const DetailForm = ({ ...props }) => {
  const {
    data,
    permission,
    update,
    refsObj,
    vendorList,
    customerList,
    handleIconClick,
    distributorList,
    dropdownCallBack,
    checkboxStatus,
    handleInputChange,
    enableErrorDisplay,
    handleDropDownChange,
  } = props;
  return (
    <>
      <Row>
        <Col md={12}>
          <Row className="invoice-form">
            <Col md={4}>
              <div className="add-ledger-wrap">
                <CustomSelectWithBtn
                  name="customerId"
                  enableValidation
                  options={customerList}
                  placeholder="Ledgers"
                  labelContent="Ledgers"
                  enableErrorDisplay={enableErrorDisplay && permission.BILLING}
                  onChange={event => handleDropDownChange(event.id, ['customer', 'id'])}
                  value={customerList.filter(({ id }) => id === data.customer.id)}
                  handleIconClick={handleIconClick}
                  data={data}
                />
              </div>
            </Col>
            {(data.customer.id !== '')
              ? <>
                <Col md={4}>
                  <div className="info">
                    <label>Address</label>
                    <div className="ledger-info">{data.customer.address}</div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="info">
                    <label>PAN</label>
                    <div className="ledger-info">{data.customer.panNumber}</div>
                  </div>
                </Col>
            </>
              : ''
            }
          </Row>
          <Row className="invoice-form">
            <Col md={4}>
              <Input
                type="text"
                name="remarks"
                enableValidation
                value={data.remarks}
                placeholder="Remarks"
                labelContent="Remarks"
                ref={ref => refsObj.remarks = ref}
                enableErrorDisplay={enableErrorDisplay && permission.BILLING}
                onChange={event => handleInputChange(event)}
              />
            </Col>

            {!permission.BILLING && (
            <Col md={4}>
              <Input
                type="text"
                enableValidation
                name="invoiceNumber"
                placeholder="Invoice Number"
                value={data.invoiceNumber}
                rule="matches"
                argument={(/^[0-9]+$/g)} /* matches only numbers */
                labelContent="Invoice Number"
                ref={ref => refsObj.invoiceNumber = ref}
                enableErrorDisplay={enableErrorDisplay && !permission.BILLING}
                errorMessage={data.invoiceNumber ? INVALID : EMPTY}
                onChange={event => handleInputChange(event)}
              />
            </Col>
            )}
          </Row>

        </Col>
      </Row>
    </>
  );
};

DetailForm.propTypes = propTypes;

DetailForm.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withLoading(DetailForm);

export default LoginViewWithErrorAndLoading;
