import gql from 'graphql-tag';


const GET_PFD_SALES_TARGET = gql`
    query getUserWisePfdTarget($roleId: Int, $userId: Int, $filter:FilterInput) {
        getUserWisePfdTarget(roleId: $roleId, userId: $userId, filter:$filter) {
            ##rows {
                id
                lpc
                bpc
                name
                route
                sbd_count
                fsku_count
                pfd_target
                distributor
                onroute_sales
                offroute_sales
                achievement
          ##  }
            ## count
        }
    }
`;

const query = {
  getPfdSalesTarget: GET_PFD_SALES_TARGET,
};

export { query };
