import {
  compose,
} from 'recompose';
import Route from './Route';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  query,
  mutation,
  CREATE_ROUTE,
  UPDATE_ROUTE,
  DOWNLOAD_REPORT,
} from './API';

const ComposedRoute = compose(
  CREATE_ROUTE,
  UPDATE_ROUTE,
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  }),
)(Route);

export default ComposedRoute;
