import gql from 'graphql-tag';
import { graphql } from 'react-apollo/graphql';

const CHANGE_PASSWORD = graphql(gql`
mutation ($userId: Int!
$oldPassword: String!
$newPassword: String!) {
  changePassword(
    userId: $userId
    oldPassword: $oldPassword
    newPassword: $newPassword
  )
}`, {
  props: ({ mutate }) => ({
    changePassword: variables => mutate({
      variables,
    }),
  }),
});

const mutation = {
  changePassword: CHANGE_PASSWORD,
};

export {
  mutation, CHANGE_PASSWORD,
};
