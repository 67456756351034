/* global google */
const initMap = () => {
    return new Promise((resolve, reject) => {
        let googleMap = new window.google.maps.Map(document.getElementById("map"), {
            center: { lat: 27.6894, lng: 85.3227 },
            zoom: 12,
            mapTypeControl: false,
            streetViewControl: false
        });
        window.globalMap = googleMap;
    })
};

const createScriptLoadMap = () => {
    return new Promise((resolve, reject) => {
        if (!window.initMap) {
            var index = window.document.getElementsByTagName("script")[0];
            window.initMap = initMap;
            var script = window.document.createElement("script");
            script.src =
                "https://maps.googleapis.com/maps/api/js?key=AIzaSyBJkliswV477p2xAyt5mr1nzBnlpx42Co8&libraries=drawing,places&callback=initMap";
            script.async = true;
            script.defer = true;
            script.onload = () => {
                return resolve(window.globalMap)
            };
            index.parentNode.insertBefore(script, index);
            // initMap()
        } else {
            initMap();
            return resolve(window.globalMap)
        }
    })
};


export { initMap, createScriptLoadMap };