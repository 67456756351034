import PropTypes from 'prop-types';
import React, { Component } from 'react';
import history from '../../../../utils/history';
import {
  crudSuccess, formConfig, breadCrumbConfig,
} from './config';
import { refValidator } from '../../../../utils/refGenerator';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import withAlert from '../../../../utils/composition/withAlert';
// eslint-disable-next-line import/no-named-as-default
import { EVENT_OPERATION, EVENT_OPERATION_MAPPER } from '../../../../data/enums/EventOperation';
import { dropdownChange, inputChange } from '../../../../utils/formHandlers';
import {
  CLIENT_STORAGE_TABLE,
  getDataFromLocalStorage,
} from '../../../../data/services';
import View from './View';
import { SUB_D_DETAILS } from '../../../../data/enums/Route';
import { getPermissionForSubD } from '../../../base/permission';
import { subD } from '../../../common/DomainConfig';
import { IMAGE_STATUS_MAPPER } from '../../../components/ImageUpload/config';

const propTypes = {
  getSubDDetail: PropTypes.func.isRequired,
  updateSubD: PropTypes.func.isRequired,
  createSubD: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  syncSkuToBilling: PropTypes.func.isRequired,
  location: PropTypes.objectOf({}).isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

const subDData = {
  title: '',
  active: true,
  type: 'NORMAL',
  townIds: [],
  location: {
    latitude: 27.700769,
    longitude: 85.300140,
  },
  address: '',
  phoneNumber: '',
  owner: '',
  ownerContact: 0,
  panNumber: 0,
  vatNumber: '',
  servicesUsed: {
    billing: {
      status: false,
      url: '',
    },
    delivery: {
      status: false,
      url: '',
    },
    logistic: {
      status: false,
      url: '',
    },
  },
  salesReturnPolicy: {
    full: false,
    partial: false,
    both: false,
  },
  readyStock: false,
  linkedSubDs: [],
  labels: [],
  vendorLedgerId: 0,
  email: '',
  secondaryContact: {
    name: '',
    mobileNumber: '',
    email: '',
  },
  erpDetails: {
    businessPartnerId: '',
    ledgerId: '',
  },
  appPermissions: {
    sales: false,
    retailer: false,
    supervisor: false,
    merchandiser: false,
  },
  marketPlacePermissions: {
    daraz: false,
    meroKirana: false,
    sastoDeal: false,
  },
};

class Detail extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      data: {
        // eslint-disable-next-line react/prop-types
        id: props.match.params ? parseInt(props.match.params.id) : null,
        ...subDData,
      },
      // eslint-disable-next-line react/no-unused-state
      route: {
        outlets: [],
      },
      menu: {
        townList: [],
        distributorList: [],
      },
      labels: [],
      enableFormValidation: false,
      formReference: formConfig.refsObj,
      crudMode: props.match.params.id
        ? EVENT_OPERATION.READ
        : EVENT_OPERATION.CREATE,
      srnService: false,
      vendors: [],
      vendorMapSection: false,
      callHolidaySection: false,
      marketPlace: false,
      skuSync: {
        show: false,
        count: 0,
        status: IMAGE_STATUS_MAPPER.EMPTY,
        progress: 0,
      },
    };
    this.permission = getPermissionForSubD();
    this.getData = () => {
      const { data } = this.state;
      return data;
    };
  }

  componentDidMount() {
    const { data } = this.state;
    if (data.id) {
      this.getSubDInfo();
      this.getBillingVendors();
    }

    this.loadDataForDropDown();
    this.getSubdTags();
  }

  // eslint-disable-next-line react/sort-comp
  componentDidCatch(error, info) {
  }

  getSubDInfo() {
    const { data } = this.state;
    const { getSubDDetail, displayAlert, location } = this.props;
    getSubDDetail(
      {
        id: data.id ? data.id.toString() : location.state.toString(),
      },
      {
        handleSuccess: (response) => {
          // eslint-disable-next-line max-len
          const formattedDetail = crudSuccess[EVENT_OPERATION.UPDATE].objectMapper(response.data.distributors.rows[0]);

          if (!formattedDetail.location) {
            formattedDetail.location = subDData.location;
          }

          this.setState({ data: formattedDetail });
          if (formattedDetail.salesReturnPolicy) {
            this.setState({
              srnService: Object.values(
                formattedDetail.salesReturnPolicy,
              )
                .includes(true),
            });
          }
          if (formattedDetail.marketPlacePermissions) {
            this.setState({
              marketPlace: Object.values(
                formattedDetail.marketPlacePermissions,
              )
                .includes(true),
            });
          }
        },
        handleError: (error) => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  }

  getBillingVendors = () => {
    const { id } = this.state.data;
    const { getBillingVendors } = this.props;

    getBillingVendors({
      distributorId: id,
    }, {
      handleSuccess: (response) => {
        const { billingVendors } = response.data;
        this.setState({
          vendors: billingVendors.rows,
        });
      },
    });
  };


getSubdTags = () => {
  const { getDistributorLabels, displayAlert } = this.props;
  getDistributorLabels(
    {},
    {
      handleSuccess: (res) => {
        this.setState({
          labels:
           res.data.getDistributorLabels,
        });
      },
      handleError: (err) => {
        displayAlert(ALERT_TYPE.DANGER, err);
      },
    },
  );
}

  loadDataForDropDown = () => {
    const { menu } = this.state;
    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.TOWN)
      .then((response) => {
        menu.townList = response;
        this.setState({ menu });
      });
    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.SUB_D)
      .then((response) => {
        menu.distributorList = response;
        this.setState({ menu });
      });
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleInputChange = (event, firstParam = '', paramList = []) => {
    const { data, srnService, marketPlace } = this.state;
    if (firstParam === 'salesReturnPolicy') {
      this.setState({
        srnService: !srnService,
      });
      if (!event.formattedValue) {
        this.setState({
          data: {
            ...data,
            salesReturnPolicy: subDData.salesReturnPolicy,
          },
        });
      }
    } else if (firstParam === 'marketPlacePermissions') {
      this.setState({
        marketPlace: !marketPlace,
      });
      if (!event.formattedValue) {
        this.setState({
          data: {
            ...data,
            marketPlacePermissions: subDData.marketPlacePermissions,
          },
        });
      }
    } else {
      const updatedDetails = inputChange(data, event, firstParam, paramList);
      this.setState({ data: updatedDetails });
      if (paramList.includes('billing')) {
        if (!event.formattedValue) {
          this.setState({
            data: {
              ...data,
              servicesUsed: {
                ...data.servicesUsed,
                billing: subDData.servicesUsed.billing,
              },
              vendorLedgerId: subDData.vendorLedgerId,
            },
          });
        }
      }
    }
  };

  // eslint-disable-next-line no-unused-vars
  handleDropDownChange = (value, parameterRef = [], callBack = () => null) => {
    const { data } = this.state;
    const updatedData = dropdownChange(data, parameterRef, value);
    if (parameterRef[0] === 'Channel') {
      updatedData.Category.id = '';
      updatedData.Category.title = '';
    }
    // if (parameterRef === 'linkedSubDs') {
    //   listLinkedSubDs.push(value);
    //   updatedData.linkedSubDs = listLinkedSubDs;
    // }
    this.setState({ data: updatedData });
  };

  apiRequestTransformer = data => ({
    title: data.title,
    address: data.address,
    townIds: data.townIds,
    active: data.active,
    servicesUsed: data.servicesUsed,
    location: data.location,
    type: data.type,
    owner: data.owner,
    ownerContact: data.ownerContact ? data.ownerContact.toString() : data.ownerConcat,
    // panNumber: data.panNumber.toString(),
    vatNumber: (data.vatNumber || data.vatNumber === 0) ? data.vatNumber.toString() : data.vatNumber,
    linkedSubDs: data.linkedSubDs,
    phoneNumber: (data.phoneNumber || data.phoneNumber === 0) ? data.phoneNumber.toString() : data.phoneNumber,
    readyStock: data.readyStock,
    salesReturnPolicy: data.salesReturnPolicy,
    vendor: data.vendor,
    vendorLedgerId: data.vendorLedgerId,
    email: data.email,
    labels: data.labels,
    secondaryContact: {
      name: data.secondaryContact.name,
      mobileNumber: data.secondaryContact.mobileNumber ? data.secondaryContact.mobileNumber.toString() : '',
      email: data.secondaryContact.email,
    },
    erpDetails: {
      businessPartnerId: data.erpDetails && data.erpDetails.businessPartnerId,
      ledgerId: data.erpDetails && data.erpDetails.ledgerId,
    },
    appPermissions: data.appPermissions,
    marketPlacePermissions: data.marketPlacePermissions,
  });

  handleButtonSubmit = () => {
    const {
      formReference, data, crudMode, callHolidaySection,
    } = this.state;
    const { updateSubD, createSubD, displayAlert } = this.props;
    const formValidation = refValidator(formReference);
    this.setState({
      callHolidaySection: true,
    });

    if (!formValidation) {
      this.setState({ enableFormValidation: true });
    } else {
      const formattedData = this.apiRequestTransformer(data);

      if (crudMode === EVENT_OPERATION.CREATE) {
        createSubD(
          { input: formattedData },
          {
            handleSuccess: (res) => {
              displayAlert(
                ALERT_TYPE.SUCCESS,
                crudSuccess[EVENT_OPERATION.CREATE].message,
              );
              this.setState({
                crudMode: EVENT_OPERATION.READ,
              }, () => {
                history.push(
                  `/${SUB_D_DETAILS}/${res.data.createDistributor.id}`, res.data.createDistributor.id,
                );
              });
            },
            handleError: (err) => {
              this.onAPIRequestFailure(err);
            },
          },
        );
      }


      if (crudMode === EVENT_OPERATION.UPDATE) {
        updateSubD(
          {
            id: data.id,
            input: formattedData,
          },
          {
            handleSuccess: (response) => {
              const formattedDetail = crudSuccess[EVENT_OPERATION.UPDATE].objectMapper(response.data.updateDistributor);

              const { data: stateData } = this.state;
              stateData.id = formattedDetail.id;

              this.setState({ data: stateData, crudMode: EVENT_OPERATION.READ }, () => {
                this.getSubDInfo();
                this.getBillingVendors();
              });

              displayAlert(
                ALERT_TYPE.SUCCESS,
                crudSuccess[EVENT_OPERATION.UPDATE].message,
              );
            },
            handleError: (err) => {
              this.onAPIRequestFailure(err);
            },
          },
        );
      }
    }
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleButtonCancel = () => {
    const { crudMode, data } = this.state;
    if (crudMode === EVENT_OPERATION.CREATE) {
      this.setState({
        data: {
          ...subDData,
          id: data.id,
        },
      });
    } else {
      this.getSubDInfo();
      this.setState({ crudMode: EVENT_OPERATION.READ });
    }
  };

  handleEditIconClick = () => {
    const { match } = this.props;
    const { data } = this.state;
    this.setState({
      crudMode: EVENT_OPERATION.UPDATE,
      data: {
        ...data,
        id: parseInt(match.params.id, 10),
      },
    });
  };

  getLatLng = (geoLocation) => {
    this.setState({
      data: {
        ...this.state.data,
        ...{
          location: {
            ...geoLocation,
          },
        },
      },
    });
  };

  marketChanged = (e) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        ...{
          marketPlacePermissions: {
            ...data.marketPlacePermissions,
            [e.target.name]: e.target.checked,
          },
        },
      },
    });
  };

  srnChanged = (e) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        ...{
          salesReturnPolicy: {
            ...{ [e.target.value.toLowerCase()]: e.target.checked },
          },
        },
      },
    });
  };

  getHeader = () => {
    const { crudMode, data } = this.state;
    const header = crudMode === EVENT_OPERATION.UPDATE ? data.title : subD.title;
    if (crudMode === EVENT_OPERATION.READ) return data.title;

    return <><span>{EVENT_OPERATION_MAPPER[crudMode].toLowerCase()}</span> {header}</>;
  };

  handleDialogOkClick = () => {
    const { data } = this.state;
    const { syncSkuToBilling, displayAlert } = this.props;
    syncSkuToBilling({ distributorId: data.id }, {
      handleSuccess: (res) => {
        const { syncSkuToBilling = {} } = res.data;
        if (syncSkuToBilling) {
          displayAlert(ALERT_TYPE.SUCCESS, `${syncSkuToBilling.count} skus synced to billing server`);
          this.setState({
            skuSync: {
              show: true,
              status: IMAGE_STATUS_MAPPER.SUCCESS,
              progress: 100,
              count: syncSkuToBilling.count,
            },
          });
        } else this.handleSkuSyncFailure();
      },
      handleError: (err) => {
        this.handleSkuSyncFailure();
        this.onAPIRequestFailure(err);
      },
    });
  };

  handleSkuSyncFailure = () => {
    this.setState({
      skuSync: {
        show: true,
        status: IMAGE_STATUS_MAPPER.DANGER,
        progress: 0,
        count: 0,
      },
    });
  };

  handleDialogCancelClick = () => {
    const { skuSync } = this.state;
    this.setState({ skuSync: { ...skuSync, show: false, status: IMAGE_STATUS_MAPPER.EMPTY } });
  };

  handleSkuSync = () => {
    const { skuSync } = this.state;
    this.setState({
      skuSync: {
        ...skuSync, status: IMAGE_STATUS_MAPPER.PENDING, show: true, progress: 10,
      },
    }, () => this.handleDialogOkClick());
  };

  render() {
    const {
      data,
      menu,
      crudMode,
      formReference,
      enableFormValidation,
      srnService,
      marketPlace,
      vendors,
      vendorMapSection,
      callHolidaySection,
      labels,
      skuSync,
    } = this.state;

    const { serverResponseWaiting, match } = this.props;
    return (
      <View
        data={data}
        menu={menu}
        labels={labels}
        crudMode={crudMode}
        refsObj={formReference}
        breadCrumb={breadCrumbConfig}
        loading={serverResponseWaiting}
        onInputChange={this.handleInputChange}
        enableErrorDisplay={enableFormValidation}
        handleButtonSubmit={this.handleButtonSubmit}
        handleButtonCancel={this.handleButtonCancel}
        onDropDownChange={this.handleDropDownChange}
        handleEditIconClick={this.handleEditIconClick}
        getLatLng={this.getLatLng}
        srnChanged={this.srnChanged}
        marketChanged={this.marketChanged}
        srnService={srnService}
        marketPlace={marketPlace}
        vendors={vendors}
        vendorMapSection={vendorMapSection}
        permission={this.permission}
        params={match.params}
        callHolidaySection={callHolidaySection}
        getHeader={this.getHeader}
        handleDialogOkClick={this.handleDialogOkClick}
        handleDialogCancelClick={this.handleDialogCancelClick}
        handleSkuSync={this.handleSkuSync}
        skuSync={skuSync}
      />
    );
  }
}

Detail.propTypes = propTypes;

Detail.defaultProps = defaultProps;

export { Detail };

export default withAlert()(Detail);
