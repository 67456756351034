const customerMapper = element => ({
  id: element.id || null,
  name: element.name || '',
  address: element.address || '',
  panNumber: element.panNumber || '',
});

const remarksList = [
  { remark: 'Outlet Closed' },
  { remark: 'No Cash Available' },
  { remark: 'Authorised Person NA' },
  { remark: 'Wrong Order' },
  { remark: 'Issue in Invoice' },
  { remark: 'Outlet Not Found' },
  { remark: 'Restricted Area' },
];

export { customerMapper, remarksList };
