import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import maxBy from 'lodash/maxBy';
import _sortBy from 'lodash/sortBy';
import Menu from '../Menu';
import { Button } from '../index';
import BaseFilter from './BaseFilter';
import { getDataFromLocalStorage } from '../../data/services';
import { clone } from '../../utils/objectProcessor';
import { compareList } from '../../utils/arrayProcessor';
import { getMenuListWithUserOption } from './filterConfig';

const propTypes = {
  menuList: PropTypes.instanceOf(Object),
  metaData: PropTypes.instanceOf(Object),
  onFilterChange: PropTypes.func,
  refreshState: PropTypes.bool,
  updateMenu: PropTypes.bool,
};

const defaultProps = {
  menuList: {},
  metaData: {},
  refreshState: false,
  updateMenu: false,
  onFilterChange: () => null,
};

// aggregator receiving the
class FilterAggregator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuList: getMenuListWithUserOption(clone(props.menuList)) || {},
      metaData: clone(props.metaData),
    };
  }

  /* componentWillReceiveProps(nextProps) {
    const { menuList = {} } = nextProps;
   // debugger;
    const menuWithUserOption = this.getMenuListWithUserOption(menuList);
    debugger;
    const k = clone(menuWithUserOption);
    debugger;
    this.setState({ menuList: menuWithUserOption});
  } */

  static getDerivedStateFromProps(nextProps, prevState) {
    const menuWithUserOption = getMenuListWithUserOption(nextProps.menuList);
    const compareMenuPropsAndState = compareList(menuWithUserOption, prevState.menuList);
    const compareDataPropsAndState = compareList(nextProps.metaData, prevState.metaData);
    let compareCriteria = (nextProps.refreshState
      ? !compareMenuPropsAndState : compareMenuPropsAndState)
      && !compareDataPropsAndState;
    if (nextProps.updateMenu) {
      const propsKeys = Object.keys(nextProps.menuList);
      const stateKeys = Object.keys(prevState.menuList);
      compareCriteria = !compareList(propsKeys, stateKeys);
    }
    if (compareCriteria) {
      return { menuList: menuWithUserOption, metaData: nextProps.metaData };
    }

    return {
      menuList: prevState.menuList,
      metaData: prevState.metaData,
    };
  }

  handleFilterGroupSelection = (newFilterGroup, oldFilterGroup = null) => {
    const newFilterGroupLabel = newFilterGroup.label;
    const oldFilterGroupLabel = oldFilterGroup.label;
    const { menuList } = this.state;
    const serverCallRequired = menuList[oldFilterGroupLabel].label === 'userOption';
    menuList[newFilterGroupLabel].index = menuList[oldFilterGroupLabel].index;
    menuList[oldFilterGroupLabel].index = 0;
    menuList[oldFilterGroupLabel].selectedIdList = [];
    menuList[newFilterGroupLabel].selectedIdList = [];
    menuList[oldFilterGroupLabel].selectedItemList = [];
    menuList[newFilterGroupLabel].selectedItemList = [];
    this.setState({ menuList });
  };

  handleFilterGroupDelete = (filterGroup) => {
    const { menuList } = this.state;
    const { label } = filterGroup;
    const serverCallRequired = menuList[label].selectedIdList.length > 0;
    menuList[label].index = 0;
    menuList[label].selectedIdList = [];
    menuList[label].selectedItemList = [];
    this.setState({ menuList }, () => serverCallRequired && this.handleFilterChange());
  };

  handleFilterChange = () => {
    // call the link to the filter.
    const { menuList } = this.state;
    this.props.onFilterChange(menuList);
  };

  handleAddClick = () => {
    const { menuList } = this.state;
    const newMenuList = { ...menuList };
    const selectedMenuCount = this.getHighestIndexValue();
    menuList.userOption.index = selectedMenuCount + 1;
    this.setState({ menuList });
  };

  getOptionListForBaseFilter = async (reference) => {
    const optionList = await getDataFromLocalStorage(reference).then(res => res) || [];

    return optionList;
  };

  onCheckBoxClick = (label, itemList, idList) => {
    const { menuList } = this.state;
    menuList[label].selectedItemList = itemList;
    menuList[label].selectedIdList = idList;
    this.setState({ menuList }, () => this.handleFilterChange());
  };

  getUnselectedFilterGroups = () => {
    const { menuList } = this.state;
    const unSelectedList = [];
    const keysList = Object.keys(menuList);
    keysList.map((key) => {
      if (menuList[key].index === 0 && key !== 'userOption') {
        unSelectedList.push(menuList[key]);
      }
    });
    return unSelectedList;
  };

  /**
   * @return {Array} in ascending order of index.
   */
  getSelectedFilterGroups = () => {
    const { menuList } = this.state;
    const arrayList = [];
    const keysList = Object.keys(menuList);
    keysList.map((key) => {
      if (menuList[key].index > 0) {
        arrayList.push(menuList[key]);
      }
    });
    const sortedList = _sortBy(arrayList, 'index');
    return sortedList;
  };

  getHighestIndexValue = () => {
    const { menuList } = this.state;
    const arrayList = [];
    const keysList = Object.keys(menuList);
    keysList.map(key => arrayList.push(menuList[key]));
    const menu = maxBy(arrayList, 'index');
    return menu.index;
    // Object.keys(newMenuList).map(key => newMenuList[key].index > 0 ? count++ : null);
    // return count;
  };

  handleFilterApplyClick = () => {
    const { menuList } = this.state;
    const { onFilterChange } = this.props;
    onFilterChange(menuList);
  };

  handleFilterResetClick = () => {
    const { menuList } = this.state;
    Object.keys(menuList)
      .map((key) => {
        menuList[key].selectedItemList = [];
        menuList[key].selectedIdList = [];
        menuList[key].index = 0;
      });
    this.setState({ menuList }, () => {
      this.props.onFilterChange(menuList);
    });
  };

  // Normally you would want to split things out into separate components.

  // But in this example everything is just done in one place for simplicity
  render() {
    const unSelectedFilterGroups = this.getUnselectedFilterGroups();
    const selectedFilterGroups = this.getSelectedFilterGroups();
    const activeFilterCount = (
      selectedFilterGroups.filter(filter => filter.selectedIdList.length > 0)
    ).length;
    return (
      <Fragment>
        <Menu label="filter" value={activeFilterCount}>
          <div className="filter-label">
            Filter By
            <Button iconBtnSmall secondary iconName="reload" onClick={() => this.handleFilterResetClick()} />
          </div>
          <div className="filter-content">
            {
              selectedFilterGroups.map(filterGroup => (
                <div id={`filter-id-${filterGroup.id}`}>
                  <div className="filter-list-group">
                    <BaseFilter
                      filter={filterGroup}
                      selectionList={unSelectedFilterGroups}
                      onFilterChange={this.handleFilterChange}
                      onFilterGroupSelect={this.handleFilterGroupSelection}
                      onGroupDelete={this.handleFilterGroupDelete}
                      getOptionList={this.getOptionListForBaseFilter}
                      onCheckBoxClick={this.onCheckBoxClick}
                      selectedIdList={filterGroup.selectedIdList}
                      selectedItemList={filterGroup.selectedItemList}
                      itemsList={[]}
                    />
                  </div>
                </div>))
            }
            {
              selectedFilterGroups.length === 0
              && <div className="no-filter-text">No Filters Applied</div>
            }
            <div className="filter-btn add" onClick={() => this.handleAddClick()}>
              <Button iconBtnSmall primary iconName="plus" />
            </div>
          </div>
        </Menu>
      </Fragment>
    );
  }
}

FilterAggregator.defaultProps = defaultProps;

FilterAggregator.propTypes = propTypes;

export default FilterAggregator;
