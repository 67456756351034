import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import DetailView from './View';
import PageHeader from '../../../base/PageHeader';
import { PanelStyled } from '../../../common/configuration';
import * as queryService from '../../../base/query.service';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import withAlert from '../../../../utils/composition/withAlert';
import { CATALOG } from '../../../../data/enums/Route';
import { getPermissionForCatalog } from '../../../base/permission';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { CATALOG_TITLE_ID_MAPPER } from '../../../../data/enums/Catalog';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../../utils/api';
import { imageSliderListFormatter, isError } from '../../../common/HelperFunctions';
import { DialogWrapper } from '../../../common';
import { crudResponseProcessor, handleFormSubmit } from '../../../../utils/crudResponseProcessor';
import {
  breadCrumb as breadCrumbConfig,
  crudSuccess as crudRequestConfig,
  getFilterListBasedOnCatalogs,
} from './config';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class Details extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      data: {
        list: [],
        total: 1,
      },
      dialog: {
        type: '',
        element: '',
      },
      queryParameters: {
        sort: queryService.baseQueryParameters.sort,
        date: { ...queryService.baseQueryParameters.date },
        filter: queryService.baseQueryParameters.filter,
        search: queryService.baseQueryParameters.search,
        pagination: queryService.baseQueryParameters.pagination,
      },
    };

    const serverCall = {
      [EVENT_OPERATION.DELETE]: props.toggleState,
    };

    this.catalogId = parseInt(props.match.params.id, 10);
    this.getData = () => {
      const { data } = this.state;
      return data;
    };
    this.setData = (data) => {
      this.setState({ data });
    };
    this.permission = getPermissionForCatalog();

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      props.displayAlert,
    );

    this.onCRUDSuccess = crudResponseProcessor((data, type) => {
      const { displayAlert } = this.props;
      displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message);
      this.setState({ data });
      this.loadTableData();
    }, this.getData);

    this.onFormSubmit = handleFormSubmit(
      this.onCRUDSuccess,
      this.onAPIRequestFailure,
      crudRequestConfig,
      serverCall,
    );
    this.filter = getFilterListBasedOnCatalogs(this.catalogId);
  }

  componentDidMount() {
    this.loadTableData();
  }

  loadTableData = () => {
    const { data, queryParameters } = this.state;
    const { getCatalogDetailList, displayAlert } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);

    getCatalogDetailList({
      offset,
      catalogId: this.catalogId,
      limit: queryParameters.pagination.limit,
      filter: {
        filters: apiFilterProcessor(queryParameters.filter),
        // dateRange: queryParameters.date,
        queryString: queryParameters.search,
      },
    }, {
      handleSuccess: (response) => {
        const catalogDetails = (response.data.catalogDetails && response.data.catalogDetails.rows) || [];
        data.total = (response.data.catalogDetails && response.data.catalogDetails.count) || 0;
        data.list = catalogDetails.map((catalog) => {
          if (catalog.images) {
            catalog.images = [...(imageSliderListFormatter(catalog.images) || [])];
          }

          return catalog;
        });
        if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
        this.setState(data);
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  };

  setQueryParameters = (queryParams, callBack = () => null) => this.setState(
    { queryParameters: queryParams },
    callBack,
  );

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleIconClick = (type, element) => {
    const { history } = this.props;
    const id = element ? element.id || '' : '';
    if (type === EVENT_OPERATION.DETAILS) {
      history.push(`/${CATALOG}/${this.catalogId}/details/${id}`);
    } else {
      history.push(`/${CATALOG}/${this.catalogId}/create`);
    }
  };

  handleBulkDelete = (type, response) => this.onCRUDSuccess(type)(response);


  handleClose = () => {
    this.setState({
      dialog: {
        type: '',
        element: '',
      },
    });
  };

  handleApiFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
    this.handleClose();
  };

  stateSetter = (stateField, value) => {
    this.setState({ [stateField]: value });
  };


  render() {
    const {
      data, queryParameters, display, dialog,
    } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <DialogWrapper
        onDialogSubmit={this.onFormSubmit}
        render={({ onDialogItemClick }) => (
          <Fragment>
            <div className="section-header">
              <PanelStyled>
                <PageHeader
                  breadCrumb={breadCrumbConfig}
                  config={{
                    title: CATALOG_TITLE_ID_MAPPER[this.catalogId],
                    create: this.permission.create,
                    download: false,
                    filter: true,
                    search: true,
                  }}
                  queryParameters={queryParameters}
                  controlDisplay={this.controlDisplay}
                  handleCreateClick={this.handleIconClick}
                  resetFilter={this.basePaginationService.resetFilter}
                  clearSearchText={this.basePaginationService.clearSearchText}
                  handleSearchChange={this.basePaginationService.handleSearchInputChange}
                  handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
                  handleDownloadClick={this.basePaginationService.handleDownloadClick}
                  filter={{
                    date:
                  queryParameters.date,
                    menuList: this.filter.menu,
                    onFilterChange: this.basePaginationService.handleFilterChange,
                  }}
                />
              </PanelStyled>
            </div>
            <div className="section-content">
              <DetailView
                data={data}
                permission={this.permission}
                loading={serverResponseWaiting}
                pagination={queryParameters.pagination}
                onIconClick={this.handleIconClick}
                onBulkDelete={this.handleBulkDelete}
                onPageChange={this.basePaginationService.onPageSelect}
                onActionClick={onDialogItemClick}
              />
            </div>
          </Fragment>)}
      />
    );
  }
}

Details.propTypes = propTypes;

Details.defaultProps = defaultProps;

export default withAlert()(Details);
