import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../../data/enums/SuccessMessage';
import { getFilterConfig } from '../../../../utils/filter';
import { FILTER } from '../../../../data/enums/filter';
import { configuration as configurationRoute } from '../../../../data/enums/Route';

const title = 'Role';

const breadCrumb = [
  configurationRoute,
];

const filter = getFilterConfig([FILTER.SUB_D]);

const form = {
  mapper: element => ({
    id: element.id || '',
    name: element.name || '',
    displayName: element.displayName || '',
    UserGroup: {
      id: element.UserGroup ? element.UserGroup.id : '',
    },
  }),
  validationField: ['name', 'displayName', 'userGroupId'],
  validationRequired: true,
};

const crudSuccess = {
  [EVENT_OPERATION.CREATE]: {
    objectMapper: data => ({
      name: data.name,
      displayName: data.displayName,
      userGrpId: data.UserGroup.id,
    }),
    responseName: 'createRole',
    message: `${title} ${MESSAGE_EVENT_OPERATION.CREATE}`,
  },
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: data => ({
      id: data.id,
      name: data.name,
      displayName: data.displayName,
      userGrpId: data.UserGroup.id,
      UserGroup: {
        id: data.userGroupId,
      },
    }),
    responseName: 'updateRole',
    message: `${title} ${MESSAGE_EVENT_OPERATION.UPDATE}`,
  },
  [EVENT_OPERATION.DELETE]: {
    objectMapper: data => ({
      ids: [data.id],
      type: title,
      active: false,
    }),
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: data => ({
      id: data.id,
      title: data.title,
    }),
    responseName: 'updateSKU',
    message: `${title} ${MESSAGE_EVENT_OPERATION.BULK_DELETE}`,
  },
  [EVENT_OPERATION.UPDATE_STATUS]: {
    objectMapper: data => ({
      ids: [data.id],
      type: title,
      active: !data.active,
    }),
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.UPDATE}`,
  },
};


export {
  crudSuccess, breadCrumb as breadCrumbConfig, form, title, filter as filterConfig
};
