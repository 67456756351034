import PropTypes from 'prop-types';
import React, { Component } from 'react';

import SortingElement from './Sorting';
import { CheckBox } from '../../components';
import { PERMISSION_OBJ } from '../../data/enums/Permission';
import { has } from '../../utils/objectPrototypes';
import { SORTING } from '../../data/enums';


const propTypes = {
  /**
   * headerDetails for pagination
   */
  headerDetails: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * @default ''
       * title of Header
       */
      title: PropTypes.string,
      /**
       * @default ''
       * className for table header
       */
      className: PropTypes.string,
      /**
       * @default false
       * if header is sortable or not
       */
      sortable: PropTypes.bool,
      /**
       * @default ''
       * the sorting lable to be sent to fetch api
       */
      label: PropTypes.string,
      /**
       * @default: true
       * Header to display or Not
       */
      show: true,
    }),
  ),
  /**
   * @default () => null
   *  handle on header click
   */
  onHeaderClick: PropTypes.func,
  /**
   * @default '', ''
   * sorting order and label
   */
  sorting: PropTypes.shape({
    /**
     * @default Sorting order: ASC, DESC, NO_SORT
     */
    order: PropTypes.string,
    /**
     * @default ''
     * Sorting label
     */
    label: PropTypes.string,
  }),
  permission: PropTypes.instanceOf(Object),
  dataListLength: PropTypes.number,
  checkedListLength: PropTypes.number,
  primaryCheckboxHandler: PropTypes.func,
};


const defaultProps = {
  headerDetails: [],
  onHeaderClick: () => null,
  sorting: {
    label: '',
    order: '',
  },
  dataListLength: 0,
  checkedListLength: 0,
  permission: PERMISSION_OBJ,
  primaryCheckboxHandler: () => null,
};

class TableHeader extends Component {
  state = {
    sorting: {
      label: '',
      order: '',
    },
  };

  componentDidMount() {
    const { sorting } = this.state;
    const { sorting: propsSorting } = this.props;

    sorting.label = propsSorting.label;
    sorting.order = propsSorting.order;

    this.setState({ sorting });
  }

  handleTableHeaderClick = (labelName) => {
    // eslint-disable-next-line react/destructuring-assignment
    const { label: currentLabel, order } = this.state.sorting;

    const { onHeaderClick } = this.props;

    const newSortingOrder = currentLabel === labelName
      ? this.getNewSortingOrder(order) : SORTING.ASC;

    this.setState(
      {
        sorting: {
          order: newSortingOrder,
          label: labelName,
        },
      },
      () => onHeaderClick(labelName, newSortingOrder),
    );
  };

  getNewSortingOrder = (currentOrder) => {
    if (currentOrder === SORTING.NO_SORT) {
      return SORTING.ASC;
    }

    if (currentOrder === SORTING.ASC) {
      return SORTING.DESC;
    }

    return SORTING.NO_SORT;
  };

  getTableHeader = (headerListToRender) => {
    const headerItemList = [];

    headerListToRender.forEach((header) => {
      const showTableHeader = !has.call(header, 'show') || header.show;

      if (showTableHeader) {
        const tHeader = this.getHeaderItem(header);
        headerItemList.push(tHeader);
      }
    });


    return headerItemList;
  };

  getHeaderItem = (header) => {
    const { sorting } = this.state;
    const {
      dataListLength, checkedListLength, primaryCheckboxHandler, permission,
    } = this.props;
    const isSortable = header.sortable;

    const displaySortingLabel = header.sortable && sorting.label === header.label;

    return (
      header.label === 'checkbox' ? (dataListLength > 0 && permission.delete) && (
        <th key={header.id + header.title} onClick={e => e.stopPropagation()}>
          <CheckBox
            checked={(dataListLength === checkedListLength)}
            onCheckBoxClick={e => primaryCheckboxHandler(e)}
          />
        </th>
      )
        : (
          <th
            key={header.id + header.title}
            className={header.className}
            onClick={isSortable ? () => this.handleTableHeaderClick(header.label) : null}
          >
            {header.title}
            {' '}
            {
            displaySortingLabel && (
              <SortingElement
                labelName={header.label}
                sortingOrder={sorting.order}
                handleClick={this.handleTableHeaderClick}
              />
            )
          }
          </th>
        )
    );
  };

  render() {
    const { headerDetails } = this.props;

    // todo filter the header Details according to Render Components.
    return (
      <thead>
        <tr>
          {
          this.getTableHeader(headerDetails)
        }
        </tr>
      </thead>
    );
  }
}

TableHeader.defaultProps = defaultProps;

TableHeader.propTypes = propTypes;

export default TableHeader;
