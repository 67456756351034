import React, { Fragment } from 'react';
import childView from './childView';
import { TableBody } from '../tableConfig';
import withErrorAndLoadingScreen from '../../../../../utils/composition/withLoading';
import AccordionTable from '../../../../../components/CollapsibleTable/AccordionTable';

const DetailView = ({ ...props }) => {
  const {
    data,
    loading,
    onRowClick,
    onIconClick,
    selectedOrders,
    checkBoxStatus,
    onInvoiceClick,
    activeCallIndex,
    receivedLoading,
    onPrimaryCheckBoxClick,
    onSecondaryCheckBoxClick,
  } = props;

  return (
    <tbody>
      {
        <AccordionTable>
          {
          data.map((received, index) => (
            <tr
              key={received.id}
              trigger={TableBody({
                data: received,
                onRowClick,
              })}
            >

              {
                received.totalOrderCount > 0 && index === activeCallIndex
                  ? (
                    childView({
                      data: received.Orders || [],
                      checkBoxStatus,
                      onPrimaryCheckBoxClick,
                      onSecondaryCheckBoxClick,
                      onIconClick,
                      onInvoiceClick,
                      selectedOrders,
                      loading: loading || receivedLoading,
                      call: received
                    })
                  ) : (<Fragment />)
              }
            </tr>))}
        </AccordionTable>
    }
    </tbody>
  );
};

const ReceivedDetailViewWithErrorAndLoading = withErrorAndLoadingScreen(DetailView);

export default ReceivedDetailViewWithErrorAndLoading;
