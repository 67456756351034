import { FILTER } from '../../../data/enums/filter';
import { getFilterConfig } from '../../../utils/filter';
import { sales as salesRoute } from '../../../data/enums/Route';

const breadCrumb = [
  salesRoute,
];

const filter = getFilterConfig([FILTER.SUB_D]);

export { breadCrumb as breadCrumbConfig, filter as filterConfig };
