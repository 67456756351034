import { promotionScopeBase } from '../PromotionDetail';
import { clone } from '../../../../../utils/objectProcessor';


const moqIdResolver = (details, index = 0) => {
  const skuListWithMoq = [];

  const skuList = details[index].skus;

  skuList.forEach((sku) => {
    if (sku.id) {
      skuListWithMoq.push({
        id: sku.id,
        moq: sku.criteria.operation.max.value,
      });
    }
  });

  return skuListWithMoq;
};

const moqIdResolverForApplicableSKUs = (applicableSkuList) => {
  const skuList = [];
  applicableSkuList.forEach((sku) => {
    if (sku.id) {
      skuList.push({
        id: sku.id,
        moq: sku.criteria.operation.max.value,
      });
    }
  });

  return skuList;
};

const customGroupSKUmoqToCriteriaResolver = (value) => {
  const {
    dimension,
    operation,
    skuCount,
  } = promotionScopeBase.customGroupDetails.sku.criteria; // dimension: QTY, operation

  const skuListWithCriteria = [];

  value.forEach((el) => {
    const clonedOperation = clone(operation);
    clonedOperation.max.value = el.moq;
    clonedOperation.min.value = el.moq;

    const sku = {
      id: el.id,
      criteria: {
        dimension,
        skuCount,
        operation: clone(clonedOperation),
      },
    };

    skuListWithCriteria.push({ ...sku });
  });

  return skuListWithCriteria;
};

const skuGroupInputToCriteriaResolver = (event) => {
  const { value } = event.target;

  const {
    dimension,
    operation,
    skuCount,
  } = promotionScopeBase.customGroupDetails.sku.criteria;

  const clonedOperation = clone(operation);
  clonedOperation.max.value = Number(value);
  clonedOperation.min.value = Number(value);

  return {
    dimension,
    skuCount,
    operation: clone(clonedOperation),
  };
};

export {
  moqIdResolver,
  customGroupSKUmoqToCriteriaResolver,
  skuGroupInputToCriteriaResolver,
  moqIdResolverForApplicableSKUs,
};
