import gql from 'graphql-tag';

const GET_FOCUSED_SKU_LIST_WITH_TITLE = gql`
    query FocusedSKUs($offset: Int, $limit: Int) {
        focusedSKUs(offset: $offset, limit: $limit) {
            rows {
                id
                title
                start
                end
                active
            }
            count
        }
    }
`;

const query = {
  getFocusedSKUListWithTitle: GET_FOCUSED_SKU_LIST_WITH_TITLE,
};

export { query };
