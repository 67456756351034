import gql from 'graphql-tag';
import { DOWNLOAD_REPORT } from '../../common/GqlAPI';

const GET_BRAND_COVERAGE_LIST = gql`
    query getBrandCoverageReport($filter: FilterInput) {
      getBrandCoverage(filter: $filter) {
          coverage
            rows {
              id
              name
              parent_name
              coverage
              active_coverage
              brand_coverage
              coverage_percent
            }
            count
        }
    }
`;

const query = {
  getBrandCoverageReport: GET_BRAND_COVERAGE_LIST,
};

const mutation = {
  downloadReport: DOWNLOAD_REPORT,
};

export {
  DOWNLOAD_REPORT,
};

export { query, mutation };
