import styled from 'styled-components';

const CustomStyle = styled.div`
.dropdown{
   position: absolute;
  top: 32px;
  right: 10px;
  opacity: 0.6;
}
`;

export default CustomStyle;
