import React from 'react';
import PropTypes from 'prop-types';
import TableHeader from '../../base/TableHeader';
import { fixedFloatAndCommas } from '../../../utils/conversion';

const labelMappings = {
  name: 'name',
  coverage: 'coverage',
  active_coverage: 'active_coverage',
  brand_coverage: 'brand_coverage',
  coverage_percent: 'coverage_percent',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.name,
    title: 'Brand',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.coverage,
    title: 'Coverage Outlet',
    className: 'text-right',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.active_coverage,
    title: 'Active Outlet',
    className: 'text-right',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.brand_coverage,
    title: 'Distribution',
    className: 'text-right',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.coverage_percent,
    title: 'Distribution %',
    className: 'text-right',
    show: true,
  },
];


const returnTableBody = (data) => {
  const table = {};
  table[labelMappings.name] = (
    <td key={`brand-coverage-${data.id}-name`}>{data.name}</td>
  );
  table[labelMappings.coverage] = (
    <td key={`brand-coverage-${data.id}-coverage`} className="text-right">{fixedFloatAndCommas(data.coverage)}</td>
  );
  table[labelMappings.active_coverage] = (
    <td key={`brand-coverage-${data.id}-active_coverage`} className="text-right">{fixedFloatAndCommas(data.active_coverage)}</td>
  );
  table[labelMappings.brand_coverage] = (
    <td key={`brand-coverage-${data.id}-brand_coverage`} className="text-right">
      {fixedFloatAndCommas(data.brand_coverage)}
    </td>
  );
  table[labelMappings.coverage_percent] = (
    <td key={`brand-coverage-${data.id}-distribution-percentage`} className="text-right">
      {data.coverage_percent ? `${fixedFloatAndCommas(data.coverage_percent)} %` : data.coverage_percent}
    </td>
  );
  return table;
};

const TableBody = ({
  data,
  onRowClick,
}) => {
  const tableBody = returnTableBody(data);

  return (
    <tr onClick={() => onRowClick(data)}>
      {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

const TableHeaderConfig = () => <TableHeader headerDetails={labelConfig} />;


TableBody.propTypes = {
  onRowClick: PropTypes.func.isRequired,
  data: PropTypes.instanceOf('object').isRequired,
};


export {
  TableHeaderConfig as TableHeader,
  TableBody,
};
