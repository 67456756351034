import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Button, Icon } from '../../../../components/index';
import {
  breadCrumbConfig,
  crudSuccess as crudRequestConfig,
  detailsFormConfig,
  formMapper,
  title,
  totalPriceMapper,
} from './config';
import { SRN } from '../../../../data/enums/Route';
import Table from './table';
import SummaryDetails from './summaryDetails';
import { clone } from '../../../../utils/arrayProcessor';
import { grnImageUploader } from '../../../../utils/image';
import { PanelStyled } from '../../../common/configuration';
import PageHeader from '../../../common/detailViews/pageHeader/PageHeader';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import withAlert from '../../../../utils/composition/withAlert';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { handleFormSubmit } from '../../../../utils/crudResponseProcessor';
import { customerMapper } from './summaryDetails/config';
import { getUser } from '../../../../data/services';
import { USER_ROLE } from '../../../../data/enums';
import { getPermissionForSrn } from '../../../base/permission';
import withLoading from '../../../../utils/composition/withLoading';
import PanelCard from '../../../../components/Cards/PanelCard';
import { handlePrint } from '../../../common/HelperFunctions';
import { DOMAIN } from '../../../../data/enums/config';

const propTypes = {
  getSkus: PropTypes.func.isRequired,
  createSRN: PropTypes.func.isRequired,
  updateSRN: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  getSrnDetail: PropTypes.func.isRequired,
  getDistributors: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class CreateSrn extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.userInfo = getUser();
    this.subDUser = this.userInfo.roleId === USER_ROLE.DISTRIBUTOR_ADMIN
      || this.userInfo.roleId === USER_ROLE.BILLING_USER;
    this.state = {
      skus: [],
      data: formMapper({}, this.userInfo.Distributor.length > 0 ? this.userInfo.Distributor[0].id : 0),
      outletList: [],
      distributorList: [],
      enableErrorDisplay: false,
      invoiceNumberStatus: false,
      /* distributorServices: {
        BILLING: {
          status: false,
          url: ''
        }, */

      // LOGISTICS: {}
      distributorServices: {
        billing: {
          status: false,
          url: '',
        },
        // LOGISTICS: { }
      },
      distributor: {
        id: null,
        townList: [],
      },
    };

    const serverCall = {
      [EVENT_OPERATION.CREATE]: props.createSRN,
      [EVENT_OPERATION.UPDATE]: props.updateSRN,
      [EVENT_OPERATION.APPROVE]: props.approveSRN,
    };
    this.onCRUDSuccess = this.responseProcessor(this.handleSrnSuccess);
    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.onAPIRequestFailure,
      crudRequestConfig, serverCall);
    this.permission = getPermissionForSrn();
  }

  componentDidMount() {
    this.getDistributorsList();
    this.getSKUs();
    // this.getOutletList();
  }


  setDistributorServices = (distributor) => {
    const { distributorServices, distributor: stateDistributor } = this.state;

    stateDistributor.townList = distributor.Towns.map(town => town.id);
    stateDistributor.id = distributor.id;
    this.setState({
      distributorServices: distributor.servicesUsed || distributorServices,
      distributor: stateDistributor,
    }, () => {
      this.getOutletList();
    });
  };

  getDistributorsList = () => {
    const { getDistributors, displayAlert } = this.props;
    getDistributors({ limit: 1000 }, {
      handleSuccess: (response) => {
        this.setState(
          { distributorList: response.data.distributors ? response.data.distributors.rows || [] : [] },
          () => {
            if (this.subDUser) {
              if (this.state.distributorList.length > 0) this.setDistributorServices(this.state.distributorList[0]);
            }
          },
        );
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  };

  getOutletList = () => {
    const { getOutlets, displayAlert } = this.props;

    const { distributor } = this.state;

    if (distributor.townList.length > 0) {
      const filter = {
        filters: [
          {
            column: 'town_id',
            value: distributor.townList.map(String),
          },
        ],
      };

      getOutlets({
        limit: 3500,
        filter,
      }, {
        handleSuccess: (response) => {
          this.setState({ outletList: response.data.retailOutlets.rows });
        },
        handleError: (error) => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      });
    }
  };

  getSKUs = () => {
    const { getSkus, displayAlert } = this.props;
    getSkus({ limit: 1000 }, {
      handleSuccess: (response) => {
        this.setState({ skus: response.data.skus.rows });
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  };

  handleInvoiceNumberChange = (invoiceNumber, exactlyMatchedInvoiceNumber) => {
    const { data } = this.state;
    const { getInvoiceDetail, displayAlert } = this.props;
    if (exactlyMatchedInvoiceNumber) {
      getInvoiceDetail({ invoiceNumber }, {
        handleSuccess: (response) => {
          const responseData = {
            ...response.data.invoiceDetailsByNumber,
            uuid: Math.random().toString(36).substring(7),
          };
          const invoiceDetail = formMapper(responseData)
            || formMapper({ uuid: Math.random().toString(36).substring(7) },
              this.userInfo.Distributor
                ? this.userInfo.Distributor.id : 0);
          this.updateList('outletList', invoiceDetail.RetailOutlet);
          if (!invoiceDetail.Customer) invoiceDetail.Customer = customerMapper({});

          this.setState({
            data: invoiceDetail,
            invoiceNumberStatus: exactlyMatchedInvoiceNumber,
          });
        },
        handleError: (error) => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      });
    } else if (data.orders.length > 0) {
      data.orders = [];
      data.updatedAmount = totalPriceMapper({});
      data.amount = totalPriceMapper({});
      data.totalValidOrders = 0;
      this.setState({
        data,
        invoiceNumberStatus: exactlyMatchedInvoiceNumber,
      });
    }
  };

  directToMainPage = () => {
    /** direct to Srn page */
    const { history } = this.props;
    history.push(`/${SRN}`);
  };

  onSubmit = () => {
    const valid = this.getValidationStatus();
    if (valid) {
      this.createSrn();
    } else {
      this.setState({ enableErrorDisplay: true });
    }
  };

  getValidationStatus = () => {
    const detailsStatus = this.getDetailsValidationStatus();
    const tableStatus = this.getTableValidationStatus();

    return (detailsStatus && tableStatus);
  };

  createSrn = () => {
    const { data, invoiceNumberStatus } = this.state;
    const updatedData = clone(data);
    const tableData = this.getTableDetails();
    updatedData.details = this.getDetails();
    updatedData.amount = tableData.totalAmount;
    updatedData.srnType = tableData.srnType;
    updatedData.returnOrders = tableData.returnOrders;
    updatedData.oldBillStatus = !invoiceNumberStatus;

    this.onFormSubmit(EVENT_OPERATION.CREATE, updatedData);
  };

  updateList = (stateField, value) => {
    const list = this.state[stateField] || [];
    if (Array.isArray(list)) {
      list.push(value);
      this.setState({ [stateField]: list });
    }
  };

  handleSrnSuccess = (response, type) => {
    const { distributorServices } = this.state;
    const { displayAlert } = this.props;
    if (distributorServices.billing.status) {
      displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message);
      handlePrint({
        distributorServices,
        invoiceNumber: response.SRNInvoiceNumber,
        modules: DOMAIN.SALES_RETURN,
      },
      this.directToMainPage);
    } else {
      displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message, this.directToMainPage);
    }
  };


  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
    console.log(error);
  };

  responseProcessor = callBack => type => (response) => {
    callBack(
      response,
      type,
    );
  };


  render() {
    const {
      data,
      skus,
      SrnId,
      update,
      outletList,
      editable,
      approvable,
      distributorList,
      distributorServices,
      enableErrorDisplay,
      invoiceNumberStatus,
    } = this.state;
    const {
      displayAlert,
      serverResponseWaiting,
    } = this.props;
    return (
      <Fragment>
        <div className="section-header">
          <PanelStyled>
            <div className="prn-page-header">
              <PageHeader
                breadCrumb={breadCrumbConfig}
                title={title}
              />
              <div className="flex m-0">
                <Button
                  secondary
                  small
                  disabled={serverResponseWaiting}
                  onClick={() => {
                    this.setState({ data: formMapper({}) });
                  }}
                >
                  <span>
                      Cancel
                  </span>
                </Button>
                <Button
                  small
                  primary
                  disabled={serverResponseWaiting}
                  onClick={() => this.onSubmit()}
                >
                    Save
                </Button>
                {
                    SrnId ? (
                      <div>
                        <Button
                          secondary
                          iconBtnSmall
                          disabled={!editable || update.type === EVENT_OPERATION.APPROVE}
                          onClick={() => {
                            this.setState({
                              update: {
                                type: EVENT_OPERATION.UPDATE,
                                status: true,
                              },
                            });
                          }}
                        >
                          <Icon iconName="pencil" />
                        </Button>
                        {approvable && (
                          <Button
                            small
                            primary
                            disabled={update.type === EVENT_OPERATION.UPDATE && update.status}
                            onClick={() => {
                              this.setState({
                                update: {
                                  type: EVENT_OPERATION.APPROVE,
                                  status: true,
                                },
                              });
                            }}
                          >
                            Approve
                          </Button>
                        )
                      }
                      </div>
                    ) : ''
                  }
              </div>
            </div>
          </PanelStyled>
        </div>
        <div className="section-content pad-48">
          <PanelCard cardTitle="details">
            <SummaryDetails
              enableErrorDisplay={enableErrorDisplay}
              loading={serverResponseWaiting}
              outletList={outletList}
              distributorList={distributorList}
              formConfig={detailsFormConfig}
              onInvoiceNumberChange={this.handleInvoiceNumberChange}
              invoiceNumberStatus={invoiceNumberStatus}
              updateDistributorServices={this.setDistributorServices}
              data={data}
              subDUser={this.subDUser}
              distributorServices={distributorServices}
              getStatus={childMethod => this.getDetailsValidationStatus = childMethod}
              getDetails={childMethod => this.getDetails = childMethod}
            />
          </PanelCard>
          <PanelCard cardTitle="sku" skuClassStatus>
            <Table
              data={data}
              skuList={skus}
              update={update}
              imageUploader={grnImageUploader}
              displayAlert={displayAlert}
              enableErrorDisplay={enableErrorDisplay}
              invoiceNumberStatus={invoiceNumberStatus}
              getStatus={childMethod => this.getTableValidationStatus = childMethod}
              getDetails={childMethod => this.getTableDetails = childMethod}
            />
          </PanelCard>
        </div>
      </Fragment>
    );
  }
}

CreateSrn.propTypes = propTypes;

CreateSrn.defaultProps = defaultProps;

export default withLoading(withAlert()(CreateSrn));
