import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { DOWNLOAD_REPORT, GET_DISTRIBUTORS, UPLOAD } from '../../common/GqlAPI';


const GET_REPLENISHMENT_REPORT = gql`
    query getStockReplenishmentReport($offset: Int, $limit: Int, $filter: FilterInput) {
        getStockReplenishmentReport(offset: $offset, limit: $limit, filter: $filter) {
            rows {
                id
                brand
                sku
                skuFamily:product_pack
                upc
                currentStock:current_stock
                sit:stock_in_transit
                pso:pending_stock
                totalStock:total_stock
                doh
                orderPcs:order_pcs
                orderCases:order_cases
            }
            count
        }
    }
`;

const GET_USER = gql`
    query getUsers($limit: Int, $filter: FilterInput) {
        users(limit: $limit, filter: $filter ) {
            rows {
                id
                email
            }
        }
    }
`;

const GET_PSR_LIST = gql`
    query getPsrReport($offset: Int, $limit: Int, $filter: FilterInput) {
        getPsrReport(offset: $offset, limit: $limit, filter: $filter) {
            rows {
                outlet_id
                outlet
                channel
                category
                distributor
                route
                town
                brand
                sku
                dse
                quantity
                gross_amount
                net_amount
                promotion_amount
                promotion_title
                discount_amount
                bill_discount_titles
            }
            count
        }
    }
`;

const SAVE_REPORT = graphql(gql`
    mutation($distributorIds:[Int]!){
        saveOrderReport(distributorIds:$distributorIds)
    }`,
{
  props: ({ mutate }) => ({
    saveOrderReport: variables => mutate({
      variables,
    }),
  }),
});


const SHARE_REPORT = graphql(gql`
      mutation($userIds:[Int]!, $distributorIds:[Int]!){
          shareOrderReport(userIds: $userIds, distributorIds:$distributorIds)
      }`,
{
  props: ({ mutate }) => ({
    shareOrderReport: variables => mutate({
      variables,
    }),
  }),
});

const query = {
  getPSRList: GET_PSR_LIST,
  getDistributors: GET_DISTRIBUTORS,
  getReplenishmentReport: GET_REPLENISHMENT_REPORT,
  getUsers: GET_USER,
};

const mutation = {
  downloadReport: DOWNLOAD_REPORT,
  upload: UPLOAD,
  saveOrderReport: SAVE_REPORT,
  shareOrderReport: SHARE_REPORT,
};

export {
  DOWNLOAD_REPORT, UPLOAD,
  SAVE_REPORT, SHARE_REPORT,
};

export { query, mutation };
