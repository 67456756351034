import { breadCrumbConfig } from '../config';
import { getTodaysDate } from '../../../../../utils/date';
import { RECEIPTS } from '../../../../../data/enums/Route';
import { MESSAGE_EVENT_OPERATION } from '../../../../../data/enums/SuccessMessage';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';

const breadCrumb = [
  ...breadCrumbConfig,

  {
    id: 1,
    title: 'Receipts',
    path: `/${RECEIPTS}`,
    active: true,
  },
];

const formConfig = {
  mapper: element => ({
    id: element.id || 0,
    collectionType: element.collectionType || {},
    date: element.date || getTodaysDate(),
    amount: element.amount || 0,
    remarks: element.remarks || '',
    status: element.status || '',
    details: {
      chequeNumber: element.details ? element.details.chequeNumber ? element.details.chequeNumber.toString() : '' : '',
      bank: element.details ? element.details.bank : '',
      image: element.details ? element.details.image : [],
      valuedDate: element.details ? element.details.valuedDate : getTodaysDate(),
    },
    RetailOutlet: {
      id: element.RetailOutlet ? element.RetailOutlet.id : '',
    },
    Role: {
      id: element.Role ? element.Role.id : '',
    },
    User: {
      id: element.User ? element.User.id : '',
      fullName: element.User ? element.fullName : '',
    },
    outletId: element.outletId || 0,
  }),
  validationField: ['collectionType', 'date', 'amount', 'RetailOutlet', 'outletId', 'Role', 'User', 'image'],
  validationRequired: true,
};

const crudSuccess = {
  [EVENT_OPERATION.CREATE]: {
    objectMapper: (data) => {
      const { id, ...structuredData } = data;
      return structuredData;
    },
    responseName: 'createPayment',
    message: `RECEIPTS ${MESSAGE_EVENT_OPERATION.CREATE}`,
  },
};

export { breadCrumb as breadCrumbConfig, formConfig, crudSuccess as crudRequestConfig };
