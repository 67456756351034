import 'whatwg-fetch';
import { ApolloClient } from 'apollo-client';
import { persistCache } from 'apollo-cache-persist';
import * as config from './config';
import link from './links';

const persistentClient = persistCache(
  {
    cache: config.cache,
    storage: window.localStorage,
  },
)
  .then(() => new ApolloClient({
    link,
    cache: config.cache,
    connectToDevTools: true,
  }));

const client = new ApolloClient(
  {
    link,
    cache: config.cache,
    connectToDevTools: true,
    defaultOptions: config.clientOptions,
  },
);

export { persistentClient, client };

export default client;
