import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Pagination from '../../../components/Pagination';
import withLoading from '../../../utils/composition/withLoading';
import BulkDelete from '../../components/BulkDelete';
import { STATEFUL_ENTITIES } from '../../../data/enums/GraphQL';
import { PERMISSION_OBJ } from '../../../data/enums/Permission';
import { TableBody, TableHeader } from './tableConfig';

const propTypes = {
  data: PropTypes.shape({
    list: PropTypes.array,
    total: PropTypes.number,
  }).isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.number,
    limit: PropTypes.number,
  }).isRequired,
  onPageChange: PropTypes.func,
  onCheckBoxClick: PropTypes.func,
  onIconClick: PropTypes.func,
  onBulkDelete: PropTypes.func,
  permission: PropTypes.shape({
    read: PropTypes.bool,
    create: PropTypes.bool,
    update: PropTypes.bool,
    delete: PropTypes.bool,
  }),
};


const defaultProps = {
  onPageChange: () => null,
  onCheckBoxClick: () => null,
  onIconClick: () => null,
  permission: PERMISSION_OBJ,
};

const View = ({ ...props }) => {
  const {
    data,
    onPageChange,
    onIconClick,
    pagination,
    onBulkDelete,
    permission,
  } = props;

  return (
    <Fragment>
      <BulkDelete
        data={data.list}
        type={STATEFUL_ENTITIES.TERRITORY}
        handler={onBulkDelete}
        render={(state, primaryCheckboxHandler, secondaryCheckboxHandler) => (
          <div className="table-wrap">
            <table>
              {
                  TableHeader({
                    dataListLength: data.list.length,
                    checkedListLength: state.checkedList.length,
                    primaryCheckboxHandler,
                    permission,
                  })
                }
              <tbody>
                {data.list.map(territory => (
                  TableBody({
                    data: territory,
                    checkedList: state.checkedList,
                    onIconClick,
                    secondaryCheckboxHandler,
                    permission,
                  })
                ))}
              </tbody>
            </table>
          </div>
        )}
      />
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </Fragment>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withLoading(View);

export default LoginViewWithErrorAndLoading;
