import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { refGenerator } from '../../../../utils/refGenerator';
import { FORM_CONFIG } from '../../../../data/enums/config';
import { colSize } from '../../../../data/enums/Styles';
import { PRN } from '../../../../data/enums/Route';
import { breadCrumbConfig } from '../config';


const breadCrumb = [
  ...breadCrumbConfig,
  {
    id: 1,
    title: 'PRN',
    path: `/${PRN}`,
    active: true,
  },
];

const summaryDetailsMapper = (data) => {
  const summaryList = [
    {
      title: 'GRN Number',
      value: data.grnInvoiceNumber || '',
      colSpace: colSize.md,
    },
    {
      title: 'PRN Invoice Number',
      value: data.prnInvoiceNumber || '',
      colSpace: colSize.md,
    },
    {
      title: 'Vendor',
      value: data.Vendor ? data.Vendor.name || '' : '',
    },
    {
      title: 'Remarks',
      value: data.reason || '',
    },
  ];
  return summaryList;
};


const totalPriceMapper = data => ({
  billDiscount: data.billDiscount || 0,
  tradeDiscount: data.tradeDiscount || 0,
  subTotal: data.subTotal || 0,
  total: data.total || 0,
  taxAmount: data.taxAmount || 0,
  taxableAmount: data.taxableAmount || 0,
});

const prnDetailLinesMapper = data => (data.map(item => ({
  ...item,
  // manufactureDate: item.GRNDetail ? item.GRNDetail.manufactureDate : null,
  // expiryDate: item.GRNDetail ? item.GRNDetail.expiryDate : null,
  manufactureDate: item.SkuBatch ? item.SkuBatch.usageDate ? item.SkuBatch.usageDate.manufacture : null : null,
  expiryDate: item.SkuBatch ? item.SkuBatch.usageDate ? item.SkuBatch.usageDate.expiry : null : null,
  updatedPriceDetails: { ...item.amountDetails, amount: (item.quantity * item.amountDetails.rate) },
  updatedQuantity: item.quantity,

})));

const detailsFormConfig = {
  [FORM_CONFIG.MAPPER]: data => summaryDetailsMapper(data),
  [FORM_CONFIG.TITLE]: `${EVENT_OPERATION.UPDATE} SKU`,
  [FORM_CONFIG.REFS_OBJ]: refGenerator(['date', 'vendorId', 'distributorId', 'invoiceNumber']),
  [FORM_CONFIG.VALIDATION_REQUIRED]: true,
};

const dataMapper = (data = {}) => ({
  prnInvoiceNumber: data.prnInvoiceNumber,
  summary: summaryDetailsMapper(data),
  lines: data.PRNDetails ? prnDetailLinesMapper(data.PRNDetails) : [],
  prnAmount: totalPriceMapper(data.prnAmount ? data.prnAmount : {}),
});

const formattedSkuLines = list => (
  list.map((item) => {
    const { SKU, SkuBatch, ...modifiedItem } = item;

    return modifiedItem;
  }));

export { dataMapper, breadCrumb };
