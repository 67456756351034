import React, { Fragment } from 'react';
import Badge from '../../../../components/Badge';
import { presentor } from '../../../../utils/date';
import { fixedFloatAndCommas } from '../../../../utils/conversion';
import { USER_ROLE_TITLE } from '../../../../data/enums/UserRole';
import { headerLabelConfig } from '../../../common/HelperFunctions';

const labelMappings = {
  id: 'id',
  date: 'replacedDate',
  outlet: 'retailOutlet.title',
  route: 'route.title',
  dse: 'dse.fullName',
  quantity: 'quantity',
  notes: 'notes',
};

const replacedTableConfig = [
  {
    id: 1,
    label: labelMappings.id,
    title: 'RID',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.date,
    title: 'Date',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.outlet,
    title: 'Outlet',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.route,
    title: 'Route',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.dse,
    title: headerLabelConfig[USER_ROLE_TITLE.DSE],
    show: true,
  },
  {
    id: 7,
    label: labelMappings.quantity,
    title: 'Quantity',
    show: true,
    align: true,
  },
  {
    id: 8,
    label: labelMappings.notes,
    title: 'Note',
    show: true,
  },
];

const returnTableBody = (data, onRowClick) => {
  const table = {};
  table[labelMappings.id] = (
    <td key={`rp-id-${data.id}`} onClick={() => onRowClick(data.id, data.Distributor.id)}>
      {
        <span>
          <span className="tab-label">{data.id}</span>
          <Badge light label={data.totalLineCount} />
        </span>
      }
    </td>
  );
  table[labelMappings.date] = (
    <td key={`rp-date-${data.id}`} onClick={() => onRowClick(data.id, data.Distributor.id)}>
      {`${presentor(data.replacedDate || '')}`}
    </td>
  );
  table[labelMappings.outlet] = (
    <td key={`rp-outlet-${data.id}`} onClick={() => onRowClick(data.id, data.Distributor.id)}>
      <a href={`outlet/details/${data.retailOutlet.id}`} onClick={e => e.preventDefault()}>
        {data.retailOutlet ? data.retailOutlet.title : ''}
      </a>
    </td>
  );
  table[labelMappings.route] = (
    <td key={`rp-route-${data.id}`} onClick={() => onRowClick(data.id, data.Distributor.id)}>
      {data.route ? data.route.title : ''}
    </td>
  );
  table[labelMappings.dse] = (
    <td key={`rp-dse-${data.id}`} onClick={() => onRowClick(data.id, data.Distributor.id)}>
      {data.dse ? data.dse.fullName : ''}
    </td>
  );

  table[labelMappings.quantity] = (
    <td
      key={`rp-quantity-${data.id}`}
      className="text-right"
      onClick={() => onRowClick(data.id, data.Distributor.id)}
    >
      {fixedFloatAndCommas(data.quantity || '')}
    </td>
  );
  table[labelMappings.notes] = (
    <td key={`rp-notes-${data.id}`} onClick={() => onRowClick(data.id, data.Distributor.id)}>
      <span>{data.notes}</span>
    </td>

  );

  return table;
};

const ReplacedTableBody = ({ data, onRowClick }) => {
  // Expanding/Dragging and collapsing table body.
  // color based upon table body
  // tooltip if it is sorted.
  // active className
  const tableBody = returnTableBody(data, onRowClick);

  return (
    <Fragment key={`rp-tr-${data.id}`}>
      {replacedTableConfig.map(label => (label.show ? tableBody[label.label] : null))}
    </Fragment>
  );
};

const tableConfig = {
  header: replacedTableConfig,
  getTableBody: ReplacedTableBody,
};

export {
  tableConfig,
  replacedTableConfig,
  ReplacedTableBody as TableBody,
};
