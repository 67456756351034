import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const GET_SBD_BASIC_DETAIL = gql`
    query getBasicDetail($value: String) {
        focusedSKUs(filter: {
            filters:[
                {
                    column:"id",
                    value: [$value]
                },
                {
                    column:"active",
                    value: ["true", "false"]
                }
            ]
        }){
            rows {
                id
                title
                start
                end
                active
                FocusedSKUDetails {
                    id
                    channelId
                    categoryId
                    brandId
                    skus
                }
            }
        }

    }
`;

const GET_BRAND_DETAILS = gql`
    query getBrandDetails($catalogId: Int){
        catalogDetails(catalogId: $catalogId){
            rows {
                id
                title
                SKUs {
                    id
                    title
                }
            }
        }
    }
`;

const GET_SKU_DETAILS = gql`
    query getBrandsSkus($value: Int) {
            catalogLevelDetails(
                catalogId: 3
                catalogDetailId: $value
            ){
                count
                rows{
                    id
                    title
                    SKUs{
                        id
                        title
                    }
                }
            }
    }
`;

const GET_SBD_SKUS_COUNT = gql`
    query getFocusSkuCount($focusedSkuId: Int, $channelId: Int, $categoryId: Int, $brandId: Int ) {
        getFocusedSkuDetails(focusedSkuId: $focusedSkuId, channelId:$channelId, categoryId: $categoryId,
            brandId: $brandId){
                focusedSkuId
                Channel{
                    id
                    count
                }
                Category{
                    id
                    count
                }
                Brand{
                    id
                    count
                }
                skus
        }
    }
`;

const CREATE_SBD_SKUS = graphql(gql`
    mutation createSBDSkus($input: FocusedSKUDetailsInput!) {
        insertFocusedSkuDetails(input:$input){
            id
            channelId
            categoryId
            brandId
            skus
        }
    }`, {
  props: ({ mutate }) => ({
    insertFocusedSkuDetails: variables => mutate({
      variables,
    }),
  }),
});

const DELETE_SBD_SKU = graphql(gql`
    mutation deleteFocusedSkuDetails($id: Int!) {
        deleteFocusedSkuDetails(id:$id){
            id
            message
        }
    }`, {
  props: ({ mutate }) => ({
    deleteFocusedSkuDetails: variables => mutate({
      variables,
    }),
  }),
});

const query = {
  getBrandDetails: GET_BRAND_DETAILS,
  getSkuDetails: GET_SKU_DETAILS,
  getBasicDetail: GET_SBD_BASIC_DETAIL,
  getFocusSkuCount: GET_SBD_SKUS_COUNT,
};

const mutation = {
  insertFocusedSkuDetails: CREATE_SBD_SKUS,
  deleteFocusedSkuDetails: DELETE_SBD_SKU,
};

export {
  query, mutation, CREATE_SBD_SKUS, DELETE_SBD_SKU,
};
