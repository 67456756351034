import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { BreadCrumbUlStyled, BreadCrumbLiStyled } from './BreadCrumbStyledIndex';
import { BaseContext } from '../../utils/context';

const BreadCrumb = ({ list = [] }) => (
  <BaseContext.Consumer>
    {
    location => (
      <BreadCrumbUlStyled>
        {list.map(item => (
          <BreadCrumbLiStyled key={item.id} className={item.active ? 'active' : ''}>
            {/*  <Link to={item.path}>{item.title}</Link> */}
            <Link to={{
              pathname: `${location.pathname && item.path === '#'
                ? `${location.pathname}` : item.path}`,
              ...item,
            }}
            >
              {item.title}
            </Link>
          </BreadCrumbLiStyled>
        ))}
      </BreadCrumbUlStyled>
    )
  }
  </BaseContext.Consumer>
);

BreadCrumb.propTypes = { list: PropTypes.instanceOf(Array) };

BreadCrumb.defaultProps = { list: [] };
export default BreadCrumb;
