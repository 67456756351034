import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CheckBox } from '../../../../components';
import {
  defaultMenuConfigList,
  menuAction,
  tableHeaderWithCheckBox,
} from '../../../../utils/paginatedListUtils';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { PERMISSION_OBJ } from '../../../../data/enums/Permission';

const labelMappings = {
  checkbox: 'checkbox',
  title: 'title',
  actionHolders: 'actionHolders',
};

const menuConfigList = [defaultMenuConfigList[EVENT_OPERATION.UPDATE],
  defaultMenuConfigList[EVENT_OPERATION.DELETE]];

const labelConfig = [
  {
    id: 1,
    label: labelMappings.checkbox,
    title: '',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.title,
    title: 'Title',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.actionHolders,
    title: '',
    show: true,
  },

];

const TableHeader = ({
  dataListLength, checkedListLength, primaryCheckboxHandler, permission,
}) => tableHeaderWithCheckBox(dataListLength, checkedListLength, primaryCheckboxHandler, permission,
  labelConfig, labelMappings);

const returnTableBody = (data, checkedList, onIconClick, onSecondaryCheckBoxClick, permission) => {
  const table = {};
  table[labelMappings.checkbox] = (permission.delete && (
    <td
      onClick={e => e.stopPropagation()}
    >
      <CheckBox
        checked={checkedList.includes(data.id)}
        onCheckBoxClick={() => onSecondaryCheckBoxClick(data.id)}
      />
    </td>
  ));
  table[labelMappings.title] = (
    <td key={`${data.id}-title`}>{data.title}</td>
  );
  table[labelMappings.actionHolders] = (
    <td
      className="text-right simple-popup-actions"
      onClick={e => e.stopPropagation()}
    >
      {menuAction(menuConfigList, { menuIcon: 'ellipsis-v' }, onIconClick, data, permission)}
    </td>
  );

  return table;
};

const TableBody = ({
  data, checkedList, onIconClick, secondaryCheckboxHandler, permission,
}) => {
  // Expanding/Dragging and collapsing table body.
  // color based upon table body
  // tooltip if it is sorted.
  // active className
  const tableBody = returnTableBody(data, checkedList, onIconClick, secondaryCheckboxHandler, permission);

  return (
    <tr
      key={data.id}
      onClick={() => onIconClick(EVENT_OPERATION.DETAILS, data)}
      style={{ cursor: 'pointer' }}
    >
      <Link to={`subd-assortment/details/${data.id}`}>
        {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
      </Link>
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
  checkedList: PropTypes.instanceOf(Array),
  onIconClick: PropTypes.func.isRequired,
  permission: PropTypes.instanceOf(Object),
  secondaryCheckboxHandler: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
  checkedList: [],
  permission: PERMISSION_OBJ,
};

export {
  TableHeader,
  TableBody,
};
