import styled from 'styled-components';

const SingleRouteStyled = styled.div`
.dropbtn {
  background-color: #3498DB;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}
.dropbtn:hover, .dropbtn:focus {
  background-color: #2980B9;
}
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #F1F1F1;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropdown a:hover {background-color: #ddd;}
.show {display: block;}
.header-route{
padding:35px 24px;
background-color:#ffffff;
display:flex;
justify-content:space-between;
align-items:center;
        .title{
      font-size:18px;
      line-height:27px;
      font-weight:500;
      color:#272833;
    }
  .left-sec{
      display:flex;
      align-items:center;
    }
    .right-sec{
      button.bordered{
        width:32px;
        height:32px;
        text-align:center;
        line-height:0;
        margin-right:8px;
        &.back{
          margin-right:16px;
          line-height:37px;
        }
        svg.icon{
          width:13px;
          height:13px;
        }
        &:last-child{
          margin-right:0;
        }
      }
    }
}
.dragIcon:hover{
cursor:pointer
}
.outlets-aside{
    &>ul{
  height:calc(100vh - 230px);
  overflow:auto;
  padding:20px;
  li{
    .left{
      display:flex;
      align-items:center;
      margin-bottom:8px;  box-shadow:0 2px 4px 0 rgba(39,40,51,0.12);
      background-color:#FFFFFF;
      padding:16px 13px;
      display:flex;
      border-radius:4px;
      align-items:center;
      .drag{
        position:relative;
        top:-1px;
      }
      .content{
        color:#272833;
        font-size:12px;
        line-height:15px;
        font-weight:500;
        width:100%;
        display:flex;
        flex-wrap:wrap;
         justify-content:space-between;
         align-items:center;
        .wrap-lft{
          display:flex;
          flex-wrap:wrap;
          width:92%;
          align-items:center;
          label{
            position:relative;
            top:1px;
            left:6px;
          }
        }
        .drag-number{
          margin:0 12px 0 12px;
          min-width: 21px;
          height: 16px;
          border-radius: 100px;
          line-height: 13px;
          border: 1px solid #F1F2F5;
          padding:0;
          white-space:nowrap;
          position: relative;
          top: 1px;
        }
        .outlet-name{
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-right:24px;
          cursor:pointer;
          flex:1;
        }
        svg{
           fill:#6B6C7E;
        }
        /* .dropdown{
          svg{
            cursor:pointer;
          }
          .dropdown-content{
            min-width: 130px;
            padding: 8px 0;
            right: 0;
            top:25px;
            position:absolute;
            box-shadow: rgba(0,0,0,0.1) 1px 2px 10px 1px;
            z-index: 1;
            background: rgb(255,255,255);
            border-radius: 4px;
            border-width: 1px;
            border-style: solid;
            border-color: rgb(231,231,237);
            border-image: initial;
            a{
                padding: 9px 16px;
                color: #272833;
                font-size: 14px;
                line-height: 21px;
                white-space: nowrap;
                &:hover{
                      background: #F0F5FF;
                }
            }
          }
        }
         */
      }
    }
        }
      }
    }
    button.borderless{
    height:32px ;
    width:32px;
    background:transparent;
    border:0;
    margin-right:8px;
    cursor:pointer;
    outline:none;
    &:last-child{
      margin-right:0;
    }
      /* svg{
        fill:#6B6C7E;
      } */
  }
  .total-select{
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    align-items: center;
    &>div{
      margin-left:6px;
    }
    label{
      position:relative;
      top:2px;
    }
  }
.del-btn{
  position:relative;
  top:-2px;
  flex:unset !important;
  svg{
    transform: rotate(90deg);
  }
}
`;
export default SingleRouteStyled;
