import { graphql } from 'react-apollo/graphql';
import gql from 'graphql-tag';
import { CHANNEL_CATEGORY_FRAGMENT, GET_NEW_CHANNEL_LIST } from '../API';

const CREATE_CHANNEL = graphql(
  gql`
      mutation($input: ChannelInput! ) {
          createChannel(input: $input) {
              ...ChannelCategoryFragment
          }
      }
      ${CHANNEL_CATEGORY_FRAGMENT}
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      createChannel: variables => mutate({
        variables,
      }),
    }),
  },
);

const UPDATE_CHANNEL = graphql(
  gql`
      mutation($input: ChannelUpdateInput! ) {
          updateChannel(input: $input) {
              ...ChannelCategoryFragment
          }
      }
      ${CHANNEL_CATEGORY_FRAGMENT}
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      updateChannel: variables => mutate({
        variables,
      }),
    }),
  },
);

const mutation = {
  createChannel: CREATE_CHANNEL,
  updateChannel: UPDATE_CHANNEL,
};

const query = {
  getChannelDetails: GET_NEW_CHANNEL_LIST,
};

export {
  query, mutation, UPDATE_CHANNEL, CREATE_CHANNEL,
};
