import {
  compose,
} from 'recompose';
import Report from './Report';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  query,
} from './API';
import AttendanceLocationMap from './AttendanceLocationMap';

const ComposedReport = compose(
  withRequestTracker({
    query,
  }),
)(Report);

const ComposedReportWithAttendanceHistory = compose(
  withRequestTracker({
    query,
  }),
)(AttendanceLocationMap);

export { ComposedReport, ComposedReportWithAttendanceHistory as AttendanceLocationMap };
export default ComposedReport;
