import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { tableData } from '../../../../../utils/paginatedListUtils';
import { detailsLabelConfig, DetailTableBody } from './tableConfig';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';
import { ORDER_TYPE } from '../../config';
import {
  replacedDetailsLabelConfig,
  ReplacedDetailTableBody,
} from '../../replaced/detail/tableConfig';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';

const propTypes = {
  activeLine: PropTypes.shape({
    id: PropTypes.number,
    sellerId: PropTypes.number,
  }),
  data: PropTypes.instanceOf(Array),
  handleSliderOpen: PropTypes.func.isRequired,
};

const defaultProps = {
  data: [],
  activeLine: {
    id: '',
    sellerId: 0,
  },
};

const getLabelConfigAndBodyRenderer = (orderType) => {
  if (orderType === ORDER_TYPE.REPLACEMENT) {
    return {
      labelConfigList: replacedDetailsLabelConfig,
      TableBody: ReplacedDetailTableBody,
    };
  }

  return {
    labelConfigList: detailsLabelConfig,
    TableBody: DetailTableBody,
  };
};

const DeliveredDetailChildView = ({
  data, activeLine, onIconClick, amountDetails, orderType, handleSliderOpen, billDiscountDetail,
}) => {
  let activeLabel = 0;
  const { labelConfigList, TableBody } = getLabelConfigAndBodyRenderer(orderType);
  return (
    <Fragment>
      <tr>
        {
          labelConfigList.map((label) => {
            if (label.show) activeLabel += 1;
            return (label.show
              ? tableData(label.title, label.align, label.classNames)
              : null);
          })
        }
      </tr>
      <Fragment>
        {
          data.map(order => order.Lines.map((sku, index) => (
            <tr>
              {TableBody({
                data: sku,
                onIconClick,
                index,
                order,
                handleSliderOpen,
              })}
            </tr>
          )))
        }
      </Fragment>
      { amountDetails.billDiscount > 0 && (
        <tr>
          <td colSpan={activeLabel - 2} />
          <td
            className="text-right clickable"
            onClick={() => onIconClick(EVENT_OPERATION.READ_SUB_TYPE,
              { billDiscountDetail, callOrderValue: { ...amountDetails } })}
          >
            <span className="bill-discount-indicator" />
            Bill Discount(
            <span className="bold">
              {fixedFloatAndCommas(
                (amountDetails.billDiscount
                        / amountDetails.subTotal) * 100, 2,
              )}
              %
            </span>
            )
          </td>
          <td className="text-right">{fixedFloatAndCommas(amountDetails.billDiscount)}</td>
        </tr>
      )}
      { amountDetails.topUpDiscount > 0 && (
        <tr>
          <td colSpan={activeLabel - 2} />
          <td className="text-right">
            <span className="top-up-indicator" />
            Top Up Discount
          </td>
          <td className="text-right">{fixedFloatAndCommas(amountDetails.topUpDiscount)}</td>
        </tr>
      )}
      { amountDetails.tradeDiscount > 0 && (
      <tr>
        <td colSpan={activeLabel - 2} />
        <td className="text-right">
          <span className="trade-discount-indicator" />
                  Trade Discount
        </td>
        <td className="text-right">{fixedFloatAndCommas(amountDetails.tradeDiscount)}</td>
      </tr>
      )}
    </Fragment>
  );
};


DeliveredDetailChildView.propTypes = propTypes;

DeliveredDetailChildView.defaultProps = defaultProps;

export default DeliveredDetailChildView;
