import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import DetailForm from './Form';
import { FORM_CONFIG } from '../../../../../data/enums/config';
import withLoading from '../../../../../utils/composition/withLoading';
import withFormWrapper from '../../../../../utils/composition/withFormWrapper';

const propTypes = {
  getDetails: PropTypes.func,
  getStatus: PropTypes.func,
  handleInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  handleDropDownChange: PropTypes.func,
  formConfig: PropTypes.shape({
    mapper: PropTypes.func,
    refsObj: PropTypes.instanceOf(Object),
    title: PropTypes.string,
    validationRequired: PropTypes.bool,
  }),
  serverResponseWaiting: PropTypes.bool,
  update: PropTypes.instanceOf(Object),
  vendorList: PropTypes.instanceOf(Array),
  distributorList: PropTypes.instanceOf(Array),
};

const defaultProps = {
  update: {
    type: '',
    status: false,
  },
  vendorList: [],
  distributorList: [],
  getStatus: () => null,
  getDetails: () => null,
  enableErrorDisplay: false,
  serverResponseWaiting: false,
  handleInputChange: () => null,
  handleDropDownChange: () => null,
  formConfig: {
    mapper: () => ({}),
    refsObj: {},
    title: '',
    validationRequired: true,
  },
};

class SummaryDetails extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount() {
    const { getStatus, getFormValidationStatus, getDetails } = this.props;
    getStatus(getFormValidationStatus);
    getDetails(this.exportData);
  }

  exportData = () => {
    const { dialogData } = this.props;
    return dialogData;
  };


  render() {
    const {
      update,
      subDUser,
      handleInputChange,
      handleDropDownChange,
      dialogData,
      formConfig,
      enableErrorDisplay,
      vendorList,
      distributorList,
    } = this.props;

    return (
      <Fragment>
        <DetailForm
          show
          update={update}
          subDUser={subDUser}
          data={dialogData}
          enableErrorDisplay={enableErrorDisplay}
          handleInputChange={handleInputChange}
          handleDropDownChange={handleDropDownChange}
          vendorList={vendorList}
          distributorList={distributorList}
          refsObj={formConfig[FORM_CONFIG.REFS_OBJ]}
        />
      </Fragment>
    );
  }
}

SummaryDetails.propTypes = propTypes;

SummaryDetails.defaultProps = defaultProps;

export default withLoading(withFormWrapper(SummaryDetails));
