import React from 'react';
import PropTypes from 'prop-types';
import RoundedLetterStyled from './RoundeLetterStyled';


const RoundedLetter = ({ letter, color }) => (
  <RoundedLetterStyled
    style={{
      backgroundColor: color,
    }}
  >
    {letter}
  </RoundedLetterStyled>
);

RoundedLetter.propTypes = {
  letter: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default RoundedLetter;
