/* global google */
class Marker {
    generateMarker(position, id, name, icon, label, distance, text, details) {
        return new google.maps.Marker({
            position,
            id,
            name,
            icon,
            label,
            distance,
            text,
            details,
            map: window.globalMap
        });
    }
}

const marker = new Marker();
export default marker;
