import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import { Input, CustomSelect } from '../../../../../../components';
import { fixedFloatAndCommas } from '../../../../../../utils/conversion';
import { INVALID } from '../../../../../../data/enums/ErrorMessage';
import { discountOptions } from './config';

const propTypes = {
  data: PropTypes.instanceOf(Array),
};

const defaultProps = {
  data: [],
};

const totalPropTypes = {
  amountDetails: PropTypes.instanceOf(Object),
};

const totalDefaultProps = {
  amountDetails: {},
};

const TableView = ({ ...props }) => {
  const {
    data,
  } = props;
  return (
    <div className="table-wrap">
      <table>
        <thead>
          <th>S.No</th>
          <th>Order ID</th>
          <th>SKU</th>
          <th className="text-right">Quantity</th>
          <th className="text-right">Rate</th>
          <th className="text-right">Amount</th>
          <th className="text-right">Discount</th>
          <th className="text-right">Sub Total</th>
        </thead>
        <tbody>
          {
            data.map((order, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{order.id}</td>
                <td>{order.SKU.title}</td>
                <td className="text-right">{order.quantity}</td>
                <td className="text-right">{order.rateDetails.rlp}</td>
                <td className="text-right">{fixedFloatAndCommas(order.rateDetails.rlp * order.quantity)}</td>
                <td
                  className="text-right"
                >
                  {fixedFloatAndCommas(order.amountDetails.promotionDiscount + (order.amountDetails.topUpDiscount || 0))}

                </td>
                <td className="text-right">{fixedFloatAndCommas(order.amountDetails.subTotal || 0)}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

TableView.propTypes = propTypes;

TableView.defaultProps = defaultProps;

const TotalView = ({ ...props }) => {
  const {
    refsObj,
    amountDetails,
    onInputChange,
    enableErrorDisplay,
    onTradeParamChange,
  } = props;

  return (
    <div className="invoice-table-info">
      <div className="inner">
        <Row>
          <Col md={8}>
            <span>Sub Total</span>
          </Col>
          <Col md={4}>
            <div>
              {fixedFloatAndCommas(amountDetails.subTotal)}
              {' '}
            </div>
          </Col>
          <Col md={8}>
            <span>
Bill Discount&nbsp;(
              {fixedFloatAndCommas((amountDetails.billDiscount / amountDetails.subTotal) * 100 || 0, 2)}
%)
            </span>

          </Col>
          <Col md={4}>
            <div>
              {fixedFloatAndCommas(amountDetails.billDiscount)}
            </div>
          </Col>
          <Col md={8}>
            <div className="trade-discount">
              <span className="">Trade Discount</span>
              <CustomSelect
                isSearchable={false}
                options={discountOptions}
                placeholder=""
                getOptionValue={({ value }) => value}
                getOptionLabel={({ label }) => label}
                value={discountOptions.filter(d => d.value === amountDetails.tradeDiscountParam)}
                onChange={e => onTradeParamChange(e)}
              />
            </div>
          </Col>
          <Col md={4} className="trade-discount-input">
            <Input
              name="tradeDiscount"
              type="number"
              enableValidation
              rule="isFloat"
              argument={{
                min: 0,
                max: amountDetails.tradeDiscountParam === 'rs' ? amountDetails.subTotal - Math.abs(amountDetails.billDiscount) : 90,
              }}
              ref={ref => refsObj.tradeDiscount = ref}
              value={amountDetails.tradeDiscount}
              enableErrorDisplay={enableErrorDisplay}
              onChange={event => onInputChange(event)}
              errorMessage={INVALID}
            />
          </Col>
          <Col md={8}>
            <span>Taxable Amount</span>
          </Col>
          <Col md={4}>
            <div>
              {' '}
              {fixedFloatAndCommas(amountDetails.taxableAmount)}
              {' '}
            </div>
          </Col>

          <Col md={8}>
            <span>VAT</span>
          </Col>
          <Col md={4}>
            <div>
              {fixedFloatAndCommas(amountDetails.taxAmount)}
            </div>
          </Col>
        </Row>
      </div>
      <div className="total-bottom">
        <div className="total-bottom-inner">
          <Row>
            <Col md={6}>
              <span className="total-text">Total</span>
            </Col>
            <Col md={6}>
              <div className="total-number">
                {fixedFloatAndCommas(amountDetails.total)}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

TotalView.propTypes = totalPropTypes;

TotalView.defaultProps = totalDefaultProps;

export { TableView, TotalView };
