import React, { Component, Fragment } from 'react';
import { TableBody, TableHeader } from '../tableConfig';
import { Pagination } from '../../../../../components';
import SplitPayment from '../../../../components/SplitPayment/SplitPayment';

class Received extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: {
        type: '',
        element: '',
      },
    };
  }

    handleIconClick = (type, element = {}) => {
      this.setState({
        dialog: {
          type,
          element,
        },
      });
    };

      resetDialog = () => {
        this.setState({
          dialog: {
            type: '',
            element: '',
          },
        });
      };

      render() {
        const { tableConfig, receiveds, onPageChange } = this.props;
        const { dialog } = this.state;
        return (
          <Fragment>
            {dialog.type && (
            <SplitPayment
              data={dialog.element}
              type={dialog.type}
              resetDialog={this.resetDialog}
            />
            )}
            <div className="table-wrap">
              <table>
                {
                  TableHeader({
                    tableConfig,
                  })
                }

                <tbody>
                  {receiveds.list.map(received => (
                    TableBody({
                      data: received,
                      handleIconClick: this.handleIconClick,
                      tableConfig,
                      section: 'received',
                    })
                  ))}
                </tbody>
              </table>

            </div>
            <Pagination
              currentPage={receiveds.page}
              limit={receiveds.limit}
              totalItems={receiveds.total}
              onPageChange={onPageChange}
            />
          </Fragment>
        );
      }
}

export default Received;
