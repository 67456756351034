import { getFilterConfig } from '../../../utils/filter';
import { inventory as inventoryRoute } from '../../../data/enums/Route';
import { FILTER } from '../../../data/enums/filter';

const breadCrumb = [
  inventoryRoute,
];

const filter = getFilterConfig([FILTER.SKU]);

export {
  breadCrumb as breadCrumbConfig,
  filter as filterConfig,
};
