import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Detail from './detail';
import { TableHeader } from './tableConfig';
import { BreadCrumb, Pagination } from '../../../../components';
import ReceivedStyled from './ReceivedStyled';
import { dataProps, paginationProps } from '../../../../data/enums/Component';
import withErrorAndLoadingScreen from '../../../../utils/composition/withLoading';
import CTAccordionStyled from '../../../../components/CollapsibleTable/CTAccordionStyled';

const propTypes = {
  data: PropTypes.shape({
    list: PropTypes.instanceOf(Array),
    total: PropTypes.number,
    limit: PropTypes.number,
    page: PropTypes.number,
  }),
  pagination: PropTypes.shape({
    page: PropTypes.number,
    limit: PropTypes.number,
    total: PropTypes.number,
  }),
  onPageChange: PropTypes.func.isRequired,
  breadCrumb: PropTypes.instanceOf(Array),
  loading: PropTypes.bool,
  stateSetter: PropTypes.func.isRequired,
  onInvoiceClick: PropTypes.func.isRequired,
  activeCallIndex: PropTypes.number.isRequired,
  onRowClick: PropTypes.func.isRequired,
  handlePassiveTabListChange: PropTypes.func.isRequired,
};

const defaultProps = {
  breadCrumb: [],
  loading: false,
  data: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
};

const ReceivedView = ({ ...props }) => {
  const {
    breadCrumb,
    data,
    loading,
    onPageChange,
    onRowClick,
    onInvoiceClick,
    stateSetter,
    activeCallIndex,
    handlePassiveTabListChange,
    onHeaderClick,
  } = props;

  return (
    <ReceivedStyled>
      <div className="tab-section received">
        <BreadCrumb list={breadCrumb} />
        <CTAccordionStyled>
          <div className="table-wrap">
            <table>
              {
              TableHeader({ onHeaderClick })
            }

              <Fragment>
                <Detail
                  data={data}
                  loadingData={loading}
                  onRowClick={onRowClick}
                  stateSetter={stateSetter}
                  activeCallIndex={activeCallIndex}
                  onInvoiceClick={onInvoiceClick}
                  handlePassiveTabListChange={handlePassiveTabListChange}
                />
              </Fragment>
            </table>
          </div>
        </CTAccordionStyled>

        <Pagination
          currentPage={data.page}
          limit={data.limit}
          totalItems={data.total}
          onPageChange={onPageChange}
        />
      </div>
    </ReceivedStyled>
  );
};

ReceivedView.propTypes = propTypes;

ReceivedView.defaultProps = defaultProps;

const ReceivedViewWithErrorAndLoading = withErrorAndLoadingScreen(ReceivedView);

export default ReceivedViewWithErrorAndLoading;
