import { compose } from 'recompose';

import Receipts from './Receipts';
import { query } from './API';
import { DOWNLOAD_REPORT } from '../../../common/GqlAPI';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';

const composedReceipt = compose(DOWNLOAD_REPORT, withRequestTracker({
  query,
  mutation: {
    downloadReport: DOWNLOAD_REPORT,
  },
}))(Receipts);

export default composedReceipt;
