import gql from 'graphql-tag';
import { persistentClient as clientResolver, client as apolloClient } from '../../apiClient/apollo';
import { GET_TOWN_LIST } from '../../views/configuration/town/API';

// TODO: handle Error in Data Fetch
const errorProcessorInFilterDataFetch = () => null;

const getTownListFromServer = () => apolloClient
  .query({
    query: GET_TOWN_LIST,
    variables: {
      limit: 1000,
      offset: 0,
      filter: {},
    },
  })
  .then(response => response.data.towns.rows)
  .catch(err => errorProcessorInFilterDataFetch(err));

const query = gql`
    query getTownList {
        townList {
            id
            title
        }
    }
`;

const setTown = (data) => {
  clientResolver.then(client => client.writeQuery({
    query,
    data: {
      townList: data,
    },
  }));
};

const fetchAndSetTownList = async () => {
  const townList = await getTownListFromServer();
  setTown(townList);
};

const getTown = async () => clientResolver.then(client => client.readQuery({ query }))
  .then(response => response.townList);

export {
  getTown,
  fetchAndSetTownList,
};
