/* global google */
class Polyline {
    generatePolyline(path, strokeColor, dse) {
        return new google.maps.Polyline({
            path,
            geodesic: true,
            strokeColor,
            strokeOpacity: '2.0',
            strokeWeight: '2.5',
            dse: dse,
            icons: [
                {
                    icon: {
                        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                    },
                    offset: '100%',
                    repeat: '50px',
                },
            ],
            map: window.globalMap,
        });
    }
}

const polyline = new Polyline();
export default polyline       
