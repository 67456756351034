import gql from 'graphql-tag';
import {
  CUSTOMER_FRAGMENT, DISTRIBUTOR_FRAGMENT, OUTLET_CUSTOMER_FRAGMENT,
} from '../../common/GqlFragments';
import { DOWNLOAD_REPORT } from '../../common/GqlAPI';

const SRN_AMOUNT_FRAGMENT = gql`
  fragment SrnAmountFragment on SRNAmount {
    billDiscount
    subTotal
    taxableAmount
    tradeDiscount
    topUpDiscount
    taxAmount
    netAmount
  }
`;

const SALES_RETURN_FRAGMENT = gql`
  fragment SalesReturnFragment on SalesReturn {
    approved
    id
    invoiceNumber
    isCashBill
    reason
    date: returnDate
    SRNInvoiceNumber
    Customer {
      ...CustomerFragment
    }
    Distributor {
      ...DistributorFragment
    }
    RetailOutlet {
      ...OutletCustomerFragment
    }
    srnAmount {
      ...SrnAmountFragment
    } 
  }
  ${CUSTOMER_FRAGMENT}
  ${DISTRIBUTOR_FRAGMENT}
  ${OUTLET_CUSTOMER_FRAGMENT}
  ${SRN_AMOUNT_FRAGMENT}
`;

const GET_SRN_LIST = gql`
  query getSrnList($offset: Int, $limit: Int, $filter: FilterInput) {
    salesReturnNotes(offset: $offset, limit: $limit, filter: $filter){
      rows{ 
        ...SalesReturnFragment
      }
      count
    }
  }
  ${SALES_RETURN_FRAGMENT}
`;

const query = {
  getSrnList: GET_SRN_LIST,
};
const mutation = {
  downloadReport: DOWNLOAD_REPORT,
};

export {
  SALES_RETURN_FRAGMENT,
};

export { query, mutation };
