import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import CatalogView from './View';
import CatalogForm from './Form';
import { DialogWrapper } from '../../common';
import { state } from '../../../data/enums/Component';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import { refGenerator } from '../../../utils/refGenerator';
import withAlert from '../../../utils/composition/withAlert';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { crudRequest as crudRequestConfig, breadCrumbConfig, formConfig } from './config';
import { crudResponseProcessor, handleFormSubmit } from '../../../utils/crudResponseProcessor';
import { isError } from '../../common/HelperFunctions';
import { CATALOG } from '../../../data/enums/Route';

const propTypes = {
  getCatalogList: PropTypes.func.isRequired,
  createCatalog: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class Catalog extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      data: state.data,
      pagination: state.pagination,
      checkedList: [],
      header: 'Add Catalog',
    };

    this.getData = () => {
      const { data } = this.state;
      return data;
    };
    const serverCall = {
      [EVENT_OPERATION.UPDATE]: props.createCatalog,
    };

    this.onCRUDSuccess = crudResponseProcessor((data, type) => {
      const { displayAlert } = this.props;
      this.setState(data);
      displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message);
    }, this.getData, [
      'title', 'territoryId',
    ]);

    this.onFormSubmit = handleFormSubmit(
      this.onCRUDSuccess,
      this.onAPIRequestFailure,
      crudRequestConfig,
      serverCall,
    );

    this.formReference = refGenerator(formConfig.validationField);
  }

  componentDidMount() {
    this.getList();
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  getList() {
    // debugger;
    const { pagination, data } = this.state;
    const { getCatalogList, displayAlert } = this.props;

    getCatalogList(
      {
        offset: 1,
        limit: 50,
      },
      {
        handleSuccess: (response) => {
          data.list = (response.data.catalogs && response.data.catalogs.rows) || [];
          data.total = (response.data.catalogs && response.data.catalogs.count) || 0;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
        handleError: (error) => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  }

  handlePaginationChange = (pagination) => {
    this.setState({ pagination }, () => this.getList());
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleRowClick = (id) => {
    const { history } = this.props;
    history.push(`/${CATALOG}/${id}`);
  };

  render() {
    const {
      data, pagination, checkedList, header, territories,
    } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <Fragment>
        <div>
          <DialogWrapper
            onDialogSubmit={this.onFormSubmit}
            formConfig={formConfig}
            refsObj={this.formReference}
            header={header}
            renderDialog={
              (
                {
                  onDialogSubmit,
                  handleDialogInputChange,
                  handleDialogDropDownChange,
                  dialogData,
                  enableErrorDisplay,
                },
              ) => (
                <Fragment>
                  <CatalogForm
                    show
                    data={dialogData}
                    territories={territories}
                    refsObj={this.formReference}
                    onFormSubmit={onDialogSubmit}
                    loading={serverResponseWaiting}
                    enableErrorDisplay={enableErrorDisplay}
                    handleInputChange={handleDialogInputChange}
                    handleDropDownChange={handleDialogDropDownChange}
                  />
                </Fragment>
              )}
            render={({ onDialogItemClick }) => (
              <CatalogView
                data={data}
                header={header}
                pagination={pagination}
                checkedList={checkedList}
                breadCrumb={breadCrumbConfig}
                onIconClick={onDialogItemClick}
                loading={serverResponseWaiting}
                onRowClick={this.handleRowClick}
                onPageChange={this.handlePaginationChange}
                onCheckBoxClick={this.handleCheckBoxClick}
              />
            )}
          />
        </div>
      </Fragment>
    );
  }
}

Catalog.propTypes = propTypes;

Catalog.defaultProps = defaultProps;

export default withAlert()(Catalog);
