import { compose } from 'recompose';
import { query } from './API';
import TargetAchievementReport from './TargetAchievementReport';
import withRequestTracker from '../../../utils/composition/withRequestTracker';

const composedTargetAchievementReport = compose(
  withRequestTracker({ query }),
)(TargetAchievementReport);

export default composedTargetAchievementReport;
