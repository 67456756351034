import gql from 'graphql-tag';
import { graphql } from 'react-apollo/graphql';
import { SUB_D_DETAILS_FRAGMENT } from '../API';

const GET_SUB_D_DETAIL = gql`
  query getDistributors($id: String) {
    distributors(filter: { filters: [{ column: "id", value: [$id] }, {column: "active", value: ["true", "false"]}] }) {
      rows {
        ...SubDDetailsFragment
        salesReturnPolicy {
          full
          partial
          both
        }
        readyStock
      }
    }
  }
  ${SUB_D_DETAILS_FRAGMENT}
`;

const GET_BILLING_VENDORS = gql`
  query getBillingVendors($distributorId: Int!) {
      billingVendors(distributorId: $distributorId) {
          rows {
              customerId
              title
              state
          }
      }
  }
`;

const UPDATE_SUB_D = graphql(
  gql`
    mutation($id: Int!, $input: DistributorInput!) {
      updateDistributor(id: $id, input: $input) {
        ...SubDDetailsFragment
        salesReturnPolicy {
          full
          partial
          both
        }
        readyStock
      }
    }
    ${SUB_D_DETAILS_FRAGMENT}
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      updateSubD: variables => mutate({
        variables,
      }),
    }),
  },
);

const CREATE_SUB_D = graphql(
  gql`
    mutation($input: DistributorInput!) {
      createDistributor(input: $input) {
        ...SubDDetailsFragment
      }
    }
    ${SUB_D_DETAILS_FRAGMENT}
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      createSubD: variables => mutate({
        variables,
      }),
    }),
  },
);

const GET_DISTRIBUTOR_LABELS = gql`
    query getDistributorLabels {
        getDistributorLabels {
            title
            label
        }
    }
`;

const SYNC_SKU_TO_BILLING = graphql(gql`
    mutation ($distributorId: Int!) {
        syncSkuToBilling(distributorId: $distributorId){
            count
            distributorId
        }
    }`, {
  props: ({ mutate }) => ({
    syncSkuToBilling: variables => mutate({
      variables,
    }),
  }),
});

const query = {
  getSubDDetail: GET_SUB_D_DETAIL,
  getBillingVendors: GET_BILLING_VENDORS,
  getDistributorLabels: GET_DISTRIBUTOR_LABELS,
};

const mutation = {
  updateSubD: UPDATE_SUB_D,
  createSubD: CREATE_SUB_D,
  syncSkuToBilling: SYNC_SKU_TO_BILLING,
};

export { UPDATE_SUB_D, CREATE_SUB_D, SYNC_SKU_TO_BILLING };

export { query, mutation };
