import React from 'react';
import PropTypes from 'prop-types';
import withLoading from '../../../utils/composition/withLoading';
import { Pagination } from '../../../components';
import { dataProps, paginationProps } from '../../../data/enums/Component';
import ReportTableStyled from '../ReportTableStyled';
import { TableHeader, TableBody } from './tableConfig';

const propTypes = {
  data: dataProps.value,
  pagination: paginationProps.value,
  onPageChange: PropTypes.func,
  onCheckBoxClick: PropTypes.func,
  onIconClick: PropTypes.func,
};


const defaultProps = {
  data: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
  onPageChange: () => null,
  onCheckBoxClick: () => null,
  onIconClick: () => null,
};

const View = ({ ...props }) => {
  const {
    data,
    onPageChange,
    pagination,
    handleRowClick,
  } = props;
  return (

    <ReportTableStyled>
      <div className="table-wrap">
        <table>
          <TableHeader />
          <tbody>
            {data.list.map(brandCoverage => (
              <TableBody data={brandCoverage} onRowClick={handleRowClick} />
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </ReportTableStyled>

  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withLoading(View);

export default LoginViewWithErrorAndLoading;
