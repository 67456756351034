import React, { Component } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import Icons from '../../utils/theme/icons';
import { Icon } from '../../components';

class DemoIcon extends Component {
  render() {
    return (
      <div className="demo-section button-wrap">
        <div className="demo-section-inner">
          <div className="demo-header">
            <h2>Icons</h2>
            <p>Icons are a visual representation of an idea and/or action.</p>
          </div>
          <div className="demo-body">
            <div className="demo-content">
              {/* Icons */}
              <div className="demo-content-list">
                <div className="demo-sample">
                  <Row>
                    {
                      Object.keys(Icons.icons)
                        .map((key, index) => {
                          return (
                            <Col xs={2} key={index}>
                              <div className="bg-light-grey" style={{ height: '120px' }}>
                                <div className="icon-list-inner">
                                  <Icon
                                    iconName={`${Icons.icons[key]}`}
                                    iconWidth="24px"
                                    iconHeight="24px"
                                  />
                                </div>
                              </div>
                              <h5 style={{
                                marginBottom: 50,
                                textAlign: 'center'
                              }}>{Icons.icons[key]}</h5>
                            </Col>
                          );
                        })
                    }
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DemoIcon;
