import { getUserRole } from '../../data/services/userLabel';
import { arrayToObj } from '../../utils/arrayProcessor';
import { JWT } from '../../environment';

const getHeaderConfig = async () => getUserRole().then((res) => {
  if (res && res.data.roles && res.data.roles.rows) return res.data.roles.rows;

  return [];
});

const setHeaderConfig = async () => {
  const configList = await getHeaderConfig();
  const configObj = arrayToObj(configList, 'name', 'displayName');
  localStorage.setItem(JWT.LOCAL_STORAGE.LABEL.HEADER, JSON.stringify(configObj));
};


const headerLabelService = {
  setHeaderConfig,
  getHeaderConfig,
};

export default headerLabelService;
