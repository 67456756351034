import { compose } from 'recompose';
import Main from './Main';
import { GET_OUTLETS_ARC, GET_ROUTES_ARC, SAVE_ROUTES_ARC, GET_ROSIA_ROUTES } from '../services/ApiGql';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';
import { GET_TOWN_LIST } from '../../town/API';

const ComposedARCmap = compose(
    SAVE_ROUTES_ARC,
    withRequestTracker({
        query: {
            getOutlets: GET_OUTLETS_ARC,
            getRoutes: GET_ROUTES_ARC,
            getTownList: GET_TOWN_LIST,
            getRosiaRoutes: GET_ROSIA_ROUTES
        },
        mutation: {
            saveRoutes: SAVE_ROUTES_ARC
        }
    }),
)(Main);

export default ComposedARCmap;
