import React from 'react';
import {
  salesForce,
} from '../../../data/enums/Route';

const breadCrumb = [
  salesForce,
];

const HEADER_ICONS = {
  PRINT: 'PRINT',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  HEADER: 'HEADER',
  TODAY: 'TODAY',
  LOCK: 'LOCK',
  REPLICATION: 'REPLICATION',
};


const DialogContext = React.createContext({});


export {
  HEADER_ICONS,
  breadCrumb as breadCrumbConfig,
  DialogContext,
};
