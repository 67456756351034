import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  TableBody,
  TableHeader,
  skuDistributionLink,
} from './tableConfig';
import {
  controlFilterDisplay,
  getDistributorFilteredViewList,
} from '../../../common/HelperFunctions';
import { getUser } from '../../../../data/dao';
import PageHeader from '../../../base/PageHeader';
import CoverageView from '../View';
import SkuCoverageStyled from '../SkuCoverageStyled';
import { apiFilterProcessor } from '../../../../utils/api';
import * as queryService from '../../../base/query.service';
import { PanelStyled } from '../../../common/configuration';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import { COVERAGE_TYPE } from '../../../../data/enums/GraphQL';
import withAlert from '../../../../utils/composition/withAlert';
import { fixedFloatAndCommas } from '../../../../utils/conversion';
import { reports, SKU_DISTRIBUTION } from '../../../../data/enums/Route';
import { computeFilterConfig, VIEW_LIST } from '../../productive-coverage/config';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  location: PropTypes.instanceOf(Object),
  getSKUDetailCoverage: PropTypes.func.isRequired,
};

const defaultProps = {
  location: {
    state: {},
  },
  serverResponseWaiting: false,
};

class SKUDistribution extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    const { state = {} } = props.location;
    this.state = {
      data: {
        list: [],
        coverage: 0,
        total: 0,
      },
      display: {
        searchBox: false,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
        type: COVERAGE_TYPE.CHANNEL,
      },
      filterConfig: computeFilterConfig(COVERAGE_TYPE.CHANNEL),
    };
    const { downloadReport, displayAlert } = props;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadReport,
      displayAlert,
    );
    this.breadCrumbConfig = [{
      ...reports,
      state,
    }, skuDistributionLink];
    this.userInfo = getUser();
    this.viewList = getDistributorFilteredViewList(this.userInfo,
      VIEW_LIST.filter(view => view.label !== COVERAGE_TYPE.ROUTE));
  }

  componentDidMount() {
    this.loadTableData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { queryParameters } = this.state;
    if (prevState.queryParameters.type !== queryParameters.type) {
      queryParameters.filter = queryService.baseQueryParameters.filter;
      this.setState({ filterConfig: computeFilterConfig(queryParameters.type), queryParameters });
    }
  }

  loadTableData = () => {
    const { queryParameters, data } = this.state;
    const { getSKUDetailCoverage, match } = this.props;
    getSKUDetailCoverage({
      id: parseInt(match.params.id || 0, 10),
      type: queryParameters.type,
      filter: {
        filters: apiFilterProcessor(queryParameters.filter),
        queryString: queryParameters.search,
        dateRange: queryParameters.date,
      },
    }, {
      handleSuccess: (response) => {
        const { getSkuDetailCoverage = {} } = response.data;
        data.list = (getSkuDetailCoverage && getSkuDetailCoverage.rows) || [];
        data.total = (getSkuDetailCoverage && getSkuDetailCoverage.rows.length) || 0;
        data.coverage = getSKUDetailCoverage.coverage || 0;
        this.setState({ data });
      },
      handleError: (err) => {
        console.log(err);
        this.onAPIRequestFailure(err);
      },
    });
  };

  setQueryParameters = (queryParams, callBack = () => null) => this.setState(
    { queryParameters: queryParams },
    callBack,
  );

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  handleTableRowClick = (type, row) => {
    const { history, location } = this.props;
    const { state = {} } = location;
    if (type === COVERAGE_TYPE.CHANNEL) {
      history.push(`/${SKU_DISTRIBUTION}/${state.skuId}/details/${row.id}`,
        {
          skuId: state.skuId, sku: state.title, title: row.name, type, detailId: row.id,
        });
    }
  };

  render() {
    const {
      data, queryParameters, display, filterConfig,
    } = this.state;
    const { serverResponseWaiting, location } = this.props;
    const { state = {} } = location;

    return (
      <SkuCoverageStyled>
        <div className="section-header">
          <PanelStyled>
            <PageHeader
              display={display}
              breadCrumb={this.breadCrumbConfig}
              config={{
                title: `${state.title} ${data.coverage ? `: ${fixedFloatAndCommas(data.coverage)}%` : ''}`,
                create: false,
                download: false,
                filter: controlFilterDisplay(filterConfig),
                search: true,
                date: true,
                singleDate: false,
                viewType: true,
              }}
              filter={{
                updateMenu: true,
                date: queryParameters.date,
                menuList: filterConfig.menu,
                onFilterChange: this.basePaginationService.handleFilterChange,
              }}
              viewTypeConfig={{
                value: queryParameters.type,
                list: this.viewList,
              }}
              handleViewTypeChange={this.basePaginationService.handleViewTypeChange}
              queryParameters={queryParameters}
              controlDisplay={this.controlDisplay}
              handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
              handleSearchChange={this.basePaginationService.handleSearchInputChange}
            />
          </PanelStyled>
        </div>
        <div className="section-content">
          <CoverageView
            data={data}
            permission={this.permission}
            pagination={queryParameters.pagination}
            handleRowClick={this.handleTableRowClick}
            loading={serverResponseWaiting}
            viewType={queryParameters.type}
            onPageChange={this.basePaginationService.onPageSelect}
            TableHeader={TableHeader}
            TableBody={TableBody}
          />
        </div>
      </SkuCoverageStyled>
    );
  }
}

SKUDistribution.propTypes = propTypes;

SKUDistribution.defaultProps = defaultProps;

export default withAlert()(SKUDistribution);
