import React from 'react';
import { IMAGE_STATUS_MAPPER } from '../../../../components/ImageUpload/config';
import {
  ProgressBarStyled,
  ProgressCardStyled,
  UploadStyled,
} from '../../../../components/ImageUpload/UploadStyled';
import DialogHeader from '../../../../../components/Dialog/DialogHeader';
import { Icon, Button } from '../../../../../components';
import DialogBody from '../../../../../components/Dialog/DialogBody';
import DialogFooter from '../../../../../components/Dialog/DialogFooter';
import withErrorAndLoading from '../../../../../utils/composition/withError';

const propTypes = {};

const getSubText = (data) => {
  if (data.status === IMAGE_STATUS_MAPPER.SUCCESS) {
    return (
      <div className='sucess'>
        {' '}
        <Icon iconName='check-circle-full' />
        Success
      </div>
    );
  }
  if (data.status === IMAGE_STATUS_MAPPER.DANGER) {
    return (
      <div className='failed'>
        <Icon iconName='exclamation-circle' /> Error!
      </div>
    );
  }
};
const UploadView = ({
  onDialogCancel,
  onDialogSubmit,
  title = 'Syncing SKU',
  data,
}) => {
  const disableDone = data.status === IMAGE_STATUS_MAPPER.PENDING;
  const disableCross = disableDone || data.status === IMAGE_STATUS_MAPPER.EMPTY;
  return (
    <UploadStyled>
      <div className='modal-wrapper'>
        <div className='modal-inner image sync-progress'>
          <DialogHeader>
            <h2>{title}</h2>
            {/* <Icon
              iconName='times'
              onClick={(e) => onDialogCancel(e)}
              className={disableDone ? 'disable-btn' : ''}
              style={{
                cursor: 'pointer',
                pointerEvents: disableCross ? 'none' : 'unset',
                opacity: disableCross ? 0.3 : 1,
              }}
            /> */}
          </DialogHeader>
          <DialogBody pad0>
            <div className='img-upload-wrap'>
              <ProgressCardStyled
                status={data.status}
                className='progress-card'
              >
                <div className='progress-bar-wrap'>
                  <p className='count-total'>
                    {data.count}/{data.count}
                  </p>
                  <div className='progress-bar-styled-wrap'>
                    <ProgressBarStyled
                      status={data.status}
                      className='progress-wrap'
                    >
                      <div
                        className='progress-bar'
                        style={{ display: 'flex', borderRadius: '4px' }}
                      >
                        <div
                          style={{
                            height: '8px',
                            width: `${data.progress}%`,
                            borderRadius: '4px',
                            backgroundColor: '#0B5FFF',
                          }}
                        />
                      </div>
                    </ProgressBarStyled>
                    <span>{data.progress}%</span>
                  </div>
                  {getSubText(data)}
                </div>
              </ProgressCardStyled>
            </div>
          </DialogBody>
          <DialogFooter>
            <Button
              primary
              className={disableDone ? 'disabled-btn' : ''}
              disabled={disableDone}
              onClick={(e) => onDialogCancel(e)}
            >
              ok
            </Button>
          </DialogFooter>
        </div>
        <div className='overlay' />
      </div>
    </UploadStyled>
  );
};

UploadView.propTypes = propTypes;

const UploadViewWithLoading = withErrorAndLoading(UploadView);

export default UploadViewWithLoading;
