import React from 'react';
import PropTypes from 'prop-types';
import { tableData } from '../../../utils/paginatedListUtils';
import { normalPresentor, timePresenter } from '../../../utils/date';
import { numberPresentor } from '../../../utils/conversion';
import { USER_ROLE_TITLE } from '../../../data/enums/UserRole';
import { headerLabelConfig } from '../../common/HelperFunctions';

const labelMappings = {
  date: 'date',
  dse: 'dse',
  outlet: 'outlet',
  channel: 'channel',
  category: 'category',
  callPerformed: 'callPerformed',
  callStatus: 'callStatus',
  remarks: 'remarks',
  callOrderValue: 'callOrderValue',
  callTime: 'callTime',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.date,
    title: 'Date',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.dse,
    title: headerLabelConfig[USER_ROLE_TITLE.DSE],
    show: true,
  },
  {
    id: 3,
    label: labelMappings.outlet,
    title: 'Outlet',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.channel,
    title: 'Channel',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.category,
    title: 'Category',
    show: true,
  }, {
    id: 6,
    label: labelMappings.callPerformed,
    title: 'Call Performed',
    show: true,
  },
  {
    id: 7,
    label: labelMappings.callStatus,
    title: 'Call Status',
    show: true,
  },
  {
    id: 8,
    label: labelMappings.remarks,
    title: 'Remarks',
    show: true,
  },
  {
    id: 9,
    label: labelMappings.callOrderValue,
    title: 'Call Order Value',
    show: true,
  },
  {
    id: 9,
    label: labelMappings.callTime,
    title: 'Call Time(Start-End)',
    show: true,
  },
];

const TableHeader = () => (
  <thead className="">
    <tr>
      {labelConfig.map(label => (label.show
        ? tableData(label.title, label.align, label.classNames) : null))}
    </tr>
  </thead>
);

const returnTableBody = (data) => {
  const table = {};

  table[labelMappings.date] = (
    <td key={`${data.id}-date`}>{normalPresentor(data.date)}</td>
  );
  table[labelMappings.dse] = (
    <td key={`${data.id}-dse`}>
      {`${data.User.firstName} 
      ${data.User.lastName}`}
    </td>
  );
  table[labelMappings.outlet] = (
    <td key={`${data.id}-outlet`}>
      {data.RetailOutlet.title}
    </td>
  );
  table[labelMappings.channel] = (
    <td key={`${data.id}-channel`}>
      {data.RetailOutlet.Category.Channel.title}
    </td>
  );
  table[labelMappings.category] = (
    <td key={`${data.id}-category`}>
      {data.RetailOutlet.Category.title}
    </td>
  );
  table[labelMappings.callPerformed] = (
    <td key={`${data.id}-callPerformed`} className="text-center">
      <span
        className={`status-label ${data.callStatus ? 'active' : 'passive'}`}
      >
        {
                      data.callStatus ? 'YES' : 'NO'
                    }
      </span>
    </td>
  );
  table[labelMappings.callStatus] = (
    <td key={`${data.id}-callStatus`}>
      {data.Call ? data.Call.type : ''}
    </td>
  );
  table[labelMappings.remarks] = (
    <td key={`${data.id}-remarks`}>
      {data.Call ? data.Call.notes : ''}
    </td>
  );
  table[labelMappings.callOrderValue] = (
    <td key={`${data.id}-callOrderValue`} className="text-right">
      {data.Call && data.Call.callOrderValue
        ? numberPresentor(data.Call.callOrderValue.netAmount) : 0 }
    </td>
  );
  table[labelMappings.callTime] = (
    <td key={`${data.id}-callTime`}>
      {(data.Call && data.Call.callTime) ? `${timePresenter(data.Call.callTime.callStart)} - ${timePresenter(data.Call.callTime.callEnd)}` : '-' }
    </td>
  );

  return table;
};

const TableBody = ({
  data,
}) => {
  // Expanding/Dragging and collapsing table body.
  // color based upon table body
  // tooltip if it is sorted.
  // active className
  const tableBody = returnTableBody(data);

  return (

    <tr key={`userCluster-${data.id}`}>
      {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
};

TableBody.defaultProps = {
  data: {},
};

export {
  TableHeader,
  TableBody,
};
