import { compose } from 'recompose';
import { query } from './API';
import Cash from './Cash';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';
import { DOWNLOAD_REPORT } from '../../../common/GqlAPI';

const composedCash = compose(DOWNLOAD_REPORT, withRequestTracker({
  query,
  mutation: {
    downloadReport: DOWNLOAD_REPORT,
  },
}))(Cash);

export default composedCash;
