import * as downloadService from './download.service';
import { SORTING, PAGINATION } from '../../data/enums';
import { ALERT_TYPE } from '../../data/enums/AlertType';
import { formatter, getStartOfCurrentMonth, getCurrentDay } from '../../utils/date';
import { generateFilterValue } from '../../utils/filter';
import { apiFilterProcessor } from '../../utils/api';
import { clone } from '../../utils/objectProcessor';

const baseQueryParameters = {
  search: '',
  sort: {
    label: '',
    order: '',
  },
  pagination: {
    page: PAGINATION.PAGE,
    limit: PAGINATION.LIMIT,
  },
  filter: {},
  date: {
    start: getStartOfCurrentMonth(),
    end: getCurrentDay(),
  },
  singleDate: {
    start: getCurrentDay(),
    end: getCurrentDay(),
  },
};

const resetQueryParameters = (query) => {
  const updatedQuery = { ...query };

  const {
    filter = {},
    pagination = null,
    date = {},
    sort = {},
  } = updatedQuery;

  Object
    .keys(filter)
    .forEach((attribute) => {
      updatedQuery.filter[attribute] = [];
    });

  if (pagination && pagination.page) {
    updatedQuery.pagination.page = 1;
  }

  if (date && date.start && date.end) {
    updatedQuery.date.start = getStartOfCurrentMonth();
    updatedQuery.date.end = getCurrentDay();
  }

  if (sort.order && sort.label) {
    updatedQuery.sort.label = '';
    updatedQuery.sort.order = SORTING.ASC;
  }

  return updatedQuery;
};

// queryParamsSetter
// queryParamsGetter
// tableDataLoader
// downloadList: default to null

class QueryClass {
  constructor(setter, getter, dataLoader, downloadList, displayAlert) {
    this.stateSetter = setter;
    this.stateGetter = getter;
    this.dataLoader = dataLoader;
    this.alertDisplayer = displayAlert;
    this.downloadList = downloadList;
  }

  setQueryParamsAndLoadData = (newQueryParams) => {
    this.stateSetter(newQueryParams, () => {
      this.dataLoader();
    });
  };

  resetFilter = () => {
    const queryParams = this.stateGetter();
    const newQueryParams = resetQueryParameters(queryParams);
    this.setQueryParamsAndLoadData(newQueryParams);
  };

  onPageSelect = (pagination) => {
    const queryParameters = this.stateGetter();

    queryParameters.pagination.page = pagination.page;
    queryParameters.pagination.limit = pagination.limit;

    this.setQueryParamsAndLoadData(queryParameters);
  };

  handleDateRangeChange = (field, date) => {
    const queryParameters = this.stateGetter();

    // debugger;

    queryParameters.date.start = formatter(date.start)
      .format('DD MMM YYYY');
    queryParameters.date.end = formatter(date.end)
      .format('DD MMM YYYY');
    queryParameters.pagination.page = 1;

    this.setQueryParamsAndLoadData(queryParameters);
  };

  handleSingleDateRangeChange = (field, date) => {
    const queryParameters = this.stateGetter();

    // debugger;

    queryParameters.date.start = formatter(date)
      .format('DD MMM YYYY');
    queryParameters.date.end = formatter(date)
      .format('DD MMM YYYY');
    queryParameters.pagination.page = 1;

    this.setQueryParamsAndLoadData(queryParameters);
  }

  handleDateChange = (date) => {
    const queryParameters = this.stateSetter();
    queryParameters.date.start = date.format('DD MMM YYYY');

    this.setQueryParamsAndLoadData(queryParameters);
  };

  handleFilterChange = (fieldValue) => {
    const queryParameter = this.stateGetter();
    queryParameter.filter = generateFilterValue(fieldValue);
    queryParameter.pagination.page = 1;

    this.setQueryParamsAndLoadData(queryParameter);
  };

  handleTableSorting = (labelName, sortingOrder) => {
    const queryParameter = this.stateGetter();

    queryParameter.sort.label = labelName;
    queryParameter.sort.order = sortingOrder;

    this.setQueryParamsAndLoadData(queryParameter);
  };

  handleSearchInputChange = (value) => {
    const queryParameter = this.stateGetter();

    queryParameter.search = value;
    queryParameter.pagination.page = 1;

    // TODO
    this.setQueryParamsAndLoadData(queryParameter);
    // debounce and load data
  };

  clearSearchText = () => {
    const queryParameters = this.stateGetter();
    queryParameters.search = '';
    this.setQueryParamsAndLoadData(queryParameters);
  };

  handleDownloadClick = (reportType = 'csv', domain, apiTitle) => {
    const queryParameter = this.stateGetter(); // pagination, search, filter and date.

    const filter = {
      filters: apiFilterProcessor(queryParameter.filter),
      queryString: queryParameter.search,
    };

    if (queryParameter.date) {
      filter.dateRange = queryParameter.date;

      if (queryParameter.date.filterBy) {
        filter.dateRange.filterBy = queryParameter.date.filterBy;
      }
    }

    this.downloadList({
      input: {
        domain,
        filter,
        reportType: 'csv',
      },
    }, {
      handleSuccess: (response) => {
        downloadService.resolver(response.data.downloadReport.file);
        this.alertDisplayer(ALERT_TYPE.SUCCESS, 'Successfully downloaded file');
      },
      handleError: (error) => {
        this.alertDisplayer(ALERT_TYPE.CUSTOM_DANGER, 'Cannot download file');
      },
    });
  };

  handleDownloadClickWithCustomFilter = (reportType = 'csv', domain, filter) => {
    this.downloadList({
      input: {
        domain,
        filter,
        reportType,
      },
    }, {
      handleSuccess: (response) => {
        downloadService.resolver(response.data.downloadReport.file);
        this.alertDisplayer(ALERT_TYPE.SUCCESS, 'Successfully downloaded file');
      },
      handleError: (error) => {
        this.alertDisplayer(ALERT_TYPE.CUSTOM_DANGER, 'Cannot download file');
      },
    });
  };

  handleViewTypeChange = (field, value) => {
    const queryParameter = clone(this.stateGetter());
    queryParameter[field] = value;
    this.setQueryParamsAndLoadData(queryParameter);
  };
}

export {
  baseQueryParameters,
  resetQueryParameters,
  QueryClass,
};
