import {
  compose,
} from 'recompose';
import SUBD from './SubD';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  query, mutation, TOGGLE_STATE,
} from './API';

const ComposedSUBD = compose(
  TOGGLE_STATE,
  withRequestTracker({
    query,
    mutation,
  }),
)(SUBD);

export default ComposedSUBD;
