import React, {Component} from 'react';
import Colors from '../../utils/theme/color';
import Wrapper from './wrapper';
import { Row, Col } from 'react-flexbox-grid';

class DemoColorPalette extends Component {
    render() {
        return (
            <Wrapper>
                <div className="demo-section color-wrap">
                    <div className="demo-section-inner">
                        <div className="demo-header">
                            <h2>Colors</h2>
                            <p>Evolve design system color palette comes with 10 different shades for each hue, ranging from 050 (lightest) to 900 (darkest). Only grey color range from 050 to 1900. When using  colors start with the 500 weight then go up or down in weight.  Shades are an exception, where P500 and SR500, SG500, SY500,SM500 are our primary and secondary weights respectively.</p>
                        </div>
                        <div className="demo-body">
                            <div className="demo-content">
                                <div className="demo-content-list">
                                    <h3>Primary color shades</h3>
                                    <div className="demo-sample">
                                        <Row>
                                            {
                                                Object.keys(Colors.primary).map((key,index) => {
                                                    return (
                                                        <Col xs={2} key={index}>
                                                            <div className="palette-list" >
                                                                <div className="palette-list-inner">
                                                                    <div className="bg" style={{backgroundColor: `${Colors.primary[key]}`}}></div>
                                                                    <h2>{key}</h2>
                                                                    <p>{Colors.primary[key]}</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                    </div>
                                </div>
                                <div className="demo-content-list">
                                    <h3>Secondary Red color shades</h3>
                                    <div className="demo-sample">
                                        <Row>
                                            {
                                                Object.keys(Colors.secRed).map((key,index) => {
                                                    return (
                                                        <Col xs={2} key={index}>
                                                            <div className="palette-list" >
                                                                <div className="palette-list-inner">
                                                                    <div className="bg" style={{backgroundColor: `${Colors.secRed[key]}`}}></div>
                                                                    <h2>{key}</h2>
                                                                    <p>{Colors.secRed[key]}</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                    </div>
                                </div>
                                <div className="demo-content-list">
                                    <h3>Secondary Yellow color shades</h3>
                                    <div className="demo-sample">
                                        <Row>
                                            {
                                                Object.keys(Colors.secYellow).map((key,index) => {
                                                    return (
                                                        <Col xs={2} key={index}>
                                                            <div className="palette-list">
                                                                <div className="palette-list-inner">
                                                                    <div className="bg" style={{backgroundColor: `${Colors.secYellow[key]}`}}></div>
                                                                    <h2>{key}</h2>
                                                                    <p>{Colors.secYellow[key]}</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                    </div>
                                </div>
                                <div className="demo-content-list">
                                    <h3>Secondary Magenta color shades</h3>
                                    <div className="demo-sample">
                                        <Row>
                                            {
                                                Object.keys(Colors.secMagenta).map((key,index) => {
                                                    return (
                                                        <Col xs={2} key={index}>
                                                            <div className="palette-list">
                                                                <div className="palette-list-inner">
                                                                    <div className="bg" style={{backgroundColor: `${Colors.secMagenta[key]}`}}></div>
                                                                    <h2>{key}</h2>
                                                                    <p>{Colors.secMagenta[key]}</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                    </div>
                                </div>
                                <div className="demo-content-list">
                                    <h3>Secondary Green color shades</h3>
                                    <div className="demo-sample">
                                        <Row>
                                            {
                                                Object.keys(Colors.secGreen).map((key,index) => {
                                                    return (
                                                        <Col xs={2} key={index}>
                                                            <div className="palette-list">
                                                                <div className="palette-list-inner">
                                                                    <div className="bg" style={{backgroundColor: `${Colors.secGreen[key]}`}}></div>
                                                                    <h2>{key}</h2>
                                                                    <p>{Colors.secGreen[key]}</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                    </div>
                                </div>
                                <div className="demo-content-list">
                                    <h3>Grey color shades</h3>
                                    <div className="demo-sample">
                                        <Row>
                                            {
                                                Object.keys(Colors.grey).map((key,index) => {
                                                    return (
                                                        <Col xs={2} key={index}>
                                                            <div className="palette-list">
                                                                <div className="palette-list-inner">
                                                                    <div className="bg" style={{backgroundColor: `${Colors.grey[key]}`}}></div>
                                                                    <h2>{key}</h2>
                                                                    <p>{Colors.grey[key]}</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                    </div>
                                </div>
                                <div className="demo-content-list">
                                    <h3>System Alert Shades</h3>
                                    <div className="demo-sample">
                                        <Row>
                                            {
                                                Object.keys(Colors.system).map((key,index) => {
                                                    return (
                                                        <Col xs={2} key={index}>
                                                            <div className="palette-list">
                                                                <div className="palette-list-inner">
                                                                    <div className="bg" style={{backgroundColor: `${Colors.system[key]}`}}></div>
                                                                    <h2>{key}</h2>
                                                                    <p>{Colors.system[key]}</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Wrapper>
        );
    }
}

export default DemoColorPalette;
