import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Button, DialogBody, DialogFooter, DialogHeader, Icon,
} from '../../components';
import { has } from '../../utils/objectPrototypes';
import { FORM_CONFIG } from '../../data/enums/config';
import { EVENT_OPERATION } from '../../data/enums/EventOperation';

const propTypes = {
  type: PropTypes.string,
  enableReset: PropTypes.bool,
  activeStatus: PropTypes.bool,
  onDialogSubmit: PropTypes.func,
  onDialogCancel: PropTypes.func,
  renderDialog: PropTypes.func,
  handleInputChange: PropTypes.func,
  handleFileUpload: PropTypes.func,
  handleFormSubmit: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  handleDropDownChange: PropTypes.func,
  getState: PropTypes.func,
  updateState: PropTypes.func,
  handleMultipleFileUpload: PropTypes.func,
  dialogData: PropTypes.instanceOf(Object),
  dialogElement: PropTypes.instanceOf(Object),
  formConfig: PropTypes.shape({
    mapper: PropTypes.func,
    refsObj: PropTypes.instanceOf(Object),
    title: PropTypes.string,
    validationRequired: PropTypes.bool,
  }),
  getExtraFooterElements: PropTypes.func,
  extraFooterCallBack: PropTypes.func,
};

const defaultProps = {
  type: '',
  enableReset: true,
  dialogData: {},
  renderDialog: () => null,
  enableErrorDisplay: false,
  onDialogSubmit: () => null,
  onDialogCancel: () => null,
  handleFormSubmit: () => null,
  handleFileUpload: () => null,
  handleInputChange: () => null,
  handleDropDownChange: () => null,
  formConfig: {
    mapper: () => ({}),
    refsObj: {},
    title: '',
    validationRequired: true,
  },
  getState: () => null,
  updateState: () => null,
  handleMultipleFileUpload: () => null,
  controlDialogClose: false,
  getExtraFooterElements: () => (<span />),
  extraFooterCallBack: () => null,

};

class ModalWrapper extends Component {
  constructor() {
    super();
    this.state = {
      submitClicked: false,
      uploadInvalid: false,
    };
  }

  handleSubmit = () => {
    const {
      type, dialogElement, dialogData, onDialogSubmit, onDialogCancel, handleFormSubmit, enableReset,
      activeStatus,
    } = this.props;
    if (this.checkValidationStatus()) {
      handleFormSubmit();
    } else {
      const data = activeStatus ? dialogElement : dialogData;
      onDialogSubmit(type, data);
      if (enableReset)onDialogCancel();
    }
  };


  checkValidationStatus = () => {
    const { formConfig } = this.props;
    return (
      has.call(formConfig, FORM_CONFIG.VALIDATION_REQUIRED) ? formConfig.validationRequired : false
    );
  };

  disableOnInvalidCSV = () => {
    // for csv date
    this.setState({ uploadInvalid: true });
  }

  render() {
    const { submitClicked, uploadInvalid } = this.state;
    const {
      formConfig: { title },
      formConfig: { buttonName },
      formConfig: { refsObj },
      formConfig: { getExtraFooterElements = () => (<span />) },
      handleInputChange,
      handleDropDownChange,
      handleFileUpload,
      dialogData,
      onDialogCancel,
      renderDialog,
      enableErrorDisplay,
      handleMultipleUpload,
      getState,
      updateState,
      type,
      controlDialogClose,
      extraFooterCallBack,
      formTitle,
    } = this.props;


    const { handleSubmit, disableOnInvalidCSV } = this;
    const newProps = {
      refsObj,
      getState,
      dialogData,
      updateState,
      handleFileUpload,
      handleInputChange,
      handleDropDownChange,
      enableErrorDisplay,
      handleMultipleUpload,
      disableOnInvalidCSV,
    };

    return (
      <Fragment>
        <Fragment>
          <div className="modal-wrapper">
            <div className={`modal-inner ${type ? type.toLowerCase() : ''}`}>
              <DialogHeader>
                <h2>{formTitle ? `${title} ${formTitle}` : title }</h2>
                <Icon iconName="times" onClick={e => onDialogCancel(e)} style={{ cursor: 'pointer' }} />
              </DialogHeader>
              <DialogBody type={type === 'UPLOAD' ? 'dialog' : ''}>
                {renderDialog(newProps)}
              </DialogBody>
              <DialogFooter>
                { getExtraFooterElements(extraFooterCallBack) }
                <Button secondary onClick={e => onDialogCancel(e)}>
                  <span>Cancel</span>
                </Button>
                { type !== EVENT_OPERATION.READ && type !== EVENT_OPERATION.READ_SUB_TYPE ? (
                  <Button
                    primary
                    className={submitClicked ? 'disabled-btn' : ''}
                    disabled={submitClicked || uploadInvalid}
                    onClick={(e) => {
                      if (controlDialogClose) this.setState({ submitClicked: true });
                      handleSubmit(e);
                    }}
                  >
                    <span>
                      { buttonName || 'Ok' }
                    </span>
                  </Button>
                ) : <Fragment />}
              </DialogFooter>
            </div>
            <div className="overlay" />
          </div>
        </Fragment>
      </Fragment>
    );
  }
}

ModalWrapper.propTypes = propTypes;

ModalWrapper.defaultProps = defaultProps;

export default ModalWrapper;
