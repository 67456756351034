import gql from 'graphql-tag';
import { graphql } from 'react-apollo/graphql';
import { TOGGLE_STATE } from '../../common/GqlAPI';

const MERCHANDISE_DEVICE_FRAGMENT = gql`
  fragment MerchandiseDeviceFragment on MerchandiseDevice {
    id
    title
    active
    categoryIds
    Categories {
        id
        title
    }
  }
`;

const GET_MERCHANDISE_DEVICE_LIST = gql`
    query getMerchandeDeviceList($offset: Int, $limit: Int, $filter: FilterInput) {
        merchandiseDevices(offset: $offset, limit: $limit, filter: $filter) {
            rows {
                ...MerchandiseDeviceFragment
            }
            count
        }
    }
    ${MERCHANDISE_DEVICE_FRAGMENT}
`;

const CREATE_MERCHANDISE_DEVICE = graphql(
  gql`
    mutation($title: String!, $categoryIds: [Int]!) {
        createMerchandiseDevice(title: $title, categoryIds: $categoryIds) {
           ...MerchandiseDeviceFragment 
        }
    }
    ${MERCHANDISE_DEVICE_FRAGMENT}
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      createMerchandiseDevice: variables => mutate({
        variables,
      }),
    }),
  },
);

const UPDATE_MERCHANDISE_DEVICE = graphql(
  gql`
      mutation($id: Int!, $title: String!, $categoryIds: [Int]!) {
          updateMerchandiseDevice(id: $id, title: $title, categoryIds: $categoryIds) {
              ...MerchandiseDeviceFragment
          }
      }
      ${MERCHANDISE_DEVICE_FRAGMENT}
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      updateMerchandiseDevice: variables => mutate({
        variables,
      }),
    }),
  },
);


const query = {
  getMerchandiseDeviceList: GET_MERCHANDISE_DEVICE_LIST,
};

const mutation = {
  createMerchandiseDevice: CREATE_MERCHANDISE_DEVICE,
  updateMerchandiseDevice: UPDATE_MERCHANDISE_DEVICE,
  deleteMerchandiseDevice: TOGGLE_STATE,
  toggleState: TOGGLE_STATE,
};


export {
  MERCHANDISE_DEVICE_FRAGMENT,
  CREATE_MERCHANDISE_DEVICE,
  UPDATE_MERCHANDISE_DEVICE,
  TOGGLE_STATE,
};

export { query, mutation };
