import React from 'react';
import PropTypes from 'prop-types';
import { tableData } from '../../../../utils/paginatedListUtils';
import { CheckBox } from '../../../../components';
import Icon from '../../../../components/Icon';
import { normalPresentor } from '../../../../utils/date';
import { fixedFloatAndCommas } from '../../../../utils/conversion';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { USER_ROLE_TITLE } from '../../../../data/enums/UserRole';
import { headerLabelConfig } from '../../../common/HelperFunctions';

const labelMappings = {
  checkbox: 'checkbox',
  date: 'date',
  outlet: 'outlet',
  collector: 'collector',
  route: 'route',
  amount: 'amount',
  alias: 'alias',
  remarks: 'remarks',
  distributor: 'distributor',
  dse: 'dse',
  userRole: 'userRole',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.checkbox,
    title: '',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.date,
    title: 'Date',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.outlet,
    title: 'Outlet',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.route,
    title: 'Route',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.collector,
    title: 'Collector',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.amount,
    title: 'Amount',
    show: true,
  },
  {
    id: 7,
    label: labelMappings.alias,
    title: 'Alias',
    show: true,
  },
  {
    id: 8,
    label: labelMappings.remarks,
    title: 'Remarks',
    show: false,
  },
  {
    id: 9,
    label: labelMappings.distributor,
    title: 'Distributor',
    show: false,
  },
  {
    id: 10,
    label: labelMappings.dse,
    title: headerLabelConfig[USER_ROLE_TITLE.DSE],
    show: false,
  },
  {
    id: 11,
    label: labelMappings.userRole,
    title: 'User Role',
    show: false,
  },
];

const TableHeader = ({
  dataListLength, checkedListLength, primaryCheckboxHandler, tableConfig, permission,
}) => (
  <thead className="">
    <tr>
      {tableConfig.map(label => (label.show
        ? label.label === labelMappings.checkbox
          ? (dataListLength > 0) && permission && permission.update && (
          <th>
            <CheckBox
              checked={(dataListLength === checkedListLength)}
              onCheckBoxClick={e => primaryCheckboxHandler(e)}
            />
          </th>
          )
          : tableData(label.title, label.align)
        : null))}
    </tr>
  </thead>
);

const returnTableBody = (data, checkedList, handleIconClick, onSecondaryCheckBoxClick, section, permission) => {
  const table = {};
  table[labelMappings.checkbox] = ((
    checkedList && permission && permission.update && (
      <td
        onClick={e => e.stopPropagation()}
        key={`cash-${data.id}-id`}
      >

        <CheckBox
          checked={checkedList.includes(data.id)}
          onCheckBoxClick={() => onSecondaryCheckBoxClick(data.id)}
        />

      </td>)
  ));
  table[labelMappings.date] = (
    <td key={`cash-${data.id}-date`}>{normalPresentor(data.date)}</td>
  );
  table[labelMappings.outlet] = (
    <td key={`cash-${data.id}-outlet`}>{data.outlet.title}</td>
  );
  table[labelMappings.collector] = (
    <td key={`cash-${data.id}-collector`}>{data.collector.fullName}</td>
  );
  table[labelMappings.route] = (
    <td key={`cash-${data.id}-route`}>{data.outlet.route && data.outlet.route.title}</td>
  );
  table[labelMappings.amount] = (
    <td key={`cash-${data.id}-amount`}>{fixedFloatAndCommas(data.amount)}</td>
  );
  table[labelMappings.alias] = (
    <td key={`cash-${data.id}-alias`}>
      {section === 'pending' ? (
        <div className={`alias-td ${permission && permission.create ? 'alias' : 'disabled'}`}>
          <div className="add">
            <Icon iconName="plus" onClick={() => handleIconClick(EVENT_OPERATION.CREATE, data)} />
          </div>
          {data.paymentDetails.length !== 0 ? <div className="count" onClick={() => handleIconClick(EVENT_OPERATION.READ, data)}>{data.paymentDetails.length}</div> : ''}
        </div>
      )
        : (
          <div className="alias-td">
            { data.paymentDetails.length !== 0
        && <div className="count" onClick={() => handleIconClick(EVENT_OPERATION.READ, data)}>{data.paymentDetails.length}</div>}
          </div>
        )
      }
    </td>
  );
  table[labelMappings.remarks] = (
    <td key={`cash-${data.id}-remarks`}>{data.remarks}</td>
  );
  table[labelMappings.distributor] = (
    <td key={`cash-${data.id}-distributor`}>{data.distributor.title}</td>
  );
  table[labelMappings.dse] = (
    <td key={`cash-${data.id}-dse`}>{data.outlet.route && data.outlet.route.user.fullName}</td>
  );
  table[labelMappings.userRole] = (
    <td key={`cash-${data.id}-userRole`}>{data.collector.role.name}</td>
  );

  return table;
};

const TableBody = ({
  data, checkedList, handleIconClick, secondaryCheckboxHandler, tableConfig, section, permission,
}) => {
  // Expanding/Dragging and collapsing table body.
  // color based upon table body
  // tooltip if it is sorted.
  // active className
  const checked = checkedList ? checkedList.includes(data.id) : null;
  const tableBody = returnTableBody(data, checkedList, handleIconClick, secondaryCheckboxHandler, section, permission);

  return (
    <tr className={checked ? 'active' : ''}>
      {tableConfig.map(label => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};


TableHeader.propTypes = {
  checkedListLength: PropTypes.number,
  dataListLength: PropTypes.number,
  primaryCheckboxHandler: PropTypes.func.isRequired,
};

TableHeader.defaultProps = {
  checkedListLength: 0,
  dataListLength: 0,
};

export {
  TableHeader,
  TableBody,
  labelConfig,
};
