import styled from 'styled-components';

const StockLedgerStyled = styled.div`
 .form-select-input{
   margin-top:8px;
   margin-bottom:0;
   width:22%;
    /* width:242px; */
 label{
   display:none;
 }
    
 }

 table{
   border:0;
 }
`;

export default StockLedgerStyled;
