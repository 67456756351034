import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Pagination } from '../../../components';
import withLoading from '../../../utils/composition/withLoading';
import { dataProps, paginationProps } from '../../../data/enums/Component';
import BulkDelete from '../../components/BulkDelete';
import { STATEFUL_ENTITIES } from '../../../data/enums/GraphQL';
import { TableBody, TableHeader } from './tableConfig';

const propTypes = {
  data: dataProps.value,
  onIconClick: PropTypes.func,
  onPageChange: PropTypes.func,
  onViewClick: PropTypes.func.isRequired,
  pagination: paginationProps.value,
};

const defaultProps = {
  data: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
  onPageChange: () => null,
  onIconClick: () => null,
};

const View = ({ ...props }) => {
  const {
    data,
    onPageChange,
    onViewClick,
    pagination,
    onBulkDelete,
    permission,
    onIconClick,
  } = props;

  return (
    <Fragment>
      <BulkDelete
        data={data.list}
        type={STATEFUL_ENTITIES.Distributor}
        handler={onBulkDelete}
        render={(state, primaryCheckboxHandler, secondaryCheckboxHandler) => (
          <div className="table-wrap">
            <table>
              {
                  TableHeader({
                    dataListLength: data.list.length,
                    checkedListLength: state.checkedList.length,
                    primaryCheckboxHandler,
                    permission,
                  })
                }
              <tbody>
                {data.list.map(subD => (
                  TableBody({
                    data: subD,
                    checkedList: state.checkedList,
                    onViewClick,
                    onIconClick,
                    secondaryCheckboxHandler,
                    permission,
                  })
                ))
                }
              </tbody>
            </table>
          </div>)}
      />
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </Fragment>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const ViewWithLoading = withLoading(View);

export default ViewWithLoading;
