import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { query as subDQuery } from '../../configuration/subD/API';
import { TOGGLE_STATE } from '../../common/GqlAPI';
import { query as userQuery } from '../../configuration/user/userAccount/API';
import { query as routeQuery } from '../route/API';
import { LEAVE_REQUEST_SUMMARY_FRAGMENT } from '../leaveManagement/API';

const CALL_ROSTER_LIST_FRAGMENT = gql`
    fragment CallRosterFragment on UserCallRoster {
        userId
        data {
            date
            routeList {
                id
                routeId
                routeTitle
                outletCount
                visitFrequencyId
                creationIdentifier
            }
        }
    }
`;

const GET_CALL_ROSTER_LIST = gql`
    query callRoster($filter: FilterInput) {
        callRoster(filter: $filter) {
            count
            rows {
                ...CallRosterFragment
            }
            leaves {
                ...LeaveRequestSummaryFragment
            }
            holidays{
                weekEnds
                calendarHolidays {
                    id
                    startDate
                    endDate
                    title
                }
            }
        }
    }
    ${CALL_ROSTER_LIST_FRAGMENT}
    ${LEAVE_REQUEST_SUMMARY_FRAGMENT}
`;


const ROSTER_FRAGMENT = gql`
    fragment CallRosterFragment on CallRoster {
        date
        routes {
            id
            title
            retailOutlets
            line {
                id
            }
        }
        leveInfo
    }
`;


const CREATE_ROSTER = graphql(
  gql`
      mutation($input: CallRosterInput!) {
          createCallRoster(input: $input) {
              id
          }
      }
  `,
  {
    props: ({ mutate }) => ({
      create: variables => mutate({
        variables,
      }),
    }),
  },
);

const UPDATE_ROSTER = graphql(
  gql`
      mutation($id: Int!, $input: UpdateCallRosterInput!) {
          updateCallRoster(id: $id, input: $input) {
              id
          }
      }
  `,
  {
    props: ({ mutate }) => ({
      update: variables => mutate({
        variables,
      }),
    }),
  },
);

const REPLICATE_ROSTER = graphql(
  gql`
      mutation($input: CallRosterReplicationInput!) {
          replicateCallRoster(input: $input)
      }
  `,
  {
    props: ({ mutate }) => ({
      replicate: variables => mutate({
        variables,
      }),
    }),
  },
);

const SUB_D_HOLIDAY_FRAGMENT = gql`
  fragment SubDHolidayFragment on HolidayDetail {
      id
      startDate
      endDate
      title
      active
  }
`;

const GET_SUB_D_HOLIDAYS = gql`
    query holidayDetails($filter: FilterInput) {
        holidayDetails(filter: $filter) {
           weekEnds
           calendarHolidays {
               ...SubDHolidayFragment
           }
        }
    }
    ${SUB_D_HOLIDAY_FRAGMENT}
`;

const query = {
  getList: GET_CALL_ROSTER_LIST,
  getRouteList: routeQuery.getRouteTitleList,
  getRouteVisitFrequencyList: routeQuery.getRouteVisitFrequencyList,
  getUserList: userQuery.getUserList,
  getSubDList: subDQuery.getSubDSummaryList,
  getSubDHolidays: GET_SUB_D_HOLIDAYS,
};

const mutation = {
  replicate: REPLICATE_ROSTER,
  create: CREATE_ROSTER,
  update: UPDATE_ROSTER,
  toggleState: TOGGLE_STATE,
};

export { ROSTER_FRAGMENT, CREATE_ROSTER, TOGGLE_STATE, UPDATE_ROSTER, REPLICATE_ROSTER };

export { query, mutation };
