import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { DialogFormWrapper } from '../../common';
import {
  breadCrumbConfig, formConfig, title,
} from './config';
import * as queryService from '../../base/query.service';
import View from './View';
import { PanelStyled } from '../../common/configuration';
import PageHeader from '../../base/PageHeader';
import { refGenerator } from '../../../utils/refGenerator';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { getOffsetFromPagination } from '../../../utils/api';
import Form from './Form';
import { MESSAGE_EVENT_OPERATION } from '../../../data/enums/SuccessMessage';
import { STATEFUL_ENTITIES } from '../../../data/enums/GraphQL';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  getTradeList: PropTypes.func.isRequired,
  getChannelList: PropTypes.func.isRequired,
  getCategoryList: PropTypes.func.isRequired,
  createTrade: PropTypes.func.isRequired,
  createCategory: PropTypes.func.isRequired,
  createChannel: PropTypes.func.isRequired,
  updateTrade: PropTypes.func.isRequired,
  updateCategory: PropTypes.func.isRequired,
  updateChannel: PropTypes.func.isRequired,
  toggleState: PropTypes.func.isRequired,
};
const defaultProps = {
  serverResponseWaiting: false,
};
class Classification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      channel: {
        list: [],
        total: 0,
      },
      category: {
        list: [],
        total: 0,
      },
      tradeId: '',
      channelId: '',
      selectedId: '',
      trade: [],
      Channel: [],
      Category: [],
      dialog: {
        type: '',
        element: '',
        cardTitle: '',
      },
      createFormTrade: {
        id: '',
        title: '',
        Channels: {
          id: '',
          title: '',
          tradeId: '',
        },
        Categories: {
          id: '',
          title: '',
          channelId: '',
        },
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
      },
    };
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.tradeListDetails,
      props.displayAlert,
    );

    this.getData = () => {
      const { data } = this.state;
      return data;
    };
    this.formReference = refGenerator(['title']);
  }

  componentDidMount() {
    this.tradeListDetails();
  }

   tradeListDetails = () => {
     const {
       data, queryParameters,
     } = this.state;
     const { getTradeList } = this.props;
     const offset = getOffsetFromPagination(queryParameters.pagination);

     getTradeList({
       offset,
       limit: queryParameters.pagination.limit,
     }, {
       handleSuccess: (response) => {
         data.list = (response.data.trades && response.data.trades.rows) || [];
         data.total = (response.data.trades && response.data.trades.count) || 0;
         this.setState({ data, trade: (response.data.trades && response.data.trades.rows) || [] });
       },
       handleError: (error) => {
         this.onAPIRequestFailure(error);
       },
     });
   };

    channelListDetails = (tradeId) => {
      const { channel, queryParameters } = this.state;
      const { getChannelList } = this.props;
      const offset = getOffsetFromPagination(queryParameters.pagination);
      this.setState({ tradeId, selectedId: tradeId });
      getChannelList({
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters:
            [{
              column: 'trade_id',
              value: [tradeId ? tradeId.toString() : ''],

            }],
        },
      },
      {
        handleSuccess: (response) => {
          channel.list = (response.data.channels && response.data.channels.rows) || [];
          channel.total = (response.data.channels && response.data.channels.count) || 0;
          this.setState({ channel, Channel: (response.data.channels && response.data.channels.rows) || [] });
        },
        handleError: error => this.onAPIRequestFailure(error),
      });
    };


    categoryListDetails = (channelId) => {
      const { category, queryParameters } = this.state;
      const { getCategoryList } = this.props;
      const offset = getOffsetFromPagination(queryParameters.pagination);
      this.setState({ channelId, selectedId: channelId });
      getCategoryList({
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: [{
            column: 'channel_id',
            value: [channelId ? channelId.toString() : ''],

          }],
        },
      },
      {
        handleSuccess: (response) => {
          category.list = (response.data.categories && response.data.categories.rows) || [];
          category.total = (response.data.categories && response.data.categories.count) || 0;
          this.setState({
            category,
            Category:
            (response.data.categories && response.data.categories.rows),
          });
        },
        handleError: error => this.onAPIRequestFailure(error),
      });
    };

    onItemClick=(tradeId) => {
      this.channelListDetails(tradeId);
      this.categoryListDetails();
    }

  setQueryParameters = (queryParams, callBack = () => null) => this.setState(
    { queryParameters: queryParams },
    callBack,
  );

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

    onAPIRequestFailure = (error) => {
      const { displayAlert } = this.props;
      displayAlert(ALERT_TYPE.DANGER, error);
    };

  handleSearchInput = (text, cardTitle) => {
    const {
      trade, Channel, Category,
    } = this.state;
    const search = d => d.title.replace(/\s+/g, '').toLowerCase().search(text) !== -1;
    const filteredDatas = trade.filter(d => search(d));
    const filteredChannelDatas = Channel.filter(d => search(d));
    const filteredCategoryDatas = Category.filter(d => search(d));

    switch (cardTitle) {
      case 'Trade':
        this.setState({
          data:
      {
        list: filteredDatas,
      },
        });
        break;
      case 'Channel':
        this.setState({
          channel: {
            list: filteredChannelDatas,
          },
        });
        break;
      case 'Category':
        this.setState({
          category: {
            list: filteredCategoryDatas,
          },
        });
        break;
      default:
    }
  };

      handleIconClick = (type, element, cardTitle) => {
        const {
          tradeId, channelId, trade, Channel,
        } = this.state;
        const elementTitle = element.title;
        const filteredTrade = trade.filter(t => t.id === tradeId);
        const filteredChannel = Channel.filter(c => c.id === channelId);

        this.setState({
          dialog: {
            type,
            element,
            cardTitle,
          },
        });

        switch (cardTitle) {
          case 'Trade':
            this.setState({
              createFormTrade: { title: elementTitle },
            });
            break;
          case 'Channel':
            this.setState({
              createFormTrade: {
                title: element.Trade ? element.Trade.title : filteredTrade[0].title,
                Channels: { title: elementTitle },
              },
            });
            break;
          case 'Category':
            this.setState({
              createFormTrade: {
                title: element.Channels ? element.Channels.Trade.title : filteredTrade[0].title,
                Channels: {
                  title: element.Channels
                    ? element.Channels.title : filteredChannel[0].title,
                },
                Categories: { title: elementTitle },
              },
            });
            break;
          default:
        }
      };

        resetDialog = () => {
          this.setState({
            dialog: {
              type: '',
              element: '',
              cardTitle: '',
            },
          });
        };

        handleSubmit=() => {
          const {
            createTrade, createChannel, displayAlert, createCategory, updateTrade, updateChannel, updateCategory, toggleState,
          } = this.props;
          const {
            dialog, createFormTrade, tradeId, channelId,
          } = this.state;
          const { element, cardTitle, type } = dialog;
          if (type === 'UPDATE') {
            switch (cardTitle) {
              case 'Trade':
                updateTrade(
                  {
                    input: {
                      id: element.id,
                      title: createFormTrade.title,
                    },
                  }, {
                    handleSuccess: () => {
                      displayAlert(ALERT_TYPE.SUCCESS, `${cardTitle} ${MESSAGE_EVENT_OPERATION.UPDATE}`);
                      this.tradeListDetails();
                    },
                    handleError: (error) => {
                      this.onAPIRequestFailure(error);
                    },
                  },
                );
                break;
              case 'Channel':
                updateChannel(
                  {
                    input: {
                      id: element.id,
                      title: createFormTrade.Channels.title,
                    },
                  }, {
                    handleSuccess: () => {
                      displayAlert(ALERT_TYPE.SUCCESS, `${cardTitle} ${MESSAGE_EVENT_OPERATION.UPDATE}`);
                      this.channelListDetails(tradeId);
                    },
                    handleError: (error) => {
                      this.onAPIRequestFailure(error);
                    },
                  },
                );
                break;
              case 'Category':
                updateCategory(
                  {
                    id: element.id,
                    title: createFormTrade.Categories.title,
                    channelId,

                  }, {
                    handleSuccess: () => {
                      displayAlert(ALERT_TYPE.SUCCESS, `${cardTitle} ${MESSAGE_EVENT_OPERATION.UPDATE}`);
                      this.categoryListDetails(channelId);
                    },
                    handleError: (error) => {
                      this.onAPIRequestFailure(error);
                    },
                  },
                );
                break;
              default:
            }
          }
          if (type === 'DELETE') {
            switch (cardTitle) {
              case 'Trade':
                toggleState(
                  {
                    ids: [element.id],
                    type: STATEFUL_ENTITIES.TRADE,
                    active: false,
                  }, {
                    handleSuccess: () => {
                      displayAlert(ALERT_TYPE.SUCCESS, `${cardTitle} ${MESSAGE_EVENT_OPERATION.DELETE}`);
                      this.tradeListDetails();
                      if (tradeId === element.id) {
                        this.channelListDetails();
                        this.categoryListDetails();
                      }
                    },
                    handleError: (error) => {
                      this.onAPIRequestFailure(error);
                    },
                  },
                );
                break;
              case 'Channel':
                toggleState(
                  {
                    ids: [element.id],
                    type: STATEFUL_ENTITIES.CHANNEL,
                    active: false,
                  }, {
                    handleSuccess: () => {
                      displayAlert(ALERT_TYPE.SUCCESS, `${cardTitle} ${MESSAGE_EVENT_OPERATION.DELETE}`);
                      this.channelListDetails(tradeId);
                      if (channelId === element.id) {
                        this.categoryListDetails();
                      }
                    },
                    handleError: (error) => {
                      this.onAPIRequestFailure(error);
                    },
                  },
                );
                break;
              case 'Category':
                toggleState(
                  {
                    ids: [element.id],
                    type: STATEFUL_ENTITIES.CATEGORY,
                    active: false,
                  }, {
                    handleSuccess: () => {
                      displayAlert(ALERT_TYPE.SUCCESS, `${cardTitle} ${MESSAGE_EVENT_OPERATION.DELETE}`);
                      this.categoryListDetails(channelId);
                    },
                    handleError: (error) => {
                      this.onAPIRequestFailure(error);
                    },
                  },
                );
                break;
              default:
            }
          }
          if (type === 'CREATE') {
            switch (cardTitle) {
              case 'Channel':
                createChannel(
                  {
                    input: {
                      title: createFormTrade.Channels.title,
                      tradeId,
                    },
                  }, {
                    handleSuccess: () => {
                      displayAlert(ALERT_TYPE.SUCCESS, `${cardTitle} ${MESSAGE_EVENT_OPERATION.CREATE}`);
                      this.channelListDetails(tradeId);
                    },
                    handleError: (error) => {
                      this.onAPIRequestFailure(error);
                    },
                  },
                );
                break;
              case 'Category':
                createCategory(
                  {
                    title: createFormTrade.Categories.title,
                    channelId,
                  }, {
                    handleSuccess: () => {
                      displayAlert(ALERT_TYPE.SUCCESS, `${cardTitle} ${MESSAGE_EVENT_OPERATION.CREATE}`);
                      this.categoryListDetails(channelId);
                    },
                    handleError: (error) => {
                      this.onAPIRequestFailure(error);
                    },
                  },
                );
                break;
              case 'Trade':
                createTrade(
                  {
                    input: { title: createFormTrade.title },
                  }, {
                    handleSuccess: () => {
                      displayAlert(ALERT_TYPE.SUCCESS, `${cardTitle} ${MESSAGE_EVENT_OPERATION.CREATE}`);
                      this.tradeListDetails();
                    },
                    handleError: (error) => {
                      this.onAPIRequestFailure(error);
                    },
                  },
                );
                break;
              default:
            }
          }
        }


  handleInputChange = (event, firstParam = '', paramList = []) => {
    const { createFormTrade } = this.state;
    if (firstParam) {
      if (paramList.length > 1) {
        paramList.reduce((acc, value, index, list) => {
          if (index === list.length - 1) {
            return acc[value] = event.formattedValue;
          }
          return acc[value];
        }, createFormTrade);
      } else {
        createFormTrade[firstParam][event.target.name] = event.formattedValue;
      }
    } else {
      createFormTrade[event.target.name] = event.formattedValue;
    }
    this.setState({ createFormTrade });
  };

  render() {
    const {
      data, channel, tradeId, channelId, dialog, category, selectedId, createFormTrade,
    } = this.state;
    const { serverResponseWaiting } = this.props;
    const { type, cardTitle, element } = dialog;
    return (
      <Fragment>
        <div>
          {type && (
          <DialogFormWrapper
            formTitle={cardTitle}
            type={type}
            dialogElement={element}
            formConfig={formConfig[type]}
            onDialogCancel={this.resetDialog}
            onDialogSubmit={this.handleSubmit}
            refsObj={this.formReference}
            renderDialog={
                (
                  {
                    enableErrorDisplay,
                    refsObj,
                  },
                ) => (
                  <Fragment>
                    {
                      (type !== EVENT_OPERATION.DELETE) ? (
                        <Form
                          show
                          type={type}
                          refsObj={refsObj}
                          data={createFormTrade}
                          enableErrorDisplay={enableErrorDisplay}
                          handleInputChange={this.handleInputChange}
                          title={cardTitle}
                        />
                      ) : (
                        <div>
                       Are you sure you want to delete this record?
                        </div>
                      )
                    }
                  </Fragment>
                )}
          />
          )}

          <Fragment>

            <div className="section-header">
              <PanelStyled>
                <PageHeader
                  breadCrumb={breadCrumbConfig}
                  config={{
                    title,
                    create: false,
                    download: false,
                    filter: false,
                    search: false,
                  }}
                  controlDisplay={this.controlDisplay}
                />
              </PanelStyled>
            </div>
            <div className="section-content">
              <View
                data={data}
                onItemClick={this.onItemClick}
                onChannelClick={this.categoryListDetails}
                channel={channel}
                category={category}
                loading={serverResponseWaiting}
                handleSearchInput={this.handleSearchInput}
                tradeId={tradeId}
                channelId={channelId}
                handleIconClick={this.handleIconClick}
                selectedId={selectedId}
                cardTitle={cardTitle}
              />
            </div>

          </Fragment>
        </div>
      </Fragment>
    );
  }
}

Classification.propTypes = propTypes;

Classification.defaultProps = defaultProps;

export default withAlert()(Classification);
