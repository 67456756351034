import React from 'react';
import PropTypes from 'prop-types';
import { dataProps, paginationProps } from '../../../data/enums/Component';
import withErrorAndLoadingScreen from '../../../utils/composition/withLoading';
import {
  Pagination,
} from '../../../components';
import SKUStyled from './SKUStyled';
import { TableBody, TableHeader } from './tableConfig';

const propTypes = {
  data: dataProps.value,
  pagination: paginationProps.value,
  onPageChange: PropTypes.func,
  onCheckBoxClick: PropTypes.func,
  onIconClick: PropTypes.func,
  checkedList: PropTypes.array,
  breadCrumb: PropTypes.array,
  onButtonClick: PropTypes.func,
  date: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }).isRequired,
  onFilterChange: PropTypes.func.isRequired,
};

const defaultProps = {
  checkedList: [],
  breadCrumb: [],
  onIconClick: () => null,
  onPageChange: () => null,
  onButtonClick: () => null,
  onCheckBoxClick: () => null,
  data: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
};

const View = ({ ...props }) => {
  const {
    data,
    onButtonClick,
    onPageChange,
    onIconClick,
    pagination,
    permission,
  } = props;
  return (
    <SKUStyled>
      <div className="table-wrap sku">
        <table>
          {
            TableHeader()
          }
          <tbody>
            {data.list.map(user => (
              TableBody({
                data: user,
                onButtonClick,
                permission,
                onIconClick,
              })
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </SKUStyled>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withErrorAndLoadingScreen(View);

export default LoginViewWithErrorAndLoading;
