import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../../data/enums/SuccessMessage';
import { PROMOTION_AREAS, SKU_CATALOG_LEVEL } from '../../../../data/enums';
import { PROMOTION } from '../../../../data/enums/Route';
import { breadCrumbConfig } from '../config';

const title = 'Promotion';

const breadCrumb = [
  ...breadCrumbConfig,

  {
    id: 1,
    title: 'Promotion',
    path: `/${PROMOTION}`,
    active: true,
  },
];


const PROMOTION_CRITERIA_OPERATION = [
  {
    id: 1,
    title: '> GREATER_THAN',
    value: 'GREATER_THAN',
  },
  {
    id: 2,
    title: '>= GREATER_THAN_EQUALS',
    value: 'GREATER_THAN_EQUALS',
  },
  {
    id: 3,
    title: '< LESS_THAN',
    value: 'LESS_THAN',
  },
  {
    id: 4,
    title: '<= LESS_THAN_EQUALS',
    value: 'LESS_THAN_EQUALS',
  },
  {
    id: 5,
    title: '= EQUALS',
    value: 'EQUALS',
  },
];

const PROMOTION_CRITERIA_DIMENSION = [
  {
    id: 1,
    title: 'Quantity',
    value: 'QUANTITY',
  },
  {
    id: 2,
    title: 'Amount',
    value: 'AMOUNT',
  },
];

const PROMOTION_TYPE = [
  {
    id: 1,
    title: 'Normal',
    value: 'NORMAL',
  },
  {
    id: 2,
    title: 'Current Bill',
    value: 'CURRENT_BILL',
  },
  {
    id: 3,
    title: 'Next Bill',
    value: 'NEXT_BILL',
  },
  {
    id: 4,
    title: 'Direct Disbursement',
    value: 'DIRECT_DISBURSEMENT',
  },
  {
    id: 5,
    title: 'Top Up',
    value: 'TOP_UP',
  },
];

const PROMOTION_CRITERIA = {
  DIMENSION: [
    {
      id: 'AMOUNT',
      value: 'AMOUNT',
    },
    {
      id: 'QUANTITY',
      value: 'QUANTITY',
    },
  ],
  OPERATOR: [
    {
      id: 'GREATER_THAN',
      value: 'GREATER_THAN',
    },
    {
      id: 'LESS_THAN',
      value: 'LESS_THAN',
    },
    {
      id: 'GREATER_THAN_EQUALS',
      value: 'GREATER_THAN_EQUALS',
    },
    {
      id: 'LESS_THAN_EQUALS',
      value: 'LESS_THAN_EQUALS',
    },
    {
      id: 'EQUALS',
      value: 'EQUALS',
    },
  ],
};

const PROMOTION_DISBURSEMENT = {
  TYPE: [
    {
      id: 'EQUALS',
      value: 'EQUALS',
    },
    {
      id: 'GREATER_THAN_EQUALS',
      value: 'GREATER_THAN_EQUALS',
    },
    {
      id: 'LESS_THAN_EQUALS',
      value: 'LESS_THAN_EQUALS',
    },
  ],
};

const PROMOTION_RELATED = {
  TYPE: [
    {
      id: 'SBD',
      value: 'SBD',
    },
    {
      id: 'FOCUSED_SKU',
      value: 'FOCUSED_SKU',
    },
  ],
};

const CUSTOM_SKU_GROUPS = {
  HEADER: 'customGroups',
  SKU: 'customGroupSKU',
};

const form = {
  mapper: element => ({
    id: element.id || '',
    name: element.name || '',
    displayName: element.displayName || '',
    UserGroup: {
      id: element.UserGroup ? element.UserGroup.id : '',
    },
  }),
  validationField: {
    info: ['title'],
    disbursement: ['value'],
    scope: ['relatedId'],
  },
  validationRequired: true,
};

const crudRequest = {
  [EVENT_OPERATION.CREATE]: {
    objectMapper: data => ({
      name: data.name,
      displayName: data.displayName,
      userGrpId: data.UserGroup.id,
    }),
    responseName: 'createRole',
    message: `${title} ${MESSAGE_EVENT_OPERATION.CREATE}`,
  },
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: data => stateToRequestProcessor(data),
    responseName: 'updateRole',
    message: `${title} ${MESSAGE_EVENT_OPERATION.UPDATE}`,
  },
  [EVENT_OPERATION.DELETE]: {
    objectMapper: data => ({
      id: data.id,
      title: data.title,
    }),
    responseName: 'deleteSKU',
    message: `${title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: data => ({
      id: data.id,
      title: data.title,
    }),
    responseName: 'updateSKU',
    message: `${title} ${MESSAGE_EVENT_OPERATION.BULK_DELETE}`,
  },
  [EVENT_OPERATION.UPDATE_STATUS]: {
    objectMapper: data => ({
      ids: [data.id],
      type: title,
      active: !data.active,
    }),
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.UPDATE}`,
  },
};

const responseToStateAreaMapper = (promotionAreas) => {
  const townList = promotionAreas.filter(el => el.domain === PROMOTION_AREAS.TOWN);
  const subDList = promotionAreas.filter(el => el.domain === PROMOTION_AREAS.SUB_D);

  const townIdList = (townList && townList.map(town => town.ref_id)) || [];
  const subDIdList = (subDList && subDList.map(subD => subD.ref_id)) || [];

  return {
    townIdList,
    subDIdList,
  };
};

const responseToStateProcessor = (data, state) => {
  const {
    info,
    scope,
    criteria,
    disbursement,
    ...remKeys
  } = state;

  info.promotionId = data.id;
  info.startDate = data.startDate;
  info.endDate = data.endDate;
  info.title = data.title;
  info.active = data.active;
  info.shortDescription = data.shortDescription;

  // todo
  // scope.catalogInfo = [data.catalogInfo];

  const channelCategoryInfo = data.channelCategoryInfo.length > 0
    ? data.channelCategoryInfo : [
      {
        channelId: null,
        categoryIds: [],
      },
    ];

  scope.channelCategoryInfo = channelCategoryInfo;
  scope.categoryIds = data.categoryIds || [];
  scope.relatedType = data.relatedType;
  scope.relatedId = data.relatedId;
  scope.customGroupStatus = data.customGroupStatus;
  scope.customGroupDetails = data.customGroupDetails;
  scope.catalogGroupId = [];

  scope.promotionAreas = responseToStateAreaMapper(data.promotionAreas);

  if (!data.catalogInfo.catalogId) {
    scope.catalogInfo = [{
      catalogId: SKU_CATALOG_LEVEL,
      applicableSkus: data.applicableSkus || [],
      catalogDetailIds: [],
    }];
  } else {
    scope.catalogInfo = [{
      catalogId: data.catalogInfo.catalogId || null,
      catalogDetailIds: data.catalogInfo.catalogDetailIds || [],
      applicableSkus: [],
    }];
  }

  // scope.categoryList = data.categoryIds;
  // scope.channelList = data.channelIds || [];
  // scope.applicableSkus = data.applicableSkus;
  // scope.catalog[0].catalog_level = 6;
  // scope.catalog[0].list = data.applicableSkus;

  criteria.dimension = data.criteria.dimension;
  criteria.skuCount = data.criteria.skuCount;
  criteria.operation = data.criteria.operation;
  criteria.allowMultiple = data.criteria.allowMultiple;
  criteria.type = data.type;

  disbursement.dimension = data.disbursement.dimension;
  disbursement.value = data.disbursement.value;
  disbursement.allowMultiple = data.disbursement.allowMultiple;
  disbursement.freeSKUs = data.disbursement.freeSKUs.map(freeSKU => freeSKU.skuId); // ? [data.freeSkuId] : [] || [];

  return {
    scope,
    info,
    criteria,
    disbursement,
    ...remKeys,
  };
};


/** promotionAreas: {
  townId: null,
    subDId: null,
}, */

const stateToRequestAreaMapper = (promArea) => {
  const promotionAreas = [];

  if (promArea.townIdList.length > 0) {
    promotionAreas.push({
      ref_ids: promArea.townIdList,
      domain: PROMOTION_AREAS.TOWN,
    });
  }

  if (promArea.subDIdList.length > 0) {
    promotionAreas.push({
      ref_ids: promArea.subDIdList,
      domain: PROMOTION_AREAS.SUB_D,
    });
  }

  return promotionAreas;
};

const stateToRequestProcessor = (state) => {
  const {
    info, scope, criteria, disbursement,
  } = state;

  const promotionAreas = stateToRequestAreaMapper(scope.promotionAreas);

  const object = {
    input: {
      title: info.title,
      type: criteria.type,
      startDate: info.startDate,
      endDate: info.endDate,
      active: info.active,
      shortDescription: info.shortDescription,
      promotionAreas,
      areaStatus: !!promotionAreas.length,
      // catalogInfo: scope.catalog[0],
      // categoryIds: scope.categoryList,
      // applicableSkus: scope.applicableSkus,
      channelCategoryInfo: scope.channelCategoryInfo.map(el => el),
      customGroupStatus: scope.customGroupStatus,
      categoryIds: scope.categoryIds,
      criteria: {
        dimension: criteria.dimension,
        skuCount: criteria.skuCount,
        allowMultiple: criteria.allowMultiple,
        operation: {
          max: {
            value: criteria.operation.max.value,
            operator: criteria.operation.max.operator,
          },
          min: {
            value: criteria.operation.max.value,
            operator: criteria.operation.max.operator,
          },
        },
      },
      disbursement: {
        value: disbursement.value,
        // freeSkuId: disbursement.freeSkuId[0] || 0,
        freeSKUs: disbursement.freeSKUs.map(skuId => ({ skuId })),
        allowMultiple: disbursement.allowMultiple,
        dimension: disbursement.dimension,
      },
    },
  };

  if (scope.customGroupStatus) {
    object.input.customGroupDetails = scope.customGroupDetails;
  } else if (scope.catalogInfo[0].catalogId === SKU_CATALOG_LEVEL) {
    object.input.applicableSkus = scope.catalogInfo[0].applicableSkus;
  } else {
    object.input.catalogInfo = {
      catalogId: scope.catalogInfo[0].catalogId,
      catalogDetailIds: scope.catalogInfo[0].catalogDetailIds || [],
    };
  }

  if (info.promotionId && info.promotionId !== 0) {
    object.id = info.promotionId;
  }

  if (scope.relatedType) {
    object.input.relatedType = scope.relatedType;
    object.input.relatedId = scope.relatedId;
  }

  return object;
};

export {
  crudRequest, breadCrumb as breadCrumbConfig, form, title,
  stateToRequestProcessor, responseToStateProcessor,
  PROMOTION_CRITERIA, PROMOTION_DISBURSEMENT, PROMOTION_RELATED,
  PROMOTION_CRITERIA_DIMENSION, PROMOTION_CRITERIA_OPERATION, PROMOTION_AREAS, PROMOTION_TYPE,
  CUSTOM_SKU_GROUPS,
};
