import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Pagination } from '../../../components';
import BulkDelete from '../../components/BulkDelete';
import { TableBody, TableHeader } from './tableConfig';
import { STATEFUL_ENTITIES } from '../../../data/enums/GraphQL';
import { dataProps, paginationProps } from '../../../data/enums/Component';
import withErrorAndLoadingScreen from '../../../utils/composition/withLoading';


const propTypes = {
  data: dataProps.value,
  pagination: paginationProps.value,
  onPageChange: PropTypes.func,
  onCheckBoxClick: PropTypes.func,
  onIconClick: PropTypes.func.isRequired,
  onBulkDelete: PropTypes.func.isRequired,
};

const defaultProps = {
  onPageChange: () => null,
  onCheckBoxClick: () => null,
  data: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
};

const SbdView = ({ ...props }) => {
  const {
    data,
    onPageChange,
    pagination,
    onIconClick,
    onBulkDelete,
    permission,

  } = props;

  return (
    <Fragment>
      <BulkDelete
        data={data.list}
        type={STATEFUL_ENTITIES.SBD}
        handler={onBulkDelete}
        render={(state, primaryCheckboxHandler, secondaryCheckboxHandler) => (
          <div className="table-wrap prn">
            <table>
              {
                  TableHeader({
                    dataListLength: data.list.length,
                    checkedListLength: state.checkedList.length,
                    primaryCheckboxHandler,
                    permission,
                  })
                }

              <tbody>
                {data.list.map(srn => (
                  TableBody({
                    data: srn,
                    checkedList: state.checkedList,
                    onIconClick,
                    secondaryCheckboxHandler,
                    permission,
                  })
                ))}
              </tbody>
            </table>

          </div>
        )}
      />
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </Fragment>
  );
};

SbdView.propTypes = propTypes;

SbdView.defaultProps = defaultProps;

const SbdViewWithErrorAndLoading = withErrorAndLoadingScreen(SbdView);

export default SbdViewWithErrorAndLoading;
