import React, { Component } from 'react'
import ARCmap from '../views/ARCmap/ARCmap'

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstOutlets: [],
            lastOutlets: [],
            loadingStatus: ''
        };
    }

    //check if needed
    setInitialOutlets = (initialOutlets) => {
        this.setState(prevState => ({
            firstOutlets: [...prevState.firstOutlets, initialOutlets]
        }))
    }

    setEndOutlets = (endOutlets) => {
        this.setState(prevState => ({
            lastOutlets: [...prevState.lastOutlets, endOutlets]
        }))
    }

    loading = (loading) => {
        this.setState({ loadingStatus: loading });
    };

    render() {
        return (
            <>
                <ARCmap
                    setInitialOutlets={this.setInitialOutlets}
                    setEndOutlets={this.setEndOutlets}
                    loading={this.loading}
                    getOutlets={this.props.getOutlets}
                    getRoutes={this.props.getRoutes}
                    getTownList={this.props.getTownList}
                    getRoutesOutlets={this.props.getRoutesOutlets}
                    saveRoutes={this.props.saveRoutes}
                    getRosiaRoutes={this.props.getRosiaRoutes}
                />
            </>

        )
    }
}
export default Main