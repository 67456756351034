import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import GrnView from './View';
import GrnForm from '../../common/CSVUploadForm';
import { DialogFormWrapper } from '../../common';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import {
  title,
  formConfig,
  filterConfig,
  breadCrumbConfig,
  crudSuccess as crudRequestConfig,
} from './config';
import PageHeader from '../../base/PageHeader';
import * as queryService from '../../base/query.service';
import { PanelStyled } from '../../common/configuration';
import Confirmation from '../../common/DialogConfirmation';
import { getPermissionForGrn } from '../../base/permission';
import withAlert from '../../../utils/composition/withAlert';
import { fetchAndSetSubDList } from '../../../data/services/subD';
import { isConfirmationType, isError } from '../../common/HelperFunctions';
import { GRN_CREATE, GRN_DETAILS } from '../../../data/enums/Route';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { crudResponseProcessor, handleFormSubmit } from '../../../utils/crudResponseProcessor';
import { PROGRAM } from '../../../data/enums/Permission';

const propTypes = {
  upload: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  getGrnList: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  toggleSate: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class Grn extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      dialog: {
        type: '',
        element: '',
      },
      display: {
        searchBox: false,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
      },
    };
    this.permission = getPermissionForGrn();

    const { downloadReport, displayAlert } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadReport,
      displayAlert,
    );
    this.getData = () => {
      const { data } = this.state;
      return data;
    };

    const serverCall = {
      [EVENT_OPERATION.UPLOAD]: props.upload,
      /* [EVENT_OPERATION.DELETE]: props.toggleState, */
      [EVENT_OPERATION.BULK_DELETE]: props.toggleState,
    };

    this.onCRUDSuccess = crudResponseProcessor((data, type) => {
      const { displayAlert } = this.props;
      let message = crudRequestConfig[type].message;
      if (type === EVENT_OPERATION.UPLOAD) {
        message = data;
      } else {
        this.setState(data);
      }
      displayAlert(ALERT_TYPE.SUCCESS, message);
    }, this.getData,
    ['title']);
    this.onFormSubmit = handleFormSubmit(
      this.onCRUDSuccess, this.onAPIRequestFailure, crudRequestConfig, serverCall,
    );
  }

  componentDidMount() {
    this.loadTableData();

    // TODO, need to update in up comming sprint
    fetchAndSetSubDList();
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  loadTableData = () => {
    const { data, queryParameters } = this.state;
    const { getGrnList } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    // all urls for search, filter and pagination
    getGrnList({
      offset,
      limit: queryParameters.pagination.limit,
      filter: {
        filters: apiFilterProcessor(queryParameters.filter),
        dateRange: queryParameters.date,
        queryString: queryParameters.search,
      },
    }, {
      handleSuccess: (response) => {
        const { goodsReceivedNotes = {} } = response.data;
        data.list = goodsReceivedNotes ? goodsReceivedNotes.rows : [];
        data.total = goodsReceivedNotes ? goodsReceivedNotes.count : 0;
        if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
        this.setState(data);
      },
      handleError: error => this.onAPIRequestFailure(error),
    });
  };

  setQueryParameters = (queryParams, callBack = () => null) => this.setState(
    { queryParameters: queryParams },
    callBack,
  );

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  handleIconClick = (type, element, attribute = 'id') => {
    const { history } = this.props;

    switch (type) {
      case EVENT_OPERATION.CREATE:
        history.push(`/${GRN_CREATE}`);
        break;
      case EVENT_OPERATION.UPDATE:
        // eslint-disable-next-line no-case-declarations
        const id = element ? element.id : '';
        history.push(`/${GRN_DETAILS}/${id}`);
        break;
      case EVENT_OPERATION.UPLOAD:
        this.updateDialog(type, element);
        break;
      default:
        break;
    }
  }

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  updateDialog = (type = '', element = {}) => {
    // fetch all the necessary data for dialog here;
    this.setState({
      dialog: {
        type: typeof type === 'string' ? type : '',
        element,
      },
    });
  };

  render() {
    const {
      data,
      dialog,
      display,
      queryParameters,
    } = this.state;
    const { type } = dialog;
    const {
      serverResponseWaiting,
    } = this.props;
    return (
      <Fragment>
          <div className="section-header">
            {type && (
              <DialogFormWrapper
                onDialogSubmit={this.onFormSubmit}
                formConfig={formConfig[type]}
                dialogElement={dialog.element}
                onDialogCancel={this.updateDialog}
                type={type}
                renderDialog={
                ({
                  dialogData, enableErrorDisplay, handleFileUpload, refsObj,
                }) => (
                  <Fragment>
                    {
                      type === EVENT_OPERATION.UPLOAD && (
                        <GrnForm
                          show
                          data={dialogData}
                          loading={serverResponseWaiting}
                          handleFileUpload={handleFileUpload}
                          enableErrorDisplay={enableErrorDisplay}
                          refsObj={refsObj}
                        />
                      )
                    }
                    {
                      isConfirmationType(type)
                      && Confirmation(type)
                    }
                  </Fragment>
                )}
              />
            )}
            <PanelStyled>
              <PageHeader
                display={display}
                breadCrumb={breadCrumbConfig}
                config={{
                  title,
                  create: this.permission.create,
                  download: true,
                  filter: true,
                  upload: false,
                  date: true,
                  search: true,
                }}
                downloadConfig={{
                  domain: PROGRAM.GRN,
                }}
                filter={{
                  date: queryParameters.date,
                  menuList: filterConfig.menu,
                  onFilterChange: this.basePaginationService.handleFilterChange,
                }}
                queryParameters={queryParameters}
                controlDisplay={this.controlDisplay}
                handleCreateClick={this.handleIconClick}
                resetFilter={this.basePaginationService.resetFilter}
                clearSearchText={this.basePaginationService.clearSearchText}
                handleSearchChange={this.basePaginationService.handleSearchInputChange}
                handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
                handleDownloadClick={this.basePaginationService.handleDownloadClick}
              />
            </PanelStyled>
          </div>
          <div className="section-content">
            <GrnView
              data={data}
              pagination={queryParameters.pagination}
              loading={serverResponseWaiting}
              onIconClick={this.handleIconClick}
              onPageChange={this.basePaginationService.onPageSelect}
            />
          </div>
      </Fragment>

    );
  }
}

Grn.propTypes = propTypes;

Grn.defaultProps = defaultProps;

export default withAlert()(Grn);
