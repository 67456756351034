import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import { Thumbnail } from '../../../../../components/ImageViewer';
import withLoading from '../../../../../utils/composition/withLoading';
import { EMPTY, INVALID } from '../../../../../data/enums/ErrorMessage';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import {
  CustomSelect, Form, Input, SingleDatePicker,
} from '../../../../../components';

const propTypes = {
  uploadCallBack: PropTypes.func,
  dropDownCallBack: PropTypes.func,
  handleInputChange: PropTypes.func,
  update: PropTypes.instanceOf(Object),
  handleDropDownChange: PropTypes.func,
  handleMultipleUpload: PropTypes.func,
  onUploadRemove: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  data: PropTypes.instanceOf(Object),
  skuList: PropTypes.instanceOf(Array),
  refsObj: PropTypes.instanceOf(Object),
  skuBatchList: PropTypes.instanceOf(Array),
};

const defaultProps = {
  data: {},
  refsObj: {
    title: '',
  },
  skuList: [],
  skuBatchList: [],
  enableErrorDisplay: true,
  uploadCallBack: () => null,
  onUploadRemove: () => null,
  dropDownCallBack: () => null,
  handleDropDownChange: () => null,
  handleMultipleUpload: () => null,
  update: {
    type: EVENT_OPERATION.CREATE,
    status: false,
  },
  /**
   * agruments:
   * first param: input event (required),
   * second param: data's first depth targeted key,
   * third param:
   *  only if object depth greater than one,
   *  should contain list of object keys in accordance of depth upto end i.e target key
   */
  handleInputChange: () => null,

};

const GRNForm = ({ ...props }) => {
  const {
    data,
    update,
    refsObj,
    skuList,
    getState,
    updateState,
    skuBatchList,
    uploadCallBack,
    onUploadRemove,
    dropDownCallBack,
    handleInputChange,
    enableErrorDisplay,
    handleDropDownChange,
    handleMultipleUpload,
  } = props;

  return (
    <Form>
      <div className={update.type === EVENT_OPERATION.APPROVE ? 'scrollable-body-vertical' : ' '}>
        <Row>
          <Col md={12}>
            <CustomSelect
              enableValidation
              disabled={update.type === EVENT_OPERATION.APPROVE}
              options={skuList}
              labelContent="SKU"
              className="custom-select"
              placeholder="Select SKU"
              getOptionValue={({ id }) => id}
              getOptionLabel={({ title }) => title}
              ref={ref => refsObj.skuId = ref}
              enableErrorDisplay={enableErrorDisplay}
              onChange={event => handleDropDownChange(event.id, ['skuId'], dropDownCallBack)}
              value={skuList.filter(({ id }) => id === data.skuId)}
            />
          </Col>
          <Col md={4} className="mt-24">
            <CustomSelect
              disabled={!data.skuId || update.type === EVENT_OPERATION.APPROVE}
              enableValidation
              options={skuBatchList}
              labelContent="Batch"
              className="custom-select"
              placeholder="Select Batch"
              getOptionValue={({ id }) => id}
              getOptionLabel={({ batchDetails }) => (batchDetails.batchNumber ? batchDetails.batchNumber.replace(/_/g, ' ') : batchDetails.batchNumber)}
              ref={ref => refsObj.skuBatchId = ref}
              enableErrorDisplay={enableErrorDisplay}
              defaultValue={skuBatchList[0]}
              onChange={event => handleDropDownChange(event.id, ['skuBatchId'], dropDownCallBack)}
              value={skuBatchList.filter(({ id }) => id === data.skuBatchId)}
            />
          </Col>
          <Col md={4}>
            <div className="single-date-picker">
              <label>Manufacturing (MFG) Date</label>
              <SingleDatePicker
                name="manufactureDate"
                date={data.manufactureDate}
                disabled={update.type === EVENT_OPERATION.APPROVE}
                onChange={(name, date) => (
                  handleInputChange({
                    target: {
                      name: 'manufactureDate',
                    },
                    formattedValue: date,
                  })
                )}
                maxDate={data.expiryDate}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="single-date-picker">
              <label>Expiry (EXP) Date</label>
              <SingleDatePicker
                name="expiryDate"
                date={data.expiryDate}
                disabled={update.type === EVENT_OPERATION.APPROVE}
                onChange={(name, date) => (
                  handleInputChange({
                    target: {
                      name: 'expiryDate',
                    },
                    formattedValue: date,
                  })
                )}
                minDate={data.manufactureDate}
              />
            </div>
          </Col>
          <Col md={4} className="mt-24">
            <Input
              name="quantity"
              type="number"
              rule="isInt"
              labelContent="Quantity (QTY)"
              enableValidation
              value={data.quantity}
              enableErrorDisplay={enableErrorDisplay}
              ref={ref => refsObj.quantity = ref}
              onChange={event => handleInputChange(event)}
              disabled={update.type === EVENT_OPERATION.APPROVE}
              argument={{
                min: 1,
                allow_leading_zeroes: false,
              }}
              errorMessage={data.quantity ? INVALID : EMPTY}
            />
          </Col>
          <Col md={4} className="mt-24">
            <Input
              name="rate"
              type="number"
              rule="isFloat"
              labelContent="Rate"
              enableValidation
              argument={{ min: 1 }}
              value={data.priceDetails.rate}
              enableErrorDisplay={enableErrorDisplay}
              ref={ref => refsObj.rate = ref}
              disabled={update.type === EVENT_OPERATION.APPROVE}
              onChange={event => handleInputChange(event, 'priceDetails')}
              errorMessage={data.priceDetails.rate ? INVALID : EMPTY}
            />
          </Col>
          <Col md={4} className="mt-24">
            <Input
              name="discount"
              type="number"
              labelContent="Discount"
              enableValidation
              rule="isFloat"
              argument={{
                min: 0,
                max: data.priceDetails.rate * data.quantity,
              }}
              value={data.priceDetails.discount}
              enableErrorDisplay={enableErrorDisplay}
              ref={ref => refsObj.discount = ref}
              disabled={update.type === EVENT_OPERATION.APPROVE}
              onChange={event => handleInputChange(event, 'priceDetails')}
              errorMessage={data.priceDetails.discount ? INVALID : EMPTY}
            />
          </Col>
          {
            update.type === EVENT_OPERATION.APPROVE && (
              <Col md={12}>
                <Row>
                  <Col md={4}>
                    <Input
                      rule="isInt"
                      type="number"
                      name="shortages"
                      labelContent="Shortage"
                      value={data.shortages}
                      enableErrorDisplay={enableErrorDisplay}
                      enableValidation
                      onChange={event => handleInputChange(event)}
                      argument={{
                        min: 0,
                        allow_leading_zeroes: false,
                      }}
                      errorMessage={INVALID}
                    />
                  </Col>
                </Row>
              </Col>
            )
          }
          {
            update.type === EVENT_OPERATION.APPROVE && (
              <Col md={4}>
                <Input
                  rule="isInt"
                  name="damages"
                  type="number"
                  labelContent="Damages"
                  value={data.damages}
                  enableErrorDisplay={enableErrorDisplay}
                  enableValidation
                  onChange={event => handleInputChange(event)}
                  argument={{
                    min: 0,
                    allow_leading_zeroes: false,
                  }}
                  errorMessage={INVALID}
                />
              </Col>
            )
          }

          {
            data.damageImages.map(item => (
              <Col md={1}>
                <div className="attachment-item">
                  <span
                    className="del-icon"
                    onClick={() => onUploadRemove(getState, updateState, item)}
                  >
                        x
                  </span>
                  <Thumbnail image={item.thumbnail} title="dummy" className="" />
                </div>
              </Col>
            ))
          }

          {
            /* (update.type === EVENT_OPERATION.APPROVE && data.damages) ? (
              <Col md={1}>
                <div className="custom-file-upload-wrap">
                  <MultipleFileInput
                    multiple
                    name="imageUpload"
                    className="input-file-upload"
                    accept="image/!*"
                    enableErrorDisplay={enableErrorDisplay}
                    onChange={e => handleMultipleUpload(e, uploadCallBack)}
                    file={data.damageImages}
                  />
                  <span className="custom-file-upload">
                    <Icon iconName="picture" />
                  </span>
                </div>
              </Col>
            ) : '' */
          }
        </Row>
      </div>
    </Form>
  );
};

GRNForm.propTypes = propTypes;

GRNForm.defaultProps = defaultProps;

const GRNFormWithErrorAndLoading = withLoading(GRNForm);

export default GRNFormWithErrorAndLoading;
