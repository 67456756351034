import {
  compose,
} from 'recompose';
import { query } from './API';
import SKUDistribution from './SKUDistribution';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';

const ComposedSKUDistribution = compose(
  withRequestTracker({
    query,
  }),
)(SKUDistribution);

export default ComposedSKUDistribution;
