import gql from 'graphql-tag';

const PAYMENTS_FRAGMENT = gql`
fragment PaymentsFragment on Payment {
           id
          amount
          remarks
          distributor: Distributor {
            title
          }
          collector: Collector {
            fullName
            role: Role {
              name
            }
          }
          outlet: RetailOutlet {
            id
            title
            route : Route {
              title
              user: User {
                fullName
              }
            }
          }
          collectionType   
          status
          details {
            chequeNumber     
            bank               
            image {
               src: image
                thumbnail
                created_at
            }                         
            valuedDate                     
          }
          date
            paymentDetails: PaymentDetails {
            id
            amount
            ledger: Ledger {
              title
            }
          }

}
`;

const GET_PAYMENTS_LIST = gql`
    query paymentsList($limit: Int, $offset: Int, $filter: FilterInput) {
      payments(limit: $limit, offset: $offset, filter: $filter) {
        rows {
          ...PaymentsFragment
        }
        count
      }
    }
    ${PAYMENTS_FRAGMENT}
`;

const query = {
  payments: GET_PAYMENTS_LIST,
};

export { PAYMENTS_FRAGMENT, GET_PAYMENTS_LIST };

export { query };
