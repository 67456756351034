import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../data/enums/SuccessMessage';
import { getFilterConfig } from '../../../utils/filter';
import { FILTER } from '../../../data/enums/filter';
import { configuration as configurationRoute } from '../../../data/enums/Route';
import { STATEFUL_ENTITIES } from '../../../data/enums/GraphQL';

const Title = 'Territory';

const breadCrumb = [
  configurationRoute,
];

const filter = getFilterConfig([FILTER.STATUS]);

const formConfig = {
  mapper: element => {
    return ({
      id: element.id || '',
      title: element.title || '',
      description: element.description || '',
    })
  },
  validationField: ['title'],
  validationRequired: true,
};

const crudSuccess = {
  [EVENT_OPERATION.CREATE]: {
    objectMapper: data => ({ title: data.title }),
    responseName: 'createTerritory',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.CREATE}`,
  },
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: data => ({
      id: data.id,
      title: data.title,
    }),
    responseName: 'updateTerritory',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.UPDATE}`,
  },
  [EVENT_OPERATION.DELETE]: {
    objectMapper: data => ({
      ids: [data.id],
      type: STATEFUL_ENTITIES.TERRITORY,
      active: false,
    }),
    responseName: 'toggleState',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: data => ({
      ids: [data.id],
      type: STATEFUL_ENTITIES.TERRITORY,
      active: false,
    }),
    responseName: 'toggleState',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.BULK_DELETE}`,
  },
};


export { crudSuccess, breadCrumb as breadCrumbConfig, formConfig, filter as filterConfig };
