import { compose } from 'recompose';
import Outlet from './Outlet';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';

import {
  CREATE_OUTLET,
  query,
  mutation,
  UPDATE_OUTLET,
  UPLOAD,
  TOGGLE_STATE,
  DOWNLOAD_REPORT,
} from './API';

const ComposedOutlet = compose(
  CREATE_OUTLET,
  UPDATE_OUTLET,
  UPLOAD,
  TOGGLE_STATE,
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  }),
)(Outlet);

export default ComposedOutlet;
