import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import {
  CUSTOMER_FRAGMENT,
  DISTRIBUTOR_FRAGMENT,
  DISTRIBUTOR_SERVICES_FRAGMENT, FILE_FORMAT_FRAGMENT,
  OUTLET_CUSTOMER_FRAGMENT,
  SKU_TITLE_FRAGMENT,
  VENDOR_FRAGMENT,
} from './GqlFragments';
// import { OUTLET_FRAGMENT } from '../sales/route-outlet/outlet/API';

const GET_DISTRIBUTORS = gql`
  query getDistributors ($limit: Int, $offset: Int, $filter: FilterInput) {
    distributors(limit: $limit, offset: $offset, filter: $filter) {
     rows {
      ...DistributorFragment
     }
     count
    }
  }
  ${DISTRIBUTOR_FRAGMENT}
`;

const GET_DISTRIBUTORS_SERVICES = gql`
    query getDistributorsWithServices ($limit: Int, $offset: Int, $filter: FilterInput) {
        distributors(limit: $limit, offset: $offset, filter: $filter) {
            rows {
                ...DistributorServicesFragment
            }
            count
        }
    }
    ${DISTRIBUTOR_SERVICES_FRAGMENT}
`;

const GET_VENDORS = gql`
  query getVendors ($limit: Int, $offset: Int) {
    vendors(limit: $limit, offset: $offset) {
     rows {
      ...VendorFragment
     }
     count
    }
  }
  ${VENDOR_FRAGMENT}
`;

const GET_SKU_WITH_BATCH = gql`
  query getSku($offset: Int, $limit: Int, $includeAssortment:Boolean) {
    skus(offset: $offset, limit: $limit, includeAssortment: $includeAssortment) {
     rows {
      ...SkuFragment
      Rates {
        id
        batchDetails {
          batchNumber
        }
        usageDate {
          manufacture
          expiry
        }
        priceDetails {
            rlp
            dlp
            vatPercentage
        }
      }
     }
     count
    }
  }
  ${SKU_TITLE_FRAGMENT}
`;

const GET_OUTLETS = gql`
  query getOutlets ($limit: Int, $offset: Int, $filter: FilterInput) {
    retailOutlets(limit: $limit, offset: $offset, filter: $filter) {
      rows {
        ...OutletCustomerFragment
      }
      count
    }
  }
  ${OUTLET_CUSTOMER_FRAGMENT}
`;

const GET_OUTLET_DETAIL_WITH_CUSTOMERS = gql`
    query getRetailOutlet($id: String) {
        retailOutlets(filter: {
            filters: [
                {
                    column: "id",
                    value: [$id]
                }
            ]
        }) {
            rows {
                ...OutletCustomerFragment
            }
        }
    }
  ${OUTLET_CUSTOMER_FRAGMENT}
`;


const GET_CUSTOMERS = gql`
  query getCustomers ($limit: Int, $offset: Int, $filter: FilterInput) {
    customers(limit: $limit, offset: $offset, filter: $filter) {
      rows {
        ...CustomerFragment
      }
      count
    }
  }
  ${CUSTOMER_FRAGMENT}
 `;

const CHECK_IF_ALREADY_EXIST = gql`
query checkIfAlreadyExist ($type: statefulEntities, $column: String, $value: String) {
    isAlreadyExist(type: $type, column: $column, value: $value)
}`;

const TOGGLE_STATE = graphql(gql`
  mutation ($ids: [Int]!, $type: statefulEntities, $active: Boolean!, $fieldName: String) {
    toggleState(ids: $ids, type:$type, active: $active, fieldName: $fieldName) {
        ids
        message
    }
  }`, {
  props: ({ mutate }) => ({
    toggleState: variables => mutate({
      variables,
    }),
  }),
});

const UPLOAD = graphql(gql`
  mutation ($input: ImportFileInput!) {
    importCSV(input: $input)
  }`, {
  props: ({ mutate }) => ({
    upload: variables => mutate({
      variables,
    }),
  }),
});

const DOWNLOAD_REPORT = graphql(gql`
    mutation($input: fileDownloadInput! ) {
        downloadReport(input: $input) {
            file {
                ...fileFormatFragment
            }
        }
    }
    ${FILE_FORMAT_FRAGMENT}
`, {
  props: ({ mutate }) => ({
    downloadReport: variables => mutate({
      variables,
    }),
  }),
});

const GET_TERRITORIES = gql`
    query getTerritory($offset: Int, $limit: Int, $filter: FilterInput) {
        territories(offset: $offset, limit: $limit, filter: $filter) {
            rows {
                id
                title
            }
        }
    }`;


export {
  UPLOAD,
  GET_OUTLETS,
  GET_VENDORS,
  TOGGLE_STATE,
  GET_CUSTOMERS,
  GET_TERRITORIES,
  DOWNLOAD_REPORT,
  GET_DISTRIBUTORS,
  GET_SKU_WITH_BATCH,
  CHECK_IF_ALREADY_EXIST,
  GET_DISTRIBUTORS_SERVICES,
  GET_OUTLET_DETAIL_WITH_CUSTOMERS,
};
