import styled from 'styled-components';

const UserAccountStyled = styled.div`
  //UserAcount

     .modal-wrapper{
.modal-inner.reset{
 .password{
 position: relative;

  .eye{
    position: absolute;
    top: 34px;
    bottom: 0;
    right: 16px;
    cursor: pointer;
    color: grey;
}
}
.form-input{
  margin-bottom: 24px;
  }
  }
} 
  .user-form-wrap {
    background-color:rgba(231, 231, 237, 0.2);
    height:100%;
    /* h2 {
      font-size: 14px;
      line-height:21px;
      font-weight: normal;
      color: #6B6C7E;
      padding-bottom: 5px;
      margin: 0 0 24px;
      border-bottom: 1px solid #E7E7ED;
      width: 100%;
    }
    
    .form-input, .form-select-input {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content:space-between;
    }
    
    label {
      width: 140px;
    }
    
    .form-select-input {
      > div {
        width:311px;
        background:#F1F2F5;
        
        &:focus{
          border: 1px solid #E7E7ED;
        }
        >div{
          background:#F1F2F5;
          border-color:transparent
        }
      }
    }
    
    input {
      width:311px;
      border: 1px solid #F1F2F5;
    }
    
    .button-wrap {
      margin-top: 48px;
      display: flex;
      justify-content: flex-end;
    }

    .user-form-content{
      width:548px;
    }
    .form-select-input,
    .form-input{
      width:548px;
      input{
        width:311px;
        background:#F1F2F5;
        border:1px solid #E7E7ED;
      }
    } */
/* 
.form-input-wrap .form-input{
background:red;
 &:last-child{ margin-bottom: 8px;}
} */

}

.reset-pw{
  margin-top: -20px;
  text-align: right;
   height: 21px;
  color: #0B5FFF;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  cursor: pointer;
}
  



`;

export default UserAccountStyled;
