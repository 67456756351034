import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import withLoading from '../../../../../utils/composition/withLoading';
import { EMPTY, INVALID } from '../../../../../data/enums/ErrorMessage';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import {
  CustomSelect, Form, Input, SingleDatePicker,
} from '../../../../../components';

const propTypes = {
  uploadCallBack: PropTypes.func,
  dropDownCallBack: PropTypes.func,
  handleInputChange: PropTypes.func,
  update: PropTypes.instanceOf(Object),
  handleDropDownChange: PropTypes.func,
  handleMultipleUpload: PropTypes.func,
  onUploadRemove: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  data: PropTypes.instanceOf(Object),
  skuList: PropTypes.instanceOf(Array),
  refsObj: PropTypes.instanceOf(Object),
  skuBatchList: PropTypes.instanceOf(Array),
};

const defaultProps = {
  data: {},
  refsObj: {
    title: '',
  },
  skuList: [],
  skuBatchList: [],
  enableErrorDisplay: true,
  uploadCallBack: () => null,
  onUploadRemove: () => null,
  dropDownCallBack: () => null,
  handleDropDownChange: () => null,
  handleMultipleUpload: () => null,
  update: {
    type: EVENT_OPERATION.CREATE,
    status: false,
  },
  /**
   * agruments:
   * first param: input event (required),
   * second param: data's first depth targeted key,
   * third param:
   *  only if object depth greater than one,
   *  should contain list of object keys in accordance of depth upto end i.e target key
   */
  handleInputChange: () => null,

};

const GRNForm = ({ ...props }) => {
  const {
    data,
    update,
    refsObj,
    skuList,
    getState,
    updateState,
    skuBatchList,
    uploadCallBack,
    onUploadRemove,
    inputCallBack,
    dropDownCallBack,
    handleInputChange,
    enableErrorDisplay,
    invoiceNumberStatus,
    handleDropDownChange,
    handleMultipleUpload,
  } = props;

  return (
    <Form>
      {/* <div className="scrollable-body-vertical"> */}
      <Row>
        <Col md={12}>
          <CustomSelect
            enableValidation
            disabled={invoiceNumberStatus}
            options={skuList}
            labelContent="SKU"
            className="custom-select"
            placeholder="Select SKU"
            getOptionValue={({ id }) => id}
            getOptionLabel={({ title }) => title}
            ref={ref => refsObj.skuId = ref}
            enableErrorDisplay={enableErrorDisplay}
            onChange={event => handleDropDownChange(event.id, ['skuId'], dropDownCallBack)}
            value={skuList.filter(({ id }) => id === data.skuId)}
          />
        </Col>
        <Col md={4} className="mt-24">
          <CustomSelect
            disabled={invoiceNumberStatus}
            enableValidation
            options={skuBatchList}
            labelContent="Batch"
            className="custom-select"
            placeholder="Select Batch"
            getOptionValue={({ id }) => id}
            getOptionLabel={({ batchDetails }) => (batchDetails.batchNumber ? batchDetails.batchNumber.replace(/_/g, ' ') : batchDetails.batchNumber)}
            ref={ref => refsObj.skuBatchId = ref}
            enableErrorDisplay={enableErrorDisplay}
            defaultValue={skuBatchList[0]}
            onChange={event => handleDropDownChange(event.id, ['skuBatchId'], dropDownCallBack)}
            value={skuBatchList.filter(({ id }) => id === data.skuBatchId)}
          />
        </Col>
        <Col md={4}>
          <div className="single-date-picker">
            <label>Manufacturing (MFG) Date</label>
            <SingleDatePicker
              name="manufactureDate"
              date={data.manufactureDate}
              disabled={invoiceNumberStatus}
              onChange={(name, date) => (
                handleInputChange({
                  target: {
                    name: 'manufactureDate',
                  },
                  formattedValue: date,
                })
              )}
              maxDate={data.expiryDate}
            />
          </div>
        </Col>
        <Col md={4}>
          <div className="single-date-picker">
            <label>Expiry (EXP) Date</label>
            <SingleDatePicker
              name="expiryDate"
              date={data.expiryDate}
              disabled={invoiceNumberStatus}
              onChange={(name, date) => (
                handleInputChange({
                  target: {
                    name: 'expiryDate',
                  },
                  formattedValue: date,
                })
              )}
              minDate={data.manufactureDate}
            />
          </div>
        </Col>
        <Col md={4} className="mt-24">
          <Input
            name="updatedQuantity"
            type="number"
            rule="isInt"
            labelContent="Quantity (QTY)"
            enableValidation
            value={data.updatedQuantity}
            enableErrorDisplay={enableErrorDisplay}
            ref={ref => refsObj.updatedQuantity = ref}
            onChange={(event) => handleInputChange(event, '', ['updatedQuantity'], inputCallBack)}
            argument={{
              min: 1,
              allow_leading_zeroes: false,
            }}
            errorMessage={data.updatedQuantity ? INVALID : EMPTY}
          />
        </Col>
        <Col md={4} className="mt-24">
          <Input
            name="rate"
            type="number"
            rule="isFloat"
            labelContent="Rate"
            enableValidation
            argument={{ min: 1 }}
            value={data.updatedPriceDetails.rate}
            enableErrorDisplay={enableErrorDisplay}
            ref={ref => refsObj.rate = ref}
            disabled={invoiceNumberStatus}
            onChange={event => handleInputChange(event, 'updatedPriceDetails')}
            errorMessage={data.updatedPriceDetails.rate ? INVALID : EMPTY}
          />
        </Col>
        <Col md={4} className="mt-24">
          <Input
            name="discount"
            type="number"
            labelContent="Discount"
            enableValidation
            rule="isFloat"
            argument={{
              min: 0,
              max: data.updatedPriceDetails.rate * data.updatedQuantity,
            }}
            value={data.updatedPriceDetails.discount}
            enableErrorDisplay={enableErrorDisplay}
            ref={ref => refsObj.discount = ref}
            disabled={invoiceNumberStatus}
            onChange={event => handleInputChange(event, 'updatedPriceDetails')}
            errorMessage={data.updatedPriceDetails.discount ? INVALID : EMPTY}
          />
        </Col>

      </Row>
      {/* </div> */}
    </Form>
  );
};

GRNForm.propTypes = propTypes;

GRNForm.defaultProps = defaultProps;

const GRNFormWithErrorAndLoading = withLoading(GRNForm);

export default GRNFormWithErrorAndLoading;
