import React from 'react';
import SectionTitleStyled from './SectionTitleStyled';
import SectionStyled from '../Section/SectionStyled';

const SectionWithTitle = ({ title, children }) => (
  <SectionStyled className="custom-display">
    <SectionTitleStyled>
      <div className="section-title">
        <span>{title}</span>
      </div>
    </SectionTitleStyled>
    {children}
  </SectionStyled>

);

export default SectionWithTitle;
