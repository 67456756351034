import gql from 'graphql-tag';
import { GET_DISTRIBUTORS, DOWNLOAD_REPORT } from '../../common/GqlAPI';

const STOCK_AVAILABILITY_FRAGMENT = gql`
    fragment StockAvailabilityFragment on stockAvailabilityLine {
        id
        brand   
        skuFamliyId: product_pack_id
        skuFamily: product_pack
        distributor
        upc
        averageSales:avg_sales
        currentStock:current_stock
        doh
        availability
        sufficiency
        overStock:over_stock
        availabilityScore:availability_score
    }
`;

const GET_STOCK_AVAILABILITY_LIST = gql`
    query getStockAvailabilityList($offset: Int, $limit: Int, $filter: FilterInput) {
      getStockAvailabilityReport(offset: $offset, limit: $limit, filter: $filter){
            rows{
                ...StockAvailabilityFragment
            }
            count
        }
    }
    ${STOCK_AVAILABILITY_FRAGMENT}
`;

const query = {
  getDistributors: GET_DISTRIBUTORS,
  getStockAvailabilityList: GET_STOCK_AVAILABILITY_LIST,
};

const mutation = {
  downloadReport: DOWNLOAD_REPORT,
};

export {
  query, mutation, DOWNLOAD_REPORT, STOCK_AVAILABILITY_FRAGMENT,
};
