import {
  compose,
} from 'recompose';
import CreateSrn from './CreateSrn';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';
import {
  query, mutation, CREATE_SRN,
} from './API';

const ComposedCreateSrn = compose(
  CREATE_SRN,
  withRequestTracker({
    query,
    mutation,
  }),
)(CreateSrn);

export default ComposedCreateSrn;
