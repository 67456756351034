import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Pagination } from '../../../../components';
import BulkDelete from '../../../components/BulkDelete';
import { STATEFUL_ENTITIES } from '../../../../data/enums/GraphQL';
import { dataProps, paginationProps } from '../../../../data/enums/Component';
import withErrorAndLoadingScreen from '../../../../utils/composition/withLoading';
import { PERMISSION_OBJ } from '../../../../data/enums/Permission';
import { TableBody, TableHeader } from './tableConfig';


const propTypes = {
  data: dataProps.value,
  pagination: paginationProps.value,
  onPageChange: PropTypes.func,
  onCheckBoxClick: PropTypes.func,
  onIconClick: PropTypes.func.isRequired,
  onBulkDelete: PropTypes.func.isRequired,
  permission: PropTypes.instanceOf(Object),
  onTableHeaderClick: PropTypes.func.isRequired,
};

const defaultProps = {
  onPageChange: () => null,
  permission: PERMISSION_OBJ,
  onCheckBoxClick: () => null,
  data: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
};

const SubDAssortmentView = ({ ...props }) => {
  const {
    data,
    onPageChange,
    pagination,
    onIconClick,
    onBulkDelete,
    permission,
  } = props;

  return (
    <Fragment>
      <BulkDelete
        data={data.list}
        type={STATEFUL_ENTITIES.SUB_D_ASSORTMENT}
        handler={onBulkDelete}
        render={(state, primaryCheckboxHandler, secondaryCheckboxHandler) => (
          <div className="table-wrap">
            <table>
              {TableHeader({
                dataListLength: data.list.length,
                checkedListLength: state.checkedList.length,
                primaryCheckboxHandler,
                permission,
              })}
              <tbody>
                {data.list.map(replenishment => (
                  TableBody({
                    data: replenishment,
                    checkedList: state.checkedList,
                    secondaryCheckboxHandler,
                    permission,
                    onIconClick,
                  })
                ))}
              </tbody>
            </table>
          </div>
        )}
      />
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </Fragment>
  );
};

SubDAssortmentView.propTypes = propTypes;

SubDAssortmentView.defaultProps = defaultProps;

const SubDAssortmentViewWithErrorAndLoading = withErrorAndLoadingScreen(SubDAssortmentView);

export default SubDAssortmentViewWithErrorAndLoading;
