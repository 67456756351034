import styled from 'styled-components';

const CallageWrapper = styled.div`

  .filter-wrap {
    .form-select-input{
      margin:0;
      label{
        display:none;
      }
      .select-css{
        .zindex-2__control{
          width:120px;
          min-height: 32px;
           height: 32px;
           .zindex-2__single-value{
            font-size:14px;
            color:#6B6C7E;
            top:42%;
           }
           .zindex-2__indicators{
             .icon{
                color:#6B6C7E;
                position: relative;
                top: -1px;
             }
           }
        }
      }
    }
  }

  table {
    tbody {
      tr {
        td {
          &:last-child:not(:first-child) {
            span {
              cursor: pointer;
              color: #6B6C7E;
              font-size: 14px;
            }
          }

          &:first-child{
            border-left:0;
          }
        }
      }
    }

    .collapsible-body-tr {
      &:hover, &:focus {
        background: transparent;
      }

      table {
        border: none;

        tr {
          cursor: default;
          border-bottom: 1px solid #e7e7ed;

          th {
            background: transparent;
            width: auto;
          }

          th, td {
            border-bottom: none;

            span {
              cursor: pointer;
            }
          }
        }
      }
    }

    tr {
      th, td {
        width: auto;
        white-space: nowrap;

        &:first-child {
          width: 30%;
        }

        &:nth-child(2) {
          width: 15%;
        }
         &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6), &:nth-child(7){
            text-align: right;
            width:8.66%;
         }
    &:nth-child(8){
         text-align: right;
}
      }
    }
  }

  table tbody {
    /* for gap only pad */

    .active + .collapsible-body-tr {
      .collapsible-body-td {
        table {
          tbody {
            tr {
              &.active,
              &.inActive {
                td {
                  &:first-child {
                    padding-left: calc(48px + 32px);

                  }
                }
              }
            }

            /* --2step nested */

            .active + .collapsible-body-tr {
              border: 0;

              .collapsible-body-td {
                table {
                  tbody {
                    tr {
                      &.active,
                      &.inActive {
                        td {
                          &:first-child {
                            padding-left: calc(80px + 32px);
                            
                          }
                        }
                      }
                    }

                    .active + .collapsible-body-tr {
                      .collapsible-body-td {
                        table {
                          tbody {
                         tr {
                      &.active,
                      &.inActive {
                        td {
                          &:first-child {
                            padding-left: calc(112px + 32px );
                           
                          }
                        }
                      }
                    }

                       .active + .collapsible-body-tr {
                      .collapsible-body-td {
                        table {
                          tbody {
                            tr {
                              td {
                                &:first-child {
                                  padding-left: calc(144px + 48px);
                                    > span:before {
                                        content: '';
                                        border-top: 5px solid transparent;
                                    }
                                }
                              }

                            }

                          }
                        }
                      }
                    }

                          }
                        }
                      }
                    }

                  }
                }
              }
            }

            /* --2step nested  end*/
          }
        }
      }
    }

    /* for gap only pad end */

    tr {
      &.active {
        td:first-child > span:before {
          transform: rotate(-90deg);
          transition: all 0.23s ease-in-out;
          border-top-color: #004AD7;
        }
      }

      td:first-child {
        > span {
          position: relative;
          padding-left: 26px;

          &:before {
            content: '';
            width: 0;
            height: 0;
            border-radius: 4px;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #6b6c7e;
            position: absolute;
            top: 5px;
            left: 0;
            transition: all 0.23s ease-in-out;
          }
        }
      }

    }
  }

`;

export default CallageWrapper;
