import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import View from './View';
import { crudSuccess, formConfig } from './config';
import { refValidator } from '../../../../../utils/refGenerator';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import withAlert from '../../../../../utils/composition/withAlert';
// eslint-disable-next-line import/no-named-as-default
import EVENT_OPERATION from '../../../../../data/enums/EventOperation';
import { dropdownChange, inputChange } from '../../../../../utils/formHandlers';
import { CLIENT_STORAGE_TABLE, getDataFromLocalStorage } from '../../../../../data/services';
import history from '../../../../../utils/history';
import { ROUTE_DETAILS } from '../../../../../data/enums/Route';
import { OFFSET } from '../../../../../data/enums/GeneralConstants';
import { USER_ROLE } from '../../../../../data/enums';


const propTypes = {
  getRouteDetail: PropTypes.func.isRequired,
  updateRoute: PropTypes.func.isRequired,
  createRoute: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  getUserList: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  serverResponseWaiting: false,
};

const routeData = {
  title: '',
  active: true,
  townId: null,
  assignedTo: null,
  VisitType: {
    id: null,
    title: '',
  },
  DeliveryType: {
    id: null,
    title: '',
  },
  VisitCategory: {
    id: null,
    title: '',
  },
  VisitFrequency: {
    id: null,
    title: '',
  },
  Town: {
    id: null,
    title: '',
  },
};

class Detail extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      data: {
        // eslint-disable-next-line react/prop-types
        id: props.id ? props.id : null,
        ...routeData,
      },
      // eslint-disable-next-line react/no-unused-state
      route: {
        outlets: [],
      },
      menu: {
        townList: [],
        dseList: [],
      },
      enableFormValidation: false,
      formReference: formConfig.refsObj,
      crudMode: props.id ? EVENT_OPERATION.READ : EVENT_OPERATION.CREATE,
    };
    this.getData = () => {
      const { data } = this.state;
      return data;
    };
  }

  componentDidMount() {
    const { data } = this.state;
    if (data.id) {
      this.getRouteInfo();
    }

    this.loadDataForDropDown();
  }

  // eslint-disable-next-line react/sort-comp
  componentDidCatch(error, info) {
  }

  getRouteInfo() {
    const { data } = this.state;
    const { getRouteDetail, displayAlert } = this.props;
    getRouteDetail(
      {
        id: data.id.toString(),
      },
      {
        handleSuccess: (response) => {
          // eslint-disable-next-line max-len
          const formattedDetail = crudSuccess[EVENT_OPERATION.UPDATE].objectMapper(response.data.routes.rows[0]);
          this.setState({ data: formattedDetail });
        },
        handleError: (error) => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  }

  loadDSEList = () => {
    // load all the DSE List.
    const { getUserList } = this.props;

    getUserList({
      offset: OFFSET,
      limit: 500,
      filter: {
        filters: [
          {
            column: 'role_id',
            value: [USER_ROLE.DSE.toString(), USER_ROLE.BRANCH_HEAD.toString(), USER_ROLE.VERIFIER.toString()],
          },
        ],
      },
    }, {
      handleSuccess: (response) => {
        const { menu } = this.state;

        menu.dseList = response.data.users.rows || [];
        this.setState({ menu });
      },
    });
  };

  loadDataForDropDown = () => {
    const { menu } = this.state;
    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.TOWN)
      .then((response) => {
        menu.townList = response;
        this.setState({ menu });
      });
    this.loadDSEList();
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  onIconClick = () => {
  };

  handleInputChange = (event, firstParam = '', paramList = []) => {
    const { data } = this.state;
    const updatedDetails = inputChange(data, event, firstParam, paramList);
    this.setState({ data: updatedDetails });
  };

  // eslint-disable-next-line no-unused-vars
  handleDropDownChange = (value, parameterRef = [], callBack = () => null) => {
    const { data } = this.state;
    const updatedData = dropdownChange(data, parameterRef, value);
    if (parameterRef[0] === 'Channel') {
      updatedData.Category.id = '';
      updatedData.Category.title = '';
    }
    this.setState({ data: updatedData });
  };

  apiRequestTransformer = data => ({
    title: data.title,
    townId: data.Town.id,
    assignedTo: data.assignedTo,
    visitType: data.VisitType.title,
    deliveryType: data.DeliveryType.title,
    visitCategory: data.VisitCategory.title,
    visitFrequency: data.VisitFrequency.title,
  });

  handleButtonSubmit = () => {
    const { formReference, data, crudMode } = this.state;
    const { updateRoute, createRoute, displayAlert } = this.props;
    const formValidation = refValidator(formReference);
    if (!formValidation) {
      this.setState({ enableFormValidation: true });
    } else {
      const formattedData = this.apiRequestTransformer(data);

      if (crudMode === EVENT_OPERATION.CREATE) {
        createRoute({ input: formattedData }, {
          handleSuccess: (res) => {
            // eslint-disable-next-line max-len
            // const formattedDetail = crudSuccess[EVENT_OPERATION.UPDATE]
            // .objectMapper(res.data.createRoute);
            // this.setState({ data: formattedDetail });
            // debugger;
            displayAlert(ALERT_TYPE.SUCCESS, 'Route Created');
            this.setState({
              crudMode: EVENT_OPERATION.READ,
            }, () => history.push(`/${ROUTE_DETAILS}/${res.data.createRoute.id}`));
          },
          handleError: (err) => {
            this.onAPIRequestFailure(err);
          },
        });
      }

      if (crudMode === EVENT_OPERATION.UPDATE) {
        updateRoute({
          id: data.id,
          input: formattedData,
        }, {
          handleSuccess: (response) => {
            // eslint-disable-next-line max-len
            const formattedDetail = crudSuccess[EVENT_OPERATION.UPDATE]
              .objectMapper(response.data.updateRoute);
            this.setState({
              data: formattedDetail,
              crudMode: EVENT_OPERATION.READ,
            });
            displayAlert(ALERT_TYPE.SUCCESS, 'Route Updated');
          },
          handleError: (err) => {
            this.onAPIRequestFailure(err);
          },
        });
      }
    }
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleButtonCancel = () => {
    const { crudMode, data } = this.state;
    if (crudMode === EVENT_OPERATION.CREATE) {
      this.setState({
        data: {
          ...routeData,
          id: data.id,
        },
      });
    } else {
      this.setState({
        crudMode: EVENT_OPERATION.READ,
      });
      this.getRouteInfo();
    }
  };

  handleEditIconClick = () => {
    // const { } = this.props;
    this.setState({ crudMode: EVENT_OPERATION.UPDATE }, () => null);
  };

  render() {
    const {
      data,
      menu,
      crudMode,
      formReference,
      enableFormValidation,
    } = this.state;

    const { serverResponseWaiting } = this.props;

    return (
      <Fragment>
        <View
          data={data}
          menu={menu}
          crudMode={crudMode}
          refsObj={formReference}
          loading={serverResponseWaiting}
          onInputChange={this.handleInputChange}
          enableErrorDisplay={enableFormValidation}
          handleButtonSubmit={this.handleButtonSubmit}
          handleButtonCancel={this.handleButtonCancel}
          onDropDownChange={this.handleDropDownChange}
          handleEditIconClick={this.handleEditIconClick}
        />
      </Fragment>
    );
  }
}

Detail.propTypes = propTypes;

Detail.defaultProps = defaultProps;

export { Detail };

export default withAlert()(Detail);
