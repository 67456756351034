import styled from 'styled-components';

const ViewTypeStyled = styled.div`
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    border-right:1px solid #CDCED9;
    padding-right:16px;
    label{
      margin-right:8px;
      font-size:14px;
      line-height:21px;
      color:#6B6C7E;
    }
    .form-select-input{
      margin:0;
      display:flex;
    }
    .select-css{
    .zindex-2__control{
      min-width:110px;
      height:32px ;
      min-height:32px;
      box-shadow:none;
      &.zindex-2__control--is-focused,
      &.zindex-2__control--menu-is-open{
        border:1px solid #CDCED9;
        background-color:#F1F2F5;
      }
    }
    .zindex-2__single-value{
      font-size:14px;
      font-weight:500;
      color:#272833;
      text-transform: capitalize;
    }
    .zindex-2__indicator{
      padding:6px;
    }
    .zindex-2__menu{
      box-shadow:none;
    }
    .zindex-2__menu-list{
      border:1px solid #E7E7ED;
      text-transform: capitalize;
      border-radius:4px;
      .zindex-2__option{
        font-size:14px;
        line-height:21px;
        color:#272833;
        &.zindex-2__option--is-selected{
          color:#FFFFFF;
        }
      }
    }
    }
`;
export default ViewTypeStyled;