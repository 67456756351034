import { FILTER } from '../../../../data/enums/filter';
import { getFilterMenu } from '../../../../utils/filter';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../../data/enums/SuccessMessage';
import { TARGET_ACHIEVEMENT } from '../../../../data/enums/Route';
import { breadCrumbConfig } from '../config';

const title = 'Target';

const breadCrumb = [
  ...breadCrumbConfig,
  {
    id: 1,
    title: 'Target',
    path: `/${TARGET_ACHIEVEMENT}`,
    active: true,
  },
];

const formConfig = {
  mapper: element => ({
    brandId: element.brandId,
    target: element.target,
    name: element.name,
    year: element.year,
    monthName: element.monthName,
    brandName: element.brandName,
    // target: element.target
    total: element.total,
    role: element.role,
  // [EVENT_OPERATION.UPDATE]: {
  //   mapper: element => ({
  //     brandId: element.brandId,
  //     target: element.target,
  //     name: element.name,
  //     year: element.year,
  //     monthName: element.monthName,
  //     brandName: element.brandName,
  //     // target: element.target
  //     total: element.total,
  //     role: element.role,
  }),
  // validationField: ['title'],
  // validationRequired: true,

};

const crudRequest = {
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: element => ({
      brandId: element.brandId,
      target: element.target,
      name: element.name,
      year: element.year,
      monthName: element.monthName,
      brandName: element.brandName,
      // target: element.target
      total: element.total,
      role: element.role,
    }),
    responseName: 'createOrEditUserBrandTarget',
    message: `${title} ${MESSAGE_EVENT_OPERATION.UPDATE}`,
  },
};


const getFilterConfig = (filterList) => {
  const labelList = filterList.map(element => element.LABEL);

  const menu = getFilterMenu(labelList);

  const instance = {};

  filterList.forEach(element => instance[element.VALUE] = {});

  return {
    menu,
    instance,
  };
};

const filter = getFilterConfig([FILTER.SUB_D]);

const crudSuccess = {
  [EVENT_OPERATION.CREATE]: {
    objectMapper: data => ({
      id: data.id,
      title: data.title,
      duration: data.duration,
    }),
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: data => ({
      id: data.id,
      title: data.title,
      duration: data.duration,
    }),
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
};

export {
  formConfig,
  crudSuccess,
  filter as filterConfig,
  breadCrumb as breadCrumbConfig,
};
