import React from 'react';
import PropTypes from 'prop-types';
import TableHeader from '../../base/TableHeader';
import { fixedFloatAndComma } from '../../../utils/conversion';


const labelMappings = {
  skuId: 'skuId',
  sku: 'sku',
  quantity: 'quantity',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.skuId,
    title: 'SKU ID',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 2,
    label: labelMappings.sku,
    title: 'SKU',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 3,
    label: labelMappings.quantity,
    title: 'Quantity',
    show: true,
    sortable: true,
    className: 'sorting',
  },
];

const TableHeaderConfig = ({ onHeaderClick }) => <TableHeader headerDetails={labelConfig} onHeaderClick={onHeaderClick} />;

const returnTableBody = (data) => {
  const table = {};

  table[labelMappings.skuId] = (
    <td key={`${data.id}-id`}>{data.SKU && data.SKU.id || ''}</td>
  );
  table[labelMappings.sku] = (
    <td key={`${data.id}-sku`}>
      {data.SKU ? data.SKU.title : ''}
    </td>
  );
  table[labelMappings.quantity] = (
    <td className="text-right" key={`${data.id}-quantity`}>{fixedFloatAndComma(data.balance)}</td>
  );

  return table;
};

const TableBody = ({
  data,
}) => {
  const tableBody = returnTableBody(data);
  return (
    <tr key={`openingBalance-${data.id}`}>
      {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
};

TableHeaderConfig.propTypes = {
  onHeaderClick: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
};

export {
  TableHeaderConfig as TableHeader,
  TableBody,
  labelConfig,
};
