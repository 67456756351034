import React, { Component, Fragment } from 'react';
import PrnView from './View';
import { breadCrumb, dataMapper } from './config';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import withAlert from '../../../../utils/composition/withAlert';

class PrnDetails extends Component {
  getList = () => {
    const {
      data, filter, date, searchText,
    } = this.state;
    const { getPrnDetails, displayAlert, match } = this.props;
    /** after api supports details fetch from prnInvoiceNumber  * */
    // prnInvoiceNumber: voucherDecoder(match.params.id),
    getPrnDetails({
      prnId: parseInt(match.params.id || '', 10),
    }, {
      handleSuccess: (response) => {
        const { prnDetails = {} } = response.data;
        const modifiedData = dataMapper(prnDetails);
        this.setState({ data: modifiedData });
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  onFilterChange = (field, value) => {
    if (field === 'date') {
      this.setState({ date: value }, () => this.getList());
    } else {
      this.setState({ filter: field }, () => this.getList());
    }
  };


  constructor(props) {
    super(props);
    this.state = {
      data: dataMapper({}),
      date: {
        start: new Date(),
        end: new Date(),
      },
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount() {
    this.getList();
  }

  componentDidCatch(error, info) {

  }

  render() {
    const { data } = this.state;

    return (
      <Fragment>
        <PrnView
          data={data}
          title={data.prnInvoiceNumber}
          breadCrumb={breadCrumb}
        />
      </Fragment>
    );
  }
}

export default withAlert()(PrnDetails);
