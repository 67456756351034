import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import MerchandiseDeviceView from './View';
import MerchandiseDeviceForm from './Form';
import { DialogWrapper } from '../../common';
import { refGenerator } from '../../../utils/refGenerator';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { apiFilterProcessor, getOffsetFromPageAndLimit } from '../../../utils/api';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { CLIENT_STORAGE_TABLE, getDataFromLocalStorage } from '../../../data/services';
import {
  crudRequest as crudRequestConfig, breadCrumbConfig, formConfig, title, filterConfig,
} from './config';
import { crudResponseProcessor, handleFormSubmit } from '../../../utils/crudResponseProcessor';
import * as queryService from '../../base/query.service';
import { getPermissionForMerchandisingDevices } from '../../base/permission';
import PageHeader from '../../base/PageHeader';
import { PanelStyled } from '../../common/configuration';
import { isError } from '../../common/HelperFunctions';

const propTypes = {
  getMerchandiseDeviceList: PropTypes.func.isRequired,
  createMerchandiseDevice: PropTypes.func.isRequired,
  updateMerchandiseDevice: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  toggleState: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class MerchandiseDevice extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      display: {
        searchBox: false,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        // date: { ... queryService.baseQueryParameters.date },
      },
      menu: {
        categoryList: [],
      },
    };
    this.permission = getPermissionForMerchandisingDevices();
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      props.displayAlert,
    );
    this.getData = () => {
      const { data } = this.state;
      return data;
    };

    const serverCall = {
      [EVENT_OPERATION.CREATE]: props.createMerchandiseDevice,
      [EVENT_OPERATION.UPDATE]: props.updateMerchandiseDevice,
      [EVENT_OPERATION.DELETE]: props.toggleState,
    };

    this.onCRUDSuccess = crudResponseProcessor((data, type) => {
      const { displayAlert } = this.props;
      // clear the checked list.
      this.setState(data);
      displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message);
    }, this.getData, ['title', 'categoryIds', 'Categories']);

    this.onFormSubmit = (operationType, data) => {
      handleFormSubmit(
        this.onCRUDSuccess,
        this.onAPIRequestFailure,
        crudRequestConfig,
        serverCall,
      )(operationType, data);
    };

    this.formReference = refGenerator(formConfig.validationField);
  }

  componentDidMount() {
    this.loadTableData();
    this.loadDataForDropDown();
  }


  // eslint-disable-next-line react/sort-comp
  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  loadTableData = () => {
    // debugger;
    const { queryParameters, data } = this.state;
    const { getMerchandiseDeviceList, displayAlert } = this.props;
    const offset = getOffsetFromPageAndLimit(queryParameters.pagination);

    getMerchandiseDeviceList(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          // dateRange: queryParameters.date,
          queryString: queryParameters.search,
        },
      },
      {
        handleSuccess: (response) => {
          data.list = response.data.merchandiseDevices
            ? response.data.merchandiseDevices.rows || [] : [];
          data.total = response.data.merchandiseDevices
            ? response.data.merchandiseDevices.count || 0 : 0;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
        handleError: error => this.onAPIRequestFailure(error),
      },
    );
  }

  loadDataForDropDown = () => {
    const { menu } = this.state;
    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.CATEGORY)
      .then((res) => {
        menu.categoryList = res;
        this.setState({ menu });
      });
  };

  setQueryParameters = (queryParams, callBack = () => null) => this.setState(
    { queryParameters: queryParams },
    callBack,
  );

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  getCategoryTitle = (categoryId) => {
    const { menu } = this.state;
    const indexInList = menu.categoryList.findIndex(category => category.id === categoryId);
    if (indexInList > -1) {
      return menu.categoryList[indexInList].title;
    }

    return '';
  };

  handleBulkDelete = (type, response) => this.onCRUDSuccess(type)(response);

  render() {
    const {
      data, queryParameters, menu, display,
    } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <Fragment>
        <div className="merchandising-config-panel">
          <DialogWrapper
            onDialogSubmit={this.onFormSubmit}
            formConfig={formConfig}
            refsObj={this.formReference}
            renderDialog={
              (
                {
                  onDialogSubmit,
                  dialogData,
                  enableErrorDisplay,
                  handleDialogInputChange,
                  handleDialogDropDownChange,
                  handleDialogAutoCompleteChange,
                },
              ) => (
                <Fragment>
                  <MerchandiseDeviceForm
                    show
                    data={dialogData}
                    refsObj={this.formReference}
                    onFormSubmit={onDialogSubmit}
                    loading={serverResponseWaiting}
                    categoryList={menu.categoryList}
                    enableErrorDisplay={enableErrorDisplay}
                    handleInputChange={handleDialogInputChange}
                    handleDropDownChange={handleDialogDropDownChange}
                    onAutoCompleteChange={handleDialogAutoCompleteChange}
                  />
                </Fragment>
              )}
            render={({ onDialogItemClick }) => (
              <Fragment>
                <div className="section-header">
                  <PanelStyled>
                    <PageHeader
                      display={display}
                      breadCrumb={breadCrumbConfig}
                      config={{
                        title,
                        create: this.permission.create,
                        download: false,
                        filter: true,
                        search: true,
                      }}
                      filter={{
                        date: queryParameters.date,
                        menuList: filterConfig.menu,
                        onFilterChange: this.basePaginationService.handleFilterChange,
                      }}
                      queryParameters={queryParameters}
                      controlDisplay={this.controlDisplay}
                      handleCreateClick={onDialogItemClick}
                      resetFilter={this.basePaginationService.resetFilter}
                      clearSearchText={this.basePaginationService.clearSearchText}
                      handleSearchChange={this.basePaginationService.handleSearchInputChange}
                      handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
                      handleDownloadClick={this.basePaginationService.handleDownloadClick}
                    />
                  </PanelStyled>
                </div>
                <div className="section-content">
                  <MerchandiseDeviceView
                    data={data}
                    pagination={queryParameters.pagination}
                    onIconClick={onDialogItemClick}
                    loading={serverResponseWaiting}
                    onPageChange={this.basePaginationService.onPageSelect}
                    getCategoryTitle={this.getCategoryTitle}
                    onBulkDelete={this.handleBulkDelete}
                    permission={this.permission}
                  />
                </div>
              </Fragment>
            )}
          />
        </div>
      </Fragment>
    );
  }
}

MerchandiseDevice.propTypes = propTypes;

MerchandiseDevice.defaultProps = defaultProps;

export default withAlert()(MerchandiseDevice);
