import React from 'react';
import PropTypes from 'prop-types';
import withLoading from '../../../../utils/composition/withLoading';
import CustomSelect from '../../../../components/CustomSelect';
import {
  Badge, Collapsible, Search, Switch,
} from '../../../../components';
import AssortmentPanelStyled from './AssortmentPanelStyled';

const propTypes = {
  onHandleCategoryChange: PropTypes.func.isRequired,
  categoryList: PropTypes.array,
  productCategoryList: PropTypes.array,
  onProductCategoryClick: PropTypes.func.isRequired,
  brandList: PropTypes.array,
  onBrandClick: PropTypes.func.isRequired,
  SKUFamilyList: PropTypes.array,
  count: PropTypes.objectOf({
    category: PropTypes.array,
    productCategories: PropTypes.array,
    brands: PropTypes.array,
    skuFamilies: PropTypes.array,
  }),
  skuAssortedList: PropTypes.array,
  onToggle: PropTypes.func.isRequired,
  onHandleSearchInput: PropTypes.func.isRequired,
  searchText: PropTypes.string,
  data: PropTypes.objectOf({
    categoryId: PropTypes.string.isRequired,
    productCategoryId: PropTypes.string.isRequired,
    brandId: PropTypes.string.isRequired,
  }),
};

const defaultProps = {
  categoryList: [],
  productCategoryList: [],
  brandList: [],
  SKUFamilyList: [],
  count: {
    category: [],
    productCategories: [],
    brands: [],
    skuFamilies: [],
  },
  skuAssortedList: [],
  searchText: '',
};

const View = ({ ...props }) => {
  const {
    data,
    categoryList,
    onHandleCategoryChange,
    productCategoryList,
    onProductCategoryClick,
    brandList,
    onBrandClick,
    SKUFamilyList,
    count,
    skuAssortedList,
    onToggle,
    onHandleSearchInput,
    searchText,
  } = props;

  const {
    category, productCategories, brands, skuFamilies,
  } = count;
  const { categoryId, productCategoryId, brandId } = data;

  const totalSkuIds = SKUFamilyList.map(item => item.skus.map(sku => sku.id)).flat() || [];
  const getCount = (countItems, id, key, type) => {
    const countItem = countItems.find(item => item[key] === id) || {};
    if (type === 'badge') {
      return countItem.count ? <Badge light badge label={countItem.count} className="badge" /> : '';
    }
    return countItem.count ? countItem.count : 0;
  };

  return (
    <section className="assortment-detail">
      <div className="select-section-wrap">
        <div className="select-section">
          <CustomSelect
            small
            enableValidation
            options={categoryList}
            className="custom-select"
            placeholder="Select Category"
            getOptionValue={({ id }) => id}
            getOptionLabel={({ id, title }) => (
              <span>
                {title}
                {getCount(category, id, 'categoryId', 'badge')}
              </span>
            )}
            enableErrorDisplay={false}
            onChange={event => onHandleCategoryChange(event.id)}
            value={categoryList.filter(({ id }) => id === categoryId) || []}
            isSearchable={false}
          />
          {
          categoryId && getCount(category, categoryId, 'categoryId', 'badge')
        }
        </div>
      </div>
      {categoryId && (
        <AssortmentPanelStyled>
          <div className="panel-section">
            <div className="panel one">
              <p className="p-title">Product Category</p>
              <ul>
                {productCategoryList.map(group => (
                  <li
                    key={group.id}
                    onClick={() => onProductCategoryClick(group.id)}
                    className={parseInt(productCategoryId, 10) === group.id ? 'active' : 'inactive'}
                    title={group.title}
                  >
                    <p>{group.title}</p>
                    {getCount(productCategories, group.id, 'id', 'badge')}
                  </li>
                ))}
              </ul>
            </div>
            {productCategoryId && (
            <div className="panel two">
              <p className="p-title">Brands</p>
              <ul>
                {brandList.map(brand => (
                  <li
                    key={brand.id}
                    onClick={() => onBrandClick(brand.id)}
                    className={parseInt(data.brandId, 10) === brand.id ? 'active' : 'inactive'}
                    title={brand.title}
                  >
                    <p>{brand.title}</p>
                    {getCount(brands, brand.id, 'id', 'badge')}
                  </li>
                ))}
              </ul>
            </div>
            )}
            {brandId && (
            <div className="panel three">
              <div className="panel-title">
                <p className="p-title">SKU Family / SKUs</p>
                <Search searchText={searchText} clearText={!searchText} handleSearchInput={onHandleSearchInput} />
              </div>
              <div className="div-thead">
                <span>SKU</span>
                <div className="status">
                  <span>Status</span>
                  <Switch
                    checked={totalSkuIds.every(r => skuAssortedList.includes(r))}
                    onToggle={(e) => { onToggle(e, totalSkuIds); }}
                  />
                </div>
              </div>
              <div className="div-tbody">
                {
                  SKUFamilyList.map(skuFamily => (
                    <Collapsible
                      key={skuFamily.id}
                      trigger={(
                      <>
                        <span>
                          <span className="text">
                            {skuFamily.title}
                            {getCount(skuFamilies, skuFamily.id, 'id', 'badge')}
                          </span>
                        </span>
                        <div onClick={e => e.stopPropagation()}>
                          <Switch
                            checked={getCount(skuFamilies, skuFamily.id, 'id', 'count') === skuFamily.skus.length}
                            onToggle={(e) => {
                              onToggle(e, skuFamily.skus.map(sku => sku.id));
                            }}
                          />
                        </div>
                      </>
                    )}
                    >
                      {
                        skuFamily.skus.map(sku => (
                          <div className="collapsible-inner" key={sku.id}>
                            <span>{sku.title}</span>
                            <Switch
                              checked={skuAssortedList.includes(sku.id)}
                              onToggle={(e) => { onToggle(e, [sku.id]); }}
                            />
                          </div>
                        ))
                      }
                    </Collapsible>
                  ))
                }
              </div>
            </div>
            )}
          </div>
        </AssortmentPanelStyled>
      )}
    </section>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withLoading(View);

export default LoginViewWithErrorAndLoading;
