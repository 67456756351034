import React from 'react';
import ModalStyled from '../../components/Modal/ModalStyled'
const RenameModal = ({
    handleClose, handler, show, children
}) => {
    const showHideClassName = show ? 'modal display-block' : 'modal display-none';
    return (
        <ModalStyled>
            <div className={showHideClassName}>
                <section className="modal-main">
                    {children}
                    <div className="m-footer">
                        <button onClick={handler}>Ok</button>
                        <button
                            className="m-close"
                            onClick={handleClose}
                        >
                            Cancel
                </button>
                    </div>
                </section>
            </div>
        </ModalStyled>
    );
};
export default RenameModal;