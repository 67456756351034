import { FORM_CONFIG } from '../../../data/enums/config';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { refGenerator } from '../../../utils';


const title = 'Ledger';

const formConfig = {
  [FORM_CONFIG.MAPPER]: element => ({
    file: element.file || {},
  }),
  [FORM_CONFIG.TITLE]: `${EVENT_OPERATION.UPLOAD} ${title}`,
  [FORM_CONFIG.REFS_OBJ]: refGenerator([
    'title',
    'typeId',
    'panNo',
    'address',
    'openingBalance',
    'openingBalanceType',
    'creditDay',
    'creditLimit',
  ]),
  [FORM_CONFIG.VALIDATION_REQUIRED]: true,
};

const ledgerType = [{
  id: 1,
  title: 'Customer',
}, {
  id: 2,
  title: 'Vendor',
}, {
  id: 3,
  title: 'Customer & Vendor',
}];

const openingBalanceTypeData = [{
  id: 'Dr',
  title: 'Debit',
}, {
  id: 'Cr',
  title: 'Credit',
}];

export { ledgerType, openingBalanceTypeData, formConfig };
