import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import UserStatus from './UserStatus';
import { USER_LABEL, USER_ROLE_TITLE } from '../../../data/enums/UserRole';
import SectionWithTitle from '../components/SectionWithTitle';
import UserStatusStyled from './UserStatusStyle';
import { headerLabelConfig } from '../../common/HelperFunctions';

const propTypes = {};

const defaultProps = {};

class UserStatusWrapper extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() { }

  render() {
    const { data, dimensions } = this.props;
    return (
      <div className="row">
        {
          Object.keys(data).map((user, key, arr) => (
            <div className="col-lg-4" index={key}>
              <UserStatusStyled listLength={arr.length || 0}>
                <Link to={`/reports/${USER_LABEL[user]}`}>
                  <SectionWithTitle
                    key={user}
                    title={user === 'dse' ? headerLabelConfig[USER_ROLE_TITLE.DSE] : user}
                  >
                    <UserStatus
                      id={USER_LABEL[user]}
                      dimensions={{ ...dimensions, width: 100 }}
                      data={data[USER_LABEL[user]]}
                      label={user}
                    />
                  </SectionWithTitle>
                </Link>
              </UserStatusStyled>
            </div>
          ))
        }

      </div>
    );
  }
}

UserStatusWrapper.propTypes = propTypes;

UserStatusWrapper.defaultProps = defaultProps;

export default UserStatusWrapper;
