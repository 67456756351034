import {
  compose,
} from 'recompose';
import Invoiced from './Invoiced';
import { DISPATCH_INVOICE, DELIVER_INVOICE } from '../API';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';

const ComposedInvoiced = compose(
  DISPATCH_INVOICE,
  DELIVER_INVOICE,
  withRequestTracker({
    query: {},
    mutation: {
      dispatchInvoice: DISPATCH_INVOICE,
      deliverInvoice: DELIVER_INVOICE,
    },
  }),
)(Invoiced);

export default ComposedInvoiced;
