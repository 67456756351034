import React from 'react';
import PropTypes from 'prop-types';
import NumberWrap from './NumberWrap';

const NumberCard = ({
  title, subTitle, children,
}) => (
  <NumberWrap>
    <div className="text-grey label">{title}</div>
    <div className={`text-grey label subTitle ${!subTitle && 'pad-top'}`}>{subTitle}</div>
    {children}
  </NumberWrap>
);


NumberCard.propTypes = {

};

export default NumberCard;
