import styled from 'styled-components';

const OutletWrapStyled = styled.div`

.table-wrap{
  tbody,thead{
  td,th{
     &:nth-last-child(2),
     &:last-child,
    &:nth-child(2){
    width:unset;
    min-width:30px;
    white-space:nowrap;
    }

  }
  }
}
`;

export default OutletWrapStyled;
