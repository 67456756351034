import { reports as reportsRoute } from '../../../data/enums/Route';
import { getFilterConfig } from '../../../utils/filter';
import { FILTER } from '../../../data/enums/filter';


const breadCrumb = [
  reportsRoute,
];

const filter = getFilterConfig([FILTER.SKU]);


export {
  breadCrumb as breadCrumbConfig, filter as filterConfig,
};
