import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { configuration as configurationRoute } from '../../../data/enums/Route';
import { MESSAGE_EVENT_OPERATION } from '../../../data/enums/SuccessMessage';
import { getFilterConfig } from '../../../utils/filter';
import { FILTER } from '../../../data/enums/filter';
import { STATEFUL_ENTITIES } from '../../../data/enums/GraphQL';

const breadCrumb = [
  configurationRoute,
];

const Title = 'Town';

const formConfig = {
  mapper: element => ({
    id: element.id || '',
    title: element.title || '',
    territoryId: (element.Territory ? element.Territory.id : element.territoryId) || '',
  }),
  validationField: ['title', 'territoryId'],
  validationRequired: true,
};

const baseConfig = {
  primaryKey: 'id',
  updateKeys: ['title'],
};

const filter = getFilterConfig([FILTER.TERRITORY, FILTER.STATUS]);

const crudRequest = {
  [EVENT_OPERATION.CREATE]: {
    objectMapper: data => ({ title: data.title, territoryId: Number(data.territoryId) }),
    responseName: 'createTown',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.CREATE}`,
  },
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: data => ({
      id: data.id,
      title: data.title,
      territoryId: Number(data.territoryId),
    }),
    responseName: 'updateTown',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.UPDATE}`,
  },
  [EVENT_OPERATION.DELETE]: {
    objectMapper: data => ({
      ids: [data.id],
      type: STATEFUL_ENTITIES.TOWN,
      active: false,
    }),
    responseName: 'toggleState',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: data => ({
      ids: [data.id],
      type: STATEFUL_ENTITIES.TOWN,
      active: false,
    }),
    responseName: 'toggleState',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.BULK_DELETE}`,
  },
};

export {
  crudRequest, breadCrumb as breadCrumbConfig, formConfig, filter as filterConfig,
};
