import colors from "../enums/colors";
import iconUrls from "./markerIcons";
const addProperties = (outlet, route) => {
    let { location, outletId, ...rest } = outlet;
    rest.lat = location ? location.latitude : outlet.lat;
    rest.lng = location ? location.longitude : outlet.lng;
    rest.id = outlet.id ? outlet.id : outletId;
    rest.name = outlet.title ? outlet.title : null;
    rest.townId = route.townId;
    rest.town = route.townName;
    rest.channel = outlet.channelName ? outlet.channelName : 'random channel';
    rest.channelId = outlet.channelId ? outlet.channelId : 0;
    rest.category = outlet.categoryName ? outlet.categoryName : 'random category';
    rest.categoryId = outlet.categoryId ? outlet.categoryId : 0;
    rest.distance = 1;
    return rest;
}

const arrangeImportedRoutes = (routes) => {
    let formattedRoutes = [];
    let iconColorIndex = 0;
    routes.forEach(route => {
        let { id, outlets, title, townId, townName, start, end } = route;
        outlets.sort((a, b) => { return a.sequence - b.sequence; });

        //Cases: 1) start.location and end.location both present
        //2) start.location but null end.location
        //3) end.location but null start.location
        //4) no both start.location and end.location
        if (iconColorIndex === iconUrls.markerColor.length - 1) {
            iconColorIndex = 0;
        }

        if (start.location && end.location) {
            let startOutlet = addProperties(start, route);
            startOutlet.distance = "First"
            startOutlet.name = startOutlet.title ? startOutlet.title : "Random Start Outlet";
            startOutlet.order = 1;
            let endOutlet = addProperties(end, route)
            endOutlet.distance = "Last"
            endOutlet.name = endOutlet.title ? endOutlet.title : "Random End Outlet";
            endOutlet.order = outlets.length;
            for (let i = 0; i < outlets.length; i++) {
                let arrangedOutlet = addProperties(outlets[i], route)
                arrangedOutlet.order = outlets[i].sequence + 1
                outlets[i] = arrangedOutlet
            }
            outlets.unshift(startOutlet)
            outlets.push(endOutlet)
        } else if (!start.location && end.location) {
            outlets.forEach(outlet => {
                let arrangedOutlet = addProperties(outlet, route)
                arrangedOutlet.order = outlet.sequence;
                outlet = arrangedOutlet
            })
            let endOutlet = addProperties(end, route)
            endOutlet.distance = "Last"
            endOutlet.name = endOutlet.title ? endOutlet.title : "Random End Outlet";
            endOutlet.order = outlets.length;
            outlets.push(endOutlet)
            let reqStartOutlet = outlets.find(outlet => outlet.id === start.outletId);
            let startOutlet = addProperties(reqStartOutlet, route)
            startOutlet.distance = "First"
            startOutlet.name = startOutlet.title ? startOutlet.title : "Random Start Outlet";
            startOutlet.order = 1;
            outlets[0] = startOutlet;
        } else if (!end.location && start.location) {
            outlets.forEach(outlet => {
                let arrangedOutlet = addProperties(outlet, route)
                arrangedOutlet.order = outlet.sequence + 1
                outlet = arrangedOutlet
            })
            let startOutlet = addProperties(start, route)
            startOutlet.distance = "First"
            startOutlet.name = startOutlet.title ? startOutlet.title : "Random Start Outlet";
            startOutlet.order = 1;
            outlets.unshift(startOutlet)
            let reqEndOutlet = outlets.find(outlet => outlet.id === end.outletId);
            let endOutlet = addProperties(reqEndOutlet, route);
            endOutlet.distance = "Last"
            endOutlet.name = endOutlet.title ? endOutlet.title : "Random End Outlet";
            endOutlet.order = outlets.length;
            outlets[outlets.length - 1] = endOutlet;
        } else if (!start.location && !end.location) {
            let reqStartOutlet = outlets.find(outlet => outlet.id === start.outletId);
            if (!reqStartOutlet) {
                alert(`No start/end outlet, data incorrect! RouteId: ${id}`);
            }
            let startOutlet = addProperties(reqStartOutlet, route)
            startOutlet.distance = "First"
            startOutlet.name = startOutlet.title ? startOutlet.title : "Random Start Outlet";
            startOutlet.order = 1;

            let reqEndOutlet = outlets.find(outlet => outlet.id === end.outletId);
            if (!reqEndOutlet) {
                alert(`No start/end outlet, data incorrect! RouteId: ${id}`);
            }
            let endOutlet = addProperties(reqEndOutlet, route);
            endOutlet.distance = "Last"
            endOutlet.name = endOutlet.title ? endOutlet.title : "Random End Outlet";
            endOutlet.order = outlets.length;

            for (let i = 1; i < outlets.length - 1; i++) {
                let arrangedOutlet = addProperties(outlets[i], route)
                arrangedOutlet.order = arrangedOutlet.sequence;
                outlets[i] = arrangedOutlet
            }
            outlets[0] = startOutlet;
            outlets[outlets.length - 1] = endOutlet;
        }
        formattedRoutes.push({
            dse: id,
            travel: outlets,
            name: title,
            townId,
            label: title,
            value: id,
            color: colors[iconColorIndex],
            distance: 1
        })
        iconColorIndex++;

    })
    formattedRoutes["arranged"] = true;
    return formattedRoutes;
}

export default arrangeImportedRoutes;