import gql from 'graphql-tag';
import { TOGGLE_STATE } from '../../user/userRole/API';

const GET_CATALOG_DETAIL_LIST = gql`
    query getCatalogDetailList($catalogId: Int, $limit:Int, $offset:Int, $filter: FilterInput){
        catalogDetails(catalogId: $catalogId, limit: $limit, offset:$offset, filter: $filter){
            rows {
                id
                catalogId
                title
                alias
                images {
                    thumbnail
                    src: image
                }
            }
            count
        }
    }
`;
const query = {
  getCatalogDetailList: GET_CATALOG_DETAIL_LIST,
};
const mutation = {
  toggleState: TOGGLE_STATE,
};

export { query, mutation, GET_CATALOG_DETAIL_LIST };
