import React, {Component} from 'react';
import Button from '../../components/Button';
import {Col, Row} from 'react-flexbox-grid';

class DemoButton extends Component {
    render() {
        return (
            <div className="demo-section button-wrap">
                <div className="demo-section-inner">
                    <div className="demo-header">
                        <h2>Button</h2>
                        <p>Button make common actions immediately visible and easy to perform with one click or tap.</p>
                    </div>
                    <div className="demo-body">
                        <div className="demo-content">
                            {/* Primary Button */}
                            <div className="demo-content-list">
                                <h3>Primary Button</h3>
                                <div className="demo-sample">
                                    <Row>
                                        <Col xs={3}>
                                            <div>
                                                <div className="bg-light-grey">
                                                    <Button primary>Default</Button>
                                                </div>
                                                <h6>Default</h6>
                                            </div>
                                        </Col>
                                        <Col xs={3}>
                                            <div>
                                                <div className="bg-light-grey">
                                                    <Button secondary>Secondary</Button>
                                                </div>
                                                <h6>Default</h6>
                                            </div>
                                        </Col>
                                        <Col xs={3}>
                                            <div>
                                                <div className="bg-light-grey">
                                                    <Button className="primary disabled">disabled</Button>
                                                </div>
                                                <h6>Primary disabled</h6>
                                            </div>
                                        </Col>
                                        <Col xs={3}>
                                            <div>
                                                <div className="bg-light-grey">
                                                    <Button className="secondary disabled">disabled</Button>
                                                </div>
                                                <h6>Secondary disabled</h6>
                                            </div>
                                        </Col>
                                        <Col xs={3}>
                                            <div>
                                                <div className="bg-light-grey">
                                                    <Button primary iconName="plus"><span>With icon</span></Button>
                                                </div>
                                                <h6>With Icon</h6>
                                            </div>
                                        </Col>
                                        <Col xs={3}>
                                            <div>
                                                <div className="bg-light-grey">
                                                    <Button primary iconName="plus"/>
                                                </div>
                                                <h6>Only Icon</h6>
                                            </div>
                                        </Col>
                                        <Col xs={3}>
                                            <div>
                                                <div className="bg-light-grey">
                                                    <Button outlined><span>Outlined</span></Button>
                                                </div>
                                                <h6>Outlined</h6>
                                            </div>
                                        </Col>
                                        <Col xs={3}>
                                            <div>
                                                <div className="bg-light-grey">
                                                    <Button outlined iconName="plus"><span>Outlined</span></Button>
                                                </div>
                                                <h6>Outlined with Icon</h6>
                                            </div>
                                        </Col>
                                        <Col xs={3}>
                                            <div>
                                                <div className="bg-light-grey">
                                                    <Button className="link"><span>Link</span></Button>
                                                </div>
                                                <h6>Link</h6>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                            {/* Secondary Button */}
                            <div className="demo-content-list">
                                <h3>Secondary Button</h3>
                                <div className="demo-sample">
                                    <Row>
                                        <Col xs={3}>
                                            <div>
                                                <div className="bg-light-grey">
                                                    <Button secondary>Secondary Button</Button>
                                                </div>
                                                <h6>Default</h6>
                                            </div>
                                        </Col>
                                        <Col xs={3}>
                                            <div>
                                                <div className="bg-light-grey">
                                                    <Button secondary iconName="plus"><span>With icon</span></Button>
                                                </div>
                                                <h6>With Icon</h6>
                                            </div>
                                        </Col>
                                        <Col xs={3}>
                                            <div>
                                                <div className="bg-light-grey">
                                                    <Button secondary iconName="folder"/>
                                                </div>
                                                <h6>Only Icon</h6>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DemoButton;
