import styled from 'styled-components';

const UserStatusStyled = styled.div`
  display: flex;
  flex-direction: column;

  a {
    text-decoration: none;
    color: #272833;
  }

  .status-wrap {
    display: flex;
    justify-content: space-between;

    svg {
      height: 52px;
      width: 90px;
      text-transform: uppercase;
      color: #6B6C7E;
    }
  }

`;

export default UserStatusStyled;
