// USE the bread crumb function over here.
const login = {
  id: 0,
  title: 'URL1',
  path: '/login',
  active: false,
};

const test = {
  id: 1,
  path: 'URL2',
  url: '/login',
  active: false,
};

const details = {
  id: 2,
  title: 'Details',
  path: '#',
  active: true,
};

const CONFIGURATION_BASE = 'configuration';
const INVENTORY_BASE = 'inventory';
const SALES_BASE = 'sales';
const REPORTS_BASE = 'reports';
const SALES_FORCE_BASE = 'sales force';
const ERR_BASE = 'error';
const DASHBOARD_BASE = 'dashboard';
const COLLECTION_BASE = 'collections';
const USER_PROFILE = 'user profile';

const configuration = {
  id: 0,
  title: CONFIGURATION_BASE,
  path: '#',
  active: false,
};
const inventory = {
  id: 0,
  title: INVENTORY_BASE,
  path: '#',
  active: false,
};
const sales = {
  id: 0,
  title: SALES_BASE,
  path: '#',
  active: false,
};

const salesForce = {
  id: 0,
  title: SALES_FORCE_BASE,
  path: '#',
  active: false,
};

const reports = {
  id: 0,
  title: REPORTS_BASE,
  path: '#',
  active: false,
};

const dashboard = {
  id: 0,
  title: DASHBOARD_BASE,
  path: '/dashboard',
  active: false,
};

const collection = {
  id: 1,
  title: COLLECTION_BASE,
  path: '#',
  active: false,
};
const userprofile = {
  id: 0,
  title: USER_PROFILE,
  path: '#',
  active: false,
};

const salesForceUrl = SALES_FORCE_BASE.replace(' ', '-');
const userProfileUrl = USER_PROFILE.replace(' ', '-');

const ARC = `${CONFIGURATION_BASE}/arc`;
const BU = `${CONFIGURATION_BASE}/bu`;
const CALL_ROSTER = `${salesForceUrl}/call-roster`;
const CATALOG = `${CONFIGURATION_BASE}/catalog`;
const CATALOG_DETAIL_LIST = `${CATALOG}/details`;
const CHANNEL = `${CONFIGURATION_BASE}/channel`;
const CHANNEL_DETAILS = `${CHANNEL}/details`;
const DASHBOARD = 'dashboard';
const DSR = `${REPORTS_BASE}/dsr`;
const PSR = `${REPORTS_BASE}/psr`;
const ATTENDANCE_REPORT = `${REPORTS_BASE}/attendance-report`;
const DISTRIBUTION_COVERAGE = `${REPORTS_BASE}/distribution-coverage`;
const PRODUCTIVITY = `${REPORTS_BASE}/productivity`;
const USER_CLUSTER = `${REPORTS_BASE}/user-cluster`;
const FOCUSED_SKU = `${CONFIGURATION_BASE}/focusedSku`;
const FOCUSED_SKU_DETAILS = `${FOCUSED_SKU}/details`;
const GRN = `${INVENTORY_BASE}/grn`;
const GRN_CREATE = `${GRN}/create`;
const GRN_DETAILS = `${GRN}/details`;
const LEAVE_MANAGEMENT = `${salesForceUrl}/leave-management`;
const MERCHANDISE = `${salesForceUrl}/merchandise-device`;
const OPENING_BALANCE = `${INVENTORY_BASE}/opening-balance`;
const ORDER_FULFILMENT = `${SALES_BASE}/order-fulfilment`;
const ORDER_INVOICE = `${ORDER_FULFILMENT}/invoice`;
const OUTLET = `${SALES_BASE}/outlet`;
const OUTLET_CREATE = `${OUTLET}/create`;
const OUTLET_DETAILS = `${OUTLET}/details`;
const PRN = `${INVENTORY_BASE}/prn`;
const PRN_CREATE = `${PRN}/create`;
const PRN_DETAILS = `${PRN}/details`;
const PROMOTION = `${salesForceUrl}/promotion`;
const PROMOTION_DETAILS = `${PROMOTION}/details`;
const ROUTE = `${salesForceUrl}/route`;
const ROUTE_CREATE = `${ROUTE}/create`;
const ROUTE_DETAILS = `${ROUTE}/details`;
const SBD = `${CONFIGURATION_BASE}/sbd`;
const SBD_DETAILS = `${SBD}/details`;
const SKU = `${CONFIGURATION_BASE}/sku`;
const SKU_CREATE = `${SKU}/create`;
const SKU_DETAILS = `${SKU}/details`;
const SRN = `${SALES_BASE}/srn`;
const SRN_CREATE = `${SRN}/create`;
const SRN_DETAILS = `${SRN}/details`;
const STOCK_ADJUSTMENT = `${INVENTORY_BASE}/stock-adjustment`;
const STOCK_LEDGER = `${INVENTORY_BASE}/stock-ledger`;
const SUB_D = `${CONFIGURATION_BASE}/sub-d`;
const SUB_D_ASSORTMENT = `${CONFIGURATION_BASE}/subd-assortment`;
const SUB_D_ASSORTMENT_DETAILS = `${SUB_D_ASSORTMENT}/details`;
const SUB_D_CREATE = `${SUB_D}/create`;
const SUB_D_DETAILS = `${SUB_D}/details`;
const TARGET_ACHIEVEMENT = `${salesForceUrl}/target-achievement`;
const TARGET_ACHIEVEMENT_DETAILS = `${TARGET_ACHIEVEMENT}/details`;
const TARGET_TOOLS = `${salesForceUrl}/cluster-target-tools`;
const TERRITORY = `${CONFIGURATION_BASE}/territory`;
const TOWN = `${CONFIGURATION_BASE}/town`;
const USER = `${CONFIGURATION_BASE}/user`;
const USER_ACCOUNT = `${USER}/user-account`;
const USER_CREATE = `${USER}/create`;
const USER_ROLE = `${USER}/user-role`;
const CHANNEL_ASSORTMENT = `${CONFIGURATION_BASE}/channel-assortment`;
const CHANNEL_ASSORTMENT_DETAILS = `${CHANNEL_ASSORTMENT}/details`;
const REPLENISHMENT = `${SUB_D}/replenishment`;
const REPLENISHMENT_DETAILS = `${REPLENISHMENT}/details`;
const SUBD_STOCK_AVAILABILITY = `${REPORTS_BASE}/subd-stock-availability`;
const SUBD_REPLENISHMENT_REPORT = `${salesForceUrl}/subd-replenishment`;
const STOCK_REPLACEMENT_REPORT = `${REPORTS_BASE}/stock-replacement`;
const ASM_REPORT = `${REPORTS_BASE}/asm`;
const STL_REPORT = `${REPORTS_BASE}/stl`;
const DSE_REPORT = `${REPORTS_BASE}/dse`;
const DSE_DETAIL = `${DASHBOARD}/dse-detail`;
const SKU_COVERAGE = `${REPORTS_BASE}/sku-coverage`;
const SKU_DISTRIBUTION = `${REPORTS_BASE}/sku-coverage-distribution`;
const SKU_DISTRIBUTION_DETAILS = `${SKU_DISTRIBUTION}/:id/details`;
const BRAND_COVERAGE = `${REPORTS_BASE}/brand-coverage`;
const CALLAGE = `${REPORTS_BASE}/callage`;
const TARGET_ACHIEVEMENT_REPORT = `${REPORTS_BASE}/target-achievement`;
const RECEIPTS = `${SALES_BASE}/${COLLECTION_BASE}/receipts`;
const CHEQUES = `${SALES_BASE}/${COLLECTION_BASE}/cheques`;
const CASH = `${SALES_BASE}/${COLLECTION_BASE}/cash`;
const POST_DATED_CHEQUES = `${SALES_BASE}/${COLLECTION_BASE}/post-dated-cheques`;
const PFD_SALES_TARGET = `${REPORTS_BASE}/pfd-sales-target`;
const PRODUCTIVE_COVERAGE = `${REPORTS_BASE}/productive-coverage`;
const PRODUCTIVE_COVERAGE_DETAIL = `${PRODUCTIVE_COVERAGE}/details`;
const BRAND_DISTRIBUTION = `${REPORTS_BASE}/brand-coverage-distribution`;
const BRAND_DISTRIBUTION_DETAIL = `${BRAND_DISTRIBUTION}/details`;
const CLASSIFICATION = `${CONFIGURATION_BASE}/classification`;
const OUTSTANDINGS = `${SALES_BASE}/${COLLECTION_BASE}/outstandings`;
const ORDER_SUMMARY_REPORT = `${REPORTS_BASE}/order-summary-report`;
const ATTENDANCE_HISTORY_MAP = `${REPORTS_BASE}/location`;
const ATTENDANCE_REPORT_MAP = `${REPORTS_BASE}/attendance-report-location`;

const PROFILE = `${userProfileUrl}`;

export {
  ARC,
  BU,
  DSR,
  PSR,
  GRN,
  SKU,
  SRN,
  PRN,
  SBD,
  test,
  TOWN,
  login,
  SUB_D,
  ROUTE,
  CHANNEL,
  OUTLET,
  CATALOG,
  USER_ROLE,
  TERRITORY,
  PROMOTION,
  GRN_CREATE,
  PRN_CREATE,
  MERCHANDISE,
  SKU_CREATE,
  SRN_CREATE,
  GRN_DETAILS,
  SKU_DETAILS,
  SRN_DETAILS,
  PRN_DETAILS,
  SBD_DETAILS,
  FOCUSED_SKU,
  ROUTE_CREATE,
  SUB_D_CREATE,
  STOCK_LEDGER,
  USER_ACCOUNT,
  USER_CREATE,
  ORDER_INVOICE,
  SUB_D_DETAILS,
  ROUTE_DETAILS,
  OUTLET_CREATE,
  OUTLET_DETAILS,
  OPENING_BALANCE,
  CHANNEL_DETAILS,
  STOCK_ADJUSTMENT,
  LEAVE_MANAGEMENT,
  SUB_D_ASSORTMENT,
  ORDER_FULFILMENT,
  PROMOTION_DETAILS,
  TARGET_ACHIEVEMENT,
  TARGET_TOOLS,
  FOCUSED_SKU_DETAILS,
  SUB_D_ASSORTMENT_DETAILS,
  TARGET_ACHIEVEMENT_DETAILS,
  configuration,
  inventory,
  sales,
  details,
  DASHBOARD,
  CALL_ROSTER,
  salesForce,
  reports,
  PRODUCTIVITY,
  USER_CLUSTER,
  DISTRIBUTION_COVERAGE,
  CHANNEL_ASSORTMENT,
  CHANNEL_ASSORTMENT_DETAILS,
  REPLENISHMENT,
  REPLENISHMENT_DETAILS,
  SUBD_STOCK_AVAILABILITY,
  SUBD_REPLENISHMENT_REPORT,
  STOCK_REPLACEMENT_REPORT,
  ASM_REPORT,
  STL_REPORT,
  DSE_REPORT,
  DSE_DETAIL,
  dashboard,
  CALLAGE,
  ERR_BASE,
  CATALOG_DETAIL_LIST,
  SKU_COVERAGE,
  BRAND_COVERAGE,
  TARGET_ACHIEVEMENT_REPORT,
  collection,
  RECEIPTS,
  CHEQUES,
  CASH,
  POST_DATED_CHEQUES,
  PFD_SALES_TARGET,
  PRODUCTIVE_COVERAGE,
  PRODUCTIVE_COVERAGE_DETAIL,
  CLASSIFICATION,
  OUTSTANDINGS,
  ORDER_SUMMARY_REPORT,
  SKU_DISTRIBUTION,
  SKU_DISTRIBUTION_DETAILS,
  BRAND_DISTRIBUTION,
  BRAND_DISTRIBUTION_DETAIL,
  PROFILE,
  userprofile,
  ATTENDANCE_HISTORY_MAP,
  ATTENDANCE_REPORT,
  ATTENDANCE_REPORT_MAP,
};
