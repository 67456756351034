import styled from 'styled-components';

const Wrapper = styled.div`
  .color-wrap {
  .demo-content-list {
    margin-bottom: 50px;
    
    &:last-child {
      margin-bottom: 0;
    }
    
    h3 {
      margin: 0 0 30px;
    }
  }
  
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  } 

  .palette-list {
    margin-bottom: 30px;
    
    .bg {
      width: 100%;
      height: 150px;
      border-radius: 4px;
      margin-bottom: 5px;
      border: 1px solid #efefef;
    }

    h2 {
      margin: 0;
      font-size: 18px;
    }

    p {
      margin: 10px 0 0;
      font-weight: bold;
      font-size: 12px;
    }
  }
}
`;

export default Wrapper;
