import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import PromotionArea from './Area';
import { Col, Row } from '../../../../../components/Grid';
import { PROMOTION_RELATED_TYPE } from '../../../../../data/enums';
import { AutoComplete, Button, CustomSelect, Icon, } from '../../../../../components';
import withErrorAndLoadingScreen from '../../../../../utils/composition/withLoading';

const propTypes = {
  scope: PropTypes.instanceOf({
    channelList: PropTypes.instanceOf(Array),
    categoryList: PropTypes.instanceOf(Array),
    catalogInfo: PropTypes.arrayOf(PropTypes.shape(
      {
        catalogId: PropTypes.number,
        catalogDetailIds: PropTypes.arrayOf(PropTypes.number),
      },
    )), // catalog_level: 1, list: [3,4,5]
    channelCategoryInfo: PropTypes.arrayOf(PropTypes.shape(
      {
        channelId: PropTypes.number,
        categoryIds: PropTypes.arrayOf(PropTypes.number),
      },
    )),
  }).isRequired,
  categoryList: PropTypes.instanceOf(Array),
  channelList: PropTypes.instanceOf(Array),
  selectedCategory: PropTypes.instanceOf(Array),
  selectedChannel: PropTypes.instanceOf(Array),
  onAutoCompleteChange: PropTypes.func.isRequired,
  onDropDownChange: PropTypes.func.isRequired,
  catalogList: PropTypes.instanceOf(Array),
  catalogChildrenList: PropTypes.instanceOf(Array),
  onIconClick: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  sbdsList: PropTypes.instanceOf(Array),
  focusedSKUsList: PropTypes.instanceOf(Array),
  townList: PropTypes.instanceOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    }),
  ),
  subDList: PropTypes.instanceOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    }),
  ),
  relatedType: PropTypes.oneOf([PROMOTION_RELATED_TYPE.SBD, PROMOTION_RELATED_TYPE.FOCUSED_SKU, '', null]),
};

const defaultProps = {
  selectedCategory: [],
  selectedChannel: [],
  categoryList: [],
  channelList: [],
  catalogList: [],
  catalogChildrenList: [],
  relatedType: null,
  sbdsList: [],
  focusedSKUsList: [],
  townList: [],
  subDList: [],
};

// eslint-disable-next-line consistent-return
const View = ({ ...props }) => {
  const {
    scope,
    categoryList,
    channelList,
    catalogList,
    onIconClick,
    onDropDownChange,
    catalogChildrenList,
    onAutoCompleteChange,
    onInputChange,
    sbdsList,
    focusedSKUsList,
    townList,
    subDList,
    refsObj,
    enableErrorDisplay,
  } = props;


  const disableCatalogAddition = !scope.catalogInfo[scope.catalogInfo.length - 1].catalogId;
  // eslint-disable-next-line max-len

  const disableChannelCategoryAddition = false; // !scope.channelCategoryInfo[scope.channelCategoryInfo.length - 1].channelId;

  // eslint-disable-next-line max-len
  const handleScopeAutoCompleteChange = index => (value, fieldName) => onAutoCompleteChange(value, fieldName, index);

  const getCategoryListUnderChannel = channelId => categoryList.filter(category => category.Channel.id === channelId);

  const selectedChannelIdList = scope.channelCategoryInfo.map(channelCategory => channelCategory.channelId);

  const getDropDownForChannelList = channelId => channelList
    .filter(channel => channel.id === channelId || !selectedChannelIdList.includes(channel.id));

  return (
      <div className="scope">
          <Row>
            <Col md={12}>
              {
                    scope.channelCategoryInfo.map((channelCategory, index) => (
                      <Fragment>
                        {
                            index > 0 && (
                            <div className="config-promotion-panel-header">
                              {/* {''} */}
                              <div className="flex m-0">
                                <Icon
                                  iconName="times"
                                  primary
                                  onClick={() => onIconClick('remove', 'channelCategoryInfo', index)}
                                />
                              </div>
                            </div>)
                          }
                        <div className="config-catalog-wrap">
                          <Row key={`channel-category-${channelCategory.channelId}`}>
                            <Col md={4} className="mb-24">
                              <CustomSelect
                                enableValidation
                                className="custom-select"
                                placeholder="Channel"
                                labelContent="Channel"
                                getOptionValue={({ id }) => id}
                                getOptionLabel={({ title }) => title}
                                options={getDropDownForChannelList(channelCategory.channelId)}
                                onChange={event => onDropDownChange(event.id, index, 'channelCategoryInfo')}
                                value={channelList.filter(({ id }) => id === channelCategory.channelId)}
                              />
                            </Col>
                            <Col md={4} className="mb-24">
                              <AutoComplete
                                name="channelCategoryInfo"
                                labelContent="Category"
                                dropDownList={getCategoryListUnderChannel(channelCategory.channelId)}
                                placeholder="Search"
                                selectedDropDowns={[...channelCategory.categoryIds]}
                                onDropDownSelection={handleScopeAutoCompleteChange(index)}
                              />
                            </Col>
                            {/* <Col md={1}>
                            {
                            index > 0 && (
                              <div className="suggested-autocomplete-list">
                                <span
                                  className="ic-close"
                                  onClick={() => onIconClick('remove', 'channelCategoryInfo', index)}
                                >
                                  Close
                                </span>
                              </div>)
                          }
                          </Col> */}
                          </Row>
                        </div>
                      </Fragment>
                    ))
                  }
              <Row>
                <Col md={4} className="mt-24">
                  <div className={`filter-btn add ${disableChannelCategoryAddition ? 'disabled' : ''}`}>
                    <Button
                      iconBtnSmall
                      secondary
                      iconName="plus"
                      onClick={() => onIconClick('add', 'channelCategoryInfo')}
                    />
                  </div>
                </Col>
              </Row>

            </Col>
            <Col md={6} className="mt-24">
              <PromotionArea
                townList={townList}
                subDList={subDList}
                onDropDownChange={onDropDownChange}
                promotionAreas={scope.promotionAreas}
                onAutoCompleteChange={onAutoCompleteChange}
              />
            </Col>
          </Row>
          </div>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const PromotionDetailViewWithErrorAndLoading = withErrorAndLoadingScreen(View);

export default PromotionDetailViewWithErrorAndLoading;
