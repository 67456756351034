import {
  compose,
} from 'recompose';
import Detail from './Detail';
import withRequestTracker from '../../../../../utils/composition/withRequestTracker';
import {
  query,
  mutation,
  CREATE_CATALOG_LIST_DETAIL,
  UPDATE_CATALOG_LIST_DETAIL,
  TOGGLE_STATE,
} from './API';


const ComposedDetail = compose(
  CREATE_CATALOG_LIST_DETAIL,
  UPDATE_CATALOG_LIST_DETAIL,
  TOGGLE_STATE,
  withRequestTracker({
    query,
    mutation,
  }),
)(Detail);

export default ComposedDetail;
