import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { GET_DISTRIBUTORS } from '../../common/GqlAPI';

const STOCK_ADJUSTMENT_FRAGMENT = gql`
    fragment StockAdjustmentFragment on StockAdjustment {
        skuId: skuid
        distributorId: distributorid
        balance
        sku{
            title
        }
        reason
        lastAdjustment: lastadjustment
        lastDate: lastdate
        negative
        skuBatchId: skubatchid
    }
`;

const GET_STOCK_ADJUSTMENT_LIST = gql`
    query getStockAdjustmentList($offset: Int, $limit: Int, $filter: FilterInput) {
        stockAdjustments(offset: $offset, limit: $limit, filter: $filter){
            rows{
                ...StockAdjustmentFragment
            }
            count
        }
    }
    ${STOCK_ADJUSTMENT_FRAGMENT}
`;

const ADJUST_STOCK = graphql(gql`
    mutation ($input: StockAdjustmentInput!) {
        createStockAdjustment(input: $input)
        {
            ...StockAdjustmentFragment
        }
    } ${STOCK_ADJUSTMENT_FRAGMENT}
`, {
  props: ({ mutate }) => ({
    createStockAdjustment: variables => mutate({
      variables,
    }),
  }),
});

const query = {
  getDistributors: GET_DISTRIBUTORS,
  getStockAdjustmentList: GET_STOCK_ADJUSTMENT_LIST
};
const mutation = {
  createStockAdjustment: ADJUST_STOCK,
};

export { query, mutation, ADJUST_STOCK };
