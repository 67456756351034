import {
  compose,
} from 'recompose';
import StockLedger from './StockLedger';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  query, mutation, DOWNLOAD_REPORT,
} from './API';

const ComposedStockLedger = compose(
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  }),
)(StockLedger);

export default ComposedStockLedger;
