import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Calendar from 'react-date-range/lib/Calendar';
import Menu from '../Menu';
import DateToRender from './DateToRender';
import * as dateUtil from '../../utils/date';
import DateStringPicker from './DateStringPicker';
import DatePickerStyled from './DatePickerStyled';
import { Button } from '../index';

const propTypes = {
  date: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  displayDateString: PropTypes.bool,
};

const defaultProps = {
  displayDateString: false,
};

class DatePicker extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    return { date: nextProps.date };
  }

  // maintain separate state for user interaction;
  constructor(props) {
    super(props);
    this.state = {
      date: {
        start: props.date.start || dateUtil.getStartOfCurrentMonth(),
        end: props.date.end || dateUtil.getCurrentDay(),
      },
      stringDate: {
        title: '',
        show: false,
      },
      actualDate: false,
    };

    this.reference = {
      startDatePicker: React.createRef(),
      endDatePicker: React.createRef(),
    };
  }

  componentDidMount() {
    const { date } = this.state;
    this.setDateTitle(date);
  }

  setDateTitle = (date) => {
    const start = dateUtil.simplePresentor(date.start);
    const end = dateUtil.simplePresentor(date.end);
    const { dateConfiguration } = dateUtil;
    const checkDate = dateConfiguration.find(d => (start === d.date.start) && (end === d.date.end));
    const title = (checkDate && checkDate.title) || '';
    this.setState({
      stringDate: {
        title,
        show: true,
      },
      actualDate: !title,
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { date } = this.props;
    if (date !== prevProps.date) {
      this.setDateTitle(date);
    }
  };

  handleApplyClick = () => {
    const { date } = this.state;
    const { onChange } = this.props;
    onChange('date', date);
  };


  handleSelect = (selectedDate, label) => {
    const { date } = this.state;
    const formattedDate = { ...date };
    formattedDate[label] = dateUtil.normalPresentor({ ...selectedDate });
    date.start = formattedDate.start;
    date.end = formattedDate.end;

    this.setState({
      date,
      actualDate: true,
      stringDate: {
        show: false,
        title: '',
      },
    });
    this.reference.startDatePicker.current.close();
    this.reference.endDatePicker.current.close();
  };

  onDateStringClick = (value, title) => {
    const dateReplica = { ...value };
    const { date } = this.state;
    date.start = dateReplica.start;
    date.end = dateReplica.end;

    this.setState({
      date,
      stringDate: {
        title,
        show: true,
      },
      actualDate: false,
    }, () => {
      this.onDateRangeChange();
    });
  };

  onDateRangeChange = () => {
    const { onChange } = this.props;
    const { date } = this.state;
    onChange('date', date);
  };

  render() {
    const { date, actualDate, stringDate } = this.state;
    const { displayDateString } = this.props;

    const header = (
      <div className="datepicker-content">
        {actualDate ? <span className="date-actual"><DateToRender date={date} /></span> : null}
        {stringDate.show ? (
          <>
            {stringDate.title && (
            <span className="title">
              {stringDate.title}
            </span>
            ) }
          </>
        ) : ''}
        <span className="psr-icons date">Date</span>
      </div>
    );

    const startDate = (
      <div className="date-input">
        <span className="text">From</span>
        <div className="moment">
          {(
            <span className="date-from">
              {
            dateUtil.normalPresentor(date.start)
          }
            </span>
          ) }
          <span className="ic-date-icon" />
        </div>
      </div>
    );

    const endDate = (
      <div className="date-input">
        <span className="text">To</span>
        <div className="moment">
          {(
            <span className="date-to">
              {
            dateUtil.normalPresentor(date.end)
          }
            </span>
          ) }
          <span className="ic-date-icon" />
        </div>
      </div>
    );

    return (
      <DatePickerStyled>
        <div className="datepicker-wrapper">
          <Menu header={header}>
            <div className="datepicker-block">
              <DateStringPicker onClick={this.onDateStringClick} date={date} />
              <div className="date-input-wrap">
                <span>
                  <Menu
                    header={startDate}
                    hideOnClick
                    ref={this.reference.startDatePicker}
                  >
                    <Calendar
                      showMonthAndYearPickers
                      date={dateUtil.formatter(date.start)}
                      maxDate={dateUtil.formatter(date.end)}
                      onChange={
                      selectedDate => this.handleSelect(selectedDate, 'start')
                    }
                    />
                  </Menu>
                </span>
                <span>
                  <Menu
                    header={endDate}
                    hideOnClick
                    ref={this.reference.endDatePicker}
                  >
                    <Calendar
                      showMonthAndYearPickers
                      date={dateUtil.formatter(date.end)}
                      minDate={dateUtil.formatter(date.start)}
                      onChange={
                      selectedDate => this.handleSelect(selectedDate, 'end')
                    }
                    />
                  </Menu>
                </span>
                <span>
                  <Button
                    primary
                    small
                    onClick={() => this.handleApplyClick()}
                  >
                  Apply
                  </Button>
                </span>
              </div>
            </div>
          </Menu>
        </div>
      </DatePickerStyled>
    );
  }
}

DatePicker.propTypes = propTypes;

DatePicker.defaultPropTypes = defaultProps;

export default DatePicker;
