import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from '../../../components';
import Menu from '../../../components/Menu';
import DownloadStyled from './DownloadStyled';

const propTypes = {
  downloadConfig: PropTypes.objectOf({}),
  handleDownloadClick: PropTypes.func,
};

const defaultProps = {
  downloadConfig: {},
  handleDownloadClick: () => null,
};

const Download = ({ downloadConfig, handleDownloadClick }) => {
  const downloadClick = (type, domain) => {
    const downloadDomain = type === 'multipleDownload' ? domain : downloadConfig.domain;
    handleDownloadClick(
      downloadConfig.type,
      downloadDomain,
      downloadConfig.apiTitle,
    );
  };
  return (!Array.isArray(downloadConfig.domain)
    ? (
      <div>
        <Button
          secondary
          iconName="download"
          iconBtnSmall
          onClick={() => downloadClick('download', '')}
        />
      </div>
    ) : (
      <DownloadStyled>
        <Menu header={(
          <Button
            secondary
            iconName="download"
            iconBtnSmall
          />
)}
        >
          <ul>
            {
              downloadConfig.domain.map(domain => (
                <li key={domain} onClick={() => downloadClick('multipleDownload', domain)}>
                  <Icon iconName="download" />
                  {domain.replace(/_/g, ' ').toLowerCase()}
                </li>
              ))
            }
          </ul>
        </Menu>
      </DownloadStyled>
    ));
};

Download.propTypes = propTypes;

Download.defaultProps = defaultProps;

export default Download;
