import gql from 'graphql-tag';
import { graphql } from 'react-apollo';


const LEDGER_FRAGMENT = gql`
    fragment LedgerFragment on Customer {
        customerId
        title
    }
`;

const GET_LEDGERS = gql`
  query getLedgers($offset:Int, $limit: Int, $filter: FilterInput ){
      customers(offset: $offset, limit: $limit, filter: $filter){
          rows {
            ...LedgerFragment
          }
          count
      }
  }
  ${LEDGER_FRAGMENT}
`;

const SPLIT_PAYMENT = graphql(
  gql`
    mutation($id: Int!,$input:[PaymentSplitInput]!){
      splitPayment(id:$id, input:$input){
        id
        outletId
      }
    }
  `, {
    props: ({ mutate }) => ({
      splitPayment: variables => mutate({
        variables,
      }),
    }),
  },
);

const query = {
  getLedgers: GET_LEDGERS,
};

const mutation = {
  splitPayment: SPLIT_PAYMENT,
};

export {
  query, GET_LEDGERS, mutation, SPLIT_PAYMENT,
};
