import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import DetailForm from './Form';
import { customerMapper } from './config';
import { summaryDetailsMapper } from '../config';
import LEDGER from '../../../../../data/enums/Ledgers';
import { clone, compareList } from '../../../../../utils/arrayProcessor';
import { FORM_CONFIG } from '../../../../../data/enums/config';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import { refValidator } from '../../../../../utils/refGenerator';
import withAlert from '../../../../../utils/composition/withAlert';
import withLoading from '../../../../../utils/composition/withLoading';
import { debouncer, dropdownChange, inputChange } from '../../../../../utils/formHandlers';


const propTypes = {
  getDetails: PropTypes.func,
  getStatus: PropTypes.func,
  handleInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  handleDropDownChange: PropTypes.func,
  formConfig: PropTypes.shape({
    mapper: PropTypes.func,
    refsObj: PropTypes.instanceOf(Object),
    title: PropTypes.string,
    validationRequired: PropTypes.bool,
  }),
  serverResponseWaiting: PropTypes.bool,
  update: PropTypes.instanceOf(Object),
  vendorList: PropTypes.instanceOf(Array),
  distributorList: PropTypes.instanceOf(Array),
};

const defaultProps = {
  update: {
    type: '',
    status: false,
  },
  vendorList: [],
  distributorList: [],
  getStatus: () => null,
  getDetails: () => null,
  enableErrorDisplay: false,
  serverResponseWaiting: false,
  handleInputChange: () => null,
  handleDropDownChange: () => null,
  formConfig: {
    mapper: () => ({}),
    refsObj: {},
    title: '',
    validationRequired: true,
  },
};

class SummaryDetails extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { data } = nextProps;
    const { backupData } = prevState;

    if (!compareList(data, backupData)) {
      return {
        data: nextProps.data,
        backupData: nextProps.data,
      };
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      backupData: props.data,
      data: clone(props.data),
      invoiceNumberList: [],
      customerList: [],
      loadingInvoiceStatus: false,
      /* invoiceNumberStatus: false, */

    };
  }


  componentDidMount() {
    const { getStatus, getDetails } = this.props;
    getStatus(this.getValidationStatus);
    getDetails(this.exportData);
  }

  componentDidUpdate(prevProps) {
    const { data, outletList } = this.props;
    const { customerList } = this.state;
    if (data.RetailOutlet.id && !customerList.length && outletList.length) {
      if (data.RetailOutlet.id !== prevProps.data.RetailOutlet.id) {
        this.updateCustomerList(data.RetailOutlet.id);
      }
    }
  }

  getInvoiceNumberList = (salesInvoiceNumber) => {
    const { data, invoiceNumberList } = this.state;
    const { onInvoiceNumberChange, getInvoiceNumberList, displayAlert } = this.props;
    if (!data.Distributor.id) {
      displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Distributor is not selected');
      return 0;
    }
    if (salesInvoiceNumber.length >= 3) {
      let exactlyMatched = invoiceNumberList.indexOf(salesInvoiceNumber) > -1;
      if (!exactlyMatched) {
        this.setState({ loadingInvoiceStatus: true });
        getInvoiceNumberList({
          invoiceString: salesInvoiceNumber,
          distributorId: data.Distributor.id,
        }, {
          handleSuccess: (response) => {
            exactlyMatched = response.data.searchInvoiceNumber.matched;
            this.setState({
              invoiceNumberList: response.data.searchInvoiceNumber.invoiceNumbers
                || [],
              loadingInvoiceStatus: false,
            },
            () => onInvoiceNumberChange(salesInvoiceNumber, exactlyMatched));
          },
          handleError: (error) => {
            this.setState({ invoiceNumberList: [], loadingInvoiceStatus: false },
              () => onInvoiceNumberChange(salesInvoiceNumber, exactlyMatched));
            displayAlert(ALERT_TYPE.DANGER, error);
          },
        });
      } else {
        onInvoiceNumberChange(salesInvoiceNumber, exactlyMatched);
      }
    } else {
      this.setState({ invoiceNumberList: [], loadingInvoiceStatus: false },
        () => onInvoiceNumberChange(salesInvoiceNumber, false));
    }
  };

  resetData = (updatedData, callBack) => {
  //  const { data } = this.state;
    const refreshedData = summaryDetailsMapper({
      invoiceNumber: updatedData.salesInvoiceNumber,
      Distributor: { id: updatedData.Distributor.id },
    });
    this.setState({ data: refreshedData },
      () => debouncer(callBack, 300)(updatedData.salesInvoiceNumber));
  };

  updateCustomerList = (outletId) => {
    const { outletList, distributorServices } = this.props;
    const selectedOutlet = outletList.find(outlet => outlet.id === outletId);

    if (selectedOutlet) {
      let customerList = selectedOutlet.Customers || [];
      customerList = customerList.filter(item => item.id !== LEDGER.CASH.id);
      if (!distributorServices.billing.status) {
        customerList.push(LEDGER.CASH);
      }
      this.setState({ customerList });
    }
  };

  handleInputChange = (event, firstParam = '', paramList = []) => {
    const { data } = this.state;

    const updatedData = inputChange(data, event, firstParam, paramList);
    switch (event.target.name) {
      case 'salesInvoiceNumber':
        this.resetData(updatedData, this.getInvoiceNumberList);
        break;
      default:
        this.setState({ data: updatedData });
        break;
    }
  };

  filterItemFromList = (list, value, expectedDefaultValue, returnValueKey, key = 'id') => {
    const item = list.filter(item => item[key] === value)[0];
    const k = returnValueKey ? item[returnValueKey] || expectedDefaultValue : item;

    return k;
  };

  handleDropDownChange = (value, parameterRef = [], name, selectedObj = {}) => {
    const { data } = this.state;
    const { updateDistributorServices } = this.props;
    const updatedData = dropdownChange(data, parameterRef, value);
    switch (name) {
      case 'outlet':
        updatedData.Customer = customerMapper({});
        this.setState({ data: updatedData }, () => this.updateCustomerList(updatedData.RetailOutlet.id));
        break;
      case 'customer':
        this.handleCustomerChange(updatedData.Customer.id);
        break;
      case 'distributor':
        updatedData.Customer = customerMapper({});
        this.setState({ data: updatedData }, () => updateDistributorServices(selectedObj));
        break;
      default:
        this.setState({ data: updatedData });
        break;
    }
  };


  handleCustomerChange = (customerId) => {
    const { customerList, data } = this.state;
    data.Customer = this.filterItemFromList(customerList, customerId, null) || customerMapper({});
    this.setState({ data });
  };

  getValidationStatus = () => {
    const { formConfig } = this.props;
    /* const { data } = this.state;
    const m = Object.values(formConfig[FORM_CONFIG.REFS_OBJ]);
    const k = m.find(item => (item.getValidState() === false)); */

    return refValidator(formConfig[FORM_CONFIG.REFS_OBJ]);
    // return (!Object.values(formConfig[FORM_CONFIG.REFS_OBJ]).find(item => item.getValidState() === false));
  };

  exportData = () => {
    const { data } = this.state;
    return data;
  };


  render() {
    const {
      subDUser,
      formConfig,
      outletList,
      enableErrorDisplay,
      distributorList,
      loading,
      distributorServices,
      invoiceNumberStatus,
    } = this.props;
    const {
      data,
      loadingInvoiceStatus,
      customerList,
      invoiceNumberList,
    } = this.state;

    return (
      <Fragment>
        <DetailForm
          show
          subDUser={subDUser}
          data={data}
          customerList={customerList}
          enableErrorDisplay={enableErrorDisplay}
          handleInputChange={this.handleInputChange}
          handleDropDownChange={this.handleDropDownChange}
          outletList={outletList}
          distributorList={distributorList}
          refsObj={formConfig[FORM_CONFIG.REFS_OBJ]}
          invoiceNumberList={invoiceNumberList}
          distributorServices={distributorServices}
          invoiceNumberStatus={invoiceNumberStatus}
          loading={loading}
          loadingInvoiceStatus={loadingInvoiceStatus}
        />
      </Fragment>
    );
  }
}

SummaryDetails.propTypes = propTypes;

SummaryDetails.defaultProps = defaultProps;

export default withAlert()(withLoading(SummaryDetails));
