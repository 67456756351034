import { EVENT_OPERATION } from '../../../../../../data/enums/EventOperation';
import { getFilterConfig } from '../../../../../../utils/filter';
import { ORDER_HISTORY_VIEW } from '../../../../../../data/enums/GraphQL';

const crudRequest = {
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: data => ({
      id: data.id,
      quantity: data.quantity,
    }),
    responseName: 'updateOrder',
    message: 'Order updated successfully.',
  },

  [EVENT_OPERATION.DELETE]: {
    objectMapper: data => ({
      orderIds: [data.id],
      reason: data.reason,
    }),
    responseName: 'cancelOrders',
    message: 'Order updated successfully.',
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper1: data => ({
      orderIds: data.ids,
      reason: data.reason,
    }),
    objectMapper: data => ({
      orderIds: data.ids,
      reason: data.reason,
    }),
    responseName: 'cancelOrders',
    message: 'Orders cancelled successfully.',
  },
  [EVENT_OPERATION.SPLIT]: {
    objectMapper: data => ({
      quantity: data.splitList.map(item => item.quantity),
      id: data.id,
    }),
    responseName: 'splitOrder',
    message: 'Orders splitted successfully.',
  },
};

const filterBasedOnView = {
  [ORDER_HISTORY_VIEW.BRAND]: [],
  [ORDER_HISTORY_VIEW.DATE]: [],
};

const computeFilterConfig = type => (getFilterConfig([...(filterBasedOnView[type] || [])]));


const newList = [
  { id: 1, label: 'BRAND' },
  { id: 2, label: 'DATE' },
];

export { crudRequest, computeFilterConfig, newList };

export default crudRequest;
