import React from 'react';
import PropTypes from 'prop-types';

import withErrorAndLoadingScreen from '../../../../../utils/composition/withLoading';

import {
  CustomSelect, Input, Row, Col,
} from '../../../../../components';

const propTypes = {
  criteria: PropTypes.shape({
    dimension: PropTypes.string,
    operation: PropTypes.shape({
      max: PropTypes.shape({
        value: PropTypes.number,
        operator: PropTypes.string,
      }),
      min: PropTypes.shape({
        value: PropTypes.number,
        operator: PropTypes.string,
      }),
    }),
    skuCount: PropTypes.number,
    type: PropTypes.string,
  }).isRequired,
  onDropDownChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  customGroupStatus: PropTypes.bool.isRequired,
};

const defaultProps = {};

const promotionCriteriaOperation = [
  {
    id: 1,
    title: '> GREATER_THAN',
    value: 'GREATER_THAN',
  },
  {
    id: 2,
    title: '>= GREATER_THAN_EQUALS',
    value: 'GREATER_THAN_EQUALS',
  },
  {
    id: 3,
    title: '< LESS_THAN',
    value: 'LESS_THAN',
  },
  {
    id: 4,
    title: '<= LESS_THAN_EQUALS',
    value: 'LESS_THAN_EQUALS',
  },
  {
    id: 5,
    title: '= EQUALS',
    value: 'EQUALS',
  },
];

const promotionCriteriaDimension = [
  {
    id: 1,
    title: 'Quantity',
    value: 'QUANTITY',
  },
  {
    id: 2,
    title: 'Amount',
    value: 'AMOUNT',
  },
  {
    id: 3,
    title: 'Count',
    value: 'COUNT',
  },
  {
    id: 4,
    title: 'Count Multiple',
    value: 'COUNT_MULTIPLE',
  },
  {
    id: 5,
    title: 'Group Count',
    value: 'GROUP_COUNT',
  },
  {
    id: 6,
    title: 'Group Count Multiple',
    value: 'GROUP_COUNT_MULTIPLE',
  },
];

const promotionType = [
  {
    id: 1,
    title: 'Normal',
    value: 'NORMAL',
  },
  {
    id: 2,
    title: 'Current Bill',
    value: 'CURRENT_BILL',
  },
  {
    id: 3,
    title: 'Next Bill',
    value: 'NEXT_BILL',
  },
  {
    id: 4,
    title: 'Direct Disbursement',
    value: 'DIRECT_DISBURSEMENT',
  },
  {
    id: 5,
    title: 'Top Up',
    value: 'TOP_UP',
  },
];


const View = ({ ...props }) => {
  const { criteria, onDropDownChange, onInputChange, customGroupStatus } = props;

  const filterdPromotionCriteiraDimension = customGroupStatus ? promotionCriteriaDimension : promotionCriteriaDimension.filter(
    criterion => criterion.value !== 'GROUP_COUNT' && criterion.value !== 'GROUP_COUNT_MULTIPLE',
  );

  return (
        <div className="condition">
          <Row>
            <Col md={12}>
              <Row>
                <Col md={3}>
                  <CustomSelect
                    enableValidation
                    options={promotionType}
                    className="custom-select"
                    placeholder="Select Type"
                    labelContent="Promotion Type"
                    getOptionValue={({ id }) => id}
                    getOptionLabel={({ title }) => title}
                    enableErrorDisplay
                    onChange={event => onDropDownChange(event.value, ['type'])}
                    value={promotionType.filter(({ value }) => value === criteria.type)}
                  />
                </Col>
                <Col md={3}>
                  <CustomSelect
                    enableValidation
                    options={filterdPromotionCriteiraDimension}
                    className="custom-select"
                    placeholder="Select Dimension"
                    labelContent="Promotion Condition"
                    getOptionValue={({ id }) => id}
                    getOptionLabel={({ title }) => title}
                    enableErrorDisplay
                    onChange={event => onDropDownChange(event.value, ['dimension'])}
                    value={promotionCriteriaDimension.filter(({ value }) => value === criteria.dimension)}
                  />
                </Col>
                <Col md={3}>
                  <CustomSelect
                    enableValidation
                    options={promotionCriteriaOperation}
                    labelContent="Criteria"
                    className="custom-select"
                    placeholder="Select Dimension"
                    getOptionValue={({ id }) => id}
                    getOptionLabel={({ title }) => title}
                    enableErrorDisplay
                    onChange={event => onDropDownChange(event.value, ['operation', 'max', 'operator'])}
                    value={promotionCriteriaOperation.filter(({ value }) => value === criteria.operation.max.operator)}
                  />
                </Col>
                <Col md={3}>
                  <Input
                    name="disbursement"
                    type="number"
                    enableValidation
                    value={criteria.operation.max.value}
                    labelContent="Value"
                    onChange={event => onInputChange(event, 'operation', ['operation', 'max', 'value'])}
                  />
                </Col>
                {/*<Col md={2}>
                  <div className="status-switch">
                      <Switch
                        onToggle={(checked, event) => {
                          onInputChange(
                            {
                              formattedValue: !!checked,
                              target: {
                                name: 'allowMultiple',
                                type: 'boolean',
                                value: !!checked,
                              },
                            },
                          );
                        }
                        }
                        checked={criteria.allowMultiple}
                        name="allowMultiple"
                        labelContent="Multiply Disbursement"
                      />
                    </div>
                </Col>*/}
              </Row>
            </Col>
          </Row>
        </div>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const ViewWithErrorAndLoading = withErrorAndLoadingScreen(View);

export default ViewWithErrorAndLoading;
