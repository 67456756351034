import Detail from './Detail';

import CreateRoute from './General';
import {
  compose,
} from 'recompose';
import withRequestTracker from './../../../../utils/composition/withRequestTracker';
import {
  query,
} from './General/API';

const ComposedDetail = compose(
  withRequestTracker({
    query
  }),
)(Detail);

export default ComposedDetail;


export { CreateRoute };

