import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../../data/enums/SuccessMessage';
import { FORM_CONFIG } from '../../../../data/enums/config';
import { refGenerator } from '../../../../utils/refGenerator';
import { SKU } from '../../../../data/enums/Route';
import { breadCrumbConfig } from '../config';
import { hyphenPresentor } from '../../../../utils/date';

const title = 'Create SKU';

const breadCrumb = [
  ...breadCrumbConfig,
  {
    id: 1,
    title: 'SKU',
    path: `/${SKU}`,
    active: true,
  },
];

const formConfig = {
  mapper: element => ({
    id: element.id || 0,
    title: element.title || '',
    powerSku: element.powerSku || false,
    description: element.description || '',
    details: {
      ean: element.details ? element.details.ean || 0 : 0,
      gtin: element.details ? element.details.gtin || 0 : 0,
      skuCode: element.details ? element.details.skuCode : '',
      unitsPerCase: element.details ? element.details.unitsPerCase : 0,
      standardUnit: element.details ? element.details.standardUnit : 0,
      packagingUnit: element.details ? element.details.packagingUnit : '',
      minimumOrderQuantity: element.details ? element.details.minimumOrderQuantity : 0,
      erpCode: element.details ? element.details.erpCode : '',
      skuProductCode: element.details ? element.details.skuProductCode : '',

    },
    Rates: element.Rates ? element.Rates : [],
    catalogDetails: element.catalogDetails ? element.catalogDetails : [],
    dimension: {
      length: element.dimension ? element.dimension.length : null,
      width: element.dimension ? element.dimension.width : null,
      height: element.dimension ? element.dimension.height : null,
    },
    weight: element.weight || null,
    uom: element.uom || '',
    images: element.images ? element.images : [],
    skuTags: element.skuTags ? element.skuTags : [],
    shelfLife: element.shelfLife || '',
    shortDescription: element.shortDescription || '',
    caseUnit: element.caseUnit || '',
    caseReportingUnit: element.caseReportingUnit || '',
    oneTimePurchaseQuantity: element.oneTimePurchaseQuantity || 0,
    oneTimePurchase: element.oneTimePurchase || false,
  }),

  validationField: ['title', 'manufactureDescription', 'upc'],
  validationRequired: true,
};

const rateFormMapper = element => ({
  batchDetails: {
    batchNumber: element.batchDetails ? element.batchDetails.batchNumber : '',
  },
  priceDetails: {
    rlp: element.priceDetails ? element.priceDetails.rlp : 0,
    dlp: element.priceDetails ? element.priceDetails.dlp : 0,
    vatPercentage: element.priceDetails ? element.priceDetails.vatPercentage : 0.13,
    mrpStatus: element.priceDetails ? element.priceDetails.mrpStatus : true,
    mrpSrp: element.priceDetails ? element.priceDetails.mrpSrp : 0,
  },
  usageDate: {
    manufacture: element.usageDate ? element.usageDate.manufacture : hyphenPresentor(new Date().toDateString()),
    expiry: element.usageDate ? element.usageDate.expiry : hyphenPresentor(new Date().toDateString()),
  },
});

const rateFormConfig = {
  [EVENT_OPERATION.UPDATE]: {
    [FORM_CONFIG.MAPPER]: element => rateFormMapper(element),
    [FORM_CONFIG.TITLE]: `${EVENT_OPERATION.UPDATE} SKU Batch/Rate`,
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['batchNumber', 'rlp', 'dlp']),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
  [EVENT_OPERATION.CREATE]: {
    [FORM_CONFIG.MAPPER]: element => rateFormMapper(element),
    [FORM_CONFIG.TITLE]: 'Add SKU Batch/Rate',
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['batchNumber', 'rlp', 'dlp']),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
};

const changedData = data => ({
  ean: data.details ? data.details.ean || 0 : 0,
  gtin: data.details ? data.details.gtin || 0 : 0,
  skuCode: data.details ? data.details.skuCode : '',
  unitsPerCase: data.details ? data.details.unitsPerCase : 0,
  standardUnit: data.details ? Number(data.details.standardUnit) : 0,
  packagingUnit: data.details ? data.details.packagingUnit : '',
  minimumOrderQuantity: data.details ? data.details.minimumOrderQuantity : 0,
  erpCode: data.details ? data.details.erpCode : '',
  skuProductCode: data.details ? data.details.skuProductCode : '',
});

const crudSuccess = {
  [EVENT_OPERATION.CREATE]: {
    objectMapper: (data) => {
      const catalogDetails = data.catalogDetails.map(item => ({
        catalogId: item.Catalog.id,
        title: item.CatalogDetail.title,
        catalogDetailId: item.CatalogDetail.id,
      }));
      data.details.ean = (data.details.ean || 0).toString();
      data.details.gtin = (data.details.gtin || 0).toString();
      const modifiedData = {
        ...data,
        details: changedData(data),
        catalogDetails,
      };
      const { id, ...structuredData } = modifiedData;
      return { input: structuredData };
    },
    responseName: 'createSKU',
    message: `SKU ${MESSAGE_EVENT_OPERATION.CREATE}`,
  },
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: (data) => {
      const catalogDetails = data.catalogDetails.map(item => ({
        catalogId: item.Catalog.id,
        title: item.CatalogDetail.title,
        catalogDetailId: item.CatalogDetail.id,
      }));
      data.details.ean = (data.details.ean || 0).toString();
      data.details.gtin = (data.details.gtin || 0).toString();
      const modifiedData = {
        ...data,
        details: changedData(data),
        catalogDetails,

      };
      const { id, ...structuredData } = modifiedData;
      return {
        id: modifiedData.id,
        input: structuredData,
      };
    },
    responseName: 'updateSKU',
    message: `SKU ${MESSAGE_EVENT_OPERATION.UPDATE}`,
  },
};

const MASTER_DATA_TYPES = {
  SKU_TAGS: 'SKU_TAGS',
  CASE_UNIT: 'CASE_UNIT',
};

const SHELF_LIFE_REQUIRED_TAGS = {
  NON_FOOD: 'Non-Food',
};

export {
  crudSuccess, breadCrumb as breadCrumbConfig, formConfig, rateFormConfig, title, MASTER_DATA_TYPES,
  SHELF_LIFE_REQUIRED_TAGS,
};
