import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Button, CheckBox, Icon, Input, Switch,
} from '../../../components';
import { SingleDatePicker } from '../../../components/DatePicker';
import withErrorAndLoading from '../../../utils/composition/withLoading';
import FormContentStyled from '../../../components/global-css/FormContentStyled';

const propTypes = {
  holidayData: PropTypes.shape({}).isRequired,
  week: PropTypes.array.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleDateRangeChange: PropTypes.func.isRequired,
  handleCancelHoliday: PropTypes.func.isRequired,
  handleHolidaysAdd: PropTypes.func.isRequired,
};

const View = ({
  holidayData, week, handleInputChange, handleDateRangeChange, handleCancelHoliday,
  handleHolidaysAdd, toggleToReplicate, replicateHoliday, replicate,
}) => (
  <div className="subd-holiday-wrap">
    {replicateHoliday && (
      <FormContentStyled>
        <div className="form-input-wrap padding-top-zero">
          <Switch
            checked={holidayData.replicate}
            labelContent={'Replicate BU\'s Holidays'}
            onToggle={toggleToReplicate}
          />
          <span>Enabling Replicate will replace your settings with BU’s Settings.</span>
        </div>
      </FormContentStyled>
    )}
    <FormContentStyled>
      <h3 className="w-60">Holidays Count</h3>
      <div className="form-input-wrap">
        <Input
          value={holidayData.holidayCount.sick || ''}
          name="sick"
          type="number"
          rule="matches"
          labelContent="Sick Leaves"
          argument={
              /\b(^[\w -]*)\b/
            } /* matches alphanumeric, underscore ,'-' & ' ' */
          placeholder="Sick Leaves"
          enableErrorDisplay={false}
          enableValidation
          onChange={event => handleInputChange(event, 'sick', ['holidayCount', 'sick'])}
        />
        <Input
          value={holidayData.holidayCount.casual || ''}
          name="casual"
          type="number"
          rule="matches"
          labelContent="Casual Leaves"
          argument={
              /\b(^[\w -]*)\b/
            } /* matches alphanumeric, underscore ,'-' & ' ' */
          placeholder="Casual Leaves"
          enableErrorDisplay={false}
          enableValidation
          onChange={event => handleInputChange(event, 'casual', ['holidayCount', 'casual'])}
        />
        <Input
          value={holidayData.holidayCount.home || ''}
          name="home"
          type="number"
          rule="matches"
          labelContent="Home Leaves"
          argument={
              /\b(^[\w -]*)\b/
            } /* matches alphanumeric, underscore ,'-' & ' ' */
          placeholder="Home Leaves"
          enableErrorDisplay={false}
          enableValidation
          onChange={event => handleInputChange(event, 'home', ['holidayCount', 'home'])}
        />
        <Input
          value={holidayData.holidayCount.unpaid || ''}
          name="unpaid"
          type="number"
          rule="matches"
          labelContent="Paid Leaves"
          argument={
              /\b(^[\w -]*)\b/
            } /* matches alphanumeric, underscore ,'-' & ' ' */
          placeholder="Paid Leaves"
          enableErrorDisplay={false}
          enableValidation
          onChange={event => handleInputChange(event, 'unpaid', ['holidayCount', 'unpaid'])}
        />
      </div>
      <div className="holiday-status-checkbox form-input-wrap padding-top-zero w-60">
        <label>Weekend Holiday</label>
        <div className="checkbox-list">
          {week.map((d, index) => (
            <CheckBox
              key={d}
              checked={holidayData.weekendDetails.includes(d.toUpperCase())}
              name={d}
              className="checkbox"
              labelText={d.slice(0, 3).toUpperCase()}
              onCheckBoxClick={(e) => {
                handleInputChange(
                  {
                    formattedValue: d.toUpperCase(),
                    target: {
                      name: index,
                      value: d.toUpperCase(),
                      checked: !!e.target.checked,
                    },
                  },
                  'weekendDetails',
                  [],
                );
              }}
            />
          ))}
        </div>
      </div>
    </FormContentStyled>

    <FormContentStyled>
      <h3 className="w-60">Public Holidays</h3>
      <div className="measurement-wrap  padding-bottom-zero form-input-wrap w-60">
        <div className="form-input margin-bottom-zero">
          <ul className="list-title">
            <li className="medium"><span>Title</span></li>
            <li><span>Start Date</span></li>
            <li><span>End Date</span></li>
            <li><span>Days</span></li>
          </ul>
        </div>
        <div>
          {holidayData.holidayDetails.map((holiday, index) => (
            <div className="list-content" key={index}>
              <label className="medium custom-control-label-text">
                <Input
                  type="text"
                  name="title"
                  placeholder="title"
                  value={holiday.title}
                  onChange={event => handleInputChange(event,
                    'holidayDetails', ['holidayDetails', [index], 'title'])}
                />
              </label>
              <label className=" custom-control-label-text">
                <SingleDatePicker
                  date={holiday.start.toString()}
                  name="start"
                  onChange={(name, date) => {
                    handleDateRangeChange(name, date, index);
                  }}
                />
              </label>
              <label className="custom-control-label-text">
                <SingleDatePicker
                  date={holiday.end.toString()}
                  name="end"
                  onChange={(name, date) => {
                    handleDateRangeChange(name, date, index);
                  }}
                />
              </label>
              <label className="small custom-control-label-text">
                <span>{moment(holiday.end).diff(moment(holiday.start), 'days') + 1}</span>
              </label>
              <Icon iconName="times" onClick={() => handleCancelHoliday(holiday.title)} />
            </div>
          ))}
        </div>
      </div>
      <Button small secondary onClick={() => handleHolidaysAdd()}>
        <Icon iconName="plus" />
        <span>Add</span>
      </Button>
    </FormContentStyled>
  </div>
);

View.propTypes = propTypes;

const HolidayInfo = withErrorAndLoading(View);

export default HolidayInfo;
