import {
  compose,
} from 'recompose';
import Promotion from './Promotion';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  query, mutation, TOGGLE_STATE, CREATE_PROMOTION, UPLOAD, REPLICATE_PROMOTION, DOWNLOAD_REPORT, REPLICATE_PROMOTIONS,
} from './API';

const ComposedPromotion = compose(
  CREATE_PROMOTION,
  REPLICATE_PROMOTION,
  TOGGLE_STATE,
  UPLOAD,
  DOWNLOAD_REPORT,
  REPLICATE_PROMOTIONS,
  withRequestTracker({
    query,
    mutation,
  }),
)(Promotion);

export default ComposedPromotion;
