import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import withLoading from '../../../utils/composition/withLoading';
import { dataProps, paginationProps } from '../../../data/enums/Component';
import { PanelStyled } from '../../common/configuration';
import { Pagination } from '../../../components';
import CustomSelect from '../../../components/CustomSelect';
import StockLedgerStyled from './StockLedgerStyled';
import { TableBody, TableHeader } from './tableConfig';

const propTypes = {
  data: dataProps.value,
  onIconClick: PropTypes.func,
  onPageChange: PropTypes.func,
  pagination: paginationProps.value,
  breadCrumb: PropTypes.instanceOf(Array),
};

const defaultProps = {
  data: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
  onPageChange: () => null,
  onIconClick: () => null,
  breadCrumb: [],
};

const View = ({ ...props }) => {
  const {
    data,
    onPageChange,
    pagination,
    breadCrumb,
    componentDisplay,
    distributorList,
    onDropDownChange,
    enableErrorDisplay,
    inputData,
    onHeaderClick,
  } = props;

  return (
    <StockLedgerStyled>
      <Fragment>
        {
          componentDisplay.distributorSelection && (
            <PanelStyled>
              <div>
                <CustomSelect
                  small
                  enableValidation
                  options={distributorList}
                  className="custom-select"
                  placeholder="Select Distributor"
                  getOptionValue={({ id }) => id}
                  getOptionLabel={({ title }) => title}
                  enableErrorDisplay={enableErrorDisplay}
                  onChange={event => onDropDownChange(event.id)}
                  value={distributorList.filter(({ id }) => id === inputData.distributorId) || []}
                />
              </div>
            </PanelStyled>
          )
        }
        {data.list.length > 0 && (
        <div className="table-wrap">
          <table>
            {TableHeader({ onHeaderClick })}
            <tbody>
              {data.list.map(stockLedger => (
                TableBody({
                  data: stockLedger,
                })
              ))}
            </tbody>
          </table>
        </div>
        )}
        { data.list.length > 0 && (
        <Pagination
          currentPage={pagination.page}
          limit={pagination.limit}
          totalItems={data.total}
          onPageChange={onPageChange}
        />
        )}
      </Fragment>
    </StockLedgerStyled>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const ViewWithLoading = withLoading(View);

export default ViewWithLoading;
