import gql from 'graphql-tag';

const DISTRIBUTOR_GROUP_FRAGMENT = gql`
fragment DistributorGroupFragment on DistributorGroup {
    id
    title
    active
}`;

const GET_SUB_D_ASSORTMENT = gql`
    query subdAssortment($offset: Int, $limit: Int, $filter: FilterInput) {
        distributorGroups(offset: $offset, limit: $limit, filter: $filter) {
           rows {
               ...DistributorGroupFragment
           }
            count
        }
    }
    ${DISTRIBUTOR_GROUP_FRAGMENT}
`;

const query = {
  getSubDAssortmentList: GET_SUB_D_ASSORTMENT,
};
const mutation = {

};

export { query, mutation, GET_SUB_D_ASSORTMENT };
