import APP_SETTINGS from '../settings/app.json';

export const APP = APP_SETTINGS;

export {
  JWT, AUTH, CLIENT_AUTH_REQUEST_TYPE, CLIENT_AUTHENTICATION_METHOD,
} from './auth';

export { VERSION, RESPONSE_CODE } from './enums';

export { LOGIN_URL, ERROR_URL, LOGIN_SUCCESS_BASE_URL } from './route';

const IMAGE_SERVER = `${APP.IMAGE.ENDPOINT.PROTOCOL}://${APP.IMAGE.ENDPOINT.HOST}`;

const IMAGE_SERVER_TOKEN = `${APP.IMAGE.TOKEN}`;

export { IMAGE_SERVER, IMAGE_SERVER_TOKEN };

const BU = 'pg';

export { BU };
