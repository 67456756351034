import {
  compose,
} from 'recompose';
import Roster from './Roster';
import withRequestTracker from '../../../utils/composition/withRequestTracker';

import {
  query,
  mutation,
  UPDATE_ROSTER,
  CREATE_ROSTER,
  TOGGLE_STATE,
  REPLICATE_ROSTER,
} from './API';

const ComposedDSERoster = compose(
  UPDATE_ROSTER,
  CREATE_ROSTER,
  TOGGLE_STATE,
  REPLICATE_ROSTER,
  withRequestTracker({
    query,
    mutation,
  }),
)(Roster);

export default ComposedDSERoster;
