import React from 'react';
import PropTypes from 'prop-types';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { menuAction, tableData } from '../../../../utils/paginatedListUtils';
import { PERMISSION_OBJ } from '../../../../data/enums/Permission';

const labelMappings = {
  id: 'id',
  name: 'name',
  displayName: 'displayName',
  actionHolders: 'actionHolders',
};

const menuConfigList = [
  {
    title: 'Edit',
    icon: 'pencil',
    permissionDerivedBy: 'update',
    type: EVENT_OPERATION.UPDATE,
  },
];

const labelConfig = [
  {
    id: 1,
    label: labelMappings.id,
    title: 'ID',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.name,
    title: 'Name',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.displayName,
    title: 'Display Name',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.actionHolders,
    title: '',
    show: true,
  },
];

const TableHeader = () => (
  <thead className="">
    <tr>{labelConfig.map(label => (label.show ? tableData(label.title, label.align) : null))}</tr>
  </thead>
);

const returnTableBody = (data, onIconClick, permission) => {
  const table = {};
  table[labelMappings.id] = (
    <td key={`user-role-${data.id}-id`}>{data.id}</td>
  );
  table[labelMappings.name] = (
    <td key={`user-role-${data.id}-name`}>{data.name}</td>
  );
  table[labelMappings.displayName] = (
    <td key={`user-role-${data.id}-displayName`}>{data.displayName}</td>
  );
  table[labelMappings.actionHolders] = (
    <td
      key={`user-role-${data.id}-actionHolders`}
      className="text-right simple-popup-actions"
      onClick={e => e.stopPropagation()}
    >
      {menuAction(menuConfigList, { menuIcon: 'ellipsis-v' }, onIconClick, data, permission)}
    </td>
  );

  return table;
};

const TableBody = ({
  data, onIconClick, permission,
}) => {
  // Expanding/Dragging and collapsing table body.
  // color based upon table body
  // tooltip if it is sorted.
  // active className
  const tableBody = returnTableBody(data, onIconClick, permission);

  return (
    <tr key={`user-role-${data.id}-details`}>
      {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
  onIconClick: PropTypes.func.isRequired,
  permission: PropTypes.instanceOf(Object),
};

TableBody.defaultProps = {
  data: {},
  permission: PERMISSION_OBJ,
};

export {
  TableHeader,
  TableBody,
};
