import React from 'react';
import TitleText from '../../../components/Chart/TitleText';
import { getDataItem, WORKING_STATUS } from '../config';

const UserStatus = ({
  data = {}, label, id, dimensions,
}) => (
  <div className="status-wrap">

    {
        Object.keys(data).map((d, i) => (
          // <div style={{ display: 'auto' }} key={d}>

          <TitleText
            id={`${id}-i`}
            dimensions={dimensions}
            styleAdjustment={{ marginTop: 5 }}
            data={getDataItem(data[d], WORKING_STATUS[d])}
          />

        ))
      }

  </div>

);

export default UserStatus;
