import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import OpeningBalanceView from './View';
import { getUser } from '../../../data/services';
import PageHeader from '../../base/PageHeader';
import { DialogFormWrapper } from '../../common';
import CSVUploadForm from '../../common/CSVUploadForm';
import * as queryService from '../../base/query.service';
import { PanelStyled } from '../../common/configuration';
import { FORM_CONFIG } from '../../../data/enums/config';
import { openingBalance } from '../../common/DomainConfig';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { ADMIN_ROLE_GROUP, DISTRIBUTOR_ASSOCIATED_ROLES } from '../../../data/enums/UserRole';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { getPermissionForOpeningBalance } from '../../base/permission';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';
import {
  formConfig, crudRequestConfig, breadCrumbConfig, filterConfig,
} from './config';
import { crudResponseProcessor, handleFormSubmit } from '../../../utils/crudResponseProcessor';
import { isError, getSortValueAfterValueCheck } from '../../common/HelperFunctions';
import OpeningBalanceStyled from './OpeningBalanceStyle';
import { checkAndResetPaginatedData } from '../../../utils/paginatedListUtils';
import { Icon } from '../../../components';
import { PROGRAM } from '../../../data/enums/Permission';

const propTypes = {
  upload: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  downloadReport: PropTypes.func.isRequired,
  getDistributors: PropTypes.func.isRequired,
  getOpeningBalanceList: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class OpeningBalance extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.userInfo = getUser();
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      display: {
        searchBox: false,
        upload: true,
      },
      distributorList: [],
      dialog: {
        type: '',
        element: formConfig[FORM_CONFIG.MAPPER]({
          distributorId: this.userInfo.Distributor.length > 0 ? this.userInfo.Distributor[0].id : 0,
        }),
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: {},
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
      },
    };
    const serverCall = {
      [EVENT_OPERATION.UPLOAD]: props.upload,
    };
    this.setData = (data) => {
      this.setState({ data });
    };
    this.permission = getPermissionForOpeningBalance();
    this.componentConfig = openingBalance.componentsDisplay(this.permission);
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      props.downloadReport,
      props.displayAlert,
    );
    this.onCRUDSuccess = crudResponseProcessor((data, type) => {
      const { displayAlert } = this.props;
      displayAlert(ALERT_TYPE.SUCCESS, data);
      this.loadTableData();
    });
    this.onFormSubmit = handleFormSubmit(
      this.onCRUDSuccess, this.onAPIRequestFailure, crudRequestConfig, serverCall,
    );
  }

  componentDidMount() {
    const { dialog } = this.state;
    if (ADMIN_ROLE_GROUP.includes(this.userInfo.roleId)
      || DISTRIBUTOR_ASSOCIATED_ROLES.includes(this.userInfo.roleId)) {
      this.getDistributorsList();
    }
    if (dialog.element.distributorId) this.loadTableData();
  }

  componentDidCatch(error, info) {
  }

  getDistributorsList = () => {
    const { getDistributors, displayAlert } = this.props;
    getDistributors({ limit: 1000 }, {
      handleSuccess: (response) => {
        this.setState({ distributorList: response.data.distributors.rows });
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  };

  loadTableData = () => {
    const {
      data, queryParameters, dialog,
    } = this.state;
    const { getOpeningBalanceList, displayAlert } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);

    if (!dialog.element.distributorId) {
      return;
    }
    getOpeningBalanceList({
      offset,
      limit: queryParameters.pagination.limit,
      filter: {
        filters: [
          ...apiFilterProcessor(queryParameters.filter),
          {
            column: 'distributor_id',
            value: [dialog.element.distributorId.toString() || ''],
          },
        ],
        sort: getSortValueAfterValueCheck(queryParameters.sort),
        // dateRange: queryParameters.date,
        queryString: queryParameters.search,
      },
    }, {
      handleSuccess: (response) => {
        data.list = response.data.openingBalance ? response.data.openingBalance.rows : [];
        data.total = response.data.openingBalance ? response.data.openingBalance.count : 1;
        if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
        this.setState(data);
      },
      handleError: (error) => {
        checkAndResetPaginatedData(data, this.setData);
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  };

  setQueryParameters = (queryParams, callBack = () => null) => this.setState(
    { queryParameters: queryParams },
    callBack,
  );

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };


  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleIconClick = (type = '', element) => {
    const { dialog } = this.state;
    this.setState({
      dialog: {
        type,
        element: dialog.element,
      },
    });
  };

  handleDistributorSelect = (distributorId) => {
    const { dialog } = this.state;
    dialog.element.distributorId = distributorId;
    this.setState({ dialog }, () => this.loadTableData());
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  resetDialog = () => {
    const { dialog } = this.state;
    this.setState({
      dialog: {
        element: { distributorId: dialog.element.distributorId },
        type: '',
      },
    });
  };

  render() {
    const {
      data, queryParameters, display, dialog, distributorList,
    } = this.state;
    const { serverResponseWaiting } = this.props;
    const { type } = dialog;
    const { element } = dialog;

    return (
      <Fragment>
        <div className="section-header">
          <OpeningBalanceStyled>
            {type && (
            <DialogFormWrapper
              onDialogSubmit={this.onFormSubmit}
              formConfig={formConfig}
              dialogElement={element}
              onDialogCancel={this.resetDialog}
              type={type}
              renderDialog={
                ({
                  dialogData, enableErrorDisplay, handleFileUpload, refsObj,
                }) => (
                  <Fragment>
                    <CSVUploadForm
                      show
                      data={dialogData}
                      loading={serverResponseWaiting}
                      handleFileUpload={handleFileUpload}
                      enableErrorDisplay={enableErrorDisplay}
                      refsObj={refsObj}
                    />
                    <a
                      onClick={() => this.basePaginationService.handleDownloadClick(
                        'csv', PROGRAM.OPENING_BALANCE,
                      )}
                    >
                      <span className="sample-csv">
                        <Icon iconName="download" />
                        Sample CSV
                      </span>
                    </a>
                  </Fragment>
                )
              }
            />
            )}
          </OpeningBalanceStyled>
          <PanelStyled>
            <PageHeader
              display={display}
              breadCrumb={breadCrumbConfig}
              config={{
                title: openingBalance.title,
                search: true,
                filter: true,
                upload: this.permission.create,
              }}
              filter={{
                menuList: filterConfig.menu,
                onFilterChange: this.basePaginationService.handleFilterChange,
              }}
              queryParameters={queryParameters}
              controlDisplay={this.controlDisplay}
              handleCreateClick={this.handleIconClick}
              resetFilter={this.basePaginationService.resetFilter}
              clearSearchText={this.basePaginationService.clearSearchText}
              handleSearchChange={this.basePaginationService.handleSearchInputChange}
              handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
              handleUploadClick={this.handleIconClick}
            />
          </PanelStyled>
        </div>
        <div className="section-content">
          <OpeningBalanceView
            data={data}
            inputData={element}
            loading={serverResponseWaiting}
            distributorList={distributorList}
            pagination={queryParameters.pagination}
            componentDisplay={this.componentConfig}
            onDropDownChange={this.handleDistributorSelect}
            onPageChange={this.basePaginationService.onPageSelect}
            onHeaderClick={this.basePaginationService.handleTableSorting}
          />
        </div>
      </Fragment>
    );
  }
}

OpeningBalance.propTypes = propTypes;

OpeningBalance.defaultProps = defaultProps;

export default withAlert()(OpeningBalance);
