import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../../data/enums/SuccessMessage';
import { configuration as configurationRoute } from '../../../../data/enums/Route';
import { getFilterConfig } from '../../../../utils/filter';
import { FILTER } from '../../../../data/enums/filter';

const title = 'User';

const breadCrumb = [
  configurationRoute,
];

const formConfig = {
  mapper: element => ({
    id: element.id || 0,
    firstName: element.firstName || '',
    lastName: element.lastName || '',
    email: element.email || '',
    phoneNumber: element.phoneNumber || '',
    password: element.password || '',
    roleId: element.roleId || '',
    parentUserId: element.parentUserId || '',
    billingStatus: element.billingStatus || '',
  }),
  validationField: ['firstName', 'lastName', 'email', 'phoneNumber', 'password', 'roleId', 'parentUserId'],
  validationRequired: true,
};


const filter = getFilterConfig([FILTER.USER_ROLE, FILTER.SUB_D, FILTER.STATUS]);

const crudSuccess = {
  [EVENT_OPERATION.CREATE]: {
    objectMapper: (data) => {
      const { id, ...structuredData } = data;
      return structuredData;
    },
    responseName: 'createUser',
    message: `${title} ${MESSAGE_EVENT_OPERATION.CREATE}`,
  },
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: (data) => {
      const { password, ...structuredData } = data;
      return structuredData;
    },
    responseName: 'updateUser',
    message: `${title} ${MESSAGE_EVENT_OPERATION.UPDATE}`,
  },
  [EVENT_OPERATION.DELETE]: {
    objectMapper: data => ({
      ids: [data.id],
      type: title,
      active: !data.active,
    }),
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: data => ({
      ids: [data.id],
      type: title,
      active: !data.active,
    }),
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.BULK_DELETE}`,
  },
};

export {
  crudSuccess, breadCrumb as breadCrumbConfig, formConfig, title, filter as filterConfig,
};
