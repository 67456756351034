import styled from 'styled-components';

const ContactDialogWrapper = styled.div`
.contact{
  position:relative;
  .eye{
    position: absolute;
    top: 9px;
    right: 14px;
  }
}
.txt-color{
  color: #6B6C7E;
}

 .modal-wrapper{
   .modal-inner{
     width:610px;
     max-width:unset;
     max-height:915px;
     .with-out-padding{
       padding:0;
       .evolve-dialog__body{
         padding-bottom:24px;
           border-radius:0 0 4px 4px;

         .table-wrap{
           height:400px;
           overflow:auto;
           table{
             border-radius:0 0 4px 4px;
             position:relative;
             thead{
               tr{
                 height:40px;
               }
             }
           tr{
             height:56px;
             th,
             td{
               width:25%;
               padding:0;
                &:first-child{
                  padding:0 12px 0 24px ;
                }
               
                img{
                      margin-left: 6px;
                      position: absolute;
                }

             }
             th{
               position:sticky;
               top:0;
             }
            }
         
           }
         }
       }
     }
   }
 }
`;

export default ContactDialogWrapper;
