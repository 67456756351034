import React from 'react';
import { circle } from 'd3-shape/src/symbol/circle';
import LegendStyled from './LegendStyled';
import { fixedFloatAndComma } from '../../../utils/conversion';


const Legend = ({
  data, colorSet, dimensions, id, placement,
}) => (
  <LegendStyled>
    <svg id={`${id}-legend`} width={dimensions.width + 10} height={dimensions.height / 2 - 0}>
      <g transform={`translate(${-3}, ${10})`}>
        {
            data.map((item, index) => (
              <g transform={`translate(${0}, ${(index * 10) / 5})`}>
                <circle
                  r={5}
                  cx="10"
                  cy={index * 20}
                  fill={colorSet[item.label]}
                />

                <text
                  className="value"
                  dominantBaseline="middle"
                  x="30"
                  y={index * 20}
                  dy="1"
                  textAnchor="left"
                  color="#6B6C7E"
                  style={{ fontSize: '12px' }}
                >
                  {item.label}

                </text>
                <text
                  className="value"
                  dominantBaseline="middle"
                  x="150"
                  y={index * 20}
                  dy="1"
                  textAnchor="right"
                  color="#272833"
                  style={{ fontSize: '12px' }}
                >
                  {fixedFloatAndComma(item.value)}
                </text>
              </g>
            ))
          }
      </g>

    </svg>
  </LegendStyled>
);


Legend.Prototype = {

};

Legend.defaultProps = {
  placement: 'down',
};
export default Legend;
