import styled from 'styled-components';

const BrandDialogWrapper = styled.div`
 .modal-wrapper{
   .modal-inner{
     width:580px;
     max-width:unset;
     .with-out-padding{
       padding:0;
       .evolve-dialog__body{
         padding-bottom:24px;
           border-radius:0 0 4px 4px;
            .detail-wrap {  
              display:flex;
              .month-wrap{
                pointer-events: none;
                width: 50%;
                padding: 24px;
                      label {
                          display: block;
                          font-weight: 600;
                          font-size: 14px;
                          line-height: 21px;
                          margin-bottom: 4px;
          
          + div {
            width: 100%;
            background: #F1F2F5;
            border: 1px solid #E7E7ED;
            border-radius: 4px;
            padding: 0 16px;
            
            > div {
              margin: 0;
              display: block;
            }
          }
        }  
        .month-view {
        position: relative;
        
        .month-icon {
          position: absolute;
          right: 0;
          margin: 0;
        }
        
        .ic-dropdown {
          display: none;
        }
      }
           }
         .form-input{
          padding:24px;
          margin:0;
          padding-left: 0px;
          label{
            font-size:14px;
            line-height:21px;
          }
          input{
            width:262px;
            border:1px solid #E7E7ED;
          }
          
         }
         }
         .table-wrap{
           /* height:400px;
            */
            height:288px;
           overflow:auto;
           table{
             border-radius:0 0 4px 4px;
             position:relative;
           tr{
             height:40px;
             th,
             td{
               width:50%;
               padding:0;
                &:first-child{
                  padding:0 12px 0 24px ;
                }

                &:last-child{
                  text-align:right;
                  padding-right:25%;
                
                }
                  .form-input{
                      padding:4px;
                      margin:0;
                      label{
            font-size:14px;
            line-height:21px;
          }
                  input{
                      width:172px;
                      border:1px solid #E7E7ED;
                      text-align: right;
                       
                      :disabled{
                            border: 1px solid white;
                            background: white;
                            color: #272833;
                      }
                }
             }
             th{
               position:sticky;
               top:0;
             }
            }
         
           }
         }
       }
     }
   }
   .modal-inner {
     &.create, &.read, &.update {
       /* width:361px; */
       width:580px;
       /* height:580px; */
  
     .month-wrap{
       margin-bottom:24px;
                      label {
          display: block;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          margin-bottom: 4px;
          
          + div {
            width: 100%;
            background: #F1F2F5;
            border: 1px solid #E7E7ED;
            border-radius: 4px;
            padding: 0 16px;
            
            > div {
              margin: 0;
              display: block;
            }
          }
        }  
        .month-view {
        position: relative;
        
        .month-icon {
          position: absolute;
          right: 0;
          margin: 0;
        }
        
        .ic-dropdown {
          display: none;
        }
      }
           }
   }
   }
 }
 }
 .targets-wrapper{.modal-wrapper{.modal-inner {
     &.create, &.read, &.update {
       width:361px;
       /* width:580px; */
       /* height:580px; */
     .month-wrap{
       margin-bottom:24px;
                      label {
          display: block;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          margin-bottom: 4px;
          
          + div {
            width: 100%;
            background: #F1F2F5;
            border: 1px solid #E7E7ED;
            border-radius: 4px;
            padding: 0 16px;
            
            > div {
              margin: 0;
              display: block;
            }
          }
        }  
        .month-view {
        position: relative;
        
        .month-icon {
          position: absolute;
          right: 0;
          margin: 0;
        }
        
        .ic-dropdown {
          display: none;
        }
      }
           }
   }
   }}}
`;

export default BrandDialogWrapper;
