import React from 'react';
import PropTypes from 'prop-types';
import withErrorAndLoading from '../../../../../utils/composition/withLoading';
import {
  CustomSelect,
  Input, Button,
} from '../../../../../components';
// eslint-disable-next-line import/no-named-as-default
import EVENT_OPERATION from '../../../../../data/enums/EventOperation';
import { RouteContext } from '../../config';
import FormContentStyled from '../../../../../components/global-css/FormContentStyled';

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    VisitType: PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    }),
    DeliveryType: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    }),
    VisitFrequency: PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    }),
    VisitCategory: PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    }),
    Town: PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    }),
  }).isRequired,
  menu: PropTypes.shape({
    townList: PropTypes.instanceOf(Array),
  }).isRequired,
  enableErrorDisplay: PropTypes.bool,
  onInputChange: PropTypes.func.isRequired,
  onDropDownChange: PropTypes.func.isRequired,
  refsObj: PropTypes.shape({
    routeName: PropTypes.string,
    channel: PropTypes.string,
    category: PropTypes.string,
    contactName: PropTypes.string,
    phoneNumber: PropTypes.string,
  }).isRequired,
  crudMode: PropTypes.string.isRequired,
  handleButtonSubmit: PropTypes.func.isRequired,
  handleButtonCancel: PropTypes.func.isRequired,
  handleEditIconClick: PropTypes.func,
};

const defaultProps = {
  enableErrorDisplay: false,
  handleEditIconClick: () => null,
};

const ROUTE_VISIT_TYPE_LIST = [
  {
    id: 'ONE_DAY',
    title: 'ONE DAY',
  },
  {
    id: 'WEEK_LONG',
    title: 'WEEK LONG',
  },
  {
    id: 'MONTH_LONG',
    title: 'MONTH LONG',
  },
];

const ROUTE_DELIVERY_TYPE_LIST = [
  {
    id: 'PRE_ORDER',
    title: 'PRE ORDER',
  },
  {
    id: 'READY_STOCK',
    title: 'READY STOCK',
  },
];

const ROUTE_VISIT_CATEGORY_LIST = [
  {
    id: 'OPEN',
    title: 'OPEN',
  },
  {
    id: 'SCHEDULED',
    title: 'SCHEDULED',
  },
];

const ROUTE_VISIT_FREQUENCY_LIST = [
  {
    id: 'DAILY',
    title: 'DAILY',
  },
  {
    id: 'WEEKLY',
    title: 'WEEKLY',
  },
  {
    id: 'FORTNIGHTLY',
    title: 'FORTNIGHTLY',
  },
];

const View = ({ ...props }) => {
  const {
    menu,
    data,
    refsObj,
    loading,
    crudMode,
    onInputChange,
    onDropDownChange,
    enableErrorDisplay,
    handleButtonSubmit,
    handleButtonCancel,
    handleEditIconClick,
  } = props;

  const showEditButton = crudMode !== EVENT_OPERATION.CREATE;

  const disableEditButton = crudMode === EVENT_OPERATION.UPDATE || data.routeLock;

  const hideEditButton = crudMode !== EVENT_OPERATION.UPDATE;

  const townIdIncludedInUser = (townId, userTownList = []) => (!userTownList ? [] : userTownList.map(town => town.id).includes(townId));

  const filteredDSEList = menu.dseList.filter(user => townIdIncludedInUser(data.Town.id, user.Town));

  // const newRefsObj = {...refsObj};
  // debugger;
  return (
    <RouteContext.Consumer>
      {
        ({ permission }) => (
          <>
            {
                // eslint-disable-next-line no-mixed-operators
                (crudMode !== EVENT_OPERATION.READ) && (
                  <div className="button-wrap">
                    <Button small secondary title="Cancel" disabled={loading} onClick={() => handleButtonCancel()} />
                    <Button small primary title="Save" disabled={loading} onClick={() => handleButtonSubmit()} />
                  </div>
                )
              }
            <div className="route-btn-wrap ">
              {hideEditButton ? (
                showEditButton
                && permission && permission.update
                && (
                  <div className="text-right ml-16">
                    <Button
                      secondary
                      iconBtnSmall
                      iconName="pencil"
                      disabled={disableEditButton}
                      onClick={() => handleEditIconClick()}
                    />
                  </div>
                )
              ) : ''
            }
            </div>
            <div className="create-ui ">
              <div className="create-ui-inner tab-less-height">
                {/* <div className="outlet-wrap"> */}
                <FormContentStyled>
                  <h3 className="">Route Info</h3>
                  <div
                    className={
                          crudMode === EVENT_OPERATION.READ
                            ? 'disabled' : ''
                        }
                  >
                    <div className="form-input-wrap">
                      {
                            data.id && (
                              <Input
                                name="id"
                                type="number"
                                disabled
                                value={data.id.toString()}
                                labelContent="ID"
                                enableErrorDisplay={false}
                                onChange={event => onInputChange(event, 'id', ['id'])}
                              />
                            )
                          }

                      <Input
                        name="title"
                        type="text"
                        enableValidation
                        value={data.title}
                        labelContent="Route Name"
                        placeholder="Route Name"
                        ref={ref => refsObj.title = ref}
                        enableErrorDisplay={enableErrorDisplay}
                        onChange={event => onInputChange(event, 'title', ['title'])}
                      />
                      <CustomSelect
                        enableValidation
                        labelContent="Town"
                        className="custom-select"
                        options={menu.townList}
                        placeholder="Select Town"
                        getOptionValue={({ id }) => id}
                        getOptionLabel={({ title }) => title}
                        enableErrorDisplay={enableErrorDisplay}
                        onChange={event => onDropDownChange(event.id, ['Town', 'id'])}
                        value={menu.townList.filter(({ id }) => id === data.Town.id)}
                        ref={ref => refsObj.town = ref}
                      />

                      <CustomSelect
                        enableValidation
                        labelContent="Assign User"
                        className="custom-select"
                        options={filteredDSEList}
                        placeholder="Select User"
                        getOptionValue={({ id }) => id}
                        getOptionLabel={({ firstName, lastName }) => `${firstName} ${lastName}`}
                        onChange={event => onDropDownChange(event.id, ['assignedTo'])}
                        value={menu.dseList.filter(({ id }) => id === data.assignedTo)}
                      />

                      <CustomSelect
                        enableValidation
                        labelContent="Visit Frequency"
                        className="custom-select"
                        options={ROUTE_VISIT_FREQUENCY_LIST}
                        placeholder="Select Route Visit Frequency"
                        getOptionValue={({ id }) => id}
                        getOptionLabel={({ title }) => title}
                        enableErrorDisplay={enableErrorDisplay}
                        onChange={event => onDropDownChange(event.id, ['VisitFrequency', 'title'])}
                        value={ROUTE_VISIT_FREQUENCY_LIST.filter(({ title }) => title === data.VisitFrequency.title)}
                        ref={ref => refsObj.visitFrequency = ref}
                      />

                      <CustomSelect
                        enableValidation
                        labelContent="Visit Category"
                        className="custom-select"
                        options={ROUTE_VISIT_CATEGORY_LIST}
                        placeholder="Select Route Visit Category"
                        getOptionValue={({ id }) => id}
                        getOptionLabel={({ title }) => title}
                        enableErrorDisplay={enableErrorDisplay}
                        onChange={event => onDropDownChange(event.id, ['VisitCategory', 'title'])}
                        value={ROUTE_VISIT_CATEGORY_LIST.filter(({ title }) => title === data.VisitCategory.title)}
                        ref={ref => refsObj.visitCategory = ref}
                      />

                      <CustomSelect
                        enableValidation
                        labelContent="Visit Type"
                        className="custom-select"
                        options={ROUTE_VISIT_TYPE_LIST}
                        placeholder="Select Route Visit Type"
                        getOptionValue={({ id }) => id}
                        getOptionLabel={({ title }) => title}
                        enableErrorDisplay={enableErrorDisplay}
                        onChange={event => onDropDownChange(event.id, ['VisitType', 'title'])}
                        value={ROUTE_VISIT_TYPE_LIST.filter(({ id }) => id === data.VisitType.title)}
                        ref={ref => refsObj.visitType = ref}
                      />

                      <CustomSelect
                        enableValidation
                        labelContent="Delivery Type"
                        className="custom-select"
                        options={ROUTE_DELIVERY_TYPE_LIST}
                        placeholder="Select Route Delivery Type"
                        getOptionValue={({ id }) => id}
                        getOptionLabel={({ title }) => title}
                        enableErrorDisplay={enableErrorDisplay}
                        onChange={event => onDropDownChange(event.id, ['DeliveryType', 'title'])}
                        value={ROUTE_DELIVERY_TYPE_LIST.filter(({ id }) => id === data.DeliveryType.title)}
                        ref={ref => refsObj.deliveryType = ref}
                      />
                    </div>
                  </div>
                </FormContentStyled>
              </div>
            </div>
            {/* </div> */}
        </>
        )
      }
    </RouteContext.Consumer>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const GeneralInfo = withErrorAndLoading(View);

export default GeneralInfo;
