import { reports as reportsRoute } from '../../../data/enums/Route';
import { getFilterConfig } from '../../../utils/filter';
import { FILTER } from '../../../data/enums/filter';


const title = 'Product Sales Report';

const breadCrumb = [
  reportsRoute,
];

const filter = getFilterConfig([FILTER.CHANNEL, FILTER.CATEGORY, FILTER.ROUTE, FILTER.SUB_D, FILTER.TOWN, FILTER.BRAND, FILTER.DSE]);


export {
  breadCrumb as breadCrumbConfig, filter as filterConfig, title,
};
