import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CheckBox } from '../../../../components';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import {
  menuAction,
  tableHeaderWithCheckBox,
} from '../../../../utils/paginatedListUtils';
import { PERMISSION_OBJ } from '../../../../data/enums/Permission';

const labelMappings = {
  checkbox: 'checkbox',
  id: 'id',
  title: 'title',
  channel: 'channel',
  category: 'category',
  town: 'town',
  owner: 'owner',
  contact: 'contact',
  status: 'status',
  actionHolders: 'actionHolders',
};

const menuConfigList = [
  {
    title: 'Delete',
    icon: 'trash',
    permissionDerivedBy: 'delete',
    type: EVENT_OPERATION.DELETE,
  },
];

const labelConfig = [
  {
    id: 1,
    label: labelMappings.checkbox,
    title: '',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.id,
    title: 'ID',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.title,
    title: 'Title',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.channel,
    title: 'Channel',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.category,
    title: 'Category',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.town,
    title: 'Town',
    show: true,
  },
  {
    id: 7,
    label: labelMappings.owner,
    title: 'Owner',
    show: true,
  },
  {
    id: 8,
    label: labelMappings.contact,
    title: 'Contact',
    show: true,
  },
  {
    id: 9,
    label: labelMappings.status,
    title: 'Status',
    classNames: 'text-center',
    show: true,
  },
  {
    id: 10,
    label: labelMappings.actionHolders,
    title: '',
    show: true,
  },
];

const TableHeader = ({
  dataListLength,
  checkedListLength,
  primaryCheckboxHandler,
  permission,
}) =>
  tableHeaderWithCheckBox(
    dataListLength,
    checkedListLength,
    primaryCheckboxHandler,
    permission,
    labelConfig,
    labelMappings
  );

TableHeader.propTypes = {
  checkedListLength: PropTypes.number,
  dataListLength: PropTypes.number,
  permission: PropTypes.instanceOf(Object),
  primaryCheckboxHandler: PropTypes.func.isRequired,
};

TableHeader.defaultProps = {
  checkedListLength: 0,
  dataListLength: 0,
  permission: PERMISSION_OBJ,
};

const returnTableBody = (
  data,
  checkedList,
  onIconClick,
  secondaryCheckboxHandler,
  permission
) => {
  const table = {};
  table[labelMappings.checkbox] = permission.delete && (
    <td key={`outlet-${data.id}-check`} onClick={(e) => e.stopPropagation()}>
      <CheckBox
        checked={checkedList.includes(data.id)}
        onCheckBoxClick={() => secondaryCheckboxHandler(data.id)}
      />
    </td>
  );
  table[labelMappings.id] = <td key={`outlet-${data.id}-id`}>{data.id}</td>;
  table[labelMappings.title] = (
    <td key={`outlet-${data.id}-title`}>{data.title}</td>
  );
  table[labelMappings.channel] = (
    <td key={`outlet-${data.id}-channel`}>{data.Category.Channel.title}</td>
  );
  table[labelMappings.category] = (
    <td key={`outlet-${data.id}-category`}>{data.Category.title}</td>
  );
  table[labelMappings.town] = (
    <td key={`outlet-${data.id}-town`}>{data.Town.title}</td>
  );
  table[labelMappings.owner] = (
    <td key={`outlet-${data.id}-contact`}>{data.contactInfo[0].name}</td>
  );
  table[labelMappings.contact] = (
    <td key={`outlet-${data.id}-phoneNumber`}>
      {data.contactInfo[0].phoneNumber}
    </td>
  );
  table[labelMappings.status] = (
    <td key={`outlet-${data.id}-status`} className='text-center'>
      <span className={`status-label ${data.active ? 'active' : 'passive'}`}>
        {data.active ? 'ACTIVE' : 'INACTIVE'}
      </span>
    </td>
  );
  table[labelMappings.actionHolders] = (
    <td
      key={`outlet-${data.id}-action`}
      className='text-right simple-popup-actions'
      onClick={(e) => e.stopPropagation()}
    >
      {menuAction(
        menuConfigList,
        { menuIcon: 'ellipsis-v' },
        onIconClick,
        data,
        permission
      )}
    </td>
  );

  return table;
};

const TableBody = ({
  data,
  checkedList,
  onIconClick,
  secondaryCheckboxHandler,
  onTableRowClick,
  permission,
}) => {
  // Expanding/Dragging and collapsing table body.
  // color based upon table body
  // tooltip if it is sorted.
  // active className
  const tableBody = returnTableBody(
    data,
    checkedList,
    onIconClick,
    secondaryCheckboxHandler,
    permission
  );

  return (
    <tr
      key={data.id}
      style={{ cursor: 'pointer' }}
      onClick={() => onTableRowClick(EVENT_OPERATION.UPDATE, data.id)}
    >
      <Link to={`outlet/details/${data.id}`}>
        {labelConfig.map((label) =>
          label.show ? tableBody[label.label] : null
        )}
      </Link>
    </tr>
  );
};

TableBody.propTypes = {
  checkedList: PropTypes.instanceOf(Array),
  data: PropTypes.instanceOf(Object),
  onIconClick: PropTypes.func.isRequired,
  onTableRowClick: PropTypes.func.isRequired,
  permission: PropTypes.instanceOf(Object),
  secondaryCheckboxHandler: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
  checkedList: [],
  permission: PERMISSION_OBJ,
};

export { TableHeader, TableBody };
