import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import View from './View';
import Form from './Form';
import UploadForm from './UploadForm';
import { DialogFormWrapper, DialogWrapper } from '../../common';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import {
  formConfig,
  filterConfig,
  breadCrumbConfig,
  crudSuccess as crudRequestConfig,
} from './config';
import { FORM_CONFIG } from '../../../data/enums/config';
import withAlert from '../../../utils/composition/withAlert';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { TARGET_ACHIEVEMENT_DETAILS } from '../../../data/enums/Route';
import { crudResponseProcessor, handleFormSubmit } from '../../../utils/crudResponseProcessor';
import { isError } from '../../common/HelperFunctions';
import Input from '../../../components/Input/Text';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { DOWNLOAD_DOMAIN } from '../../../data/enums/GraphQL';
import BrandDialogWrapper from './BrandDialogWrapper';
import { Icon } from '../../../components';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  getBUTarget: PropTypes.func.isRequired,
  createBUTarget: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  getBUSettings: PropTypes.func.isRequired,
  upload: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class TargetAchievement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        list: [],
        total: 1,
      },
      dialog: {
        type: '',
        element: '',
      },
      pagination: {
        page: 0,
        limit: 50,
      },
      // eslint-disable-next-line react/no-unused-state
      date: {
        start: new Date(),
        end: new Date(),
      },
      filter: {
        ...filterConfig.instance,
      },
      settings: {
        salesTeamWise: null,
        brandWise: null,
        subDWise: null,
      },
      brandDialogData: [{
        id: 0,
        brandName: 'xxxxx',
        target: 0,
        monthName: 'xxxxx',
        year: 'xxxx',
        total: 0,
      }],
    };

    this.getData = () => {
      const { data } = this.state;
      return data;
    };

    const serverCall = {
      [EVENT_OPERATION.UPLOAD]: props.upload,
      [EVENT_OPERATION.CREATE]: props.createBUTarget,
      [EVENT_OPERATION.UPDATE]: props.updateBUTarget,
    };

    this.onCRUDSuccess = crudResponseProcessor((data, type) => {
      const { displayAlert } = this.props;
      let message = crudRequestConfig[type].message;
      if (type === EVENT_OPERATION.UPLOAD) {
        message = data;
      } else {
        this.setState(data);
      }
      this.getList();
      displayAlert(ALERT_TYPE.SUCCESS, message);
    }, this.getData,
    ['target']);

    this.onFormSubmit = handleFormSubmit(
      this.onCRUDSuccess, this.onAPIRequestFailure, crudRequestConfig, serverCall,
    );
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount() {
    this.getList();
    this.loadBUSettings();
  }

  loadBUSettings = () => {
    const { getBUSettings } = this.props;
    getBUSettings(
      {},
      {
        handleSuccess: (res) => {
          const settings = res.data.settings.targets;
          this.setState({ settings });
          localStorage.setItem('settings', JSON.stringify(settings));
        },
        handleFailure: (err) => {
        },
      },
    );
  };

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  getList = () => {
    const { getBUTarget, displayAlert } = this.props;
    const { data, filter, pagination } = this.state;

    getBUTarget({}, {
      handleSuccess: (response) => {
        data.list = (response.data.buTargets && response.data.buTargets.rows) || [];
        data.total = (response.data.buTargets && response.data.buTargets.count) || 0;
        if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
        this.setState({ data });
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  };

  handlePaginationChange = (pagination) => {
    this.setState({ pagination }, () => this.getList());
  };

  handleIconClick = (type, element) => {
    this.updateDialog(type, element);
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  onFilterChange = (field, value) => {
    if (field === 'date') {
      this.setState({ date: value }, () => this.getList());
    } else {
      this.setState({ filter: field }, () => this.getList());
    }
  };

  updateDialog = (type, element = {}) => {
    // fetch all the necessary data for dialog here;

    this.setState({
      dialog: {
        type,
        element,
      },
    });
  };

  resetDialog = () => {
    this.setState({
      dialog: {
        type: '',
        element: '',
      },
    });
  };

  onTableBodyClick = (data) => {
    // push to the target achievement details.
    const { history } = this.props;
    history.push(`/${TARGET_ACHIEVEMENT_DETAILS}/${data.id}`);
  };

  handleCSVUpload = (type) => {
    this.updateDialog(EVENT_OPERATION.UPLOAD, { name: type });
  };

  isConfirmationType = type => type === EVENT_OPERATION.DELETE
    || type === EVENT_OPERATION.BULK_DELETE
    || type === EVENT_OPERATION.UPDATE_STATUS;

  handleBrandDialog = (id) => {
    const { getUserBrandTarget, getSubDBrandTarget, displayAlert } = this.props;
    const { settings } = this.state;
    if (settings.salesTeamWise) {
      getUserBrandTarget({
        buTargetId: id,
      }, {
        handleSuccess: (response) => {
          this.setState({ brandDialogData: response.data.userBrandTargetsPerMonth });
        },
        handleError: (error) => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      });
    }
    if (settings.subDWise) {
      this.setState({
        brandDialog: id,
      });
      getSubDBrandTarget({
        buTargetId: id,
      }, {
        handleSuccess: (response) => {
          this.setState({ brandDialogData: response.data.subdBrandTargetsPerMonth });
        },
        handleError: (error) => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      });
    }
  };

  onSampleDownload = (entity) => {
    const { downloadReport, displayAlert } = this.props;
    downloadReport({
      input: {
        domain: entity,
        reportType: 'csv',
      },
    }, {
      handleSuccess: (response) => {
        const { downloadReport } = response.data;
        window.open(`${window.location.origin}/${downloadReport.file.name}`);
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  };

  render() {
    const {
      data,
      dialog,
      settings,
      pagination,
      brandDialogData,
    } = this.state;

    const { type } = dialog;

    const {
      serverResponseWaiting,
    } = this.props;

    const sampleUrl = checkCase => (
      <a onClick={() => this.onSampleDownload(checkCase)}>
        <span
          className="sample-csv"
        >
          {' '}
          <Icon iconName="download" />
Sample.csv
        </span>
      </a>
    );

    const sampleCase = (setting) => {
      if (setting.salesTeamWise && setting.brandWise) {
        return sampleUrl(DOWNLOAD_DOMAIN.USER_BRAND_TARGET_SAMPLE);
      }
      if (setting.salesTeamWise && !setting.brandWise) {
        return sampleUrl(DOWNLOAD_DOMAIN.USER_TARGET_SAMPLE);
      }
      if (setting.subDWise && setting.brandWise) {
        return sampleUrl(DOWNLOAD_DOMAIN.DISTRIBUTOR_BRAND_TARGET_SAMPLE);
      }
      if (setting.subDWise && !setting.brandWise) {
        return sampleUrl(DOWNLOAD_DOMAIN.DISTRIBUTOR_TARGET_SAMPLE);
      }
    };
    const formTitle = (type === EVENT_OPERATION.CREATE || type === EVENT_OPERATION.UPLOAD) ? '' : `${dialog.element.monthName}, ${dialog.element.year}`;

    return (

      <BrandDialogWrapper>
        {type && (
          <div className="targets-wrapper">
            <DialogFormWrapper
              type={type}
              dialogElement={dialog.element}
              formConfig={formConfig[type]}
              onDialogCancel={this.resetDialog}
              onDialogSubmit={this.onFormSubmit}
              fileUploadRefs={formConfig[FORM_CONFIG.REFS_OBJ]}
              formTitle={formTitle}
              renderDialog={
                (
                  {
                    dialogData,
                    enableErrorDisplay,
                    handleInputChange,
                    refsObj,
                    fileUploadRefs,
                    handleFileUpload,
                  },
                ) => (
                  <Fragment>
                    {
                      (type === EVENT_OPERATION.CREATE || type === EVENT_OPERATION.UPDATE || type === EVENT_OPERATION.READ) && (
                        <>
                          <Form
                            show
                            type={type}
                            refsObj={refsObj}
                            data={dialogData}
                            enableErrorDisplay={enableErrorDisplay}
                            handleInputChange={handleInputChange}
                            brandDialogData={brandDialogData}
                          />
                        </>
                      )

                    }
                    {
                      (type === EVENT_OPERATION.UPLOAD) && (
                        <>
                          <UploadForm
                            show
                            refsObj={fileUploadRefs}
                            data={dialogData}
                            loading={serverResponseWaiting}
                            handleFileUpload={handleFileUpload}
                            enableErrorDisplay={enableErrorDisplay}
                          />
                          {
                            sampleCase(settings)
                          }
                        </>
                      )
                    }
                  </Fragment>
                )}
            />
          </div>
        )}
        <DialogWrapper
          title={brandDialogData[0] && `${brandDialogData[0].monthName}, ${brandDialogData[0].year}`}
          footer
          withOutPadding
          renderDialog={({ handleDialogInputChange }) => (
                <>
                  {
                    brandDialogData.length !== 0 ? (
                      <Fragment>
                        <Input
                          disabled
                          type="text"
                          name="monthly"
                          labelContent="Monthly Target"
                          value={fixedFloatAndCommas(brandDialogData[0].total)}
                        />
                        <div className="table-wrap">
                          <table>
                            <thead>
                              <tr>
                                <th>Brand</th>
                                <th>Brand Target</th>
                              </tr>
                            </thead>
                            <tbody>
                              {brandDialogData.map(brand => (
                                <tr>
                                  <td>{brand.brandName}</td>
                                  <td>
                                    {fixedFloatAndCommas(brand.target)}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </Fragment>
                    )
                      : <h2 className="text-center">No Brand Target</h2>
                  }
                </>
          )}
          render={({ onDialogItemClick }) => (
            <View
              data={data}
              settings={settings}
              pagination={pagination}
              breadCrumb={breadCrumbConfig}
              loading={serverResponseWaiting}
              onIconClick={onDialogItemClick}
              onTableBodyClick={this.onTableBodyClick}
              onPageChange={this.handlePaginationChange}
              onCSVUpload={this.handleCSVUpload}
              onHandleBrandDialog={this.handleBrandDialog}
              handleIconClick={this.handleIconClick}
            />
          )}
        />
      </BrandDialogWrapper>

    );
  }
}

TargetAchievement.propTypes = propTypes;

TargetAchievement.defaultProps = defaultProps;

export default withAlert()(TargetAchievement);
