import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import SubDView from './View';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import {
  filterConfig,
  breadCrumbConfig,
} from './config';
import {
  apiFilterProcessor,
  getOffsetFromPagination,
} from '../../../utils/api';
import { SUB_D_DETAILS, SUB_D_CREATE } from '../../../data/enums/Route';
import * as queryService from '../../base/query.service';
import { getPermissionForSubD } from '../../base/permission';
import PageHeader from '../../base/PageHeader';
import { PanelStyled } from '../../common/configuration';
import { subD } from '../../common/DomainConfig';
import { isError } from '../../common/HelperFunctions';
import { crudResponseProcessor } from '../../../utils/crudResponseProcessor';
import { FORM_CONFIG } from '../../../data/enums/config';
import { DialogWrapper } from '../../common';
import { crudSuccess, formConfig } from './detail/config';

const propTypes = {
  getSubDList: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  toggleState: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class SubD extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      display: {
        searchBox: false,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
      },
    };
    this.permission = getPermissionForSubD();
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      props.displayAlert,
    );
  }

  componentDidMount() {
    this.loadTableData();
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  loadTableData = () => {
    const { data, queryParameters } = this.state;
    const { getSubDList } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);

    getSubDList(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          queryString: queryParameters.search,
        },
      },
      {
        handleSuccess: (response) => {
          data.list = response.data.distributors ? response.data.distributors.rows || [] : [];
          data.total = response.data.distributors ? response.data.distributors.count || 0 : 0;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
        handleError: error => this.onAPIRequestFailure(error),
      },
    );
  };

  setQueryParameters = (queryParams, callBack = () => null) => this.setState(
    { queryParameters: queryParams },
    callBack,
  );

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleSubDViewClick = (type, id) => {
    const { history } = this.props;
    if (type === EVENT_OPERATION.CREATE) {
      history.push(`/${SUB_D_CREATE}`);
    } else {
      history.push(`/${SUB_D_DETAILS}/${id}`);
    }
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  handleBulkDelete = (type, response) => {
    const { displayAlert } = this.props;
    const { data } = this.state;
    displayAlert(ALERT_TYPE.SUCCESS, crudSuccess[type].message);
    crudResponseProcessor(
      (modifiedData) => {
        this.setState({ data: modifiedData });
      },
      () => data,
    )(type)(response);
  };

  handleFormSubmit = (type, response) => {
    const { toggleState } = this.props;
    const data = crudSuccess[type].objectMapper(response);
    toggleState(data, {
      handleSuccess: () => {
        // considered only upload case for data.
        const { displayAlert } = this.props;
        displayAlert(ALERT_TYPE.SUCCESS, crudSuccess[type].message);
        this.loadTableData();
      },
      handleError: (err) => {
        this.onAPIRequestFailure(err);
      },
    });
  };

  render() {
    const {
      data, queryParameters, display,
    } = this.state;
    const { serverResponseWaiting } = this.props;

    return (
      <DialogWrapper
        header=""
        fileConfig={formConfig}
        refsObj={formConfig.ref}
        fileUploadRefs={formConfig[FORM_CONFIG.REFS_OBJ]}
        onDialogSubmit={this.handleFormSubmit}
        renderDialog={{}}
        render={({ onDialogItemClick }) => (
          <Fragment>
            <div className="section-header">
              <PanelStyled>
                <PageHeader
                  display={display}
                  breadCrumb={breadCrumbConfig}
                  config={{
                    title: subD.title,
                    create: this.permission.create,
                    download: false,
                    filter: true,
                    date: false,
                    search: true,
                  }}
                  filter={{
                    date: queryParameters.date,
                    menuList: filterConfig.menu,
                    onFilterChange: this.basePaginationService.handleFilterChange,
                  }}
                  queryParameters={queryParameters}
                  controlDisplay={this.controlDisplay}
                  handleCreateClick={this.handleSubDViewClick}
                  resetFilter={this.basePaginationService.resetFilter}
                  clearSearchText={this.basePaginationService.clearSearchText}
                  handleSearchChange={this.basePaginationService.handleSearchInputChange}
                  handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
                  handleDownloadClick={this.basePaginationService.handleDownloadClick}
                />
              </PanelStyled>
            </div>
            <div className="section-content">
              <SubDView
                data={data}
                pagination={queryParameters.pagination}
                loading={serverResponseWaiting}
                onPageChange={this.basePaginationService.onPageSelect}
                onViewClick={this.handleSubDViewClick}
                onBulkDelete={this.handleBulkDelete}
                permission={this.permission}
                onIconClick={onDialogItemClick}
              />
            </div>
          </Fragment>
        )}
      />
    );
  }
}

SubD.propTypes = propTypes;

SubD.defaultProps = defaultProps;

export default withAlert()(SubD);
