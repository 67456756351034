import { compose } from 'recompose';
import LedgerInfo from './LedgerInfo';
import withRequestTracker from '../../../../../../utils/composition/withRequestTracker';
import { query } from './API';

const composeLedgerInfo = compose(
  withRequestTracker({
    query,
  }),
)(LedgerInfo);

export default composeLedgerInfo;
