import React, { Component } from 'react';
import { normalPresentor } from '../../../../utils/date';

class InitialInfoWindow extends Component {
  render() {
    const {
      name, id, category, dse, subD, route, contact, assignedTo, clickHandler, lastCallDate, reason, callType,
    } = this.props;
    return (
      <div id="infoWindow">
        <h3 className="outlet-info-name">{name}</h3>
        <p className="outlet-info-id">
          <b>ID</b>
          <span>
            {id}
          </span>
        </p>
        <p className="outlet-info-category">
          <b>Category </b>
          <span>{category}</span>
        </p>
        <p className="outlet-info-dse">
          <b>DSE</b>
          <span>{dse}</span>
        </p>
        <p className="outlet-info-subD">
          <b>Sub-D </b>
          <span>{subD}</span>
        </p>
        <p className="outlet-info-route">
          <b>Route </b>
          <span>{route}</span>
        </p>
        <p className="outlet-info-contact-number">
          <b>Contact Number </b>
          <span>{contact}</span>
        </p>
        <p className="outlet-info-call-Info">
          <b>Last Call Date </b>
          <span>{normalPresentor(lastCallDate)}</span>
        </p>
        {assignedTo ? (
          <p className="outlet-info-contact-number">
            <b>Assigned To </b>
            <span>{assignedTo}</span>
          </p>
        )
          : null}
        {callType === 'UNSUCCESSFUL' && (
          <p className="outlet-info-contact-number">
            <b>Reason </b>
            <span>{reason}</span>
          </p>
        )}
        <button onClick={() => clickHandler()}>Assign</button>
      </div>
    );
  }
}

export default InitialInfoWindow;
