import React, { Fragment } from 'react';
import { detailsLabelConfig, DetailTableBody } from './tableConfig';
import { tableData } from '../../../../../utils/paginatedListUtils';
import { Button, CheckBox } from '../../../../../components';
import { PRIMARY } from '../../../../../data/enums/GeneralConstants';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { OrderFulFilmentContext } from '../../config';
import InformationRows from './InformationRows';

const childView = (
  {
    data,
    call,
    loading,
    checkBoxStatus,
    onPrimaryCheckBoxClick,
    onSecondaryCheckBoxClick,
    onInvoiceClick,
    onIconClick,
    selectedOrders,
  },
) => {
  const { stockReplacement = {} } = call;
  const disableInvoiceBtn = () => stockReplacement.count
    || selectedOrders.length < 1
    || loading;

  return (
    <Fragment>
      <tr>
        {
          detailsLabelConfig.map(
            label => (
              !label.action
                ? tableData(label.title, label.align, label.classNames)
                : (
                  <th>
                    <CheckBox
                      checked={checkBoxStatus(PRIMARY, (call.callOrderValue.billDiscount || 0))}
                      onCheckBoxClick={e => onPrimaryCheckBoxClick(e,
                        (call.callOrderValue.billDiscount || 0))}
                    />
                  </th>
                )

            ),
          )
        }
      </tr>
      <OrderFulFilmentContext.Consumer>
        {
          ({ permission }) => (
            <Fragment>
              <Fragment>
                {
                  data.map(order => order.Lines.map((sku, index) => (
                    <tr>
                      {DetailTableBody({
                        index,
                        data: sku,
                        order,
                        checkBoxStatus,
                        selectedOrders,
                        onIconClick,
                        callOrderValue: call.callOrderValue,
                        onSecondaryCheckBoxClick,
                        permission,
                      })}
                    </tr>
                  )))
                    }
              </Fragment>
              <InformationRows
                onIconClick={onIconClick}
                call={call}
              />
              {
                  permission.update && (
                    <tr>
                      <td colSpan="75%">
                        <div className="text-right">
                          {
                            permission.delete && (
                              <Button
                                secondary
                                small
                                disabled={loading}
                                onClick={() => onIconClick(EVENT_OPERATION.BULK_DELETE,
                                  {
                                    ids: selectedOrders,
                                    callId: (data.length > 0
                                        && data[0].Lines.length > 0
                                        && data[0].Lines[0].callId)
                                        || null,
                                  })}
                              >
                                <span>Cancel Order</span>
                              </Button>
                            )
                          }
                          {
                            permission.update && (
                              <Button
                                primary
                                small
                                onClick={e => onInvoiceClick(selectedOrders)}
                                disabled={disableInvoiceBtn()}
                              >
                                <span>Invoice</span>
                              </Button>
                            )
                            }
                        </div>
                      </td>
                    </tr>
                  )
                }
            </Fragment>
          )
        }
      </OrderFulFilmentContext.Consumer>
    </Fragment>

  );
};

export default childView;
