import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { dataProps } from '../../../../../data/enums/Component';
import withErrorAndLoadingScreen from '../../../../../utils/composition/withLoading';
import {
  Button,
  Input,
} from '../../../../../components';
import { INVALID } from '../../../../../data/enums/ErrorMessage';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';
import { Col } from '../../../../../components/Grid';
import { defaultMenuConfigList, menuAction } from '../../../../../utils/paginatedListUtils';
import { normalPresentor } from '../../../../../utils/date';
import GRNDetailTable from './GRNDetailTable';


const propTypes = {
  data: dataProps.value,
  onPageChange: PropTypes.func,
  onCheckBoxClick: PropTypes.func,
  onIconClick: PropTypes.func,
  onButtonClick: PropTypes.func,
  date: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }).isRequired,
  skuLines: PropTypes.array,
  update: PropTypes.instanceOf(Object),
  onFilterChange: PropTypes.func.isRequired,
};

const defaultProps = {
  checkedList: [],
  breadCrumb: [],
  onIconClick: () => null,
  onPageChange: () => null,
  onButtonClick: () => null,
  onCheckBoxClick: () => null,
  data: dataProps.defaultValue,
  skuLines: [],
  update: {
    type: EVENT_OPERATION.CREATE,
    status: false,
  },
};

const menuConfigList = [defaultMenuConfigList[EVENT_OPERATION.UPDATE],
  defaultMenuConfigList[EVENT_OPERATION.DELETE]];

const TableView = ({ ...props }) => {
  const {
    update,
    onIconClick,
    skuLines,
  } = props;

  if (skuLines.length > 0) {
  }

  return (
    <GRNDetailTable>
      <div className="table-wrap">
        <table>
          <thead>
            <tr>
              <th>SKU</th>
              <th>Batch</th>
              <th className="text-center">MFG Date</th>
              <th className="text-center">EXP Date</th>
              <th>S/D</th>
              <th className="text-right">Quantity</th>
              <th className="text-right">Rate</th>
              <th className="text-right">Amount</th>
              <th className="text-right">Discount</th>
              <th className="text-right">Net Amount</th>
              {
            update.status && (<th />)
          }
            </tr>
          </thead>
          <tbody>
            {
          skuLines.map(sku => (
            <tr key={sku.skuId}>
              <td>{sku.SKU.title}</td>
              <td>{sku.SkuBatch.batchDetails.batchNumber ? sku.SkuBatch.batchDetails.batchNumber.replace(/_/g, ' ') : sku.SkuBatch.batchDetails.batchNumber}</td>
              <td className="text-center">{(normalPresentor(sku.manufactureDate) === 'Invalid date') ? ('-') : (normalPresentor(sku.manufactureDate))}</td>
              <td className="text-center">{(normalPresentor(sku.expiryDate) === 'Invalid date') ? ('-') : (normalPresentor(sku.expiryDate)) }</td>
              <td>{`${sku.shortages}/${sku.damages}`}</td>
              <td className="text-right">{sku.quantity}</td>
              <td className="text-right">{fixedFloatAndCommas(sku.priceDetails.rate || 0)}</td>
              <td className="text-right">{fixedFloatAndCommas(sku.priceDetails.amount || 0) }</td>
              <td className="text-right">{fixedFloatAndCommas(sku.priceDetails.discount || 0)}</td>
              <td className="text-right">{fixedFloatAndCommas(sku.priceDetails.netAmount)}</td>
              {
                update.status && (
                  <td className="text-right simple-popup-actions" onClick={e => e.stopPropagation()}>
                    {menuAction(
                      menuConfigList,
                      { menuIcon: 'ellipsis-v' },
                      onIconClick, sku,
                      {
                        update: true,
                        delete: update.type === EVENT_OPERATION.UPDATE,
                      },
                    )}
                  </td>
                )
              }

            </tr>
          ))}
          </tbody>
        </table>
        {
          update.status && (
          <div className="grn-sku-add">
            <Button
              iconBtnSmall
              secondary
              iconName="plus"
              disabled={update.type === EVENT_OPERATION.APPROVE}
              onClick={() => onIconClick(EVENT_OPERATION.CREATE, {})}
            />
          </div>
          )
        }
      </div>
    </GRNDetailTable>
  );
};

TableView.propTypes = propTypes;

TableView.defaultProps = defaultProps;

const TableViewWithErrorAndLoading = withErrorAndLoadingScreen(TableView);


const TotalView = ({ ...props }) => {
  const {
    update,
    refsObj,
    enableErrorDisplay,
    priceDetails,
    onInputChange,
  } = props;

  return (
    <Fragment>
      <div className="grn-sku-update">
        <div className="grn-sku-update-inner">
          <Row>
            <Col md={6}>
              <span>Sub Total</span>
            </Col>
            <Col md={6}>
              <div>
                {fixedFloatAndCommas(priceDetails.subTotal)}
                {' '}
              </div>
            </Col>
            <Col md={6} className="mt-5">
              <span>Bill Discount</span>
            </Col>
            <Col md={6}>
              <Input
                name="billDiscount"
                type="number"
                enableValidation
                rule="isFloat"
                ref={ref => refsObj.billDiscount = ref}
                argument={{
                  min: 0,
                  max: priceDetails.subTotal - priceDetails.tradeDiscount,
                }}
                value={priceDetails.billDiscount}
                enableErrorDisplay={enableErrorDisplay}
                onChange={event => onInputChange(event)}
                disabled={update.type === EVENT_OPERATION.APPROVE}
                errorMessage={INVALID}
              />
            </Col>
            <Col md={6} className="mt-5">
              <span>Trade Discount</span>
            </Col>
            <Col md={6}>
              <Input
                name="tradeDiscount"
                type="number"
                enableValidation
                rule="isFloat"
                argument={{
                  min: 0,
                  max: priceDetails.subTotal - priceDetails.billDiscount,
                }}
                ref={ref => refsObj.tradeDiscount = ref}
                value={priceDetails.tradeDiscount}
                enableErrorDisplay={enableErrorDisplay}
                onChange={event => onInputChange(event)}
                disabled={update.type === EVENT_OPERATION.APPROVE}
                errorMessage={INVALID}
              />
            </Col>
            <Col md={6}>
              <span>Taxable Amount</span>
            </Col>
            <Col md={6}>
              <div>
                {' '}
                {fixedFloatAndCommas(priceDetails.taxableAmount)}
                {' '}
              </div>
            </Col>
            <Col md={6}>
              <span>VAT</span>
            </Col>
            <Col md={6}>
              <div>
                {fixedFloatAndCommas(priceDetails.taxAmount)}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="grn-sku-update-total">
        <span>TOTAL</span>
        <div>
          {fixedFloatAndCommas(priceDetails.total)}
        </div>
      </div>
    </Fragment>
  );
};


export { TableViewWithErrorAndLoading as TableView, TotalView };

TableView.propTypes = propTypes;

TableView.defaultProps = defaultProps;

export default TableViewWithErrorAndLoading;
