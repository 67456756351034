import styled from 'styled-components';

const BuStyled = styled.div`
  padding: 32px 48px;
  background: #fbfbfc;
  
  .bu-replicate-text {
    margin-bottom: 48px;
    
    .form-input {
      margin-bottom: 0 !important;
    }
    
    > span {
      font-size: 10px;
      line-height: 12px;
      margin-top: -4px;
      display: inline-block;
      width: 160px;
      color: #6B6C7E;
    }
  }
  
  .bu-section{
    .loading-wrap {
      display: none;
    }
  }
  
  .subd-holiday-wrap {
    .custom-control-label-text {
      width: 255px;
    }
    .bu-form-content {
      label {
        width: 142px;
        margin-right: 24px;
      }
      
      input {
        width: 310px;
        flex: none;
        background: #F1F2F5;
        border-color: #e7e7ed;
      }
    }
  }
  
  .bu-form-content {
    margin-bottom: 64px;
    
    &:last-child {
      margin-bottom: 0;
    }
    
    h2 {
      font-size: 14px;
      font-weight: normal;
      color: #6B6C7E;
      padding-bottom: 5px;
      margin: 0 0 24px;
      border-bottom: 1px solid #E7E7ED;
      width: 66.66667%;
    }
  }
  
  .measurement-wrap {
    width: 66.67%;
    width:100%;
    + button {
      margin-top: 8px;
      
      &:hover, &:focus {
        .icon {
          color: #fff;
        }
      }
      
      .icon {
        width: 14px;
        height: 14px;
      }
    }
    
    .list-title {
      display: flex;
      
      li {
        width: 150px;
        margin-right: 16px;
          
           &.medium {
             width: 222px;
          }
          &.large {
             width: 252px;
          }
      }
      
      span {
        display: inline-block;
        margin-bottom: 4px;
        color: #272833;
        font-size: 14px;
        font-weight: 600;
      }
      
    }
    
    .list-content {
      margin-bottom: 4px;
      
      label {
        width: 150px;
        margin-right: 16px;
        
        > div {
          width: 100%;
        }

        &.small {
          margin-right: 0px;
        }

      &.medium{
          width:222px;
        }

        &.large{
          width:252px;
        }
      }
      
      input {
        width: 100%  !important;
      }
    }
    
    .icon {
      cursor: pointer;
      color: #6B6C7E;
    }
  }
  
  .bu-draggable {
   > div {
    padding: 0 !important;
    width:100% !important;
   }
   
   .form-input {
      padding: 0 !important;
      margin-bottom: 16px !important;
      
      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }
  
  .form-input, .form-select-input {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  
  label {
    width: 260px;
    display: inline-block;
    margin-bottom: 4px;
    color: #272833;
    font-size: 14px;
    font-weight: 600;
    
    input {
      background: #F1F2F5;
      border: 1px solid #E7E7ED;
    }
    
      .datepicker-wrapper {
    
        .moment {
          border-color: #E7E7ED;
          background: #f1f2f5;
          color: #6B6C7E;
          padding: 10px 14px;
          font-size: 14px;
          width: 100%;
          height: 40px;
          font-weight: 400;
      
          .ic-date-icon {
            top: 9px;
          }
        }
      }

      .filter-dropdown-card {
        min-width: 310px;
      }
    }
  
  input {
    flex: 1;
  }
 
  
  .form-select-input > div {
    flex: 1;
  }
  
  .subd-holiday-wrap .bu-form-content .holiday-status-checkbox .checkbox-list {
    label {
      width: auto;
      margin-bottom: 0;
    }
  }

.checkbox{
  label{
    width:auto;
    margin-right: 32px;
  }
}
`;

export default BuStyled;
