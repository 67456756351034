import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import View from './View';
import PageHeader from '../../base/PageHeader';
import { isError } from '../../common/HelperFunctions';
import { PanelStyled } from '../../common/configuration';
import * as queryService from '../../base/query.service';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import {
  apiFilterProcessor,
  getOffsetFromPagination,
} from '../../../utils/api';
import { breadCrumbConfig, title, filterConfig } from './config';
import { imageSliderListFormatter } from '../../common/HelperFunctions';
import { ATTENDANCE_REPORT_MAP } from '../../../data/enums/Route';
// import data from './data';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class AttendanceReport extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      display: {
        searchBox: false,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
      },
    };

    const { downloadReport, displayAlert } = props;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadReport,
      displayAlert
    );

    this.getData = () => {
      const { data } = this.state;
      return data;
    };
  }

  componentDidMount() {
    this.loadTableData();
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  loadTableData = () => {
    const { data, queryParameters } = this.state;
    const { listAttendanceHistory } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    listAttendanceHistory(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          queryString: queryParameters.search,
          dateRange: queryParameters.date,
        },
      },
      {
        handleSuccess: (response) => {
          const attendanceList =
            (response.data.listAttendanceHistory &&
              response.data.listAttendanceHistory.rows) ||
            [];
          data.list = attendanceList.map((attendance) => {
            if (attendance.images) {
              attendance.images = [
                ...(imageSliderListFormatter(attendance.images, '', {
                  w: 2400,
                  h: 1100,
                }) || []),
              ];
            }
            return attendance;
          });
          // data.list =
          //   (response.data.listAttendanceHistory &&
          //     response.data.listAttendanceHistory.rows) ||
          //   [];
          data.total =
            (response.data.listAttendanceHistory &&
              response.data.listAttendanceHistory.count) ||
            0;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
        handleError: (error) => {
          this.onAPIRequestFailure(error);
        },
      }
    );
  };
  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;
    return queryParameters;
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };
  onMarkerClick = (id, location) => {
    const { history } = this.props;
    const { queryParameters } = this.state;
    history.push(`/${ATTENDANCE_REPORT_MAP}/${id}`, { center: location, queryParameters });
  }
  
  render() {
    const { data, queryParameters, display } = this.state;
    const { serverResponseWaiting } = this.props;
    console.log(data.list,'list');
    return (
      <Fragment>
        <div className='section-header'>
          <PanelStyled>
            <PageHeader
              display={display}
              breadCrumb={breadCrumbConfig}
              config={{
                title,
                create: false,
                download: true,
                filter: true,
                search: true,
                date: true,
              }}
              downloadConfig={{
                domain: 'ATTENDANCE_REPORT',
              }}
              filter={{
                date: queryParameters.date,
                menuList: filterConfig.menu,
                onFilterChange: this.basePaginationService.handleFilterChange,
              }}
              queryParameters={queryParameters}
              controlDisplay={this.controlDisplay}
              handleCreateClick={() => {}}
              resetFilter={this.basePaginationService.resetFilter}
              clearSearchText={this.basePaginationService.clearSearchText}
              handleSearchChange={
                this.basePaginationService.handleSearchInputChange
              }
              handleDateRangeChange={
                this.basePaginationService.handleDateRangeChange
              }
              handleDownloadClick={
                this.basePaginationService.handleDownloadClick
              }
            />
          </PanelStyled>
        </div>
        <div className='section-content'>
          <View
            data={data}
            permission={this.permission}
            pagination={queryParameters.pagination}
            onIconClick={() => {}}
            loading={serverResponseWaiting}
            onPageChange={this.basePaginationService.onPageSelect}
            onMarkerClick={this.onMarkerClick}
          />
        </div>
      </Fragment>
    );
  }
}

AttendanceReport.propTypes = propTypes;

AttendanceReport.defaultProps = defaultProps;

export default withAlert()(AttendanceReport);
