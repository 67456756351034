import { collection as collectionRoute } from '../../../../data/enums/Route';
import { FILTER } from '../../../../data/enums/filter';
import { getFilterConfig } from '../../../../utils/filter';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { FORM_CONFIG } from '../../../../data/enums/config';
import { refGenerator } from '../../../../utils';
import { getTodaysDate } from '../../../../utils/date';

const title = 'Receipts';

const breadCrumbConfig = [
  collectionRoute,
];

const filter = getFilterConfig([FILTER.SUB_D, FILTER.ROUTE, FILTER.DSE, FILTER.PAYMENT_STATUS, FILTER.COLLECTION_TYPE]);

const collectionTypes = [
  {
    id: 1,
    title: 'CASH',
    label: 'CASH',
  },
  {
    id: 2,
    title: 'CHEQUE',
    label: 'CHEQUE',
  },
  {
    id: 3,
    title: 'POST DATED CHEQUE',
    label: 'POST_DATED_CHEQUE',
  },
];

const formMapper = element => ({
  id: element.id || '',
  outletId: element.outLetId || 0,
  ledgerId: element.id || 0,
  date: element.date || getTodaysDate(),
  details: {
    valuedDate: element.details && element.details.valuedDate || getTodaysDate(),
    chequeNumber: element.details && element.details.chequeNumber || '',
    bank: element.details && element.details.bank || '',
  },
  /* receivedBy: element.receivedBy || 0, */
  remarks: element.remarks || '',
  amount: element.amount || 0,
  collectionType: element.collectionType || collectionTypes[0].label,


});

const cashRequiredList = [
  'date', 'receivedBy', 'outletId', 'amount', 'collectionType',
  'remarks', 'ledgerId',
];

const chequeRequiredList = [...cashRequiredList, 'chequeNo', 'bank'];
const postDateChequeRequiredList = [...chequeRequiredList, 'valuedDate'];

const formConfig = {
  [EVENT_OPERATION.CREATE]: {
    [FORM_CONFIG.TITLE]: 'Create Receipts',
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    [FORM_CONFIG.MAPPER]: () => formMapper({}),
    [FORM_CONFIG.REFS_OBJ]: refGenerator([...postDateChequeRequiredList]),
  },
  [EVENT_OPERATION.UPDATE]: {
    [FORM_CONFIG.TITLE]: 'Update Receipts',
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    [FORM_CONFIG.MAPPER]: element => formMapper(element),
    [FORM_CONFIG.REFS_OBJ]: refGenerator([...postDateChequeRequiredList]),
  },
  [EVENT_OPERATION.DELETE]: {
    [FORM_CONFIG.TITLE]: 'DELETE',
    [FORM_CONFIG.VALIDATION_REQUIRED]: false,
    [FORM_CONFIG.MAPPER]: (element = {}) => ({ id: element.id || '' }),
  },
};

export {
  collectionTypes, title, breadCrumbConfig, filter as filterConfig, formConfig,
  chequeRequiredList,
};
