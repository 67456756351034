
import React from 'react';
import PropTypes from 'prop-types';
import { tableData } from '../../../../utils/paginatedListUtils';
import { CheckBox } from '../../../../components';
import Icon from '../../../../components/Icon';
import { normalPresentor } from '../../../../utils/date';
import { fixedFloatAndCommas } from '../../../../utils/conversion';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { ImageColumn } from '../../../../utils/tableUtils';
import { USER_ROLE_TITLE } from '../../../../data/enums/UserRole';
import { headerLabelConfig } from '../../../common/HelperFunctions';


const labelMappings = {
  checkbox: 'checkbox',
  date: 'date',
  outlet: 'outlet',
  collector: 'collector',
  bank: 'bank',
  amount: 'amount',
  alias: 'alias',
  remarks: 'remarks',
  userRole: 'userRole',
  dse: 'dse',
  chequeNumber: 'chequeNumber',
  distributor: 'distributor',
  route: 'route',
  images: 'images',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.checkbox,
    title: '',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.date,
    title: 'Date',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.outlet,
    title: 'Outlet',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.collector,
    title: 'Collector',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.bank,
    title: 'Bank',
    show: true,
  },
  {
    id: 11,
    label: labelMappings.chequeNumber,
    title: 'Cheque No.',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.amount,
    title: 'Amount',
    show: true,
  },
  {
    id: 7,
    label: labelMappings.alias,
    title: 'Alias',
    show: true,
  },
  {
    id: 8,
    label: labelMappings.remarks,
    title: 'Remarks',
    show: true,
  },
  {
    id: 9,
    label: labelMappings.userRole,
    title: 'User Role',
    show: false,
  },
  {
    id: 10,
    label: labelMappings.dse,
    title: headerLabelConfig[USER_ROLE_TITLE.DSE],
    show: false,
  },
  {
    id: 12,
    label: labelMappings.distributor,
    title: 'Distributor',
    show: false,
  },
  {
    id: 13,
    label: labelMappings.route,
    title: 'Route',
    show: false,
  },
  {
    id: 14,
    label: labelMappings.images,
    title: 'Image',
    show: true,
  },
];

const TableHeader = ({
  dataListLength, checkedListLength, primaryCheckboxHandler, tableConfig, permission,
}) => (
  <thead>
    <tr>
      {tableConfig.map(label => (label.show
        ? label.label === labelMappings.checkbox
          ? (dataListLength > 0) && permission && permission.update && (
            <th>
              <CheckBox
                checked={(dataListLength === checkedListLength)}
                onCheckBoxClick={e => primaryCheckboxHandler(e)}
              />
            </th>
          )
          : tableData(label.title, label.align, label.classNames)
        : null))}
    </tr>
  </thead>
);

const returnTableBody = (
  data, onIconClick, handleSliderOpen, checkedList, onSecondaryCheckBoxClick, checked, section, permission,
) => {
  const table = {};
  table[labelMappings.checkbox] = ((
    checkedList && permission && permission.update && (
      <td
        onClick={e => e.stopPropagation()}
        key={`cash-${data.id}-id`}
      >

        <CheckBox
          checked={checked}
          onCheckBoxClick={() => onSecondaryCheckBoxClick(data.id)}
        />

      </td>)
  ));
  table[labelMappings.date] = (
    <td key={`cash-${data.id}-date`}>{normalPresentor(data.date)}</td>
  );
  table[labelMappings.outlet] = (
    <td key={`cash-${data.id}-outlet`}>{data.outlet.title}</td>
  );
  table[labelMappings.collector] = (
    <td key={`cash-${data.id}-collector`}>{data.collector.fullName}</td>
  );
  table[labelMappings.bank] = (
    <td key={`cash-${data.id}-bank`}>{data.details.bank}</td>
  );
  table[labelMappings.amount] = (
    <td key={`cash-${data.id}-amount`}>{fixedFloatAndCommas(data.amount)}</td>
  );
  table[labelMappings.alias] = (
    <td key={`cash-${data.id}-alias`}>
      {section === 'received' ? (
        <div className={`alias-td ${permission && permission.create ? 'alias' : 'disabled'}`}>
          <div className="add">
            <Icon iconName="plus" onClick={() => onIconClick(EVENT_OPERATION.CREATE, data)} />
          </div>
          {data.paymentDetails.length !== 0 ? <div className="count" onClick={() => onIconClick(EVENT_OPERATION.READ, data)}>{data.paymentDetails.length}</div> : ''}
        </div>
      )
        : (
          <div className="alias-td">
            { data.paymentDetails.length !== 0
        && <div className="count" onClick={() => onIconClick(EVENT_OPERATION.READ, data)}>{data.paymentDetails.length}</div>}
          </div>
        )
      }
    </td>
  );
  table[labelMappings.remarks] = (
    <td key={`cash-${data.id}-remarks`}>{data.remarks}</td>
  );
  table[labelMappings.userRole] = (
    <td key={`cash-${data.id}-userRole`}>{data.collector.role.name}</td>
  );
  table[labelMappings.dse] = (
    <td key={`cash-${data.id}-dse`}>{data.outlet.route.user.fullName}</td>
  );
  table[labelMappings.chequeNumber] = (
    <td key={`cash-${data.id}-chequeNumber`}>{data.details.chequeNumber}</td>
  );
  table[labelMappings.distributor] = (
    <td key={`cash-${data.id}-distributor`}>{data.distributor.title}</td>
  );
  table[labelMappings.route] = (
    <td key={`cash-${data.id}-route`}>{data.outlet.route.title}</td>
  );
  table[labelMappings.images] = (
    <ImageColumn
      keyTitle={`rp-image-${data.id}`}
      imageList={data.details.image || []}
      onImageClick={handleSliderOpen}
    />
  );


  return table;
};

const TableBody = ({
  data, onIconClick, tableConfig, handleSliderOpen, checkedList, secondaryCheckboxHandler, section, permission,
}) => {
  // Expanding/Dragging and collapsing table body.
  // color based upon table body
  // tooltip if it is sorted.
  // active className
  const checked = checkedList ? checkedList.includes(data.id) : null;
  const tableBody = returnTableBody(data, onIconClick, handleSliderOpen, checkedList, secondaryCheckboxHandler, checked, section, permission);

  return (
    <tr className={checked ? 'active' : ''}>
      {tableConfig.map(label => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};


TableHeader.propTypes = {
  checkedListLength: PropTypes.number,
  dataListLength: PropTypes.number,
  primaryCheckboxHandler: PropTypes.func.isRequired,
};

TableHeader.defaultProps = {
  checkedListLength: 0,
  dataListLength: 0,
};

export {
  TableHeader,
  TableBody,
  labelConfig,
};
